import axios from "../helpers/axios";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";

import { API_URL } from "./constants";
import * as queryString from "query-string";
import { getBrowserInfo } from "../helpers/common";

const cookies = new Cookies();

const getUserId = () => {
  const userId = cookies.get("userPaymentId");
  if (userId) {
    return userId;
  }

  const randomId = uuidv4();
  const cookiesExpiresAt = cookies.get("expiresAt") || Date.now() + 86400000;
  cookies.set("userPaymentId", randomId, {
    expires: new Date(cookiesExpiresAt),
    path: "/"
  });

  return randomId;
};

const listing = {
  async getHotelList(data) {
    try {
      const result = await axios.get(`${API_URL}/hotels?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getHotel(data) {
    try {
      const result = await axios.get(`${API_URL}/hotels/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getListing(data) {
    try {
      const result = await axios.get(`${API_URL}/listings/${data.slug}?${queryString.stringify({
        ...data,
        userId: getUserId()
      }, {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: "bracket"
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getListingList(data) {
    try {
      const result = await axios.get(`${API_URL}/listings?${queryString.stringify({
        ...data,
        userId: getUserId()
      }, {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: "bracket"
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getMyPurchasesList(data) {
    try {
      const result = await axios.get(`${API_URL}/profile/purchases?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getSellerListingList(data) {
    try {
      const result = await axios.get(`${API_URL}/seller/listings?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createListing(data) {
    try {
      const result = await axios.post(`${API_URL}/listings`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async sendListingToApproval(data) {
    try {
      const result = await axios.patch(`${API_URL}/listings/${data.id}/send-to-approval`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateListing(data) {
    try {
      const result = await axios.put(`${API_URL}/listings/${data.id}`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteListing(data) {
    try {
      const result = await axios.delete(`${API_URL}/listings/${data.id}`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getAttachmentsUrl(data) {
    try {
      const result = await axios.get(`${API_URL}/listings/${data.id}/download-attachments?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async startBookingListing(data) {
    try {
      const result = await axios.patch(`${API_URL}/listings/${data.id}/start-booking`, {
        userId: getUserId(),
        ...data
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async endBookingListing(data) {
    try {
      const result = await axios.patch(`${API_URL}/listings/${data.id}/end-booking`, {
        userId: getUserId(),
        ...data
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async purchaseOrganicListing(data) {
    try {
      const result = await axios.post(`${API_URL}/listings/organic/${data.listingId}/purchase`, {
        userId: getUserId(),
        cardType: data.cardType,
        slug: data.slug
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async purchaseB2BListing(data) {
    try {
      const result = await axios.post(`${API_URL}/listings/b2b/${data.slug}/purchase`, {
        ...data,
        userId: getUserId()
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createListingDispute(data) {
    try {
      const result = await axios.post(`${API_URL}/listings/${data.slug}/disputes`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateListingActivity(data) {
    try {
      const result = await axios.patch(`${API_URL}/listings/${data.id}/update-activity`, {
        slug: data.slug,
        userId: getUserId()
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getBestDeals(data) {
    try {
      const result = await axios.get(`${API_URL}/listings/best-deals?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async cancelBooking(data) {
    try {
      const result = await axios.patch(`${API_URL}/listings/${data.id}/cancel`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async addCard(data) {
    try {
      const result = await axios.patch(`${API_URL}/listings/${data.id}/add-card`, {
        ...data,
        browserInfo: getBrowserInfo()
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createListingPriceOffer(data) {
    try {
      const result = await axios.post(`${API_URL}/listings/${data.listingId}/offers`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async declineListingPriceOffer(data) {
    try {
      const result = await axios.post(`${API_URL}/listings/${data.listingId}/offers/${data.offerId}/decline`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async acceptListingPriceOffer(data) {
    try {
      const result = await axios.post(`${API_URL}/listings/${data.listingId}/offers/${data.offerId}/accept`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default listing;
