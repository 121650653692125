import { all, put, call, takeLatest } from "redux-saga/effects";

import article from "../../../api/admin/article";
import * as types from "./articleActionsTypes";
import * as generalTypes from "./../../general/generalActionsTypes";
import { MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR } from "../../../react/constants";

function *getArticle ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(article.getArticle, data);
    yield put({ type: types.ADMIN_GET_ARTICLE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_ARTICLE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *createArticle ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(article.createArticle, data);
    yield put({ type: types.ADMIN_CREATE_ARTICLE_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Article created successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_CREATE_ARTICLE_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
    }
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateArticle ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(article.updateArticle, data);
    yield put({ type: types.ADMIN_UPDATE_ARTICLE_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Article saved successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_ARTICLE_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
    }
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getArticleList ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(article.getArticleList, data);
    yield put({ type: types.ADMIN_GET_ARTICLE_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_ARTICLE_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *deleteArticle ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(article.deleteArticle, data);
    yield put({ type: types.ADMIN_DELETE_ARTICLE_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_GET_ARTICLE_LIST_REQUEST });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_ARTICLE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *publishArticle ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(article.publishArticle, data);
    yield put({ type: types.ADMIN_PUBLISH_ARTICLE_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: `Article ${data.publish === "publish" ? "published" : "unpublished"} successfully`
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_PUBLISH_ARTICLE_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *saveAndPublishArticle ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(data.id ? article.updateArticle : article.createArticle, data);
    data = { ...data, id: response.id };
    yield put({ type: types.ADMIN_PUBLISH_ARTICLE_REQUEST, data });
  } catch (error) {
    yield put({ type: types.ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_ARTICLE_LIST_REQUEST, getArticleList),
    yield takeLatest(types.ADMIN_GET_ARTICLE_REQUEST, getArticle),
    yield takeLatest(types.ADMIN_CREATE_ARTICLE_REQUEST, createArticle),
    yield takeLatest(types.ADMIN_UPDATE_ARTICLE_REQUEST, updateArticle),
    yield takeLatest(types.ADMIN_DELETE_ARTICLE_REQUEST, deleteArticle),
    yield takeLatest(types.ADMIN_PUBLISH_ARTICLE_REQUEST, publishArticle),
    yield takeLatest(types.ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST, saveAndPublishArticle)
  ])
}
