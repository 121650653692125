import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Select, Tooltip } from "antd";
import Listings from "./Listings";
import Users from "./Users";
import Disputes from "./Disputes";
import Fees from "./Fees";
import Notifications from "./Notifications";
import SalesListings from "./SalesListings";
import FundsInWallets from "./FundsInWallets";
import GeolocationData from "./GeolocationData";
import { updateAdminCountryViewAction } from "../../../redux/admin/dashboard/dashboardActions";

const Dashboard = ({ country, updateAdminCountryView }) => {

  return (
    <Row className="admin-dashboard">
      <Col span={24}>
        <Row>
          <Col span={24} className="feedback-list__filters">
            <span className="admin-dashboard__select-country">Select country:</span>
            <Select
              className="feedback-list__filter admin-dashboard__filter-country"
              defaultValue={country}
              onChange={value => updateAdminCountryView(value)}
            >
              <Select.Option value="GB">United Kingdom, £</Select.Option>
              <Select.Option value="US">United States, $</Select.Option>
            </Select>
            <Tooltip
              overlayClassName="admin-dashboard__filter-country-tooltip-text"
              placement="bottom"
              title="By selecting country you specify the registered country of a Seller and native currency of a listing. Sections Listings, Funds in wallets, Users, Geolocation data, Sales listings will show analytics for users from a selected country and/or listings created by a user from a selected country."
            >
              <img src="/images/info.svg" className="admin-dashboard__filter-country-tooltip-image" alt="info" />
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col span={12} className="admin-dashboard__column">
            <Listings />
            <Users />
            <SalesListings />
          </Col>
          <Col span={12} className="admin-dashboard__column">
            <FundsInWallets />
            <GeolocationData />
          </Col>
        </Row>
        <Row>
          <Col span={10} className="admin-dashboard__static-column">
            <Disputes />
          </Col>
          <Col span={7} className="admin-dashboard__static-column">
            <Fees />
          </Col>
          <Col span={7} className="admin-dashboard__static-column">
            <Notifications />
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

Dashboard.propTypes = {
  country: PropTypes.string.isRequired,
  updateAdminCountryView: PropTypes.func.isRequired
};

export default connect(
  state => ({
    country: state.admin.dashboard.country
  }),
  {
    updateAdminCountryView: updateAdminCountryViewAction
  }
)(Dashboard);
