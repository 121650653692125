import * as types from "./experiencesActionsTypes";

export const getExperiencesListRequest = (data) => {
  return { type: types.ADMIN_GET_EXPERIENCES_LIST_REQUEST, data };
};

export const getExperienceRequest = (data) => {
  return { type: types.ADMIN_GET_EXPERIENCE_REQUEST, data };
};

export const createExperienceRequest = (data) => {
  return { type: types.ADMIN_CREATE_EXPERIENCE_REQUEST, data };
};

export const updateExperienceRequest = (data) => {
  return { type: types.ADMIN_UPDATE_EXPERIENCE_REQUEST, data };
};

export const deleteExperienceRequest = (data) => {
  return { type: types.ADMIN_DELETE_EXPERIENCE_REQUEST, data };
};

export const clearExperienceAction = () => {
  return { type: types.ADMIN_CLEAR_EXPERIENCE };
};

export const clearExperienceErrorAction = () => {
  return { type: types.ADMIN_CLEAR_EXPERIENCE_ERROR };
};
