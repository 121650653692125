import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Input, Row, Select, Table } from "antd";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";

import { deleteHotelRequest, getHotelsRequest } from "../../../redux/admin/hotels/hotelsActions";
import { itemRender } from "../../../helpers/pagination";
import { hideModalAction, showModalAction } from "../../../redux/general/generalActions";

export const HotelsList = ({
  hotels,
  pagination,
  isProcessing,
  error,
  requestSuccess,
  getHotels,
  deleteHotel,
  showModal,
  hideModal
}) => {
  const [paginationObj, setPaginationObj] = useState({
    pageSize: 10,
    sort: "name"
  });

  useEffect(() => {
    getHotels(paginationObj);
  }, [paginationObj]);

  useEffect(() => {
    hideModal();
  }, [requestSuccess]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderEditButton = record => (
    <Link to={`/admin/hotels/${record.key}`} >
      <img
        src="/images/edit.svg"
        alt="edit"
        className="feedback-list__delete"
      />
    </Link>
  );

  const renderDeleteButton = record => (
    <img
      src="/images/close.svg"
      alt="remove"
      className="feedback-list__delete"
      onClick={() => showModal({
        type: "confirm",
        title: "Are You sure you would like to delete hotel?",
        width: 400,
        params: {
          isProcessing,
          error,
          confirmAction: () => deleteHotel({ id: record.key })
        }
      })}
    />
  );

  return (
    <Row className="admin-hotels">
      <Col span={24}>
        <Row justify="space-between" align="top">
          <Col span={10} className="feedback-list__filters admin-listings__filters">
            <Select
              className="feedback-list__filter"
              defaultValue={paginationObj.pageSize}
              onChange={value => {
                setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
              }}
            >
              <Select.Option value={10}>Show 10</Select.Option>
              <Select.Option value={20}>Show 20</Select.Option>
              <Select.Option value={30}>Show 30</Select.Option>
            </Select>
          </Col>
          <Col span={14} className="hotels-list__search-wrapper">
            <Input.Search
              onChange={event => delayedSearch(event.target.value)}
              onSearch={handleSearch}
              placeholder="Search"
              className="feedback-list__search"
            />
            <Link
              to="/admin/hotels/new"
              className="ant-btn button button--primary"
            >
              Add Hotel
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              loading={isProcessing}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                itemRender: itemRender,
                showSizeChanger: false
              }}
              dataSource={hotels.map(hotel => {
                return {
                  key: hotel.id,
                  name: hotel.name,
                  id: hotel.id,
                  source: hotel.source,
                  isUpdatedManually: hotel.isUpdatedManually ? "Yes" : "No"
                }
              })}
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                  sorter: true,
                  defaultSortOrder: "ascend",
                },
                {
                  title: "Ref. no.",
                  dataIndex: "id",
                  key: "id",
                  sorter: true
                },
                {
                  title: "Source",
                  dataIndex: "source",
                  key: "source",
                  sorter: true
                },
                {
                  title: "Updated manually?",
                  dataIndex: "isUpdatedManually",
                  key: "isUpdatedManually",
                  sorter: true,
                  width: 160
                },
                {
                  title: "",
                  key: "actions",
                  width: 20,
                  render: renderEditButton
                },
                {
                  title: "",
                  key: "actions",
                  width: 20,
                  render: renderDeleteButton
                }
              ]}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

HotelsList.propTypes = {
  hotels: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  getHotels: PropTypes.func.isRequired,
  deleteHotel: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    hotels: state.admin.hotels.data,
    pagination: {
      current: state.admin.hotels.curPage,
      total: state.admin.hotels.total,
      pageSize: state.admin.hotels.perPage
    },
    isProcessing: state.admin.hotels.processing,
    error: state.admin.hotels.error,
    requestSuccess: state.admin.hotels.requestSuccess
  }),
  {
    getHotels: getHotelsRequest,
    deleteHotel: deleteHotelRequest,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(HotelsList);
