import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Table, Input, InputNumber, Button, Select } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as yup from "yup";

import { updateChargeOptionsRequest, setDefaultCommissionRequest } from "../../../redux/admin/finance/financeActions";
import { hideModalAction, showModalAction } from "../../../redux/general/generalActions";
import { SpinContainer } from "../../shared/SimpleComponents/SpinContainer/SpinContainer";
import { SUPER_ADMIN_ROLE } from "../../constants";
import { getFeeLabel } from "../../../helpers/common";

const optionsVerificationSchema = yup.object().shape({
  vat: yup.number().max(100)
});

const ChargeOptions = ({
  chargeOptions,
  feeConfigurations,
  userRole,
  error,
  isProcessing,
  updateChargeOptions,
  setDefaultCommission,
  showModal,
  hideModal
}) => {
  const [option, setOption] = useState({});

  const { handleSubmit, control, formState, reset, errors } = useForm({
    validationSchema: optionsVerificationSchema,
  });

  useEffect(() => {
    const initialCountryCode = option.id ? option.country : "GB";
    setOption(chargeOptions.find(item => item.country === initialCountryCode) || chargeOptions[0] || {});
  }, [chargeOptions]);

  useEffect(() => {
    option &&
      reset({
        id: option.id,
        country: option.country,
        feeId: option.feeId,
        vat: option.vat,
      });
  }, [option]);

  const { dirtyFields } = formState;

  const submitHandler = (data) => {
    updateChargeOptions({ id: data.id, body: data });
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Controller
          name="id"
          as={<Input type="hidden" defaultValue={option.id} />}
          control={control}
          defaultValue={option.id}
        />
        <div className="ant-table-wrapper">
          <SpinContainer loading={isProcessing}>
            <div className="ant-table">
              <div className="ant-table-container">
                <div className="ant-table-content">
                  <table>
                    <thead>
                      <tr>
                        <th className="ant-table-cell">Country</th>
                        <th className="ant-table-cell" style={{ width: "100px" }}>VAT, %</th>
                        <th className="ant-table-cell" style={{ width: "100px" }}>Fee</th>
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody">
                      <tr className="ant-table-row">
                        <td className="ant-table-cell">
                          <Controller
                            name="country"
                            as={<Select
                              showSearch
                              className="profile__nationality-select"
                              defaultValue={option.country}
                              onSelect={data => setOption(chargeOptions.find(item => item.country === data) || {})}
                            >
                              {
                                chargeOptions.map(item => (
                                  <Select.Option key={`option-country-${item.id}`} value={item.country}>{item.countryName}</Select.Option>
                                ))
                              }
                            </Select>}
                            control={control}
                            defaultValue={option.country}
                            onChange={([event]) => {
                              return event
                            }}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Controller
                            name="vat"
                            as={<InputNumber
                              className={`profile__input${errors.vat ? " profile__input--error" : ""}`}
                              min={0}
                              max={100}
                              precision={1}
                              decimalSeparator="."
                              placeholder="0.0"
                            />}
                            control={control}
                            defaultValue={option.vat}
                            onChange={([event]) => {
                              return event
                            }}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Controller
                            name="feeId"
                            as={<Select
                              style={{ width: "100px" }}
                              defaultValue={option.feeId}
                            >
                              {
                                feeConfigurations.map(item => (
                                  <Select.Option key={`fee-option-${item.id}`} value={item.id}>
                                    {getFeeLabel(item.id, feeConfigurations)}
                                  </Select.Option>
                                ))
                              }
                            </Select>}
                            control={control}
                            defaultValue={option.feeId}
                            onChange={([event]) => {
                              return event
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </SpinContainer>
        </div>
        {Object.values(errors).length ? <span className="profile__error">VAT can not be greater than 100%</span> : null}
        <p className="finance-settings__comment">
          User commission is higher priority than global commission if it was specified.
        </p>
        <div className="finance-settings__submit">
          <Button
            htmlType="submit"
            disabled={isProcessing || !dirtyFields.size}
            className="button button--primary"
          >
            Save
          </Button>
          {
            userRole === SUPER_ADMIN_ROLE &&
              <Button
                htmlType="button"
                disabled={isProcessing}
                className="button button--default"
                onClick={() => showModal({
                  type: "confirm",
                  title: "Are you sure you would like to reset commission values to default for all Users? This action cannot be reverted",
                  params: {
                    icon: "/images/danger.svg",
                    confirmButtonText: "OK",
                    isProcessing,
                    error,
                    confirmAction: () => {
                      setDefaultCommission();
                      hideModal();
                    }
                  }
                })}
              >
                Reset to default
              </Button>
          }
        </div>
      </form>
      <Table
        className="finance-settings__table"
        pagination={false}
        dataSource={chargeOptions.map(item => ({
          key: item.id,
          countryName: item.countryName,
          vatLabel: parseFloat(item.vat).toFixed(1),
          feeLabel: getFeeLabel(item.feeId, feeConfigurations)
        }))}
        columns={[
          {
            title: "Country",
            dataIndex: "countryName",
            key: "countryName",
            width: 300
          },
          {
            title: "VAT, %",
            dataIndex: "vatLabel",
            key: "vatLabel",
            width: 100
          },
          {
            title: "Fee",
            dataIndex: "feeLabel",
            key: "feeLabel",
            width: 100
          }
        ]}
      />
    </Fragment>
  )
};

ChargeOptions.propTypes = {
  chargeOptions: PropTypes.array.isRequired,
  feeConfigurations: PropTypes.array.isRequired,
  userRole: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  updateChargeOptions: PropTypes.func.isRequired,
  setDefaultCommission: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    chargeOptions: state.admin.finance.chargeOptions,
    feeConfigurations: state.admin.finance.feeConfigurations,
    userRole: state.profile.permissions.role,
    error: state.admin.finance.error,
    isProcessing: state.admin.finance.processing
  }),
  {
    updateChargeOptions: updateChargeOptionsRequest,
    setDefaultCommission: setDefaultCommissionRequest,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(ChargeOptions);
