import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Rate, Checkbox, Button } from "antd";
import { withRouter } from "react-router";
import * as queryString from "query-string";
import chunk from "lodash/chunk";

import ModalHeader from "./components/ModalHeader";
import { hotelChains } from "../../../static/hotelChains";

const quickSearch = [
  {
    name: "Apartment",
    icon: "/images/icons/apartment.svg",
    type: "types",
    value: "3"
  },
  {
    name: "Boutique Hotel",
    icon: "/images/icons/boutique-hotel.svg",
    type: "types",
    value: "45"
  },
  // {
  //   name: "Bed & Breakfast",
  //   icon: "/images/icons/bed-and-breakfast.svg",
  //   type: "types",
  //   value: "4"
  // },
  {
    name: "Fur Friendly",
    icon: "/images/icons/fur-friendly.svg",
    type: "categories"
  }
];

const beds = [
  {
    name: "Single Room",
    icon: "/images/icons/single-room.svg",
    value: "Single Room",
    key: "single-room"
  },
  {
    name: "Double/Twin",
    icon: "/images/icons/double-room.svg",
    value: "Double/Twin",
    key: "double-twin"
  },
  {
    name: "King Size",
    icon: "/images/icons/king-size.svg",
    value: "King Size",
    key: "king-size"
  }
];

const ratings = [
  {
    name: "5",
    value: "5",
    showStars: true
  },
  {
    name: "4",
    value: "4",
    showStars: true
  },
  {
    name: "3",
    value: "3",
    showStars: true
  },
  {
    name: "2",
    value: "2",
    showStars: true
  },
  {
    name: "1",
    value: "1",
    showStars: true
  },
  {
    name: "Unrated",
    value: "0",
    showStars: false
  }
];

const facilities = [
  {
    name: "Bar",
    icon: "/images/icons/bar.svg"
  },
  {
    name: "Restaurant",
    icon: "/images/icons/restaurant.svg"
  },
  {
    name: "Free Parking",
    icon: "/images/icons/free-parking.svg"
  },
  {
    name: "Gym",
    icon: "/images/icons/gym.svg"
  },
  {
    name: "Pool",
    icon: "/images/icons/pool.svg"
  },
  {
    name: "Spa",
    icon: "/images/icons/spa.svg"
  },
  {
    name: "Accessibility",
    icon: "/images/icons/accessibility.svg"
  }
];

const amenities = [
  {
    name: "Wifi",
    icon: "/images/icons/wifi.svg"
  },
  {
    name: "Air Conditioning",
    icon: "/images/icons/air-conditioning.svg"
  },
  {
    name: "Business Facilities",
    icon: "/images/icons/business-facilities.svg"
  },
  {
    name: "Tea/Coffee Facilities",
    icon: "/images/icons/tea-coffee-facilities.svg"
  },
  {
    name: "Room Service",
    icon: "/images/icons/room-service.svg"
  },
  {
    name: "Safe",
    icon: "/images/icons/safe.svg"
  },
  {
    name: "24/7 Reception",
    icon: "/images/icons/24-7-reception.svg"
  },
  {
    name: "Iron",
    icon: "/images/icons/iron.svg"
  },
  {
    name: "Non Smoking",
    icon: "/images/icons/non-smoking.svg"
  },
  {
    name: "Bath/Shower",
    icon: "/images/icons/bath-shower.svg"
  }
];

const ListingsSearchFiltersModalBody = ({ history, searchQuery, onCancel }) => {
  const [filters, setFilters] = useState({});
  const [customKey, setCustomKey] = useState(new Date());
  const [displayedHotelChains, setDisplayedHotelChains] = useState(hotelChains.filter(chain => !!chain.top));

  const [amenitiesGroupFirst, amenitiesGroupSecond] = chunk(amenities, Math.ceil(amenities.length / 2));
  const hotelChainsGroups = chunk(displayedHotelChains, Math.ceil(displayedHotelChains.length / 3));

  useEffect(() => {
    const initialQuery = queryString.parse(history.location.search.slice(1), { arrayFormat: "bracket" }) || {};

    setFilters({
      categories: initialQuery.categories,
      facilities: initialQuery.facilities,
      amenities: initialQuery.amenities,
      rating: initialQuery.rating,
      beds: initialQuery.beds,
      chains: initialQuery.chains,
      types: initialQuery.types
    });
  }, []);

  const onFilterSelect = (event) => {
    const { checked, value, name } = event.target;
    const updatedFilters = Object.assign(filters);

    if (checked) {
      updatedFilters[name] = [...(updatedFilters[name] || []), value];
    } else {
      updatedFilters[name] = updatedFilters[name].filter(item => item !== value);
    }

    setFilters(updatedFilters);
    setCustomKey(new Date());
  };

  return (
    <div className="listings-search-filters listings-search-filters-modal" key={customKey}>
      <ModalHeader onClose={onCancel}>Add more filters</ModalHeader>
      <Row justify="start" align="start" gutter={32}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
          <div className="listings-search-filters__section-title">Quick Search</div>
          { quickSearch.map(item => {
            return (
              <div key={`filter-option-${item.name}`} className="listings-search-filters__filter-row">
                <div className="listings-search-filters__filter-label">
                  <img
                    src={item.icon}
                    alt={item.name}
                    className="listings-search-filters__filter-icon"
                  />
                  <span>{item.name}</span>
                </div>
                <Checkbox
                  value={item.value || item.name}
                  name={item.type}
                  checked={filters[item.type] && filters[item.type].includes(item.value || item.name)}
                  onChange={onFilterSelect}
                />
              </div>
            );
          })}
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
          <div className="listings-search-filters__section-title">Rooms and Beds</div>
          { beds.map(bed => {
            return (
              <div key={`filter-option-${bed.name}`} className="listings-search-filters__filter-row">
                <div className="listings-search-filters__filter-label">
                  <img
                    src={bed.icon}
                    alt={bed.name}
                    className={`listings-search-filters__filter-icon listings-search-filters__filter-icon--${bed.key}`}
                  />
                  <span>{bed.name}</span>
                </div>
                <Checkbox
                  value={bed.value}
                  name="beds"
                  checked={filters.beds && filters.beds.includes(bed.value)}
                  onChange={onFilterSelect}
                />
              </div>
            );
          })}
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
          <div className="listings-search-filters__section-title listings-search-filters__section-title--rating">
            Star Rating
          </div>
          { ratings.map(rating => {
            return (
              <div
                key={`filter-option-${rating.name}`}
                className="listings-search-filters__filter-row listings-search-filters__filter-row--rating"
              >
                { rating.showStars
                  ? <Rate disabled count={rating.value} defaultValue={rating.value} />
                  : <span className="listings-search-filters__filter-label">{rating.name}</span>
                }
                <Checkbox
                  value={rating.value}
                  name="rating"
                  checked={filters.rating && filters.rating.includes(rating.value)}
                  onChange={onFilterSelect}
                />
              </div>
            );
          })}
        </Col>
      </Row>
      <Row justify="start" align="start" gutter={32}>
        <Col xs={{ span: 24 }} md={{ span: 16 }}>
          <div className="listings-search-filters__section-title">Amenities</div>
          <Row justify="start" align="start" gutter={[32, 8]}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              { amenitiesGroupFirst.map(amenity => {
                return (
                  <div key={`filter-option-${amenity.name}`} className="listings-search-filters__filter-row">
                    <div className="listings-search-filters__filter-label">
                      <img
                        src={amenity.icon}
                        alt={amenity.name}
                        className="listings-search-filters__filter-icon"
                      />
                      <span>{amenity.name}</span>
                    </div>
                    <Checkbox
                      value={amenity.name}
                      name="amenities"
                      checked={filters.amenities && filters.amenities.includes(amenity.name)}
                      onChange={onFilterSelect}
                    />
                  </div>
                );
              })}
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              { amenitiesGroupSecond.map(amenity => {
                return (
                  <div key={`filter-option-${amenity.name}`} className="listings-search-filters__filter-row">
                    <div className="listings-search-filters__filter-label">
                      <img
                        src={amenity.icon}
                        alt={amenity.name}
                        className="listings-search-filters__filter-icon"
                      />
                      <span>{amenity.name}</span>
                    </div>
                    <Checkbox
                      value={amenity.name}
                      name="amenities"
                      checked={filters.amenities && filters.amenities.includes(amenity.name)}
                      onChange={onFilterSelect}
                    />
                  </div>
                );
              })}
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
          <div className="listings-search-filters__section-title">Facilities</div>
          { facilities.map(facility => {
            return (
              <div key={`filter-option-${facility.name}`} className="listings-search-filters__filter-row">
                <div className="listings-search-filters__filter-label">
                  <img
                    src={facility.icon}
                    alt={facility.name}
                    className="listings-search-filters__filter-icon"
                  />
                  <span>{facility.name}</span>
                </div>
                <Checkbox
                  value={facility.name}
                  name="facilities"
                  checked={filters.facilities && filters.facilities.includes(facility.name)}
                  onChange={onFilterSelect}
                />
              </div>
            );
          })}
        </Col>
      </Row>
      <Row justify="start" align="start" gutter={32}>
        <Col span={24}>
          <div className="listings-search-filters__section-title">Filter by Brands</div>
          <Row justify="start" align="start" gutter={[32, 8]}>
            {hotelChainsGroups.map((chainsGroup, index) => {
              return (
                <Col xs={{ span: 24 }} sm={{ span: 8 }} key={`hotel-chains-row-${index}`}>
                  { chainsGroup.map(chain => {
                    return (
                      <div key={`filter-option-${chain.value}`} className="listings-search-filters__filter-row">
                        <div className="listings-search-filters__filter-label">
                          <span>{chain.name}</span>
                        </div>
                        <Checkbox
                          value={chain.value}
                          name="chains"
                          checked={filters.chains && filters.chains.includes(chain.value)}
                          onChange={onFilterSelect}
                        />
                      </div>
                    );
                  })}
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col span={24} className="listings-search-filters__show-container">
              <span
                className="listings-search-filters__show"
                onClick={() => {
                  let chains = hotelChains;
                  if (hotelChains.length === displayedHotelChains.length) {
                    chains = hotelChains.filter(chain => !!chain.top);
                  }

                  setDisplayedHotelChains(chains)
                }}
              >
                Show {hotelChains.length === displayedHotelChains.length ? "less" : "more"}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="listings-search-filters__buttons">
          <Row gutter={16}>
            <Col span={12}>
              <Button
                htmlType="button"
                type="primary"
                className="listings-search-filters__clear"
                onClick={() => {
                  const urlQuery = queryString.stringify({
                    placeId: searchQuery.placeId,
                    placeType: searchQuery.placeType,
                    label: searchQuery.label,
                    checkInDate: searchQuery.checkInDate,
                    checkOutDate: searchQuery.checkOutDate,
                    rooms: searchQuery.rooms,
                    adults: searchQuery.adults,
                    children: searchQuery.children,
                    childrenAge: searchQuery.childrenAge
                  }, {
                    skipEmptyString: true,
                    skipNull: true,
                    arrayFormat: "bracket"
                  });

                  history.push(`${history.location.pathname}?${urlQuery}`);

                  setFilters({});
                }}
              >
                Clear Filters
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="button"
                type="primary"
                className="listings-search-filters__apply"
                onClick={() => {
                  const urlQuery = queryString.stringify({
                    placeId: searchQuery.placeId,
                    placeType: searchQuery.placeType,
                    label: searchQuery.label,
                    checkInDate: searchQuery.checkInDate,
                    checkOutDate: searchQuery.checkOutDate,
                    rooms: searchQuery.rooms,
                    adults: searchQuery.adults,
                    children: searchQuery.children,
                    childrenAge: searchQuery.childrenAge,
                    ...filters
                  }, {
                    skipEmptyString: true,
                    skipNull: true,
                    arrayFormat: "bracket"
                  });

                  history.push(`${history.location.pathname}?${urlQuery}`);

                  onCancel();
                }}
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

ListingsSearchFiltersModalBody.propTypes = {
  history: PropTypes.object,
  searchQuery: PropTypes.object,
  onCancel: PropTypes.func
};

export default withRouter(ListingsSearchFiltersModalBody);
