import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

const Image = ({ imageUrl, emptyImageUrl, alt, className, loading, ...rest }) => {
  return (
    <LazyLoad scrollContainer="body">
      { imageUrl
        ? <img
          src={imageUrl}
          className={`image ${className ? className : ""}`}
          alt={alt}
          loading={loading}
          {...rest}
        />
        : <div className={`image image--empty ${className ? className : ""}`}>
          <img
            src={emptyImageUrl}
            alt={alt}
            loading={loading}
            {...rest}
          />
        </div>
      }
    </LazyLoad>
  );
};

Image.propTypes = {
  imageUrl: PropTypes.string,
  emptyImageUrl: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.oneOf(["lazy", "eager", "auto"])
};

Image.defaultProps = {
  emptyImageUrl: "/images/no-image.svg",
  loading: "lazy"
};

export default Image;
