import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet-universal";
import * as queryString from "query-string";
import "leaflet/dist/leaflet.css";

import ListingItem from "./ListingItem";
import { ImageBgCover } from "../shared/SimpleComponents/ImageBgCover/ImageBgCover";
import {
  getHotelRequest,
  getListingListRequest,
  setListingsListAction,
  cancelListingsSearchAction
} from "../../redux/listing/listingActions";
import { itemRender } from "../../helpers/pagination";
import { getCurrencyBadge } from "../../helpers/common";
import { MAPBOX_ACCESS_TOKEN, MAPBOX_USERNAME, MAPBOX_STYLE_ID } from "../../api/constants";
import { LISTING_TYPE_B2B } from "../constants";
import Pagination from "../shared/Pagination";
import SortHotelsList from "./SortHotelsList";
import ListingSearch from "../shared/ListingSearch";
import useSingleton from "../../hooks/useSingleton";
import { placesWithSearchRadiusSpecified } from "../../static/placesWithSearchRadiusSpecified";
import FilterHotelsList from "./FilterHotelsList";

const PAGE_SIZE = 20;
let Leaflet = null;
let initialQuery = null;

const reducer = (acc, cur) => {
  try {
    const longitude = cur.Hotel.location.coordinates[0];
    const latitude = cur.Hotel.location.coordinates[1];

    return {
      minLat: !acc.minLat || latitude < acc.minLat ? latitude : acc.minLat,
      maxLat: !acc.maxLat || latitude > acc.maxLat ? latitude : acc.maxLat,
      minLon: !acc.minLon || longitude < acc.minLon ? longitude : acc.minLon,
      maxLon: !acc.maxLon || longitude > acc.maxLon ? longitude : acc.maxLon
    }
  } catch (e) {
    return acc;
  }
};

const setListingsSearchQuery = (query) => {
  if (window && window.localStorage) {
    window.localStorage.setItem("searchQuery", query);
  }
};

const PurchasingListingList = ({
  isProcessing,
  listingList,
  history,
  totalListingsCount,
  coordinates,
  getListingList,
  setListingsList,
  cancelListingsSearch,
  getHotel,
  selectedHotel
}) => {
  const mapRef = useRef(null);
  const markerRefs = useRef({});
  const [searchHotelChanged, setSearchHotelChanged] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  let delayedFlyTo = false;


  const updateInitialQuery = () => {
    try {
      setListingsSearchQuery(history.location.search);
      initialQuery = queryString.parse(history.location.search.slice(1), { arrayFormat: "bracket" }) || {};
    } catch (e) {
      initialQuery = {};
    }
  };

  useSingleton(() => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      Leaflet = require("leaflet");
    }
    updateInitialQuery();
  });

  const [bounds, setBounds] = useState(null);
  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isMoreResultsShown, setIsMoreResultsShown] = useState(false);
  const [mapView, setMapView] = React.useState(false)
  const onShowMap = () => {
    setMapView(true)
    setTimeout(() => {
      mapRef.current.leafletElement.invalidateSize();
    }, 100)
  }
  const onShowList = () => setMapView(false)

  useEffect(() => {
    return () => {
      cancelListingsSearch();
      setListingsList([]);
    }
  }, []);

  useEffect(() => {
    const boundsObj = listingList.reduce(reducer, { minLat: null, maxLat: null, minLon: null, maxLon: null, });
    if (!bounds && !Object.values(boundsObj).some(b => b === null)) {
      setBounds([[boundsObj.minLat, boundsObj.minLon], [boundsObj.maxLat, boundsObj.maxLon]]);
    }
  }, [listingList]);

  const formattedSelectedHotel = useMemo(() => {
    const defaultHotel = {
      Hotel: {
        HotelImages: []
      }
    }
    if (selectedHotel && !searchHotelChanged) {
      if (selectedHotel.hotelId) {
        setTimeout(() => {
          setInitialLoadDone(true)
        }, 2000)
      }

      return { ...selectedHotel, Hotel: { ...defaultHotel.Hotel, ...selectedHotel.Hotel } }
    }

    return defaultHotel
  }, [selectedHotel, searchHotelChanged]);

  const getSearchRadius = (placeId) => {
    const selectedPlace = placesWithSearchRadiusSpecified.find(place => place.placeId === placeId);
    if (selectedPlace) {
      return selectedPlace.searchRadius;
    }

    return 7;
  }

  const getListingListByParams = (data) => {
    if (!Object.values(data).find(b => b)) return;
    const urlQuery = queryString.stringify(data, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: "bracket"
    });
    setListingsSearchQuery(`?${urlQuery}`);
    setSearchQuery(data);
    setCurrentPage(1);
    setIsMoreResultsShown(false);

    if (selectedHotel.hotelId) {
      if (!data.placeId || `${data.placeId}` !== `${selectedHotel?.hotelId}`) {
        setSearchHotelChanged(true)
      }
    }

    if (data.placeId && data.checkInDate && data.checkOutDate) {
      if (data.placeId && data.placeType === "hotel") {
        getHotel({ id: data.placeId });
      } else {
        setInitialLoadDone(true);
      }
      setBounds(null);
      setListingsList([]);
      getListingList({
        ...data,
        pageSize: PAGE_SIZE,
        searchRadius: getSearchRadius(data.placeId)
      });
    }

    history.push(`${history.location.pathname}?${urlQuery}`);
  };

  const searchWithExtendedRadius = () => {
    if (searchQuery.placeId && searchQuery.checkInDate && searchQuery.checkOutDate) {
      setBounds(null);
      setListingsList([]);
      getListingList({
        ...searchQuery,
        searchRadius: 9
      });
      setIsMoreResultsShown(true);
      setCurrentPage(1);
    }
  }

  useEffect(() => {
    updateInitialQuery();

    if (initialQuery.placeId && initialQuery.checkInDate && initialQuery.checkOutDate) {
      getListingListByParams(initialQuery);
    }
  }, [history.location.search]);

  const mapClickHandler = (ev) => {
    if (mapRef.current && ev.latlng) {
      mapRef.current.leafletElement.panTo(ev.latlng)
    }
    setSelectedMarker(null);
  };

  const markerClickHandler = (ev, id) => {
    setSelectedMarker(id);
    if (mapRef.current && ev.latlng) {
      mapRef.current.leafletElement.panTo(ev.latlng)
    }
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);

    setTimeout(() => {
      document.querySelector("#root").scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const getB2BSearchParams = () => {
    return queryString.stringify(
      {
        checkInDate: searchQuery.checkInDate,
        checkOutDate: searchQuery.checkOutDate,
        adults: searchQuery.adults,
        children: searchQuery.children,
        childrenAge: searchQuery.childrenAge,
        rooms: searchQuery.rooms
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: "bracket" }
    );
  };

  const getAvailableListingsLabel = () => {
    const start = (currentPage * PAGE_SIZE) - (PAGE_SIZE - 1);
    let end = currentPage * PAGE_SIZE;

    if (end > totalListingsCount) {
      end = totalListingsCount;
    }

    return `${start} – ${end} of ${totalListingsCount} places to stay`;
  };

  const inList = () => {
    if (!listingList) {
      return false
    }
    const filteredList = listingList.filter(listing => {
      return listing.Hotel ? `${listing.Hotel.HotelAddress.id}` === `${searchQuery.placeId}` : false
    })

    return filteredList.length > 0
  }

  return (
    <Row justify="space-around" align="middle" className="listing-list">
      <Col xs={{ span: 24 }} md={{ span: 22 }}>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 16 }} className={`listing-list__list-container ${mapView ? "mobile-hidden" : ""}`}>
            <Row className="listing-list__search">
              <ListingSearch
                showFilters={true}
                showMobileVersion={true}
                initialValue={initialQuery}
                onSearch={getListingListByParams}
              />
            </Row>
            <Row>
              <Col span={24} className="listing-list__items">
                { searchQuery.label &&
                  <Row>
                    <Col xs={{ span: 24 }} xl={{ span: 18 }} className="flex-column">
                      <Typography.Title level={1} className="listing-list__title">
                        Stays selected for {decodeURI(searchQuery.label).split(", ")[0]}
                      </Typography.Title>
                      { !isProcessing && listingList && listingList.length > 0 && !mapView &&
                        <button className="map-switch" onClick={onShowMap}>
                          <img src="/images/location.svg" alt="location" className="listing-search__place-icon"/>
                          Map View
                        </button>
                      }
                    </Col>
                  </Row>
                }
                <Row align="middle">
                  <Col xs={{ span: 24 }} xl={{ span: 12 }} className="listing-sort__total">
                    { isProcessing && listingList && listingList.length > 0 &&
                      <React.Fragment>
                        { `${listingList.length} Found` }
                      </React.Fragment>
                    }
                    { !isProcessing && !!totalListingsCount &&
                      <React.Fragment>
                        {getAvailableListingsLabel()}
                        { !isProcessing && !isMoreResultsShown &&
                          <React.Fragment>
                            {". "}
                            <span
                              className="listing-list__link"
                              onClick={searchWithExtendedRadius}
                            >Show more</span>
                            
                          </React.Fragment>
                        }
                      </React.Fragment>
                    }
                  </Col>
                  <Col
                    className="listing-list__sort"
                    xs={{ span: 14 }}
                    sm={{ span: 14 }}
                    md={{ span: 14 }}
                    lg={{ span: 14 }}
                    xl={{ span: 6 }}
                  >
                    <SortHotelsList
                      defaultValue="saving"
                      list={listingList}
                      updateList={(listings) => {
                        setListingsList(listings);
                        setCurrentPage(1);
                      }}
                      isProcessing={isProcessing}
                      searchQuery={searchQuery}
                    />
                  </Col>
                  <Col
                    className="listing-list__sort"
                    xs={{ span: 10 }}
                    sm={{ span: 10 }}
                    md={{ span: 10 }}
                    lg={{ span: 10 }}
                    xl={{ span: 6 }}
                  >
                    <FilterHotelsList
                      initialValue={initialQuery}
                      lastSearchParams={searchQuery}
                    />
                  </Col>
                </Row>
                { (!searchQuery.checkInDate) &&
                 <Typography.Title level={2} className="listing-list__date-empty">
                 Please select dates above to see search results
                 </Typography.Title>
                }
                { !!searchQuery.placeId && isProcessing && selectedHotel &&
                !searchHotelChanged && selectedHotel.hotelId && (!listingList.length || !inList()) &&
                  <ListingItem listing={{ ...formattedSelectedHotel, searching: true, searchQuery: searchQuery }} />
                }
                { !!searchQuery.placeId && !!initialLoadDone && !isProcessing && !listingList.length && <Row>
                  <Col span={24} className="listing-list__empty-list-container">
                    <div className="listing-list__empty-list-text">
                      Looks like there are no hotels available for the location, dates or rooms.
                    </div>
                    <div className="listing-list__empty-list-text">
                      Try changing your criteria
                      { !!searchQuery.placeId && !isMoreResultsShown && <React.Fragment>{" "}or <span className="listing-list__empty-list-link" onClick={searchWithExtendedRadius}>search 10 km around the location</span></React.Fragment>}.
                    </div>
                  </Col>
                </Row>
                }
                {
                  isProcessing && !listingList.length && <React.Fragment>
                    {["load-1", "load-2", "load-3"].map(item => {
                      return (
                        <Row key={item} className="listing-list__item listing-list__item--loading">
                          <Col xs={{ span: 24 }} xl={{ span: 10 }} className="listing-list__item-image-wrapper">
                            <div className="listing-list__item-image">
                              <div className="animation-slide-box">
                                <div className="animation-slide-box__inner" />
                              </div>
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} xl={{ span: 14 }} className="listing-list__item-hotel">
                            <div className="listing-list__item-hotel-line">
                              <div className="animation-slide-box">
                                <div className="animation-slide-box__inner" />
                              </div>
                            </div>
                            <div className="listing-list__item-hotel-line">
                              <div className="animation-slide-box">
                                <div className="animation-slide-box__inner" />
                              </div>
                            </div>
                            <div className="listing-list__item-hotel-line">
                              <div className="animation-slide-box">
                                <div className="animation-slide-box__inner" />
                              </div>
                            </div>
                            <div className="listing-list__item-hotel-line">
                              <div className="animation-slide-box">
                                <div className="animation-slide-box__inner" />
                              </div>
                            </div>
                            <div className="listing-list__item-buy">
                              <div className="animation-slide-box">
                                <div className="animation-slide-box__inner" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}

                  </React.Fragment>
                }
                {
                  listingList.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE).map(listing => {
                    return (
                      <div
                        onMouseOut= {() => {
                          if (delayedFlyTo) {
                            clearTimeout(delayedFlyTo)
                          }
                        }}
                        onMouseOver={() => {
                          const mapCoordinates = listing.Hotel &&
                            listing.Hotel.location &&
                            listing.Hotel.location.coordinates;
                          if (mapCoordinates) {
                            if (delayedFlyTo) {
                              clearTimeout(delayedFlyTo)
                            }
                            delayedFlyTo = setTimeout(() => {
                              setSelectedMarker(listing.id);
                              mapRef.current.leafletElement.flyTo([mapCoordinates[1], mapCoordinates[0]], 14);
                              if (markerRefs.current[listing.id]?.leafletElement) {
                                markerRefs.current[listing.id]?.leafletElement.openPopup();
                              }
                            }, 1000)
                          }
                        }}
                        key={`listing-${listing.id}`}>
                        <ListingItem listing={listing} />
                      </div>
                    )
                  })
                }
              </Col>
            </Row>
            {
              listingList.length > PAGE_SIZE &&
              <Row justify="center" align="middle" className="listing-list__pagination">
                <Col span={24} align="center">
                  <Pagination
                    current={currentPage}
                    pageSize={PAGE_SIZE}
                    total={listingList.length}
                    itemRender={itemRender}
                    onChange={handlePaginationChange}
                  />
                </Col>
              </Row>
            }
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }} className={`listing-list__map-container ${!mapView ? "mobile-hidden" : ""}`}>
            <LeafletMap
              ref={mapRef}
              center={listingList.length > 0 ? [coordinates.latitude, coordinates.longitude] :
                (initialQuery.initialLat && initialQuery.initialLon) ?
                  [initialQuery.initialLat, initialQuery.initialLon] :
                  [coordinates.latitude, coordinates.longitude]  }
              bounds={bounds}
              zoom={10}
              minZoom={1}
              maxZoom={14}
              attributionControl={true}
              zoomControl={false}
              doubleClickZoom={true}
              scrollWheelZoom={true}
              dragging={true}
              animate={true}
              easeLinearity={0.35}
              onClick={mapClickHandler}
            >
              <TileLayer
                url={`https://api.mapbox.com/styles/v1/${MAPBOX_USERNAME}/${MAPBOX_STYLE_ID}/tiles/256/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`}
                // url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                // url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"
              />
              {
                listingList.map(item => {
                  if (!item.Hotel) return null;
                  const mapCoordinates = item.Hotel && item.Hotel.location && item.Hotel.location.coordinates;
                  const imageUrl = item.Hotel.HotelImages[0] && item.Hotel.HotelImages[0].imageUrl;
                  let icon = false;
                  if (typeof window !== "undefined" && item.id) {
                    // eslint-disable-next-line global-require
                    const { divIcon, Point } = Leaflet;
                    icon = divIcon({
                      iconSize: new Point(40, 20),
                      iconAnchor: new Point(20, 20),
                      html: item.type === "organic"
                        ? selectedMarker === item.id
                          ? `<div class="listing-map__marker listing-map__marker_resale active">${getCurrencyBadge(item.Seller.currency)}${(+item.sellingPrice).toFixed(2)}</div>`
                          : `<div class="listing-map__marker listing-map__marker_resale">${getCurrencyBadge(item.Seller.currency)}${(+item.sellingPrice).toFixed(2)}</div>`
                        : selectedMarker === item.id
                          ? `<div class="listing-map__marker active">${getCurrencyBadge(item.Seller.currency)}${(+item.sellingPrice).toFixed(2)}</div>`
                          : `<div class="listing-map__marker">${getCurrencyBadge(item.Seller.currency)}${(+item.sellingPrice).toFixed(2)}</div>`,
                      className: item.type === "organic" ? "leaflet-div-icon resale" : "leaflet-div-icon"
                    });
                  }

                  return mapCoordinates && item.id
                    ? (
                      <Marker
                        ref={el => {
                          markerRefs.current[item.id] = el;
                        }}
                        key={`marker-${item.id}`}
                        icon={icon}
                        position={[mapCoordinates[1], mapCoordinates[0]]}
                        onClick={(ev) => markerClickHandler(ev, item.id)}
                        zIndexOffset={selectedMarker === item.id ? 1000 : item.type === "organic" ? 500 : 0}
                        riseOnHover
                      >
                        <Popup closeButton={true} className="listing-map__popup">
                          <Link
                            to={`/listings/${item.slug}${item.type === LISTING_TYPE_B2B ? `?${getB2BSearchParams()}` : ""}`}
                            target="_blank"
                            className="listing-map__popup-link"
                          >
                            <div className="listing-map__popup-image">
                              <ImageBgCover url={imageUrl} height={60} />
                            </div>
                            <div className="listing-map__popup-text">
                              <div className="listing-map__popup-title">{item.Hotel.name}</div>
                              { item.discount > 0 &&
                                <div className="listing-map__popup-discount">
                                  {`Save ${item.discount}%`}
                                </div>
                              }
                            </div>
                          </Link>
                        </Popup>
                      </Marker>
                    )
                    : null;
                })
              }
            </LeafletMap>
            <div className="listing-map__controls">
              <img
                src="/images/icons/plus-solid.svg"
                className="listing-map__zoom-control"
                onClick={() => mapRef.current.leafletElement.zoomIn()}
              />
              <img
                src="/images/icons/minus-solid.svg"
                className="listing-map__zoom-control"
                onClick={() => mapRef.current.leafletElement.zoomOut()}
              />
            </div>
            <div className="listing-map__switch">
              {mapView && <button className="map-switch" onClick={onShowList} >
                <img src="/images/list.svg" alt="location" className="listing-search__place-icon"/>
                List View
              </button>}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

PurchasingListingList.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  listingList: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  totalListingsCount: PropTypes.number.isRequired,
  coordinates: PropTypes.object.isRequired,
  getListingList: PropTypes.func.isRequired,
  setListingsList: PropTypes.func.isRequired,
  cancelListingsSearch: PropTypes.func.isRequired,
  getHotel: PropTypes.func.isRequired,
  selectedHotel: PropTypes.object
};

export default connect(
  state => ({
    listingList: state.listing.listings.data,
    totalListingsCount: state.listing.listings.total,
    coordinates: state.listing.coordinates,
    isProcessing: state.listing.processing,
    selectedHotel: state.listing.singleListing
  }),
  {
    getHotel: getHotelRequest,
    getListingList: getListingListRequest,
    setListingsList: setListingsListAction,
    cancelListingsSearch: cancelListingsSearchAction
  }
)(PurchasingListingList);
