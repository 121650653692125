export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_ARTICLE_LIST_REQUEST = "ADMIN_GET_ARTICLE_LIST_REQUEST";
export const ADMIN_GET_ARTICLE_LIST_REQUEST_SUCCESS = "ADMIN_GET_ARTICLE_LIST_REQUEST_SUCCESS";
export const ADMIN_GET_ARTICLE_LIST_REQUEST_ERROR = "ADMIN_GET_ARTICLE_LIST_REQUEST_ERROR";

export const ADMIN_GET_ARTICLE_REQUEST = "ADMIN_GET_ARTICLE_REQUEST";
export const ADMIN_GET_ARTICLE_REQUEST_SUCCESS = "ADMIN_GET_ARTICLE_REQUEST_SUCCESS";
export const ADMIN_GET_ARTICLE_REQUEST_ERROR = "ADMIN_GET_ARTICLE_REQUEST_ERROR";

export const ADMIN_CREATE_ARTICLE_REQUEST = "ADMIN_CREATE_ARTICLE_REQUEST";
export const ADMIN_CREATE_ARTICLE_REQUEST_SUCCESS = "ADMIN_CREATE_ARTICLE_REQUEST_SUCCESS";
export const ADMIN_CREATE_ARTICLE_REQUEST_ERROR = "ADMIN_CREATE_ARTICLE_REQUEST_ERROR";

export const ADMIN_UPDATE_ARTICLE_REQUEST = "ADMIN_UPDATE_ARTICLE_REQUEST";
export const ADMIN_UPDATE_ARTICLE_REQUEST_SUCCESS = "ADMIN_UPDATE_ARTICLE_REQUEST_SUCCESS";
export const ADMIN_UPDATE_ARTICLE_REQUEST_ERROR = "ADMIN_UPDATE_ARTICLE_REQUEST_ERROR";

export const ADMIN_DELETE_ARTICLE_REQUEST = "ADMIN_DELETE_ARTICLE_REQUEST";
export const ADMIN_DELETE_ARTICLE_REQUEST_SUCCESS = "ADMIN_DELETE_ARTICLE_REQUEST_SUCCESS";
export const ADMIN_DELETE_ARTICLE_REQUEST_ERROR = "ADMIN_DELETE_ARTICLE_REQUEST_ERROR";

export const ADMIN_PUBLISH_ARTICLE_REQUEST = "ADMIN_PUBLISH_ARTICLE_REQUEST";
export const ADMIN_PUBLISH_ARTICLE_REQUEST_SUCCESS = "ADMIN_PUBLISH_ARTICLE_REQUEST_SUCCESS";
export const ADMIN_PUBLISH_ARTICLE_REQUEST_ERROR = "ADMIN_PUBLISH_ARTICLE_REQUEST_ERROR";

export const ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST = "ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST";
export const ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST_SUCCESS = "ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST_SUCCESS";
export const ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST_ERROR = "ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST_ERROR";

export const ADMIN_CLEAR_ARTICLE = "ADMIN_CLEAR_ARTICLE";
export const ADMIN_CLEAR_ARTICLE_ERROR = "ADMIN_CLEAR_ARTICLE_ERROR";
