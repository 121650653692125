import React from "react";
import { Pagination as AntPagination } from "antd";
import PropTypes from "prop-types";

const Pagination = ({
  current,
  pageSize,
  total,
  onChange
}) => {
  const itemRender = (currentPage, type, originalElement) => {
    if (type === "prev") {
      return (
        <a className="ant-pagination-button">
          <img src={`/images/arrow-right${current === 1 ? "-grey" : ""}.svg`} alt="back" style={{ transform: "rotate(180deg)" }}/>
          <span>Back</span>
        </a>
      )
    }

    if (type === "next") {
      return (
        <a className="ant-pagination-button">
          <span>Next</span>
          <img src={`/images/arrow-right${total <= pageSize ? "-grey" : ""}.svg`} alt="next"/>
        </a>
      )
    }

    return originalElement;
  };

  return (
    <div className="pagination">
      <AntPagination
        current={current}
        pageSize={pageSize}
        total={total}
        itemRender={itemRender}
        onChange={onChange}
        showSizeChanger={false}
        responsive={true}
      />
    </div>
  );
};

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Pagination;
