import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Typography } from "antd";
import SellerWalletTabs from "../shared/SellerWalletTabs";

import InternalLink from "../shared/InternalLink"

function SellerAccountLayout({ children, isProfileFilled }) {
  return (
    <div className="seller-account-content">
      <div>
        <Typography.Title>Seller Wallet</Typography.Title>

        <section className="section">
          <SellerWalletTabs/>
        </section>

        <div>
          {children}
        </div>
      </div>
      <Modal
        className="modal"
        visible={!isProfileFilled}
        closable={false}
        footer={null}
      >
        <div className="seller-account-restrict-access__modal-body">
          <img src="/images/danger.svg" alt="danger"/>
          <div className="seller-account-restrict-access__modal-text">
            Fill in “User profile” section first
          </div>
          <InternalLink to="/profile">Go back</InternalLink>
        </div>
      </Modal>
    </div>
  );
}

SellerAccountLayout.propTypes = {
  children: PropTypes.any,
  isProfileFilled: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    isProfileFilled: state.profile.permissions.isProfileFilled
  })
)(SellerAccountLayout);
