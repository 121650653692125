import * as types from "./faqActionsTypes";

export const createQuestionRequest = (data) => {
  return { type: types.ADMIN_CREATE_QUESTION_REQUEST, data };
};

export const updateQuestionRequest = (data) => {
  return { type: types.ADMIN_UPDATE_QUESTION_REQUEST, data };
};

export const getQuestionRequest = (data) => {
  return { type: types.ADMIN_GET_QUESTION_REQUEST, data };
};

export const getQuestionsRequest = (data) => {
  return { type: types.ADMIN_GET_QUESTIONS_REQUEST, data };
};

export const deleteQuestionRequest = (data) => {
  return { type: types.ADMIN_DELETE_QUESTION_REQUEST, data };
};

export const getCategoriesRequest = (data) => {
  return { type: types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST, data };
};

export const setCategoriesAction = (data) => {
  return { type: types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_SUCCESS, response: data };
};

export const updateCategoriesOrderRequest = (data) => {
  return { type: types.ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST, data };
};

export const createCategoryRequest = (data) => {
  return { type: types.ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST, data };
};

export const deleteCategoryRequest = (data) => {
  return { type: types.ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST, data };
};

export const getCategoryRequest = (data) => {
  return { type: types.ADMIN_GET_QUESTIONS_CATEGORY_REQUEST, data };
};

export const updateQuestionsOrderRequest = (data) => {
  return { type: types.ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST, data };
};

export const resetQuestionsOrderRequest = (data) => {
  return { type: types.ADMIN_RESET_QUESTIONS_ORDER_REQUEST, data };
};

export const setQuestionsAction = (data) => {
  return { type: types.ADMIN_GET_QUESTIONS_REQUEST_SUCCESS, response: data };
};

export const clearCategoryErrorAction = () => {
  return { type: types.ADMIN_CLEAR_QUESTIONS_CATEGORY_ERROR };
};

export const clearQuestionAction = () => {
  return { type: types.ADMIN_CLEAR_QUESTION };
};

export const clearQuestionErrorAction = () => {
  return { type: types.ADMIN_CLEAR_QUESTION_ERROR };
};
