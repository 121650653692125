import React, { useMemo } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { Col, Row } from "antd";

import FaqAccordion from "../Faq/FaqAccordion";

const divideArray = arr => {
  const halfwayThrough = Math.ceil(arr.length / 2);

  const firstHalf = arr.slice(0, halfwayThrough);
  const secondHalf = arr.slice(halfwayThrough, arr.length);

  return [firstHalf, secondHalf];
};

const renderColumn = (data = []) => {
  return (
    <Row gutter={[16, 16]}>
      {data.map(question => {
        return (
          <Col xs={{ span: 24 }} key={question.id}>
            <FaqAccordion defaultOpen={false} answer={parse(question.content)} question={question.title}/>
          </Col>
        );
      })}
    </Row>
  );
};

const FaqsList = ({ faqs = [] }) => {
  const [firstFaqs, secondFaqs] = useMemo(() => divideArray(faqs), [faqs]);

  return (
    <div className="faqs-list">
      <Row gutter={[16, 16]}>
        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
          {renderColumn(firstFaqs)}
        </Col>
        <Col sm={{ span: 24 }} lg={{ span: 12 }}>
          {renderColumn(secondFaqs)}
        </Col>
      </Row>
    </div>
  );
};

FaqsList.propTypes = {
  faqs: PropTypes.array.isRequired
};

export default FaqsList;
