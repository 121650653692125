import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Select, InputNumber, Button, Input, Rate, Collapse } from "antd";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import * as yup from "yup";

import BackLink from "../../shared/BackLink";
import { getDisputeRequest, updateDisputeRequest, createDisputeNoteRequest } from "../../../redux/admin/dispute/disputeActions";
import { showModalAction } from "../../../redux/general/generalActions";
import { createHotelRoomsText } from "../../../helpers/common";
import { ISO31661ALPHA2 } from "../../../api/constants";
import { ImageBgCover } from "../../shared/SimpleComponents/ImageBgCover/ImageBgCover";
import TextAreaWithLabel from "../../shared/TextAreaWithLabel";

const noteSchema = yup.object().shape({
  text: yup.string().required("Dispute note can not be empty")
});

const Dispute = ({
  dispute,
  notes,
  isProcessing,
  match,
  history,
  getDispute,
  updateDispute,
  createDisputeNote,
  showModal
}) => {
  const { id } = match.params;
  const buyer = dispute.Listing && dispute.Listing.Buyer;
  const seller = dispute.Listing && dispute.Listing.Seller;
  const sellingPrice = parseFloat(dispute.Listing && dispute.Listing.sellingPrice) || 0;

  const [resolution, setResolution] = useState("");
  const [priceError, setPriceError] = useState("");
  const [resolutionError, setResolutionError] = useState("");
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [disputeProps, setDisputeProps] = useState({
    status: null,
    buyerRefund: null,
    sendToSeller: null,
  });
  const showSellerField = ["partially-accepted"].includes(disputeProps.status);
  const showBuyerField = ["accepted", "partially-accepted"].includes(disputeProps.status);
  const showResolutionNote = !["raised", "in-review"].includes(disputeProps.status);

  const { handleSubmit, errors, control } = useForm({
    validationSchema: noteSchema
  });

  useEffect(() => {
    getDispute({ id });
  }, [id]);

  useEffect(() => {
    setDisputeProps({
      status: dispute.status,
      buyerRefund: dispute.buyerRefund,
      sendToSeller: dispute.sendToSeller,
    });
    setResolution(dispute.resolutionNote);
  }, [dispute]);

  const onChangeStatus = data => {
    setDisputeProps({
      status: data,
      sendToSeller: data === "rejected" ? sellingPrice : null,
    });
  };

  const onSubmitHandler = () => {
    if (showSellerField && !disputeProps.buyerRefund) {
      return setPriceError("Need to set refund for buyer");
    }
    if (showResolutionNote && !resolution) {
      return setResolutionError("Resolution can't be empty.")
    }

    showModal({
      type: "confirm",
      title: "Are you sure you would like to update a dispute status?",
      params: {
        icon: "/images/info.svg",
        confirmButtonText: "Yes",
        confirmButtonClassName: "button--primary",
        isProcessing,
        showLoader: true,
        confirmAction: () => {
          if (dispute.status === "in-review" && disputeProps.status === "raised") {
            showModal({
              type: "error",
              title: "Dispute status can not be changed to 'Raised'"
            });
          } else {
            updateDispute({ id: dispute.id, body: { ...disputeProps, resolutionNote: resolution } });
          }
        }
      }
    });
  };

  const createNoteHandler = (data) => {
    setShowNoteForm(false);
    createDisputeNote({ id: dispute.id, body: { text: data.text } });
  };

  const renderListing = (listing, hotel) => {
    const imageUrl = hotel.HotelImages[0] && hotel.HotelImages[0].imageUrl;
    const hotelFacilities = hotel.facilities ? hotel.facilities : [];
    const hotelListingfacilities = hotelFacilities.concat(listing.facilities ? listing.facilities : [])
    const facilities = hotelListingfacilities.concat(listing.facilitiesOther ? [listing.facilitiesOther] : []).join(", ");
    const hotelAddress = listing.Hotel && listing.Hotel.HotelAddress ? listing.Hotel.HotelAddress : {};
    const country = ISO31661ALPHA2.find(item => item.code === hotelAddress.country);

    return (
      <div className="seller-listings__item pointer" onClick={() => history.push(`/listings/${listing.slug}`)}>
        <Row gutter={32}>
          <Col xs={{ span: 24 }} md={{ span: 8 }} style={{ maxWidth: "190px" }}>
            <div className="seller-listings__item-image">
              <ImageBgCover url={imageUrl} height={100} iconSize="medium" />
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 16 }}>
            <Row className="seller-listings__item-ref">
              {`Ref. no. ${listing.id}`}
            </Row>
            <Row className="seller-listings__item-hotel-name">
              {hotel.name}
            </Row>
            <Row>
              {
                listing.hotelRating &&
                <div className="seller-listings__item-hotel-rating">
                  <Rate disabled count={listing.hotelRating} defaultValue={listing.hotelRating} />
                </div>
              }
            </Row>
            <Row className="listing-view__hotel-data">
              <div>
                {
                  country &&
                  <div>
                    <img src="/images/location.svg" alt="location" />
                    <span>{`${country.name}, ${hotelAddress.cityName}`}</span>
                  </div>
                }
                <div>
                  <img src="/images/calendar.svg" alt="calendar" />
                  <span>{`${listing.checkInDate} - ${listing.checkOutDate}`}</span>
                </div>
                <div>
                  <img src="/images/guests.svg" alt="guests" />
                  <span>{createHotelRoomsText(listing.rooms, listing.adults, listing.children)}</span>
                </div>
                { listing.roomType &&
                  <div className="seller-listings__item-room-type">
                    {listing.roomType}
                    { listing.roomTypeOther &&
                      <span> - {listing.roomTypeOther}</span>
                    }
                  </div>
                }
                <div className="seller-listings__item-facilities">
                  {facilities}
                </div>
                <div className={`seller-listings__item-price${listing.Seller && listing.Seller.currency === "USD" ? " seller-listings__item-price--usd" : ""}`}>
                  {
                    listing.Seller && listing.Seller.currency === "USD"
                      ? <img src="/images/usd.svg" alt="dollar" />
                      : <img src="/images/gbp.svg" alt="pound" />
                  }
                  <span>{(+listing.sellingPrice).toFixed(2)}</span>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="dispute">
      <Row>
        <Col span={24} className="feedback__back-link-container">
          <BackLink label="Back to management" url="/admin/disputes" />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="form-section">
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div className="dispute__title">{`Dispute Ref. ${dispute.id}`}</div>
              <div className="dispute__text">
                <span>Opened by:</span>
                <b>{dispute.Listing && dispute.Listing.buyerId === dispute.authorId ? "Buyer" : "Seller"}</b>
              </div>
              <div className="dispute__text">
                <span>Date:</span>
                <b>{dispute.createdAt ? moment(dispute.createdAt).format("DD MMM YYYY") : ""}</b>
              </div>
              <div className="dispute__text">
                <span>Reasons:</span>
                <b>{dispute.reasons && dispute.reasons.length && dispute.reasons.join(", ")}</b>
              </div>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Row gutter={24}>
                <Col span={12} className="dispute__user buyer">
                  {
                    buyer &&
                      <Fragment>
                        <Row className="dispute__user-title">Buyer</Row>
                        <Row className="pointer" onClick={() => history.push(`/admin/user-management/users/${buyer.id}`)}>
                          <div><img src={buyer.avatarUrl ? buyer.avatarUrl : "/images/noun_avatar.svg"} alt="avatar" /></div>
                          <div className="dispute__user-text">
                            <div className="dispute__text">
                              <b>{`${buyer.firstName} ${buyer.lastName}`}</b>
                            </div>
                            <div className="dispute__text">
                              <span>User from:</span>
                              <b>{buyer.createdAt ? moment(buyer.createdAt).format("DD MMM YYYY") : ""}</b>
                            </div>
                            <div className="dispute__text">
                              <span>Last activity:</span>
                              <b>Today</b>
                            </div>
                          </div>
                        </Row>
                      </Fragment>
                  }
                </Col>
                <Col span={12} className="dispute__user seller">
                  {
                    seller &&
                      <Fragment>
                        <Row className="dispute__user-title">Seller</Row>
                        <Row className="pointer" onClick={() => history.push(`/admin/user-management/users/${seller.id}`)}>
                          <div><img src={seller.avatarUrl ? seller.avatarUrl : "/images/noun_avatar.svg"} alt="avatar" /></div>
                          <div className="dispute__user-text">
                            <div className="dispute__text">
                              <b>{`${seller.firstName} ${seller.lastName}`}</b>
                            </div>
                            <div className="dispute__text">
                              <span>User from:</span>
                              <b>{seller.createdAt ? moment(seller.createdAt).format("DD MMM YYYY") : ""}</b>
                            </div>
                            <div className="dispute__text">
                              <span>Last activity:</span>
                              <b>Today</b>
                            </div>
                          </div>
                        </Row>
                      </Fragment>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {
          dispute.Listing && dispute.Listing.Hotel && renderListing(dispute.Listing, dispute.Listing.Hotel)
        }
      </Row>
      <Row gutter={32}>
        <Col xs={{ span: 24 }} md={{ span: 16 }}>
          <Row className="form-section">
            <Col span={24}>
              <Row>
                <div>
                  <div className="form-section__title">Request details</div>
                  <div>{dispute.description}</div>
                </div>
              </Row>
              <Row>
                <div className="listing-view__documents-wrapper">
                  <span className="form-section__label">Attached document</span>
                  <div className="listing-view__documents">
                    {
                      dispute.DisputeDocuments && dispute.DisputeDocuments.length
                        ? dispute.DisputeDocuments.map(file => (
                          <a href={file.fileUrl} download key={file.id} className="listing-view__documents-item">
                            <img src="/images/file.svg" alt="file" />
                            <span>{file.fileName}</span>
                          </a>
                        ))
                        : null
                    }
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
          <form>
            <Row className="form-section">
              <Col span={24}>
                <Row>
                  <div className="form-section__title">Resolution</div>
                </Row>
                <Row>
                  <div className="form-section__label">Set status</div>
                  <div className="form-section__field">
                    <Select
                      className="dispute-list__filter-status"
                      value={disputeProps.status}
                      disabled={isProcessing || ["accepted", "partially-accepted", "rejected"].includes(dispute.status)}
                      onChange={onChangeStatus}
                    >
                      <Select.Option value="raised">Raised</Select.Option>
                      <Select.Option value="in-review">In review</Select.Option>
                      <Select.Option value="accepted">Accepted</Select.Option>
                      <Select.Option value="partially-accepted">Partially accepted</Select.Option>
                      <Select.Option value="rejected">Rejected</Select.Option>
                    </Select>
                  </div>
                </Row>
                <Row>
                  <Col span={24} className="dispute__price-wrapper">
                    <div className={`form-section__field${showBuyerField ? "" : " hidden"}`}>
                      <label htmlFor="originalPrice" className="form-section__label">Refund for buyer</label>
                      <InputNumber
                        id="buyerRefund"
                        disabled={["accepted", "partially-accepted", "rejected"].includes(dispute.status)}
                        min={0}
                        max={sellingPrice}
                        precision={2}
                        decimalSeparator="."
                        value={disputeProps.buyerRefund}
                        className={`form-section__input${priceError ? " form-section__input--error" : ""}`}
                        onChange={data => {
                          setPriceError("");
                          setDisputeProps({
                            ...disputeProps,
                            buyerRefund: data,
                            sendToSeller: sellingPrice - data
                          });
                        }}
                      />
                      {priceError && <span className="profile__error">{priceError}</span>}
                    </div>
                    <div className={`form-section__field${showSellerField ? "" : " hidden"}`}>
                      <label htmlFor="sendToSeller" className="form-section__label">Send to seller</label>
                      <InputNumber
                        id="sendToSeller"
                        disabled={true}
                        min={0}
                        max={sellingPrice}
                        precision={2}
                        decimalSeparator="."
                        value={disputeProps.sendToSeller}
                        className="form-section__input"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className={`form-section__field${showResolutionNote ? "" : " hidden"}`}>
                    <div className="form-section__label">Write your resolution</div>
                    <Input.TextArea
                      id="resolutionNote"
                      rows={1}
                      value={resolution}
                      className={`form-section__input${resolutionError ? " form-section__input--error" : ""}`}
                      disabled={["accepted", "partially-accepted", "rejected"].includes(dispute.status)}
                      onChange={event => {
                        setResolutionError("");
                        setResolution(event.target.value);
                      }}
                    />
                    {
                      resolutionError
                        ? <span className="profile__error">{resolutionError}</span>
                        : <div className="form-section__label">Your resolution will be sent to the Buyer and Seller</div>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Button
                htmlType="button"
                disabled={isProcessing || (dispute.status === disputeProps.status &&
                  dispute.buyerRefund === disputeProps.buyerRefund &&
                  dispute.sendToSeller === disputeProps.sendToSeller) ||
                  ["accepted", "partially-accepted", "rejected"].includes(dispute.status)}
                className="button button--primary dispute__submit-button"
                onClick={onSubmitHandler}
              >
                Save and send
              </Button>
            </Row>
          </form>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }} className="dispute__add-note">
          {
            showNoteForm
              ? (
                <form onSubmit={handleSubmit(createNoteHandler)}>
                  <Row className="dispute__user-title">Notes</Row>
                  <Row>
                    <Col span={24}>
                      <TextAreaWithLabel
                        name="text"
                        control={control}
                        id="text"
                        error={errors.text}
                        placeholder="Write your note here..."
                        rows={5}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Button htmlType="submit" className="button button--primary" disabled={isProcessing}>Save</Button>
                    </Col>
                  </Row>
                </form>
              )
              : (
                <Row>
                  <Button htmlType="button" className="button button--default" onClick={() => setShowNoteForm(true)}>
                    Add note
                  </Button>
                </Row>
              )
          }
          <Row>
            <Col span={24}>
              <Collapse
                accordion
                bordered={false}
                expandIconPosition="right"
              >
                { notes.map(row => {
                  return (
                    <Collapse.Panel header={row.date} key={row.date} className="profile-history__list-item">
                      {row.notes.map(item => {
                        return (
                          <Row key={item.id} align="middle" className="profile-history__list-item-field">
                            <Col span={24}>
                              {item.text}
                            </Col>
                          </Row>
                        );
                      })}
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
};

Dispute.propTypes = {
  dispute: PropTypes.object.isRequired,
  notes: PropTypes.array.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getDispute: PropTypes.func.isRequired,
  updateDispute: PropTypes.func.isRequired,
  createDisputeNote: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    dispute: state.admin.dispute.singleDispute,
    notes: state.admin.dispute.singleDisputeNotes,
    requestSuccess: state.admin.dispute.requestSuccess,
    isProcessing: state.admin.dispute.processing,
  }),
  {
    getDispute: getDisputeRequest,
    updateDispute: updateDisputeRequest,
    createDisputeNote: createDisputeNoteRequest,
    showModal: showModalAction
  }
)(Dispute);
