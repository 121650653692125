import * as types from "./profileActionsTypes";

export const accountVerificationRequest = data => {
  return { type: types.ACCOUNT_VERIFICATION_REQUEST, data };
};

export const clearProfileErrorAction = () => {
  return { type: types.CLEAR_PROFILE_ERROR };
};

export const getProfilePermissionsRequest = () => {
  return { type: types.PROFILE_PERMISSIONS_REQUEST };
};

export const kycVerificationRequest = data => {
  return { type: types.KYC_VERIFICATION_REQUEST, data };
};

export const changePasswordRequest = data => {
  return { type: types.CHANGE_PASSWORD_REQUEST, data };
};

export const changeEmailRequest = data => {
  return { type: types.CHANGE_EMAIL_REQUEST, data };
};

export const closeAccountRequest = () => {
  return { type: types.CLOSE_ACCOUNT_REQUEST };
};

export const createBankAccountRequest = data => {
  return { type: types.CREATE_BANK_ACCOUNT_REQUEST, data };
};

export const getBankAccountsRequest = () => {
  return { type: types.GET_BANK_ACCOUNTS_REQUEST };
};

export const deleteBankAccountRequest = data => {
  return { type: types.DELETE_BANK_ACCOUNT_REQUEST, data };
};

export const updateBankAccountRequest = data => {
  return { type: types.UPDATE_BANK_ACCOUNT_REQUEST, data };
};

export const getBalanceRequest = () => {
  return { type: types.GET_BALANCE_REQUEST };
};

export const withdrawMoneyRequest = data => {
  return { type: types.WITHDRAW_MONEY_REQUEST, data };
};

export const getTransactionsRequest = data => {
  return { type: types.GET_TRANSACTIONS_REQUEST, data };
};

export const showKycValidationForm = (show) => {
  return { type: types.SHOW_KYC_VALIDATION_FORM, payload: show };
};

export const clearValidationSendMessage = () => {
  return { type: types.CLEAR_VALIDATION_SEND_MESSAGE }
}

export const createAccountVerificationIntentRequest = data => {
  return { type: types.CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST, data };
};
