import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./financeActionsTypes";
import * as generalTypes from "../../general/generalActionsTypes";
import finance from "../../../api/admin/finance";
import { MODAL_TYPE_ERROR } from "../../../react/constants";

function *getChargeOptions () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.getChargeOptions);
    yield put({ type: types.ADMIN_GET_CHARGE_OPTIONS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_CHARGE_OPTIONS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateChargeOptions ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.updateChargeOptions, data);
    yield put({ type: types.ADMIN_UPDATE_CHARGE_OPTION_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_CHARGE_OPTION_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *setDefaultCommission () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.setDefaultCommission);
    yield put({ type: types.ADMIN_SET_DEFAULT_COMMISSION_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_SET_DEFAULT_COMMISSION_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getFeeConfigurations () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.getFeeConfigurations);
    yield put({ type: types.ADMIN_GET_FEE_CONFIGURATIONS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_FEE_CONFIGURATIONS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateFeeConfiguration ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.updateFeeConfiguration, data);
    yield put({ type: types.ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getListingsForAccounting ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.getListingsForAccounting, data);
    yield put({ type: types.ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getListingsForAccountingCsvExportUrl ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.getListingsForAccountingCsvExportUrl, data);
    if (response.fileUrl) {
      const a = document.createElement("a");
      a.href = response.fileUrl;
      a.download = response.fileUrl;
      a.click();
    }
  } catch (error) {
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getPaymentFeesConfigurations () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.getPaymentFeesConfigurations);
    yield put({ type: types.ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updatePaymentFeesConfiguration ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(finance.updatePaymentFeesConfiguration, data);
    yield put({ type: types.ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_CHARGE_OPTIONS_REQUEST, getChargeOptions),
    yield takeLatest(types.ADMIN_UPDATE_CHARGE_OPTION_REQUEST, updateChargeOptions),
    yield takeLatest(types.ADMIN_SET_DEFAULT_COMMISSION_REQUEST, setDefaultCommission),
    yield takeLatest(types.ADMIN_GET_FEE_CONFIGURATIONS_REQUEST, getFeeConfigurations),
    yield takeLatest(types.ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST, updateFeeConfiguration),
    yield takeLatest(types.ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST, getListingsForAccounting),
    yield takeLatest(
      types.ADMIN_GET_LISTINGS_FOR_ACCOUNTING_CSV_EXPORT_URL_REQUEST,
      getListingsForAccountingCsvExportUrl
    ),
    yield takeLatest(types.ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST, getPaymentFeesConfigurations),
    yield takeLatest(types.ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST, updatePaymentFeesConfiguration),
  ])
}
