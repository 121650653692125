import React, { useState } from "react";
import { Button, Col, Input, Row } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { clearAuthErrorAction, resendActivationEmailRequest, verifyAccountRequest } from "../../redux/auth/authActions";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import ActivateEmail from "./ActivateEmail";
import Label from "../shared/Form/Label";

const AccountVerifySchema = yup.object().shape({
  email: yup.string().email("Please provide correct email").required("Email can not be empty")
});

export const AccountVerify = ({
  isProcessing,
  match,
  history,
  isVerificationSuccessful,
  isVerificationEmailSent,
  isAuthenticated,
  error,
  verifyAccount,
  resendActivationEmail,
  clearError
}) => {
  const { token } = match.params;
  const [email, setEmail] = useState(null);

  const { handleSubmit, errors, control } = useForm({
    validationSchema: AccountVerifySchema
  });

  useServerSafeEffect(() => {
    verifyAccount({ token });
  }, []);

  const resendEmailHandler = (data) => {
    resendActivationEmail({
      ...data,
      slug: "auth/verify"
    });
  };

  if (isVerificationSuccessful) {
    history.push(isAuthenticated ? "/" : "/auth/sign-in", { from: { pathname: "/" } });
  }

  if (isVerificationEmailSent) {
    return <ActivateEmail email={email} verifyAccountUrl="auth/verify"/>;
  }

  if (isVerificationSuccessful === false) {
    return (
      <Row justify="space-around" align="middle" className="auth-row">
        <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 8 }}>
          <div className="account-verify">
            <h1 className="account-verify__title">Activate your account</h1>

            <div className="account-verify__subtitle">
              Time for account verification has expired. Please enter your email below to verify yourself
            </div>

            <form onSubmit={handleSubmit(resendEmailHandler)} className="account-verify__form form-container">
              <div className="account-verify__field">
                <Label htmlFor="account-verify-email">Email</Label>
                <Controller
                  name="email"
                  as={<Input
                    id="account-verify-email"
                    className={`form-input ${errors.email ? "form-input--error" : ""}`}
                    data-testid="account-verify-email"
                  />}
                  control={control}
                  onChange={([event]) => {
                    const value = event.target.value;

                    clearError();
                    setEmail(value);

                    return value;
                  }}
                />
                {(error || errors.email) && <span
                  className="account-verify__error"
                  data-testid="account-verify-error"
                >{error || errors.email.message}</span>}
              </div>

              <Button
                htmlType="submit"
                disabled={isProcessing}
                type="primary"
                className="submit-button"
                data-testid="account-verify-submit"
                block
              >
                Send verification email
              </Button>
            </form>
          </div>
        </Col>
      </Row>
    );
  }

  return "";
};

AccountVerify.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  verifyAccount: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  resendActivationEmail: PropTypes.func.isRequired,
  isVerificationSuccessful: PropTypes.bool,
  isVerificationEmailSent: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  error: PropTypes.string,
  clearError: PropTypes.func.isRequired
};

export default withRouter(connect(
  state => ({
    isProcessing: state.auth.processing,
    isVerificationRequired: state.auth.isVerificationRequired,
    isVerificationSuccessful: state.auth.isVerificationSuccessful,
    isVerificationEmailSent: state.auth.isVerificationEmailSent,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error
  }),
  {
    verifyAccount: verifyAccountRequest,
    resendActivationEmail: resendActivationEmailRequest,
    clearError: clearAuthErrorAction
  }
)(AccountVerify));
