import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

const CancellationPaymentMessage = ({
  isRefundable,
  guaranteeType,
  depositPolicy,
  cancellationPolicy,
}) => {
  if (!isRefundable) {
    return (
      <Row className="listing-details__row cancellation-payment-message">
        <Col span={24}>
          <div>Non refundable</div>
          <div>No changes allowed. No refund if you cancel.</div>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="cancellation-payment-message">
      <Col span={24}>
        <Row className="listing-details__row">
          <Col span={24} className="cancellation-payment-message__free-cancellation">
            <img src="/images/icons/check-pink.svg" alt="check"/>
            <div>Free cancellation</div>
          </Col>
        </Row>
        { guaranteeType === "Deposit" &&
          <Row className="listing-details__row">
            <Col span={24}>
              <div className="cancellation-payment-message__deposit-required">Deposit required</div>
              { depositPolicy &&
                <div>{depositPolicy}</div>
              }
            </Col>
          </Row>
        }
        { cancellationPolicy &&
          <Row className="listing-details__row">
            <Col span={24}>
              <div>{cancellationPolicy}</div>
            </Col>
          </Row>
        }
      </Col>
    </Row>
  )
};

CancellationPaymentMessage.propTypes = {
  isRefundable: PropTypes.bool,
  guaranteeType: PropTypes.string,
  depositPolicy: PropTypes.string,
  cancellationPolicy: PropTypes.string
};

export default CancellationPaymentMessage;
