import * as types from "./authActionsTypes";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const token = cookies.get("authToken");
const user = cookies.get("authUser");
const loggedIn = cookies.get("loggedIn");

export const initialState = {
  user: {
    id: user ? user.id : null,
    firstName: user ? user.first_name : "",
    lastName: user ? user.last_name : "",
    email: user ? user.email : "",
    loggedIn: loggedIn ? loggedIn : ""
  },
  isAuthenticated: !!token,
  processing: false,
  error: "",
  isTerminated: false,
  isSuspended: false,
  isVerificationRequired: false,
  isVerificationSuccessful: null,
  isVerificationEmailSent: false,
  isResetPasswordEmailSent: false,
  isNewPasswordSet: false,
  verifiedEmail: null
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.SIGN_IN_REQUEST_SUCCESS:
      return {
        ...state,
        user: {
          id: action.response.id || null,
          firstName: action.response.first_name || "",
          lastName: action.response.last_name || "",
          email: action.response.email || "",
          loggedIn: state.user.loggedIn
        },
        isAuthenticated: true,
        error: "",
        isTerminated: false,
        isSuspended: false
      };
    case types.SIGN_IN_REQUEST_ERROR:
      return {
        ...state,
        error: (action.error.data && action.error.data.deletedAt) || action.error.message || "",
        isTerminated: (action.error.data && action.error.data.terminated) || false,
        isSuspended: (action.error.data && action.error.data.suspended) || false
      };
    case types.SIGN_OUT_REQUEST_SUCCESS:
      return {
        ...initialState,
        user: {
          id: null,
          firstName: "",
          lastName: "",
          email: "",
          loggedIn: ""
        },
        isAuthenticated: false
      };
    case types.SIGN_OUT_REQUEST_ERROR:
      return {
        ...initialState,
        isAuthenticated: true
      };
    case types.SIGN_UP_REQUEST_SUCCESS:
      return {
        ...state,
        isVerificationRequired: true
      };
    case types.SIGN_UP_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.CLEAR_VERIFY_ACCOUNT_STATUS:
      return {
        ...state,
        isVerificationRequired: false,
        verifiedEmail: null
      };
    case types.VERIFY_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        isVerificationRequired: false,
        isVerificationSuccessful: true,
        verifiedEmail: action.response.data.user.email
      };
    case types.VERIFY_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        isVerificationSuccessful: false
      };
    case types.RESEND_ACTIVATION_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        isTerminated: false,
        isSuspended: false,
        isVerificationEmailSent: true
      };
    case types.RESEND_ACTIVATION_EMAIL_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.CLEAR_AUTH_ERROR:
      return {
        ...state,
        error: "",
        isTerminated: false,
        isSuspended: false
      };
    case types.RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        isResetPasswordEmailSent: true
      };
    case types.RESET_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.CLEAR_RESET_PASSWORD_EMAIL_STATUS:
      return {
        ...state,
        isResetPasswordEmailSent: false
      };
    case types.SET_NEW_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        isNewPasswordSet: true
      };
    case types.CLEAR_SET_NEW_PASSWORD_STATUS:
      return {
        ...state,
        isNewPasswordSet: false
      };
    default:
      return state;
  }
};

export default auth;
