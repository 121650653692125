export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST = "ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST";
export const ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST_SUCCESS = "ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST_SUCCESS";
export const ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST_ERROR = "ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST_ERROR";

export const ADMIN_DELETE_SUBSCRIPTION_REQUEST = "ADMIN_DELETE_SUBSCRIPTION_REQUEST";
export const ADMIN_DELETE_SUBSCRIPTION_REQUEST_SUCCESS = "ADMIN_DELETE_SUBSCRIPTION_REQUEST_SUCCESS";
export const ADMIN_DELETE_SUBSCRIPTION_REQUEST_ERROR = "ADMIN_DELETE_SUBSCRIPTION_REQUEST_ERROR";

export const ADMIN_CLEAR_SUBSCRIPTIONS_ERROR = "ADMIN_CLEAR_SUBSCRIPTIONS_ERROR";

export const ADMIN_GET_SUBSCRIPTIONS_CSV_EXPORT_URL_REQUEST = "ADMIN_GET_SUBSCRIPTIONS_CSV_EXPORT_URL_REQUEST";
