import { all, put, call, takeLatest } from "redux-saga/effects";

import highlights from "../../../api/admin/highlights";
import * as types from "./highlightsActionsTypes";
import * as generalTypes from "../../general/generalActionsTypes";
import { MODAL_TYPE_SUCCESS } from "../../../react/constants";

function *getHighlights ({ data }) {
  yield put({ type: types.ADMIN_HIGHLIGHTS_SHOW_LOADING });

  try {
    const response = yield call(highlights.getHighlights, data);
    yield put({ type: types.ADMIN_GET_HIGHLIGHTS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_HIGHLIGHTS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIGHLIGHTS_HIDE_LOADING })
  }
}

function *deleteHighlight ({ data }) {
  yield put({ type: types.ADMIN_HIGHLIGHTS_SHOW_LOADING });

  try {
    const response = yield call(highlights.deleteHighlight, data);
    yield put({ type: types.ADMIN_DELETE_HIGHLIGHT_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_GET_HIGHLIGHTS_REQUEST });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_HIGHLIGHT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIGHLIGHTS_HIDE_LOADING })
  }
}

function *addHighlight ({ data, callBack }) {
  yield put({ type: types.ADMIN_HIGHLIGHTS_SHOW_LOADING });

  try {
    const response = yield call(highlights.addHighlight, data);
    yield put({ type: types.ADMIN_ADD_HIGHLIGHT_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Highlight added successfully"
      }
    });

    if (typeof callBack === "function") {
      yield call(callBack, response.id);
    }
  } catch (error) {
    console.error('addHighlight.error', error)
    yield put({ type: types.ADMIN_ADD_HIGHLIGHT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIGHLIGHTS_HIDE_LOADING })
  }
}

function *getHighlight ({ data }) {
  yield put({ type: types.ADMIN_HIGHLIGHTS_SHOW_LOADING });

  try {
    const response = yield call(highlights.getHighlight, data);
    yield put({ type: types.ADMIN_GET_HIGHLIGHT_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_HIGHLIGHT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIGHLIGHTS_HIDE_LOADING })
  }
}

function *updateHighlight ({ data }) {
  yield put({ type: types.ADMIN_HIGHLIGHTS_SHOW_LOADING });

  try {
    const response = yield call(highlights.updateHighlight, data);
    yield put({ type: types.ADMIN_UPDATE_HIGHLIGHT_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Highlight updated successfully",
      }
    });
  } catch (error) {
    console.error("sagas.updateHighlight.error", error);
    yield put({ type: types.ADMIN_UPDATE_HIGHLIGHT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIGHLIGHTS_HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_HIGHLIGHTS_REQUEST, getHighlights),
    yield takeLatest(types.ADMIN_DELETE_HIGHLIGHT_REQUEST, deleteHighlight),
    yield takeLatest(types.ADMIN_ADD_HIGHLIGHT_REQUEST, addHighlight),
    yield takeLatest(types.ADMIN_GET_HIGHLIGHT_REQUEST, getHighlight),
    yield takeLatest(types.ADMIN_UPDATE_HIGHLIGHT_REQUEST, updateHighlight),
  ])
}
