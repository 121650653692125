import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Croppie from "croppie";
import { Row, Col, Button } from "antd";
import debounce from "lodash/debounce";
import { v4 as uuidv4 } from "uuid";
import "croppie/croppie.css";

export const CropExperienceImageModalBody = ({ cropImageUrl, onCancel, onCrop }) => {
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState("");

  let cropper = null;

  useEffect(() => {
    cropper = new Croppie(cropperRef.current, {
      viewport: {
        width: 995,
        height: 435
      },
      enableExif: true,
      customClass: "crop-experience-image-modal__cropper-container"
    });

    cropper.bind({
      url: cropImageUrl
    });

    cropperRef.current.addEventListener("update", () => {
      cropper.result({ type: "blob" })
        .then(debounce(data => {
          data.lastModifiedDate = new Date();
          data.name = `${uuidv4}.png`;

          setCroppedImage({
            url: URL.createObjectURL(data),
            file: data
          });
        }, 300));
    });

    return () => {
      cropper.destroy();
    }
  }, []);

  return (
    <Row>
      <Col span={24} className="crop-experience-image-modal__cropper-column">
        <Row>
          <Col span={24} className="crop-experience-image-modal__title">
            Crop image
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div ref={cropperRef} />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="crop-experience-image-modal__buttons">
            <Button
              type="default"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => onCrop(croppedImage.url, croppedImage.file)}
            >
              Crop
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CropExperienceImageModalBody.propTypes = {
  cropImageUrl: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired
};

export default CropExperienceImageModalBody;
