export const reviews = [
  {
    name: "Megan",
    location: "London",
    text: "I couldn't use my booking so listed it on PlansChange. It took just a few minutes of my time, sold really quickly and I got some money back. Very happy!",
    rating: 5
  },
  {
    name: "Jake",
    location: "Manchester",
    text: "Selling my hotel booking was easy and hassle-free. Money was in my account 24 hours after the checkout date. Thanks PlansChange.",
    rating: 5
  },
  {
    name: "Dawn",
    location: "Leeds",
    text: "Too late for a refund so I decided to sell my hotel booking through PlansChange at a discounted price. The whole process was very easy and it was such a relief to get my money back.",
    rating: 5
  },
  {
    name: "Will",
    location: "Exeter",
    text: "Fancied an extra night away & booked a hotel through PlansChange just a couple of hours before checkin. Great for spontaneous travel.",
    rating: 5
  },
  {
    name: "Hannah",
    location: "Edinburgh",
    text: "Got a great hotel deal at 60% less than the original price. Bargain!",
    rating: 5
  },
  {
    name: "Rosie",
    location: "Bath",
    text: "Checkin process was smooth and we even got an upgrade!",
    rating: 5
  },
  {
    name: "Lewis",
    location: "Cardiff",
    text: "Great hotel discounts, easy booking process and quick confirmation. Highly recommended.",
    rating: 5
  }
];
