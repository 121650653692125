import * as types from "./listingsActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: []
};

const listings = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_LISTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: ""
      };
    case types.ADMIN_UPDATE_LISTING_REQUEST_SUCCESS:
      return {
        ...state,
        data: state.data.map(listing => {
          return listing.id === action.response.id ? { ...listing, ...action.response } : listing;
        }),
        error: ""
      };
    case types.ADMIN_GET_LISTINGS_REQUEST_ERROR:
    case types.ADMIN_UPDATE_LISTING_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_CLEAR_LISTING_ERROR:
      return {
        ...state,
        error: ""
      };
    default:
      return state;
  }
};

export default listings;
