import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { Col } from "antd";

import Image from "../shared/Image"

const BlogArticleCard = ({ article }) => {

  return (
    <Col
      className="blog-card-wrapper"
      xs={{ span: 12 }}
      sm={{ span: 8 }}
      lg={{ span: 6 }}
    >
      <Link to={`/blog/articles/${article.slug}`} className="blog-article-card">
        <div className="blog-article-card__item blog-article-card__image-container">
          <Image
            className="blog-article-card__image"
            imageUrl={article.coverImageUrl}
            alt="article"
          />
        </div>

        <div className="blog-article-card__item">
          <div className="blog-article-card__date-container">
            <span className="blog-article-card__date">{moment(article.publishDate).format("Do MMMM YYYY")}</span>
          </div>
          <h3 className="blog-article-card__title">
            {article.title}
          </h3>
        </div>

        <div className="blog-article-card__item">
          <span className="blog-article-card__description">
            {article.shortDescription}
          </span>
        </div>
      </Link>
    </Col>
  );
};

BlogArticleCard.propTypes = {
  article: PropTypes.object
};

export default BlogArticleCard;
