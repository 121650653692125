import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setNewListingData } from "../../redux/listing/listingActions";
import { showModalAction } from "../../redux/general/generalActions";
import parse from "html-react-parser";

const SellBookingStep = ({ number, name }) => {
  return (
    <div className="sell-booking-step">
      <div className="sell-booking-step__number">
        <span>{number}</span>
      </div>
      <span className="sell-booking-step__name">{name}</span>
    </div>
  );
};

SellBookingStep.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
}

const SellBooking = ({ isAuthenticated, canCreateListing, history, setNewListing, showModal, content }) => {
  const sellReservationHandler = (e) => {
    e.preventDefault();

    if (isAuthenticated) {
      if (canCreateListing) {
        setNewListing({});
        history.push("/listings/create");
      } else {
        showModal({
          type: "info",
          title: "You have too many Draft Listings. Please delete or publish them to be able to create new Listings.",
          params: {
            icon: "/images/info-yellow.svg",
            buttonText: "Got it",
            confirmAction: () => history.push("/seller/listings/active")
          }
        });
      }
    } else {
      history.push("/auth/sign-in", { from: { pathname: "/listings/create" } });
    }
  };

  return (
    <div className="sell-booking">
      <div className="sell-booking__item">
        {
          content ? (
            <div className="plainHtml">
              {parse(content || "")}
            </div>
          ) : (
            <>
              <Typography.Title className="sell-booking__title" level={2}>
                Sell Non-Refundable Hotel Rooms
              </Typography.Title>
              <p className="sell-booking__subtitle">On our new marketplace</p>
              <p>
                If you can't use it, don't lose it! List your booking here and we'll find you a match. The only rule
                is to sell it for the same amount or less.
              </p>
            </>
          )
        }
        <div className="sell-booking__step-list-wrapper">
          <div className="sell-booking__step-list">
            <div className="sell-booking__step-item">
              <SellBookingStep number={1} name="Sign In/Up"/>
            </div>
            <div className="sell-booking__step-item">
              <SellBookingStep number={2} name="Sell My Room"/>
            </div>
            <div className="sell-booking__step-item">
              <SellBookingStep number={3} name="Get Your Money Back"/>
            </div>
          </div>
        </div>
        <div className="sell-booking__actions">
          <Link to="/listings/create" onClick={sellReservationHandler} className="sell-booking__sell-container">
            <Button className="sell-booking__button sell-booking__button--sell">Sell</Button>
          </Link>
          <Link to="/resale-deals" className="sell-booking__buy-container">
            <Button className="sell-booking__button">Buy</Button>
          </Link>
        </div>
        <Link to="/selling" className="sell-booking__seller-info">
          <img className="sell-booking__seller-info-image" src="/images/icons/info-outlined.svg" alt="info"/>
          <span>seller info</span>
        </Link>
      </div>
      <div className="sell-booking__item">
        <img
          className="sell-booking__image"
          src="/images/sell-booking-image.png"
          alt="Sell your booking here"
        />
      </div>
    </div>
  );
};

SellBooking.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  canCreateListing: PropTypes.bool,
  history: PropTypes.object.isRequired,
  setNewListing: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default withRouter(connect(
  state => ({
    isProcessing: state.listing.processing,
    isAuthenticated: state.auth.isAuthenticated,
    canCreateListing: state.profile.permissions.canCreateListing
  }),
  {
    setNewListing: setNewListingData,
    showModal: showModalAction
  }
)(SellBooking));
