import React, { useEffect } from "react";
import { Row, Col, Button, Card } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { getStaticPageRequest, updateStaticPageRequest } from "../../../redux/admin/staticPages/staticPagesActions";
import RichTextEditor from "../../shared/RichTextEditor/RichTextEditor";
import InputWithLabel from "../../shared/InputWithLabel";
import testHtmlLength from "./helpers/testHtmlLength";

const AboutUsSchema = yup.object().shape({
  section1: yup.string().trim()
    .test({
      name: "section1Length",
      message: "Section text can not be empty",
      test: testHtmlLength
    }),
  section2: yup.string().trim()
    .test({
      name: "section2Length",
      message: "Section text can not be empty",
      test: testHtmlLength
    }),
  section3: yup.string().trim()
    .test({
      name: "section3Length",
      message: "Section text can not be empty",
      test: testHtmlLength
    }),
  aboutUsLine1: yup.string().trim().required("Field can not be empty"),
  aboutUsLine2: yup.string().trim(),
  aboutUsColumn1: yup.string().trim()
    .test({
      name: "aboutUsColumn1Length",
      message: "Section text can not be empty",
      test: testHtmlLength
    }),
  aboutUsColumn2: yup.string().trim()
    .test({
      name: "aboutUsColumn2Length",
      message: "Section text can not be empty",
      test: testHtmlLength
    }),
  aboutUsColumn3: yup.string().trim()
    .test({
      name: "aboutUsColumn3Length",
      message: "Section text can not be empty",
      test: testHtmlLength
    }),
  discover: yup.string().trim()
    .test({
      name: "aboutUsColumn3Length",
      message: "Section text can not be empty",
      test: testHtmlLength
    }),
});

const AboutUs = ({ isProcessing, updatePage, page, getPage, location }) => {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: AboutUsSchema
  });

  useEffect(() => {
    getPage({ permalink: active });
  }, []);

  useEffect(() => {
    reset(page);
  }, [page]);

  const submitHandler = (data) => {
    updatePage({
      ...data,
      permalink: active
    });
  };

  return (
    <Row className="admin-about-us">
      <Col span={24}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Row className="admin-section">
            <Col span={24}>
              <Row>
                <Col span={24} className="admin-about-us__title">
                  Edit meta
                </Col>
              </Row>
              <Row gutter={16} className="admin-about-us__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaTitle"
                    control={control}
                    id="metaTitle"
                    label="Title"
                    error={errors.metaTitle}
                    placeholder="Title"
                    defaultValue={page.metaTitle}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-about-us__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaDescription"
                    control={control}
                    id="metaDescription"
                    label="Description"
                    error={errors.metaDescription}
                    placeholder="Description"
                    defaultValue={page.metaDescription}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaKeywords"
                    control={control}
                    id="metaKeywords"
                    label="Keywords"
                    error={errors.metaKeywords}
                    placeholder="Keywords"
                    defaultValue={page.metaKeywords}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="admin-about-us__title">
                  Edit content
                </Col>
              </Row>
              <Row gutter={16} className="admin-about-us__row">
                <Col span={24}>
                  <label className="admin-about-us__label">Section 1</label>
                  <Controller
                    name="section1"
                    as={<RichTextEditor
                      value={page.section1}
                    />}
                    defaultValue={page.section1}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.section1 &&
                    <span className="admin-about-us__error">{errors.section1.message}</span>
                  }
                </Col>
              </Row>
              <Row gutter={16} className="admin-about-us__row">
                <Col span={24}>
                  <label className="admin-about-us__label">Section 2</label>
                  <Controller
                    name="section2"
                    as={<RichTextEditor
                      value={page.section2}
                    />}
                    defaultValue={page.section2}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.section2 &&
                    <span className="admin-about-us__error">{errors.section2.message}</span>
                  }
                </Col>
              </Row>
              <Row gutter={16} className="admin-about-us__row">
                <Col span={24}>
                  <label className="admin-about-us__label">Section 3</label>
                  <Controller
                    name="section3"
                    as={<RichTextEditor
                      value={page.section3}
                    />}
                    defaultValue={page.section3}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.section3 &&
                    <span className="admin-about-us__error">{errors.section3.message}</span>
                  }
                </Col>
              </Row>
              <Row gutter={16} className="admin-about-us__row">
                <Col span={12}>
                  <InputWithLabel
                    name="aboutUsLine1"
                    control={control}
                    id="aboutUsLine1"
                    label="About us title (line 1)"
                    error={errors.aboutUsLine1}
                    placeholder="About us"
                    defaultValue={page.aboutUsLine1}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-about-us__row">
                <Col span={12}>
                  <InputWithLabel
                    name="aboutUsLine2"
                    control={control}
                    id="aboutUsLine2"
                    label="About us title (line 2)"
                    error={errors.aboutUsLine2}
                    placeholder="About us"
                    defaultValue={page.aboutUsLine2}
                  />
                </Col>
              </Row>
              <label className="admin-about-us__label">About us columns</label>
              <Row gutter={24} className="admin-about-us__row">
                <Col span={8}>
                  <Card title="1st column" bordered={false}>
                    <label className="admin-about-us__label">Content</label>
                    <Controller
                      name="aboutUsColumn1"
                      as={<RichTextEditor
                        value={page.aboutUsColumn1}
                      />}
                      defaultValue={page.aboutUsColumn1}
                      control={control}
                      onChange={([value]) => value }
                    />
                    { errors.aboutUsColumn1 &&
                      <span className="admin-about-us__error">{errors.aboutUsColumn1.message}</span>
                    }
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="2nd column" bordered={false}>
                    <label className="admin-about-us__label">Content</label>
                    <Controller
                      name="aboutUsColumn2"
                      as={<RichTextEditor
                        value={page.aboutUsColumn2}
                      />}
                      defaultValue={page.aboutUsColumn2}
                      control={control}
                      onChange={([value]) => value }
                    />
                    { errors.aboutUsColumn2 &&
                      <span className="admin-about-us__error">{errors.aboutUsColumn2.message}</span>
                    }
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="3rd column" bordered={false}>
                    <label className="admin-about-us__label">Content</label>
                    <Controller
                      name="aboutUsColumn3"
                      as={<RichTextEditor
                        value={page.aboutUsColumn3}
                      />}
                      defaultValue={page.aboutUsColumn3}
                      control={control}
                      onChange={([value]) => value }
                    />
                    { errors.aboutUsColumn3 &&
                      <span className="admin-about-us__error">{errors.aboutUsColumn3.message}</span>
                    }
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} className="admin-about-us__row">
                <Col span={24}>
                  <label className="admin-about-us__label">Discover content</label>
                  <Controller
                    name="discover"
                    as={<RichTextEditor
                      value={page.discover}
                    />}
                    defaultValue={page.discover}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.discover &&
                    <span className="admin-about-us__error">{errors.discover.message}</span>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="admin-about-us__submit">
              <Button htmlType="submit" disabled={isProcessing} className="button button--primary">Save</Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  )
};

AboutUs.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  getPage: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.staticPage.processing,
    error: state.admin.staticPage.error,
    page: state.admin.staticPage.page
  }),
  {
    getPage: getStaticPageRequest,
    updatePage: updateStaticPageRequest
  }
)(AboutUs);
