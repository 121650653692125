import React from "react";
import PropTypes from "prop-types";
import HotelCard from "../../shared/HotelCard";
import { parseDataForHotelCard } from "../../../helpers/common";

const MyPurchasesCard = ({ data }) => {
  const priceLabel = "Paid";

  return (
    <HotelCard
      {...parseDataForHotelCard(data)}
      priceLabel={priceLabel}
    />
  );
};

MyPurchasesCard.propTypes = {
  data: PropTypes.object.isRequired
};

export default MyPurchasesCard;
