import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col } from "antd";

import { getDashboardFundsRequest } from "../../../redux/admin/dashboard/dashboardActions";
import SectionHeading from "./SectionHeading";


const FundsInWallets = ({ funds, country, getFunds }) => {
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD")
  });

  useEffect(() => {
    getFunds({ ...searchQuery, country });
  }, [searchQuery]);

  useEffect(() => {
    getFunds({ ...searchQuery, country });
  }, [country]);

  return (
    <Row className="admin-dashboard__block">
      <Col span={24}>
        <SectionHeading
          setSearchQuery={setSearchQuery}
          title="Funds in wallets"
          searchQuery={searchQuery}
          sectionKey="funds"
        />
        <Row className="admin-dashboard__section">
          <Col span={12} className="admin-dashboard__section-element admin-dashboard__section-element--right-bordered">
            <div className="admin-dashboard__section-element-title">
              PlansChange fees (Organic)
            </div>
            <div className="admin-dashboard__section-element-number">{country === "US" ? "$" : "£"} {(+funds.organicFees).toFixed(2)}</div>
          </Col>
          <Col span={12} className="admin-dashboard__section-element">
            <div className="admin-dashboard__section-element-title">
              PlansChange fees (B2B)
            </div>
            <div className="admin-dashboard__section-element-number">{country === "US" ? "$" : "£"} {(+funds.b2bFees).toFixed(2)}</div>
          </Col>
        </Row>
        <Row className="admin-dashboard__section">
          <Col span={12} className="admin-dashboard__section-element admin-dashboard__section-element--right-bordered">
            <div className="admin-dashboard__section-element-title admin-dashboard__section-element-title--yellow">
              In dispute
            </div>
            <div className="admin-dashboard__section-element-number">{country === "US" ? "$" : "£"} {(+funds.inDispute).toFixed(2)}</div>
          </Col>
          <Col span={12} className="admin-dashboard__section-element">
            <div className="admin-dashboard__section-element-title admin-dashboard__section-element-title--blue">
              Pending
            </div>
            <div className="admin-dashboard__section-element-number">{country === "US" ? "$" : "£"} {(+funds.pending).toFixed(2)}</div>
          </Col>
        </Row>
        <Row className="admin-dashboard__section">
          <Col span={24} className="admin-dashboard__section-element">
            <div className="admin-dashboard__section-element-title admin-dashboard__section-element-title--red">
              Available to withdraw
            </div>
            <div className="admin-dashboard__section-element-number">{country === "US" ? "$" : "£"} {(+funds.available).toFixed(2)}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

FundsInWallets.propTypes = {
  funds: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  getFunds: PropTypes.func.isRequired
};

export default connect(
  state => ({
    funds: state.admin.dashboard.funds,
    country: state.admin.dashboard.country
  }),
  {
    getFunds: getDashboardFundsRequest
  }
)(FundsInWallets);
