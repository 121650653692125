export const partners = [
  {
    id: 1,
    name: "accor-hotels",
    img: "/images/partners-logos/accor-hotels.png"
  },
  {
    id: 2,
    name: "best-western-hotels",
    img: "/images/partners-logos/best-western-hotels.png"
  },
  {
    id: 3,
    name: "fairmont",
    img: "/images/partners-logos/fairmont.png"
  },
  {
    id: 4,
    name: "four-seasons-hotels",
    img: "/images/partners-logos/four-seasons-hotels.png"
  },
  {
    id: 5,
    name: "holiday-inn",
    img: "/images/partners-logos/holiday-inn.png"
  },
  {
    id: 6,
    name: "hilton",
    img: "/images/partners-logos/hilton.png"
  },
  {
    id: 7,
    name: "hyatt-hotels",
    img: "/images/partners-logos/hyatt-hotels.png"
  },
  {
    id: 8,
    name: "marriott",
    img: "/images/partners-logos/marriott.png"
  },
  {
    id: 9,
    name: "radisson",
    img: "/images/partners-logos/radisson.png"
  },
  {
    id: 10,
    name: "wyndham",
    img: "/images/partners-logos/wyndham.png"
  }
];
