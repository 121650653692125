import React from "react";
import { Row, Col, Typography, Grid } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";
import { Helmet } from "react-helmet";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import parse from "html-react-parser";

export const AboutUs = ({ getStaticPage, pageData }) => {
  const { xs } = Grid.useBreakpoint();

  useServerSafeEffect(() => {
    getStaticPage();
  }, []);

  return (
    <>
      <Helmet>
        {
          pageData.metaTitle && (
            <title>{ pageData.metaTitle}</title>
          )
        }
        {
          pageData.metaDescription && (
            <meta name="description" content={pageData.metaDescription} />
          )
        }
        {
          pageData.metaKeywords && (
            <meta name="keywords" content={pageData.metaKeywords} />
          )
        }
      </Helmet>
      <Row justify="space-around" align="middle" className="about-us">
        <Col xs={{ span: 22 }} lg={{ span: 18 }}>
          <Row gutter={16} align="middle" className="about-us__section">
            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
              <div className="plainHtml">
                {parse(pageData.section1 || "")}
              </div>
            </Col>
            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
              <img
                src="/images/about-us/something-different.png"
                alt="Checkin to something different"
                className="about-us__image"
              />
            </Col>
          </Row>
          <Row gutter={16} align="middle" className="about-us__section">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <img
                src="/images/about-us/sell-your-booking.png"
                alt="Sell your booking"
                className="about-us__image"
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div className="plainHtml">
                {parse(pageData.section2 || "")}
              </div>
            </Col>
          </Row>
          <Row gutter={16} align="middle" className="about-us__section about-us__section">
            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
              <div className="plainHtml">
                {parse(pageData.section3 || "")}
              </div>
            </Col>
            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
              <img
                src="/images/about-us/win-for-all.png"
                alt="Win … Win for all"
                className="about-us__image"
              />
            </Col>
          </Row>
          <Row gutter={16} align="middle" className="about-us__section">
            <Col span={24}>
              <hr className="about-us__line"/>
            </Col>
          </Row>
          <Row justify="space-around" className="about-us__section">
            <Col span={22}>
              { !xs &&
                <Row>
                  <Col span={24}>
                    <Typography.Title level={2} className="about-us__title about-us__title--help">
                      { pageData.aboutUsLine1 }
                      { pageData.aboutUsLine2 && (
                        <>
                          <br/>
                          { pageData.aboutUsLine2 }
                        </>
                      )}
                    </Typography.Title>
                  </Col>
                </Row>
              }
              <Row gutter={48} align="top">
                <Col xs={{ span: 24 }} md={{ span: 8 }} className="about-us__help-item">
                  <img
                    src="/images/about-us/flexible-bookings.png"
                    alt="Flexible hotel bookings at great prices"
                    className="about-us__help-item-image"
                  />
                  <div className="plainHtml">
                    {parse(pageData.aboutUsColumn1 || "")}
                  </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} className="about-us__help-item">
                  <img
                    src="/images/about-us/free-cancellation.png"
                    alt="Easy & hassle-free holiday cancellations"
                    className="about-us__help-item-image"
                  />
                  <div className="plainHtml">
                    {parse(pageData.aboutUsColumn2 || "")}
                  </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} className="about-us__help-item">
                  <img
                    src="/images/about-us/great-deals.png"
                    alt="No gimmicks, just great deals"
                    className="about-us__help-item-image"
                  />
                  <div className="plainHtml">
                    {parse(pageData.aboutUsColumn3 || "")}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="space-around" className="about-us__section about-us__section--white">
            <Col xs={{ span: 24 }} md={{ span: 22 }}>
              <Row align="middle">
                <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                  <div className="plainHtml discover">
                    {parse(pageData.discover || "")}
                  </div>
                  <Link to="/resale-deals" className="about-us__discover-deals">
                    Discover the deals
                  </Link>
                </Col>
                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                  <img
                    src="/images/about-us/discover-deals.png"
                    alt="Discover the deals"
                    className="about-us__image"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
};

AboutUs.propTypes = {
  getStaticPage: PropTypes.func.isRequired,
  pageData: PropTypes.any.isRequired,
};

export default connect(
  state => ({
    pageData: state.staticPage.page,
  }),
  {
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "about-us" }),
  }
)(AboutUs);
