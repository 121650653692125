import React from "react";
import { Route, Switch } from "react-router-dom";

import ProfileLayout from "../layout/ProfileLayout";

import Seo from "../shared/Seo";
import Profile from "../Profile/Profile";
import MyPurchases from "../Listing/MyPurchases";
import PageNotFound from "../PageNotFound/PageNotFound";

const seoData = {
  upcoming: {
    title: "Upcoming Purchases ",
    description:
      "Not long to go now! View your upcoming bookings and all details you need to know for your stay.",
  },
  past: {
    title: "Past Purchases",
    description:
      "View your past purchases and all details of your past hotel bookings to book again or leave a review.",
  }
};

export default function UserRoutes() {
  return (
    <ProfileLayout>
      <Switch>
        <Route
          path="/profile/purchases/:status"
          exact
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo {...seoData[props.match.params.status]}>
              <MyPurchases {...props} />
            </Seo>
          )}
        />
        <Route
          path="/profile"
          exact
          render={(props) => (
            <Seo
              title="Profile"
              description="View your PlansChange profile and edit your details for us to keep you updated with the latest deals and news on your hotel bookings."
            >
              <Profile {...props} />
            </Seo>
          )}
        />
        <Route component={PageNotFound} />
      </Switch>
    </ProfileLayout>
  );
}
