import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const feedback = {
  async getFeedbackList(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/feedback?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteFeedback(data) {
    try {
      const result = await axios.delete(`${API_URL}/admin/feedback/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getFeedback(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/feedback/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default feedback;
