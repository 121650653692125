import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const article = {
  async getArticleList(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/articles?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createArticle(data) {
    try {
      const result = await axios.post(`${API_URL}/admin/articles`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateArticle(data) {
    try {
      const result = await axios.put(`${API_URL}/admin/articles/${data.id}`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getArticle(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/articles/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteArticle(data) {
    try {
      const result = await axios.delete(`${API_URL}/admin/articles/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async publishArticle(data) {
    try {
      const result = await axios.post(`${API_URL}/admin/articles/${data.id}/${data.publish}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default article;
