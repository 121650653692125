import React from "react";
import PropTypes from "prop-types";
import { Col, Layout, Row } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { CARD_VERIFICATION_FAILED_STATUS } from "../constants";

import Header from "../shared/Header";
import Footer from "../shared/Footer";

function SellerLayout({ children, cardVerificationStatus }) {
  return (
    <div className="user-content">
      <Header/>
      {cardVerificationStatus === CARD_VERIFICATION_FAILED_STATUS &&
      <Row>
        <Col span={24} className="card-verification-failed">
          <Row justify="space-around" align="middle">
            <Col xs={{ span: 22 }} md={{ span: 16 }} className="card-verification-failed__message">
              <img src="/images/danger.svg" className="card-verification-failed__icon"/>
              <div>
                <div className="card-verification-failed__title">
                  The credit card you have supplied for Profile verification has failed.
                </div>
                <div>
                  You won’t be able to publish a listing. Please contact <Link
                    to="/contact-us"
                    className="card-verification-failed__link"
                  >our customer support</Link> team.
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      }
      <Layout.Content>
        <div className="seller-layout">
          {children}
        </div>
      </Layout.Content>
      <Footer/>
    </div>
  );
}

SellerLayout.propTypes = {
  children: PropTypes.any,
  cardVerificationStatus: PropTypes.string
};

export default connect(
  state => ({
    cardVerificationStatus: state.profile.permissions.cardVerificationStatus
  })
)(SellerLayout);
