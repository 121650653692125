import React, { useState } from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";

import { ISO31661ALPHA2, regions } from "../../../api/constants";
import SelectWithLabel from "../SelectWithLabel";
import InputWithLabel from "../InputWithLabel";

export const ProfileAddress = ({ address, errors, control, setValue, watch }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  return (
    <Row className="profile-details">
      <Col span={24}>
        <Row>
          <Col span={24}>
            <div className="profile-details__field">
              <InputWithLabel
                name="address1"
                control={control}
                id="address-address"
                label="Address"
                error={errors.address1}
                placeholder="Address"
                defaultValue={address.address1}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="profile-details__field">
              <InputWithLabel
                name="city"
                control={control}
                id="address-city"
                label="City"
                error={errors.city}
                placeholder="City"
                defaultValue={address.city}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="profile-details__field">
              <SelectWithLabel
                name="country"
                control={control}
                id="address-country"
                label="Country / Region"
                showSearch={true}
                onChange={value => {
                  setSelectedCountry(value[0]);

                  if (value[0] !== watch("country")) {
                    setValue("state", null);
                  }

                  return value[0];
                }}
                error={errors.country}
                defaultValue={address.country}
                options={ISO31661ALPHA2
                  .filter(country => country.isSupportedInMangoPay)
                  .map(country => {
                    return {
                      value: country.code,
                      label: country.name
                    };
                  })
                }
                placeholder="Select"
                disabled={!!address && !!address.country}
              />
            </div>
          </Col>
        </Row>
        {
          ["US", "CA"].includes(selectedCountry) &&
          <Row>
            <Col span={24}>
              <div className="profile-details__field">
                <SelectWithLabel
                  name="state"
                  control={control}
                  id="state"
                  label="State"
                  showSearch={true}
                  onChange={value => {
                    return value[0];
                  }}
                  error={errors.state}
                  defaultValue={address.state}
                  options={regions[selectedCountry].map(state => {
                    return {
                      value: state.code,
                      label: state.name
                    };
                  })}
                  placeholder="Select State"
                />
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col span={24}>
            <div className="profile-details__field">
              <InputWithLabel
                name="zipCode"
                control={control}
                id="address-zip-code"
                label="Zip/Post Code"
                error={errors.zipCode}
                placeholder="Zip/Post Code"
                defaultValue={address.zipCode}
                onChange={([event]) => {
                  return event.target.value ? event.target.value.toUpperCase() : null;
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ProfileAddress.propTypes = {
  address: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired
};

export default ProfileAddress;
