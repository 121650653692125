import React from "react";
import { InputNumber } from "antd";

const CustomInputNumber = (props) => {
  return (
    <InputNumber className="custom-input-number" {...props}/>
  );
};

export default CustomInputNumber;
