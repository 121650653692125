import { all, put, call, takeLatest } from "redux-saga/effects";

import highlights from "../../api/highlights";
import * as types from "./highlightsActionsTypes";

function *getHighlights ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(highlights.getHighlights, data);
    yield put({ type: types.GET_HIGHLIGHTS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_HIGHLIGHTS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_HIGHLIGHTS_REQUEST, getHighlights)
  ])
}
