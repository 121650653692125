import { all, put, call, takeLatest } from "redux-saga/effects";

import subscriptions from "../../../api/admin/subscriptions";
import * as types from "./subscriptionsActionsTypes";
import * as generalTypes from "../../general/generalActionsTypes";
import { MODAL_TYPE_ERROR } from "../../../react/constants";

function *getSubscriptionsList ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(subscriptions.getSubscriptionsList, data);
    yield put({ type: types.ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getCsvExportUrl ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(subscriptions.getCsvExportUrl, data);
    if (response.fileUrl) {
      const a = document.createElement("a");
      a.href = response.fileUrl;
      a.download = response.fileUrl;
      a.click();
    }
  } catch (error) {
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *deleteSubscription ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(subscriptions.deleteSubscription, data);
    yield put({ type: types.ADMIN_DELETE_SUBSCRIPTION_REQUEST_SUCCESS, response });
    yield put({ type: generalTypes.HIDE_MODAL });
    yield put({ type: types.ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_SUBSCRIPTION_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST, getSubscriptionsList),
    yield takeLatest(types.ADMIN_GET_SUBSCRIPTIONS_CSV_EXPORT_URL_REQUEST, getCsvExportUrl),
    yield takeLatest(types.ADMIN_DELETE_SUBSCRIPTION_REQUEST, deleteSubscription)
  ])
}
