import React from "react";
import { Row, Col } from "antd"
import { connect } from "react-redux";
import PropTypes from "prop-types";

import UpdatesCard from "./UpdatesCard"
import { getNewsUpdatesRequest } from "../../../redux/blog/blogActions";
import { useServerSafeEffect, useMessage } from "../../../hooks";
import Loader from "../../shared/Loader";

const Updates = ({ articles, processing, error, getNewsUpdates }) => {
  useServerSafeEffect(() => {
    getNewsUpdates({ page: 1, pageSize: 3, type: "top" });
  }, []);

  useMessage({ type: "error", error });

  return (
    <Row gutter={[16, 16]} className="updates__container">
      {processing && !articles.length && <Loader type="section"/>}
      { articles?.[0] &&
        <Col xs={{ span: 24 }} xl={{ span: 15 }}>
          <UpdatesCard
            path={`/blog/articles/${articles[0].slug}`}
            imageUrl={articles[0].coverImageUrl}
            isMain
            title={articles[0].title}
            text={articles[0].shortDescription}
          />
        </Col>
      }
      { (articles?.[1] || articles?.[2]) &&
        <Col xs={{ span: 24 }} xl={{ span: 9 }}>
          <Row gutter={[16, 16]}>
            { articles?.[1] &&
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 24 }}>
                <UpdatesCard
                  path={`/blog/articles/${articles[1].slug}`}
                  imageUrl={articles[1].coverImageUrl}
                  title={articles[1].title}
                  text={articles[1].shortDescription}
                />
              </Col>
            }
            { articles?.[2] &&
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 24 }}>
                <UpdatesCard
                  path={`/blog/articles/${articles[2].slug}`}
                  imageUrl={articles[2].coverImageUrl}
                  title={articles[2].title}
                  text={articles[2].shortDescription}
                />
              </Col>
            }
          </Row>
        </Col>
      }
    </Row>
  )
};

Updates.propTypes = {
  articles: PropTypes.array.isRequired,
  processing: PropTypes.bool.isRequired,
  getNewsUpdates: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default connect(
  state => ({
    articles: state.blog.newsUpdates.data,
    error: state.blog.newsUpdates.error,
    processing: state.blog.newsUpdates.processing,
  }),
  {
    getNewsUpdates: getNewsUpdatesRequest
  }
)(Updates);
