import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";

import {
  createCategoryRequest,
  deleteCategoryRequest,
  clearCategoryErrorAction,
  getCategoriesRequest,
  setCategoriesAction,
  updateCategoriesOrderRequest
} from "../../../redux/admin/faq/faqActions";
import InputWithLabel from "../../shared/InputWithLabel";
import { showModalAction } from "../../../redux/general/generalActions";
import DragSortingTable from "../../shared/DragSortingTable/DragSortingTable";

const CategoriesVerificationSchema = yup.object().shape({
  title: yup.string().trim().required("Category can't be empty")
});

const Categories = ({
  categories,
  error,
  isProcessing,
  requestSuccess,
  createCategory,
  deleteCategory,
  clearError,
  getCategories,
  showModal,
  setCategories,
  updateCategoriesOrder
}) => {
  const { handleSubmit, errors, setValue, control } = useForm({
    validationSchema: CategoriesVerificationSchema,
  });

  useEffect(() => {
    getCategories({ sort: "position,title", pageSize: -1 });
  }, []);

  useEffect(() => {
    if (requestSuccess) {
      setValue("title", "");
      clearError();
    }
  }, [requestSuccess]);

  const categoriesVerificationHandler = (data) => {
    createCategory(data);
  };

  const handleOrderChange = (updatedData) => {
    updateCategoriesOrder({
      data: updatedData.map((item, key) => {
        return {
          id: item.id,
          position: key + 1
        }
      })
    });
  };

  const renderTitle = (text, record) => {
    return (<Link to={`/admin/faq/categories/${record.key}`}>{text}</Link>);
  };

  const renderDeleteButton = (item) => {
    return (
      <a
        onClick={() => showModal({
          type: "confirm",
          title: "Are You sure you would like to delete category?",
          params: {
            isProcessing,
            error,
            confirmAction: () => deleteCategory({ id: item.key })
          }
        })}
      >
        <img src="/images/close.svg" alt="delete" />
      </a>
    )
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: renderTitle
    },
    {
      align: "right",
      title: "",
      key: "operation",
      width: 30,
      render: renderDeleteButton,
    }
  ];

  return (
    <div className="admin-categories">
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="admin-categories__column">
          <form onSubmit={handleSubmit(categoriesVerificationHandler)} className="admin-categories__form">
            <Row>
              <Col span={24} className="admin-categories__form-body">
                <Row>
                  <Col span={24} className="admin-categories__form-title">
                    Create new category
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <InputWithLabel
                      name="title"
                      control={control}
                      id="category-name"
                      onChange={([event]) => {
                        clearError();

                        return event.target.value
                      }}
                      label="Title"
                      error={errors.title || (error ? { message: error } : null)}
                      placeholder="Enter Title"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="admin-categories__form-submit">
                <Button className="button button--primary" htmlType="submit" disabled={isProcessing}>Add</Button>
              </Col>
            </Row>
          </form>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="admin-categories__column">
          <DragSortingTable
            columns={columns}
            data={categories.map(category => ({
              key: category.id,
              id: category.id,
              title: category.title
            }))}
            pagination={false}
            handleOrderChange={handleOrderChange}
            setData={setCategories}
          />
        </Col>
      </Row>
    </div>
  );
};

Categories.propTypes = {
  getCategories: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  error: PropTypes.string,
  isProcessing: PropTypes.bool,
  requestSuccess: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired,
  updateCategoriesOrder: PropTypes.func.isRequired
};

export default connect(
  state => ({
    categories: state.admin.faq.categories.data,
    error: state.admin.faq.error,
    isProcessing: state.admin.faq.processing,
    requestSuccess: state.admin.faq.requestSuccess,
  }),
  {
    getCategories: getCategoriesRequest,
    createCategory: createCategoryRequest,
    deleteCategory: deleteCategoryRequest,
    clearError: clearCategoryErrorAction,
    showModal: showModalAction,
    setCategories: setCategoriesAction,
    updateCategoriesOrder: updateCategoriesOrderRequest
  }
)(Categories);
