import React from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";

import AuthFooter from "../shared/AuthFooter";
import Header from "../shared/Header";
import { useLocation } from "react-router-dom";

function AuthLayout({ children }) {
  const location = useLocation();

  return (
    <div className="auth-content">
      <Header />

      <Layout.Content>
        { children }

        { location.pathname !== "/auth/sign-up" && <AuthFooter /> }
      </Layout.Content>
    </div>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.any,
};

export default AuthLayout;
