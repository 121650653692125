import React, { useState } from "react";
import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { clearProfileErrorAction, closeAccountRequest } from "../../../redux/profile/profileActions";
import { signOutAction } from "../../../redux/auth/authActions";

export const CloseAccount = ({ isProcessing, error, isProfileClosed, closeAccount, clearError, signOut }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeAccountHandler = () => {
    setIsModalVisible(false);
    clearError();
    closeAccount();
  };

  if (isProfileClosed) {
    clearError();
    signOut();

    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <div className="close-account">
        <Button className="button button--danger" disabled={isProcessing} onClick={() => setIsModalVisible(true)}>
          Terminate user account
        </Button>
      </div>
      <Modal
        className="modal"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        closable={false}
        footer={null}
      >
        <div className="close-account__modal-body">
          <img src="/images/close_outlined.svg" alt="close" className="close-account__modal-icon"/>
          <div className="close-account__modal-text">
            Are You sure you would like to terminate <span className="text-semi-bold">PlansChange</span> account?
          </div>
          <div className="close-account__modal-buttons">
            <Button className="button button--danger" disabled={isProcessing} onClick={closeAccountHandler} data-testid="close-account-confirm">
              Yes
            </Button>
            <Button className="button button--default" onClick={() => setIsModalVisible(false)}>
              No
            </Button>
          </div>
          { error &&
            <div className="close-account__modal-error">
              Something went wrong. Please try again later.
            </div>
          }
        </div>
      </Modal>
    </React.Fragment>
  );
};

CloseAccount.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  isProfileClosed: PropTypes.bool.isRequired,
  closeAccount: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.profile.processing,
    error: state.profile.error,
    isProfileClosed: state.profile.isProfileClosed
  }),
  {
    closeAccount: closeAccountRequest,
    clearError: clearProfileErrorAction,
    signOut: signOutAction
  }
)(CloseAccount);
