import { all, put, call, takeLatest } from "redux-saga/effects";

import places from "../../api/places";
import * as types from "./placesActionsTypes";

function *getPlaces ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(places.getPlaces, data);
    yield put({ type: types.GET_PLACES_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_PLACES_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getPlaceDetails ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(places.getPlaceDetails, data);
    yield put({ type: types.GET_PLACE_DETAILS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_PLACE_DETAILS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_PLACES_REQUEST, getPlaces),
    yield takeLatest(types.GET_PLACE_DETAILS_REQUEST, getPlaceDetails)
  ])
}
