import React from "react";
import { Route, Switch } from "react-router-dom";

import AuthLayout from "../layout/AuthLayout";

import Seo from "../shared/Seo";
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignUp/SignUp";
import ResetPassword from "../ResetPassword/ResetPassword";
import SetNewPassword from "../ResetPassword/SetNewPassword";
import PageNotFound from "../PageNotFound/PageNotFound";

export default function AuthRoutes() {
  return (
    <AuthLayout>
      <Switch>
        <Route
          exact
          path="/auth/sign-in"
          render={(props) => (
            <Seo
              title="Sign In"
              description="Sign to your PlansChange account to view and amend your bookings, sell your hotel room and find great deals for your next hotel stay."
            >
              <SignIn {...props} />{" "}
            </Seo>
          )}
        />
        <Route
          exact
          path="/auth/sign-up"
          render={(props) => (
            <Seo
              title="Sign Up"
              description="Are you looking for hotel stays without compromise? Then check in to a new booking experience with PlansChange, sign up now to find the right hotel stay for you. "
            >
              <SignUp {...props} />{" "}
            </Seo>
          )}
        />
        <Route
          exact
          path="/auth/reset-password"
          render={(props) => (
            <Seo
              title="Reset Password"
              description="Forgot your password or simply wanting to reset it? We get that privacy is important, reset your PlansChange password to gain access to your account."
            >
              <ResetPassword {...props} />
            </Seo>
          )}
        />
        <Route exact path="/auth/set-new-password/:token" component={SetNewPassword} />
        <Route component={PageNotFound} />
      </Switch>
    </AuthLayout>
  );
}
