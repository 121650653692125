import * as types from "./generalActionsTypes";

export const showModalAction = data => {
  return { type: types.SHOW_MODAL, data };
};

export const hideModalAction = () => {
  return { type: types.HIDE_MODAL };
};

export const subscribeToMarketingCampaignAction = (data) => {
  return { type: types.SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST, data };
};

export const clearSubscribedStatusStateAction = (data) => {
  return { type: types.CLEAR_SUBSCRIBED_STATUS_STATE, data };
};

export const clearStateAction = (data) => {
  return { type: types.CLEAR_STATE, data };
};
