import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { v4 as uuidv4 } from "uuid";
import { Row, Empty } from "antd"

import Tabs from "../shared/Tabs";
import BlogArticleCard from "./BlogArticleCard";
import Loader from "../shared/Loader"
import { useServerSafeEffect } from "../../hooks";
import {
  getBlogCategoryListRequest,
  getArticleListByCategoryRequest,
  updateArticleListByCategoryRequest
} from "../../redux/blog/blogActions";

const Categories = ({
  processing,
  getCategories,
  getArticles,
  fetchMore,
  categories,
  articles,
  pages
}) => {
  const [curPage, setCurPage] = useState(1);
  const [categorySlug, setCategorySlug] = useState();
  const refRoot = useRef();
  const ref = useRef();

  const handleObserver = (entities) => {
    const target = entities[0];

    if (!target.isIntersecting) {
      setCurPage((prevCurPage) => prevCurPage + 1)
    }
  };

  useServerSafeEffect(() => {
    getCategories();
  }, []);

  useServerSafeEffect(() => {
    categories.length && setCategorySlug(categories[0].slug);
  }, [categories]);

  useServerSafeEffect(() => {
    if (categories.length) {
      getArticles({ page: 1, pageSize: 6, categorySlug });
      setCurPage(1);
    }
  }, [categorySlug]);

  useServerSafeEffect(() => {
    if (curPage > 1 && curPage <= pages) {
      fetchMore({ page: curPage, pageSize: 6, categorySlug })
    }
  }, [curPage]);

  useEffect(() => {
    if (ref.current && refRoot.current) {
      const options = {
        root: refRoot.current,
        rootMargin: "20px",
        threshold: 1.0
      };

      const observer = new IntersectionObserver(handleObserver, options);
      observer.observe(ref.current)
    }
  }, [ref.current, refRoot.current]);


  return categories && (
    <Tabs
      className="categories__tabs"
      data={categories.map(({ name, slug }) => ({ tabName: name, key: slug }))}
      onChange={(key) => setCategorySlug(key)}
      activeKey={categorySlug}
    >
      <div ref={refRoot} className="wrapper">

        <Row ref={ref} wrap={false} gutter={16} className="categories__tab">
          {articles?.map((item) => (
            <BlogArticleCard key={uuidv4()} article={item}/>
          ))}

          {!processing && !articles?.length && (
            <Row style={{ width: "100%" }} justify="center" align="middle">
              <Empty/>
            </Row>
          )}

          {processing &&
            <div className="categories__loader-wrapper">
              <Loader type="section"/>
            </div>
          }
        </Row>
      </div>

    </Tabs>
  )
};

Categories.propTypes = {
  categories: PropTypes.array,
  categoryProcessing: PropTypes.bool,
  processing: PropTypes.bool,
  articles: PropTypes.array,
  pages: PropTypes.number,
  getCategories: PropTypes.func.isRequired,
  getArticles: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    categories: state.blog.categories.data,
    categoryProcessing: state.blog.categories.processing,
    processing: state.blog.articleListByCategory.processing,
    articles: state.blog.articleListByCategory.data,
    pages: state.blog.articleListByCategory.pages
  }),
  {
    getCategories: getBlogCategoryListRequest,
    getArticles: getArticleListByCategoryRequest,
    fetchMore: updateArticleListByCategoryRequest
  }
)(Categories);
