import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { v4 as uuidv4 } from "uuid";
import { Row, Button } from "antd";

import BlogArticleCard from "./BlogArticleCard";
import { getArticleListRequest, updateArticleListRequest } from "../../redux/blog/blogActions";
import { useServerSafeEffect, useMessage } from "../../hooks";
import Loader from "../shared/Loader"

const BrowseAll = ({ articles, processing, error, getArticles, updatedArticlesList, pages, curPage }) => {
  useServerSafeEffect(() => {
    getArticles({ page: 1 });
  }, []);

  useMessage({ type: "error", error });

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        {articles?.map((item) => (
          <BlogArticleCard key={uuidv4()} article={item} />
        ))}
      </Row>
      {processing && <Loader type="section" />}

      { pages > curPage &&
        <Button
          className="browse-all__button"
          onClick={() => updatedArticlesList({ page: curPage + 1 })}
          disabled={pages === curPage}
        >
          More
        </Button>
      }
    </React.Fragment>

  )
};

BrowseAll.propTypes = {
  articles: PropTypes.array,
  error: PropTypes.string,
  processing: PropTypes.bool,
  getArticles: PropTypes.func.isRequired,
  updatedArticlesList: PropTypes.func.isRequired,
  curPage: PropTypes.number,
  pages: PropTypes.number,
};

export default connect(
  state => ({
    articles: state.blog.articleList.data,
    error: state.blog.articleList.error,
    curPage: state.blog.articleList.curPage,
    pages: state.blog.articleList.pages,
    processing: state.blog.articleList.processing,
  }),
  {
    getArticles: getArticleListRequest,
    updatedArticlesList: updateArticleListRequest,
  }
)(BrowseAll);
