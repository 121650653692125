import { all, put, call, takeLatest } from "redux-saga/effects";

import staticPage from "../../api/staticPage";
import * as types from "./staticPagesActionsTypes";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";

function *getStaticPage ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(staticPage.getStaticPage, data);
    yield put({ type: types.GET_STATIC_PAGE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_STATIC_PAGE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *postFeedback ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(staticPage.postFeedback, data);
    yield put({ type: types.POST_FEEDBACK_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.POST_FEEDBACK_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getFaq ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(staticPage.getFaq, data);
    yield put({ type: types.GET_FAQ_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_FAQ_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_STATIC_PAGE_REQUEST, serverSagaWrapper(getStaticPage)),
    yield takeLatest(types.POST_FEEDBACK_REQUEST, postFeedback),
    yield takeLatest(types.GET_FAQ_REQUEST, serverSagaWrapper(getFaq))
  ])
}
