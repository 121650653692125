import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col } from "antd";

import { getDashboardListingsRequest } from "../../../redux/admin/dashboard/dashboardActions";
import SectionHeading from "./SectionHeading";


const Listings = ({ listings, country, getListings }) => {
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD")
  });

  useEffect(() => {
    getListings({ ...searchQuery, country });
  }, [searchQuery]);

  useEffect(() => {
    getListings({ ...searchQuery, country });
  }, [country]);

  return (
    <Row className="admin-dashboard__block">
      <Col span={24}>
        <SectionHeading
          setSearchQuery={setSearchQuery}
          title="Listings"
          searchQuery={searchQuery}
          sectionKey="listings"
        />
        <Row className="admin-dashboard__section">
          <Col span={12} className="admin-dashboard__section-element admin-dashboard__section-element--right-bordered">
            <div className="admin-dashboard__section-element-title admin-dashboard__section-element-title--yellow">
              Organic
            </div>
            <div className="admin-dashboard__section-element-number">{listings.organic}</div>
          </Col>
          <Col span={12} className="admin-dashboard__section-element">
            <div className="admin-dashboard__section-element-title admin-dashboard__section-element-title--green">
              B2B
            </div>
            <div className="admin-dashboard__section-element-number">{listings.b2b}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Listings.propTypes = {
  listings: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  getListings: PropTypes.func.isRequired
};

export default connect(
  state => ({
    listings: state.admin.dashboard.listings,
    country: state.admin.dashboard.country
  }),
  {
    getListings: getDashboardListingsRequest
  }
)(Listings);
