import React from "react";
import { Controller } from "react-hook-form";
import { Input } from "antd";
import PropTypes from "prop-types";

import Label from "./Form/Label";

const TextAreaWithLabel = ({
  id,
  name,
  control,
  onChange,
  label = "",
  error = "",
  placeholder = "",
  // eslint-disable-next-line
  defaultValue = undefined,
  isInvalid = false,
  maxLength = null,
  rows = 2,
  className = "",
  disabled = false
}) => {
  if (!onChange) {
    onChange = ([event]) => event.target.value;
  }

  return (
    <div className="textarea-with-label__field">
      {label &&
      <Label className={error ? "textarea-with-label__error-label" : ""} htmlFor={id}>{label}</Label>
      }
      <Controller
        name={name}
        defaultValue={defaultValue}
        as={<Input.TextArea
          id={id}
          placeholder={placeholder}
          maxLength={maxLength}
          rows={rows}
          className={`textarea-with-label__input ${className} ${(error || isInvalid) ? "textarea-with-label__input--error" : ""}`}
          data-testid={id}
          disabled={disabled}
        />}
        control={control}
        onChange={onChange}
      />
      {error &&
      <span className="textarea-with-label__error" data-testid="textarea-with-label-error">{error.message}</span>}
    </div>
  );
};

TextAreaWithLabel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default TextAreaWithLabel;
