import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import CountButton from "./CountButton";
import PlusIcon from "../../shared/icons/PlusIcon/PlusIcon";
import MinusIcon from "../../shared/icons/MinusIcon/MinusIcon";

const validateCount = (count, min, max) => {
  if (typeof min === "number" && typeof max === "number") {
    return count >= min && count <= max;
  }

  return true;
};

const CountInput = ({
  onChange,
  onIncrease,
  onDecrease,
  min,
  max,
  renderCount,
  value,
  defaultValue = 0,
  disabled = false
}) => {
  const [count, setCount] = useState(defaultValue);

  useEffect(() => {
    if (typeof value === "number") {
      setCount(value);
    }
  }, [value]);

  const handleClickIncrease = () => {
    const newCount = count + 1;

    if (validateCount(newCount, min, max)) {

      setCount(newCount);
      onChange && onChange(newCount);
      onIncrease && onIncrease(newCount);
    }
  };

  const handleClickDecrease = () => {
    const newCount = count - 1;

    if (validateCount(newCount, min, max)) {

      setCount(newCount);
      onChange && onChange(newCount);
      onDecrease && onDecrease(newCount);
    }
  };

  const handleChange = (val) => {
    setCount(val);
    onChange && onChange(val);
  };

  return (
    <div className="count-input">
      <div className="count-input__item left">
        <CountButton
          disabled={!(count > min) || disabled}
          onClick={handleClickDecrease}
          icon={<MinusIcon/>}
        />
      </div>

      <div className="count-input__item count-input__central-item">
        {renderCount ? renderCount({ count, handleChange }) : <span className="count-input__count-value">{count}</span>}
      </div>

      <div className="count-input__item right">
        <CountButton
          disabled={!(count < max) || disabled}
          onClick={handleClickIncrease}
          icon={<PlusIcon/>}
        />
      </div>
    </div>
  );
};

CountInput.propTypes = {
  onChange: PropTypes.func,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  renderCount: PropTypes.func,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool
};

export default CountInput;
