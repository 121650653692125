import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const dashboard = {
  async getListings(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/statistics/listings?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getUsers(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/statistics/users?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getDisputes(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/statistics/disputes?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getFees(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/statistics/fees?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getNotifications(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/statistics/notifications?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getFunds(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/statistics/funds?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getGeolocationData(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/statistics/geolocation-data?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getSalesListings(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/statistics/sales-listings?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default dashboard;
