import * as types from "./staticPagesActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  page: {
    content: ""
  }
};

const staticPage = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_STATIC_PAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        page: action.response
      };
    case types.ADMIN_GET_STATIC_PAGE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_UPDATE_STATIC_PAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        page: action.response
      };
    case types.ADMIN_UPDATE_STATIC_PAGE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_CLEAR_STATIC_PAGE_ERROR:
      return {
        ...state,
        error: ""
      };
    default:
      return state;
  }
};

export default staticPage;
