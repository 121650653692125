export const FAQ_SHOW_LOADING = "SHOW_LOADING";
export const FAQ_HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_CREATE_QUESTION_REQUEST = "ADMIN_CREATE_QUESTION_REQUEST";
export const ADMIN_CREATE_QUESTION_REQUEST_SUCCESS = "ADMIN_CREATE_QUESTION_REQUEST_SUCCESS";
export const ADMIN_CREATE_QUESTION_REQUEST_ERROR = "ADMIN_CREATE_QUESTION_REQUEST_ERROR";

export const ADMIN_UPDATE_QUESTION_REQUEST = "ADMIN_UPDATE_QUESTION_REQUEST";
export const ADMIN_UPDATE_QUESTION_REQUEST_SUCCESS = "ADMIN_UPDATE_QUESTION_REQUEST_SUCCESS";
export const ADMIN_UPDATE_QUESTION_REQUEST_ERROR = "ADMIN_UPDATE_QUESTION_REQUEST_ERROR";

export const ADMIN_GET_QUESTION_REQUEST = "ADMIN_GET_QUESTION_REQUEST";
export const ADMIN_GET_QUESTION_REQUEST_SUCCESS = "ADMIN_GET_QUESTION_REQUEST_SUCCESS";
export const ADMIN_GET_QUESTION_REQUEST_ERROR = "ADMIN_GET_QUESTION_REQUEST_ERROR";

export const ADMIN_GET_QUESTIONS_REQUEST = "ADMIN_GET_QUESTIONS_REQUEST";
export const ADMIN_GET_QUESTIONS_REQUEST_SUCCESS = "ADMIN_GET_QUESTIONS_REQUEST_SUCCESS";
export const ADMIN_GET_QUESTIONS_REQUEST_ERROR = "ADMIN_GET_QUESTIONS_REQUEST_ERROR";

export const ADMIN_DELETE_QUESTION_REQUEST = "ADMIN_DELETE_QUESTION_REQUEST";
export const ADMIN_DELETE_QUESTION_REQUEST_SUCCESS = "ADMIN_DELETE_QUESTION_REQUEST_SUCCESS";
export const ADMIN_DELETE_QUESTION_REQUEST_ERROR = "ADMIN_DELETE_QUESTION_REQUEST_ERROR";

export const ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST = "ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST";
export const ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_SUCCESS = "ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_SUCCESS";
export const ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_ERROR = "ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_ERROR";

export const ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST = "ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST";
export const ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST_SUCCESS = "ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST_SUCCESS";
export const ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST_ERROR = "ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST_ERROR";

export const ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST = "ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST";
export const ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST_SUCCESS = "ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST_SUCCESS";
export const ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST_ERROR = "ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST_ERROR";

export const ADMIN_GET_QUESTIONS_CATEGORY_REQUEST = "ADMIN_GET_QUESTIONS_CATEGORY_REQUEST";
export const ADMIN_GET_QUESTIONS_CATEGORY_REQUEST_SUCCESS = "ADMIN_GET_QUESTIONS_CATEGORY_REQUEST_SUCCESS";
export const ADMIN_GET_QUESTIONS_CATEGORY_REQUEST_ERROR = "ADMIN_GET_QUESTIONS_CATEGORY_REQUEST_ERROR";

export const ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST = "ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST";
export const ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST_SUCCESS = "ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST_SUCCESS";
export const ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST_ERROR = "ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST_ERROR";

export const ADMIN_RESET_QUESTIONS_ORDER_REQUEST = "ADMIN_RESET_QUESTIONS_ORDER_REQUEST";
export const ADMIN_RESET_QUESTIONS_ORDER_REQUEST_SUCCESS = "ADMIN_RESET_QUESTIONS_ORDER_REQUEST_SUCCESS";
export const ADMIN_RESET_QUESTIONS_ORDER_REQUEST_ERROR = "ADMIN_RESET_QUESTIONS_ORDER_REQUEST_ERROR";

export const ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST = "ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST";
export const ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST_SUCCESS = "ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST_SUCCESS";
export const ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST_ERROR = "ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST_ERROR";

export const ADMIN_CLEAR_QUESTIONS_CATEGORY_ERROR = "ADMIN_CLEAR_QUESTIONS_CATEGORY_ERROR";

export const ADMIN_CLEAR_QUESTION = "ADMIN_CLEAR_QUESTION";
export const ADMIN_CLEAR_QUESTION_ERROR = "ADMIN_CLEAR_QUESTION_ERROR";
