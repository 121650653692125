import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

const SellerWalletTabs = ({ location }) => {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  return (
    <div className="seller-account-sidebar">
      <Menu
        className="menu-tabs"
        mode="horizontal"
        selectedKeys={[active]}
      >
        <Menu.Item key="wallet">
          <Link to="/seller/wallet">Wallet</Link>
        </Menu.Item>
        <Menu.Item key="bank-account">
          <Link to="/seller/bank-account">Bank Account</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

SellerWalletTabs.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(SellerWalletTabs);
