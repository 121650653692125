import * as types from "./categoryActionsTypes";

export const getCategoryListRequest = (data) => {
  return { type: types.ADMIN_GET_CATEGORY_LIST_REQUEST, data };
};

export const createCategoryRequest = (data) => {
  return { type: types.ADMIN_CREATE_CATEGORY_REQUEST, data };
};

export const deleteCategoryRequest = (data) => {
  return { type: types.ADMIN_DELETE_CATEGORY_REQUEST, data };
};

export const clearCategoryErrorAction = () => {
  return { type: types.ADMIN_CLEAR_CATEGORY_ERROR };
};
