import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

export const SpinContainer = ({ children, className, loading }) => {
  return (
    <div className={`ant-spin-nested-loading${className ? ` ${className}` : ""}`}>
      <div className={`ant-spin-container${loading ? " ant-spin-blur" : ""}`}>
        {children}
        {loading && <Spin />}
      </div>
    </div>
  )
};

SpinContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};
