import React from "react";
import { Controller } from "react-hook-form";
import { Select, Tooltip } from "antd";
import PropTypes from "prop-types";

import Label from "./Form/Label";

const SelectWithLabel = ({
  id,
  mode,
  className = "",
  name,
  control,
  onChange,
  options = [],
  label = "",
  tooltip,
  error = "",
  // eslint-disable-next-line
  defaultValue = undefined,
  placeholder = "",
  isInvalid = false,
  showSearch = false,
  disabled = false
}) => {
  if (!onChange) {
    onChange = value => value[0];
  }

  return (
    <div className={`select-with-label__field ${className}`}>
      {label &&
      <Label
        htmlFor={id}
        className={`${(error || isInvalid) ? "select-with-label__label--error" : ""}`}
      >
        {label}
        {
          tooltip &&
          <Tooltip placement="topLeft" title={tooltip} className="profile__field-tooltip" arrowPointAtCenter>
            <span>*</span>
          </Tooltip>
        }
      </Label>
      }
      <Controller
        name={name}
        defaultValue={defaultValue}
        as={<Select
          id={id}
          mode={mode}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          showSearch={showSearch}
          disabled={disabled}
          optionFilterProp="children"
          className={`form-select ${(error || isInvalid) ? "select-with-label__input--error" : ""}`}
          data-testid={id}
        >
          {options.map((option, key) => <Select.Option
            key={`${key} ${option.value}`}
            value={option.value}
          >
            {option.label}
          </Select.Option>)}
        </Select>}
        control={control}
        onChange={onChange}
      />
      {error && <span className="select-with-label__error" data-testid="select-with-label-error">{error.message}</span>}
    </div>
  );
};

SelectWithLabel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  error: PropTypes.object,
  options: PropTypes.array,
  mode: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  showSearch: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SelectWithLabel;
