import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

const PartnersList = ({ partners = [] }) => {
  return (
    <div className="partners-list">
      {partners.map(partner => {
        return (
          <div key={partner.id} className="partners-list__col">
            <LazyLoad scrollContainer="body">
              <img className="partners-list__image" src={partner.img} alt={partner.name}/>
            </LazyLoad>
          </div>
        );
      })}
    </div>
  );
};

PartnersList.propTypes = {
  partners: PropTypes.array.isRequired
};

export default PartnersList;
