import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Menu, Table } from "antd";
import moment from "moment";

import { getTransactionsRequest } from "../../../redux/profile/profileActions";
import { TRANSACTION_TYPE_PURCHASE, TRANSACTION_TYPE_REFUND, TRANSACTION_TYPE_WITHDRAW } from "../../constants";
import { getCurrencyBadge } from "../../../helpers/common";
import Pagination from "../../shared/Pagination";

const MAX_ROWS_PER_PAGE = 10;

const typesLabels = {
  withdraw: "withdrawal",
  refund: "withdrawal",
  purchase: "purchase",
  pending: "pending",
  "in-dispute": "pending"
};

const getHotelImage = (listing) => {
  if (listing && listing.Hotel.HotelImages.length) {
    return listing.Hotel.HotelImages[0].imageUrl;
  }

  return null;
};

const renderType = type => {
  switch (type) {
    case "pending":
      return (
        <div className="transactions__pending-item">Pending</div>
      );
    case "withdrawal":
      return (
        <img src="/images/icons/arrow-down-icon.svg" alt="icon" className="transactions__icon"/>
      );
    case "purchase":
      return (
        <img src="/images/icons/arrow-up-icon.svg" alt="icon" className="transactions__icon"/>
      );
    default:
      return null;
  }
};

const renderTitle = (text, record) => {
  return (
    <div key={record.key} className="transactions__type">
      {record.hotelImage &&
      <img className="transactions__hotel-image" alt="hotel image" src={record.hotelImage}/>}
      <span className="transactions__title">{text}</span>
    </div>
  );
};

const renderAmount = (amount, record) => {
  const pendingClassName = record.type === "pending" ? "transactions__pending-amount" : "";

  return (
    <div className="transactions__amount-wrapper">
      {renderType(record.type)}
      <span className={`transactions__amount ${pendingClassName}`}>{amount}</span>
    </div>
  );
};

export const Transactions = ({ transactions, pagination, currency, isProcessing, getTransactions }) => {
  const [paginationObj, setPaginationObj] = useState({
    pageSize: 10,
    type: null,
    sort: "-createdAt"
  });

  useEffect(() => {
    getTransactions(paginationObj);
  }, [paginationObj]);

  const handlePaginationChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleFilterByType = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, type: value });
  };

  return (
    <div className="transactions form-container">
      <h2 className="form-subtitle">Transactions History</h2>
      <Menu
        className="menu-tabs"
        mode="horizontal"
        defaultSelectedKeys={["your-account"]}
      >
        <Menu.Item key="your-account" onClick={() => handleFilterByType(null)}>Your account</Menu.Item>
        <Menu.Item key="purchase" onClick={() => handleFilterByType("purchase")}>Proceeds</Menu.Item>
        <Menu.Item key="withdraw" onClick={() => handleFilterByType("withdraw")}>Withdrawals</Menu.Item>
        <Menu.Item key="pending" onClick={() => handleFilterByType("pending")}>Pending</Menu.Item>
      </Menu>
      <Table
        loading={isProcessing}
        pagination={false}
        dataSource={transactions.map(transaction => {

          return {
            key: transaction.id,
            hotelName: [
              TRANSACTION_TYPE_WITHDRAW,
              TRANSACTION_TYPE_REFUND
            ].includes(transaction.type) ? "Withdrawn" : transaction.Listing.Hotel.name,
            hotelImage: getHotelImage(transaction.Listing),
            type: typesLabels[transaction.type],
            createdAt: moment(transaction.createdAt).format("DD MMM YYYY"),
            createdAtHours: moment(transaction.createdAt).format("HH:mmA"),
            amount: transaction.type === TRANSACTION_TYPE_PURCHASE
              ? `${getCurrencyBadge(currency)}${(+transaction.sum).toFixed(2)}`
              : (
                transaction.sum.substring(0, 1) === "-"
                  ? `${getCurrencyBadge(currency)}${(+transaction.sum.replace("-", "")).toFixed(2)}`
                  : `${getCurrencyBadge(currency)}${(+transaction.sum).toFixed(2)}`
              )
          };
        })}
        columns={[
          {
            title: "Title",
            dataIndex: "hotelName",
            key: "hotelName",
            sorter: true,
            render: renderTitle
          },
          {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt"
          },
          {
            title: "Date Hours",
            dataIndex: "createdAtHours",
            key: "createdAtHours"
          },
          {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: renderAmount
          }
        ]}
      />
      {transactions && transactions.length > MAX_ROWS_PER_PAGE ?
        <div className="transactions__pagination-container">
          <Pagination
            onChange={handlePaginationChange}
            total={pagination.total}
            pageSize={pagination.pageSize}
            current={pagination.current}
          />
        </div> :
        null
      }
    </div>
  );
};

Transactions.propTypes = {
  error: PropTypes.string,
  pagination: PropTypes.object.isRequired,
  transactions: PropTypes.array.isRequired,
  getTransactions: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired
};

export default connect(
  state => ({
    error: state.profile.error,
    isProcessing: state.profile.processing,
    pagination: {
      current: state.profile.transactions.curPage,
      total: state.profile.transactions.total,
      pageSize: state.profile.transactions.perPage
    },
    transactions: state.profile.transactions.data,
    currency: state.profile.permissions.currency
  }),
  {
    getTransactions: getTransactionsRequest
  }
)(Transactions);
