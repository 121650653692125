import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import moment from "moment";
import parse from "html-react-parser";
import LazyLoad from "react-lazyload";

const BlogSingleArticle = ({ article }) => {
  return (
    <div className="blog-single-article">
      <Row justify="space-around" align="middle" className="blog__container">
        <Col xs={{ span: 20 }} className="blog-single-article__container">
          <section className="blog-single-article__section">
            <div className="blog-single-article__categories">
              {article.categories.map(category => {
                return (
                  <span key={category.id} className="blog-single-article__category">{category.name}</span>
                );
              })}
            </div>
            <h1 className="blog-single-article__main-title">{article.title}</h1>
          </section>

          <section className="blog-single-article__section blog-single-article__info-container">
            <div className="blog-single-article__info-item">
              {
                article.author ?
                  <div className="blog-single-article__info-item-block">
                    <span>By PlansChange</span>
                  </div> :
                  null
              }
              <div className="blog-single-article__info-item-block">
                <span>{moment(article.publishDate).format("D MMMM YYYY")}</span>
              </div>
            </div>

            <div className="blog-single-article__info-item">
              <div className="blog-single-article__info-item-link">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_URL}/blog/articles/${article.slug}`}
                >
                  <img
                    className="blog-single-article__social-img"
                    src="/images/facebook-black-icon.svg"
                    alt="Share into Facebook"
                  />
                </a>
              </div>

              <div className="blog-single-article__info-item-link">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={`https://twitter.com/share?text=${article.title}&url=${process.env.REACT_APP_URL}/blog/articles/${article.slug}`}
                >
                  <img
                    className="blog-single-article__social-img"
                    src="/images/twitter-black-icon.svg"
                    alt="Share into Twitter"
                  />
                </a>
              </div>

              <div className="blog-single-article__info-item-link">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={`https://wa.me/?text=${article.title} ${process.env.REACT_APP_URL}/blog/articles/${article.slug}`}
                >
                  <img
                    className="blog-single-article__social-img"
                    src="/images/whatsapp-black-icon.svg"
                    alt="Share into WhatsApp"
                  />
                </a>
              </div>
            </div>
          </section>
          <section className="blog-single-article__section">
            <div className="blog-single-article__main-image-container">
              <LazyLoad scrollContainer="body">
                <img className="blog-single-article__main-image" alt="main image" src={article.coverImageUrl}/>
              </LazyLoad>
            </div>
          </section>
          <div className="blog-single-article__body-content">
            {parse(article.body)}
          </div>
        </Col>
      </Row>
    </div>
  );
};

BlogSingleArticle.propTypes = {
  article: PropTypes.object.isRequired
};

export default BlogSingleArticle;
