import * as types from "./staticPagesActionsTypes";

export const getPublicStaticPageRequest = data => {
  return { type: types.GET_STATIC_PAGE_REQUEST, data };
};

export const postFeedbackRequest = data => {
  return { type: types.POST_FEEDBACK_REQUEST, data };
};

export const getFaqRequest = data => {
  return { type: types.GET_FAQ_REQUEST, data };
};

export const resetStaticPageAction = data => {
  return { type: types.RESET_STATIC_PAGE, data };
};
