import axios from "../../helpers/axios";

import { API_URL } from "../constants";
import * as queryString from "query-string";

const finance = {
  async getChargeOptions() {
    try {
      const result = await axios.get(`${API_URL}/admin/finance/charge-options`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateChargeOptions(data) {
    try {
      const result = await axios.put(`${API_URL}/admin/finance/charge-options/${data.id}`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async setDefaultCommission() {
    try {
      const result = await axios.patch(`${API_URL}/admin/finance/reset-to-defaults`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getFeeConfigurations() {
    try {
      const result = await axios.get(`${API_URL}/admin/finance/fee-configurations`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateFeeConfiguration(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/finance/fee-configurations/${data.id}`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getListingsForAccounting(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/finance/accounting-listings?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getListingsForAccountingCsvExportUrl(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/finance/accounting-listings/export-csv?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getPaymentFeesConfigurations() {
    try {
      const result = await axios.get(`${API_URL}/admin/finance/payment-fees-configurations`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updatePaymentFeesConfiguration(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/finance/payment-fees-configurations/${data.id}`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default finance;
