import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./assets/styles/index.scss";
import App from "./react/App";
import * as serviceWorker from "./serviceWorker";
import { hasSSR, store } from "./redux/configureStore";

// Render App
const renderFunc = hasSSR ? ReactDOM.hydrate : ReactDOM.render;
renderFunc(
  <Provider store={store}>
    <Router>
      <App/>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
