import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from "antd"

import Image from "../../shared/Image"

const UpdatesCard = ({ path, isMain, imageUrl, alt, title, text }) => {
  const classMain = isMain ? "update-card-main" : "update-card-secondary";

  return (
    <Link to={path} className={`update-card ${classMain}`}>
      <Image imageUrl={imageUrl} alt={alt} loading="eager"/>
      {title &&
        <Typography.Title level={2} className="blog__section-title update-card__title">
          {title}
        </Typography.Title>
      }
      {text &&
       <Typography.Paragraph level={2} className="update-card__text">
         {text}
       </Typography.Paragraph>
      }
    </Link>
  )
};

UpdatesCard.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  isMain: PropTypes.bool,
  imageUrl: PropTypes.string,
  alt: PropTypes.string
};

export default UpdatesCard;
