import React from "react";
import { Row, Col, } from "antd"

import InternalLink from "../../shared/InternalLink";

const SuccessDispute = () => {
  return (
    <Row justify="space-around" align="middle" className="share-listing">
      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
        <Row>
          <Col span={24} className="share-listing__container">
            <div>
              <img src="/images/success.svg" className="share-listing__icon" alt="success" />
              <div className="share-listing__text">
                Your dispute is now raised.<br/>
                For more details check your email.
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <InternalLink to="/profile/purchases">
            Your purchases
          </InternalLink>
        </Row>
      </Col>
    </Row>
  );
}

export default SuccessDispute;
