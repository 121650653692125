import * as types from "./userManagementActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  requestSuccess: false,
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: [],
  KYCFile: null,
  singleUser: {
    profileHistory: [],
    user: {
      balance: 0,
      withdrawn: 0,
      purchased: {
        gbp: 0,
        usd: 0
      },
      pending: 0
    },
    listings: {
      total: 0,
      curPage: 1,
      pages: 1,
      perPage: 10,
      data: []
    }
  }
};

const userManagement = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_USER_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: ""
      };
    case types.ADMIN_UPDATE_USER_REQUEST_SUCCESS:
      return {
        ...state,
        data: state.data.map(user => user.id === action.response.id ? { ...user, ...action.response } : user),
        singleUser: {
          ...state.singleUser,
          user: state.singleUser.user.id === action.response.id
            ? { ...state.singleUser.user, ...action.response }
            : state.singleUser.user
        },
        error: ""
      };
    case types.ADMIN_GET_USER_REQUEST_SUCCESS:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          ...action.response
        },
        error: ""
      };
    case types.ADMIN_DOWNLOAD_KYC_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        KYCFile: action.response
      };
    case types.ADMIN_GET_USER_LIST_REQUEST_ERROR:
    case types.ADMIN_GET_USER_REQUEST_ERROR:
    case types.ADMIN_UPDATE_USER_REQUEST_ERROR:
    case types.ADMIN_DOWNLOAD_KYC_FILE_REQUEST_ERROR:
    case types.ADMIN_GET_USER_LISTINGS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_CLEAR_USER_KYC_FILE:
      return {
        ...state,
        KYCFile: null
      };
    case types.ADMIN_GET_USER_REQUEST:
    case types.ADMIN_CLEAR_USER:
      return {
        ...state,
        singleUser: {
          listings: {
            data: []
          },
          profileHistory: [],
          user: {
            purchased: {}
          }
        }
      };
    case types.ADMIN_GET_USER_LISTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          listings: action.response,
        }
      };
    case types.ADMIN_CLEAR_USER_ERROR:
      return {
        ...state,
        error: "",
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default userManagement;
