import React from "react";
import PropTypes from "prop-types";
import { Col, Row, DatePicker } from "antd";
import moment from "moment";

const SectionHeading = ({ title, searchQuery, sectionKey, setSearchQuery }) => {
  return (
    <Row justify="space-between" align="middle" key={sectionKey}>
      <Col className="admin-dashboard__section-title">{ title }</Col>
      <Col>
        <DatePicker.RangePicker
          showArrow={true}
          className="admin-dashboard__date-range-picker"
          format="DD-MM-YYYY"
          value={[
            searchQuery.startDate ? moment(searchQuery.startDate) : null,
            searchQuery.endDate ? moment(searchQuery.endDate) : null
          ]}
          onChange={dates => {
            if (dates && dates[0] && dates[1]) {
              setSearchQuery({
                startDate: dates[0].format("YYYY-MM-DD"),
                endDate: dates[1].format("YYYY-MM-DD")
              });
            } else {
              setSearchQuery({
                startDate: null,
                endDate: null
              });
            }
          }}
        />
      </Col>
    </Row>
  );
};

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  searchQuery: PropTypes.object.isRequired,
  sectionKey: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired
};

export default SectionHeading;
