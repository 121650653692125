export const ADMIN_HOTELS_SHOW_LOADING = "ADMIN_HOTELS_SHOW_LOADING";
export const ADMIN_HOTELS_HIDE_LOADING = "ADMIN_HOTELS_HIDE_LOADING";

export const ADMIN_GET_HOTELS_REQUEST = "ADMIN_GET_HOTELS_REQUEST";
export const ADMIN_GET_HOTELS_REQUEST_SUCCESS = "ADMIN_GET_HOTELS_REQUEST_SUCCESS";
export const ADMIN_GET_HOTELS_REQUEST_ERROR = "ADMIN_GET_HOTELS_REQUEST_ERROR";

export const ADMIN_DELETE_HOTEL_REQUEST = "ADMIN_DELETE_HOTEL_REQUEST";
export const ADMIN_DELETE_HOTEL_REQUEST_SUCCESS = "ADMIN_DELETE_HOTEL_REQUEST_SUCCESS";
export const ADMIN_DELETE_HOTEL_REQUEST_ERROR = "ADMIN_DELETE_HOTEL_REQUEST_ERROR";

export const ADMIN_GET_HOTELS_FACILITIES_REQUEST = "ADMIN_GET_HOTELS_FACILITIES_REQUEST";
export const ADMIN_GET_HOTELS_FACILITIES_REQUEST_SUCCESS = "ADMIN_GET_HOTELS_FACILITIES_REQUEST_SUCCESS";
export const ADMIN_GET_HOTELS_FACILITIES_REQUEST_ERROR = "ADMIN_GET_HOTELS_FACILITIES_REQUEST_ERROR";

export const ADMIN_ADD_HOTEL_REQUEST = "ADMIN_ADD_HOTEL_REQUEST";
export const ADMIN_ADD_HOTEL_REQUEST_SUCCESS = "ADMIN_ADD_HOTEL_REQUEST_SUCCESS";
export const ADMIN_ADD_HOTEL_REQUEST_ERROR = "ADMIN_ADD_HOTEL_REQUEST_ERROR";

export const ADMIN_GET_HOTEL_REQUEST = "ADMIN_GET_HOTEL_REQUEST";
export const ADMIN_GET_HOTEL_REQUEST_SUCCESS = "ADMIN_GET_HOTEL_REQUEST_SUCCESS";
export const ADMIN_GET_HOTEL_REQUEST_ERROR = "ADMIN_GET_HOTEL_REQUEST_ERROR";

export const ADMIN_UPDATE_HOTEL_REQUEST = "ADMIN_UPDATE_HOTEL_REQUEST";
export const ADMIN_UPDATE_HOTEL_REQUEST_SUCCESS = "ADMIN_UPDATE_HOTEL_REQUEST_SUCCESS";
export const ADMIN_UPDATE_HOTEL_REQUEST_ERROR = "ADMIN_UPDATE_HOTEL_REQUEST_ERROR";

export const ADMIN_CLEAR_HOTEL_DETAILS_ACTION = "ADMIN_CLEAR_HOTEL_DETAILS_ACTION";
