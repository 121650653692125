import * as types from "./generalActionsTypes";

export const initialState = {
  processing: false,
  requestToken: "",
  modal: {
    className: "",
    isHidden: true,
    type: "",
    width: null,
    title: "",
    params: {}
  },
  subscribeToMarketingCampaignSuccess: false,
  subscribedEmail: null,
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REQUEST_TOKEN:
      return {
        ...state,
        requestToken: action.payload,
      };
    case types.SHOW_MODAL:
      return {
        ...state,
        modal: {
          ...initialState.modal,
          isHidden: false,
          ...action.data,
        }
      };
    case types.HIDE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          isHidden: true,
        }
      };
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST_SUCCESS:
      return {
        ...state,
        subscribeToMarketingCampaignSuccess: true,
        subscribedEmail: action.data.email,
      };
    case types.SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST_ERROR:
      return {
        ...state,
        subscribeToMarketingCampaignSuccess: false
      };
    case types.CLEAR_SUBSCRIBED_STATUS_STATE:
      return {
        ...state,
        subscribeToMarketingCampaignSuccess: false
      };
    case types.CLEAR_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default general;
