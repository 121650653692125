import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Table, Select, Input } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

import { getDisputeListRequest, updateDisputeRequest } from "../../../redux/admin/dispute/disputeActions";
import { itemRender } from "../../../helpers/pagination";
import { hideModalAction } from "../../../redux/general/generalActions";

const DisputeList = ({
  disputeList,
  pagination,
  requestSuccess,
  isProcessing,
  history,
  getDisputeList,
  updateDispute,
  hideModal
}) => {
  const [paginationObj, setPaginationObj] = useState({
    country: "GB",
    pageSize: 10,
    sort: "-createdAt",
    status: null
  });

  useEffect(() => {
    getDisputeList(paginationObj);
  }, [paginationObj]);

  useEffect(() => {
    hideModal();
  }, [requestSuccess]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleFilterByStatus = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, status: value });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderLink = (text, record) => <Link to={`/admin/disputes/${record.key}`} className="feedback-list__link">
    {text}
  </Link>;

  const renderStatusSelect = (text, record) => {

    return (
      <span>
        <Select
          id={`status-${record.key}`}
          className="dispute-list__filter-status"
          disabled={isProcessing}
          value={text}
          onChange={(data) => {
            if (record.status === "raised" && data === "in-review") {
              updateDispute({ id: record.disputeRef, body: { status: "in-review" } });
            } else {
              history.push(`/admin/disputes/${record.disputeRef}`);
            }
          }}
        >
          <Select.Option value="raised">Raised</Select.Option>
          <Select.Option value="in-review">In review</Select.Option>
          <Select.Option value="accepted">Accepted</Select.Option>
          <Select.Option value="partially-accepted">Partially accepted</Select.Option>
          <Select.Option value="rejected">Rejected</Select.Option>
        </Select>
      </span>
    );
  };

  return (
    <React.Fragment>
      <Row className="feedback-list">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" align="top">
                <Col span={18} className="feedback-list__filters">
                  <span className="feedback-list__filter">Show items</span>
                  <Select
                    className="feedback-list__filter"
                    defaultValue={paginationObj.pageSize}
                    onChange={value => {
                      setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
                    }}
                  >
                    <Select.Option value="10">10</Select.Option>
                    <Select.Option value="20">20</Select.Option>
                    <Select.Option value="30">30</Select.Option>
                  </Select>
                  <Select
                    className="feedback-list__filter dispute-list__filter-status"
                    defaultValue={paginationObj.status}
                    onChange={handleFilterByStatus}
                  >
                    <Select.Option value={null}>All</Select.Option>
                    <Select.Option value="raised">Raised</Select.Option>
                    <Select.Option value="in-review">In review</Select.Option>
                    <Select.Option value="accepted">Accepted</Select.Option>
                    <Select.Option value="partially-accepted">Partially accepted</Select.Option>
                    <Select.Option value="rejected">Rejected</Select.Option>
                  </Select>
                  <span className="admin-listings__select-country">Select country:</span>
                  <Select
                    className="feedback-list__filter"
                    defaultValue={paginationObj.country}
                    onChange={value => {
                      setPaginationObj({ ...paginationObj, page: 1, country: value });
                    }}
                  >
                    <Select.Option value="GB">GB</Select.Option>
                    <Select.Option value="US">US</Select.Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <Input.Search
                    onChange={event => delayedSearch(event.target.value)}
                    onSearch={handleSearch}
                    placeholder="Search"
                    className="feedback-list__search"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isProcessing}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  itemRender: itemRender,
                  showSizeChanger: false
                }}
                dataSource={disputeList.map(dispute => {
                  return {
                    key: dispute.id,
                    disputeRef: dispute.id,
                    listingId: dispute.listingId,
                    name: dispute.Author ? `${dispute.Author.firstName} ${dispute.Author.lastName}` : "No name",
                    details: dispute.reasons.join(", "),
                    createdAt: moment(dispute.createdAt).format("DD MMM YYYY, hh:mmA"),
                    status: dispute.status
                  }
                })}
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                    render: renderLink,
                    width: 150
                  },
                  {
                    title: "Dispute Ref.",
                    dataIndex: "disputeRef",
                    key: "disputeRef",
                  },
                  {
                    title: "Details",
                    dataIndex: "details",
                    key: "details",
                  },
                  {
                    title: "Listing Ref.",
                    dataIndex: "listingId",
                    key: "listingId",
                  },
                  {
                    title: "Date/Time",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                    sortDirections: ["descend", "ascend"],
                    width: 145
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: renderStatusSelect
                  }
                ]}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
};

DisputeList.propTypes = {
  disputeList: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  getDisputeList: PropTypes.func.isRequired,
  updateDispute: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    disputeList: state.admin.dispute.data,
    pagination: {
      current: state.admin.dispute.curPage,
      total: state.admin.dispute.total,
      pageSize: state.admin.dispute.perPage
    },
    requestSuccess: state.admin.dispute.requestSuccess,
    isProcessing: state.admin.dispute.processing,
  }),
  {
    getDisputeList: getDisputeListRequest,
    updateDispute: updateDisputeRequest,
    hideModal: hideModalAction
  }
)(DisputeList);
