import React from "react";
import { Col, Row } from "antd";

import InternalLink from "../shared/InternalLink"

export const PageNotFound = () => {
  return (
    <Row justify="space-around" align="middle" className="page-not-found">
      <Col span={24}>
        <Row>
          <Col span={24} className="page-not-found__title">
            404
          </Col>
        </Row>
        <Row>
          <Col span={24} className="page-not-found__text">
            The page you were looking for<br/> couldn’t be found
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InternalLink to="/" className="page-not-found__link">Go back to the homepage</InternalLink>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

export default PageNotFound;
