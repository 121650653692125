import axios from "../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "./constants";

const blog = {
  async getArticleList(data) {
    try {
      const result = await axios.get(`${API_URL}/articles?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getArticle(data) {
    try {
      const result = await axios.get(`${API_URL}/articles/slug/${data.slug}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getCategoryList(data) {
    try {
      const result = await axios.get(`${API_URL}/categories?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getRelatedArticles(data) {
    try {
      const result = await axios.get(`${API_URL}/articles/slug/${data.slug}/related?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default blog;
