import * as types from "./disputeActionsTypes";

export const getDisputeListRequest = data => {
  return { type: types.ADMIN_GET_DISPUTE_LIST_REQUEST, data };
};

export const getDisputeRequest = data => {
  return { type: types.ADMIN_GET_DISPUTE_REQUEST, data };
};

export const updateDisputeRequest = data => {
  return { type: types.ADMIN_UPDATE_DISPUTE_REQUEST, data };
};

export const createDisputeNoteRequest = data => {
  return { type: types.ADMIN_CREATE_DISPUTE_NOTE_REQUEST, data };
};
