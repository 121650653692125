import { select } from "redux-saga/effects";
import ssrPromiseHelper from "./ssrPromiseHelper";


function serverSagaWrapper(saga) {
  return function *(params) {
    const requestToken = yield select(state => (state && state.general) ? state.general.requestToken : null);

    // This is executed on server
    if (ssrPromiseHelper.bucket[requestToken] && ssrPromiseHelper.bucket[requestToken].shouldTrack) {
      // Create promise
      let promiseResolve = null;
      ssrPromiseHelper.bucket[requestToken].promises[params.type] = new Promise(resolve => {
        promiseResolve = resolve
      });

      // Run passed saga
      yield saga(params);

      // Mark promise as resolved after saga is finished
      promiseResolve();
    }
    // This is executed on client
    else {
      yield saga(params);
    }
  }
}

export default serverSagaWrapper;
