import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SpinContainer } from "../../shared/SimpleComponents/SpinContainer/SpinContainer";
import ListingFeesConfigurationForm from "./ListingFeesConfigurationForm";

const ListingFeesConfiguration = ({
  paymentFeesConfigurations,
  isProcessing,
}) => {
  return (
    <SpinContainer loading={isProcessing}>
      <Row gutter={48}>
        {paymentFeesConfigurations.gbp.map((config) => {
          return (
            <Col span={12} key={`config${config.id}`}>
              <ListingFeesConfigurationForm paymentFeesConfigurations={config}/>
            </Col>
          )
        })}
        {paymentFeesConfigurations.usd.map((config) => {
          return (
            <Col span={12} key={`config${config.id}`}>
              <ListingFeesConfigurationForm paymentFeesConfigurations={config}/>
            </Col>
          )
        })}
      </Row>
    </SpinContainer>
  )
};

ListingFeesConfiguration.propTypes = {
  paymentFeesConfigurations: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    paymentFeesConfigurations: state.admin.finance.paymentFeesConfigurations,
    isProcessing: state.admin.finance.processing
  })
)(ListingFeesConfiguration);
