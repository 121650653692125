import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { useLocation } from "react-router";
import PropTypes from "prop-types";


const ListsNavTabs = ({ items }) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  return (
    <div>
      <Menu
        className="menu-tabs"
        mode="horizontal"
        selectedKeys={[active]}
      >
        {items.map(item => {
          return (
            <Menu.Item key={item.key}>
              <Link to={item.url}>{item.label}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
};

ListsNavTabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string
  })).isRequired
};

export default ListsNavTabs;
