import * as types from "./authActionsTypes";


//set auth state of application: true = is logged in, false = no user is logged in
export const signInRequest = data => {
  return { type: types.SIGN_IN_REQUEST, data };
};

export const signInSuccess = data => {
  return { type: types.SIGN_IN_REQUEST_SUCCESS, response: data };
};

export const signUpRequest = data => {
  return { type: types.SIGN_UP_REQUEST, data };
};

export const resendActivationEmailRequest = data => {
  return { type: types.RESEND_ACTIVATION_EMAIL_REQUEST, data };
};

export const signOutAction = data => {
  return { type: types.SIGN_OUT_REQUEST, data };
};

export const verifyAccountRequest = data => {
  return { type: types.VERIFY_ACCOUNT_REQUEST, data };
};

export const clearAuthErrorAction = () => {
  return { type: types.CLEAR_AUTH_ERROR };
};

export const resetPasswordRequest = data => {
  return { type: types.RESET_PASSWORD_REQUEST, data };
};

export const clearResetPasswordEmailStatusAction = () => {
  return { type: types.CLEAR_RESET_PASSWORD_EMAIL_STATUS };
};

export const setNewPasswordRequest = data => {
  return { type: types.SET_NEW_PASSWORD_REQUEST, data };
};

export const clearSetNewPasswordStatusAction = () => {
  return { type: types.CLEAR_SET_NEW_PASSWORD_STATUS };
};

export const clearVerifyAccountStatusAction = () => {
  return { type: types.CLEAR_VERIFY_ACCOUNT_STATUS };
};
