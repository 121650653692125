import React, { useEffect, useState } from "react";
import { Button, Col, Row, Upload } from "antd";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import SelectWithLabel from "../../shared/SelectWithLabel";
import { kycVerificationRequest } from "../../../redux/profile/profileActions";
import ProfileDetails from "../../shared/ProfileDetails/ProfileDetails";
import ProfileAddress from "../../shared/ProfileAddress/ProfileAddress";
import { profileGetRequest } from "../../../redux/profileDetails/profileDetailsActions";

const KnowYourCustomerSchema = yup.object().shape({
  firstName: yup.string()
    .trim()
    .max(25, "Max length of First name - 25 symbols")
    .required("First name can not be empty"),
  lastName: yup.string().trim().max(25, "Max length of Last name - 25 symbols").required("Last name can not be empty"),
  dateOfBirth: yup.mixed().required("Date of Birth can not be empty").test({
    name: "isDateOfBirthValid",
    test: value => {
      const date = moment.utc(value);

      return date.isValid() && Math.abs(date.diff(moment.utc(), "years")) >= 18;
    },
    message: "Date of Birth should be valid date and you must be over the age of 18 to use our service"
  }),
  nationality: yup.mixed().required("Nationality can not be empty"),
  phone: yup.string().trim().test({
    name: "phoneLength",
    test: value => !!value && /[0-9]/.test(value) && value.length > 10 && value.length < 20
  }),
  address1: yup.string().max(255, "Address could be maximum 255 symbols")
    .required("Address can not be empty")
    .nullable(),
  city: yup.string().max(255, "City could be maximum 255 symbols")
    .required("City name can not be empty")
    .nullable(),
  country: yup.mixed().required("Country / Region can not be empty"),
  state: yup.mixed().when("country", {
    is: value => value && ["US"].includes(value),
    then: yup.mixed().required("State can not be empty"),
    otherwise: yup.mixed().notRequired()
  }),
  zipCode: yup.string().max(25, "Zip/Post could be maximum 255 symbols")
    .required("Zip/Post code  can not be empty")
    .nullable(),
  documentType: yup.string().trim().required("Document type can not be empty")
    .oneOf(["passport", "driver_license"], "Please select correct credit card type"),
  document: yup.mixed().required("Document can not be empty")
});

export const KnowYourCustomer = ({ profile, address, isProcessing, verifyKYC, getProfile }) => {
  const { handleSubmit, errors, control, setValue, reset, watch } = useForm({
    validationSchema: KnowYourCustomerSchema
  });

  const [files, setFiles] = useState([]);
  const [fileTypeError, setFileFileTypeError] = useState(false);
  const [phone, setPhone] = useState({});

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (profile.id) {
      setPhone({
        number: profile.phoneNumber,
        countryCode: profile.phoneCountryCode
      });

      reset({
        firstName: profile.first_name,
        lastName: profile.last_name,
        dateOfBirth: profile.dateOfBirth ? moment.utc(profile.dateOfBirth) : null,
        nationality: profile.nationality,
        phone: profile?.phoneCountryCode ? `${profile.phoneCountryCode}${profile.phoneNumber}` : "+44",
        address1: address.address1,
        city: address.city,
        country: address.country,
        state: address.state,
        zipCode: address.zipCode
      });
    }
  }, [profile]);

  const deleteFile = fileUid => {
    const filesList = files.filter(file => file.uid !== fileUid);

    setFiles(filesList);
    setFileFileTypeError(false);

    if (!filesList.length) {
      setValue("document", null);
    }
  };

  const kycVerificationHandler = (data) => {
    const isFilesValid = files.reduce((isValid, file) => {
      return ["application/pdf", "image/jpeg", "image/jpg", "image/gif", "image/png"].includes(file.type);
    }, true);

    if (!isFilesValid) {
      setFileFileTypeError(true);

      return;
    }

    verifyKYC({
      ...data,
      files,
      phoneCountryCode: phone.countryCode,
      phoneNumber: phone.number
    });
  };

  return (
    <Row className="know-your-customer">
      <Col span={24}>
        <Row justify="space-between" align="start">
          <h2 className="form-subtitle">Know Your Customer</h2>
        </Row>
        <form onSubmit={handleSubmit(kycVerificationHandler)} className="know-your-customer__form">
          <Row gutter={32} className="know-your-customer__profile">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <ProfileDetails
                profile={profile}
                errors={errors}
                control={control}
                setValue={setValue}
                setPhone={setPhone}
                isProcessing={isProcessing}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <ProfileAddress
                address={address}
                errors={errors}
                control={control}
                isProcessing={isProcessing}
                setValue={setValue}
                watch={watch}
              />
            </Col>
          </Row>
          <Row justify="space-between" align="start">
            <Col
              xs={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 8 }}
              className={`file-upload__container${errors.document ? " file-upload__container--error" : ""}`}
            >
              <Row>
                <Col span={24}>
                  <Controller
                    name="document"
                    as={<Upload.Dragger
                      className="form-file-upload__container"
                      multiple={true}
                      accept=".pdf,.jpeg,.jpg,.gif,.png"
                      beforeUpload={(file, fileList) => {
                        setFiles([
                          ...files,
                          ...fileList
                        ]);

                        return false;
                      }}
                      showUploadList={false}
                      fileList={files}
                    >
                      <div className="file-upload__text-container">
                        <div>Drag & Drop</div>
                        <div>or</div>
                        <div>Upload</div>
                        <img alt="dnd-image" src="/images/dnd-image.svg"/>
                      </div>
                    </Upload.Dragger>}
                    control={control}
                  />
                </Col>
              </Row>
              {errors.document &&
              <Row>
                <Col span={24} className="file-upload__error">
                  Documents are required
                </Col>
              </Row>
              }
              <Row>
                <Col
                  span={24}
                  className={`file-upload__file-types${fileTypeError ? " file-upload__file-types--error" : ""}`}
                >
                  Supports: .pdf, .jpeg, .jpg, .gif and .png file format
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 16 }}>
              <Row>
                <Col span={24} className="know-your-customer__document-type">
                  <Row>
                    <Col span={24}>
                      <SelectWithLabel
                        name="documentType"
                        control={control}
                        id="document-type"
                        label="Document Type"
                        error={errors.documentType}
                        options={[
                          {
                            value: "passport",
                            label: "Passport"
                          },
                          {
                            value: "driver_license",
                            label: "Driver license"
                          }
                        ]}
                        placeholder="Select"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <ol className="know-your-customer__document-hint">
                        <li>
                          For KYC validation please check that the details above (including middle name) match your I.D.
                        </li>
                        <li>
                          For countries outside the USA, EU, UK and Canada require passport validation as driving
                          licences cannot be accepted.
                        </li>
                      </ol>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="know-your-customer__file-container">
                <Col span={24}>
                  {files.map(file => {
                    return (
                      <div key={file.uid} className="file-upload__file">
                        <div>
                          {file.name}
                        </div>
                        <div
                          onClick={() => deleteFile(file.uid)}
                          className="file-upload__delete-uploaded-file"
                        >
                          <img src="/images/close.svg" alt="delete"/>
                        </div>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24 }}>
              <Button
                className="know-your-customer__submit-button"
                htmlType="submit"
                disabled={isProcessing}
              >
                Validate Account
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

KnowYourCustomer.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string,
  verifyKYC: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  kycStatus: PropTypes.string.isRequired,
  profile: PropTypes.object,
  address: PropTypes.object
};

export default connect(
  state => ({
    isProcessing: state.profile.processing,
    error: state.profile.error,
    kycStatus: state.profile.permissions.kycStatus,
    profile: state.profileDetails.profile,
    address: state.profileDetails.profile.Address || {},
  }),
  {
    verifyKYC: kycVerificationRequest,
    getProfile: profileGetRequest,
  }
)(KnowYourCustomer);
