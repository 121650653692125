export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_NOTIFICATION_LIST_REQUEST = "ADMIN_GET_NOTIFICATION_LIST_REQUEST";
export const ADMIN_GET_NOTIFICATION_LIST_REQUEST_SUCCESS = "ADMIN_GET_NOTIFICATION_LIST_REQUEST_SUCCESS";
export const ADMIN_GET_NOTIFICATION_LIST_REQUEST_ERROR = "ADMIN_GET_NOTIFICATION_LIST_REQUEST_ERROR";

export const ADMIN_READ_NOTIFICATION_LIST_REQUEST = "ADMIN_READ_NOTIFICATION_LIST_REQUEST";
export const ADMIN_READ_NOTIFICATION_LIST_REQUEST_SUCCESS = "ADMIN_READ_NOTIFICATION_LIST_REQUEST_SUCCESS";
export const ADMIN_READ_NOTIFICATION_LIST_REQUEST_ERROR = "ADMIN_READ_NOTIFICATION_LIST_REQUEST_ERROR";
