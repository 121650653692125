import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Rate } from "antd";

import Image from "./Image";

const HighlightCard = ({ highlight, size = "big", baseElementSize = 10 }) => {
  const imageUrl = highlight.Hotel && highlight.Hotel.HotelImages && highlight.Hotel.HotelImages[0] &&
    highlight.Hotel.HotelImages[0].imageUrl;
  const hotelAddress = highlight.Hotel && highlight.Hotel.HotelAddress ? highlight.Hotel.HotelAddress : {};

  return (
    <Link style={{ fontSize: `${baseElementSize}px` }} key={`${highlight.id}`} to={`/resale-deals/${highlight.id}-${highlight.Hotel.id}-${highlight.Hotel.name}, ${highlight.Hotel.HotelAddress.cityName}, ${highlight.Hotel.HotelAddress.countryName}`} className={`highlight-card highlight-card--${size}`}>
      <div className="highlight-card__container">
        { !!highlight.maxPercentage &&
          <div className="highlight-card__discount">{`-${highlight.maxPercentage}% off`}</div>
        }
        { !!highlight.Hotel.rating && size === "big" &&
          <div className="highlight-card__hotel-rating">
            {highlight.Hotel.rating}
            <Rate disabled count={1} defaultValue={1} />
          </div>
        }
        { size === "small" &&
          <div className="highlight-card__selling-info-container">
          </div>
        }
        <Image imageUrl={imageUrl} alt={highlight.Hotel.name} loading="eager"/>
        <div className="highlight-card__info">
          <div className="highlight-card__hotel-name">
            {highlight.Hotel.name}
          </div>
          <div className="highlight-card__city-name">{hotelAddress.cityName}</div>
          <div className="highlight-card__details-container">
            { !!highlight.Hotel.rating && size === "small" &&
              <div className="highlight-card__hotel-rating">
                {highlight.Hotel.rating}
                <Rate disabled count={1} defaultValue={1} />
              </div>
            }
          </div>
        </div>
      </div>
    </Link>
  )
};

HighlightCard.propTypes = {
  highlight: PropTypes.object.isRequired,
  size: PropTypes.string,
  baseElementSize: PropTypes.number,
};

export default HighlightCard;

