import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import parse from "html-react-parser";
import { getCurrencyBadge } from "../../helpers/common";
import { getProfilePermissionsRequest } from "../../redux/profile/profileActions";

export const Selling = ({
  pageData,
  paymentConfig,
  currency,
  getStaticPage,
  getProfilePermissions,
}) => {
  useServerSafeEffect(() => {
    getProfilePermissions();
    getStaticPage();
  }, []);

  return (
    <>
      <Helmet>
        {
          pageData.metaTitle && (
            <title>{pageData.metaTitle}</title>
          )
        }
        {
          pageData.metaDescription && (
            <meta name="description" content={pageData.metaDescription}/>
          )
        }
        {
          pageData.metaKeywords && (
            <meta name="keywords" content={pageData.metaKeywords}/>
          )
        }
      </Helmet>
      <div className="selling__top-background" />
      <Row justify="space-around" align="middle" className="selling__section selling__section--top">
        <Col xs={{ span: 22 }}>
          <Row gutter={[{ xs: 0, md: 72 }, { xs: 0, md: 72 }]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Link to="/listings/create" className="selling__plans-changed-container">
                {parse(pageData.sellListingButtonLeftLabel || "")}
              </Link>
              <div className="plainHtml">
                {parse(pageData.sellListingTitleLeft || "")}
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Row>
                <Col span={24}>
                  <div className="selling__steps-title">
                    {parse(pageData.sellListingStepsTitle || "")}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="selling__steps-bg-container">
                  <Row>
                    <Col span={24} className="selling__steps-container">
                      <Row gutter={{ xs: 0, md: 16 }}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__step">
                          <div className="icon">
                            <img src="/images/icons/login.svg" alt="icon" />
                          </div>
                          <div className="plainHtml">{parse(pageData.sellListingStep1 || "")}</div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__step">
                          <div className="icon">
                            <img src="/images/icons/enter-details.svg" alt="icon" />
                          </div>
                          <div className="plainHtml">{parse(pageData.sellListingStep3 || "")}</div>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 0, md: 16 }}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__step">
                          <div className="icon">
                            <img src="/images/icons/upload-proff.svg" alt="icon" />
                          </div>
                          <div className="plainHtml">{parse(pageData.sellListingStep2 || "")}</div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__step">
                          <div className="icon">
                            <img src="/images/icons/preview-listing.svg" alt="icon" />
                          </div>
                          <div className="plainHtml">{parse(pageData.sellListingStep4 || "")}</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="divider" />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="selling__steps-container">
                      <Row>
                        <Col span={24}>
                          <div className="plainHtml">{parse(pageData.sellListingTitleRight || "")}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Link to="/listings/create" className="selling__create-listing-button">
                            {parse(pageData.sellListingButtonRightLabel || "")}
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-around" align="middle" className="selling__section">
        <Col xs={{ span: 22 }}>
          <Row>
            <Col span={24}>
              <div className="selling__title">
                {parse(pageData.listingPreviewTitle || "")}
              </div>
              <div className="selling__underscore"/>
              <div className="selling__listing-review-subtitle">
                {parse(pageData.listingPreviewSubtitle || "")}
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col xs={{ span: 24 }} lg={{ span: 14 }}>
              <img src="/images/selling/listing-preview.png" alt="Sell Room" className="selling__preview-listing-img"/>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10 }} className="selling__listing-preview-block-right-container" >
              <Row>
                <Col span={24} className="selling__listing-preview-block-right">
                  {parse(pageData.listingPreviewTitleRight || "")}
                </Col>
              </Row>
              <Row>
                <Col span={24} className="selling__listing-preview-block-right">
                  <Link to="/listings/create" className="selling__create-listing-button">
                    {parse(pageData.listingPreviewCreateButtonLabel || "")}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-around" align="middle" className="selling__section">
        <Col xs={{ span: 22 }}>
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <div className="selling__title">
                {parse(pageData.servicesWeProvideTitle || "")}
              </div>
              <div className="selling__underscore"/>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 14 }}>
              <Row>
                <Col span={24}>
                  <Row gutter={20}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__service">
                      <div className="icon">
                        <img src="/images/selling/icons/create-listing.svg" alt="icon"/>
                      </div>
                      <div>
                        {parse(pageData.serviceWeProvide1 || "")}
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__service">
                      <div className="icon">
                        <img src="/images/selling/icons/validate-booking-info.svg" alt="icon"/>
                      </div>
                      <div>
                        {parse(pageData.serviceWeProvide5 || "")}
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__service">
                      <div className="icon">
                        <img src="/images/selling/icons/advertise.svg" alt="icon"/>
                      </div>
                      <div>
                        {parse(pageData.serviceWeProvide2 || "")}
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__service">
                      <div className="icon">
                        <img src="/images/selling/icons/promote.svg" alt="icon"/>
                      </div>
                      <div>
                        {parse(pageData.serviceWeProvide6 || "")}
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__service">
                      <div className="icon">
                        <img src="/images/selling/icons/transfer.svg" alt="icon"/>
                      </div>
                      <div>
                        {parse(pageData.serviceWeProvide3 || "")}
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__service">
                      <div className="icon">
                        <img src="/images/selling/icons/secure-money.svg" alt="icon"/>
                      </div>
                      <div>
                        {parse(pageData.serviceWeProvide7 || "")}
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__service">
                      <div className="icon">
                        <img src="/images/selling/icons/validate-bank-details.svg" alt="icon"/>
                      </div>
                      <div>
                        {parse(pageData.serviceWeProvide4 || "")}
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="selling__service">
                      <div className="icon">
                        <img src="/images/selling/icons/keep-data-secure.svg" alt="icon"/>
                      </div>
                      <div>
                        {parse(pageData.serviceWeProvide8 || "")}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-around" align="middle" className="selling__section">
        <Col xs={{ span: 22 }}>
          <Row>
            <Col span={24}>
              <div className="selling__title">
                {parse(pageData.feesTitle || "")}
              </div>
              <div className="selling__underscore"/>
            </Col>
          </Row>
          <Row gutter={[32, 32]} className="selling__fees-cards-container">
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Row className="selling__fees-card-container">
                <Col span={24} className="selling__fees-card">
                  <Row>
                    <Col span={14} className="selling__fees-card-title">
                      {parse(pageData.feesFirstCardTitle || "")}
                    </Col>
                    <Col span={10} className="selling__fees-card-price">
                      {getCurrencyBadge(currency)}{parseFloat(paymentConfig.accountVerification).toFixed(2)}
                      <sup>*</sup>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="selling__fees-card-subtitle">
                      {parse(pageData.feesFirstCardSubtitle || "")}
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={24} className="selling__fees-card-description">
                      <Row>
                        <Col span={24} className="selling__fees-card-line">
                          <div className="icon">
                            <img src="/images/selling/icons/check.svg" alt="icon"/>
                          </div>
                          <div>{parse(pageData.feesFirstCardLine1 || "")}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="selling__fees-card-line">
                          <div className="icon">
                            <img src="/images/selling/icons/check.svg" alt="icon"/>
                          </div>
                          <div>{parse(pageData.feesFirstCardLine2 || "")}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="selling__fees-card-line">
                          <div className="icon">
                            <img src="/images/selling/icons/check.svg" alt="icon"/>
                          </div>
                          <div>{parse(pageData.feesFirstCardLine3 || "")}</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className="selling__fees-card-note">
                      {parse(pageData.feesFirstCardNote || "")}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Row className="selling__fees-card-container">
                <Col span={24} className="selling__fees-card">
                  <Row>
                    <Col span={14} className="selling__fees-card-title">
                      {parse(pageData.feesSecondCardTitle || "")}
                    </Col>
                    <Col span={10} className="selling__fees-card-price">
                      {getCurrencyBadge(currency)}{parseFloat(paymentConfig.listingPublish).toFixed(2)}
                      <sup>*</sup>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={24} className="selling__fees-card-description">
                      <Row>
                        <Col span={24} className="selling__fees-card-line">
                          <div className="icon">
                            <img src="/images/selling/icons/check.svg" alt="icon"/>
                          </div>
                          <div>{parse(pageData.feesSecondCardLine1 || "")}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="selling__fees-card-line">
                          <div className="icon">
                            <img src="/images/selling/icons/check.svg" alt="icon"/>
                          </div>
                          <div>{parse(pageData.feesSecondCardLine2 || "")}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="selling__fees-card-line">
                          <div className="icon">
                            <img src="/images/selling/icons/check.svg" alt="icon"/>
                          </div>
                          <div>{parse(pageData.feesSecondCardLine3 || "")}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="selling__fees-card-line">
                          <div className="icon">
                            <img src="/images/selling/icons/check.svg" alt="icon"/>
                          </div>
                          <div>{parse(pageData.feesSecondCardLine4 || "")}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="selling__fees-card-line">
                          <div className="icon">
                            <img src="/images/selling/icons/check.svg" alt="icon"/>
                          </div>
                          <div>{parse(pageData.feesSecondCardLine5 || "")}</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className="selling__fees-card-note">
                      {parse(pageData.feesSecondCardNote || "")}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

Selling.propTypes = {
  getStaticPage: PropTypes.func.isRequired,
  getProfilePermissions: PropTypes.func.isRequired,
  pageData: PropTypes.any.isRequired,
  paymentConfig: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    pageData: state.staticPage.page,
    paymentConfig: state.profile.permissions.paymentConfig || { accountVerification: 0, listingPublish: 0 },
    currency: state.profile.permissions.currency
  }),
  {
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "selling-page" }),
    getProfilePermissions: getProfilePermissionsRequest,
  }
)(Selling);
