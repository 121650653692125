import * as types from "./reservationsActionsTypes";

export const initialState = {
  processing: false,
  requestSuccess: false,
  error: "",
  searchResult: null,
  modifyResult: null
};

const reservations = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
        requestSuccess: false
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false
      };
    case types.ADMIN_RESERVATIONS_SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        searchResult: action.response,
        requestSuccess: true,
        error: ""
      };
    case types.ADMIN_RESERVATIONS_MODIFY_REQUEST_SUCCESS:
      return {
        ...state,
        modifyResult: action.response,
        requestSuccess: true,
        error: ""
      };
    case types.ADMIN_RESERVATIONS_SEARCH_REQUEST_ERROR:
    case types.ADMIN_RESERVATIONS_MODIFY_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default reservations;
