import React from "react";
import PropTypes from "prop-types";
import { Col, Rate, Row, Typography } from "antd";

import CheckDateItem from "./CheckDateItem";
import { formatAddress } from "../../../helpers/common";
import HotelCardStatus from "./HotelCardStatus";
import HotelCardGuests from "./HotelCardGuests";
import CancellationPaymentMessage from "../CancellationPaymentMessage"

const HotelCard = ({
  image,
  status,
  hotelName,
  hotelRating,
  id,
  checkInDate,
  checkOutDate,
  guestsList,
  addressList,
  priceLabel,
  currencyBadge,
  price,
  fullCancellationPaymentMessage,
  cancellationData
}) => {
  
  return (
    <div className="hotel-card">
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <div className="hotel-card__image-wrapper">
            {image
              ? <img className="hotel-card__hotel-image" alt="hotel image" src={image}/>
              : <div className="hotel-card__hotel-image hotel-card__empty-hotel-image">
                <img alt="hotel image" src="/images/no-image.svg"/>
              </div>
            }
            <div className="hotel-card__status-container">
              {status && <HotelCardStatus status={status}/>}
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <div className="hotel-card__hotel-info-container">
            <Typography.Title className="hotel-card__hotel-name" level={4}>{hotelName}</Typography.Title>
            <div className="hotel-card__section">
              {hotelRating > 0 ?
                <Rate
                  key={`hotel-card-rate-${id}`}
                  disabled
                  count={hotelRating}
                  defaultValue={hotelRating}
                /> :
                <Rate disabled key={`hotel-card-rate-${id}`}/>
              }

            </div>
            <div className="hotel-card__section">
              <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <CheckDateItem date={checkInDate} name="Check-In"/>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <CheckDateItem date={checkOutDate} name="Check-Out"/>
                </Col>
              </Row>
            </div>
            <div className="hotel-card__section">
              <span className="hotel-card__hotel-info-title">Guests</span>
              <HotelCardGuests list={guestsList}/>
            </div>
            <div className="hotel-card__section">
              <span className="hotel-card__hotel-info-title">Address</span>
              <div>
                {formatAddress(addressList)}
              </div>
            </div>
            <div className="hotel-card__footer">
              <span className="hotel-card__price">
                {priceLabel && <span>{priceLabel} </span>}
                <span>{currencyBadge}{price}</span>
              </span>
            </div>

            {!fullCancellationPaymentMessage && cancellationData?.isRefundable &&
              <div className="hotel-card__free-cancellation">
                <img src="/images/icons/check-pink.svg" alt="check"/>
                Free Cancellation
              </div>
            }

            {fullCancellationPaymentMessage && <CancellationPaymentMessage {...cancellationData} />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

HotelCard.propTypes = {
  image: PropTypes.string,
  status: PropTypes.string,
  hotelName: PropTypes.string.isRequired,
  hotelRating: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  checkInDate: PropTypes.string.isRequired,
  checkOutDate: PropTypes.string.isRequired,
  guestsList: PropTypes.array.isRequired,
  addressList: PropTypes.array.isRequired,
  priceLabel: PropTypes.string,
  currencyBadge: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  cancellationData: PropTypes.object,
  fullCancellationPaymentMessage: PropTypes.bool
};

export default HotelCard;
