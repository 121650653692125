export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_CATEGORY_LIST_REQUEST = "ADMIN_GET_CATEGORY_LIST_REQUEST";
export const ADMIN_GET_CATEGORY_LIST_REQUEST_SUCCESS = "ADMIN_GET_CATEGORY_LIST_REQUEST_SUCCESS";
export const ADMIN_GET_CATEGORY_LIST_REQUEST_ERROR = "ADMIN_GET_CATEGORY_LIST_REQUEST_ERROR";

export const ADMIN_CREATE_CATEGORY_REQUEST = "ADMIN_CREATE_CATEGORY_REQUEST";
export const ADMIN_CREATE_CATEGORY_REQUEST_SUCCESS = "ADMIN_CREATE_CATEGORY_REQUEST_SUCCESS";
export const ADMIN_CREATE_CATEGORY_REQUEST_ERROR = "ADMIN_CREATE_CATEGORY_REQUEST_ERROR";

export const ADMIN_DELETE_CATEGORY_REQUEST = "ADMIN_DELETE_CATEGORY_REQUEST";
export const ADMIN_DELETE_CATEGORY_REQUEST_SUCCESS = "ADMIN_DELETE_CATEGORY_REQUEST_SUCCESS";
export const ADMIN_DELETE_CATEGORY_REQUEST_ERROR = "ADMIN_DELETE_CATEGORY_REQUEST_ERROR";

export const ADMIN_CLEAR_CATEGORY_ERROR = "ADMIN_CLEAR_CATEGORY_ERROR";
