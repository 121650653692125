import * as types from "./userManagementActionsTypes";

export const getUserListRequest = (data) => {
  return { type: types.ADMIN_GET_USER_LIST_REQUEST, data };
};

export const getUserRequest = (data) => {
  return { type: types.ADMIN_GET_USER_REQUEST, data };
};

export const updateUserRequest = (data) => {
  return { type: types.ADMIN_UPDATE_USER_REQUEST, data };
};

export const downloadKYCFileRequest = (data) => {
  return { type: types.ADMIN_DOWNLOAD_KYC_FILE_REQUEST, data };
};

export const clearUserAction = () => {
  return { type: types.ADMIN_CLEAR_USER };
};

export const clearUserKYCFileAction = () => {
  return { type: types.ADMIN_CLEAR_USER_KYC_FILE };
};

export const clearUserErrorAction = () => {
  return { type: types.ADMIN_CLEAR_USER_ERROR };
};

export const getCsvExportUrlRequest = (data) => {
  return { type: types.ADMIN_GET_USERS_CSV_EXPORT_URL_REQUEST, data };
};

export const getUserListingsRequest = (data) => {
  return { type: types.ADMIN_GET_USER_LISTINGS_REQUEST, data };
};
