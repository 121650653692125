export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const GET_STATIC_PAGE_REQUEST = "GET_STATIC_PAGE_REQUEST";
export const GET_STATIC_PAGE_REQUEST_SUCCESS = "GET_STATIC_PAGE_REQUEST_SUCCESS";
export const GET_STATIC_PAGE_REQUEST_ERROR = "GET_STATIC_PAGE_REQUEST_ERROR";

export const POST_FEEDBACK_REQUEST = "POST_FEEDBACK_REQUEST";
export const POST_FEEDBACK_REQUEST_SUCCESS = "POST_FEEDBACK_REQUEST_SUCCESS";
export const POST_FEEDBACK_REQUEST_ERROR = "POST_FEEDBACK_REQUEST_ERROR";

export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
export const GET_FAQ_REQUEST_SUCCESS = "GET_FAQ_REQUEST_SUCCESS";
export const GET_FAQ_REQUEST_ERROR = "GET_FAQ_REQUEST_ERROR";

export const RESET_STATIC_PAGE = "RESET_STATIC_PAGE";
