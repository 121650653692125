import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Carousel, Col, Row, Rate, Button, Tooltip, Typography } from "antd";
import * as queryString from "query-string";
import { withRouter } from "react-router";
import parse from "html-react-parser";

import { ImageBgCover } from "./SimpleComponents/ImageBgCover/ImageBgCover";
import {
  getListingStatus,
  getCurrencyBadge,
  round,
} from "../../helpers/common";
import { splitText } from "../../helpers/text";
import { showModalAction } from "../../redux/general/generalActions";
import {
  ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  DISPUTE_PROPS,
  LISTING_TYPE_ORGANIC,
  CARD_NOT_VERIFIED_STATUS,
  MODAL_TYPE_ACCOUNT_VERIFICATION,
  MODAL_TYPE_LISTING_AMENITIES,
  CARD_VERIFIED_STATUS,
  LISTING_TYPE_B2B,
  MODAL_BOOK_NOT_LOGGED,
  MODAL_TYPE_LISTING_CARD_DETAILS,
  MODAL_TYPE_LISTING_OFFER_PRICE_NOT_LOGGED,
  MODAL_TYPE_LISTING_OFFER_PRICE,
} from "../constants";
import {
  deleteListingRequest,
  setNewListingData,
  setShowCardDetailsModalAction
} from "../../redux/listing/listingActions";
import ArrowRightIcon from "./icons/ArrowRightIcon/ArrowRightIcon"
import ListingDetails from "../Listing/ListingDetails";
import PageNotFound from "../PageNotFound/PageNotFound";

const carouselRef = React.createRef();
const modalCarouselRef = React.createRef();

const ListingView = ({
  listing,
  hotel,
  hotelFacilities,
  isPreview,
  user,
  userRole,
  isAuthenticated,
  fee,
  vat,
  currency,
  location,
  isProcessing,
  cardVerificationStatus,
  push,
  history,
  error,
  publishListing,
  saveListing,
  saveDraftListing,
  showModal,
  setNewListing,
  deleteListing,
  setShowCardDetailsModal,
}) => {
  const [isPreviewImageVisible, setIsPreviewImageVisible] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const { rooms, adults, children, childrenAge } = listing;
  const isMyListing = !!listing.sellerId && listing.sellerId === user.id;
  const isAdmin = [SUPER_ADMIN_ROLE, ADMIN_ROLE].includes(userRole);
  const displayDocuments = (isAdmin || [listing.sellerId, listing.buyerId].includes(user.id)) && !isPreview &&
    listing.ListingDocuments && !!listing.ListingDocuments.length;
  const isDisputedOpened = listing.Dispute && listing.Dispute.status && DISPUTE_PROPS[listing.Dispute.status];
  const canOpenDispute = !isDisputedOpened && listing.buyerId && listing.buyerId === user.id &&
    listing.type === LISTING_TYPE_ORGANIC;

  const currencyBadge = getCurrencyBadge(listing.Seller ? listing.Seller.currency : (listing.currency || currency));

  let listingStatus = listing.status ? getListingStatus(listing.status, listing.checkInDate) : null;
  if (listingStatus !== "sold" && listing.Seller && listing.Seller.status === "suspended") {
    listingStatus = "suspended";
  }

  const showCardDetailsModal = () => {
    showModal({
      type: MODAL_TYPE_LISTING_CARD_DETAILS,
      width: 700,
      params: {
        listing
      }
    });
  };

  useEffect(() => {
    const { action } = queryString.parse(location.search, { arrayFormat: "bracket" });
    if (action === "extend") {
      if (!isAuthenticated) {
        history.push("/auth/sign-in", { from: history.location });
      } else {
        showCardDetailsModal();
      }
    }

    return () => {
      if (!isPreview) {
        setNewListing({});
      }
    }
  }, []);

  const onChange = (data) => {
    setImageIndex(data);
  };

  const goToImage = (index) => {
    carouselRef && carouselRef.current && carouselRef.current.goTo(index);
  };

  const goToModalImage = (index) => {
    modalCarouselRef && modalCarouselRef.current && modalCarouselRef.current.goTo(index);
  };

  const bookNowHandler = () => {
    const { checkInDate, checkOutDate } = queryString.parse(location.search, { arrayFormat: "bracket" });
    const queryParams = queryString.stringify(
      { checkInDate, checkOutDate, adults, children, childrenAge, rooms },
      { skipEmptyString: true, skipNull: true, arrayFormat: "bracket" }
    )
    const path = `/listings/${listing.slug}/details${!listing.sellerId ? `?${queryParams}` : "" }`;

    if (isAuthenticated && !isMyListing) {
      push(path);
    } else if (!isAuthenticated && !isMyListing) {
      const { pathname, search } = history.location;

      showModal({
        type: MODAL_BOOK_NOT_LOGGED,
        width: 500,
        params: {
          pathToBook: path,
          pathToBack: pathname,
          searchQuery: search
        }
      });
    }
  };

  const offerPriceHandler = () => {
    const { checkInDate, checkOutDate } = queryString.parse(location.search, { arrayFormat: "bracket" });
    const queryParams = queryString.stringify(
      { checkInDate, checkOutDate, adults, children, childrenAge, rooms },
      { skipEmptyString: true, skipNull: true, arrayFormat: "bracket" }
    )
    const path = `/listings/${listing.slug}${!listing.sellerId ? `?${queryParams}` : "" }`;

    const { pathname, search } = history.location;

    showModal({
      type: isAuthenticated ? MODAL_TYPE_LISTING_OFFER_PRICE : MODAL_TYPE_LISTING_OFFER_PRICE_NOT_LOGGED,
      width: isAuthenticated ? 400 : 500,
      params: {
        pathToBook: path,
        pathToBack: pathname,
        searchQuery: search,
        listing
      }
    });
  };

  const createDisputeHandler = () => {
    const nowTime = moment();
    const reservationTime = moment(listing.checkInDate);
    const endTime = moment(listing.checkOutDate).add(2, "days").endOf("day");
    const name = listing.Hotel && listing.Hotel.name ? listing.Hotel.name : listing.bookingName;

    if (nowTime < reservationTime) {
      showModal({
        type: "info",
        title: <span>The reservation date for the <b>{name}</b> hasn’t come yet</span>,
        width: 450,
        params: {
          buttonClassName: "button--default",
          buttonText: "Close"
        }
      });
    } else if (nowTime > endTime) {
      showModal({
        type: "info",
        title: <span>Dates at which you can open a dispute have already passed for <b>{name}</b></span>,
        width: 450,
        params: {
          buttonClassName: "button--default",
          buttonText: "Close"
        }
      });
    } else {
      push(`/listings/${listing.slug}/dispute`);
    }
  };

  if (!listing || !hotel) {
    return <PageNotFound/>;
  }

  const imagesMini = hotel && hotel.HotelImages && hotel.HotelImages.length
    ? showAllImages ? hotel.HotelImages : hotel.HotelImages.slice(0, 4)
    : [];
  const hotelAddress = hotel.HotelAddress ? hotel.HotelAddress : {};
  const originalPrice = parseFloat(listing.originalPrice);
  const sellingPrice = parseFloat(listing.sellingPrice);
  const systemFee = round((sellingPrice * (listing.fee ? parseFloat(listing.fee) : fee)) / 100, 2);
  const systemVat = round((systemFee * (listing.vat ? parseFloat(listing.vat) : vat)) / 100, 2);

  let showExtendPreAuthButton = false;
  if (listing.preAuthExpiresAt) {
    showExtendPreAuthButton = moment(listing.preAuthExpiresAt).diff(moment(), "hours") <= 24;
  }

  const getPreAuthExpiresInLabel = () => {
    const duration = moment.duration(moment(listing.preAuthExpiresAt).diff(moment()));
    const days = duration.days();
    const hours = duration.hours();

    let label = `${hours} hour${hours > 1 ? "s" : ""}`;

    if (days) {
      label = `${days} day${days > 1 ? "s" : ""} ${label}`;
    }

    return label;
  };

  // eslint-disable-next-line no-unused-vars, react/prop-types, no-shadow
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );

  const renderButton = () => {
    if (isPreview && publishListing) {
      if (!listing.id) {
        return (
          <React.Fragment>
            <Col xs={{ span: 24 }} md={{ span: cardVerificationStatus === CARD_VERIFIED_STATUS ? 12 : 24 }}>
              <Button
                type="primary"
                className="listing-details__action-button"
                disabled={isProcessing}
                onClick={() => {
                  if (cardVerificationStatus === CARD_NOT_VERIFIED_STATUS) {
                    publishListing(listing);
                  } else {
                    setShowCardDetailsModal(false);
                    saveDraftListing();
                  }
                }}
              >
                {cardVerificationStatus === CARD_NOT_VERIFIED_STATUS ? "Verify & Publish" : "Save as Draft"}
              </Button>
            </Col>
            { cardVerificationStatus === CARD_VERIFIED_STATUS &&
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Button
                  type="primary"
                  className="listing-details__action-button"
                  disabled={isProcessing}
                  onClick={() => {
                    publishListing(listing);
                    setShowCardDetailsModal(true);
                  }}
                >
                  Publish
                </Button>
              </Col>
            }
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          { listing.status === "draft" &&
            [CARD_VERIFIED_STATUS, CARD_NOT_VERIFIED_STATUS].includes(cardVerificationStatus) &&
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Button
                type="primary"
                className="listing-details__action-button"
                disabled={isProcessing}
                onClick={() => {
                  showModal({
                    type: "confirm",
                    width: 450,
                    title: `Are you sure you would like to publish ${listing.Hotel ? listing.Hotel.name : "your"} listing?`,
                    params: {
                      icon: "/images/info_outlined.svg",
                      confirmButtonText: "Publish",
                      isProcessing,
                      confirmAction: () => {
                        setShowCardDetailsModal(true);

                        if (cardVerificationStatus === CARD_NOT_VERIFIED_STATUS) {
                          showModal({
                            type: MODAL_TYPE_ACCOUNT_VERIFICATION,
                            width: 900,
                            params: {
                              listing
                            }
                          });
                        // } else if (!listing.isPaidInFull || listing.isPaidInFull === "arrival") {
                        //   showCardDetailsModal();
                        } else {
                          publishListing();
                        }
                      }
                    }
                  });
                }}
              >
                Publish
              </Button>
            </Col>
          }
          <Col
            xs={{ span: 24 }}
            md={{
              span: listing.status === "draft" &&
              [CARD_VERIFIED_STATUS, CARD_NOT_VERIFIED_STATUS].includes(cardVerificationStatus)
                ? 12 : 24
            }}
          >
            <Button
              type="primary"
              className="listing-details__action-button"
              disabled={isProcessing}
              onClick={saveListing}
            >
              Save
            </Button>
          </Col>
        </React.Fragment>
      );
    }

    if (listingStatus === "sold") {
      return (
        <Col span={24}>
          <Button
            type="primary"
            className="listing-details__action-button"
            disabled={true}
          >
            {isMyListing ? "Sold" : "Bought"} for {currencyBadge} {(+listing.sellingPrice).toFixed(2)}
          </Button>
        </Col>
      );
    }

    if (listingStatus === "suspended" || listing.publishStatus === "suspended") {
      return <Col span={24} className="listing-view__status listing-view__status--suspended">Suspended</Col>;
    }

    if (listingStatus === "canceled") {
      return <Col span={24} className="listing-view__status listing-view__status--canceled">Cancelled</Col>;
    }

    if (listingStatus === "expired") {
      if (isMyListing) {
        return (
          <Col span={24}>
            <Button
              type="danger"
              className="listing-details__action-button"
              disabled={isProcessing}
              onClick={() => {
                showModal({
                  type: "confirm",
                  width: 450,
                  title: "Are You sure you would like to delete listing?",
                  params: {
                    isProcessing,
                    error,
                    confirmAction: () => {
                      deleteListing({ id: listing.id, type: "seller" });
                      history.push("/seller/listings/active");
                    }
                  }
                })
              }}
            >
              Delete
            </Button>
          </Col>
        );
      }

      return <Col span={24} className="listing-view__status listing-view__status--expired">Expired</Col>;
    }

    if (isMyListing) {
      return (
        <React.Fragment>
          {
            listing.bookingStartedAt || listing.paymentStartedAt
              ? <React.Fragment>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Tooltip title="Someone is purchasing this listing">
                    <Button
                      disabled={true}
                      type="primary"
                      className="listing-details__action-button"
                    >
                      Edit
                    </Button>
                  </Tooltip>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Tooltip title="Someone is purchasing this listing">
                    <Button
                      disabled={true}
                      type="danger"
                      className="listing-details__action-button"
                    >
                      Delete
                    </Button>
                  </Tooltip>
                </Col>
              </React.Fragment>
              : <React.Fragment>
                {!listing.isPaidInFull && showExtendPreAuthButton &&
                  <Col xs={{ span: 24 }}>
                    <Row className="listing-details__expires-in-container">
                      <Col span={24} className="listing-details__expires-in">
                        Your listing is <b>Published</b> and expires in {getPreAuthExpiresInLabel()}
                      </Col>
                      <Col span={24}>
                        <Button
                          type="primary"
                          className="listing-details__action-button"
                          disabled={isProcessing}
                          onClick={() => {
                            showCardDetailsModal();
                          }}
                        >
                          Continue till {moment().add(7, "days").format("DD MMM YYYY")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                }
                { listingStatus === "draft" && publishListing &&
                  <Col span={24}>
                    <Button
                      type="primary"
                      className="listing-details__action-button"
                      disabled={isProcessing}
                      onClick={() => {
                        showModal({
                          type: "confirm",
                          width: 450,
                          title: `Are you sure you would like to publish ${listing.Hotel ? listing.Hotel.name : "your"} listing?`,
                          params: {
                            icon: "/images/info_outlined.svg",
                            confirmButtonText: "Publish",
                            isProcessing,
                            confirmAction: () => {
                              setShowCardDetailsModal(true);

                              if (cardVerificationStatus === CARD_NOT_VERIFIED_STATUS) {
                                showModal({
                                  type: MODAL_TYPE_ACCOUNT_VERIFICATION,
                                  width: 900,
                                  params: {
                                    listing
                                  }
                                });
                              // } else if (!listing.isPaidInFull) {
                              //   showCardDetailsModal();
                              } else {
                                publishListing(listing);
                              }
                            }
                          }
                        });
                      }}
                    >
                      Publish
                    </Button>
                  </Col>
                }
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Button
                    type="primary"
                    disabled={isProcessing}
                    className="listing-details__action-button"
                    onClick={() => history.push(`${history.location.pathname}/edit`)}
                  >
                    Edit
                  </Button>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Button
                    type="danger"
                    disabled={isProcessing}
                    className="listing-details__action-button"
                    onClick={() => {
                      showModal({
                        type: "confirm",
                        width: 450,
                        title: "Are You sure you would like to delete listing?",
                        params: {
                          isProcessing,
                          error,
                          confirmAction: () => {
                            deleteListing({ id: listing.id, type: "seller" });
                            history.push("/seller/listings/active");
                          }
                        }
                      })
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              </React.Fragment>
          }
        </React.Fragment>
      );
    }

    return (
      <Col span={24}>
        <Button
          type="primary"
          className="listing-details__action-button"
          disabled={
            isProcessing ||
            (listing.Seller && listing.Seller.deletedAt) ||
            listing.publishStatus === "suspended" ||
            listingStatus === "pending" ||
            listingStatus === "draft"
          }
          onClick={bookNowHandler}
        >
          Book for {currencyBadge} {(+listing.sellingPrice).toFixed(2)}
        </Button>
        { listing.priceOfferCanBeCreated && (
          listing.receiveOffers === "always" ||
          (
            listing.receiveOffers === "in-last-4-hours" &&
            moment().isBetween(
              moment(listing.checkInDate).subtract(4, "hours"),
              moment(listing.checkInDate),
            )
          )
        ) &&
          <div
            className="listing-details__offer-price-container"
          >
            <Button
              size="small"
              className="listing-details__offer-price"
              onClick={offerPriceHandler}
            >
              Make an offer
            </Button>
          </div>
        }
      </Col>
    );
  };

  return (
    <Row justify="space-around" align="middle" className="listing-view">
      <Col xs={{ span: 24 }} md={{ span: 22 }} xl={{ span: 20 }}>
        <Row className="listing-view__hotel-name-container">
          <Col span={24}>
            <Row className="listing-view__hotel-name">
              <Col span={24}>
                <Typography.Title>{hotel.name}</Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 13 }}>
                <Row gutter={32} justify="space-between" align="middle">
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: listing.hotelRating ? 12 : 24 }}
                    lg={{ span: listing.hotelRating ? 14 : 24 }}
                  >
                    {hotelAddress.countryName}, {hotelAddress.cityName}
                  </Col>
                  {
                    !!listing.hotelRating &&
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 10 }}
                      className="listing-view__rating"
                    >
                      <Rate
                        key={`listing-rate-${listing.id}`}
                        disabled
                        count={listing.hotelRating}
                        defaultValue={listing.hotelRating}
                      />
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={{ xs: 0, md: 32, xl: 80 }} align="start">
          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 13 }}>
            {
              hotel && hotel.HotelImages && hotel.HotelImages.length
                ? <React.Fragment>
                  <Row className="listing-view__hotel-images">
                    <Carousel
                      afterChange={onChange}
                      arrows={true}
                      dots={false}
                      ref={carouselRef}
                      nextArrow={<SlickButtonFix><ArrowRightIcon/></SlickButtonFix>}
                      prevArrow={<SlickButtonFix><ArrowRightIcon/></SlickButtonFix>}
                    >
                      {
                        hotel.HotelImages.map(image => (
                          <div key={image.id} className="listing-view__hotel-images-item" onClick={() => {
                            if (!isPreviewImageVisible) {
                              setIsPreviewImageVisible(true);
                              goToModalImage(imageIndex);
                            }
                          }}>
                            <ImageBgCover url={image.imageUrl} height={56} />
                          </div>
                        ))
                      }
                    </Carousel>
                    <div
                      className={`ant-modal-mask listing-view__carousel-modal${isPreviewImageVisible ? " visible" : ""}`}
                      onClick={evt => {
                        if (!evt.target.className.baseVal && evt.target.className.startsWith("ant-modal-mask")) {
                          setIsPreviewImageVisible(false);
                        }
                      }}
                    >
                      <Carousel
                        arrows={true}
                        ref={modalCarouselRef}
                        className="modal-inner"
                        nextArrow={<SlickButtonFix><ArrowRightIcon/></SlickButtonFix>}
                        prevArrow={<SlickButtonFix><ArrowRightIcon/></SlickButtonFix>}
                      >
                        {
                          hotel.HotelImages.map(image => (
                            <div key={image.id} className="listing-view__hotel-images-item">
                              <ImageBgCover url={image.imageUrl} height={56} />
                            </div>
                          ))
                        }
                      </Carousel>
                      <div className="listing-view__carousel-modal-close" onClick={() => setIsPreviewImageVisible(false)}>
                        <img src="/images/close-white.svg" alt="close" />
                      </div>
                    </div>
                  </Row>
                  <Row className="listing-view__hotel-images-mini" justify="start">
                    {
                      imagesMini.map((image, index) => (
                        <Col span={6}
                          key={image.id}
                          className={index === imageIndex ? "active" : ""}
                          onClick={() => goToImage(index)}
                        >
                          <ImageBgCover url={image.imageUrl} height={63} />
                          {
                            !showAllImages && index === 3 &&
                            <div
                              className="listing-view__hotel-images-mini-button"
                              onClick={(event) => {
                                event.stopPropagation();
                                setShowAllImages(true);
                              }}
                            >
                              All photos
                            </div>
                          }
                        </Col>
                      ))
                    }
                  </Row>
                </React.Fragment>
                : <ImageBgCover url={""} height={56} iconSize="big" className="listing-view__hotel-images-empty" />
            }
            <Row className="listing-view__hotel-description">
              <Col span={24}>
                <Row>
                  <Col span={24} className="listing-view__hotel-description-title">
                    Description
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {hotel.source === "travelport" ? parse(splitText(hotel.description)) : parse(hotel.description)}
                  </Col>
                </Row>
              </Col>
            </Row>
            {
              hotel.topFacilities && hotel.topFacilities.length
                ? <Row className="mobile-padding">
                  <div className="form-container listing-view__facilities">
                    <div className="listing-view__facilities-container">
                      <div className="listing-view__facilities-label-container">
                        <div className="listing-view__facilities-label">Amenities</div>
                        <div
                          className="listing-view__facilities-see-all"
                          onClick={() => showModal({
                            width: "900px",
                            type: MODAL_TYPE_LISTING_AMENITIES,
                            params: {
                              amenities: hotelFacilities
                            }
                          })}
                        >
                          See all <img src="/images/arrow-circle-icon.svg" alt="See all"/>
                        </div>
                      </div>
                      <div className="listing__field-facilities">
                        {
                          hotel.topFacilities.map((item, index) => (
                            <div className="listing__field-facilities-item" key={`hotel-facilities-${item}-${index}`}>
                              <img
                                className="listing-view__facilities-icon"
                                src={`/images/icons/${item.toLowerCase().replace(/\W/g, "-")}.svg`}
                                alt={item}
                              />
                              <span>{item}</span>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </Row>
                : null
            }
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 11 }}>
            <div
              className={!isMyListing && !displayDocuments && !isAdmin ? "listing-view__details-container" : ""}
            >
              <ListingDetails listing={listing} renderButton={renderButton}/>
              { !isNaN(listing.id) &&
                <Row>
                  <Col span={24}>
                    <div className="listing-view__ref-number">Ref. no. {listing.id}</div>
                  </Col>
                </Row>
              }
            </div>
            { displayDocuments &&
              <Row>
                <Col span={24} className="form-container listing-view__documents">
                  {
                    listing.ListingDocuments.map(file => (
                      <a href={file.fileUrl} download key={file.id} className="listing-view__documents-item">
                        <img src="/images/file.svg" alt="file"/>
                        <span>{file.fileName}</span>
                      </a>
                    ))
                  }
                </Col>
              </Row>
            }
            { (isDisputedOpened || canOpenDispute) &&
              <Row>
                <Col span={24} className="listing-view__dispute">
                  { isDisputedOpened &&
                    <div className="listing-view__dispute-status">
                      {`Dispute ${DISPUTE_PROPS[listing.Dispute.status].value}`}
                    </div>
                  }
                  { canOpenDispute &&
                    <div className="listing-view__dispute-submit" onClick={createDisputeHandler}>Open dispute</div>
                  }
                </Col>
              </Row>
            }
            { isMyListing && !isPreview &&
              <Row>
                <Col span={24} className="form-container listing-view__commissions">
                  <Row>
                    <Col span={16}>Selling price:</Col>
                    <Col span={8} className="listing-view__commissions-money">
                      {currencyBadge}&nbsp;{(+sellingPrice).toFixed(2)}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>Service fee:</Col>
                    <Col span={8} className="listing-view__commissions-money">
                      {currencyBadge}&nbsp;{systemFee.toFixed(2)}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>VAT:</Col>
                    <Col span={8} className="listing-view__commissions-money">
                      {currencyBadge}&nbsp;{systemVat.toFixed(2)}
                    </Col>
                  </Row>
                  <Row className="listing-view__commissions-total">
                    <Col span={16}>{listing.isPaidInFull ? "You will receive" : "Total to be charged to your card"}</Col>
                    <Col span={8} className="listing-view__commissions-money">
                      {currencyBadge}&nbsp;
                      {listing.isPaidInFull
                        ? (sellingPrice - (systemFee + systemVat)).toFixed(2)
                        : ((originalPrice - sellingPrice) + (systemFee + systemVat)).toFixed(2)
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
            { isAdmin &&
              <Row>
                <Col span={24} className="form-container listing-view__additional-info">
                  <Row>
                    <Col span={24}>
                      { listing.type === LISTING_TYPE_B2B ?
                        <React.Fragment>
                          <Row>
                            <Col span={10}>Commission{(listing.fee || listing.commission) ? " %" : ""}:</Col>
                            <Col span={14} className="listing-view__commissions-money">
                              {listing.commission
                                ? `${currencyBadge} ${((sellingPrice * parseFloat(listing.commission)) / 100).toFixed(2)}`
                                : "N/A"
                              }
                            </Col>
                          </Row>
                          {(listing?.additionalDetails?.ratePlanType || listing.ratePlanType) &&
                          <Row>
                            <Col span={10}>Rate plan type:</Col>
                            <Col span={14} className="listing-view__commissions-money">
                              {listing?.additionalDetails?.ratePlanType || listing.ratePlanType}
                            </Col>
                          </Row>
                          }
                          <Row>
                            <Col span={10}>Refundable:</Col>
                            <Col span={14} className="listing-view__commissions-money">
                              {listing?.additionalDetails?.isRefundable ? "Yes" : "No"}
                            </Col>
                          </Row>
                        </React.Fragment>
                        : <React.Fragment>
                          <Row>
                            <Col span={10}>Payment status:</Col>
                            <Col span={14} className="listing-view__commissions-money">
                              {(!listing?.isPaidInFull || listing?.isPaidInFull === "arrival") ? "Payment at the property" : "Paid in full"}
                            </Col>
                          </Row>
                        </React.Fragment>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          </Col>
        </Row>
        <Row className="listing-view__hotel-description mobile-view">
          <Col span={24}>
            <Row>
              <Col span={24} className="listing-view__hotel-description-title">
                Description
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {parse(hotel.description)}
              </Col>
            </Row>
          </Col>
        </Row>
        { hotel.topFacilities && !!hotel.topFacilities.length &&
          <Row>
            <div className="form-container listing-view__facilities mobile-view">
              <div className="listing-view__facilities-container">
                <div className="listing-view__facilities-label-container">
                  <div className="listing-view__facilities-label">Amenities</div>
                  <div
                    className="listing-view__facilities-see-all"
                    onClick={() => showModal({
                      width: "900px",
                      type: MODAL_TYPE_LISTING_AMENITIES,
                      params: {
                        amenities: hotelFacilities
                      }
                    })}
                  >
                    See all <img src="/images/arrow-circle-icon.svg" alt="See all"/>
                  </div>
                </div>
                <div className="listing__field-facilities">
                  {
                    hotel.topFacilities.map((item, index) => (
                      <div className="listing__field-facilities-item" key={`hotel-facilities-${item}-${index}`}>
                        <img
                          className="listing-view__facilities-icon"
                          src={`/images/icons/${item.toLowerCase().replace(/\W/g, "-")}.svg`}
                          alt={item}
                        />
                        <span>{item}</span>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </Row>
        }
        { listing.buyerId && listing.buyerId === user.id && listing.type === LISTING_TYPE_ORGANIC &&
          <Row className="listing-view__submit mobile-view mobile-view--dispute">
            <div className="listing-view__dispute-submit" onClick={createDisputeHandler}>Open dispute</div>
          </Row>
        }
      </Col>
    </Row>
  );
};

ListingView.propTypes = {
  listing: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  hotel: PropTypes.object,
  hotelFacilities: PropTypes.array,
  isPreview: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  fee: PropTypes.number.isRequired,
  vat: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.string,
  publishListing: PropTypes.func,
  setNewListing: PropTypes.func,
  saveListing: PropTypes.func,
  saveDraftListing: PropTypes.func,
  showModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  cardVerificationStatus: PropTypes.string.isRequired,
  deleteListing: PropTypes.func.isRequired,
  setShowCardDetailsModal: PropTypes.func.isRequired
};

export default withRouter(connect(
  state => ({
    user: state.auth.user,
    userRole: state.profile.permissions.role,
    isAuthenticated: state.auth.isAuthenticated,
    fee: state.profile.permissions.fee,
    vat: state.profile.permissions.vat,
    currency: state.profile.permissions.currency,
    isProcessing: state.listing.processing,
    cardVerificationStatus: state.profile.permissions.cardVerificationStatus,
    error: state.listing.error
  }),
  {
    showModal: showModalAction,
    setNewListing: setNewListingData,
    deleteListing: deleteListingRequest,
    setShowCardDetailsModal: setShowCardDetailsModalAction,
  }
)(ListingView));
