export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const GET_PLACES_REQUEST = "GET_PLACES_REQUEST";
export const GET_PLACES_REQUEST_ERROR = "GET_PLACES_REQUEST_ERROR";
export const GET_PLACES_REQUEST_SUCCESS = "GET_PLACES_REQUEST_SUCCESS";

export const GET_PLACE_DETAILS_REQUEST = "GET_PLACE_DETAILS_REQUEST";
export const GET_PLACE_DETAILS_REQUEST_ERROR = "GET_PLACE_DETAILS_REQUEST_ERROR";
export const GET_PLACE_DETAILS_REQUEST_SUCCESS = "GET_PLACE_DETAILS_REQUEST_SUCCESS";

export const SET_PLACES_LIST = "SET_PLACES_LIST";
