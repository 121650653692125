import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import Menu from "../shared/Menu";

function BlogLayout({ children, location }) {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  return (
    <Row>
      <Col span={24} className="admin-website-management">
        <Menu
          items={[
            {
              url: "/admin/blog/articles",
              label: "Articles",
              key: "articles"
            },
            {
              url: "/admin/blog/categories",
              label: "Categories",
              key: "categories"
            }
          ]}
          mode="horizontal"
          selected={[active]}
        />
      </Col>

      <Col span={24}>
        { children }
      </Col>
    </Row>
  )
}

BlogLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(BlogLayout);
