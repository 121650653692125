import React, { useCallback, useState } from "react";
import { Button, Checkbox, Col, Input, Row } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Link, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import Label from "../shared/Form/Label";

const GoogleSignUpSchema = yup.object().shape({
  termsAndConditions: yup.boolean().oneOf([true]),
  promocode: yup.string().trim().nullable()
});

const GoogleSignUp = () => {
  const { state } = useLocation();
  const { handleSubmit, errors, control } = useForm({
    validationSchema: GoogleSignUpSchema
  });

  const [termsAndConditionsSelected, setTermsAndConditionsSelected] = useState(false);
  const [marketingSelected, setMarketingSelected] = useState(false);

  const getQueryPrevLocation = useCallback((data) => queryString.stringify({
    ...data,
    ...queryString.parse(state?.from?.search),
    ...{ prevPath: state?.from?.pathname }
  }, {
    skipEmptyString: true,
    skipNull: true
  }, [state]));

  const googleSignUpHandler = (data) => {
    window.location.href = `${process.env.REACT_APP_API_HOST}/api/v1/auth/google-login?${getQueryPrevLocation(data)}`;
  };

  return (
    <Row justify="space-around" align="middle" className="auth-row">
      <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }}>
        <div className="google-sign-up">
          <h1 className="google-sign-up__title">Sign Up With Google</h1>

          <form onSubmit={handleSubmit(googleSignUpHandler)} className="google-sign-up__form form-container">
            <div className="google-sign-up__field">
              <Label htmlFor="google-sign-up-promocode">Introducer Code/Promotion Code</Label>
              <Controller
                name="promocode"
                as={<Input
                  id="google-sign-up-promocode"
                  className={`form-input ${errors.promocode ? "form-input--error" : ""}`}
                  data-testid="-google-sign-up-promocode"
                />}
                control={control}
              />
              { errors.promocode &&
              <span className="sign-up__error" data-testid="sign-up-error">{errors.promocode.message}</span>
              }
            </div>
            <div className="google-sign-up__checkbox-container">
              <Controller
                name="termsAndConditions"
                as={<Checkbox
                  value={termsAndConditionsSelected}
                  className={`checkbox--primary google-sign-up__checkbox${errors.termsAndConditions ? " google-sign-up__checkbox--error" : ""}`}
                >
                  By signing in or creating an account, you agree with our <Link
                    to="/auth/google-sign-up"
                    className="link--primary google-sign-up__link"
                  >Terms & Conditions</Link> and <Link
                    to="/auth/google-sign-up"
                    className="link--primary google-sign-up__link"
                  >Privacy
                    Statement</Link>
                </Checkbox>}
                control={control}
                defaultValue={termsAndConditionsSelected}
                onChange={([event]) => {
                  setTermsAndConditionsSelected(event.target.checked);

                  return event.target.checked;
                }}
              />
            </div>

            <div className="google-sign-up__checkbox-container">
              <Controller
                name="marketing"
                as={<Checkbox
                  value={marketingSelected}
                  className="checkbox--primary google-sign-up__checkbox google-sign-up__checkbox--center-label"
                >
                  I want to receive marketing messages from PlansChange
                </Checkbox>}
                control={control}
                defaultValue={marketingSelected}
                onChange={([event]) => {
                  setMarketingSelected(event.target.checked);

                  return event.target.checked;
                }}
              />
            </div>

            <Button htmlType="submit" type="primary" className="sign-up__submit" block>Continue</Button>
          </form>
        </div>
      </Col>
    </Row>
  );
};

export default GoogleSignUp;
