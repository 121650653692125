import moment from "moment";

export const createHotelRoomsText = (rooms, adults, children) => {
  const roomsText = rooms ? `${rooms} room${+rooms > 1 ? "s" : ""}` : "";
  const adultsText = adults ? `${adults} adult${+adults > 1 ? "s" : ""}` : "";
  const childrenText = children ? `${children} child${+children !== 1 ? "ren" : ""}` : "";

  return `${roomsText}${rooms && adults ? ", " : ""}${adultsText}${rooms && children ? ", " : ""}${childrenText}`;
};

export const createHotelRoomsTextsArray = (rooms, adults, children) => {
  const texts = [];

  if (rooms) {
    texts.push(`${rooms} room${+rooms > 1 ? "s" : ""}`);
  }

  if (adults) {
    texts.push(`${adults} adult${+adults > 1 ? "s" : ""}`);
  }

  if (children) {
    texts.push(`${children} child${+children !== 1 ? "ren" : ""}`);
  }

  return texts;
};

export const createHotelGuestsArray = (rooms, roomType, adults, children) => {
  const texts = [];

  if (adults) {
    texts.push(`${adults} adult${+children !== 1 ? "s" : ""}`);
  }

  if (rooms) {
    if (roomType) {
      texts.push(`${rooms} ${roomType}`);
    } else {
      texts.push(`${rooms} room${+adults > 1 ? "s" : ""}`);
    }
  }

  if (children) {
    texts.push(`${children} child${+children !== 1 ? "ren" : ""}`);
  }

  return texts;
};

export const calcDiscount = (sellingPrice, originalPrice) => {
  const a = parseFloat(sellingPrice);
  const b = parseFloat(originalPrice);

  return a && b ? Math.round(((b - a) / b) * 100) : null;
};

export const getListingStatus = (status, checkInDate) => {
  const dateCheckIn = moment(checkInDate).format("YYYY-MM-DD");
  const dateNow = moment().format("YYYY-MM-DD");

  switch (true) {
    case (status === "published" && !moment(dateCheckIn).isSameOrAfter(dateNow, "day")):
      return "expired";
    case status === "published":
      return "active";
    default:
      return status;
  }
};

export const getFeeLabel = (id, feeConfigurations = []) => {
  const feeObj = feeConfigurations.find(item => id === item.id);

  return feeObj ? `${feeObj.code} (${feeObj.fee}%)` : id;
};

export const round = (value, decimals = 0) => {
  return Math.round((value + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const getConvertedPrice = ({ originalPrice, originalCurrency, convertedCurrency, exchangeRates }) => {
  let convertedPrice = originalPrice;

  if (originalCurrency !== convertedCurrency) {
    const exchangeRate = exchangeRates.find(currency => {
      return currency.currency === `${originalCurrency}-${convertedCurrency}`;
    });

    if (exchangeRate) {
      convertedPrice = round(originalPrice * exchangeRate.rate, 2);
    }
  }

  return (+convertedPrice).toFixed(2);
};

export const getCurrencyBadge = (currency) => {
  if (!currency) {
    return "";
  }

  return currency === "USD" ? "$" : "£";
};

export const ejectFaqs = (categories) => {
  let faqs = [];

  categories.forEach(item => {
    faqs = [...faqs, ...item.Faqs];
  });

  return faqs;
};

export const formatAddress = (addressItems = []) => {
  const formattedAddress = addressItems.filter(item => {
    return Boolean(item);
  });

  return formattedAddress.join(", ");
};

export const parseDataForHotelCard = (hotelInfo) => {
  const {
    Hotel,
    type,
    roomType,
    Seller,
    sellingPrice,
    status,
    currency,
    rooms,
    adults,
    children,
    facilities,
    checkInDate,
    checkOutDate,
    additionalDetails
  } = hotelInfo;

  const hotelRoomType = type === "organic" ? roomType : "";
  const { HotelAddress } = Hotel || {};

  return {
    hotelRating: hotelInfo.hotelRating,
    hotelName: hotelInfo.Hotel?.name,
    status: status ? getListingStatus(status, checkInDate) : null,
    addressList: [
      HotelAddress?.countryName,
      HotelAddress?.cityName,
      HotelAddress?.addressLine,
      HotelAddress?.postalCode
    ],
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    id: hotelInfo.id,
    image: Hotel?.HotelImages?.[0]?.imageUrl,
    price: sellingPrice && (+sellingPrice).toFixed(2),
    currencyBadge: getCurrencyBadge(Seller ? Seller.currency : currency),
    guestsList: [
      ...createHotelGuestsArray(rooms, hotelRoomType, adults, children),
      ...facilities || []
    ],
    cancellationData: {
      isRefundable: additionalDetails?.isRefundable,
      cancelDeadline: additionalDetails?.cancelDeadline,
      guaranteeType: additionalDetails?.guarantee?.type,
      guaranteePaymentDeadline: additionalDetails?.guarantee?.deadline,
      cancellationPolicy: additionalDetails?.cancellationPolicy,
      depositPolicy: additionalDetails?.depositPolicy
    },
  }
};

export const getBrowserInfo = () => {
  return {
    javaEnabled: navigator.javaEnabled(),
    language: navigator.language,
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timezoneOffset: (new Date()).getTimezoneOffset().toString(),
    userAgent: navigator.userAgent,
    javascriptEnabled: true
  };
};

export const stripTags = (html) => {
  return html.replace(/<[^>]*>/g, "");
}
