import React from "react";
import { Route, Switch } from "react-router-dom";

import SellerLayout from "../layout/SellerLayout";
import SellerAccountRoutes from "./SellerAccountRoutes";

import Seo from "../shared/Seo";
import MyListings from "../Listing/MyListings";
import PageNotFound from "../PageNotFound/PageNotFound";

const seoData = {
  active: {
    title: "My Active Listings ",
    description:
      "You've listed your hotel room and now it's time to see how it's doing. How many people have viewed your listing? Do you need to edit it? Do it all here!",
  },
  drafts: {
    title: "My Draft Listings ",
    description:
      "Are you looking to make your hotel room listing live? View your draft listings and amend before making active.",
  },
  sold: {
    title: "My Sold Listings ",
    description:
      "You've done it! You sold your hotel room, view your sold listings and how much money you'll receive for selling your booking.",
  },
};

export default function SellerRoutes() {
  return (
    <SellerLayout>
      <Switch>
        <Route
          path="/seller/listings/:status"
          exact
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo {...seoData[props.match.params.status]}>
              <MyListings {...props} />
            </Seo>
          )}
        />
        <Route path="/seller" component={SellerAccountRoutes} />
        <Route component={PageNotFound} />
      </Switch>
    </SellerLayout>
  );
}
