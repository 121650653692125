import React from "react";
import { Col, Input, Row } from "antd";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { connect } from "react-redux";

import { changePasswordRequest } from "../../../redux/profile/profileActions";
import Label from "../../shared/Form/Label";
import useAdditionalAction from "../../../hooks/useAdditionalAction";
import SubmitButton from "../../shared/SubmitButton";

const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required("Current password can not be empty"),
  newPassword: yup.string().required("New password can not be empty").matches(
    /^(?=.*\d)(?=.*[A-Z]).{8,255}$/,
    "Password should be at least 8 characters long and include at least one number and one capital letter"
  ),
  confirmNewPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Passwords don't match")
});

export const ChangePassword = ({
  error,
  processing,
  success,
}) => {
  const { onSubmit, handleChange, errors, control } = useAdditionalAction(
    {
      validationSchema: ChangePasswordSchema,
      action: changePasswordRequest,
      error,
      success
    })

  return (
    <div className="additional-service">
      <form onSubmit={onSubmit}>
        <Row className="additional-service__row">

          <Col span={24}>
            <div className="input-with-label__field">
              <Label htmlFor="current-password">Current Password</Label>
              <Controller
                name="currentPassword"
                as={<Input.Password
                  id="current-password"
                  placeholder="Current Password"
                  className={`form-input ${error || errors.currentPassword ? "form-input--error" : ""}`}
                  visibilityToggle={true}
                  data-testid="current-password"
                />}
                onChange={handleChange}
                control={control}
              />
              {(error || errors.currentPassword) && <span
                className="input-with-label__error"
                data-testid="additional-service-error"
              >{error || errors.currentPassword.message}</span>}
            </div>
          </Col>
        </Row>

        <Row className="additional-service__row">
          <Col span={24}>
            <div className="input-with-label__field">
              <Label htmlFor="new-password">New Password</Label>
              <Controller
                name="newPassword"
                as={<Input.Password
                  id="new-password"
                  placeholder="New Password"
                  className={`form-input ${errors.newPassword ? "form-input--error" : ""}`}
                  visibilityToggle={true}
                  data-testid="new-password"
                />}
                onChange={handleChange}
                control={control}
              />
              {errors.newPassword && <span
                className="input-with-label__error"
                data-testid="additional-service-error"
              >{errors.newPassword.message}</span>}
            </div>
          </Col>
        </Row>

        <Row className="additional-service__row">
          <Col span={24}>
            <div className="input-with-label__field">
              <Label htmlFor="confirm-new-password">Confirm Password</Label>
              <Controller
                name="confirmNewPassword"
                as={<Input.Password
                  id="confirm-new-password"
                  placeholder="Confirm Password"
                  className={`form-input ${errors.confirmNewPassword ? "form-input--error" : ""}`}
                  visibilityToggle={true}
                  data-testid="confirm-new-password"
                />}
                onChange={handleChange}
                control={control}
              />
              {errors.confirmNewPassword && <span
                className="input-with-label__error"
                data-testid="additional-service-error"
              >{errors.confirmNewPassword.message}</span>}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <SubmitButton
              type={!success ? "default" : "primary"}
              success={success}
              loading={processing}
              data-testid="additional-service-submit"
              successText="Password Changed"
              text="Reset Password"
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

ChangePassword.propTypes = {
  error: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    ...state.profile.changePassword
  })
)(ChangePassword);
