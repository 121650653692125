import React from "react";
import PropTypes from "prop-types";

export const ImageBgCover = ({ children, className, iconSize, url, height = 100 }) => {
  return (
    <div
      className={`image-bg-cover${className ? ` ${className}` : ""}`}
      style={{
        width: "100%",
        paddingTop: `${height}%`,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: url && `url(${url})`,
        backgroundColor: !url && "#FAFAFA",
        border: url ? "none" : "2px solid #EDEDED",
        borderRadius: url ? 0 : "16px",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0",
        top: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        {
          !url && <div style={{ textAlign: "center", color: "#BFBFBF", fontWeight: 600 }}>
            <img src="/images/no-image.svg" alt="info" />
            <div style={iconSize === "big" ? { fontSize: "18px" } : { fontSize: "14px", maxWidth: "120px" }}>
              {iconSize ? "No hotel photos available" : ""}
            </div>
          </div>
        }
        {children}
      </div>
    </div>
  )
};

ImageBgCover.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  iconSize: PropTypes.string,
  url: PropTypes.string,
  height: PropTypes.number
};
