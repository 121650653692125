import * as types from "./articleActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  requestSuccess: false,
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: [],
  options: [],
  singleArticle: {
    body: "",
    categories: [],
    coverImageUrl: "",
    title: ""
  }
};

const articles = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
        requestSuccess: false
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_ARTICLE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: ""
      };
    case types.ADMIN_DELETE_ARTICLE_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: true
      };
    case types.ADMIN_PUBLISH_ARTICLE_REQUEST_SUCCESS:
      return {
        ...state,
        data: state.data.map(item => item.id === action.response.id ? action.response : item)
      };
    case types.ADMIN_GET_ARTICLE_REQUEST_SUCCESS:
    case types.ADMIN_CREATE_ARTICLE_REQUEST_SUCCESS:
    case types.ADMIN_UPDATE_ARTICLE_REQUEST_SUCCESS:
      return {
        ...state,
        singleArticle: action.response,
        error: ""
      };
    case types.ADMIN_GET_ARTICLE_LIST_REQUEST_ERROR:
    case types.ADMIN_GET_ARTICLE_REQUEST_ERROR:
    case types.ADMIN_CREATE_ARTICLE_REQUEST_ERROR:
    case types.ADMIN_UPDATE_ARTICLE_REQUEST_ERROR:
    case types.ADMIN_DELETE_ARTICLE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        requestSuccess: false
      };
    case types.ADMIN_CLEAR_ARTICLE:
      return {
        ...state,
        singleArticle: {
          body: "",
          categories: [],
          coverImageUrl: "",
          title: ""
        }
      };
    case types.ADMIN_CLEAR_ARTICLE_ERROR:
      return {
        ...state,
        error: "",
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default articles;
