import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { clearProfileErrorAction } from "../redux/profile/profileActions";

const useAdditionalAction = ({ validationSchema, defaultValues, action, error, success }) => {
  const dispatch = useDispatch();
  const resetProfileState = () => dispatch(clearProfileErrorAction());
  const dispatchAction = (data) => dispatch(action(data));
  const { handleSubmit, errors, control, ...rest } = useForm({
    validationSchema,
    defaultValues,
  });

  const onSubmit = handleSubmit((data) => {
    (error || success) && resetProfileState();
    dispatchAction(data);
  });

  const handleChange = ([event]) => {
    (error || success) && resetProfileState();

    return event;
  };

  return { onSubmit, handleChange, errors, control, ...rest };
};

export default useAdditionalAction;
