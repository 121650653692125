import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Select } from "antd";
import moment from "moment";

const months = [
  {
    value: 1,
    label: "January"
  },
  {
    value: 2,
    label: "February"
  },
  {
    value: 3,
    label: "March"
  },
  {
    value: 4,
    label: "April"
  },
  {
    value: 5,
    label: "May"
  },
  {
    value: 6,
    label: "June"
  },
  {
    value: 7,
    label: "July"
  },
  {
    value: 8,
    label: "August"
  },
  {
    value: 9,
    label: "September"
  },
  {
    value: 10,
    label: "October"
  },
  {
    value: 11,
    label: "November"
  },
  {
    value: 12,
    label: "December"
  }
];

export const DobPicker = ({ defaultValue, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [selectedYear, setSelectedYear] = useState(undefined);

  const getDates = () => {
    const dates = [];

    for (let date = 1; date <= 31; date++) {
      dates.push(date);
    }

    return dates;
  };

  const getYears = () => {
    const years = [];
    const startYear = moment().subtract(120, "year").year();
    const endYear = moment().subtract(18, "year").year();

    for (let year = endYear; year >= startYear; year--) {
      years.push(year);
    }

    return years;
  };

  useEffect(() => {
    if (defaultValue) {
      const dateOfBirth = moment(defaultValue);

      if (!selectedDate) {
        setSelectedDate(dateOfBirth.date());
      }

      if (!selectedMonth) {
        setSelectedMonth(dateOfBirth.month() + 1);
      }

      if (!selectedYear) {
        setSelectedYear(dateOfBirth.year());
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    onChange(moment.utc(`${selectedYear}-${selectedMonth}-${selectedDate}`, "YYYY-M-D", true).startOf("day"));
  }, [selectedDate, selectedMonth, selectedYear]);

  return (
    <Row gutter={16} className="dob-picker">
      <Col span={6}>
        <Select
          className="form-select"
          id="dob-picker-date"
          key={`dob-picker-date-${selectedDate}`}
          defaultValue={selectedDate}
          onChange={value => {
            setSelectedDate(value);

            return value;
          }}
          placeholder="day"
        >
          {getDates().map((option, key) => <Select.Option
            key={`${key}-date-${option}`}
            value={option}
          >
            {option}
          </Select.Option>)}
        </Select>
      </Col>
      <Col span={10}>
        <Select
          className="form-select"
          id="dob-picker-month"
          key={`dob-picker-month-${selectedMonth}`}
          defaultValue={selectedMonth}
          onChange={value => {
            setSelectedMonth(value);

            return value;
          }}
          placeholder="month"
        >
          {months.map((option, key) => <Select.Option
            key={`${key}-month-${option.value}`}
            value={option.value}
          >
            {option.label}
          </Select.Option>)}
        </Select>
      </Col>
      <Col span={8}>
        <Select
          className="form-select"
          id="dob-picker-year"
          key={`dob-picker-year-${selectedYear}`}
          defaultValue={selectedYear}
          onChange={value => {
            setSelectedYear(value);

            return value;
          }}
          placeholder="year"
        >
          {getYears().map((option, key) => <Select.Option
            key={`${key}-year-${option}`}
            value={option}
          >
            {option}
          </Select.Option>)}
        </Select>
      </Col>
    </Row>
  );
};

DobPicker.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default DobPicker;
