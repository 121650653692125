import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as yup from "yup";
import SelectWithLabel from "../shared/SelectWithLabel";
import InputWithLabel from "../shared/InputWithLabel";
import Label from "../shared/Form/Label";
import { clearProfileErrorAction, createBankAccountRequest } from "../../redux/profile/profileActions";
import { regions } from "../../api/constants";

const BankAccountSchema = yup.object().shape({
  holderName: yup.string().required("Account Holders Name can not be empty"),
  accountNumber: yup.string()
    .min(8, "Incorrect Bank Account Number")
    .max(10, "Incorrect Bank Account Number")
    .matches(/[0-9]/, "Incorrect Bank Account Number")
    .required("Bank Account Number can not be empty"),
  bankName: yup.string().required("Bank Name can not be empty"),
  sortCodePart1: yup.mixed().when("country", {
    is: value => value && ["US"].includes(value),
    then: yup.mixed().notRequired(),
    otherwise: yup.string()
      .matches(/[0-9]/, "Incorrect SORT Code")
      .min(2, "Incorrect SORT Code")
      .max(2, "Incorrect SORT Code")
      .required("SORT Code can not be empty")
  }),
  sortCodePart2: yup.mixed().when("country", {
    is: value => value && ["US"].includes(value),
    then: yup.mixed().notRequired(),
    otherwise: yup.string()
      .matches(/[0-9]/, "Incorrect SORT Code")
      .min(2, "Incorrect SORT Code")
      .max(2, "Incorrect SORT Code")
      .required("SORT Code can not be empty")
  }),
  sortCodePart3: yup.mixed().when("country", {
    is: value => value && ["US"].includes(value),
    then: yup.mixed().notRequired(),
    otherwise: yup.string()
      .matches(/[0-9]/, "Incorrect SORT Code")
      .min(2, "Incorrect SORT Code")
      .max(2, "Incorrect SORT Code")
      .required("SORT Code can not be empty")
  }),
  aba: yup.mixed().when("country", {
    is: value => value && ["US"].includes(value),
    then: yup.string()
      .matches(/[0-9]/, "Incorrect ABA Number")
      .min(9, "Incorrect ABA Number")
      .max(9, "Incorrect ABA Number")
      .required("ABA Number can not be empty"),
    otherwise: yup.mixed().notRequired()
  }),
  address: yup.string().required("Address can not be empty"),
  city: yup.string().trim().required("City can not be empty"),
  country: yup.string().trim().required("Country / Region can not be empty"),
  state: yup.mixed().when("country", {
    is: value => value && ["US"].includes(value),
    then: yup.mixed().required("State can not be empty"),
    otherwise: yup.mixed().notRequired()
  }),
  postcode: yup.string().trim().required("Zip/Post Code can not be empty")
});

export const BankAccountForm = ({
  isProcessing,
  createBankAccount,
  error,
  errorsList,
  clearError
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const { handleSubmit, errors, control } = useForm({
    validationSchema: BankAccountSchema
  });

  const createBankAccountHandler = (data) => {
    data.sortCode = `${data.sortCodePart1}${data.sortCodePart2}${data.sortCodePart3}`;

    createBankAccount(data);
  };

  const clearErrorHandler = () => {
    if (error || (errorsList && !!Object.keys(errorsList).length)) {
      clearError();
    }
  };

  const sortCodeErrors = errors.sortCodePart1 || errors.sortCodePart3 || errors.sortCodePart3;

  return (
    <div className="bank-account-form">
      <form onSubmit={handleSubmit(createBankAccountHandler)}>
        <section className="section">
          <div className="bank-account-form__info">
            <img src="/images/info.svg" alt="info" className="bank-account-form__info-icon"/>
            <div className="bank-account-form__info-text">
              This is where you would like your money paid into from the sale of your booking
            </div>
          </div>
        </section>
        <Row>
          <Col>
            <h2 className="form-subtitle">Billing Address</h2>
          </Col>
        </Row>
        <section className="section">
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12} className="profile-section">
              <Row className="bank-account-form__row">
                <Col span={24}>
                  <InputWithLabel
                    name="address"
                    control={control}
                    id="address"
                    label="Address"
                    error={errors.address}
                    placeholder="Address"
                    onChange={([event]) => {
                      clearErrorHandler();

                      return event.target.value;
                    }}
                  />
                </Col>
              </Row>
              <Row className="bank-account-form__row">
                <Col span={24}>
                  <InputWithLabel
                    name="city"
                    control={control}
                    id="city"
                    label="City"
                    error={errors.city}
                    placeholder="City"
                    onChange={([event]) => {
                      clearErrorHandler();

                      return event.target.value;
                    }}
                  />
                </Col>
              </Row>
              <Row className="bank-account-form__row">
                <Col span={24}>
                  <SelectWithLabel
                    name="country"
                    control={control}
                    id="country"
                    label="Country / Region"
                    error={errors.country}
                    options={[
                      {
                        value: "GB",
                        label: "United Kingdom"
                      },
                      {
                        value: "US",
                        label: "United States"
                      }
                    ]}
                    placeholder="Select Country / Region"
                    onChange={value => {
                      clearErrorHandler();
                      setSelectedCountry(value[0]);

                      return value[0];
                    }}
                  />
                </Col>
              </Row>
              {
                ["US", "CA"].includes(selectedCountry) &&
                <Row className="bank-account-form__row">
                  <Col span={24}>
                    <SelectWithLabel
                      name="state"
                      control={control}
                      id="state"
                      label="State"
                      showSearch={true}
                      onChange={value => {
                        return value[0];
                      }}
                      error={errors.state}
                      options={regions[selectedCountry].map(state => {
                        return {
                          value: state.code,
                          label: state.name
                        };
                      })}
                      placeholder="Select State"
                    />
                  </Col>
                </Row>
              }
              <Row className="bank-account-form__row">
                <Col span={24}>
                  <InputWithLabel
                    name="postcode"
                    control={control}
                    id="postcode"
                    label="Zip/Post Code"
                    error={errors.postcode}
                    placeholder="Zip/Post Code"
                    onChange={([event]) => {
                      clearErrorHandler();

                      return event.target.value ? event.target.value.toUpperCase() : null;
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={24} lg={12} className="profile-section">
              <Row className="bank-account-form__row">
                <Col span={24}>
                  <InputWithLabel
                    name="holderName"
                    control={control}
                    id="holder-name"
                    label="Account Holders Name"
                    error={errors.holderName}
                    placeholder="Holder Name"
                    onChange={([event]) => {
                      clearErrorHandler();

                      return event.target.value;
                    }}
                  />
                </Col>
              </Row>
              <Row className="bank-account-form__row">
                <Col span={24}>
                  <InputWithLabel
                    name="accountNumber"
                    control={control}
                    id="account-number"
                    label="Bank Account Number"
                    error={errors.accountNumber}
                    placeholder="Account Number"
                    onChange={([event]) => {
                      clearErrorHandler();

                      return event.target.value;
                    }}
                  />
                </Col>
              </Row>
              <Row className="bank-account-form__row">
                <Col span={24}>
                  <InputWithLabel
                    name="bankName"
                    control={control}
                    id="bank-name"
                    label="Bank Name"
                    error={errors.bankName}
                    placeholder="Bank Name"
                    onChange={([event]) => {
                      clearErrorHandler();

                      return event.target.value;
                    }}
                  />
                </Col>
              </Row>
              <Row className="bank-account-form__row">
                <Col span={24}>
                  <Row justify="space-between" align="top">
                    {
                      selectedCountry === "US"
                        ? <Col span={11} className="bank-account-form__sort-code">
                          <Label>ABA Number</Label>
                          <div className="bank-account-form__aba-number-inputs-container">
                            <InputWithLabel
                              name="aba"
                              control={control}
                              id="aba"
                              placeholder="XXXXXXXXX"
                              maxLength={9}
                              isInvalid={!!errors.aba}
                              onChange={([event]) => {
                                clearErrorHandler();

                                return event.target.value;
                              }}
                            />
                          </div>
                          {errors.aba &&
                          <div className="bank-account-form__sort-code-error">
                            Please provide correct ABA Number
                          </div>
                          }
                        </Col>
                        : <Col span={11} className="bank-account-form__sort-code">
                          <Label className={sortCodeErrors ? "input-with-label__label-error" : ""}>SORT Code</Label>
                          <div className="bank-account-form__sort-code-inputs-container">
                            <InputWithLabel
                              name="sortCodePart1"
                              control={control}
                              id="sort-code-part-1"
                              placeholder="XX"
                              maxLength={2}
                              isInvalid={!!errors.sortCodePart1 || !!errors.sortCodePart3 || !!errors.sortCodePart3}
                              onChange={([value]) => {
                                clearErrorHandler();

                                if (value.length >= 2) {
                                  document.getElementById("sort-code-part-2").focus();

                                  return value.slice(0, 2);
                                }

                                return value;
                              }}
                            />
                            <InputWithLabel
                              name="sortCodePart2"
                              control={control}
                              id="sort-code-part-2"
                              placeholder="XX"
                              maxLength={2}
                              isInvalid={!!errors.sortCodePart1 || !!errors.sortCodePart3 || !!errors.sortCodePart3}
                              onChange={([value]) => {
                                clearErrorHandler();

                                if (value.length >= 2) {
                                  document.getElementById("sort-code-part-3").focus();

                                  return value.slice(0, 2);
                                }

                                return value;
                              }}
                            />
                            <InputWithLabel
                              name="sortCodePart3"
                              control={control}
                              id="sort-code-part-3"
                              placeholder="XX"
                              maxLength={2}
                              isInvalid={!!errors.sortCodePart1 || !!errors.sortCodePart3 || !!errors.sortCodePart3}
                              onChange={([value]) => {
                                clearErrorHandler();

                                if (value.length >= 2) {
                                  return value.slice(0, 2);
                                }

                                return value;
                              }}
                            />
                          </div>
                          {sortCodeErrors &&
                          <div className="bank-account-form__sort-code-error">
                            Please provide correct SORT code
                          </div>
                          }
                        </Col>
                    }
                  </Row>
                </Col>
              </Row>
              {(error || (errorsList && !!Object.keys(errorsList).length)) &&
              <Row>
                <Col span={24} className="bank-account-form__error">
                  {error && !Object.keys(errorsList).length &&
                  <span>Something went wrong. Please try again later</span>
                  }
                  {errorsList && !!Object.keys(errorsList).length &&
                  <React.Fragment>
                    {Object.values(errorsList).map((errorLine, key) => <div key={key}>{errorLine}</div>)}
                  </React.Fragment>
                  }
                </Col>
              </Row>
              }
            </Col>
          </Row>
        </section>
        <Button
          htmlType="submit"
          disabled={isProcessing}
          type="primary"
          data-testid="bank-account-form-submit"
        >
          Add Account
        </Button>
      </form>
    </div>
  );
};

BankAccountForm.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  createBankAccount: PropTypes.func.isRequired,
  error: PropTypes.string,
  clearError: PropTypes.func.isRequired,
  errorsList: PropTypes.object
};

export default connect(
  state => ({
    isProcessing: state.profile.processing,
    error: state.profile.bankAccounts.error,
    errorsList: state.profile.bankAccounts.errors
  }),
  {
    createBankAccount: createBankAccountRequest,
    clearError: clearProfileErrorAction
  }
)(BankAccountForm);
