import { all, put, call, takeLatest } from "redux-saga/effects";

import notification from "../../../api/admin/notification";
import * as types from "./notificationActionsTypes";

function *getNotificationList ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(notification.getNotificationList, data);
    yield put({ type: types.ADMIN_GET_NOTIFICATION_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_NOTIFICATION_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *readNotificationList ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(notification.readNotificationList, data);
    yield put({ type: types.ADMIN_READ_NOTIFICATION_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_READ_NOTIFICATION_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_NOTIFICATION_LIST_REQUEST, getNotificationList),
    yield takeLatest(types.ADMIN_READ_NOTIFICATION_LIST_REQUEST, readNotificationList),
  ])
}
