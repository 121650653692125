import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Row, Typography } from "antd";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { getArticleRequest, getRelatedArticlesRequest } from "../../redux/blog/blogActions";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import BlogSingleArticle from "./BlogSingleArticle";
import BlogArticleBigCard from "./BlogArticleBigCard";
import PageNotFound from "../PageNotFound/PageNotFound";
import Loader from "../shared/Loader";
import { getBlogArticleSchemaScriptContent } from "../../helpers/schema";

export const BlogArticle = ({
  article,
  relatedArticles,
  getArticle,
  getRelatedArticles
}) => {
  const { slug } = useParams();
  const [isArticleExist, setIsArticleExist] = useState(true);

  useServerSafeEffect(() => {
    getArticle({ slug }, () => setIsArticleExist(false));
    getRelatedArticles({ slug, page: 1, pageSize: 3 });
  }, [slug]);

  if (!isArticleExist) {
    return <PageNotFound/>;
  }

  return article.id
    ? (
      <React.Fragment>
        <Helmet>
          <title>{article.metaTitle || article.title}</title>
          <meta name="description" content={article.metaDescription || article.shortDescription} />
          <meta name="robots" content="index" />
          <meta name="twitter:card" content="summary_large_image"/>
          <meta property="og:url" content={`${process.env.REACT_APP_URL}/blog/articles/${article.slug}`}/>
          <meta property="og:title" content={article.title}/>
          { !!article.shortDescription &&
            <meta property="og:description" content={article.shortDescription.replace(/\n/ig, " ")}/>
          }
          <meta property="og:image" content={article.coverImageUrl}/>
          { typeof window === "undefined" &&
            <script type="application/ld+json">{getBlogArticleSchemaScriptContent(article)}</script>
          }
        </Helmet>
        <Row className="blog-article__container">
          <Col span={24}>
            <BlogSingleArticle article={article}/>
          </Col>
        </Row>
        { relatedArticles && !!relatedArticles.length &&
          <Row justify="space-around" align="middle" className="blog-article__related-articles">
            <Col xs={{ span: 24 }} md={{ span: 20 }}>
              <Row>
                <Col span={24}>
                  <Typography.Title level={3}>
                    Related Articles
                  </Typography.Title>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {relatedArticles.map((relatedArticle) => (
                  <Col
                    key={`related-article-${article.id}-${relatedArticle.id}`}
                    xs={{ span: 24 }}
                    sm={{ span: 8 }}
                  >
                    <BlogArticleBigCard article={relatedArticle}/>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        }
      </React.Fragment>
    )
    : <Loader type="page"/>;
};

BlogArticle.propTypes = {
  article: PropTypes.object.isRequired,
  relatedArticles: PropTypes.array,
  getArticle: PropTypes.func.isRequired,
  getRelatedArticles: PropTypes.func.isRequired
};

export default connect(
  state => ({
    article: state.blog.singleArticle,
    relatedArticles: state.blog.singleArticle.relatedArticles
  }),
  {
    getArticle: getArticleRequest,
    getRelatedArticles: getRelatedArticlesRequest
  }
)(BlogArticle);
