import * as types from "./highlightsActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  requestSuccess: false,
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 50,
  data: [],
  singleHighlight: {
  }
};

const highlights = (state = initialState, action) => {
  switch (action.type) {
    case types.ADMIN_HIGHLIGHTS_SHOW_LOADING:
      return {
        ...state,
        processing: true,
        requestSuccess: false
      };
    case types.ADMIN_HIGHLIGHTS_HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_HIGHLIGHTS_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: ""
      };
    case types.ADMIN_DELETE_HIGHLIGHT_REQUEST_SUCCESS:
    case types.ADMIN_UPDATE_HIGHLIGHT_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: true
      };
    case types.ADMIN_ADD_HIGHLIGHT_REQUEST_SUCCESS:
    case types.ADMIN_GET_HIGHLIGHT_REQUEST_SUCCESS:
      return {
        ...state,
        singleHighlight: action.response
      };
    case types.ADMIN_CLEAR_HIGHLIGHT_DETAILS_ACTION:
      return {
        ...state,
        singleHighlight: initialState.singleHighlight
      };
    case types.ADMIN_GET_HIGHLIGHTS_REQUEST_ERROR:
    case types.ADMIN_DELETE_HIGHLIGHT_REQUEST_ERROR:
    case types.ADMIN_ADD_HIGHLIGHT_REQUEST_ERROR:
    case types.ADMIN_GET_HIGHLIGHT_REQUEST_ERROR:
    case types.ADMIN_UPDATE_HIGHLIGHT_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default highlights;
