import { all } from "redux-saga/effects";

import adminArticleSagas from "./admin/article/articleSagas";
import adminCategorySagas from "./admin/category/categorySagas";
import adminDisputeSagas from "./admin/dispute/disputeSagas";
import adminFeedbackSagas from "./admin/feedback/feedbackSagas";
import adminFinanceSagas from "./admin/finance/financeSagas";
import adminNotificationSagas from "./admin/notification/notificationSagas";
import adminStaticPagesSagas from "./admin/staticPages/staticPagesSagas";
import adminListingsSagas from "./admin/listings/listingsSagas";
import adminDashboardSagas from "./admin/dashboard/dashboardSagas";
import adminFaqSagas from "./admin/faq/faqSagas";
import adminExperiencesSagas from "./admin/experiences/experiencesSagas";
import adminSubscriptionsSagas from "./admin/subscriptions/subscriptionsSagas";
import adminHotelsSagas from "./admin/hotels/hotelsSagas";
import adminHighlightsSagas from "./admin/highlights/highlightsSagas";
import adminReservationsSagas from "./admin/reservations/reservationsSagas";
import adminPlacesSagas from "./admin/places/placesSagas";
import authSagas from "./auth/authSagas";
import blogSagas from "./blog/blogSagas";
import listingSagas from "./listing/listingSagas";
import profileDetailsSagas from "./profileDetails/profileDetailsSagas";
import profileSagas from "./profile/profileSagas";
import staticPagesSagas from "./staticPages/staticPagesSagas";
import userManagementSagas from "./admin/userManagement/userManagementSagas";
import generalSagas from "./general/generalSagas";
import placesSagas from "./places/placesSagas";
import highlightsSagas from "./highlights/highlightsSagas";

export default function *rootSaga() {
  yield all([
    adminArticleSagas(),
    adminCategorySagas(),
    adminDisputeSagas(),
    adminFeedbackSagas(),
    adminFinanceSagas(),
    adminNotificationSagas(),
    adminStaticPagesSagas(),
    adminListingsSagas(),
    adminDashboardSagas(),
    adminFaqSagas(),
    adminExperiencesSagas(),
    adminSubscriptionsSagas(),
    adminHotelsSagas(),
    adminHighlightsSagas(),
    adminReservationsSagas(),
    adminPlacesSagas(),
    authSagas(),
    blogSagas(),
    listingSagas(),
    profileDetailsSagas(),
    profileSagas(),
    staticPagesSagas(),
    userManagementSagas(),
    generalSagas(),
    placesSagas(),
    highlightsSagas()
  ]);
}
