import React from "react";

export let Swiper = () => <div />;
export let SwiperSlide = () => <div />;
export let Pagination = () => <div />;
export let Navigation = () => <div />;

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  const SwiperGlobal = require("swiper");
  Pagination = SwiperGlobal.Pagination;
  Navigation = SwiperGlobal.Navigation;

  // eslint-disable-next-line global-require
  const SwiperReact = require("swiper/react");
  Swiper = SwiperReact.Swiper;
  SwiperSlide = SwiperReact.SwiperSlide;
}
