import * as types from "./financeActionsTypes";
import { ISO31661ALPHA2 } from "../../../api/constants";

const transformOption = (option) => {
  const label = ISO31661ALPHA2.find(item => option.country === item.code);

  return { ...option, countryName: label ? label.name : option.country }
};

export const initialState = {
  chargeOptions: [],
  feeConfigurations: [],
  paymentFeesConfigurations: {
    gbp: [],
    usd: [],
  },
  listings: {
    total: 0,
    curPage: 1,
    pages: 1,
    perPage: 10,
    data: []
  },
  processing: false,
  error: ""
};

const finance = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_CHARGE_OPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        chargeOptions: action.response.map(item => transformOption(item)),
        error: "",
      };
    case types.ADMIN_UPDATE_CHARGE_OPTION_REQUEST_SUCCESS:
      return {
        ...state,
        chargeOptions: state.chargeOptions.map(item => item.id === action.response.id
          ? transformOption(action.response)
          : item
        ),
        error: "",
      };
    case types.ADMIN_GET_FEE_CONFIGURATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        feeConfigurations: action.response.sort((a, b) => a.code - b.code),
        error: "",
      };
    case types.ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST_SUCCESS:
      return {
        ...state,
        feeConfigurations: state.feeConfigurations.map(item => item.id === action.response.id ? action.response : item),
        error: "",
      };
    case types.ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST_SUCCESS:
      return {
        ...state,
        paymentFeesConfigurations: action.response,
        error: "",
      };
    case types.ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST_SUCCESS:
      return {
        ...state,
        listings: action.response,
        error: "",
      };
    case types.ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        paymentFeesConfigurations: action.response,
        error: "",
      };
    case types.ADMIN_GET_CHARGE_OPTIONS_REQUEST_ERROR:
    case types.ADMIN_UPDATE_CHARGE_OPTION_REQUEST_ERROR:
    case types.ADMIN_SET_DEFAULT_COMMISSION_REQUEST_ERROR:
    case types.ADMIN_GET_FEE_CONFIGURATIONS_REQUEST_ERROR:
    case types.ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST_ERROR:
    case types.ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST_ERROR:
    case types.ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_CLEAR_FINANCE_SETTINGS_ERROR:
      return {
        ...state,
        error: "",
        success: false
      };
    default:
      return state;
  }
};

export default finance;
