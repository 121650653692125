import { all, put, call, takeLatest } from "redux-saga/effects";
import omit from "lodash/omit";

import profile from "../../api/profile";
import * as types from "./profileActionsTypes";
import * as generalTypes from "../general/generalActionsTypes";
import * as profileDetailsTypes from "../profileDetails/profileDetailsActionsTypes";
import { MODAL_TYPE_SUCCESS, MODAL_TYPE_INFO } from "../../react/constants";
import { getCurrencyBadge } from "../../helpers/common";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";

function *accountVerification ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.accountVerification, data);
    if (response.redirectUrl) {
      yield put({ type: types.ACCOUNT_VERIFICATION_REQUEST_3DS_REQUIRED, response });
    } else {
      yield put({ type: types.ACCOUNT_VERIFICATION_REQUEST_SUCCESS });
    }
  } catch (error) {
    yield put({ type: types.ACCOUNT_VERIFICATION_REQUEST_ERROR, error: error.message, data: error.data });
    yield put({ type: types.PROFILE_PERMISSIONS_REQUEST });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getPermissions () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.getPermissions);
    yield put({ type: types.PROFILE_PERMISSIONS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.PROFILE_PERMISSIONS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *kycVerification ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(profile.updateUser, omit(data, ["documentType", "document"]));

    const formData = new FormData();
    formData.append("documentType", data.documentType);

    for (const file of data.files) {
      formData.append("document[]", file);
    }

    yield call(profile.verifyKYC, formData);
    yield put({ type: types.KYC_VERIFICATION_REQUEST_SUCCESS });
    yield put({ type: types.PROFILE_PERMISSIONS_REQUEST });
  } catch (error) {
    yield put({ type: types.KYC_VERIFICATION_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *changePassword ({ data }) {
  yield put({ type: types.CHANGE_PASSWORD_REQUEST_PROCESSING });

  try {
    yield call(profile.changePassword, data);
    yield put({ type: types.CHANGE_PASSWORD_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CHANGE_PASSWORD_REQUEST_ERROR, error: error.message });
  }
}

function *changeEmail ({ data }) {
  yield put({ type: types.CHANGE_EMAIL_REQUEST_PROCESSING });

  try {
    const response = yield call(profile.changeEmail, data);
    yield put({ type: profileDetailsTypes.UPDATE_PROFILE_DETAILS_REQUEST_SUCCESS, response });
    yield put({ type: types.CHANGE_EMAIL_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CHANGE_EMAIL_REQUEST_ERROR, error: error.message, data: error.data });
  }
}

function *closeAccount ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(profile.closeAccount, data);
    yield put({ type: types.CLOSE_ACCOUNT_REQUEST_SUCCESS });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        className: "modal--close-account",
        type: MODAL_TYPE_INFO,
        width: 450,
        title: `<div>
          You have Now Terminated Your User Account with us.<br/>
          We have confirmed this in an email to you.<br/>
          We are sorry to see you go.
        </div>`,
        params: {
          buttonText: "OK"
        }
      }
    });
  } catch (error) {
    if (error.data && error.data.reason) {
      yield put({
        type: generalTypes.SHOW_MODAL,
        data: {
          width: 550,
          type: MODAL_TYPE_INFO,
          title: `<div>
            You have requested us to terminate your account, but we are unable to do this while you have active
            listing or funds held in your wallet. Once you deal with these issues we will be able to terminate your
            account if you still wish to do this. If you need assistance contact our customer service department.
            We will be sorry to see you go. 
          </div>`,
          params: {
            buttonText: "OK"
          }
        }
      });
    } else {
      yield put({ type: types.CLOSE_ACCOUNT_REQUEST_ERROR, error: error.message });
    }
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *createBankAccount ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(profile.createBankAccount, data);
    yield put({ type: types.CREATE_BANK_ACCOUNT_REQUEST_SUCCESS });
    yield put({ type: types.GET_BANK_ACCOUNTS_REQUEST });
  } catch (error) {
    yield put({ type: types.CREATE_BANK_ACCOUNT_REQUEST_ERROR, error: error.message, errors: error.data.errors });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getBankAccounts () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.getBankAccounts);
    yield put({ type: types.GET_BANK_ACCOUNTS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_BANK_ACCOUNTS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *deleteBankAccount ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(profile.deleteBankAccount, data);
    yield put({ type: types.DELETE_BANK_ACCOUNT_REQUEST_SUCCESS });
    yield put({ type: types.GET_BANK_ACCOUNTS_REQUEST });
  } catch (error) {
    yield put({ type: types.DELETE_BANK_ACCOUNT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateBankAccount ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(profile.updateBankAccount, data);
    yield put({ type: types.UPDATE_BANK_ACCOUNT_REQUEST_SUCCESS });
    yield put({ type: types.GET_BANK_ACCOUNTS_REQUEST });
  } catch (error) {
    yield put({ type: types.UPDATE_BANK_ACCOUNT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getBalance () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.getBalance);
    yield put({ type: types.GET_BALANCE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_BALANCE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *withdrawMoney ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.withdrawMoney);
    yield put({ type: types.WITHDRAW_MONEY_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        width: 450,
        title: `${getCurrencyBadge(data.currency)} ${data.withdrawn} was withdrawn to your bank account. Cleared funds into your bank account are dependent on your banks policy.`
      }
    });
    yield put({ type: types.GET_TRANSACTIONS_REQUEST });
  } catch (error) {
    yield put({ type: types.WITHDRAW_MONEY_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getTransactions ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.getTransactions, data);
    yield put({ type: types.GET_TRANSACTIONS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_TRANSACTIONS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *createAccountVerificationPaymentIntent ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.createAccountVerificationIntent, data);
    yield put({ type: types.CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ACCOUNT_VERIFICATION_REQUEST, accountVerification),
    yield takeLatest(types.PROFILE_PERMISSIONS_REQUEST, serverSagaWrapper(getPermissions)),
    yield takeLatest(types.KYC_VERIFICATION_REQUEST, kycVerification),
    yield takeLatest(types.CHANGE_PASSWORD_REQUEST, changePassword),
    yield takeLatest(types.CHANGE_EMAIL_REQUEST, changeEmail),
    yield takeLatest(types.CLOSE_ACCOUNT_REQUEST, closeAccount),
    yield takeLatest(types.CREATE_BANK_ACCOUNT_REQUEST, createBankAccount),
    yield takeLatest(types.GET_BANK_ACCOUNTS_REQUEST, getBankAccounts),
    yield takeLatest(types.DELETE_BANK_ACCOUNT_REQUEST, deleteBankAccount),
    yield takeLatest(types.UPDATE_BANK_ACCOUNT_REQUEST, updateBankAccount),
    yield takeLatest(types.GET_BALANCE_REQUEST, getBalance),
    yield takeLatest(types.WITHDRAW_MONEY_REQUEST, withdrawMoney),
    yield takeLatest(types.GET_TRANSACTIONS_REQUEST, getTransactions),
    yield takeLatest(types.CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST, createAccountVerificationPaymentIntent)
  ])
}
