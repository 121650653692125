/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Button } from "antd";
import { useForm } from "react-hook-form";
import InputWithLabel from "../../shared/InputWithLabel";
import * as yup from "yup";
import {
  reservationSearchRequest,
  reservationModifyRequest
} from "../../../redux/admin/reservations/reservationsActions";

const ReservationSearchSchema = yup.object().shape({});
const ReservationModifySchema = yup.object().shape({
  providerLocatorCode: yup.string().trim().max(255).required("Provider Locator Code can not be empty"),
  bookingFirstName: yup.string().trim().max(255).required("First Name can not be empty"),
  bookingLastName: yup.string().trim().max(255).required("Last Name can not be empty"),
});

export const Reservations = ({
  isProcessing,
  error,
  requestSuccess,
  searchResult,
  modifyResult,
  searchReservation,
  modifyReservation

}) => {

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: ReservationSearchSchema,
  });
  const {
    handleSubmit: handleModifySubmit,
    control: controlModify,
    errors: errorsModify,
    reset: resetModify } = useForm({
    validationSchema: ReservationModifySchema,
  });
  
  const sendSearchRequest = (formData) => {
    searchReservation(formData)
  }
  const sendModifyRequest = (formData) => {
    modifyReservation(formData)
  }

  const submitHandler = (formData) => {
    sendSearchRequest({
      ...formData
    });
  };
  const submitModifyHandler = (formData) => {
    sendModifyRequest({
      ...formData
    });
  };
 
  return (
    <Row className="admin-reservations">
      <Col span={24}>
        { error && !isProcessing &&
          <div className="admin-reservation__errors">
            { error }
          </div>
        }
        <Row className="admin-reservation-search">
          <Col span={24}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <Row className="admin-section">
                <Col span={24}>
                  <Row>
                    <Col span={24} className="admin-reservation-search__title">
                    Hotel Reservation Search
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-reservation-search__row">
                    <Col span={12}>
                      <InputWithLabel
                        name="bookingCode"
                        control={control}
                        id="bookingCode"
                        label="Booking Code"
                        error={errors.bookingCode}
                        placeholder="Hotel Booking Code"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-reservation-search__row">
                    <Col span={12}>
                      <InputWithLabel
                        name="hotelCode"
                        control={control}
                        id="hotelCode"
                        label="Hotel Code"
                        error={errors.hotelCode}
                        placeholder="Hotel Code"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-reservation-search__row">
                    <Col span={12}>
                      <InputWithLabel
                        name="hotelChainCode"
                        control={control}
                        id="hotelChainCode"
                        label="Hotel Chain Code"
                        error={errors.hotelChainCode}
                        placeholder="Hotel Chain Code"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="admin-reservation-search__submit">
                  <Button htmlType="submit" disabled={isProcessing} className="button button--primary">Search</Button>
                </Col>
              </Row>
            </form>
            { searchResult &&
              <div className="admin-reservation-search__result">
                { JSON.stringify(searchResult, null, 2) }
              </div>
            }
          </Col>
        </Row>

        <Row className="admin-reservation-modify">
          <Col span={24}>
            <form onSubmit={handleModifySubmit(submitModifyHandler)}>
              <Row className="admin-section">
                <Col span={24}>
                  <Row>
                    <Col span={24} className="admin-reservation-modify__title">
                    Hotel Reservation Modify
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-reservation-modify__row">
                    <Col span={12}>
                      <InputWithLabel
                        name="providerLocatorCode"
                        control={controlModify}
                        id="providerLocatorCode"
                        label="Provider Locator Code"
                        error={errorsModify.providerLocatorCode}
                        placeholder="Provider Locator Code"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-reservation-modify__row">
                    <Col span={12}>
                      <InputWithLabel
                        name="bookingFirstName"
                        control={controlModify}
                        id="bookingFirstName"
                        label="Booking First Name"
                        error={errorsModify.bookingFirstName}
                        placeholder="Booking First Name"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-reservation-modify__row">
                    <Col span={12}>
                      <InputWithLabel
                        name="bookingLastName"
                        control={controlModify}
                        id="bookingLastName"
                        label="Booking Last Name"
                        error={errorsModify.bookingLastName}
                        placeholder="Booking Last Name"
                        defaultValue=""
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="admin-reservation-modify__submit">
                  <Button htmlType="submit" disabled={isProcessing} className="button button--primary">Modify</Button>
                </Col>
              </Row>
            </form>
            { modifyResult &&
              <div className="admin-reservation-modify__result">
                { JSON.stringify(modifyResult, null, 2) }
              </div>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

Reservations.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  searchResult: PropTypes.object.isRequired,
  modifyResult: PropTypes.object.isRequired,
  searchReservation: PropTypes.func.isRequired,
  modifyReservation: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isProcessing: state.admin.reservations.processing,
    error: state.admin.reservations.error,
    requestSuccess: state.admin.reservations.requestSuccess,
    searchResult: state.admin.reservations.searchResult,
    modifyResult: state.admin.reservations.modifyResult,
  }),
  {
    searchReservation: reservationSearchRequest,
    modifyReservation: reservationModifyRequest,
  }
)(Reservations);
