import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const notification = {
  async getNotificationList(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/notifications?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async readNotificationList(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/notifications/mark-as-read`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default notification;
