import React from "react";
import PropTypes from "prop-types";
import { Col, Collapse, Row } from "antd";

export const History = ({ history, title }) => {
  if (!history.length) {
    return null;
  }

  return (
    <div className="profile-history">
      <Row>
        <h2 className="form-subtitle">
          {title ? title : "History"}
        </h2>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
          >
            {history.map(historyRow => {
              return (
                <Collapse.Panel header={historyRow.date} key={historyRow.date} className="profile-history__list-item">
                  {historyRow.changes.map(item => {
                    return (
                      <Row key={item.id} align="middle" className="profile-history__list-item-field">
                        <Col span={6} className="profile-history__list-item-field-name">
                          {item.fieldName}
                        </Col>
                        <Col span={18}>
                          {item.fieldValue}
                        </Col>
                      </Row>
                    );
                  })}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

History.propTypes = {
  history: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default History;
