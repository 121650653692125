import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CoverLink = ({ children, to, ...props }) => {
  return (
    <div className="cover-link-container">
      {children}
      <Link className="cover-link" to={to} {...props} />
    </div>
  );
};

CoverLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string.isRequired
};

export default CoverLink;
