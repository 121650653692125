import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Carousel, Col, Row, Rate, Typography } from "antd";
import { withRouter } from "react-router";
import parse from "html-react-parser";
import { ImageBgCover } from "./SimpleComponents/ImageBgCover/ImageBgCover";
import { splitText } from "../../helpers/text";
import { showModalAction } from "../../redux/general/generalActions";
import { MODAL_TYPE_LISTING_AMENITIES } from "../constants";
import ArrowRightIcon from "./icons/ArrowRightIcon/ArrowRightIcon"
import PageNotFound from "../PageNotFound/PageNotFound";

const carouselRef = React.createRef();
const modalCarouselRef = React.createRef();

const HotelView = ({
  hotel,
  showModal
}) => {
  const [isPreviewImageVisible, setIsPreviewImageVisible] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const onChange = (data) => {
    setImageIndex(data);
  };

  const goToImage = (index) => {
    carouselRef && carouselRef.current && carouselRef.current.goTo(index);
  };

  const goToModalImage = (index) => {
    modalCarouselRef && modalCarouselRef.current && modalCarouselRef.current.goTo(index);
  };

  if (!hotel) {
    return <PageNotFound/>;
  }

  const imagesMini = hotel && hotel.HotelImages && hotel.HotelImages.length
    ? showAllImages ? hotel.HotelImages : hotel.HotelImages.slice(0, 4)
    : [];
  const hotelAddress = hotel.HotelAddress ? hotel.HotelAddress : {};

  // eslint-disable-next-line no-unused-vars, react/prop-types
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );

  return (
    <Row justify="space-around" align="middle" className="hotel-view">
      <Col xs={{ span: 24 }}>
        <Row className="hotel-view__hotel-name-container">
          <Col span={24}>
            <Row className="hotel-view__hotel-name">
              <Col span={24}>
                <Typography.Title>{hotel.name}</Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 13 }}>
                <Row gutter={32} justify="space-between" align="middle">
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: hotel.hotelRating ? 12 : 24 }}
                    lg={{ span: hotel.hotelRating ? 14 : 24 }}
                  >
                    {hotelAddress.countryName}, {hotelAddress.cityName}
                  </Col>
                  {
                    !!hotel.hotelRating &&
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 10 }}
                      className="hotel-view__rating"
                    >
                      <Rate
                        key={`hotel-rate-${hotel.id}`}
                        disabled
                        count={hotel.hotelRating}
                        defaultValue={hotel.hotelRating}
                      />
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={ 8}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Row gutter={{ xs: 0, md: 32, xl: 80 }} align="start">
              <Col xs={{ span: 24 }}>
                {
                  hotel && hotel.HotelImages && hotel.HotelImages.length
                    ? <React.Fragment>
                      <Row className="hotel-view__hotel-images">
                        <Carousel
                          afterChange={onChange}
                          arrows={true}
                          dots={false}
                          ref={carouselRef}
                          nextArrow={<SlickButtonFix><ArrowRightIcon/></SlickButtonFix>}
                          prevArrow={<SlickButtonFix><ArrowRightIcon/></SlickButtonFix>}
                        >
                          {
                            hotel.HotelImages.map(image => (
                              <div key={`hotel-view-image-${ image.id}`} className="hotel-view__hotel-images-item" onClick={() => {
                                if (!isPreviewImageVisible) {
                                  setIsPreviewImageVisible(true);
                                  goToModalImage(imageIndex);
                                }
                              }}>
                                <ImageBgCover url={image.imageUrl} height={56} />
                              </div>
                            ))
                          }
                        </Carousel>
                        <div
                          className={`ant-modal-mask hotel-view__carousel-modal${isPreviewImageVisible ? " visible" : ""}`}
                          onClick={evt => {
                            if (!evt.target.className.baseVal && evt.target.className.startsWith("ant-modal-mask")) {
                              setIsPreviewImageVisible(false);
                            }
                          }}
                        >
                          <Carousel
                            arrows={true}
                            ref={modalCarouselRef}
                            className="modal-inner"
                            nextArrow={<SlickButtonFix><ArrowRightIcon/></SlickButtonFix>}
                            prevArrow={<SlickButtonFix><ArrowRightIcon/></SlickButtonFix>}
                          >
                            {
                              hotel.HotelImages.map(image => (
                                <div key={`hotel-view-image-${ image.id}`} className="hotel-view__hotel-images-item">
                                  <ImageBgCover url={image.imageUrl} height={56} />
                                </div>
                              ))
                            }
                          </Carousel>
                          <div className="hotel-view__carousel-modal-close" onClick={() => setIsPreviewImageVisible(false)}>
                            <img src="/images/close-white.svg" alt="close" />
                          </div>
                        </div>
                      </Row>
                      <Row className="hotel-view__hotel-images-mini" justify="start">
                        {
                          imagesMini.map((image, index) => (
                            <Col span={6}
                              key={`hotel-view-image-${image.id}-${index}`}
                              className={index === imageIndex ? "active" : ""}
                              onClick={() => goToImage(index)}
                            >
                              <ImageBgCover url={image.imageUrl} height={63} />
                              {
                                !showAllImages && index === 3 &&
                            <div
                              className="hotel-view__hotel-images-mini-button"
                              onClick={(event) => {
                                event.stopPropagation();
                                setShowAllImages(true);
                              }}
                            >
                              All photos
                            </div>
                              }
                            </Col>
                          ))
                        }
                      </Row>
                    </React.Fragment>
                    : <ImageBgCover url={""} height={56} iconSize="big" className="hotel-view__hotel-images-empty" />
                }
                <Row className="hotel-view__hotel-description">
                  <Col span={24}>
                    <Row>
                      <Col span={24} className="hotel-view__hotel-description-title">
                    Description
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {hotel.source === "travelport" ? parse(splitText(hotel.description)) : parse(hotel.description || "")}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="hotel-view__hotel-description mobile-view">
              <Col span={24}>
                <Row>
                  <Col span={24} className="hotel-view__hotel-description-title">
                Description
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {hotel.source === "travelport" ? parse(splitText(hotel.description)) : parse(hotel.description || "")}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            {
              hotel.topFacilities && hotel.topFacilities.length
                ? <Row className="mobile-padding hotel-view__facilities-wrapper">
                  <div className="form-container hotel-view__facilities">
                    <div className="hotel-view__facilities-container">
                      <div className="hotel-view__facilities-label-container">
                        <div className="hotel-view__facilities-label">Amenities</div>
                        <div
                          className="hotel-view__facilities-see-all"
                          onClick={() => showModal({
                            width: "900px",
                            type: MODAL_TYPE_LISTING_AMENITIES,
                            params: {
                              amenities: hotel.facilities
                            }
                          })}
                        >
                        See all <img src="/images/arrow-circle-icon.svg" alt="See all"/>
                        </div>
                      </div>
                      <div className="hotel__field-facilities">
                        {
                          hotel.topFacilities.map((item, index) => (
                            <div className="hotel__field-facilities-item" key={`hotel-facilities-${item}-${index}`}>
                              <img
                                className="hotel-view__facilities-icon"
                                src={`/images/icons/${item.toLowerCase().replace(/\W/g, "-")}.svg`}
                                alt={item}
                              />
                              <span>{item}</span>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </Row>
                : null
            }
            { hotel.topFacilities && !!hotel.topFacilities.length &&
                <Row className="hotel-view__facilities-wrapper-mobile">
                  <div className="form-container hotel-view__facilities mobile-view">
                    <div className="hotel-view__facilities-container">
                      <div className="hotel-view__facilities-label-container">
                        <div className="hotel-view__facilities-label">Amenities</div>
                        <div
                          className="hotel-view__facilities-see-all"
                          onClick={() => showModal({
                            width: "900px",
                            type: MODAL_TYPE_LISTING_AMENITIES,
                            params: {
                              amenities: hotel.facilities
                            }
                          })}
                        >
                            See all <img src="/images/arrow-circle-icon.svg" alt="See all"/>
                        </div>
                      </div>
                      <div className="hotel__field-facilities">
                        {
                          hotel.topFacilities.map((item, index) => (
                            <div className="hotel__field-facilities-item" key={`hotel-facilities-${item}-${index}`}>
                              <img
                                className="hotel-view__facilities-icon"
                                src={`/images/icons/${item.toLowerCase().replace(/\W/g, "-")}.svg`}
                                alt={item}
                              />
                              <span>{item}</span>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </Row>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

HotelView.propTypes = {
  hotel: PropTypes.object.isRequired,
  error: PropTypes.string,
  showModal: PropTypes.func.isRequired
};

export default withRouter(connect(
  state => ({
    user: state.auth.user,
    isProcessing: state.listing.processing,
    error: state.listing.error
  }),
  {
    showModal: showModalAction,
  }
)(HotelView));
