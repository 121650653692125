import React from "react";
import { getCurrencyBadge } from "../../../helpers/common";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export const WalletPending = ({ currency, balance }) => {
  return (
    <div className="form-container">
      <div className="wallet-pending">
        <div className="wallet-pending__item">
          <div>
            <h4 className="form-subtitle wallet-pending__title">Pending</h4>
            <span className="wallet-pending__subtext">Funds Pending</span>
          </div>
        </div>
        <div className="wallet-pending__item">
          <span className="wallet-pending__balance">{getCurrencyBadge(currency)}{(+balance.pending).toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

WalletPending.propTypes = {
  currency: PropTypes.string.isRequired,
  balance: PropTypes.object.isRequired,
  kycStatus: PropTypes.string.isRequired
};

export default connect(
  state => ({
    currency: state.profile.permissions.currency,
    balance: state.profile.balance,
    kycStatus: state.profile.permissions.kycStatus
  })
)(WalletPending);

