export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_LISTINGS_REQUEST = "ADMIN_GET_LISTINGS_REQUEST";
export const ADMIN_GET_LISTINGS_REQUEST_SUCCESS = "ADMIN_GET_LISTINGS_REQUEST_SUCCESS";
export const ADMIN_GET_LISTINGS_REQUEST_ERROR = "ADMIN_GET_LISTINGS_REQUEST_ERROR";

export const ADMIN_UPDATE_LISTING_REQUEST = "ADMIN_UPDATE_LISTING_REQUEST";
export const ADMIN_UPDATE_LISTING_REQUEST_SUCCESS = "ADMIN_UPDATE_LISTING_REQUEST_SUCCESS";
export const ADMIN_UPDATE_LISTING_REQUEST_ERROR = "ADMIN_UPDATE_LISTING_REQUEST_ERROR";

export const ADMIN_GET_CSV_EXPORT_URL_REQUEST = "ADMIN_GET_CSV_EXPORT_URL_REQUEST";

export const ADMIN_CLEAR_LISTING_ERROR = "ADMIN_CLEAR_LISTING_ERROR";
