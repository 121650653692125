import React from "react";
import PropTypes from "prop-types";

const getStatusIcon = (status) => {
  switch (status) {
    case "suspended":
    case "dispute":
      return "/images/icons/cancel-circle-icon.svg";
    default:
      return null;
  }
};

const HotelCardStatus = ({ status }) => {
  const statusClassName = status ? `hotel-card-status--${status}` : "";
  const statusIcon = getStatusIcon(status);

  return (
    <div className={`hotel-card-status ${statusClassName}`}>
      {statusIcon && <img className="hotel-card-status__icon" alt="status icon" src={statusIcon}/>}
      <span className="hotel-card-status__status-text">{status}</span>
    </div>
  );
};

HotelCardStatus.propTypes = {
  status: PropTypes.string.isRequired
};

export default HotelCardStatus;
