import * as types from "./profileDetailsActionsTypes";
import * as authTypes from "./../auth/authActionsTypes";

export const initialState = {
  profile: {
    Address: {}
  },
  history: [],
  isProfileUpdated: false,
  isProfileDetailsUpdated: false,
  isProfileAddressUpdated: false,
  isProfileDetailsUpdating: false,
  isProfileAddressUpdating: false,
  processing: false,
  error: "",
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.GET_PROFILE_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        profile: action.response.profile,
        isProfileUpdated: false,
        error: ""
      };
    case types.UPDATE_USER_REQUEST_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...action.response },
        isProfileUpdated: true
      };
    case types.UPDATE_PROFILE_DETAILS_REQUEST_PROCESSING:
      return {
        ...state,
        isProfileDetailsUpdating: true
      };
    case types.UPDATE_PROFILE_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...action.response },
        isProfileDetailsUpdated: true,
        isProfileDetailsUpdating: false
      };
    case types.UPDATE_PROFILE_ADDRESS_REQUEST_PROCESSING:
      return {
        ...state,
        isProfileAddressUpdating: true
      };
    case types.UPDATE_PROFILE_ADDRESS_REQUEST_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...action.response },
        isProfileAddressUpdated: true,
        isProfileAddressUpdating: false
      };
    case types.GET_PROFILE_HISTORY_REQUEST_SUCCESS:
      return {
        ...state,
        history: action.response,
        error: ""
      };
    case types.CREATE_TEMPORARY_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        profile: action.response,
        error: ""
      };
    case types.GET_PROFILE_DETAILS_REQUEST_ERROR:
    case types.GET_PROFILE_HISTORY_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.UPDATE_USER_REQUEST_ERROR:
      return {
        ...state,
        isProfileUpdated: false,
        error: action.error
      };
    case types.UPDATE_PROFILE_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        isProfileDetailsUpdating: false,
        error: action.error
      };
    case types.UPDATE_PROFILE_ADDRESS_REQUEST_ERROR:
      return {
        ...state,
        isProfileAddressUpdating: false,
        error: action.error
      };
    case types.CLEAR_PROFILE_DETAILS_ERROR:
      return {
        ...state,
        isProfileUpdated: false,
        error: ""
      };
    case authTypes.SIGN_OUT_REQUEST_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default profile;
