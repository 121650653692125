import * as types from "./blogActionsTypes";

export const getArticleListRequest = (data) => {
  return { type: types.BLOG_GET_ARTICLE_LIST_REQUEST, data };
};

export const updateArticleListRequest = (data) => {
  return { type: types.BLOG_UPDATE_ARTICLE_LIST_REQUEST, data };
};

export const getArticleRequest = (data, errorCallback) => {
  return { type: types.BLOG_GET_ARTICLE_REQUEST, data, errorCallback };
};

export const getBlogCategoryListRequest = (data) => {
  return { type: types.BLOG_GET_CATEGORY_LIST_REQUEST, data };
};

export const getArticleListByCategoryRequest = (data) => {
  return { type: types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST, data };
};

export const getLatestNewsRequest = (data) => {
  return { type: types.BLOG_GET_LATEST_NEWS_REQUEST, data };
};

export const getNewsUpdatesRequest = (data) => {
  return { type: types.BLOG_GET_NEWS_UPDATES_REQUEST, data };
};

export const updateArticleListByCategoryRequest = (data) => {
  return { type: types.BLOG_UPDATE_ARTICLE_LIST_BY_CATEGORY_REQUEST, data };
};

export const getRelatedArticlesRequest = (data) => {
  return { type: types.BLOG_GET_RELATED_ARTICLES_REQUEST, data };
};
