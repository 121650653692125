import React from "react";
import PropTypes from "prop-types";
import { Collapse, Row } from "antd"

const ServiceItem = ({ children, name }) => (
  <Collapse ghost bordered={false}>
    <Collapse.Panel showArrow={false} header={<Row className="profile__password">
      <div className="profile__field">
        <label htmlFor="profile-first-name" className="profile__label">{name}</label>
        <img
          src="/images/edit.svg"
          alt="edit"
          className="profile__password-image"

        />
      </div>
    </Row>} >
      {children}
    </Collapse.Panel>
  </Collapse>
);

ServiceItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  name: PropTypes.string.isRequired,
}

export default ServiceItem;
