import React from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";

import Header from "../shared/Header";
import Footer from "../shared/Footer";

function ProfileLayout({ children }) {
  return (
    <div className="user-content">
      <Header/>
      <Layout.Content>
        <div className="profile-layout">
          {children}
        </div>
      </Layout.Content>
      <Footer/>
    </div>
  );
}

ProfileLayout.propTypes = {
  children: PropTypes.any
};

export default ProfileLayout;
