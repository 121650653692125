import { useEffect } from "react";
import { useSelector } from "react-redux";
import ssrPromiseHelper from "../helpers/ssrPromiseHelper";


function useServerSafeEffect(action, dependencies) {
  useEffect(action, dependencies);

  const requestToken = useSelector(state => state.general.requestToken);
  if (!ssrPromiseHelper.bucket[requestToken] || !ssrPromiseHelper.bucket[requestToken].shouldTrack) return;

  action();
}

export default useServerSafeEffect;
