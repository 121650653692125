import React, { useEffect } from "react";
import { Button, Col, Row, Typography } from "antd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import InputWithLabel from "../shared/InputWithLabel";
import TextAreaWithLabel from "../shared/TextAreaWithLabel";
import {
  getPublicStaticPageRequest,
  postFeedbackRequest,
  resetStaticPageAction
} from "../../redux/staticPages/staticPagesActions";
import parse from "html-react-parser";

const socialIcons = [
  {
    link: process.env.REACT_APP_FACEBOOK_URL,
    img: "/images/facebook.svg",
    name: "facebook"
  },
  {
    link: process.env.REACT_APP_TWITTER_URL,
    img: "/images/twitter.svg",
    name: "twitter"
  },
  {
    link: process.env.REACT_APP_INSTAGRAM_URL,
    img: "/images/instagram.svg",
    name: "instagram"
  }
];

const ContactUsSchema = yup.object().shape({
  firstName: yup.string().required("First name can not be empty"),
  lastName: yup.string().required("Last name can not be empty"),
  email: yup.string().email("Please provide correct email").required("Email can not be empty"),
  message: yup.string().required("Message can not be empty")
});

export const ContactUs = ({
  isProcessing,
  isFeedbackPosted,
  user,
  isAuthenticated,
  postFeedback,
  reset,
  pageData,
  getStaticPage,
}) => {
  const { handleSubmit, errors, control } = useForm({
    validationSchema: ContactUsSchema
  });

  useEffect(() => {
    reset();
    getStaticPage();
  }, []);

  return (
    <Row justify="space-around" align="middle" className="contact-us">
      <Col xs={{ span: 22 }} md={{ span: 16 }}>
        <Row>
          <Col xs={{ span: 24 }} xl={{ span: 12 }} className="contact-us__title-container">
            <div className="plainHtml contact-us__title-info">
              {parse(pageData.contactUsContent || "")}
            </div>
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 24 }} xxl={{ span: 12 }}>
                <Typography.Title level={1} className="title-level-4">{pageData.contactDetailsTitle}</Typography.Title>
                <p className="contact-us__contact">{process.env.REACT_APP_SUPPORT_PHONE}</p>
                <p className="contact-us__contact">{process.env.REACT_APP_SUPPORT_EMAIL}</p>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 24 }} xxl={{ span: 12 }}>
                <Typography.Title level={1} className="title-level-4">{pageData.socialTitle}</Typography.Title>
                <ul className="contact-us__social-list">
                  {socialIcons.map(item => {
                    return (
                      <li key={item.name}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                          <img className="contact-us__social-icon" alt={item.name} src={item.img}/>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            {isFeedbackPosted &&
            <Row>
              <Col span={24} className="contact-us__success" data-testid="contact-us-success">
                <img src="/images/success.svg" alt="success" className="contact-us__success-icon"/>
                <div>
                  Thank You!<br/>
                  Your feedback has been sent.
                </div>
              </Col>
            </Row>
            }
            {!isFeedbackPosted &&
            <div className="form-container">
              <form onSubmit={handleSubmit(postFeedback)}>
                <h2 className="form-subtitle">Let us know how we can help</h2>
                <Row className="contact-us__row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }} className="contact-us__first-name">
                    <InputWithLabel
                      name="firstName"
                      control={control}
                      id="first-name"
                      label="First Name"
                      error={errors.firstName}
                      placeholder="First Name"
                      defaultValue={isAuthenticated ? user.firstName : ""}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} className="contact-us__last-name">
                    <InputWithLabel
                      name="lastName"
                      control={control}
                      id="last-name"
                      label="Last Name"
                      error={errors.lastName}
                      placeholder="Last Name"
                      defaultValue={isAuthenticated ? user.lastName : ""}
                    />
                  </Col>
                </Row>
                <Row className="contact-us__row">
                  <Col span={24}>
                    <InputWithLabel
                      name="email"
                      control={control}
                      id="email"
                      label="Your Email"
                      error={errors.email}
                      placeholder="Enter Email"
                      defaultValue={isAuthenticated ? user.email : ""}
                    />
                  </Col>
                </Row>
                <Row className="contact-us__row">
                  <Col span={24}>
                    <TextAreaWithLabel
                      className="contact-us__textarea-description"
                      name="message"
                      control={control}
                      id="message"
                      label="Share your thoughts"
                      error={errors.message}
                      placeholder="Write a message"
                      rows={1}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="contact-us__submit-btn-wrapper">
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isProcessing}
                        data-testid="contact-us-submit"
                      >
                        Share your feedback
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ContactUs.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  isFeedbackPosted: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  postFeedback: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pageData: PropTypes.any.isRequired,
  getStaticPage: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isProcessing: state.staticPage.processing,
    isFeedbackPosted: state.staticPage.isFeedbackPosted,
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    pageData: state.staticPage.page,
  }),
  {
    postFeedback: postFeedbackRequest,
    reset: resetStaticPageAction,
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "contact-us" }),
  }
)(ContactUs);
