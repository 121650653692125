import * as types from "./blogActionsTypes";

export const initialState = {
  latestNews: {
    processing: false,
    error: "",
    data: [],
  },
  newsUpdates: {
    processing: false,
    error: "",
    data: [],
  },
  articleList: {
    processing: false,
    error: "",
    data: [],
  },
  articleListByCategory: {
    processing: false,
    error: "",
    data: [],
  },
  categories: {
    processing: false,
    error: "",
    data: [],
  },
  singleArticle: {
    processing: false,
    error: "",
    body: "",
    categories: [],
    coverImageUrl: "",
    title: "",
    shortDescription: "",
    relatedArticles: []
  }
};

const blog = (state = initialState, action) => {
  switch (action.type) {
    case types.BLOG_GET_ARTICLE_LIST_REQUEST_PROCESSING:
      return {
        ...state,
        articleList: {
          ...state.articleList,
          processing: true,
        }
      };
    case types.BLOG_UPDATE_ARTICLE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        articleList: {
          ...action.response,
          data: [...state.articleList.data, ...action.response.data],
          processing: false,
          error: ""
        }
      };
    case types.BLOG_GET_ARTICLE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        articleList: {
          ...action.response,
          processing: false,
          error: ""
        }
      };
    case types.BLOG_GET_ARTICLE_LIST_REQUEST_ERROR:
      return {
        ...state,
        articleList: {
          ...state.articleList,
          processing: false,
          error: action.error
        }
      };

    case types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_PROCESSING:
      return {
        ...state,
        articleListByCategory: {
          ...state.articleListByCategory,
          processing: true,
        }
      };
    case types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        articleListByCategory: {
          ...action.response,
          processing: false,
          error: ""
        }
      };
    case types.BLOG_UPDATE_ARTICLE_LIST_BY_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        articleListByCategory: {
          ...action.response,
          data: [...state.articleListByCategory.data, ...action.response.data],
          processing: false,
          error: ""
        }
      };
    case types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_ERROR:
      return {
        ...state,
        articleListByCategory: {
          ...state.articleListByCategory,
          processing: false,
          error: action.error
        }
      };

    case types.BLOG_GET_ARTICLE_REQUEST_PROCESSING:
      return {
        ...state,
        singleArticle: {
          ...state.singleArticle,
          processing: true,
        }
      };
    case types.BLOG_GET_ARTICLE_REQUEST_SUCCESS:
      return {
        ...state,
        singleArticle: {
          ...action.response,
          processing: false,
          error: ""
        }
      };
    case types.BLOG_GET_ARTICLE_REQUEST_ERROR:
      return {
        ...state,
        singleArticle: {
          ...state.singleArticle,
          processing: false,
          error: action.error
        }
      };

    case types.BLOG_GET_CATEGORY_LIST_REQUEST_PROCESSING:
      return {
        ...state,
        categories: {
          ...state.categories,
          processing: true,
        }
      };

    case types.BLOG_GET_CATEGORY_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        categories: {
          ...action.response,
          processing: false,
          error: ""
        }
      };
    case types.BLOG_GET_CATEGORY_LIST_REQUEST_ERROR:
      return {
        ...state,
        categories: {
          ...state.categories,
          processing: false,
          error: action.error
        }
      };
    case types.BLOG_GET_NEWS_UPDATES_REQUEST_PROCESSING:
      return {
        ...state,
        newsUpdates: {
          ...state.newsUpdates,
          processing: true,
        }
      };
    case types.BLOG_GET_NEWS_UPDATES_REQUEST_SUCCESS:
      return {
        ...state,
        newsUpdates: {
          ...action.response,
          processing: false,
          error: ""
        }
      };
    case types.BLOG_GET_NEWS_UPDATES_REQUEST_ERROR:
      return {
        ...state,
        newsUpdates: {
          ...state.newsUpdates,
          processing: false,
          error: action.error
        }
      };
    case types.BLOG_GET_LATEST_NEWS_REQUEST_PROCESSING:
      return {
        ...state,
        latestNews: {
          ...state.latestNews,
          processing: true,
        }
      };
    case types.BLOG_GET_LATEST_NEWS_REQUEST_SUCCESS:
      return {
        ...state,
        latestNews: {
          ...action.response,
          processing: false,
          error: ""
        }
      };
    case types.BLOG_GET_LATEST_NEWS_REQUEST_ERROR:
      return {
        ...state,
        latestNews: {
          ...state.latestNews,
          processing: false,
          error: action.error
        }
      };
    case types.BLOG_GET_RELATED_ARTICLES_REQUEST_PROCESSING:
      return {
        ...state,
        singleArticle: {
          ...state.singleArticle,
          processing: true,
        }
      };
    case types.BLOG_GET_RELATED_ARTICLES_REQUEST_SUCCESS:
      return {
        ...state,
        singleArticle: {
          ...state.singleArticle,
          relatedArticles: action.response.data,
          processing: false,
          error: ""
        }
      };
    case types.BLOG_GET_RELATED_ARTICLES_REQUEST_ERROR:
      return {
        ...state,
        singleArticle: {
          ...state.singleArticle,
          processing: false,
          error: action.error
        }
      };
    case types.BLOG_CLEAR_ARTICLE_ERROR:
      return {
        latestNews: {
          ...state.latestNews,
          error: "",
        },
        newsUpdates: {
          ...state.newsUpdates,
          error: "",
        },
        articleList: {
          ...state.articleList,
          error: "",
        },
        categories: {
          ...state.categories,
          error: "",
        },
        singleArticle: {
          ...state.singleArticle,
          error: "",
        }
      };

    default:
      return state;
  }
};

export default blog;
