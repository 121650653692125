const testHtmlLength = value => {
  let text = "";
  if (value) {
    text = value.replace(/(<([^>]+)>)/ig, "");
  }

  return !!text.length;
};

export default testHtmlLength;
