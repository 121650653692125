import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";

import ArrowIcon from "../shared/icons/ArrowIcon/ArrowIcon";
import PreventCopyWrapper from "../shared/PreventCopyWrapper"

const RenderExpendIcon = ({ isActive }) => {

  const iconOpenClass = isActive ? "faq-accordion__arrow-icon--open" : "";

  return (
    <span className="faq-accordion__arrow-icon-wrapper">
      <ArrowIcon className={`faq-accordion__arrow-icon ${iconOpenClass}`} alt="arrow" src="/images/arrow-icon.svg"/>
    </span>
  );
};

RenderExpendIcon.propTypes = {
  isActive: PropTypes.bool
}


const FaqAccordion = ({ question, answer, defaultOpen = true }) => {
  const defaultActiveKey = defaultOpen ? "1" : "";

  return (
    <PreventCopyWrapper>
      <Collapse
        defaultActiveKey={defaultActiveKey}
        className="faq-accordion"
        expandIcon={RenderExpendIcon}
        expandIconPosition="right"
      >
        <Collapse.Panel key="1" header={question}>
          <div className="faq-accordion__content">{answer}</div>
        </Collapse.Panel>
      </Collapse>
    </PreventCopyWrapper>
  );
};

FaqAccordion.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.any.isRequired,
  defaultOpen: PropTypes.bool
};

export default FaqAccordion;
