import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as yup from "yup";

import RichTextEditor from "../../shared/RichTextEditor/RichTextEditor";
import { getStaticPageRequest, updateStaticPageRequest } from "../../../redux/admin/staticPages/staticPagesActions";
import { SUPER_ADMIN_ROLE } from "../../constants";
import PageNotFound from "../../PageNotFound/PageNotFound";

const TermsAndConditionsSchema = yup.object().shape({
  content: yup.string().trim()
    .test({
      name: "contentLength",
      message: "Content can not be empty",
      test: value => {
        let text = "";
        if (value) {
          text = value.replace(/(<([^>]+)>)/ig, "");
        }

        return !!text.length;
      }
    })
});

const TermsAndConditions = ({ getPage, updatePage, isProcessing, location, page, userRole }) => {
  if (userRole && userRole !== SUPER_ADMIN_ROLE) {
    return <PageNotFound/>;
  }

  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  const { handleSubmit, control, reset, errors } = useForm({
    validationSchema: TermsAndConditionsSchema
  });

  useEffect(() => {
    getPage({ permalink: active });
  }, []);

  useEffect(() => {
    reset({
      content: page.content || ""
    });
  }, [page]);

  const submitHandler = (data) => {
    updatePage({
      ...data,
      permalink: active
    });
  };

  return (
    <div className="admin-terms-and-conditions" key="terms-and-conditions">
      <form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Col span={24}>
            <Controller
              name="content"
              as={<RichTextEditor
                value={page.content}
              />}
              defaultValue={page.content}
              control={control}
              onChange={([value]) => value }
            />
            { errors.content &&
              <span className="admin-terms-and-conditions__error">{errors.content.message}</span>
            }
          </Col>
        </Row>
        <Row>
          <Col span={24} className=" admin-terms-and-conditions__submit">
            <Button htmlType="submit" disabled={isProcessing} className="button button--primary">Save</Button>
          </Col>
        </Row>
      </form>
    </div>
  )
};

TermsAndConditions.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  getPage: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.staticPage.processing,
    error: state.admin.staticPage.error,
    page: state.admin.staticPage.page,
    userRole: state.profile.permissions.role
  }),
  {
    getPage: getStaticPageRequest,
    updatePage: updateStaticPageRequest
  }
)(TermsAndConditions);

