import React from "react";
import PropTypes from "prop-types";
import { Row } from "antd"

const DisputeView = ({ labelSeller }) => {
  return (
    <Row className="seller-listings__item-dispute">
      <img src="/images/info.svg" alt="info-icon" />
      <span>
        {labelSeller}
      </span>
    </Row>
  )
}

DisputeView.propTypes = {
  labelSeller: PropTypes.string.isRequired
}

export default DisputeView;
