import moment from "moment";

import { stripTags } from "./common";

export const getOrganizationSchemaScriptContent = () => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "PlansChange",
    "url": `${process.env.REACT_APP_URL}/`,
    "logo": `${process.env.REACT_APP_URL}/images/logo.svg`,
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "Customer Support",
      "email": process.env.REACT_APP_SUPPORT_EMAIL
    },
    "sameAs": [
      process.env.REACT_APP_TWITTER_URL,
      process.env.REACT_APP_FACEBOOK_URL,
      process.env.REACT_APP_INSTAGRAM_URL
    ]
  });
}

export const getFaqSchemaScriptContent = (questions) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": questions.map(question => {
      return {
        "@type": "Question",
        "name": question.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": stripTags(question.content)
        }
      };
    })
  });
}

export const getBlogArticleSchemaScriptContent = (article) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": article.title,
    "image": article.coverImageUrl,
    "publisher": {
      "@type": "Organization",
      "name": "PlansChange",
      "logo": {
        "@type": "ImageObject",
        "url": `${process.env.REACT_APP_URL}/images/logo.svg`
      }
    },
    "url": `${process.env.REACT_APP_URL}/blog/articles/${article.slug}`,
    "datePublished": moment(article.publishDate).format("YYYY-MM-DD"),
    "dateCreated": moment(article.createdAt).format("YYYY-MM-DD"),
    "dateModified": moment(article.updatedAt).format("YYYY-MM-DD"),
    "description": article.shortDescription,
    "articleBody": stripTags(article.body)
  });
}

export const getBlogPageSchemaScriptContent = () => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Blog",
    "description": "Looking for inspiration and the top travel tips? The PlansChange blog is full of travel news, the top things to see and do and where to stay. Browse our latest blogs.",
    "publisher": {
      "@type": "Organization",
      "name": "PlansChange",
      "logo": {
        "@type": "ImageObject",
        "url": `${process.env.REACT_APP_URL}/images/logo.svg`
      }
    },
    "url": `${process.env.REACT_APP_URL}/blog`
  });
}

export const getAboutUsPageSchemaScriptContent = () => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "name": "About Us",
    "description": "PlansChange is a hotel booking site like no other. Created with you in mind! No more confusion when it comes to hotel bookings, complete transparency. Plans change, we get it.",
    "publisher": {
      "@type": "Organization",
      "name": "PlansChange",
      "logo": {
        "@type": "ImageObject",
        "url": `${process.env.REACT_APP_URL}/images/logo.svg`
      }
    },
    "url": `${process.env.REACT_APP_URL}/about-us`
  });
}

export const getContactUsPageSchemaScriptContent = () => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contact Us",
    "description": "Get in touch with us with ease. Our customers are important to us, contact us via email, calling us or our social channels and our dedicated team will get back to you.",
    "publisher": {
      "@type": "Organization",
      "name": "PlansChange",
      "logo": {
        "@type": "ImageObject",
        "url": `${process.env.REACT_APP_URL}/images/logo.svg`
      }
    },
    "url": `${process.env.REACT_APP_URL}/contact-us`
  });
}

export const getHotelSchemaScriptContent = (listing) => {
  const hotel = listing.Hotel;

  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Hotel",
    "name": hotel.name,
    ...(hotel.HotelImages && !!hotel.HotelImages.length ? {
      "image": hotel.HotelImages[0].imageUrl
    } : {}),
    ...(hotel.phoneNumbers && !!hotel.phoneNumbers.length ? {
      "telephone": hotel.phoneNumbers[0],
    } : {}),
    ...(hotel.emails && !!hotel.emails.length ? {
      "email": hotel.emails[0],
    } : {}),
    "address": {
      "@type": "PostalAddress",
      "streetAddress": hotel.HotelAddress.addressLine,
      "addressLocality": hotel.HotelAddress.cityName,
      ...(hotel.HotelAddress.state ? {
        "addressRegion": hotel.HotelAddress.state,
      } : {}),
      "postalCode": hotel.HotelAddress.postalCode,
      "addressCountry": hotel.HotelAddress.countryName
    },
    "starRating": {
      "@type": "https://schema.org/Rating",
      "ratingValue": listing.hotelRating
    },
    ...(hotel.topFacilities && hotel.topFacilities.length ? {
      "amenityFeature": hotel.topFacilities.map(facility => {
        return {
          "@type": "LocationFeatureSpecification", "name": facility
        };
      })
    } : {})
  });
}
