export const LISTING_SHOW_LOADING = "LISTING_SHOW_LOADING";
export const LISTING_HIDE_LOADING = "LISTING_HIDE_LOADING";

export const GET_HOTEL_LIST_REQUEST = "GET_HOTEL_LIST_REQUEST";
export const GET_HOTEL_LIST_REQUEST_SUCCESS = "GET_HOTEL_LIST_REQUEST_SUCCESS";
export const GET_HOTEL_LIST_REQUEST_ERROR = "GET_HOTEL_LIST_REQUEST_ERROR";

export const GET_HOTEL_REQUEST = "GET_HOTEL_REQUEST";
export const GET_HOTEL_REQUEST_SUCCESS = "GET_HOTEL_REQUEST_SUCCESS";
export const GET_HOTEL_REQUEST_ERROR = "GET_HOTEL_REQUEST_ERROR";

export const CREATE_LISTING_REQUEST = "CREATE_LISTING_REQUEST";
export const CREATE_LISTING_REQUEST_SUCCESS = "CREATE_LISTING_REQUEST_SUCCESS";
export const CREATE_LISTING_REQUEST_ERROR = "CREATE_LISTING_REQUEST_ERROR";

export const SEND_LISTING_TO_APPROVAL_REQUEST = "SEND_LISTING_TO_APPROVAL_REQUEST";
export const SEND_LISTING_TO_APPROVAL_REQUEST_SUCCESS = "SEND_LISTING_TO_APPROVAL_REQUEST_SUCCESS";
export const SEND_LISTING_TO_APPROVAL_REQUEST_ERROR = "SEND_LISTING_TO_APPROVAL_REQUEST_ERROR";

export const GET_LISTING_REQUEST = "GET_LISTING_REQUEST";
export const GET_LISTING_REQUEST_SUCCESS = "GET_LISTING_REQUEST_SUCCESS";
export const GET_LISTING_REQUEST_ERROR = "GET_LISTING_REQUEST_ERROR";

export const UPDATE_LISTING_REQUEST = "UPDATE_LISTING_REQUEST";
export const UPDATE_LISTING_REQUEST_SUCCESS = "UPDATE_LISTING_REQUEST_SUCCESS";
export const UPDATE_LISTING_REQUEST_ERROR = "UPDATE_LISTING_REQUEST_ERROR";

export const DELETE_LISTING_REQUEST = "DELETE_LISTING_REQUEST";
export const DELETE_LISTING_REQUEST_SUCCESS = "DELETE_LISTING_REQUEST_SUCCESS";
export const DELETE_LISTING_REQUEST_ERROR = "DELETE_LISTING_REQUEST_ERROR";

export const GET_LISTING_LIST_REQUEST = "GET_LISTING_LIST_REQUEST";
export const GET_LISTING_LIST_REQUEST_SUCCESS = "GET_LISTING_LIST_REQUEST_SUCCESS";
export const GET_LISTING_LIST_REQUEST_ERROR = "GET_LISTING_LIST_REQUEST_ERROR";

export const GET_MY_PURCHASES_LIST_REQUEST = "GET_MY_PURCHASES_LIST_REQUEST";
export const GET_MY_PURCHASES_LIST_REQUEST_SUCCESS = "GET_MY_PURCHASES_LIST_REQUEST_SUCCESS";
export const GET_MY_PURCHASES_LIST_REQUEST_ERROR = "GET_MY_PURCHASES_LIST_REQUEST_ERROR";

export const GET_MY_LISTINGS_REQUEST = "GET_MY_LISTINGS_REQUEST";
export const GET_MY_LISTINGS_REQUEST_SUCCESS = "GET_MY_LISTINGS_REQUEST_SUCCESS";
export const GET_MY_LISTINGS_REQUEST_ERROR = "GET_MY_LISTINGS_REQUEST_ERROR";

export const START_BOOKING_LISTING_REQUEST = "START_BOOKING_LISTING_REQUEST";
export const START_BOOKING_LISTING_REQUEST_SUCCESS = "START_BOOKING_LISTING_REQUEST_SUCCESS";
export const START_BOOKING_LISTING_REQUEST_ERROR = "START_BOOKING_LISTING_REQUEST_ERROR";

export const END_BOOKING_LISTING_REQUEST = "END_BOOKING_LISTING_REQUEST";
export const END_BOOKING_LISTING_REQUEST_SUCCESS = "END_BOOKING_LISTING_REQUEST_SUCCESS";
export const END_BOOKING_LISTING_REQUEST_ERROR = "END_BOOKING_LISTING_REQUEST_ERROR";

export const PURCHASE_ORGANIC_LISTING_REQUEST = "PURCHASE_ORGANIC_LISTING_REQUEST";
export const PURCHASE_ORGANIC_LISTING_REQUEST_SUCCESS = "PURCHASE_ORGANIC_LISTING_REQUEST_SUCCESS";
export const PURCHASE_ORGANIC_LISTING_REQUEST_ERROR = "PURCHASE_ORGANIC_LISTING_REQUEST_ERROR";

export const PURCHASE_B2B_LISTING_REQUEST = "PURCHASE_B2B_LISTING_REQUEST";
export const PURCHASE_B2B_LISTING_REQUEST_SUCCESS = "PURCHASE_B2B_LISTING_REQUEST_SUCCESS";
export const PURCHASE_B2B_LISTING_REQUEST_ERROR = "PURCHASE_B2B_LISTING_REQUEST_ERROR";

export const CREATE_LISTING_DISPUTE_REQUEST = "CREATE_LISTING_DISPUTE_REQUEST";
export const CREATE_LISTING_DISPUTE_REQUEST_SUCCESS = "CREATE_LISTING_DISPUTE_REQUEST_SUCCESS";
export const CREATE_LISTING_DISPUTE_REQUEST_ERROR = "CREATE_LISTING_DISPUTE_REQUEST_ERROR";

export const UPDATE_LISTING_ACTIVITY_REQUEST = "UPDATE_LISTING_ACTIVITY_REQUEST";
export const UPDATE_LISTING_ACTIVITY_REQUEST_SUCCESS = "UPDATE_LISTING_ACTIVITY_REQUEST_SUCCESS";
export const UPDATE_LISTING_ACTIVITY_REQUEST_ERROR = "UPDATE_LISTING_ACTIVITY_REQUEST_ERROR";

export const GET_BEST_DEALS_REQUEST = "GET_BEST_DEALS_REQUEST";
export const GET_BEST_DEALS_REQUEST_SUCCESS = "GET_BEST_DEALS_REQUEST_SUCCESS";
export const GET_BEST_DEALS_REQUEST_ERROR = "GET_BEST_DEALS_REQUEST_ERROR";

export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST";
export const CANCEL_BOOKING_REQUEST_SUCCESS = "CANCEL_BOOKING_REQUEST_SUCCESS";
export const CANCEL_BOOKING_REQUEST_ERROR = "CANCEL_BOOKING_REQUEST_ERROR";

export const ADD_CARD_TO_LISTING_REQUEST = "ADD_CARD_TO_LISTING_REQUEST";
export const ADD_CARD_TO_LISTING_REQUEST_SUCCESS = "ADD_CARD_TO_LISTING_REQUEST_SUCCESS";
export const ADD_CARD_TO_LISTING_REQUEST_ERROR = "ADD_CARD_TO_LISTING_REQUEST_ERROR";

export const CREATE_LISTING_PRICE_OFFER_REQUEST = "CREATE_LISTING_PRICE_OFFER_REQUEST";
export const CREATE_LISTING_PRICE_OFFER_REQUEST_SUCCESS = "CREATE_LISTING_PRICE_OFFER_REQUEST_SUCCESS";
export const CREATE_LISTING_PRICE_OFFER_REQUEST_ERROR = "CREATE_LISTING_PRICE_OFFER_REQUEST_ERROR";

export const DECLINE_LISTING_PRICE_OFFER_REQUEST = "DECLINE_LISTING_PRICE_OFFER_REQUEST";
export const DECLINE_LISTING_PRICE_OFFER_REQUEST_SUCCESS = "DECLINE_LISTING_PRICE_OFFER_REQUEST_SUCCESS";
export const DECLINE_LISTING_PRICE_OFFER_REQUEST_ERROR = "DECLINE_LISTING_PRICE_OFFER_REQUEST_ERROR";

export const ACCEPT_LISTING_PRICE_OFFER_REQUEST = "ACCEPT_LISTING_PRICE_OFFER_REQUEST";
export const ACCEPT_LISTING_PRICE_OFFER_REQUEST_SUCCESS = "ACCEPT_LISTING_PRICE_OFFER_REQUEST_SUCCESS";
export const ACCEPT_LISTING_PRICE_OFFER_REQUEST_ERROR = "ACCEPT_LISTING_PRICE_OFFER_REQUEST_ERROR";

export const GET_ATTACHMENTS_URL_REQUEST = "GET_ATTACHMENTS_URL_REQUEST";

export const SET_NEW_LISTING = "SET_NEW_LISTING";

export const CLEAR_LISTING_ERROR = "CLEAR_LISTING_ERROR";

export const SET_LISTING_LIST = "SET_LISTING_LIST";

export const CANCEL_LISTINGS_SEARCH = "CANCEL_LISTINGS_SEARCH";

export const SET_LAST_SEARCH_PARAMS = "SET_LAST_SEARCH_PARAMS";

export const SET_SHOW_CARD_DETAILS_MODAL = "SET_SHOW_CARD_DETAILS_MODAL";
