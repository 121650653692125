import { all, put, call, takeLatest } from "redux-saga/effects";

import profile from "../../api/profile";
import * as types from "./profileDetailsActionsTypes";
import * as authTypes from "./../auth/authActionsTypes";

function *getProfile () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.getProfile);
    yield put({ type: types.GET_PROFILE_DETAILS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_PROFILE_DETAILS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateUser ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.updateUser, data);
    yield put({ type: types.UPDATE_USER_REQUEST_SUCCESS, response });
    yield put({ type: types.GET_PROFILE_HISTORY_REQUEST });
    yield put({ type: authTypes.SIGN_IN_REQUEST_SUCCESS, response: {
      id: response.id,
      first_name: response.first_name,
      last_name: response.last_name,
      email: response.email
    } });
  } catch (error) {
    yield put({ type: types.UPDATE_USER_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getProfileHistory () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.getProfileHistory);
    yield put({ type: types.GET_PROFILE_HISTORY_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_PROFILE_HISTORY_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *createTemporaryProfile ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(profile.createTemporaryProfile, data);
    yield put({ type: types.CREATE_TEMPORARY_PROFILE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.CREATE_TEMPORARY_PROFILE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateProfileDetails ({ data }) {
  yield put({ type: types.UPDATE_PROFILE_DETAILS_REQUEST_PROCESSING });

  try {
    const response = yield call(profile.updateProfileDetails, data);
    yield put({ type: types.UPDATE_PROFILE_DETAILS_REQUEST_SUCCESS, response });
    yield put({ type: types.GET_PROFILE_HISTORY_REQUEST });
    yield put({ type: authTypes.SIGN_IN_REQUEST_SUCCESS, response: {
      id: response.id,
      first_name: response.first_name,
      last_name: response.last_name,
      email: response.email
    } });
  } catch (error) {
    yield put({ type: types.UPDATE_PROFILE_DETAILS_REQUEST_ERROR, error: error.message });
  }
}

function *updateProfileAddress ({ data }) {
  yield put({ type: types.UPDATE_PROFILE_ADDRESS_REQUEST_PROCESSING });

  try {
    const response = yield call(profile.updateProfileAddress, data);
    yield put({ type: types.UPDATE_PROFILE_ADDRESS_REQUEST_SUCCESS, response });
    yield put({ type: types.GET_PROFILE_HISTORY_REQUEST });
  } catch (error) {
    yield put({ type: types.UPDATE_PROFILE_ADDRESS_REQUEST_ERROR, error: error.message });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_PROFILE_DETAILS_REQUEST, getProfile),
    yield takeLatest(types.UPDATE_USER_REQUEST, updateUser),
    yield takeLatest(types.GET_PROFILE_HISTORY_REQUEST, getProfileHistory),
    yield takeLatest(types.CREATE_TEMPORARY_PROFILE_REQUEST, createTemporaryProfile),
    yield takeLatest(types.UPDATE_PROFILE_DETAILS_REQUEST, updateProfileDetails),
    yield takeLatest(types.UPDATE_PROFILE_ADDRESS_REQUEST, updateProfileAddress)
  ])
}
