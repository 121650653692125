import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Button, Col, Row, Table } from "antd";

import {
  createCategoryRequest,
  deleteCategoryRequest,
  clearCategoryErrorAction,
  getCategoryListRequest
} from "../../../redux/admin/category/categoryActions";
import InputWithLabel from "../../shared/InputWithLabel";
import { itemRender } from "../../../helpers/pagination";

const CategoriesVerificationSchema = yup.object().shape({
  name: yup.string().trim().required("Category can't be empty")
});

const CategoryList = ({
  categoryList,
  error,
  isProcessing,
  pagination,
  requestSuccess,
  createCategory,
  deleteCategory,
  clearError,
  getCategoryList
}) => {
  const { handleSubmit, errors, setValue, control } = useForm({
    validationSchema: CategoriesVerificationSchema,
  });

  useEffect(() => {
    getCategoryList({ sort: "-articlesCount" });
  }, []);

  useEffect(() => {
    if (requestSuccess) {
      setValue("name", "");
      clearError();
    }
  }, [requestSuccess]);

  const [sortOrder, setSortOrder] = useState("-articlesCount");

  const getCategoryListByParams = ({ page, sort }) => {
    getCategoryList({
      page: page || pagination.current,
      sort: sort || sortOrder
    });
  };

  const categoriesVerificationHandler = (data) => {
    createCategory({ ...data, sort: sortOrder });
  };

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = `${sorter.order === "descend" ? "-" : ""}${sorter.field === "created" ? "name" : sorter.field}`;
    setSortOrder(sortByValue);

    getCategoryListByParams({
      page: tablePagination.current,
      sort: sortByValue
    });
  };

  const renderDeleteButton = (item) => {
    return (
      <a onClick={() => deleteCategory({ id: item.key, sort: sortOrder })}>
        <img src="/images/close.svg" alt="delete" />
      </a>
    )
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Created",
      dataIndex: "created",
      defaultSortOrder: "",
      sorter: (a, b) => a > b ? 1 : a < b ? -1 : 0,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Count",
      dataIndex: "articlesCount",
      key: "articlesCount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a - b,
      sortDirections: ["descend"],
    },
    {
      align: "right",
      title: "",
      key: "operation",
      width: 30,
      render: renderDeleteButton,
    }
  ];

  return (
    <div className="admin-categories">
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="admin-categories__column">
          <form onSubmit={handleSubmit(categoriesVerificationHandler)} className="admin-categories__form">
            <Row>
              <Col span={24} className="admin-categories__form-body">
                <Row>
                  <Col span={24} className="admin-categories__form-title">
                    Create new category
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <InputWithLabel
                      name="name"
                      control={control}
                      id="category-name"
                      onChange={([event]) => {
                        clearError();

                        return event.target.value
                      }}
                      label="Title"
                      error={errors.name || (error ? { message: error } : null)}
                      placeholder="Enter Title"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="admin-categories__form-submit">
                <Button className="button button--primary" htmlType="submit" disabled={isProcessing}>Add</Button>
              </Col>
            </Row>
          </form>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="admin-categories__column">
          <Table
            columns={columns}
            dataSource={categoryList.map(category => ({
              key: category.id,
              name: category.name,
              created: category.User ? `${category.User.first_name} ${category.User.last_name}` : "",
              articlesCount: category.articlesCount
            }))}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              itemRender: itemRender
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </div>
  );
};

CategoryList.propTypes = {
  getCategoryList: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  categoryList: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  error: PropTypes.string,
  isProcessing: PropTypes.bool,
  requestSuccess: PropTypes.bool,
};

export default connect(
  state => ({
    categoryList: state.admin.category.data,
    pagination: {
      current: state.admin.category.curPage,
      total: state.admin.category.total,
      pageSize: state.admin.category.perPage
    },
    error: state.admin.category.error,
    isProcessing: state.admin.category.processing,
    requestSuccess: state.admin.category.success,
  }),
  {
    getCategoryList: getCategoryListRequest,
    createCategory: createCategoryRequest,
    deleteCategory: deleteCategoryRequest,
    clearError: clearCategoryErrorAction,
  }
)(CategoryList);
