import React, { Fragment } from "react";
import { Col, Row } from "antd";
import Footer from "./Footer";

const AuthFooter = () => {
  return (
    <Fragment>
      <Row justify="space-around" align="middle" className="footer-desktop">
        <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 8 }}>
          <div className="auth-footer">
            &copy; {new Date().getFullYear()} Loss2Gain Limited. All rights reserved.
          </div>
        </Col>
      </Row>
      <Footer className="footer-mobile" />
    </Fragment>
  );
};

export default AuthFooter;
