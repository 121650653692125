import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { Col, List, Row, Typography } from "antd";

import ListsNavTabs from "./ListsNavTabs";
import Pagination from "../Pagination";
import CoverLink from "../CoverLink";

const getNavQuery = (navOptions, status) => navOptions
  .find(({ key }) => key === status)?.queryValue;

const HotelCardsLists = ({
  defaultPaginationObj,
  navOptions,
  dataSource,
  isProcessing,
  pagination,
  getList,
  hotelCard: HotelCard,
  queryKey,
  title
}) => {
  const { status } = useParams();
  const query = useMemo(
    () => ({ ...defaultPaginationObj, [queryKey]: getNavQuery(navOptions, status) }),
    [status, navOptions, defaultPaginationObj]
  );

  useEffect(() => {
    getList(query);
  }, [query]);

  const onChange = useCallback((value) => {
    getList({ ...query, page: value })
  }, [query])

  useEffect(() => {
    if (!isProcessing) {
      const scrolledY = document.body.scrollTop;

      if (scrolledY) {
        document.body.scrollTo({ top: 0 });
      }
    }
  }, [isProcessing]);

  return (
    <div className="my-listings">
      <Typography.Title level={1}>
        {title}
      </Typography.Title>

      <section className="section">
        <ListsNavTabs items={navOptions}/>
      </section>

      <Row>
        <Col span={24}>
          <List
            loading={isProcessing}
            pagination={false}
            dataSource={dataSource}
            renderItem={item => {
              return (
                <div className="list-item">
                  <CoverLink to={`/listings/${item.slug}`}>
                    <HotelCard data={item}/>
                  </CoverLink>
                </div>
              );
            }}
          >
          </List>
          <div className="my-listings__pagination">
            <Pagination
              onChange={onChange}
              total={pagination.total}
              pageSize={pagination.pageSize}
              current={pagination.current}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

HotelCardsLists.defaultProps = {
  defaultPaginationObj: {
    page: 1,
    pageSize: 10,
    status: null
  }
}

HotelCardsLists.propTypes = {
  navOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  hotelCard: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  defaultPaginationObj: PropTypes.object,
  queryKey: PropTypes.string
};

export default HotelCardsLists;

