import { all, put, call, takeLatest } from "redux-saga/effects";

import userManagement from "../../../api/admin/userManagement";
import * as types from "./userManagementActionsTypes";
import * as generalTypes from "../../general/generalActionsTypes";
import { MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS } from "../../../react/constants";

function *getUserList ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(userManagement.getUserList, data);
    yield put({ type: types.ADMIN_GET_USER_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_USER_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getUser ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(userManagement.getUser, data);
    yield put({ type: types.ADMIN_GET_USER_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_USER_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateUser ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(userManagement.updateUser, data);
    if (data.body && data.body.cardVerificationStatus) {
      yield put({
        type: generalTypes.SHOW_MODAL,
        data: {
          type: MODAL_TYPE_SUCCESS,
          title: "Card verification status updated successfully!"
        }
      });
    }
    yield put({ type: types.ADMIN_UPDATE_USER_REQUEST_SUCCESS, response });
    if (data.body && !data.body.cardVerificationStatus) {
      yield put({ type: generalTypes.HIDE_MODAL, response });
    }
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_USER_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *downloadKYCFile ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(userManagement.downloadKYCFile, data);
    yield put({ type: types.ADMIN_DOWNLOAD_KYC_FILE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_DOWNLOAD_KYC_FILE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getCsvExportUrl ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(userManagement.getCsvExportUrl, data);
    if (response.fileUrl) {
      const a = document.createElement("a");
      a.href = response.fileUrl;
      a.download = response.fileUrl;
      a.click();
    }
  } catch (error) {
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getUserListings ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(userManagement.getUserListings, data);
    yield put({ type: types.ADMIN_GET_USER_LISTINGS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_USER_LISTINGS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_USER_LIST_REQUEST, getUserList),
    yield takeLatest(types.ADMIN_GET_USER_REQUEST, getUser),
    yield takeLatest(types.ADMIN_UPDATE_USER_REQUEST, updateUser),
    yield takeLatest(types.ADMIN_DOWNLOAD_KYC_FILE_REQUEST, downloadKYCFile),
    yield takeLatest(types.ADMIN_GET_USERS_CSV_EXPORT_URL_REQUEST, getCsvExportUrl),
    yield takeLatest(types.ADMIN_GET_USER_LISTINGS_REQUEST, getUserListings)
  ])
}
