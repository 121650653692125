import React from "react";
import { Route, Switch } from "react-router-dom";

import MainLayout from "../layout/MainLayout";
import PrivateRoute from "./components/PrivateRoute";

import Seo from "../shared/Seo";
import CreateDispute from "../Listing/CreateDispute/CreateDispute";
import SellingListing from "../Listing/SellingListing";
import PreviewListing from "../Listing/PreviewListing";
import PurchasingListingList from "../Listing/PurchasingListingList";
import PurchasingListing from "../Listing/PurchasingListing";
import PrepaymentDetails from "../Listing/PrepaymentDetails";
import ShareListing from "../Listing/ShareListing/ShareListing";
import PaymentResult from "../Listing/PaymentResult";
import PageNotFound from "../PageNotFound/PageNotFound";

export default function AuthRoutes() {
  return (
    <MainLayout>
      <Switch>
        <PrivateRoute
          path="/listings/create"
          exact
          component={(props) => (
            <Seo
              title="Create New Listing"
              description="Create a new listing to sell your hotel room for the price you paid for it or less. List on our site and get notified when your listing attracts interest or gets booked!"
            >
              <SellingListing {...props} />
            </Seo>
          )}
        />
        <PrivateRoute
          path="/listings/create/preview"
          exact
          component={(props) => (
            <Seo
              title="Create New Listing"
              description="Create a new listing to sell your hotel room for the price you paid for it or less. List on our site and get notified when your listing attracts interest or gets booked!"
            >
              <PreviewListing {...props} />
            </Seo>
          )}
        />
        <PrivateRoute
          path="/listings/:slug/share"
          exact
          component={(props) => (
            <Seo
              title="Hotel Listings"
              description="From budget to luxury, nearby or far away. We work with a variety of hotels across the globe, all are listed in one place! Making booking easy & completely flexible!"
            >
              <ShareListing {...props} />
            </Seo>
          )}
        />
        <PrivateRoute
          path="/listings/:slug/edit"
          exact
          component={(props) => (
            <Seo
              title="Hotel Listings"
              description="From budget to luxury, nearby or far away. We work with a variety of hotels across the globe, all are listed in one place! Making booking easy & completely flexible!"
            >
              <SellingListing {...props} />
            </Seo>
          )}
        />
        <PrivateRoute
          path="/listings/:slug/edit/preview"
          exact
          component={(props) => (
            <Seo
              title="Hotel Listings"
              description="From budget to luxury, nearby or far away. We work with a variety of hotels across the globe, all are listed in one place! Making booking easy & completely flexible!"
            >
              <PreviewListing {...props} />
            </Seo>
          )}
        />
        <PrivateRoute
          path="/listings/:slug/dispute"
          exact
          component={(props) => (
            <Seo
              title="Hotel Listings"
              description="From budget to luxury, nearby or far away. We work with a variety of hotels across the globe, all are listed in one place! Making booking easy & completely flexible!"
            >
              <CreateDispute {...props} />
            </Seo>
          )}
        />
        <Route
          path="/listings/:slug/payment"
          exact
          render={(props) => (
            <Seo
              title="Hotel Listings"
              description="From budget to luxury, nearby or far away. We work with a variety of hotels across the globe, all are listed in one place! Making booking easy & completely flexible!"
            >
              <PaymentResult {...props} />
            </Seo>
          )}
        />
        <Route
          path="/listings"
          exact
          render={(props) => (
            <Seo
              title="Hotel Listings"
              description="From budget to luxury, nearby or far away. We work with a variety of hotels across the globe, all are listed in one place! Making booking easy & completely flexible!"
            >
              <PurchasingListingList {...props} />
            </Seo>
          )}
        />
        <Route path="/listings/:slug" exact component={PurchasingListing} />
        <Route
          path="/listings/:slug/details"
          exact
          render={(props) => (
            <Seo
              title="Hotel Listings"
              description="From budget to luxury, nearby or far away. We work with a variety of hotels across the globe, all are listed in one place! Making booking easy & completely flexible!"
            >
              <PrepaymentDetails {...props} />
            </Seo>
          )}
        />
        <Route component={PageNotFound} />
      </Switch>
    </MainLayout>
  );
}
