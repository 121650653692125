import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const SwitchButton = ({ isActive = false, ...props }) => {

  const activeClass = isActive ? "switch-button--active" : "";

  return (
    <Button type="primary" className={`switch-button ${activeClass}`} {...props} />
  );
};

SwitchButton.propTypes = {
  isActive: PropTypes.bool
};

export default SwitchButton;
