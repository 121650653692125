import * as types from "./faqActionsTypes";

export const initialState = {
  processing: false,
  requestSuccess: false,
  error: "",
  questions: {
    total: 0,
    curPage: 1,
    pages: 1,
    perPage: 10,
    data: [],
    singleQuestion: {
      content: "",
      title: "",
      categoryId: null
    }
  },
  categories: {
    total: 0,
    curPage: 1,
    pages: 1,
    perPage: 10,
    data: [],
    singleCategory: {
      title: ""
    }
  }
};

const faq = (state = initialState, action) => {
  switch (action.type) {
    case types.FAQ_SHOW_LOADING:
      return {
        ...state,
        processing: true,
        requestSuccess: false
      };
    case types.FAQ_HIDE_LOADING:
      return {
        ...state,
        processing: false
      };
    case types.ADMIN_GET_QUESTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        questions: {
          ...state.questions,
          ...action.response
        },
        error: ""
      };
    case types.ADMIN_CREATE_QUESTION_REQUEST_SUCCESS:
    case types.ADMIN_GET_QUESTION_REQUEST_SUCCESS:
      return {
        ...state,
        questions: {
          ...state.questions,
          singleQuestion: action.response
        },
        error: ""
      };
    case types.ADMIN_UPDATE_QUESTION_REQUEST_SUCCESS:
      return {
        ...state,
        questions: {
          ...state.questions,
          singleQuestion: action.response,
          data: state.questions.data.map(item => item.id === action.response.id ? action.response : item)
        },
        error: ""
      };
    case types.ADMIN_CREATE_QUESTION_REQUEST_ERROR:
    case types.ADMIN_UPDATE_QUESTION_REQUEST_ERROR:
    case types.ADMIN_GET_QUESTION_REQUEST_ERROR:
    case types.ADMIN_GET_QUESTIONS_REQUEST_ERROR:
    case types.ADMIN_DELETE_QUESTION_REQUEST_ERROR:
    case types.ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST_ERROR:
    case types.ADMIN_RESET_QUESTIONS_ORDER_REQUEST_ERROR:
    case types.ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        requestSuccess: false
      };
    case types.ADMIN_RESET_QUESTIONS_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: true
      };
    case types.ADMIN_DELETE_QUESTION_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: true
      };
    case types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.response
        },
        error: "",
      };
    case types.ADMIN_GET_QUESTIONS_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          singleCategory: action.response
        },
        error: "",
      };
    case types.ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        requestSuccess: true
      };
    case types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_ERROR:
    case types.ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST_ERROR:
    case types.ADMIN_GET_QUESTIONS_CATEGORY_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_CLEAR_QUESTIONS_CATEGORY_ERROR:
      return {
        ...state,
        error: "",
        requestSuccess: false
      };
    case types.ADMIN_CLEAR_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          singleQuestion: {
            content: "",
            title: "",
            categoryId: null
          }
        }
      };
    case types.ADMIN_CLEAR_QUESTION_ERROR:
      return {
        ...state,
        error: "",
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default faq;
