import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const BackLink = ({ url, label, onClick }) => {
  return (
    <Link to={url} className="back-link" onClick={evt => {
      if (onClick) {
        evt.preventDefault();
        onClick();
      }
    }}>
      <svg width="4px" height="8px" viewBox="0 0 4 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor">
          <polygon points="4 0 4 8 0 4"/>
        </g>
      </svg>
      <span className="back-link__label">{label}</span>
    </Link>
  );
};

BackLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default BackLink;

