export const hotelChains = [
  {
    name: "Above Property Llc",
    value: "AY"
  },
  {
    name: "Accor Hotels",
    value: "RT",
    top: true
  },
  {
    name: "Alesia Distribution",
    value: "OK"
  },
  {
    name: "Amadeus Linkhotel",
    value: "OI"
  },
  {
    name: "Atel Hotels Network",
    value: "AC"
  },
  {
    name: "Best Western Hotels And Resorts",
    value: "BW",
    top: true
  },
  {
    name: "Choice Hotels Corporation",
    value: "ZCH"
  },
  {
    name: "City Lodge Hotels",
    value: "CG"
  },
  {
    name: "D-Edge",
    value: "FG"
  },
  {
    name: "Gamma Hotels",
    value: "YP"
  },
  {
    name: "Great Hotels Of The World",
    value: "GW"
  },
  {
    name: "Hilton Hotels Corporation",
    value: "HH",
    top: true
  },
  {
    name: "Hotelzon",
    value: "HZ"
  },
  {
    name: "Hrs",
    value: "HQ"
  },
  {
    name: "Hyatt",
    value: "HY",
    top: true
  },
  {
    name: "Intercontinental Hotels Group",
    value: "HI",
    top: true
  },
  {
    name: "Keytel",
    value: "KY"
  },
  {
    name: "Louvre Hotels",
    value: "NN"
  },
  {
    name: "Magnuson Hotels",
    value: "MS"
  },
  {
    name: "Maritim Hotels",
    value: "MM"
  },
  {
    name: "Marriott Hotels & Resorts",
    value: "MC",
    top: true
  },
  {
    name: "Mgm Mirage Operations",
    value: "MV"
  },
  {
    name: "Motel 6",
    value: "MX"
  },
  {
    name: "Nh Hoteles",
    value: "NH"
  },
  {
    name: "Nikko Hotels International",
    value: "NK"
  },
  {
    name: "Omni Hotels",
    value: "OM"
  },
  {
    name: "Omnibees Portugal",
    value: "OD"
  },
  {
    name: "Oracle Hospitality",
    value: "IQ"
  },
  {
    name: "Pegasus Solutions",
    value: "AX"
  },
  {
    name: "Premier Inn",
    value: "PI",
    top: true
  },
  {
    name: "Prodigios Interactivos S.A.",
    value: "SM"
  },
  {
    name: "Radisson Hotels & Resorts",
    value: "RD",
    top: true
  },
  {
    name: "Reconline",
    value: "ON"
  },
  {
    name: "Red Lion Hotels & Inns",
    value: "RL"
  },
  {
    name: "Reservhotel",
    value: "TA"
  },
  {
    name: "Sabre Hospitality Solutions",
    value: "YX"
  },
  {
    name: "Scandic Hotels",
    value: "SH"
  },
  {
    name: "Sceptre Hotels",
    value: "SC"
  },
  {
    name: "Southern Sun Hotels (Pty) Ltd",
    value: "SU"
  },
  {
    name: "Supranational",
    value: "SX"
  },
  {
    name: "Travelclick",
    value: "WV"
  },
  {
    name: "Travelodge Hotels",
    value: "TG",
    top: true
  },
  {
    name: "Wyndham Worldwide",
    value: "ZHF",
    top: true
  },
  {
    name: "Xn Protel Systems",
    value: "XN"
  }
];
