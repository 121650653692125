import React, { useEffect } from "react";

export const CookieConsent = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const consentScript = document.createElement("script");
      consentScript.type = "text/javascript"
      consentScript.charset = "UTF-8"
      consentScript.src = "//www.freeprivacypolicy.com/public/cookie-consent/4.1.0/cookie-consent.js"
      document.getElementsByTagName("head")[0].appendChild(consentScript);
      const initialDelay = 1000 // 1s
      const consentFunction = (attempt) => {
        if (attempt < 4) {
          if (window.cookieconsent && window.hj) {
            window.cookieconsent.run({ "notice_banner_type": "simple", "consent_type": "express", "palette": "light",
              "language": "en", "page_load_consent_levels": ["strictly-necessary"], "notice_banner_reject_button_hide": false,
              "preferences_center_close_button_hide": false, "page_refresh_confirmation_buttons": false,
              "website_name": "plansChange.com", "website_privacy_policy_url": "https://www.planschange.com/legal/privacy-policy" });
          } else {
            attempt++;
            setTimeout(() => {
              consentFunction(attempt);
            }, initialDelay + (1000 * attempt))
          }
        }
      }
      consentFunction(0);
    }
  }, []);

  return <React.Fragment/>
};

export default CookieConsent;
