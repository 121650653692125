export const KYC_NOT_VERIFIED_STATUS = "not-verified";
export const KYC_PENDING_STATUS = "pending";
export const KYC_VERIFIED_STATUS = "verified";
export const KYC_FAILED_STATUS = "failed";
export const KYC_MANGO_PAY_FAILED_STATUS = "mango-pay-failed";
export const BANK_ACCOUNT_PRIORITY_PRIMARY = "primary";
export const BANK_ACCOUNT_PRIORITY_SECONDARY = "secondary";
export const MODAL_TYPE_SUCCESS = "success";
export const MODAL_TYPE_ERROR = "error";
export const MODAL_TYPE_CONFIRM = "confirm";
export const MODAL_TYPE_INFO = "info";
export const MODAL_TYPE_FAQ_PREVIEW = "faq-preview";
export const MODAL_TYPE_KYC_FAILURE_REASONS = "kyc-failure-reasons";
export const MODAL_TYPE_ACCOUNT_VERIFICATION = "account-verification";
export const MODAL_TYPE_LISTING_AMENITIES = "listing-amenities";
export const MODAL_TYPE_LISTING_CARD_DETAILS = "listing-card-details";
export const MODAL_TYPE_LISTING_OFFER_PRICE = "listing-offer-price";
export const MODAL_TYPE_LISTING_OFFER_PRICE_NOT_LOGGED = "listing-offer-price-not-logged";
export const MODAL_TYPE_LISTING_PUBLISH_PAYMENT = "listing-publish-payment";
export const ADMIN_ROLE = "admin";
export const SUPER_ADMIN_ROLE = "super-admin";
export const CARD_NOT_VERIFIED_STATUS = "not-verified";
export const CARD_VERIFIED_STATUS = "verified";
export const CARD_VERIFICATION_FAILED_STATUS = "failed";
export const NOTIFICATION_TYPE_REGISTRATION = "registration";
export const NOTIFICATION_TYPE_KYC = "kyc";
export const NOTIFICATION_TYPE_FAILED_VERIFICATION = "failed-verification";
export const NOTIFICATION_TYPE_CREATED_LISTING = "created-listing";
export const NOTIFICATION_TYPE_CANCELLED_LISTING = "cancelled-listing";
export const NOTIFICATION_TYPE_MARKETING_SUBSCRIPTION = "marketing-subscription";
export const TRANSACTION_TYPE_WITHDRAW = "withdraw";
export const TRANSACTION_TYPE_REFUND = "refund";
export const TRANSACTION_TYPE_PURCHASE = "purchase";
export const MODAL_BOOK_NOT_LOGGED = "book-not-logged";

export const DISPUTE_PROPS = {
  "raised": {
    value: "raised",
    labelBuyer: "Your dispute was raised. Administrator will review it as soon as possible.",
    labelSeller: "A dispute was raised. Administrator will review it as soon as possible."
  },
  "in-review": {
    value: "in review",
    labelBuyer: "Administrator has started to review your dispute.",
    labelSeller: "Administrator has started to review a dispute on this listing."
  },
  "accepted": {
    value: "accepted",
    labelBuyer: "Administrator fully accepted your dispute. Check your email for details.",
    labelSeller: "Administrator fully accepted a dispute on this listing. Check your email for details."
  },
  "partially-accepted": {
    value: "partially accepted",
    labelBuyer: "Administrator accepted your dispute. Check your email for details.",
    labelSeller: "Administrator accepted a dispute on this listing. Check your email for details."
  },
  "rejected": {
    value: "rejected",
    labelBuyer: "Administrator rejected your dispute. Check your email for details.",
    labelSeller: "Administrator rejected a dispute on this listing. Check your email for details."
  }
};
export const LISTING_TYPE_ORGANIC = "organic";
export const LISTING_TYPE_B2B = "b2b";

export const HOTEL_BRAND_LOGOS = [
  {
    codes: ["RT", "BY", "PU", "YR", "RX", "SB", "SL"],
    logo: "/images/hotel-brand-logos/accor.svg"
  },
  {
    codes: ["FA"],
    logo: "/images/hotel-brand-logos/fairmont.svg"
  },
  {
    codes: ["BW", "UR", "XW"],
    logo: "/images/hotel-brand-logos/best-western.svg"
  },
  {
    codes: ["PY", "CN", "QQ", "DT", "ES", "HX", "GI", "HH", "HL", "HT", "HG", "OL", "UP", "RU", "UA", "WA"],
    logo: "/images/hotel-brand-logos/hilton.svg"
  },
  {
    codes: ["LF", "DN", "HY", "HU", "JV", "TM"],
    logo: "/images/hotel-brand-logos/hyatt.svg"
  },
  {
    codes: ["HI"],
    logo: "/images/hotel-brand-logos/holiday-inn.svg"
  },
  {
    codes: [
      "AR", "AL", "AK", "BG", "CY", "DE", "DP", "EB", "EL", "FN", "FP", "GE", "MD", "LC", "ET", "MC", "VC", "OX", "PR",
      "BR", "RC", "SI", "XV", "XR", "RZ", "TO", "TX", "WH", "WI"
    ],
    logo: "/images/hotel-brand-logos/marriott.svg"
  },
  {
    codes: ["CX", "PD", "PK", "RD"],
    logo: "/images/hotel-brand-logos/radisson.svg"
  },
  {
    codes: ["FS"],
    logo: "/images/hotel-brand-logos/four-seasons.svg"
  },
  {
    codes: ["AA", "BU", "DI", "DX", "FE", "BH", "HJ", "LQ", "MT", "RA", "OZ", "TQ", "TL", "WT", "WG", "WY"],
    logo: "/images/hotel-brand-logos/wyndham.svg"
  }
];

export const PAYMENT_FORM_TYPE_ACCOUNT_VERIFICATION = "account-verification";
export const PAYMENT_FORM_TYPE_LISTING_PUBLISH = "listing-publish";

