import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { message } from "antd";
import { Helmet } from "react-helmet";

import AppRoutes from "./router/AppRoutes";
import { getProfilePermissionsRequest } from "../redux/profile/profileActions";
import Modal from "./shared/Modal/Modal";
import GoogleAnalytics from "./shared/GoogleAnalytics";
import GoogleAds from "./shared/GoogleAds";
import GoogleTagManager from "./shared/GoogleTagManager";
import FacebookPixel from "./shared/FacebookPixel";
import CookieConsent from "./shared/CookieConsent";
import Hotjar from "./shared/Hotjar";
import { getOrganizationSchemaScriptContent } from "../helpers/schema";

function App({ getProfilePermissions }) {
  message.config({
    maxCount: 3
  });

  useEffect(() => {
    getProfilePermissions();

    if (typeof window !== "undefined") {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  });

  return (
    <React.Fragment>
      { typeof window === "undefined" &&
        <Helmet>
          <script type="application/ld+json">{getOrganizationSchemaScriptContent()}</script>
        </Helmet>
      }
      <AppRoutes/>
      <Modal/>
      <CookieConsent/>
      { process.env.REACT_APP_ANALYTICS_STATUS === "enabled" &&
        <React.Fragment>
          <GoogleAnalytics/>
          <GoogleAds/>
          <GoogleTagManager/>
          <FacebookPixel/>
          <Hotjar/>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

App.propTypes = {
  getProfilePermissions: PropTypes.func.isRequired
};

export default withRouter(connect(
  null,
  {
    getProfilePermissions: getProfilePermissionsRequest
  }
)(App));
