export const splitText = (text, chunkSize) => {
  if (!text) {
    return ""
  }
  let paragraph = "";
  const paragraphs = [],
    sentenceRegex = /[^\\.!\\?]+([\\.!\\?]+|\s*$)/g,
    sentences = text.match(sentenceRegex);

  if (!chunkSize) {
    chunkSize = 200
  }
  sentences.forEach(function createParagraphs (sentence, index) {
    paragraph += sentence;
        
    const words = paragraph.split(" ");
    const lastWord = words[words.length - 1]

    if ((paragraph.length >= chunkSize && lastWord.length > 4) || index === sentences.length - 1) {
      paragraphs.push(paragraph);
      paragraph = "";
    }
  });

  return paragraphs.length === 0 ? text : paragraphs.join("<br><br>");
}
