import React from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";

import Header from "../shared/Header";
import Footer from "../shared/Footer";

function MainLayout({ children }) {
  return (
    <div className="main-content">
      <Header/>

      <Layout.Content>
        { children }
      </Layout.Content>

      <Footer/>
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.any,
};

export default MainLayout;
