import React, { useEffect } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BackLink from "../../shared/BackLink";
import { getFeedbackRequest } from "../../../redux/admin/feedback/feedbackActions";

const Feedback = ({ getFeedback, feedback, match }) => {
  const { id } = match.params;

  useEffect(() => {
    getFeedback({ id });
  }, [id]);

  return (
    <div className="feedback">
      <Row>
        <Col span={24} className="feedback__back-link-container">
          <BackLink label="Back to feedback" url="/admin/feedback" />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="admin-section">
          <Row>
            <Col span={24} className="feedback__user-name">{feedback.firstName} {feedback.lastName}</Col>
          </Row>
          <Row>
            <Col span={24} className="feedback__user">
              <span>Email:</span>
              <span className="feedback__user-email">{feedback.email}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="admin-section">
          <Row>
            <Col span={24} className="feedback__message-title">
              Feedback
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {feedback.message}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
};

Feedback.propTypes = {
  feedback: PropTypes.object.isRequired,
  getFeedback: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

export default connect(
  state => ({
    feedback: state.admin.feedback.singleFeedback
  }),
  {
    getFeedback: getFeedbackRequest
  }
)(Feedback);
