import React from "react";
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { Row, Col, Checkbox } from "antd"

const CheckboxGroup = ({ options, className, defaultValue, grid, ...rest }) => {

  return (
    <Checkbox.Group
      className={className}
      defaultValue={defaultValue}
      {...rest}
    >
      <Row>
        {options.map(({ value, label }) => (
          <Col key={uuidv4()} {...grid} >
            <Checkbox value={value} > {label} </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>

  );
}

CheckboxGroup.defaultProps = {
  grid: {
    xs: { span: 24 },
    md: { span: 12 },
    xl: { span: 8 }
  },
  defaultValue: []
}

CheckboxGroup.propTypes = {
  defaultValue: PropTypes.array,
  className: PropTypes.string,
  options: PropTypes.array,
  grid: PropTypes.object
}

export default CheckboxGroup;
