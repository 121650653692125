import { all, put, call, takeLatest } from "redux-saga/effects";

import dashboard from "../../../api/admin/dashboard";
import * as types from "./dashboardActionsTypes";

function *getListings ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dashboard.getListings, data);
    yield put({ type: types.ADMIN_GET_DASHBOARD_LISTINGS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DASHBOARD_LISTINGS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getUsers ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dashboard.getUsers, data);
    yield put({ type: types.ADMIN_GET_DASHBOARD_USERS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DASHBOARD_USERS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getDisputes ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dashboard.getDisputes, data);
    yield put({ type: types.ADMIN_GET_DASHBOARD_DISPUTES_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DASHBOARD_DISPUTES_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getFees ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dashboard.getFees, data);
    yield put({ type: types.ADMIN_GET_DASHBOARD_FEES_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DASHBOARD_FEES_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getNotifications ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dashboard.getNotifications, data);
    yield put({ type: types.ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getFunds ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dashboard.getFunds, data);
    yield put({ type: types.ADMIN_GET_DASHBOARD_FUNDS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DASHBOARD_FUNDS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getGeolocationData ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dashboard.getGeolocationData, data);
    yield put({ type: types.ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getSalesListings ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dashboard.getSalesListings, data);
    yield put({ type: types.ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_DASHBOARD_LISTINGS_REQUEST, getListings),
    yield takeLatest(types.ADMIN_GET_DASHBOARD_USERS_REQUEST, getUsers),
    yield takeLatest(types.ADMIN_GET_DASHBOARD_DISPUTES_REQUEST, getDisputes),
    yield takeLatest(types.ADMIN_GET_DASHBOARD_FEES_REQUEST, getFees),
    yield takeLatest(types.ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST, getNotifications),
    yield takeLatest(types.ADMIN_GET_DASHBOARD_FUNDS_REQUEST, getFunds),
    yield takeLatest(types.ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST, getGeolocationData),
    yield takeLatest(types.ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST, getSalesListings)
  ])
}
