import { all, put, call, takeLatest } from "redux-saga/effects";

import listings from "../../../api/admin/listings";
import * as types from "./listingsActionsTypes";
import * as generalTypes from "../../general/generalActionsTypes";
import { MODAL_TYPE_ERROR } from "../../../react/constants";

function *getListings ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(listings.getListings, data);
    yield put({ type: types.ADMIN_GET_LISTINGS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_LISTINGS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getCsvExportUrl ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(listings.getCsvExportUrl, data);
    if (response.fileUrl) {
      const a = document.createElement("a");
      a.href = response.fileUrl;
      a.download = response.fileUrl;
      a.click();
    }
  } catch (error) {
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateListing ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(listings.updateListing, data);
    yield put({ type: types.ADMIN_UPDATE_LISTING_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_LISTING_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_LISTINGS_REQUEST, getListings),
    yield takeLatest(types.ADMIN_GET_CSV_EXPORT_URL_REQUEST, getCsvExportUrl),
    yield takeLatest(types.ADMIN_UPDATE_LISTING_REQUEST, updateListing)
  ])
}
