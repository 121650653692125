import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import { formatAddress } from "../../helpers/common";
import { BANK_ACCOUNT_PRIORITY_PRIMARY } from "../constants";

const BankAccountContainer = ({ name, accountNumber, address, priority, makePriority, onDelete }) => {

  const renderPriority = () => {
    if (priority === BANK_ACCOUNT_PRIORITY_PRIMARY) {
      return <span className="bank-account__priority-item seller-wallet-container__subtext">{priority} Account</span>;
    }

    return (
      <button className="bank-account__priority-button seller-wallet-container__subtext" onClick={makePriority}>
        Make Primary
      </button>
    );
  };

  const { Country, City, AddressLine1, AddressLine2, PostalCode, Region } = address;
  const formattedAddress = formatAddress([Country, Region, City, AddressLine1, AddressLine2, PostalCode]);

  return (
    <div className="form-container">
      <div className="seller-wallet-container">
        <div className="seller-wallet-container__item">
          <div>
            <h3 className="form-subtitle seller-wallet-container__title">{name}</h3>
            {renderPriority()}
          </div>
        </div>

        <div className="seller-wallet-container__item">
          <div>
            <h3 className="form-subtitle seller-wallet-container__title">Account Number</h3>
            <span className="seller-wallet-container__subtext">{accountNumber}</span>
          </div>
        </div>

        <div className="seller-wallet-container__item">
          <div>
            <h3 className="form-subtitle seller-wallet-container__title">Address</h3>
            <span className="seller-wallet-container__subtext">{formattedAddress}</span>
          </div>
        </div>

        <div className="seller-wallet-container__item seller-wallet-container__right-item">
          <Button className="bank-account__remove-button" onClick={onDelete}>Remove</Button>
        </div>
      </div>
    </div>
  );
};

BankAccountContainer.propTypes = {
  name: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  priority: PropTypes.string.isRequired,
  makePriority: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default BankAccountContainer;
