import React, { useEffect } from "react";
import { Button, Popover } from "antd";
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { KYC_FAILED_STATUS, KYC_NOT_VERIFIED_STATUS, KYC_PENDING_STATUS, KYC_VERIFIED_STATUS } from "../constants";
import { getCurrencyBadge } from "../../helpers/common";
import InfoOutlinedIcon from "../shared/icons/InfoOutlinedIcon/InfoOutlinedIcon";
import KnowYourCustomer from "./KnowYourCustomer/KnowYourCustomer";
import { showKycValidationForm, clearValidationSendMessage } from "../../redux/profile/profileActions";
import KYCPopover from "./KYCPopover/KYCPopover";

const kycStatusLabels = {
  [KYC_FAILED_STATUS]: "KYC failed",
  [KYC_NOT_VERIFIED_STATUS]: "KYC Not Verified",
  [KYC_VERIFIED_STATUS]: "KYC Validated",
  [KYC_PENDING_STATUS]: "KYC Pending"
};

const WalletBalance = ({
  kycStatus,
  currency,
  balance,
  onWithdraw,
  isBankAccountExists,
  validationFormOpen,
  showValidationForm,
  kycValidationSendMessage,
  clearKycValidationSendMessage
}) => {
  const openValidationForm = () => {
    showValidationForm(true);
  };

  const iconSuccessClass = [KYC_VERIFIED_STATUS].includes(kycStatus) ? "wallet-balance__verify-info-icon-success" : "";
  const isWithdrawalActive = [KYC_VERIFIED_STATUS].includes(kycStatus) && balance.available > 0 && isBankAccountExists;

  useEffect(() => () => clearKycValidationSendMessage(), []);

  return (
    <div className="form-container">
      <div className="wallet-balance">
        <div className="wallet-balance__item">
          <div>
            <h4 className="form-subtitle wallet-balance__title">Balance</h4>
            <span className="wallet-balance__subtext">Available Funds</span>
          </div>
        </div>
        <div className="wallet-balance__item">
          <span className="wallet-balance__balance">
            {getCurrencyBadge(currency)}{(+balance.available).toFixed(2)}
          </span>
        </div>
        <div className="wallet-balance__item wallet-balance__verify-info-item">
          <Popover
            placement="bottom"
            content={() => <KYCPopover type={kycStatus} />}
          >
            <div className="wallet-balance__verify-info-icon-wrapper">
              <InfoOutlinedIcon className={`wallet-balance__verify-info-icon ${iconSuccessClass}`}/>
            </div>
          </Popover>
          <span className="wallet-balance__kyc-status-text">{kycStatusLabels[kycStatus]}</span>
        </div>
        <div className="wallet-balance__right-item wallet-balance__item">
          {
            [KYC_NOT_VERIFIED_STATUS].includes(kycStatus) ?
              <Button type="primary" onClick={openValidationForm}>Validate Account</Button> :
              !isBankAccountExists
                ? <Popover
                  content={
                    <div className="wallet-balance__popover-content">
                      Please create a{" "}
                      <Link to="/seller/bank-account">Bank Account</Link>{" "}
                      to be able to withdraw your funds
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    onClick={onWithdraw}
                    disabled={!isWithdrawalActive}
                  >
                    Withdraw
                  </Button>
                </Popover>
                : <Button
                  type="primary"
                  onClick={onWithdraw}
                  disabled={!isWithdrawalActive}
                >
                  Withdraw
                </Button>
          }
        </div>
      </div>
      {kycValidationSendMessage && <span className="user-wallet__success-send-kyc">{kycValidationSendMessage}</span>}
      {validationFormOpen &&
      <div className="user-wallet__know-your-customer-wrapper">
        <KnowYourCustomer/>
      </div>}
    </div>
  );
};

WalletBalance.propTypes = {
  kycStatus: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  balance: PropTypes.object.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  isBankAccountExists: PropTypes.bool.isRequired,
  validationFormOpen: PropTypes.bool.isRequired,
  showValidationForm: PropTypes.func.isRequired,
  kycValidationSendMessage: PropTypes.string.isRequired,
  clearKycValidationSendMessage: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    kycStatus: state.profile.permissions.kycStatus,
    currency: state.profile.permissions.currency,
    isBankAccountExists: state.profile.permissions.isBankAccountExists,
    balance: state.profile.balance,
    validationFormOpen: state.profile.kycValidationFormOpen,
    kycValidationSendMessage: state.profile.kycValidationSendMessage
  }),
  {
    showValidationForm: showKycValidationForm,
    clearKycValidationSendMessage: clearValidationSendMessage
  }
)(WalletBalance);
