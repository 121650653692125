import React from "react";
import { Route, Switch } from "react-router-dom";

import WebsiteManagementLayout from "../layout/WebsiteManagementLayout";
import TermsAndConditions from "../Admin/WebsiteManagement/TermsAndConditions";
import AboutUs from "../Admin/WebsiteManagement/AboutUs";
import PrivacyPolicy from "../Admin/WebsiteManagement/PrivacyPolicy";
import CookiePolicy from "../Admin/WebsiteManagement/CookiePolicy";
import PageNotFound from "../PageNotFound/PageNotFound";
import HomePage from "../Admin/WebsiteManagement/HomePage";
import SellingPage from "../Admin/WebsiteManagement/SellingPage";
import ContactUs from "../Admin/WebsiteManagement/ContactUs";
import ResaleDeals from "../Admin/WebsiteManagement/ResaleDeals";
import Blog from "../Admin/WebsiteManagement/Blog";
import FAQ from "../Admin/WebsiteManagement/FAQ";

export const managementRoutes = {
  ABOUT_US: "about-us",
  TERMS_AND_CONDITIONS: "terms-and-conditions",
  PRIVACY_POLICY: "privacy-policy",
  COOKIES_POLICY: "cookie-policy",
  HOME_PAGE: "home-page",
  SELLING_PAGE: "selling-page",
  CONTACT_US: "contact-us",
  RESALE_DEALS: "resale-deals",
  BLOG: "blog",
  FAQ: "faq-page",
}

export const pathComponentMap = {
  [managementRoutes.ABOUT_US]: AboutUs,
  [managementRoutes.TERMS_AND_CONDITIONS]: TermsAndConditions,
  [managementRoutes.PRIVACY_POLICY]: PrivacyPolicy,
  [managementRoutes.COOKIES_POLICY]: CookiePolicy,
  [managementRoutes.HOME_PAGE]: HomePage,
  [managementRoutes.SELLING_PAGE]: SellingPage,
  [managementRoutes.CONTACT_US]: ContactUs,
  [managementRoutes.RESALE_DEALS]: ResaleDeals,
  [managementRoutes.BLOG]: Blog,
  [managementRoutes.FAQ]: FAQ,

}

export const fullPathBuilder = (path) => `/admin/website-management/${path}`;

const routeFactory = (path, component) => (
  <Route path={fullPathBuilder(path)} component={component} />
)

export default function WebsiteManagementRoutes() {
  return (
    <WebsiteManagementLayout>
      <Switch>
        {
          Object.entries(pathComponentMap).map(([path, component]) => routeFactory(path, component))
        }
        <Route component={PageNotFound} />
      </Switch>
    </WebsiteManagementLayout>
  )
}
