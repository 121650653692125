import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import { getDashboardNotificationsRequest } from "../../../redux/admin/dashboard/dashboardActions";

const Notifications = ({ notifications, country, getNotifications }) => {
  useEffect(() => {
    getNotifications({ country });
  }, []);

  useEffect(() => {
    getNotifications({ country });
  }, [country]);

  return (
    <React.Fragment>
      <div className="admin-dashboard__static-section-title">Notifications</div>
      <div className="admin-dashboard__static-section">
        { notifications.map((row, key) => {
          return (
            <Row key={key} justify="space-between" align="middle" className="admin-dashboard__static-section-row">
              <Col span={20}>{row.label}</Col>
              <Col span={4} className="admin-dashboard__static-section-number">{row.value}</Col>
            </Row>
          );
        }) }
      </div>
    </React.Fragment>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
  getNotifications: PropTypes.func.isRequired
};

export default connect(
  state => ({
    notifications: state.admin.dashboard.notifications,
    country: state.admin.dashboard.country
  }),
  {
    getNotifications: getDashboardNotificationsRequest
  }
)(Notifications);
