import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Table, DatePicker, Select, Input } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

import { deleteFeedbackRequest, getFeedbackListRequest } from "../../../redux/admin/feedback/feedbackActions";
import { itemRender } from "../../../helpers/pagination";
import { hideModalAction, showModalAction } from "../../../redux/general/generalActions";

const FeedbackList = ({
  feedbackList,
  getFeedbackList,
  pagination,
  isProcessing,
  error,
  requestSuccess,
  deleteFeedback,
  showModal,
  hideModal
}) => {
  const [paginationObj, setPaginationObj] = useState({
    pageSize: 10,
    sort: "-createdAt"
  });

  useEffect(() => {
    getFeedbackList(paginationObj);
  }, [paginationObj]);

  useEffect(() => {
    hideModal();
  }, [requestSuccess]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedFilterByDate = debounce(date => {
    setPaginationObj({
      ...paginationObj,
      createdAt: date ? moment(date).format("YYYY-MM-DD") : date,
      page: 1
    });
  }, 300);

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderLink = (text, record) => <Link to={`/admin/feedback/${record.key}`} className="feedback-list__link">
    {text}
  </Link>;

  const renderDeleteButton = record => <img
    src="/images/close.svg"
    alt="remove"
    className="feedback-list__delete"
    onClick={() => showModal({
      type: "confirm",
      title: "Are You sure you would like to delete feedback?",
      params: {
        isProcessing,
        error,
        confirmAction: () => deleteFeedback({ id: record.key })
      }
    })}
  />;

  return (
    <React.Fragment>
      <Row className="feedback-list">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" align="top">
                <Col span={18} className="feedback-list__filters">
                  <span className="feedback-list__filter">Show items</span>
                  <Select
                    className="feedback-list__filter"
                    defaultValue={paginationObj.pageSize}
                    onChange={value => {
                      setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
                    }}
                  >
                    <Select.Option value="10">10</Select.Option>
                    <Select.Option value="20">20</Select.Option>
                    <Select.Option value="30">30</Select.Option>
                  </Select>
                  <DatePicker
                    className="feedback-list__filter"
                    format="DD-MM-YYYY"
                    onChange={value => delayedFilterByDate(value)}
                  />
                </Col>
                <Col span={6}>
                  <Input.Search
                    onChange={event => delayedSearch(event.target.value)}
                    onSearch={handleSearch}
                    placeholder="Search"
                    className="feedback-list__search"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isProcessing}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  itemRender: itemRender,
                  showSizeChanger: false
                }}
                dataSource={feedbackList.map(feedback => {
                  return {
                    key: feedback.id,
                    name: `${feedback.firstName} ${feedback.lastName}`,
                    email: feedback.email,
                    message: feedback.message,
                    createdAt: moment(feedback.createdAt).format("DD MMM YYYY, hh:mmA")
                  }
                })}
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                    render: renderLink,
                    width: 150
                  },
                  {
                    title: "Email",
                    dataIndex: "email",
                    key: "email",
                    render: renderLink,
                    width: 200
                  },
                  {
                    title: "Message",
                    dataIndex: "message",
                    key: "message",
                  },
                  {
                    title: "Date/Time",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                    sortDirections: ["descend", "ascend"],
                    width: 145
                  },
                  {
                    title: "",
                    key: "actions",
                    width: 30,
                    render: renderDeleteButton
                  }
                ]}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
};

FeedbackList.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  feedbackList: PropTypes.array.isRequired,
  getFeedbackList: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  deleteFeedback: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.feedback.processing,
    error: state.admin.feedback.error,
    feedbackList: state.admin.feedback.data,
    pagination: {
      current: state.admin.feedback.curPage,
      total: state.admin.feedback.total,
      pageSize: state.admin.feedback.perPage
    },
    requestSuccess: state.admin.feedback.requestSuccess
  }),
  {
    getFeedbackList: getFeedbackListRequest,
    deleteFeedback: deleteFeedbackRequest,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(FeedbackList);
