import React from "react";
import PropTypes from "prop-types";
import { Col, Input, Row } from "antd";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";
import * as yup from "yup";

import Label from "../../shared/Form/Label";
import { changeEmailRequest } from "../../../redux/profile/profileActions";
import useAdditionalAction from "../../../hooks/useAdditionalAction";
import SubmitButton from "../../shared/SubmitButton";

export const ChangeEmail = ({ error: serverError, processing, success, email }) => {
  const ChangeEmailSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required("Please put new email")
      .notOneOf([email], "Please put new email"),
    password: yup
      .string()
      .required("To change the email you must input a password")
      .matches(/^(?=.*\d)(?=.*[A-Z]).{8,255}$/, "Password is incorrect"),
  });

  const { onSubmit, handleChange, getValues, errors, control } = useAdditionalAction({
    validationSchema: ChangeEmailSchema,
    defaultValues: { email },
    action: changeEmailRequest,
    error: serverError,
    success,
  });

  return (
    <div className="additional-service">
      <form autoComplete="off" onSubmit={onSubmit}>
        <Row className="additional-service__row">
          <Col span={24}>
            <div className="input-with-label__field">
              <Label htmlFor="current-password">Email</Label>
              <Controller
                name="email"
                as={
                  <Input
                    id="email"
                    className={`form-input ${serverError.email || errors.email ? "form-input--error" : ""}`}
                    data-testid="email"
                  />
                }
                onChange={handleChange}
                control={control}
              />
              {(serverError.email || errors.email) && (
                <span className="input-with-label__error" data-testid="additional-service-error">
                  {serverError?.email?.message || errors?.email?.message}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="additional-service__row">
          <Col span={24}>
            <div className="input-with-label__field">
              <Label htmlFor="password">Password</Label>
              <Controller
                name="password"
                as={
                  <Input.Password
                    id="password"
                    placeholder="Password"
                    className={`form-input ${serverError.password || errors.password ? "form-input--error" : ""}`}
                    visibilityToggle={true}
                    data-testid="password"
                  />
                }
                onChange={handleChange}
                control={control}
              />
              {(serverError.password || errors.password) && (
                <span className="input-with-label__error" data-testid="additional-service-error">
                  {serverError?.password?.message || errors?.password?.message}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <SubmitButton
              type={success && email === getValues("email") ? "primary" : "default"}
              success={success && email === getValues("email")}
              loading={processing}
              data-testid="additional-service-submit"
              successText="Email Changed"
              text="Change Email"
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

ChangeEmail.propTypes = {
  error: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

export default connect((state) => ({
  ...state.profile.changeEmail,
  email: state.profileDetails.profile.email,
}))(ChangeEmail);
