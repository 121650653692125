import React from "react";

const SocialsLinks = () => {
  return (
    <div className="socials-link-wrapper">
      <a
        href={process.env.REACT_APP_FACEBOOK_URL}
        rel="noopener noreferrer"
        target="_blank"
        className="social-link"
      >
        <img src="/images/facebook.svg" alt="facebook"/>
      </a>
      <a
        href={process.env.REACT_APP_INSTAGRAM_URL}
        rel="noopener noreferrer"
        target="_blank"
        className="social-link"
      >
        <img src="/images/instagram.svg" alt="instagram"/>
      </a>
      <a
        href={process.env.REACT_APP_TWITTER_URL}
        rel="noopener noreferrer"
        target="_blank"
        className="social-link"
      >
        <img src="/images/twitter.svg" alt="twitter"/>
      </a>
    </div>
  )
}

SocialsLinks.propTypes = {
}

export default SocialsLinks;
