import React, { useEffect } from "react";
import { Row, Col, Button, Card } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { getStaticPageRequest, updateStaticPageRequest } from "../../../redux/admin/staticPages/staticPagesActions";
import RichTextEditor from "../../shared/RichTextEditor/RichTextEditor";
import InputWithLabel from "../../shared/InputWithLabel";
import testHtmlLength from "./helpers/testHtmlLength";

const SellingPageSchema = yup.object().shape({
  sellListingTitleLeft: yup.string().trim()
    .test({
      name: "sellListingTitleLeftLength",
      message: "Content can not be empty",
      test: testHtmlLength
    }),
  sellListingTitleRight: yup.string().trim()
    .test({
      name: "sellListingTitleRightLength",
      message: "Content can not be empty",
      test: testHtmlLength
    }),
});

const SellingPage = ({ isProcessing, updatePage, page, getPage, location }) => {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: SellingPageSchema,
  });

  useEffect(() => {
    getPage({ permalink: active });
  }, []);

  useEffect(() => {
    reset(page);
  }, [page]);

  const submitHandler = (formData) => {
    updatePage({
      ...formData,
      permalink: active
    });
  };

  return (
    <Row className="admin-selling-page">
      <Col span={24}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Row className="admin-section">
            <Col span={24}>
              <Row>
                <Col span={24} className="admin-selling-page__title">
                  Edit meta
                </Col>
              </Row>
              <Row gutter={16} className="admin-selling-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaTitle"
                    control={control}
                    id="metaTitle"
                    label="Title"
                    error={errors.metaTitle}
                    placeholder="Title"
                    defaultValue={page.metaTitle}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-selling-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaDescription"
                    control={control}
                    id="metaDescription"
                    label="Description"
                    error={errors.metaDescription}
                    placeholder="Description"
                    defaultValue={page.metaDescription}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-selling-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaKeywords"
                    control={control}
                    id="metaKeywords"
                    label="Keywords"
                    error={errors.metaKeywords}
                    placeholder="Keywords"
                    defaultValue={page.metaKeywords}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="admin-selling-page__title">
                  Edit content
                </Col>
              </Row>
              <Row className="admin-selling-page__row" gutter={16}>
                <Col span={12}>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <InputWithLabel
                        name="sellListingButtonLeftLabel"
                        control={control}
                        id="sellListingButtonLeftLabel"
                        error={errors.sellListingButtonLeftLabel}
                        defaultValue={page.sellListingButtonLeftLabel}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <Controller
                        name="sellListingTitleLeft"
                        as={<RichTextEditor
                          value={page.sellListingTitleLeft}
                        />}
                        defaultValue={page.sellListingTitleLeft}
                        control={control}
                        onChange={([value]) => value}
                      />
                      {errors.sellListingTitleLeft &&
                        <span className="admin-selling-page__error">{errors.sellListingTitleLeft.message}</span>
                      }
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <Controller
                        name="sellListingStepsTitle"
                        as={<RichTextEditor
                          value={page.sellListingStepsTitle}
                        />}
                        defaultValue={page.sellListingStepsTitle}
                        control={control}
                        onChange={([value]) => value}
                      />
                      {errors.sellListingStepsTitle &&
                        <span className="admin-selling-page__error">{errors.sellListingStepsTitle.message}</span>
                      }
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <InputWithLabel
                        name="sellListingStep1"
                        control={control}
                        id="sellListingStep1"
                        error={errors.sellListingStep1}
                        placeholder="Step 1"
                        defaultValue={page.sellListingStep1}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <InputWithLabel
                        name="sellListingStep2"
                        control={control}
                        id="sellListingStep2"
                        error={errors.sellListingStep2}
                        placeholder="Step 2"
                        defaultValue={page.sellListingStep2}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <InputWithLabel
                        name="sellListingStep3"
                        control={control}
                        id="sellListingStep3"
                        error={errors.sellListingStep3}
                        placeholder="Step 3"
                        defaultValue={page.sellListingStep3}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <InputWithLabel
                        name="sellListingStep4"
                        control={control}
                        id="sellListingStep4"
                        error={errors.sellListingStep4}
                        placeholder="Step 4"
                        defaultValue={page.sellListingStep4}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <Controller
                        name="sellListingTitleRight"
                        as={<RichTextEditor
                          value={page.sellListingTitleRight}
                        />}
                        defaultValue={page.sellListingTitleRight}
                        control={control}
                        onChange={([value]) => value}
                      />
                      {errors.sellListingTitleRight &&
                        <span className="admin-selling-page__error">{errors.sellListingTitleRight.message}</span>
                      }
                    </Col>
                  </Row>
                  <Row gutter={16} className="admin-home-page__row">
                    <Col span={24}>
                      <InputWithLabel
                        name="sellListingButtonRightLabel"
                        control={control}
                        id="sellListingButtonRightLabel"
                        error={errors.sellListingButtonRightLabel}
                        defaultValue={page.sellListingButtonRightLabel}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <label className="admin-selling-page__label">"Listing preview" section</label>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={24}>
                  <Controller
                    name="listingPreviewTitle"
                    as={<RichTextEditor
                      value={page.listingPreviewTitle}
                    />}
                    defaultValue={page.listingPreviewTitle}
                    control={control}
                    onChange={([value]) => value}
                  />
                  {errors.listingPreviewTitle &&
                    <span className="admin-selling-page__error">{errors.listingPreviewTitle.message}</span>
                  }
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="listingPreviewSubtitle"
                    control={control}
                    id="listingPreviewSubtitle"
                    error={errors.listingPreviewSubtitle}
                    placeholder="Subtitle"
                    defaultValue={page.listingPreviewSubtitle}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={24}>
                  <Controller
                    name="listingPreviewTitleRight"
                    as={<RichTextEditor
                      value={page.listingPreviewTitleRight}
                    />}
                    defaultValue={page.listingPreviewTitleRight}
                    control={control}
                    onChange={([value]) => value}
                  />
                  {errors.listingPreviewTitleRight &&
                    <span className="admin-selling-page__error">{errors.listingPreviewTitleRight.message}</span>
                  }
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="listingPreviewCreateButtonLabel"
                    control={control}
                    id="listingPreviewCreateButtonLabel"
                    error={errors.listingPreviewCreateButtonLabel}
                    defaultValue={page.listingPreviewCreateButtonLabel}
                  />
                </Col>
              </Row>
              <label className="admin-selling-page__label">"Services we provide" section</label>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={24}>
                  <Controller
                    name="servicesWeProvideTitle"
                    as={<RichTextEditor
                      value={page.servicesWeProvideTitle}
                    />}
                    defaultValue={page.servicesWeProvideTitle}
                    control={control}
                    onChange={([value]) => value}
                  />
                  {errors.servicesWeProvideTitle &&
                    <span className="admin-selling-page__error">{errors.servicesWeProvideTitle.message}</span>
                  }
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="serviceWeProvide1"
                    control={control}
                    id="serviceWeProvide1"
                    error={errors.serviceWeProvide1}
                    defaultValue={page.serviceWeProvide1}
                  />
                </Col>
                <Col span={12}>
                  <InputWithLabel
                    name="serviceWeProvide5"
                    control={control}
                    id="serviceWeProvide5"
                    error={errors.serviceWeProvide5}
                    defaultValue={page.serviceWeProvide5}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="serviceWeProvide2"
                    control={control}
                    id="serviceWeProvide2"
                    error={errors.serviceWeProvide2}
                    defaultValue={page.serviceWeProvide2}
                  />
                </Col>
                <Col span={12}>
                  <InputWithLabel
                    name="serviceWeProvide6"
                    control={control}
                    id="serviceWeProvide6"
                    error={errors.serviceWeProvide6}
                    defaultValue={page.serviceWeProvide6}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="serviceWeProvide3"
                    control={control}
                    id="serviceWeProvide3"
                    error={errors.serviceWeProvide3}
                    defaultValue={page.serviceWeProvide3}
                  />
                </Col>
                <Col span={12}>
                  <InputWithLabel
                    name="serviceWeProvide7"
                    control={control}
                    id="serviceWeProvide7"
                    error={errors.serviceWeProvide7}
                    defaultValue={page.serviceWeProvide7}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="serviceWeProvide4"
                    control={control}
                    id="serviceWeProvide4"
                    error={errors.serviceWeProvide4}
                    defaultValue={page.serviceWeProvide4}
                  />
                </Col>
                <Col span={12}>
                  <InputWithLabel
                    name="serviceWeProvide8"
                    control={control}
                    id="serviceWeProvide8"
                    error={errors.serviceWeProvide8}
                    defaultValue={page.serviceWeProvide8}
                  />
                </Col>
              </Row>
              <label className="admin-selling-page__label">"Fees" section</label>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={24}>
                  <Controller
                    name="feesTitle"
                    as={<RichTextEditor
                      value={page.feesTitle}
                    />}
                    defaultValue={page.feesTitle}
                    control={control}
                    onChange={([value]) => value}
                  />
                  {errors.feesTitle &&
                    <span className="admin-selling-page__error">{errors.feesTitle.message}</span>
                  }
                </Col>
              </Row>
              <Row gutter={24} className="admin-selling-page__row">
                <Col span={12}>
                  <Card title="1st card" bordered={false}>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesFirstCardTitle"
                          control={control}
                          id="feesFirstCardTitle"
                          label="Title"
                          error={errors.feesFirstCardTitle}
                          placeholder="Title"
                          defaultValue={page.feesFirstCardTitle}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesFirstCardSubtitle"
                          control={control}
                          id="feesFirstCardSubtitle"
                          label="Subtitle"
                          error={errors.feesFirstCardSubtitle}
                          placeholder="Subtitle"
                          defaultValue={page.feesFirstCardSubtitle}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesFirstCardLine1"
                          control={control}
                          id="feesFirstCardLine1"
                          label="Line 1"
                          error={errors.feesFirstCardLine1}
                          placeholder="Line 1"
                          defaultValue={page.feesFirstCardLine1}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesFirstCardLine2"
                          control={control}
                          id="feesFirstCardLine2"
                          label="Line 2"
                          error={errors.feesFirstCardLine2}
                          placeholder="Line 2"
                          defaultValue={page.feesFirstCardLine2}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesFirstCardLine3"
                          control={control}
                          id="feesFirstCardLine3"
                          label="Line 3"
                          error={errors.feesFirstCardLine3}
                          placeholder="Line 3"
                          defaultValue={page.feesFirstCardLine3}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesFirstCardNote"
                          control={control}
                          id="feesFirstCardNote"
                          label="Note"
                          error={errors.feesFirstCardNote}
                          placeholder="Note"
                          defaultValue={page.feesFirstCardNote}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="2nd card" bordered={false}>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesSecondCardTitle"
                          control={control}
                          id="feesSecondCardTitle"
                          label="Title"
                          error={errors.feesSecondCardTitle}
                          placeholder="Title"
                          defaultValue={page.feesSecondCardTitle}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesSecondCardLine1"
                          control={control}
                          id="feesSecondCardLine1"
                          label="Line 1"
                          error={errors.feesSecondCardLine1}
                          placeholder="Line 1"
                          defaultValue={page.feesSecondCardLine1}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesSecondCardLine2"
                          control={control}
                          id="feesSecondCardLine2"
                          label="Line 2"
                          error={errors.feesSecondCardLine2}
                          placeholder="Line 2"
                          defaultValue={page.feesSecondCardLine2}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesSecondCardLine3"
                          control={control}
                          id="feesSecondCardLine3"
                          label="Line 3"
                          error={errors.feesSecondCardLine3}
                          placeholder="Line 3"
                          defaultValue={page.feesSecondCardLine3}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesSecondCardLine4"
                          control={control}
                          id="feesSecondCardLine4"
                          label="Line 4"
                          error={errors.feesSecondCardLine4}
                          placeholder="Line 4"
                          defaultValue={page.feesSecondCardLine4}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesSecondCardLine5"
                          control={control}
                          id="feesSecondCardLine5"
                          label="Line 5"
                          error={errors.feesSecondCardLine5}
                          placeholder="Line 5"
                          defaultValue={page.feesSecondCardLine5}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-selling-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="feesSecondCardNote"
                          control={control}
                          id="feesSecondCardNote"
                          label="Note"
                          error={errors.feesSecondCardNote}
                          placeholder="Note"
                          defaultValue={page.feesSecondCardNote}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="admin-selling-page__submit">
              <Button htmlType="submit" disabled={isProcessing} className="button button--primary">Save</Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  )
};

SellingPage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  getPage: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.staticPage.processing,
    error: state.admin.staticPage.error,
    page: state.admin.staticPage.page
  }),
  {
    getPage: getStaticPageRequest,
    updatePage: updateStaticPageRequest
  }
)(SellingPage);
