import * as types from "./staticPagesActionsTypes";

export const getStaticPageRequest = data => {
  return { type: types.ADMIN_GET_STATIC_PAGE_REQUEST, data };
};

export const updateStaticPageRequest = (data) => {
  return { type: types.ADMIN_UPDATE_STATIC_PAGE_REQUEST, data };
};

export const clearStaticPageErrorAction = () => {
  return { type: types.ADMIN_CLEAR_STATIC_PAGE_ERROR };
};
