import { all, put, call, takeLatest } from "redux-saga/effects";

import staticPage from "../../../api/admin/staticPage";
import * as types from "./staticPagesActionsTypes";
import * as generalTypes from "./../../general/generalActionsTypes";
import { MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR } from "../../../react/constants";

function *getStaticPage ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(staticPage.getStaticPage, data);
    yield put({ type: types.ADMIN_GET_STATIC_PAGE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_STATIC_PAGE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateStaticPage ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(staticPage.updateStaticPage, data);
    yield put({ type: types.ADMIN_UPDATE_STATIC_PAGE_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Updated successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_STATIC_PAGE_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_STATIC_PAGE_REQUEST, getStaticPage),
    yield takeLatest(types.ADMIN_UPDATE_STATIC_PAGE_REQUEST, updateStaticPage)
  ])
}
