import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Select, DatePicker, Input, Table, Button } from "antd";
import debounce from "lodash/debounce";
import moment from "moment";

import { getFeeConfigurationsRequest } from "../../../redux/admin/finance/financeActions";
import { getUserListRequest, updateUserRequest, getCsvExportUrlRequest } from "../../../redux/admin/userManagement/userManagementActions";
import { itemRender } from "../../../helpers/pagination";
import { getFeeLabel } from "../../../helpers/common";

const AdminUserList = ({
  getUserList,
  updateUser,
  getFeeConfigurations,
  userList,
  pagination,
  feeConfigurations,
  isProcessing,
  getCsvExportUrl
}) => {
  const [paginationObj, setPaginationObj] = useState({
    country: null,
    pageSize: 10,
    role: null,
    sort: "-createdAt",
    marketing: null
  });

  useEffect(() => {
    getUserList(paginationObj);
  }, [paginationObj]);

  useEffect(() => {
    getFeeConfigurations();
  }, []);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleFilterByRole = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, role: value });
  };

  const handleFilterBySubscription = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, marketing: value });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedFilterByDate = debounce(date => {
    setPaginationObj({
      ...paginationObj,
      createdAt: date ? moment(date).format("YYYY-MM-DD") : date,
      page: 1
    });
  }, 300);

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderLink = (text, record) => (
    <Link to={`/admin/user-management/users/${record.key}`} className="feedback-list__link">
      {text}
    </Link>
  );

  const renderEmailLink = (text) => (
    <a href={`mailto:${text}`} className="feedback-list__link">
      {text}
    </a>
  );

  const renderStatusSelect = (text, record) => (
    <span>
      <Select
        id={`status-${record.key}`}
        disabled={isProcessing || record.status === "terminated"}
        value={text}
        onChange={data => {
          updateUser({ id: record.key, body: { "status": data } });
        }}
      >
        <Select.Option value="active">Active</Select.Option>
        <Select.Option value="suspended">Suspended</Select.Option>
      </Select>
    </span>
  );

  const renderFeeSelect = (text, record) => (
    <span>
      <Select
        id={`feeId-${record.key}`}
        disabled={isProcessing}
        value={text}
        onChange={(data) => {
          updateUser({ id: record.key, body: { feeId: data } });
        }}
      >
        {
          feeConfigurations.map(option => (
            <Select.Option key={`fee-option-${option.id}`} value={option.id}>
              {getFeeLabel(option.id, feeConfigurations)}
            </Select.Option>
          ))
        }
      </Select>
    </span>
  );

  return (
    <React.Fragment>
      <Row className="user-management-list">
        <Col span={24}>
          <Row justify="space-between" align="top">
            <Col span={16} className="feedback-list__filters user-management-list__filters">
              <Select
                className="feedback-list__filter"
                defaultValue={paginationObj.pageSize}
                onChange={value => {
                  setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
                }}
              >
                <Select.Option value={10}>Show 10</Select.Option>
                <Select.Option value={20}>Show 20</Select.Option>
                <Select.Option value={30}>Show 30</Select.Option>
              </Select>
              <Select
                className="feedback-list__filter user-management-list__filter-category"
                defaultValue={paginationObj.role}
                onChange={handleFilterByRole}
              >
                <Select.Option value={null}>Any level</Select.Option>
                <Select.Option value="admin">Admin</Select.Option>
                <Select.Option value="user">User</Select.Option>
              </Select>
              <Select
                className="feedback-list__filter user-management-list__filter-category"
                defaultValue={paginationObj.marketing}
                onChange={handleFilterBySubscription}
              >
                <Select.Option value={null}>All</Select.Option>
                <Select.Option value="subscribed">Subscribed</Select.Option>
                <Select.Option value="not-subscribed">Did not subscribe</Select.Option>
              </Select>
              <Select
                className="feedback-list__filter"
                defaultValue={paginationObj.country}
                onChange={value => {
                  setPaginationObj({ ...paginationObj, page: 1, country: value });
                }}
              >
                <Select.Option value={null}>All</Select.Option>
                <Select.Option value="GB">Great Britain</Select.Option>
                <Select.Option value="US">United States</Select.Option>
              </Select>
              <DatePicker
                className="feedback-list__filter"
                format="DD-MM-YYYY"
                placeholder="Date Registered"
                onChange={value => delayedFilterByDate(value)}
              />
            </Col>
            <Col span={4}>
              <Input.Search
                onChange={event => delayedSearch(event.target.value)}
                onSearch={handleSearch}
                placeholder="Search"
                className="feedback-list__search"
              />
            </Col>
            <Col span={4} className="user-management-list__download-csv">
              <Button
                className="button button--default"
                onClick={() => getCsvExportUrl(paginationObj)}
              >Download .csv</Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isProcessing}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  itemRender: itemRender,
                  showSizeChanger: false
                }}
                dataSource={userList.map(user => {
                  return {
                    key: user.id,
                    name: `${user.firstName} ${user.lastName}`,
                    createdAt: moment(user.createdAt).format("DD MMM YYYY"),
                    status: user.status,
                    email: user.email,
                    listings: user.listings,
                    bookings: user.bookings,
                    feeId: user.feeId,
                    purchasedGbp: (+user.purchasedGbp).toFixed(2),
                    purchasedUsd: (+user.purchasedUsd).toFixed(2),
                    balance: (+user.balance).toFixed(2),
                    promocode: user.promocode,
                    currency: user.currency
                  }
                })}
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                    render: renderLink,
                    width: 150
                  },
                  {
                    title: "Registered",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                    sortDirections: ["descend", "ascend"],
                    width: 145
                  },
                  {
                    title: "Code/Promotion",
                    dataIndex: "promocode",
                    key: "promocode",
                    sorter: true
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    defaultSortOrder: "",
                    sorter: true,
                    sortDirections: ["descend", "ascend"],
                    className: "user-management-list__item-status",
                    render: renderStatusSelect
                  },
                  {
                    title: "Email",
                    dataIndex: "email",
                    key: "email",
                    render: renderEmailLink,
                    width: 200
                  },
                  {
                    title: "Listings",
                    dataIndex: "listings",
                    key: "listings",
                    sorter: true
                  },
                  {
                    title: "Bookings",
                    dataIndex: "bookings",
                    key: "bookings",
                    sorter: true
                  },
                  {
                    title: "Fee",
                    dataIndex: "feeId",
                    key: "feeId",
                    className: "user-management-list__item-fee",
                    sorter: true,
                    render: renderFeeSelect
                  },
                  {
                    title: "Purchased, £",
                    dataIndex: "purchasedGbp",
                    key: "purchasedGbp",
                    sorter: true
                  },
                  {
                    title: "Purchased, $",
                    dataIndex: "purchasedUsd",
                    key: "purchasedUsd",
                    sorter: true
                  },
                  {
                    title: "Balance",
                    dataIndex: "balance",
                    key: "balance",
                    sorter: true,
                    render: (value, record) => `${record.currency === "USD" ? "$" : "£"} ${value}`
                  }
                ]}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
};

AdminUserList.propTypes = {
  userList: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  feeConfigurations: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  getUserList: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getFeeConfigurations: PropTypes.func.isRequired,
  getCsvExportUrl: PropTypes.func.isRequired
};

export default connect(
  state => ({
    userList: state.admin.userManagement.data,
    pagination: {
      current: state.admin.userManagement.curPage,
      total: state.admin.userManagement.total,
      pageSize: state.admin.userManagement.perPage
    },
    feeConfigurations: state.admin.finance.feeConfigurations,
    isProcessing: state.admin.userManagement.processing,
    error: state.admin.userManagement.error,
  }),
  {
    getUserList: getUserListRequest,
    updateUser: updateUserRequest,
    getFeeConfigurations: getFeeConfigurationsRequest,
    getCsvExportUrl: getCsvExportUrlRequest
  }
)(AdminUserList);
