export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_DASHBOARD_LISTINGS_REQUEST = "ADMIN_GET_DASHBOARD_LISTINGS_REQUEST";
export const ADMIN_GET_DASHBOARD_LISTINGS_REQUEST_SUCCESS = "ADMIN_GET_DASHBOARD_LISTINGS_REQUEST_SUCCESS";
export const ADMIN_GET_DASHBOARD_LISTINGS_REQUEST_ERROR = "ADMIN_GET_DASHBOARD_LISTINGS_REQUEST_ERROR";

export const ADMIN_GET_DASHBOARD_USERS_REQUEST = "ADMIN_GET_DASHBOARD_USERS_REQUEST";
export const ADMIN_GET_DASHBOARD_USERS_REQUEST_SUCCESS = "ADMIN_GET_DASHBOARD_USERS_REQUEST_SUCCESS";
export const ADMIN_GET_DASHBOARD_USERS_REQUEST_ERROR = "ADMIN_GET_DASHBOARD_USERS_REQUEST_ERROR";

export const ADMIN_GET_DASHBOARD_DISPUTES_REQUEST = "ADMIN_GET_DASHBOARD_DISPUTES_REQUEST";
export const ADMIN_GET_DASHBOARD_DISPUTES_REQUEST_SUCCESS = "ADMIN_GET_DASHBOARD_DISPUTES_REQUEST_SUCCESS";
export const ADMIN_GET_DASHBOARD_DISPUTES_REQUEST_ERROR = "ADMIN_GET_DASHBOARD_DISPUTES_REQUEST_ERROR";

export const ADMIN_GET_DASHBOARD_FEES_REQUEST = "ADMIN_GET_DASHBOARD_FEES_REQUEST";
export const ADMIN_GET_DASHBOARD_FEES_REQUEST_SUCCESS = "ADMIN_GET_DASHBOARD_FEES_REQUEST_SUCCESS";
export const ADMIN_GET_DASHBOARD_FEES_REQUEST_ERROR = "ADMIN_GET_DASHBOARD_FEES_REQUEST_ERROR";

export const ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST = "ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST";
export const ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST_SUCCESS = "ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST_SUCCESS";
export const ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST_ERROR = "ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST_ERROR";

export const ADMIN_GET_DASHBOARD_FUNDS_REQUEST = "ADMIN_GET_DASHBOARD_FUNDS_REQUEST";
export const ADMIN_GET_DASHBOARD_FUNDS_REQUEST_SUCCESS = "ADMIN_GET_DASHBOARD_FUNDS_REQUEST_SUCCESS";
export const ADMIN_GET_DASHBOARD_FUNDS_REQUEST_ERROR = "ADMIN_GET_DASHBOARD_FUNDS_REQUEST_ERROR";

export const ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST = "ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST";
export const ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST_SUCCESS = "ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST_SUCCESS";
export const ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST_ERROR = "ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST_ERROR";

export const ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST = "ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST";
export const ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST_SUCCESS = "ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST_SUCCESS";
export const ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST_ERROR = "ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST_ERROR";

export const ADMIN_UPDATE_COUNTRY_VIEW_ACTION = "ADMIN_UPDATE_COUNTRY_VIEW_ACTION";
