import React from "react";
import { Modal as AntModal } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import loadable from "react-loadable-visibility/loadable-components";

import { hideModalAction } from "../../../redux/general/generalActions";
import {
  MODAL_TYPE_ACCOUNT_VERIFICATION,
  MODAL_TYPE_CONFIRM,
  MODAL_TYPE_ERROR,
  MODAL_TYPE_FAQ_PREVIEW,
  MODAL_TYPE_INFO,
  MODAL_TYPE_KYC_FAILURE_REASONS,
  MODAL_TYPE_SUCCESS,
  MODAL_TYPE_LISTING_AMENITIES,
  MODAL_BOOK_NOT_LOGGED,
  MODAL_TYPE_LISTING_CARD_DETAILS,
  MODAL_TYPE_LISTING_OFFER_PRICE,
  MODAL_TYPE_LISTING_OFFER_PRICE_NOT_LOGGED, MODAL_TYPE_LISTING_PUBLISH_PAYMENT
} from "../../constants";
const StatusModalBody = loadable(() => import("./StatusModalBody"), { ssr: true });
const ConfirmModalBody = loadable(() => import("./ConfirmModalBody"), { ssr: true });
const InfoModalBody = loadable(() => import("./InfoModalBody"), { ssr: true });
const FaqPreviewModalBody = loadable(() => import("./FaqPreviewModalBody"), { ssr: true });
const KycFailureReasonsModalBody = loadable(() => import("./KycFailureReasonsModalBody"), { ssr: true });
const AccountVerificationModalBody = loadable(() => import("./AccountVerificationModalBody"), { ssr: true });
const ListingAmenitiesModalBody = loadable(() => import("./ListingAmenitiesModalBody"), { ssr: true });
const BookNowNotLoggedBodyModal = loadable(() => import("./BookNowNotLoggedBodyModal"), { ssr: true });
const ListingCardDetailsModalBody = loadable(() => import("./ListingCardDetailsModalBody"), { ssr: true });
const OfferListingPriceNotLoggedBodyModal = loadable(() => import("./OfferListingPriceNotLoggedBodyModal"), { ssr: true });
const ListingOfferPriceModalBody = loadable(() => import("./ListingOfferPriceModalBody"), { ssr: true });
const ListingPublishPaymentModalBody = loadable(() => import("./ListingPublishPaymentModalBody"), { ssr: true });

const Modal = ({ isModalHidden, hideModal, className, type, width, title, params }) => {
  let modalBody = "";

  switch (type) {
    case MODAL_TYPE_SUCCESS:
    case MODAL_TYPE_ERROR:
      modalBody = <StatusModalBody type={type} title={title} {...params} />;
      break;
    case MODAL_TYPE_CONFIRM:
      modalBody = <ConfirmModalBody type={type} title={title} {...params} />;
      break;
    case MODAL_TYPE_INFO:
      modalBody = <InfoModalBody type={type} title={title} {...params} />;
      break;
    case MODAL_TYPE_FAQ_PREVIEW:
      modalBody = <FaqPreviewModalBody type={type} {...params} />;
      break;
    case MODAL_TYPE_KYC_FAILURE_REASONS:
      modalBody = <KycFailureReasonsModalBody type={type} {...params} />;
      break;
    case MODAL_TYPE_ACCOUNT_VERIFICATION:
      modalBody = <AccountVerificationModalBody type={type} {...params} />;
      break;
    case MODAL_TYPE_LISTING_AMENITIES:
      modalBody = <ListingAmenitiesModalBody onCancel={hideModal} {...params} />;
      break;
    case MODAL_BOOK_NOT_LOGGED:
      modalBody = <BookNowNotLoggedBodyModal onCancel={hideModal} {...params} />;
      break;
    case MODAL_TYPE_LISTING_CARD_DETAILS:
      modalBody = <ListingCardDetailsModalBody type={type} {...params} />;
      break;
    case MODAL_TYPE_LISTING_OFFER_PRICE_NOT_LOGGED:
      modalBody = <OfferListingPriceNotLoggedBodyModal type={type} {...params} />;
      break;
    case MODAL_TYPE_LISTING_OFFER_PRICE:
      modalBody = <ListingOfferPriceModalBody type={type} {...params} />;
      break;
    case MODAL_TYPE_LISTING_PUBLISH_PAYMENT:
      modalBody = <ListingPublishPaymentModalBody onCancel={hideModal} type={type} {...params} />;
      break;
  }

  return (
    <AntModal
      visible={!isModalHidden}
      onCancel={hideModal}
      closable={false}
      footer={null}
      className={`modal modal--${type}${className ? ` ${className}` : ""}`}
      centered={true}
      destroyOnClose={true}
      width={width || 350}
    >
      {modalBody}
    </AntModal>
  );
};

Modal.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  isModalHidden: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.object
};

export default connect(
  state => ({
    type: state.general.modal.type,
    width: state.general.modal.width,
    className: state.general.modal.className,
    title: state.general.modal.title,
    isModalHidden: state.general.modal.isHidden,
    params: state.general.modal.params
  }),
  {
    hideModal: hideModalAction
  }
)(Modal);
