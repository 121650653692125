import * as types from "./listingActionsTypes";

export const getHotelListRequest = (data) => {
  return { type: types.GET_HOTEL_LIST_REQUEST, data };
};

export const getHotelRequest = (data) => {
  return { type: types.GET_HOTEL_REQUEST, data };
};

export const createListingRequest = (data) => {
  return { type: types.CREATE_LISTING_REQUEST, data };
};

export const sendListingToApprovalRequest = (data) => {
  return { type: types.SEND_LISTING_TO_APPROVAL_REQUEST, data };
};

export const getListingRequest = (data) => {
  return { type: types.GET_LISTING_REQUEST, data };
};

export const updateListingRequest = (data) => {
  return { type: types.UPDATE_LISTING_REQUEST, data };
};

export const deleteListingRequest = (data) => {
  return { type: types.DELETE_LISTING_REQUEST, data };
};

export const getListingListRequest = (data) => {
  return { type: types.GET_LISTING_LIST_REQUEST, data };
};

export const getMyPurchasesListRequest = (data) => {
  return { type: types.GET_MY_PURCHASES_LIST_REQUEST, data };
};

export const getSellerListingListRequest = (data) => {
  return { type: types.GET_MY_LISTINGS_REQUEST, data };
};

export const startBookingListingRequest = (data) => {
  return { type: types.START_BOOKING_LISTING_REQUEST, data };
};

export const endBookingListingRequest = (data) => {
  return { type: types.END_BOOKING_LISTING_REQUEST, data };
};

export const purchaseOrganicListingRequest = (action, listingId, data) => {
  return { type: types.PURCHASE_ORGANIC_LISTING_REQUEST, action, listingId, data };
};

export const purchaseB2BListingRequest = (data, callBack) => {
  return { type: types.PURCHASE_B2B_LISTING_REQUEST, data, callBack };
};

export const createListingDisputeRequest = (data) => {
  return { type: types.CREATE_LISTING_DISPUTE_REQUEST, data };
};

export const getAttachmentsUrlRequest = (data) => {
  return { type: types.GET_ATTACHMENTS_URL_REQUEST, data };
};

export const updateListingActivityRequest = (data) => {
  return { type: types.UPDATE_LISTING_ACTIVITY_REQUEST, data };
};

export const getBestDealsRequest = (data) => {
  return { type: types.GET_BEST_DEALS_REQUEST, data };
};

export const cancelBookingRequest = (data) => {
  return { type: types.CANCEL_BOOKING_REQUEST, data };
};

export const addCardToListingRequest = (data, callBack) => {
  return { type: types.ADD_CARD_TO_LISTING_REQUEST, data, callBack };
};

export const createListingPriceOfferRequest = (data) => {
  return { type: types.CREATE_LISTING_PRICE_OFFER_REQUEST, data };
};

export const declineListingPriceOfferRequest = (data, callBack) => {
  return { type: types.DECLINE_LISTING_PRICE_OFFER_REQUEST, data, callBack };
};

export const acceptListingPriceOfferRequest = (data, callBack) => {
  return { type: types.ACCEPT_LISTING_PRICE_OFFER_REQUEST, data, callBack };
};

export const setNewListingData = (data) => {
  return { type: types.SET_NEW_LISTING, data };
};

export const clearListingErrorAction = () => {
  return { type: types.CLEAR_LISTING_ERROR };
};

export const setListingsListAction = (data) => {
  return { type: types.SET_LISTING_LIST, data };
};

export const cancelListingsSearchAction = (data) => {
  return { type: types.CANCEL_LISTINGS_SEARCH, data };
};

export const setLastSearchParams = (data) => {
  return { type: types.SET_LAST_SEARCH_PARAMS, data };
};

export const setShowCardDetailsModalAction = (data) => {
  return { type: types.SET_SHOW_CARD_DETAILS_MODAL, data };
};
