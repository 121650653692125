import * as types from "./profileActionsTypes";
import * as authTypes from "./../auth/authActionsTypes";
import * as profileTypes from "./../profileDetails/profileDetailsActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  isProfileClosed: false,
  accountVerificationSuccess: false,
  accountVerification3DSUrl: "",
  verificationAddressNotMatch: false,
  kycValidationFormOpen: false,
  kycValidationSendMessage: "",
  changePassword: {
    error: "",
    processing: false,
    success: false
  },
  changeEmail: {
    error: {},
    processing: false,
    success: false
  },
  permissions: {
    cardVerificationStatus: "",
    kycStatus: "",
    isProfileFilled: true,
    isBankAccountExists: true,
    role: "",
    fee: 0,
    vat: 0,
    currency: "",
    exchangeRates: [],
    canCreateListing: true,
    createdListingsCount: 0,
    paymentConfig: {
      accountVerification: 0,
      listingPublish: 0
    }
  },
  bankAccounts: {
    list: [],
    error: "",
    errors: {},
    success: false
  },
  balance: {
    available: 0,
    pending: 0
  },
  transactions: {
    total: 0,
    curPage: 1,
    pages: 1,
    perPage: 10,
    data: []
  },
  paymentIntent: {
    clientSecret: "",
  }
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false
      };
    case types.ACCOUNT_VERIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        accountVerificationSuccess: true,
        accountVerification3DSUrl: "",
        error: ""
      };
    case types.ACCOUNT_VERIFICATION_REQUEST_3DS_REQUIRED:
      return {
        ...state,
        accountVerification3DSUrl: action.response.redirectUrl,
        error: ""
      };
    case types.ACCOUNT_VERIFICATION_REQUEST_ERROR:
      return {
        ...state,
        accountVerificationSuccess: false,
        accountVerification3DSUrl: "",
        verificationAddressNotMatch: action.data ? action.data.addressNotMatch : false,
        error: action.error
      };
    case types.KYC_VERIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        kycValidationFormOpen: false,
        kycValidationSendMessage: "The form was successfully submitted"
      };
    case types.KYC_VERIFICATION_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.PROFILE_PERMISSIONS_REQUEST_SUCCESS:
      return {
        ...state,
        permissions: {
          ...action.response,
          fee: parseFloat(action.response.fee),
          vat: parseFloat(action.response.vat)
        }
      };
    case profileTypes.UPDATE_USER_REQUEST_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isProfileFilled: true
        }
      };
    case types.PROFILE_PERMISSIONS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.CLEAR_PROFILE_ERROR:
      return {
        ...state,
        error: "",
        isProfileClosed: false,
        changePassword: {
          ...state.changePassword,
          error: "",
        },
        bankAccounts: {
          ...state.bankAccounts,
          error: "",
          errors: {}
        },
        changeEmail: {
          ...state.changeEmail,
          error: {}
        }
      };
    case types.CHANGE_PASSWORD_REQUEST_PROCESSING:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          processing: true,
        }
      };
    case types.CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        changePassword: {
          error: "",
          processing: false,
          success: true,
        }
      };
    case types.CHANGE_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        changePassword: {
          error: action.error,
          processing: false,
          success: false
        }
      };
    case types.CHANGE_EMAIL_REQUEST_PROCESSING:
      return {
        ...state,
        changeEmail: {
          ...state.changeEmail,
          processing: true,
        }
      };
    case types.CHANGE_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        changeEmail: {
          error: {},
          processing: false,
          success: true
        }
      };
    case types.CHANGE_EMAIL_REQUEST_ERROR:
      return {
        ...state,
        changeEmail: {
          error: action.data,
          processing: false,
          success: false
        }
      };
    case types.CLOSE_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        isProfileClosed: true,
        error: ""
      };
    case types.CLOSE_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.CREATE_BANK_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          error: "",
          errors: {},
          success: true
        }
      };
    case types.CREATE_BANK_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          error: action.error,
          errors: action.errors
        }
      };
    case types.GET_BANK_ACCOUNTS_REQUEST_SUCCESS:
      return {
        ...state,
        bankAccounts: {
          list: action.response,
          error: "",
          errors: {},
          success: false
        }
      };
    case types.GET_BANK_ACCOUNTS_REQUEST_ERROR:
      return {
        ...state,
        bankAccounts: {
          list: [],
          error: action.error,
          errors: {},
          success: false
        }
      };
    case types.DELETE_BANK_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          error: "",
          errors: {},
          success: true
        }
      };
    case types.DELETE_BANK_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          error: action.error,
          errors: {}
        }
      };
    case types.UPDATE_BANK_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          error: "",
          errors: {},
          success: true
        }
      };
    case types.UPDATE_BANK_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          error: action.error,
          errors: {}
        }
      };
    case types.GET_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        balance: action.response
      };
    case types.GET_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        balance: state.balance
      };
    case types.WITHDRAW_MONEY_REQUEST_SUCCESS:
      return {
        ...state,
        balance: action.response
      };
    case types.WITHDRAW_MONEY_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.GET_TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        transactions: action.response
      };
    case types.GET_TRANSACTIONS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.SHOW_KYC_VALIDATION_FORM:
      return {
        ...state,
        kycValidationFormOpen: action.payload
      };
    case types.CLEAR_VALIDATION_SEND_MESSAGE:
      return {
        ...state,
        kycValidationSendMessage: "",
      };
    case authTypes.SIGN_OUT_REQUEST_SUCCESS:
      return initialState;
    case types.CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST_SUCCESS:
      return {
        ...state,
        paymentIntent: action.response
      };
    case types.CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default profile;
