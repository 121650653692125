export const SET_REQUEST_TOKEN = "SET_REQUEST_TOKEN";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST = "SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST";
export const SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST_SUCCESS = "SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST_SUCCESS";
export const SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST_ERROR = "SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST_ERROR";

export const CLEAR_SUBSCRIBED_STATUS_STATE = "CLEAR_SUBSCRIBED_STATUS_STATE";
export const CLEAR_STATE = "CLEAR_STATE";
