import * as types from "./feedbackActionsTypes";

export const getFeedbackListRequest = data => {
  return { type: types.ADMIN_GET_FEEDBACK_LIST_REQUEST, data };
};

export const deleteFeedbackRequest = data => {
  return { type: types.ADMIN_DELETE_FEEDBACK_REQUEST, data };
};

export const getFeedbackRequest = data => {
  return { type: types.ADMIN_GET_FEEDBACK_REQUEST, data };
};
