export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_USER_LIST_REQUEST = "ADMIN_GET_USER_LIST_REQUEST";
export const ADMIN_GET_USER_LIST_REQUEST_SUCCESS = "ADMIN_GET_USER_LIST_REQUEST_SUCCESS";
export const ADMIN_GET_USER_LIST_REQUEST_ERROR = "ADMIN_GET_USER_LIST_REQUEST_ERROR";

export const ADMIN_GET_USER_REQUEST = "ADMIN_GET_USER_REQUEST";
export const ADMIN_GET_USER_REQUEST_SUCCESS = "ADMIN_GET_USER_REQUEST_SUCCESS";
export const ADMIN_GET_USER_REQUEST_ERROR = "ADMIN_GET_USER_REQUEST_ERROR";

export const ADMIN_UPDATE_USER_REQUEST = "ADMIN_UPDATE_USER_REQUEST";
export const ADMIN_UPDATE_USER_REQUEST_SUCCESS = "ADMIN_UPDATE_USER_REQUEST_SUCCESS";
export const ADMIN_UPDATE_USER_REQUEST_ERROR = "ADMIN_UPDATE_USER_REQUEST_ERROR";

export const ADMIN_DOWNLOAD_KYC_FILE_REQUEST = "ADMIN_DOWNLOAD_KYC_FILE_REQUEST";
export const ADMIN_DOWNLOAD_KYC_FILE_REQUEST_SUCCESS = "ADMIN_DOWNLOAD_KYC_FILE_REQUEST_SUCCESS";
export const ADMIN_DOWNLOAD_KYC_FILE_REQUEST_ERROR = "ADMIN_DOWNLOAD_KYC_FILE_REQUEST_ERROR";

export const ADMIN_GET_USER_LISTINGS_REQUEST = "ADMIN_GET_USER_LISTINGS_REQUEST";
export const ADMIN_GET_USER_LISTINGS_REQUEST_SUCCESS = "ADMIN_GET_USER_LISTINGS_REQUEST_SUCCESS";
export const ADMIN_GET_USER_LISTINGS_REQUEST_ERROR = "ADMIN_GET_USER_LISTINGS_REQUEST_ERROR";

export const ADMIN_CLEAR_USER = "ADMIN_CLEAR_USER";
export const ADMIN_CLEAR_USER_KYC_FILE = "ADMIN_CLEAR_USER_KYC_FILE";
export const ADMIN_CLEAR_USER_ERROR = "ADMIN_CLEAR_USER_ERROR";

export const ADMIN_GET_USERS_CSV_EXPORT_URL_REQUEST = "ADMIN_GET_USERS_CSV_EXPORT_URL_REQUEST";
