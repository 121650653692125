import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const listings = {
  async getListings(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/listings?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getCsvExportUrl(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/listings/export-csv?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateListing(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/listings/${data.id}`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default listings;
