export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_REQUEST_ERROR = "SIGN_IN_REQUEST_ERROR";
export const SIGN_IN_REQUEST_SUCCESS = "SIGN_IN_REQUEST_SUCCESS";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_REQUEST_ERROR = "SIGN_UP_REQUEST_ERROR";
export const SIGN_UP_REQUEST_SUCCESS = "SIGN_UP_REQUEST_SUCCESS";

export const RESEND_ACTIVATION_EMAIL_REQUEST = "RESEND_ACTIVATION_EMAIL_REQUEST";
export const RESEND_ACTIVATION_EMAIL_REQUEST_ERROR = "RESEND_ACTIVATION_EMAIL_REQUEST_ERROR";
export const RESEND_ACTIVATION_EMAIL_REQUEST_SUCCESS = "RESEND_ACTIVATION_EMAIL_REQUEST_SUCCESS";

export const SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST";
export const SIGN_OUT_REQUEST_SUCCESS = "SIGN_OUT_REQUEST_SUCCESS";
export const SIGN_OUT_REQUEST_ERROR = "SIGN_OUT_REQUEST_ERROR";

export const VERIFY_ACCOUNT_REQUEST = "VERIFY_ACCOUNT_REQUEST";
export const VERIFY_ACCOUNT_REQUEST_SUCCESS = "VERIFY_ACCOUNT_REQUEST_SUCCESS";
export const VERIFY_ACCOUNT_REQUEST_ERROR = "VERIFY_ACCOUNT_REQUEST_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_ERROR = "RESET_PASSWORD_REQUEST_ERROR";

export const SET_NEW_PASSWORD_REQUEST = "SET_NEW_PASSWORD_REQUEST_REQUEST";
export const SET_NEW_PASSWORD_REQUEST_SUCCESS = "SET_NEW_PASSWORD_REQUEST_REQUEST_SUCCESS";
export const SET_NEW_PASSWORD_REQUEST_ERROR = "SET_NEW_PASSWORD_REQUEST_REQUEST_ERROR";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const CLEAR_RESET_PASSWORD_EMAIL_STATUS = "CLEAR_RESET_PASSWORD_EMAIL_STATUS";
export const CLEAR_SET_NEW_PASSWORD_STATUS = "CLEAR_SET_NEW_PASSWORD_STATUS";
export const CLEAR_VERIFY_ACCOUNT_STATUS = "CLEAR_VERIFY_ACCOUNT_STATUS";
