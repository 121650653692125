import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

import SocialsLinks from "./SocialsLinks";
import Subscribe from "./Subscribe/Subscribe";

const Footer = ({ className }) => {
  return (
    <Row justify="space-around" align="middle" className={`footer${className ? ` ${className}` : ""}`}>
      <Col xs={{ span: 24 }} md={{ span: 20 }} className="footer__content">
        <Row>
          <Col span={24}>
            <Link to="/">
              <img src="/images/logo.svg" alt="PlansChange" className="footer__logo" />
            </Link>
          </Col>
        </Row>
        <Row justify="space-around" align="start" className="footer__middle">
          <Col xs={{ span: 24, order: 1 }} md={{ span: 14 }} lg={{ span: 5 }}>
            <div className="footer__nav-link-container">
              <Link to="/seller/wallet" className="footer__nav-link">Seller Account</Link>
              <Link to="/legal/privacy-policy" className="footer__nav-link">Privacy Policy</Link>
              <Link to="/legal/terms-and-conditions" className="footer__nav-link">Terms & Conditions</Link>
              <Link to="/faq" className="footer__nav-link">FAQs</Link>
            </div>
          </Col>
          <Col xs={{ span: 24, order: 3 }} md={{ span: 10, order: 2 }} lg={{ span: 5 }}>
            <div className="footer__nav-link-container">
              <Link to="/about-us" className="footer__nav-link">About Us</Link>
              <Link to="/selling" className="footer__nav-link">Selling: How It Works</Link>
              <Link to="/contact-us" className="footer__nav-link">Contact Us</Link>
              <Link to="/blog" className="footer__nav-link">Blog</Link>
            </div>
          </Col>
          <Col xs={{ span: 24, order: 2 }} md={{ span: 14, order: 3 }} lg={{ span: 9 }} className="footer__subscribe">
            <div className="footer__title">
              Sign Up to get the best deals
            </div>
            <Subscribe/>
          </Col>
          <Col xs={{ span: 24, order: 4 }} md={{ span: 10 }} lg={{ span: 5 }}>
            <div className="footer__title">
              Get Social with us!
            </div>
            <SocialsLinks/>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" className="footer__bottom">
          <Col span={24} className="footer__copyright">
            &copy; {new Date().getFullYear()} Loss2Gain Limited
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
