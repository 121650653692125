import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "./ReactQuill";

const RichTextEditor = ({ onChange, value = "", style = {} }) => {
  return (
    <ReactQuill
      modules={{
        toolbar: [
          [{ "font": [] }, { "size": [] }],
          [{ "header": [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"],
          [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "#3D4CC1", "#F75497"] }, { "background": [] }],
          [{ "list": "ordered" }, { "list": "bullet" }, { "align": [] }],
          ["link", "image"],
        ]
      }}
      style={style}
      value={value}
      onChange={onChange}
      className="rich-text-editor"
    />
  )
};

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  style: PropTypes.object
};

export default RichTextEditor;
