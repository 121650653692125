import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import moment from "moment";
import * as queryString from "query-string";

import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import {
  acceptListingPriceOfferRequest,
  declineListingPriceOfferRequest,
  getListingRequest,
} from "../../redux/listing/listingActions";
import ListingView from "../shared/ListingView";
import ListingViewLoading from "../shared/ListingViewLoading"
import Loader from "../shared/Loader";
import { createHotelRoomsTextsArray } from "../../helpers/common";
import { getHotelSchemaScriptContent } from "../../helpers/schema";
import { MODAL_TYPE_LISTING_PUBLISH_PAYMENT } from "../constants";
import { showModalAction } from "../../redux/general/generalActions";

const PurchasingListing = ({
  isAuthenticated,
  listing,
  match,
  history,
  location,
  getListing,
  declineListingPriceOffer,
  acceptListingPriceOffer,
  showModal,
}) => {
  const { slug } = match.params;
  const {
    type, checkInDate, checkOutDate, adults, children, childrenAge, rooms, accessToken = "", offerId = null,
    action = ""
  } = queryString.parse(location.search, { arrayFormat: "bracket" });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useServerSafeEffect(() => {
    slug && getListing({ slug, checkInDate, checkOutDate, adults, children, childrenAge, rooms, accessToken });
  }, [slug]);

  useEffect(() => {
    if (listing.id && offerId) {
      if (isAuthenticated) {
        switch (action) {
          case "decline":
            declineListingPriceOffer({
              listingId: listing.id,
              offerId: offerId
            }, () => {
              history.push(`/listings/${listing.slug}`);
            });
            break;
          case "accept":
            acceptListingPriceOffer({
              listingId: listing.id,
              offerId: offerId
            }, () => {
              history.push(`/listings/${listing.slug}`);
            });
            break;
        }
      } else {
        history.push(
          "/auth/sign-in",
          { from: { pathname: `/listings/${listing.slug}`, search: location.search } }
        );
      }
    }
  }, [action, offerId, listing.id]);

  const hotelAddress = listing.Hotel && listing.Hotel.HotelAddress ? listing.Hotel.HotelAddress : {};
  const metaDescription = `${[
    ...createHotelRoomsTextsArray(listing.rooms, listing.adults, listing.children),
    ...(listing.facilities || []),
    (listing.facilitiesOther ? listing.facilitiesOther : null),
    (listing.type === "organic" ? listing.roomType !== "other" ? listing.roomType : null : null),
    (listing.roomTypeOther ? listing.roomTypeOther : null)
  ].filter(item => !!item).join(" • ")}. ${[
    hotelAddress.countryName,
    hotelAddress.cityName,
    hotelAddress.addressLine,
    hotelAddress.postalCode
  ].join(", ")}`;

  return listing.id
    ? (
      <React.Fragment>
        <Helmet>
          <title>
            {listing.Hotel.name} booking on {moment(listing.checkInDate).format("DD MMM YYYY")} - {moment(listing.checkOutDate).format("DD MMM YYYY")} | PlansChange
          </title>
          <meta name="description" content={metaDescription} />
          <meta name="robots" content="index" />
          <meta name="twitter:card" content="summary_large_image"/>
          <meta property="og:url" content={`${process.env.REACT_APP_URL}/listings/${listing.slug}`} />
          <meta property="og:title" content={listing.Hotel.name} />
          <meta property="og:description" content={type === "created" ? `
            My #Travel plans have changed at short notice. 
            My non-refundable #Hotel room at ${listing.Hotel.name} on ${moment(listing.checkInDate).format("DD MMM YYYY")} - ${moment(listing.checkOutDate).format("DD MMM YYYY")} can now be resold and booked on the new platform from @planschangecom at a big discount.
            Link to booking here ${process.env.REACT_APP_URL}/listings/${listing.slug}
          ` : metaDescription} />
          { listing.Hotel.HotelImages && !!listing.Hotel.HotelImages.length &&
            <meta property="og:image" content={listing.Hotel.HotelImages[0].imageUrl}/>
          }
          { typeof window === "undefined" &&
            <script type="application/ld+json">{getHotelSchemaScriptContent(listing)}</script>
          }
        </Helmet>
        <ListingView
          listing={listing}
          hotel={listing.Hotel}
          hotelFacilities={listing.Hotel && listing.Hotel.facilities}
          listingFacilities={listing.facilities}
          listingRoomType={listing.roomType}
          isPreview={false}
          push={history.push}
          publishListing={() => {
            showModal({
              type: MODAL_TYPE_LISTING_PUBLISH_PAYMENT,
              width: 700,
              params: {
                listing
              }
            });
          }}
        />
      </React.Fragment>
    )
    : (
      <React.Fragment>
        <Loader type="page" extraClass="listing__loader"/>
        <ListingViewLoading/>
      </React.Fragment>
    )
};

PurchasingListing.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  listing: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getListing: PropTypes.func.isRequired,
  declineListingPriceOffer: PropTypes.func.isRequired,
  acceptListingPriceOffer: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    listing: state.listing.singleListing
  }),
  {
    getListing: getListingRequest,
    declineListingPriceOffer: declineListingPriceOfferRequest,
    acceptListingPriceOffer: acceptListingPriceOfferRequest,
    showModal: showModalAction,
  }
)(PurchasingListing);
