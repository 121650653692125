import React, { useState } from "react";
import { DatePicker as AntDatePicker } from "antd";
import PropTypes from "prop-types";
import moment from "moment";

const DatePicker = ({
  disabledDate,
  key,
  endMode,
  dropdownClassName,
  onPanelChange,
  onFocus,
  onChange,
  onBlur,
  type = "date",
  value: defaultValue,
  ...props
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [datePickerMode, setDatePickerMode] = useState("date");
  const [datePickerKey, setDatePickerKey] = useState("date");
  const [range, setRange] = useState(defaultValue);

  const closeDatePicker = () => {
    setDatePickerOpen(false);
    setDatePickerMode("date");
    setDatePickerKey(moment());
  };

  const selectClick = (pickerType) => {
    setTimeout(() => {
      const monthSelect = document.getElementsByClassName(`ant-picker-${pickerType}-btn`)[0];
      if (monthSelect) {
        monthSelect.click();
      }
    }, 100);
  };

  const datePickerItemClick = (mode) => {
    if (mode === "year" || mode === endMode) {
      closeDatePicker();
    }

    if (mode === "date" && mode !== endMode) {
      selectClick("month");
      //eslint-disable-next-line
      addDatePickerItemsEvent("month");
    }

    if (mode === "month" && mode !== endMode) {
      selectClick("year");
      //eslint-disable-next-line
      addDatePickerItemsEvent("year");
    }
  };

  const addDatePickerItemsEvent = (mode) => {
    setDatePickerMode(mode);

    document.querySelectorAll(`.ant-picker-${mode}-panel .ant-picker-cell-selected .ant-picker-cell-inner`).forEach(item => {
      item.removeEventListener("click", () => datePickerItemClick(mode));
    });

    setTimeout(() => {
      document.querySelectorAll(`.ant-picker-${mode}-panel .ant-picker-cell-selected .ant-picker-cell-inner`).forEach(item => {
        item.addEventListener("click", () => datePickerItemClick(mode));
      });
    }, 100);
  };

  const disableYearsAndMonths = () => {
    setTimeout(() => {
      document.querySelectorAll(".customized-datepicker .ant-picker-year-panel .ant-picker-cell").forEach(item => {
        if (disabledDate(moment(item.title))) {
          item.classList.add("ant-picker-cell-disabled");
        } else {
          item.classList.remove("ant-picker-cell-disabled");
        }
      });
      document.querySelectorAll(".customized-datepicker .ant-picker-month-panel .ant-picker-cell").forEach(item => {
        if (disabledDate(moment(item.title))) {
          item.classList.add("ant-picker-cell-disabled");
        } else {
          item.classList.remove("ant-picker-cell-disabled");
        }
      });
    }, 10);
  };

  if (endMode) {
    props = {
      ...props,
      picker: datePickerMode,
      open: datePickerOpen
    }
  }

  let Component = AntDatePicker;
  if (type === "range") {
    Component = AntDatePicker.RangePicker;
  }
  
  return (
    <Component
      {...props}
      key={key || datePickerKey}
      disabledDate={disabledDate || null}
      dropdownClassName={`customized-datepicker ${dropdownClassName || ""}`}
      value={range}
      onPanelChange={() => {
        if (disabledDate) {
          disableYearsAndMonths();
        }

        if (onPanelChange) {
          onPanelChange();
        }
      }}
      onFocus={() => {
        if (endMode) {
          setDatePickerOpen(true);

          addDatePickerItemsEvent("date");
        }

        if (onFocus) {
          onFocus();
        }
      }}
      onChange={value => {
        
        if (endMode) {
          if (!value) {
            closeDatePicker();
          }

          setDatePickerMode(datePickerMode === "date" ? "month" : endMode);
          datePickerItemClick(datePickerMode);
        }

        if (onChange) {
          onChange(value);
        }
        setRange(value)
      }}
      onBlur={() => {
        if (endMode) {
          closeDatePicker();
        }

        if (onBlur) {
          onBlur();
        }
      }}
      onCalendarChange={(value) => {
        if (!value?.[1] && !!range?.[1]) {
          const newRange = [value[0], value[0].clone().add(1, "d")];
          setRange(newRange);
          onChange(newRange)
        }
      }}
    />
  );
};

DatePicker.propTypes = {
  disabledDate: PropTypes.func,
  key: PropTypes.string,
  endMode: PropTypes.string,
  dropdownClassName: PropTypes.string,
  type: PropTypes.string,
  onPanelChange: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object])
};


export default DatePicker;
