import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col } from "antd";

import { getDashboardUsersRequest } from "../../../redux/admin/dashboard/dashboardActions";
import SectionHeading from "./SectionHeading";

const Users = ({ users, country, getUsers }) => {
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD")
  });

  useEffect(() => {
    getUsers({ ...searchQuery, country });
  }, [searchQuery]);

  useEffect(() => {
    getUsers({ ...searchQuery, country });
  }, [country]);

  return (
    <Row className="admin-dashboard__block">
      <Col span={24}>
        <SectionHeading
          setSearchQuery={setSearchQuery}
          title="Users"
          searchQuery={searchQuery}
          sectionKey="users"
        />
        <Row className="admin-dashboard__section">
          <Col span={8} className="admin-dashboard__section-element admin-dashboard__section-element--right-bordered">
            <div className="admin-dashboard__section-element-title">
              Total users
            </div>
            <div className="admin-dashboard__section-element-number">{users.total}</div>
          </Col>
          <Col span={8} className="admin-dashboard__section-element admin-dashboard__section-element--right-bordered">
            <div className="admin-dashboard__section-element-title">
              New registrations
            </div>
            <div className="admin-dashboard__section-element-number">{users.newRegistrations}</div>
          </Col>
          <Col span={8} className="admin-dashboard__section-element">
            <div className="admin-dashboard__section-element-title">
              Not sold profile
            </div>
            <div className="admin-dashboard__section-element-number">{users.notSoldProfile}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Users.propTypes = {
  users: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  getUsers: PropTypes.func.isRequired
};

export default connect(
  state => ({
    users: state.admin.dashboard.users,
    country: state.admin.dashboard.country
  }),
  {
    getUsers: getDashboardUsersRequest
  }
)(Users);
