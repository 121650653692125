export const BLOG_GET_ARTICLE_LIST_REQUEST = "BLOG_GET_ARTICLE_LIST_REQUEST";
export const BLOG_GET_ARTICLE_LIST_REQUEST_PROCESSING = "BLOG_GET_ARTICLE_LIST_REQUEST_PROCESSING";
export const BLOG_GET_ARTICLE_LIST_REQUEST_SUCCESS = "BLOG_GET_ARTICLE_LIST_REQUEST_SUCCESS";
export const BLOG_GET_ARTICLE_LIST_REQUEST_ERROR = "BLOG_GET_ARTICLE_LIST_REQUEST_ERROR";

export const BLOG_UPDATE_ARTICLE_LIST_REQUEST = "BLOG_UPDATE_ARTICLE_LIST_REQUEST";
export const BLOG_UPDATE_ARTICLE_LIST_REQUEST_SUCCESS = "BLOG_UPDATE_ARTICLE_LIST_REQUEST_SUCCESS";

export const BLOG_GET_ARTICLE_REQUEST = "BLOG_GET_ARTICLE_REQUEST";
export const BLOG_GET_ARTICLE_REQUEST_PROCESSING = "BLOG_GET_ARTICLE_REQUEST_PROCESSING";
export const BLOG_GET_ARTICLE_REQUEST_SUCCESS = "BLOG_GET_ARTICLE_REQUEST_SUCCESS";
export const BLOG_GET_ARTICLE_REQUEST_ERROR = "BLOG_GET_ARTICLE_REQUEST_ERROR";

export const BLOG_GET_CATEGORY_LIST_REQUEST = "BLOG_GET_CATEGORY_LIST_REQUEST";
export const BLOG_GET_CATEGORY_LIST_REQUEST_PROCESSING = "BLOG_GET_CATEGORY_LIST_REQUEST_PROCESSING";
export const BLOG_GET_CATEGORY_LIST_REQUEST_SUCCESS = "BLOG_GET_CATEGORY_LIST_REQUEST_SUCCESS";
export const BLOG_GET_CATEGORY_LIST_REQUEST_ERROR = "BLOG_GET_CATEGORY_LIST_REQUEST_ERROR";

export const BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST = "BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST ";
export const BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_PROCESSING = "BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_PROCESSING";
export const BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_SUCCESS = "BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_SUCCESS";
export const BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_ERROR = "BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_ERROR";

export const BLOG_GET_LATEST_NEWS_REQUEST = "BLOG_GET_LATEST_NEWS_REQUEST ";
export const BLOG_GET_LATEST_NEWS_REQUEST_PROCESSING = "BLOG_GET_LATEST_NEWS_REQUEST_PROCESSING";
export const BLOG_GET_LATEST_NEWS_REQUEST_SUCCESS = "BLOG_GET_LATEST_NEWS_REQUEST_SUCCESS";
export const BLOG_GET_LATEST_NEWS_REQUEST_ERROR = "BLOG_GET_LATEST_NEWS_REQUEST_ERROR";

export const BLOG_GET_NEWS_UPDATES_REQUEST = "BLOG_GET_NEWS_UPDATES_REQUEST ";
export const BLOG_GET_NEWS_UPDATES_REQUEST_PROCESSING = "BLOG_GET_NEWS_UPDATES_REQUEST_PROCESSING";
export const BLOG_GET_NEWS_UPDATES_REQUEST_SUCCESS = "BLOG_GET_NEWS_UPDATES_REQUEST_SUCCESS";
export const BLOG_GET_NEWS_UPDATES_REQUEST_ERROR = "BLOG_GET_NEWS_UPDATES_REQUEST_ERROR";

export const BLOG_GET_RELATED_ARTICLES_REQUEST = "BLOG_GET_RELATED_ARTICLES_REQUEST ";
export const BLOG_GET_RELATED_ARTICLES_REQUEST_PROCESSING = "BLOG_GET_RELATED_ARTICLES_REQUEST_PROCESSING";
export const BLOG_GET_RELATED_ARTICLES_REQUEST_SUCCESS = "BLOG_GET_RELATED_ARTICLES_REQUEST_SUCCESS";
export const BLOG_GET_RELATED_ARTICLES_REQUEST_ERROR = "BLOG_GET_RELATED_ARTICLES_REQUEST_ERROR";

export const BLOG_UPDATE_ARTICLE_LIST_BY_CATEGORY_REQUEST = "BLOG_UPDATE_ARTICLE_LIST_BY_CATEGORY_REQUEST";
export const BLOG_UPDATE_ARTICLE_LIST_BY_CATEGORY_REQUEST_SUCCESS = "BLOG_UPDATE_ARTICLE_LIST_BY_CATEGORY_REQUEST_SUCCESS"

export const BLOG_CLEAR_ARTICLE_ERROR = "BLOG_CLEAR_ARTICLE_ERROR";
