import axios from "axios";
import Cookies from "universal-cookie";
import { message } from "antd";

import { store } from "../redux/configureStore";

const cookies = new Cookies();

axios.interceptors.request.use(
  config => {
    const token = cookies.get("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    Promise.reject(error)
  }
);

axios.interceptors.response.use(response => response, function (error) {
  if (error.message === "Network Error" && !error.response) {
    message.error({
      content: "Unexpected server error"
    });

    return Promise.reject(error);
  }

  if (error.response.status === 401) {
    store.dispatch({ type: "SIGN_OUT_REQUEST" });
  }

  return Promise.reject(error);
});

export default axios;
