import * as types from "./articleActionsTypes";

export const getArticleListRequest = (data) => {
  return { type: types.ADMIN_GET_ARTICLE_LIST_REQUEST, data };
};

export const getArticleRequest = (data) => {
  return { type: types.ADMIN_GET_ARTICLE_REQUEST, data };
};

export const createArticleRequest = (data) => {
  return { type: types.ADMIN_CREATE_ARTICLE_REQUEST, data };
};

export const updateArticleRequest = (data) => {
  return { type: types.ADMIN_UPDATE_ARTICLE_REQUEST, data };
};

export const deleteArticleRequest = (data) => {
  return { type: types.ADMIN_DELETE_ARTICLE_REQUEST, data };
};

export const publishArticleRequest = (data) => {
  return { type: types.ADMIN_PUBLISH_ARTICLE_REQUEST, data };
};

export const saveAndPublishArticleRequest = (data) => {
  return { type: types.ADMIN_SAVE_AND_PUBLISH_ARTICLE_REQUEST, data };
};

export const clearArticleAction = () => {
  return { type: types.ADMIN_CLEAR_ARTICLE };
};

export const clearArticleErrorAction = () => {
  return { type: types.ADMIN_CLEAR_ARTICLE_ERROR };
};
