import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getSellerListingListRequest } from "../../redux/listing/listingActions";
import MyListingsCard from "./MyListingsCard";
import HotelCardsLists from "../shared/HotelCardsLists";

const navOptions = [
  {
    key: "active",
    url: "/seller/listings/active",
    label: "Listed",
    queryValue: "published"
  },
  {
    key: "drafts",
    url: "/seller/listings/drafts",
    label: "Unlisted",
    queryValue: ["draft", "expired", "suspended", "pending"],
  },
  {
    key: "sold",
    url: "/seller/listings/sold",
    label: "Sold",
    queryValue: "sold"
  }
];

const MyListings = ({
  dataSource,
  isProcessing,
  pagination,
  getList
}) => {
  return (
    <HotelCardsLists
      navOptions={navOptions}
      dataSource={dataSource}
      isProcessing={isProcessing}
      pagination={pagination}
      getList={getList}
      hotelCard={MyListingsCard}
      queryKey="statuses"
      title="My Listings"
    />
  );
}

MyListings.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired
};

export default connect(
  state => ({
    dataSource: state.listing.myListings.data,
    isProcessing: state.listing.processing,
    error: state.listing.error,
    pagination: {
      current: state.listing.myListings.curPage,
      total: state.listing.myListings.total,
      pageSize: state.listing.myListings.perPage
    },
  }),
  {
    getList: getSellerListingListRequest,
  }
)(MyListings);
