import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "../layout/AdminLayout";
import BlogRoutes from "./BlogRoutes";
import WebsiteManagementRoutes from "./WebsiteManagementRoutes";
import FaqRoutes from "./FaqRoutes";

import Seo from "../shared/Seo";
import Article from "../Admin/Blog/Article";
import Dashboard from "../Admin/Dashboard/Dashboard";
import Dispute from "../Admin/Dispute/Dispute";
import DisputeList from "../Admin/Dispute/DisputeList";
import Feedback from "../Admin/Feedback/Feedback";
import FeedbackList from "../Admin/Feedback/FeedbackList";
import AdminUser from "../Admin/UserManagement/AdminUser";
import AdminUserList from "../Admin/UserManagement/AdminUserList";
import FinanceSettings from "../Admin/FinanceSettings/FinanceSettings";
import ListingList from "../Admin/Listing/ListingList";
import NotificationList from "../Admin/Notification/NotificationList";
import Question from "../Admin/Faq/Question";
import Category from "../Admin/Faq/Category";
import ExperiencesList from "../Admin/Experiences/ExperiencesList";
import Experience from "../Admin/Experiences/Experience";
import SubscriptionsList from "../Admin/Subscriptions/SubscriptionsList";
import FinancialAccounting from "../Admin/FinancialAccounting/FinancialAccounting";
import HotelsList from "../Admin/Hotels/HotelsList";
import Hotel from "../Admin/Hotels/Hotel";
import Reservations from "../Admin/Reservations/Reservations";
import PageNotFound from "../PageNotFound/PageNotFound";

export default function AdminRoutes() {
  return (
    <AdminLayout>
      <Switch>
        <Route
          path="/admin/blog/articles/:id(new|[0-9]+)"
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo title={`Blog Article #${props.match.params.id}`} noindex>
              <Article {...props} />
            </Seo>
          )}
        />
        <Route path="/admin/blog" component={BlogRoutes} />
        <Route
          path="/admin/dashboard"
          render={(props) => (
            <Seo title="Dashboard" noindex>
              <Dashboard {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/disputes/:id(|[0-9]+)"
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo title={`Dispute #${props.match.params.id}`} noindex>
              <Dispute {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/disputes"
          render={(props) => (
            <Seo title="Disputes" noindex>
              <DisputeList {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/feedback/:id(|[0-9]+)"
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo title={`Feedback #${props.match.params.id}`} noindex>
              <Feedback {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/feedback"
          render={(props) => (
            <Seo title="Feedbacks" noindex>
              <FeedbackList {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/user-management/users/:id(|[0-9]+)"
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo title={`User #${props.match.params.id}`} noindex>
              <AdminUser {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/user-management"
          render={(props) => (
            <Seo title="User Management" noindex>
              <AdminUserList {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/finance-settings"
          render={(props) => (
            <Seo title="Finance Settings" noindex>
              <FinanceSettings {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/financial-accounting"
          render={(props) => (
            <Seo title="Financial Accounting" noindex>
              <FinancialAccounting {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/listings"
          render={(props) => (
            <Seo title="Listing" noindex>
              <ListingList {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/notification-center"
          render={(props) => (
            <Seo title="Notifications" noindex>
              <NotificationList {...props} />
            </Seo>
          )}
        />
        <Route path="/admin/website-management" component={WebsiteManagementRoutes} />
        <Route
          path="/admin/faq/questions/:id(new|[0-9]+)"
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo title={`FAQ #${props.match.params.id}`} noindex>
              <Question {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/faq/categories/:id"
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo title={`FAQ Categories #${props.match.params.id}`} noindex>
              <Category {...props} />
            </Seo>
          )}
        />
        <Route path="/admin/faq" component={FaqRoutes} />
        <Route path="/admin/experiences/:id(new|[0-9]+)" component={Experience} />
        <Route path="/admin/experiences" component={ExperiencesList} />
        <Route
          path="/admin/subscriptions"
          render={(props) => (
            <Seo title="Subscriptions" noindex>
              <SubscriptionsList {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/hotels/:id(new|[0-9]+)"
          render={(props) => (
            // eslint-disable-next-line react/prop-types
            <Seo title={`Hotel #${props.match.params.id}`} noindex>
              <Hotel {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/hotels"
          render={(props) => (
            <Seo title="Hotels" noindex>
              <HotelsList {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/reservations"
          render={(props) => (
            <Seo title="Reservations" noindex>
              <Reservations {...props} />
            </Seo>
          )}
        />
        Reservations
        <Route exact path="/admin" render={() => <Redirect to="/admin/dashboard" />} />
        <Route component={PageNotFound} />
      </Switch>
    </AdminLayout>
  );
}
