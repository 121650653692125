import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom"

import { KYC_FAILED_STATUS, KYC_NOT_VERIFIED_STATUS, KYC_PENDING_STATUS, KYC_VERIFIED_STATUS } from "../../constants";

export const KYCPopover = ({ type }) => {
  switch (type) {
    case KYC_VERIFIED_STATUS:
    case KYC_NOT_VERIFIED_STATUS:
      return (
        <div className="wallet-balance__popover-content" data-testid="kyc-verified">
          <div>
            Money laundering regulations require us to verify a seller's identity.{" "}
            We do this electronically which is why we ask you to scan your one of{" "}
            the following photo ID's to us:
            <ul>
              <li>Passport</li>
              <li>Driving License</li>
            </ul>
          </div>
        </div>
      )
    case KYC_FAILED_STATUS:
    case KYC_PENDING_STATUS:
      return (
        <div className="wallet-balance__popover-content" data-testid="kyc-failed">
          We’re in process of verifying your identity.{" "}
          Please read <Link to="/faq">FAQ</Link> or{" "}
          <Link to="/contact-us"> contact us</Link> if you have any questions.
        </div>
      )
    default:
      return null
  }
}

KYCPopover.propTypes = {
  type: PropTypes.string.isRequired
}

export default KYCPopover;
