import axios from "../helpers/axios";

import { API_URL } from "./constants";

const general = {
  async subscribeToMarketingCampaign(data) {
    try {
      const result = await axios.post(`${API_URL}/marketing/subscribe`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default general;
