import { all, call, put, takeLatest } from "redux-saga/effects";

import general from "../../api/general";
import * as types from "./generalActionsTypes";

function *subscribeToMarketingCampaign ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(general.subscribeToMarketingCampaign, data);
    yield put({
      type: types.SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST_SUCCESS,
      data: {
        ...response,
        ...data
      }
    });
  } catch (error) {
    yield put({ type: types.SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.SUBSCRIBE_TO_MARKETING_CAMPAIGN_REQUEST, subscribeToMarketingCampaign),
  ])
}
