import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const userManagement = {
  async getUserList(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/users?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getUser(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/users/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateUser(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/users/${data.id}`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async downloadKYCFile(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/users/${data.userId}/kyc-documents/${data.documentId}/kyc-files/${data.fileId}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getCsvExportUrl(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/users/export-csv?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getUserListings(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/users/${data.userId}/listings?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default userManagement;
