import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Image from "../shared/Image"

const BlogArticleBigCard = ({ article }) => {

  return (
    <Link to={`/blog/articles/${article.slug}`} className="blog-article-big-card">
      <div className="blog-article-big-card__item blog-article-big-card__image-container">
        <Image
          className="blog-article-big-card__image"
          imageUrl={article.coverImageUrl}
          alt="article"
        />
      </div>

      <div className="blog-article-big-card__item">
        <h3 className="blog-article-big-card__title">
          {article.title}
        </h3>
      </div>

      <div className="blog-article-big-card__item">
        <span className="blog-article-big-card__description">
          {article.shortDescription}
        </span>
        <span className="blog-article-big-card__read-more">Read More</span>
      </div>
    </Link>
  );
};

BlogArticleBigCard.propTypes = {
  article: PropTypes.object
};

export default BlogArticleBigCard;
