import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Button, Checkbox, Col, Input, Row } from "antd";
import * as yup from "yup";
import { Link } from "react-router-dom";

import { clearAuthErrorAction, signUpRequest } from "../../redux/auth/authActions";
import ActivateEmail from "./ActivateEmail";
import GoogleSignUp from "./GoogleSignUp";
import * as queryString from "query-string";
import Label from "../shared/Form/Label";
import { clearStateAction } from "../../redux/general/generalActions";
import { getBestDealsRequest } from "../../redux/listing/listingActions";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import ListingCard from "../shared/ListingCard";

const SignUpSchema = yup.object().shape({
  firstName: yup.string().required("First name can not be empty"),
  lastName: yup.string().required("Last name can not be empty"),
  email: yup.string().trim().email("Please provide correct email").required("Email can not be empty"),
  password: yup.string().required("Password can not be empty").matches(
    /^(?=.*\d)(?=.*[A-Z]).{8,255}$/,
    "Password should be at least 8 characters long and include at least one number and one capital letter"
  ),
  passwordConfirm: yup.string().required("Password confirmation can not be empty")
    .oneOf([yup.ref("password"), null], "Passwords don't match"),
  termsAndConditions: yup.boolean().oneOf([true]),
  promocode: yup.string().trim().nullable()
});

const verifyAccountUrl = "auth/verify";

export const SignUp = ({
  isProcessing,
  signUp,
  isAccountVerificationRequired,
  location,
  clearError,
  error,
  subscribedEmail,
  bestDeals,
  clearGeneralState,
  getBestDeals,
}) => {
  const { handleSubmit, errors, control } = useForm({
    validationSchema: SignUpSchema,
    defaultValues: {
      name: ""
    }
  });

  const { type } = queryString.parse(location.search);

  const [email, setEmail] = useState();
  const [termsAndConditionsSelected, setTermsAndConditionsSelected] = useState(false);
  const [marketingSelected, setMarketingSelected] = useState(true);
  const [isGoogleAuthActive, setIsGoogleAuthActive] = useState(type === "google");

  useServerSafeEffect(() => {
    clearError();
    getBestDeals({ page: 1, pageSize: 5 });

    return () => {
      clearGeneralState();
    }
  }, []);

  const signUpHandler = (data) => {
    if (!termsAndConditionsSelected) {
      return;
    }

    setEmail(data.email);

    signUp({
      ...data,
      slug: verifyAccountUrl
    });
  };

  const formattedBestDeals = useMemo(() => {
    const listings = [];
    let latestGroupIndex = 0;

    bestDeals.forEach((bestDeal, index) => {
      const incrementedIndex = index + 1;

      if (incrementedIndex % 3 === 0) {
        listings.push(bestDeal);
        latestGroupIndex++;
      } else {
        const lastArrayItem = listings[latestGroupIndex];

        if (Array.isArray(lastArrayItem)) {
          listings[latestGroupIndex] = [
            ...lastArrayItem,
            bestDeal
          ];
        } else {
          latestGroupIndex = listings.length;
          listings.push([bestDeal]);
        }
      }
    });

    return listings;
  }, [bestDeals]);

  if (isAccountVerificationRequired) {
    return <ActivateEmail email={email} verifyAccountUrl={verifyAccountUrl}/>;
  }

  if (isGoogleAuthActive) {
    return <GoogleSignUp/>;
  }

  return (
    <Row justify="space-around" align="middle" className="sign-up-row">
      <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 12, push: 12 }} lg={{ span: 12, push: 12 }}>
        <div className="sign-up">
          <h1 className="sign-up__title">Join Now</h1>

          <div className="sign-up__login-text">
            Already have an account? <Link to="/auth/sign-in" className="sign-up__login-link">Log in</Link>
          </div>

          <div className="sign-up__subtitle-container">
            <p>
              Receive the best Hotel deals; automatically be eligible to sell unwanted Hotel Bookings.<br/>
              <span className="sign-up__bold-text">WIN free hotel nights in our regular draws</span>
            </p>
            <p className="sign-up__bold-text">It’s Quick and FREE to join</p>
          </div>

          <form onSubmit={handleSubmit(signUpHandler)} className="sign-up__form form-container">
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <div className="sign-up__field sign-up__field--first-name">
                  <Label htmlFor="sign-up-first-name">First Name</Label>
                  <Controller
                    name="firstName"
                    as={<Input
                      id="sign-up-first-name"
                      className={`form-input ${errors.firstName ? "form-input--error" : ""}`}
                      data-testid="sign-up-first-name"
                    />}
                    control={control}
                  />
                  {errors.firstName &&
                  <span className="sign-up__error" data-testid="sign-up-error">{errors.firstName.message}</span>}
                </div>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <div className="sign-up__field sign-up__field--last-name">
                  <Label htmlFor="sign-up-last-name">Last Name</Label>
                  <Controller
                    name="lastName"
                    as={<Input
                      id="sign-up-last-name"
                      className={`form-input ${errors.lastName ? "form-input--error" : ""}`}
                      data-testid="sign-up-last-name"
                    />}
                    control={control}
                  />
                  {errors.lastName &&
                  <span className="sign-up__error" data-testid="sign-up-error">{errors.lastName.message}</span>}
                </div>
              </Col>
            </Row>

            <div className="sign-up__field">
              <Label htmlFor="sign-up-email">Email</Label>
              <Controller
                name="email"
                as={<Input
                  id="sign-up-email"
                  className={`form-input ${errors.email ? "form-input--error" : ""}`}
                  data-testid="sign-up-email"
                />}
                defaultValue={subscribedEmail}
                control={control}
                onChange={([event]) => {
                  clearError();

                  return event.target.value;
                }}
              />
              {(error || errors.email) &&
              <span className="sign-up__error" data-testid="sign-up-error">{error || errors.email.message}</span>}
            </div>
            <div className="sign-up__field">
              <Label htmlFor="sign-up-password">Password</Label>
              <Controller
                name="password"
                as={<Input.Password
                  visibilityToggle={true}
                  id="sign-up-password"
                  className={`form-input ${errors.password ? "form-input--error" : ""}`}
                  data-testid="sign-up-password"
                />}
                control={control}
              />
              <span
                className={`sign-up__hint${errors.password ? " sign-up__hint--error" : ""}`}
                data-testid="sign-up-password-hint"
              >
                *Password should be at least 8 characters long and include at least one number and one capital letter
              </span>
            </div>
            <div className="sign-up__field">
              <Label htmlFor="sign-up-password-confirm">Confirm Password</Label>
              <Controller
                name="passwordConfirm"
                as={<Input.Password
                  visibilityToggle={true}
                  id="sign-up-password-confirm"
                  className={`form-input ${errors.passwordConfirm ? "form-input--error" : ""}`}
                  data-testid="sign-up-password-confirm"
                />}
                control={control}
              />
              {errors.passwordConfirm &&
              <span className="sign-up__error" data-testid="sign-up-error">{errors.passwordConfirm.message}</span>}
            </div>

            <div className="sign-up__field sign-up__field--promocode">
              <Label htmlFor="sign-up-promocode">Introducer Code/Promotion Code</Label>
              <Controller
                name="promocode"
                as={<Input
                  id="sign-up-promocode"
                  className={`form-input ${errors.promocode ? "form-input--error" : ""}`}
                  data-testid="sign-up-promocode"
                />}
                control={control}
                onChange={([event]) => {
                  clearError();

                  return event.target.value;
                }}
              />
              { errors.promocode &&
                <span className="sign-up__error" data-testid="sign-up-error">{errors.promocode.message}</span>
              }
            </div>

            <div className="sign-up__checkbox-container">
              <Controller
                name="marketing"
                as={<Checkbox
                  value={marketingSelected}
                  className="checkbox--primary sign-up__checkbox sign-up__checkbox--center-label"
                >
                  I want to receive marketing messages from PlansChange
                </Checkbox>}
                control={control}
                defaultValue={marketingSelected}
                onChange={([event]) => {
                  setMarketingSelected(event.target.checked);

                  return event.target.checked;
                }}
              />
            </div>

            <div className="sign-up__checkbox-container">
              <Controller
                name="termsAndConditions"
                as={<Checkbox
                  value={termsAndConditionsSelected}
                  className={`checkbox--primary sign-up__checkbox${errors.termsAndConditions ? " sign-up__checkbox--error" : ""}`}
                  data-testid="sign-up-terms"
                >
                  By signing in or creating an account, you agree with our <Link
                    to="/legal/terms-and-conditions"
                    className="link--primary"
                    target="_blank"
                  >Terms & Conditions</Link> and <Link
                    to="/legal/privacy-policy"
                    className="link--primary"
                    target="_blank"
                  >Privacy Statement</Link>
                </Checkbox>}
                control={control}
                defaultValue={termsAndConditionsSelected}
                onChange={([event]) => {
                  setTermsAndConditionsSelected(event.target.checked);

                  return event.target.checked;
                }}
              />
            </div>

            <Button
              className="sign-up__submit"
              htmlType="submit"
              type="primary"
              disabled={isProcessing}
              data-testid="sign-up-submit"
              block
            >
              Sign Up
            </Button>
            <div className="sign-up__or">OR</div>
            <div className="sign-up__with-google" onClick={() => setIsGoogleAuthActive(true)}>
              <img src="/images/google.svg" alt="Sign Up with Google" className="sign-up__google-logo"/>
              Sign Up with Google
            </div>
          </form>
        </div>
      </Col>
      {!!formattedBestDeals.length &&
        <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 12, pull: 12 }} lg={{ span: 12, pull: 12 }}>
          {formattedBestDeals.map((listings, key) => {
            return (
              <Row key={`best-deals-group-${key}`}>
                { Array.isArray(listings)
                  ? <Col span={24}>
                    <Row gutter={16}>
                      {
                        listings.map((item, itemKey) => {
                          return <Col
                            xs={{ span: 24 }}
                            lg={{ span: Math.round(24 / listings.length) }}
                            key={`best-deal-container-${itemKey}`}
                            className="sign-up__listing"
                          >
                            <ListingCard
                              listing={item}
                              size="small"
                            />
                          </Col>
                        })
                      }
                    </Row>
                  </Col>
                  : <Col span={24} className="sign-up__listing">
                    <ListingCard listing={listings} size="big"/>
                  </Col>
                }
              </Row>
            )
          })}
          <Row justify="space-around" align="middle">
            <Col span={24}>
              <div className="auth-footer">
                &copy; {new Date().getFullYear()} Loss2Gain Limited. All rights reserved.
              </div>
            </Col>
          </Row>
        </Col>
      }
      {!formattedBestDeals.length &&
        <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 12, pull: 12 }} lg={{ span: 12, pull: 12 }}>
          <Row justify="space-around" align="middle">
            <Col span={24}>
              <div className="auth-footer">
                &copy; {new Date().getFullYear()} Loss2Gain Limited. All rights reserved.
              </div>
            </Col>
          </Row>
        </Col>
      }
    </Row>
  );
};

SignUp.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  signUp: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isAccountVerificationRequired: PropTypes.bool.isRequired,
  clearError: PropTypes.func.isRequired,
  clearGeneralState: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  subscribedEmail: PropTypes.string,
  bestDeals: PropTypes.array.isRequired,
  getBestDeals: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isProcessing: state.auth.processing,
    error: state.auth.error,
    isAccountVerificationRequired: state.auth.isVerificationRequired,
    subscribedEmail: state.general.subscribedEmail,
    bestDeals: state.listing.bestDeals,
  }),
  {
    signUp: signUpRequest,
    clearError: clearAuthErrorAction,
    clearGeneralState: clearStateAction,
    getBestDeals: getBestDealsRequest,
  }
)(SignUp);
