import React from "react";
import PropTypes from "prop-types"
import { Row, Col } from "antd"


const ShareButtons = ({ type }) => {
  return (
    <Row>
      <Col span={24} className="share-listing__container">
        <div>
          <img src="/images/success.svg" className="share-listing__icon" alt="success" />
          <div className="share-listing__text">
            { type === "verified" ?
              <React.Fragment>
                    You have now successfully validated your account.<br/>
              </React.Fragment>
              : ""
            }
              Your listing is now ready for authentication with our admin team.<br/>
              Once approved we will notify you your listing is now published.<br/>
          </div>
        </div>
      </Col>
    </Row>
  )
}

ShareButtons.propTypes = {
  type: PropTypes.string
}

export default ShareButtons;
