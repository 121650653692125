import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Collapse } from "antd"

import { getProfileHistoryRequest } from "../../../redux/profileDetails/profileDetailsActions";
import ChangePassword from "./ChangePassword"
import ChangeEmail from "./ChangeEmail"
import History from "../../shared/History/History";
import CloseAccount from "./CloseAccount"
import ServiceItem from "./ServiceItem";


const AdditionalServices = ({ profileHistory, getProfileHistory }) => {

  useEffect(() => {
    getProfileHistory()
  }, [])

  return (
    <Row>
      <Col span={24} className="profile-section profile__additional-services">
        <Collapse className="additional-services__collapse" ghost >
          <Collapse.Panel
            header={<h2 className="profile__additional-services-button">Additional services</h2> }
            showArrow={false}
          >
            <ServiceItem name="Password">
              <ChangePassword/>
            </ServiceItem>
            <ServiceItem name="Email">
              <ChangeEmail/>
            </ServiceItem>
            <Row className="profile__history">
              <Col span={24}>
                <History history={profileHistory}/>
              </Col>
            </Row>
            <Row justify="space-around" align="middle">
              <Col>
                <CloseAccount/>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  )
}

AdditionalServices.propTypes = {
  getProfileHistory: PropTypes.func.isRequired,
  profileHistory: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    profileHistory: state.profileDetails.history,
  }),
  {
    getProfileHistory: getProfileHistoryRequest
  }
)(AdditionalServices);
