import { all, put, call, takeLatest } from "redux-saga/effects";

import experiences from "../../../api/admin/experiences";
import * as types from "./experiencesActionsTypes";
import * as generalTypes from "./../../general/generalActionsTypes";
import { MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR } from "../../../react/constants";

function *getExperience ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(experiences.getExperience, data);
    yield put({ type: types.ADMIN_GET_EXPERIENCE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_EXPERIENCE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

function *createExperience ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(experiences.createExperience, data);
    yield put({ type: types.ADMIN_CREATE_EXPERIENCE_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Experience created successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_CREATE_EXPERIENCE_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
    }
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

function *updateExperience ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(experiences.updateExperience, data);
    yield put({ type: types.ADMIN_UPDATE_EXPERIENCE_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Experience saved successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_EXPERIENCE_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
    }
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

function *getExperiencesList ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(experiences.getExperiencesList, data);
    yield put({ type: types.ADMIN_GET_EXPERIENCES_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_EXPERIENCES_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

function *deleteExperience ({ data }) {
  yield put({ type: types.ADMIN_SHOW_LOADING });

  try {
    const response = yield call(experiences.deleteExperience, data);
    yield put({ type: types.ADMIN_DELETE_EXPERIENCE_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_GET_EXPERIENCES_LIST_REQUEST });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_EXPERIENCE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_EXPERIENCES_LIST_REQUEST, getExperiencesList),
    yield takeLatest(types.ADMIN_GET_EXPERIENCE_REQUEST, getExperience),
    yield takeLatest(types.ADMIN_CREATE_EXPERIENCE_REQUEST, createExperience),
    yield takeLatest(types.ADMIN_UPDATE_EXPERIENCE_REQUEST, updateExperience),
    yield takeLatest(types.ADMIN_DELETE_EXPERIENCE_REQUEST, deleteExperience)
  ])
}
