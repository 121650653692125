export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_DISPUTE_LIST_REQUEST = "ADMIN_GET_DISPUTE_LIST_REQUEST";
export const ADMIN_GET_DISPUTE_LIST_REQUEST_SUCCESS = "ADMIN_GET_DISPUTE_LIST_REQUEST_SUCCESS";
export const ADMIN_GET_DISPUTE_LIST_REQUEST_ERROR = "ADMIN_GET_DISPUTE_LIST_REQUEST_ERROR";

export const ADMIN_GET_DISPUTE_REQUEST = "ADMIN_GET_DISPUTE_REQUEST";
export const ADMIN_GET_DISPUTE_REQUEST_SUCCESS = "ADMIN_GET_DISPUTE_REQUEST_SUCCESS";
export const ADMIN_GET_DISPUTE_REQUEST_ERROR = "ADMIN_GET_DISPUTE_REQUEST_ERROR";

export const ADMIN_UPDATE_DISPUTE_REQUEST = "ADMIN_UPDATE_DISPUTE_REQUEST";
export const ADMIN_UPDATE_DISPUTE_REQUEST_SUCCESS = "ADMIN_UPDATE_DISPUTE_REQUEST_SUCCESS";
export const ADMIN_UPDATE_DISPUTE_REQUEST_ERROR = "ADMIN_UPDATE_DISPUTE_REQUEST_ERROR";

export const ADMIN_CREATE_DISPUTE_NOTE_REQUEST = "ADMIN_CREATE_DISPUTE_NOTE_REQUEST";
export const ADMIN_CREATE_DISPUTE_NOTE_REQUEST_SUCCESS = "ADMIN_CREATE_DISPUTE_NOTE_REQUEST_SUCCESS";
export const ADMIN_CREATE_DISPUTE_NOTE_REQUEST_ERROR = "ADMIN_CREATE_DISPUTE_NOTE_REQUEST_ERROR";
