import { useEffect, isValidElement } from "react";
import { message as antdMessage } from "antd"

const useMessage = ({ type, content, ...rest }) => {
  useEffect(() => {
    if (content && (isValidElement(content) || typeof content === "string")) {
      antdMessage[type]({ content, ...rest })
    }
  }, [content])
}

export default useMessage;
