import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

const ModalHeader = ({ children, onClose }) => {
  return (
    <div className="modal-header">
      <h2 className="modal-header__title">{children}</h2>
      <Button
        onClick={onClose}
        className="modal-header__close-button"
        type="primary"
        shape="circle"
        icon={<img className="modal-header__close-icon" alt="close" src="/images/modal-close.svg"/>}
      />
    </div>
  );
};

ModalHeader.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func
};

export default ModalHeader;
