import React from "react";
import { Row, Col, Typography } from "antd";

import Updates from "./Updates/Updates";
import LatestNews from "./LatestNews";
import Categories from "./Categories";
import BrowseAll from "./BrowseAll"
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";

const Blog = ({ getStaticPage, pageData }) => {
  useServerSafeEffect(() => {
    getStaticPage();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        {
          pageData.metaTitle && (
            <title>{ pageData.metaTitle}</title>
          )
        }
        {
          pageData.metaDescription && (
            <meta name="description" content={pageData.metaDescription} />
          )
        }
        {
          pageData.metaKeywords && (
            <meta name="keywords" content={pageData.metaKeywords} />
          )
        }
      </Helmet>
      <section className="blog__updates">
        <Row justify="space-around" align="middle" className="blog__container">
          <Col xs={{ span: 22 }} lg={{ span: 18 }}>
            <Typography.Title
              className="blog__section-title"
              level={1}
            >
              Inspiration & Advice From Our Blog
            </Typography.Title>
            <Updates/>
          </Col>
        </Row>
      </section>
      <section className="blog__sections">
        <Row justify="space-around" align="middle" className="blog__container">
          <Col xs={{ span: 22 }} lg={{ span: 18 }}>
            <Typography.Title
              className="blog__section-title"
              level={2}
            >
              Latest Blogs
            </Typography.Title>
            <LatestNews/>
          </Col>
        </Row>
      </section>
      <section className="blog__sections">
        <Row justify="space-around" align="middle" className="blog__container">
          <Col xs={{ span: 22 }} lg={{ span: 18 }}>
            <Typography.Title
              className="blog__section-title"
              level={2}
            >
              Categories
            </Typography.Title>
            <Categories/>
          </Col>
        </Row>
      </section>
      <section className="blog__sections">
        <Row justify="space-around" align="middle" className="blog__container">
          <Col xs={{ span: 22 }} lg={{ span: 18 }}>
            <Typography.Title
              className="blog__section-title"
              level={2}
            >
              Browse All Blogs
            </Typography.Title>
            <BrowseAll/>
          </Col>
        </Row>
      </section>
    </React.Fragment>

  )
}

Blog.propTypes = {
  getStaticPage: PropTypes.func.isRequired,
  pageData: PropTypes.any.isRequired,
};

export default connect(
  state => ({
    pageData: state.staticPage.page,
  }),
  {
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "blog" }),
  }
)(Blog);
