import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import * as queryString from "query-string";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import { Button, Col, Row } from "antd";
import { Link, Redirect } from "react-router-dom";

import { signInSuccess } from "../../redux/auth/authActions";

const GoogleSignIn = ({ history, location, isAuthenticated, signInSuccessAction }) => {
  const { token, expiresAt, status, prevPath, ...rest } = queryString.parse(location.search);

  useEffect(() => {
    if (isAuthenticated) {
      history.replace(
        prevPath
          ? `${prevPath}?${queryString.stringify(rest, { skipEmptyString: true, skipNull: true, arrayFormat: "bracket" })}`
          : "/"
      );
    }
  }, [isAuthenticated]);

  if (status && !token) {
    return (
      <Row justify="space-around" align="middle" className="auth-row">
        <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 8 }}>
          <div className="google-sign-in form-container">
            <h1 className="google-sign-in__title">
              Account {status === "missing" ? "does not exist" : (status === "suspended" ? "suspended" : "terminated")}
            </h1>

            <div className="google-sign-in__subtitle">
              {status === "missing"
                ? <React.Fragment>
                  The account does not exist.
                </React.Fragment>
                : <React.Fragment>
                  Your account is {status === "suspended" ? "suspended" : "terminated"}. Please contact support team.
                </React.Fragment>
              }
            </div>

            <img src="/images/danger.svg" alt="envelope" className="google-sign-in__icon"/>

            {status === "missing"
              ? <Link to="/auth/sign-up?type=google" className="google-sign-in__login-link">
                <Button className="submit-button" type="primary" block>Sign Up with Google</Button>
              </Link>
              : <Link to="/auth/sign-in" className="google-sign-in__login-link">
                <Button className="submit-button" type="primary" block>Back to Login</Button>
              </Link>
            }
          </div>
        </Col>
      </Row>
    );
  }

  if (!token) {
    return <Redirect to="/"/>;
  }

  const cookies = new Cookies();

  const expirationDate = new Date(expiresAt);
  const user = jwt_decode(token);

  // protect from start on server
  if (typeof window !== "undefined") {
    cookies.set("authToken", token, {
      expires: expirationDate,
      path: "/"
    });

    cookies.set("authUser", user, {
      expires: expirationDate,
      path: "/"
    });

    cookies.set("expiresAt", expirationDate, {
      expires: expirationDate,
      path: "/"
    });

    signInSuccessAction(user);
  }

  return "";
};

GoogleSignIn.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  signInSuccessAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
  }),
  {
    signInSuccessAction: signInSuccess
  }
)(GoogleSignIn);
