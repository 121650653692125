import React from "react";

export const initialPaginationObject = {
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: []
};

export const itemRender = (current, type, originalElement) => {
  if (type === "prev") {
    return (
      <a className="ant-pagination-button">
        <svg width="4px" height="8px" viewBox="0 0 4 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g fill="currentColor">
            <polygon points="4 0 4 8 0 4"/>
          </g>
        </svg>
        <span>Back</span>
      </a>
    )
  }
  if (type === "next") {
    return (
      <a className="ant-pagination-button">
        <span>Next</span>
        <svg width="4px" height="8px" viewBox="0 0 4 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon fill="currentColor" points="0 0 4 4 0 8"/>
        </svg>
      </a>
    )
  }

  return originalElement;
};
