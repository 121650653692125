import React from "react";
import PropTypes from "prop-types";
import { Tabs as AntdTabs } from "antd";

const Tabs = ({ data, children, animated, className, ...rest }) => {

  return (
    <AntdTabs
      animated={animated}
      className={`tabs ${className ? className : ""}`}
      tabBarGutter={16}
      tabBarExtraContent={null}
      {...rest}
    >
      {data.map(({ key, tabName }) => (
        <AntdTabs.TabPane key={key} tab={tabName}>
          {children}
        </AntdTabs.TabPane>
      ))}

    </AntdTabs>

  )
}

Tabs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  animated: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Tabs;
