import * as types from "./listingActionsTypes";
import { initialPaginationObject } from "../../helpers/pagination";
import { calcDiscount } from "../../helpers/common";

export const initialState = {
  processing: false,
  successCreated: false,
  successCreatedAndPublished: false,
  successPublished: false,
  successCreatedDispute: false,
  successListingsFetched: false,
  showCardDetailsModal: true,
  isSomebodyProcessingListing: false,
  error: "",
  myListings: initialPaginationObject,
  myPurchases: initialPaginationObject,
  listings: initialPaginationObject,
  hotels: [],
  singleListing: {
    Hotel: {},
    facilities: [],
    roomType: null,
    status: "draft",
    isPaymentRequired: false,
  },
  paymentDetails: {
    redirectUrl: "",
    paymentId: "",
    clientSecret: ""
  },
  bestDeals: [],
  coordinates: {
    latitude: 51.505,
    longitude: -0.09
  },
  lastSearchParams: {}
};

const listing = (state = initialState, action) => {
  switch (action.type) {
    case types.LISTING_SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.LISTING_HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.GET_HOTEL_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        hotels: action.response,
        error: ""
      };
    case types.GET_HOTEL_REQUEST_SUCCESS:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          hotelId: action.response.id,
          Hotel: action.response
        },
        error: ""
      };
    case types.GET_LISTING_REQUEST_SUCCESS:
      return {
        ...state,
        singleListing: action.response,
        error: ""
      };
    case types.CREATE_LISTING_REQUEST_SUCCESS:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          ...action.response,
          files: [],
          savedFiles: action.response.status === "draft" ? state.singleListing.files : []
        },
        successCreated: action.response.status === "draft",
        successCreatedAndPublished: action.response.status !== "draft",
        error: ""
      };
    case types.UPDATE_LISTING_REQUEST_SUCCESS:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          ...action.response,
          files: []
        },
        error: ""
      };
    case types.GET_LISTING_LIST_REQUEST_SUCCESS: {
      const unsortedData = [
        ...state.listings.data,
        ...action.response.data.map(item => {
          return {
            discount: calcDiscount(item.sellingPrice, item.originalPrice),
            ...item
          };
        })
      ];

      let data = []
      if (unsortedData) {
        data = [
          ...unsortedData.filter((listingItem) => {
            return listingItem.type === "organic"
          }),
          ...unsortedData.filter((listingItem) => {
            return listingItem.type !== "organic"
          })
        ]
      }

      const selectedHotelListing = action?.response?.selectedHotelListing;
      if (selectedHotelListing && !selectedHotelListing?.id && !action.response.nextResultReference) {
        const selectedHotel = data.find((item) => item && item.Hotel && selectedHotelListing &&
          selectedHotelListing.Hotel && item.Hotel.id === selectedHotelListing.Hotel.id);

        if (!selectedHotel) {
          if (action?.response?.selectedHotelListing?.Hotel) {
            data.unshift(action?.response?.selectedHotelListing);
          }
        } else {
          const idx = data.indexOf(selectedHotel);
          const hotel = data[idx];
          data.splice(idx, 1);
          data.unshift(hotel);
        }
      }

      return {
        ...state,
        successListingsFetched: !action.response.nextResultReference,
        coordinates: action.response.coordinates,
        listings: {
          ...initialPaginationObject,
          total: data.length,
          data
        },
        error: ""
      };
    }
    case types.GET_MY_PURCHASES_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        myPurchases: action.response,
        error: ""
      };
    case types.GET_MY_LISTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        myListings: action.response,
        error: ""
      };
    case types.GET_MY_PURCHASES_LIST_REQUEST_ERROR:
      return {
        ...state,
        myPurchases: initialPaginationObject,
        error: action.error
      };
    case types.GET_MY_LISTINGS_REQUEST_ERROR:
      return {
        ...state,
        myListings: initialPaginationObject,
        error: action.error
      };
    case types.GET_LISTING_LIST_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        successListingsFetched: false
      };
    case types.SEND_LISTING_TO_APPROVAL_REQUEST_SUCCESS:
      return {
        ...state,
        listings: {
          ...state.listings,
          data: state.listings.data.map(item => item.id === action.response.id ? { ...item, ...action.response } : item)
        },
        singleListing: state.singleListing.id === action.response.id
          ? { ...state.singleListing, ...action.response }
          : state.singleListing,
        error: "",
        successPublished: true
      };
    case types.PURCHASE_ORGANIC_LISTING_REQUEST_SUCCESS:
      return {
        ...state,
        paymentDetails: action.response,
        error: ""
      };
    case types.CREATE_LISTING_DISPUTE_REQUEST_SUCCESS:
      return {
        ...state,
        successCreatedDispute: true,
        error: ""
      };
    case types.SET_NEW_LISTING:
      return {
        ...state,
        singleListing: action.data
      };
    case types.CLEAR_LISTING_ERROR:
      return {
        ...state,
        error: "",
        successCreated: false,
        successCreatedAndPublished: false,
        successCreatedDispute: false,
        successPublished: false,
        successListingsFetched: false,
        isSomebodyProcessingListing: false,
      };
    case types.SET_LISTING_LIST:
      return {
        ...state,
        listings: {
          ...state.listings,
          data: action.data
        }
      };
    case types.START_BOOKING_LISTING_REQUEST_ERROR:
      return {
        ...state,
        isSomebodyProcessingListing: !!action.data.somebodyProcessing
      };
    case types.GET_BEST_DEALS_REQUEST_SUCCESS:
      return {
        ...state,
        bestDeals: action.response.map(item => {
          return {
            ...item,
            discount: calcDiscount(item.sellingPrice, item.originalPrice),
          }
        }),
        error: ""
      };
    case types.GET_BEST_DEALS_REQUEST_ERROR:
      return {
        ...state,
        bestDeals: initialState.bestDeals,
        error: action.error
      };
    case types.CANCEL_BOOKING_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.SET_LAST_SEARCH_PARAMS:
      return {
        ...state,
        lastSearchParams: {
          ...action.data
        },
      };
    case types.SET_SHOW_CARD_DETAILS_MODAL:
      return {
        ...state,
        showCardDetailsModal: action.data,
      };
    default:
      return state;
  }
};

export default listing;
