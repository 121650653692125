import React from "react";

import SocialsLinks from "../shared/SocialsLinks";

const FollowUs = () => {
  return (
    <div className="follow-us">
      <h3 className="follow-us__title">
      Follow Us
      </h3>
      <p className="follow-us__text">
        Get the latest news and deals in the travel industry through us.{" "}
        Only quality stuff!
      </p>
      <SocialsLinks/>
    </div>
  )
}

FollowUs.propTypes = {
}

export default FollowUs;
