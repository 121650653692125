import * as types from "./staticPagesActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  isFeedbackPosted: false,
  page: {
    content: "",
    motto: "",
    firstColumnContent: "",
    secondColumnContent: ""
  },
  faq: {
    updatedAt: null,
    categories: []
  }
};

const staticPage = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.GET_STATIC_PAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        page: action.response
      };
    case types.GET_STATIC_PAGE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.POST_FEEDBACK_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        isFeedbackPosted: true
      };
    case types.POST_FEEDBACK_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        isFeedbackPosted: false
      };
    case types.GET_FAQ_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        faq: {
          categories: action.response.categories.filter(category => category.Faqs && !!category.Faqs.length),
          updatedAt: action.response.updatedAt
        }
      };
    case types.GET_FAQ_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.RESET_STATIC_PAGE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default staticPage;
