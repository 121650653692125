import * as types from "./categoryActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: []
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_CATEGORY_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: "",
      };
    case types.ADMIN_CREATE_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        success: true
      };
    case types.ADMIN_GET_CATEGORY_LIST_REQUEST_ERROR:
    case types.ADMIN_CREATE_CATEGORY_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_CLEAR_CATEGORY_ERROR:
      return {
        ...state,
        error: "",
        success: false
      };
    default:
      return state;
  }
};

export default categories;
