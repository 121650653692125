import { all, call, put, takeLatest, fork, cancel, take } from "redux-saga/effects";

import serverSagaWrapper from "../../helpers/serverSagaWrapper"
import listing from "../../api/listing";
import * as types from "./listingActionsTypes";
import * as generalTypes from "../general/generalActionsTypes";
import { MODAL_TYPE_ERROR, MODAL_TYPE_SUCCESS } from "../../react/constants";
import profile from "../../api/profile";

function *getHotelList ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.getHotelList, data);
    yield put({ type: types.GET_HOTEL_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_HOTEL_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *getHotel ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.getHotel, data);
    yield put({ type: types.GET_HOTEL_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_HOTEL_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *getListing ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.getListing, data);
    yield put({ type: types.GET_LISTING_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_LISTING_REQUEST_ERROR, error: error.message });

    if (typeof window !== "undefined") {
      yield put(
        {
          type: generalTypes.SHOW_MODAL,
          data: {
            type: MODAL_TYPE_ERROR,
            title: error.message,
            params: { pathToRedirect: "/" }
          }
        }
      );
    }
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *getListingList ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.getListingList, data);
    yield put({ type: types.GET_LISTING_LIST_REQUEST_SUCCESS, response });

    const listingsCount = response.listingsCount;

    if (response.nextResultReference && listingsCount < 60) {
      yield put({
        type: types.GET_LISTING_LIST_REQUEST,
        data: {
          ...data,
          listingsCount,
          nextResultReference: response.nextResultReference,
          page: response.nextPage
        }
      })
    } else {
      yield put({ type: types.CANCEL_LISTINGS_SEARCH });
    }
  } catch (error) {
    yield put({ type: types.GET_LISTING_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *fetchListings({ data }) {
  const getListingListTask = yield fork(getListingList, { data });

  yield take(types.CANCEL_LISTINGS_SEARCH);
  yield cancel(getListingListTask);
}

function *getMyPurchasesList ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.getMyPurchasesList, data);
    yield put({ type: types.GET_MY_PURCHASES_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_MY_PURCHASES_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *getSellerListingList ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.getSellerListingList, data);
    yield put({ type: types.GET_MY_LISTINGS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_MY_LISTINGS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *createListing ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.createListing, data);
    yield put({ type: types.CREATE_LISTING_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.CREATE_LISTING_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *sendListingToApproval ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.sendListingToApproval, data);
    response.id = data.id;
    yield put({ type: types.SEND_LISTING_TO_APPROVAL_REQUEST_SUCCESS, response });
    if (!data.silent) {
      yield put({
        type: generalTypes.SHOW_MODAL,
        data: {
          type: MODAL_TYPE_SUCCESS,
          title: "Your listing is now ready for authentication with our admin team. Once approved we will notify you your listing is now published.",
          width: 450
        }
      });
    } else {
      yield put({ type: generalTypes.HIDE_MODAL });
    }

    if (data.listingsListStatus) {
      yield put({ type: types.GET_MY_LISTINGS_REQUEST, data: { status: data.listingsListStatus } });
    }
  } catch (error) {
    yield put({ type: types.SEND_LISTING_TO_APPROVAL_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *updateListing ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.updateListing, data);
    yield put({ type: types.UPDATE_LISTING_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: `Listing ${response.status === "pending" ? "sent to review" : "saved"} successfully!`,
        width: 400
      }
    });
  } catch (error) {
    yield put({ type: types.UPDATE_LISTING_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING });
  }
}

function *deleteListing ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.deleteListing, data);
    yield put({ type: types.DELETE_LISTING_REQUEST_SUCCESS, response });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_SUCCESS, title: "Listing has been deleted." } });
    if (data.type !== "seller") {
      yield put({ type: types.GET_MY_PURCHASES_LIST_REQUEST });
    }
  } catch (error) {
    yield put({ type: types.DELETE_LISTING_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *startBookingListing ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.startBookingListing, data);
    yield put({ type: types.START_BOOKING_LISTING_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.START_BOOKING_LISTING_REQUEST_ERROR, error: error.message, data: error.data });
    if (!error.data || (error.data && !error.data.somebodyProcessing)) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
    }
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *endBookingListing ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.endBookingListing, data);
    yield put({ type: types.END_BOOKING_LISTING_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.END_BOOKING_LISTING_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *purchaseOrganicListing ({ action, listingId, data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });
  try {
    if (action === "update") {
      yield call(profile.updateUser, data);
      const response = yield call(
        listing.purchaseOrganicListing,
        { listingId, cardType: data.cardType, slug: data.slug }
      );
      yield put({ type: types.PURCHASE_ORGANIC_LISTING_REQUEST_SUCCESS, response });
    } else {
      const response = yield call(
        listing.purchaseOrganicListing,
        { listingId, cardType: data.cardType, slug: data.slug }
      );
      data.paymentId = response.paymentId;
      yield call(profile.createTemporaryProfile, data);
      yield put({ type: types.PURCHASE_ORGANIC_LISTING_REQUEST_SUCCESS, response });
    }
  } catch (error) {
    yield put({ type: types.PURCHASE_ORGANIC_LISTING_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *purchaseB2BListing ({ data, callBack }) {
  yield put({ type: types.LISTING_SHOW_LOADING });
  try {
    const response = yield call(listing.purchaseB2BListing, data);
    yield put({ type: types.PURCHASE_B2B_LISTING_REQUEST_SUCCESS, response });
    yield call(callBack, { status: "success", listingSlug: response.slug, accessToken: response.accessToken });
  } catch (error) {
    yield put({ type: types.PURCHASE_B2B_LISTING_REQUEST_ERROR, error: error.message });
    yield call(callBack, { status: "failed", listingSlug: data.slug });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *createListingDispute ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });
  try {
    const response = yield call(listing.createListingDispute, data);
    yield put({ type: types.CREATE_LISTING_DISPUTE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.CREATE_LISTING_DISPUTE_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *getAttachmentsUrl ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.getAttachmentsUrl, data);
    if (response.fileUrl) {
      const a = document.createElement("a");
      a.href = response.fileUrl;
      a.download = response.fileUrl;
      a.click();
    }
  } catch (error) {
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *updateListingActivity ({ data }) {
  try {
    const response = yield call(listing.updateListingActivity, data);
    yield put({ type: types.UPDATE_LISTING_ACTIVITY_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.UPDATE_LISTING_ACTIVITY_REQUEST_ERROR, error: error.message });
  }
}

function *getBestDeals ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.getBestDeals, data);
    yield put({ type: types.GET_BEST_DEALS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.GET_BEST_DEALS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *cancelBooking ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.cancelBooking, data);
    yield put({ type: types.CANCEL_BOOKING_REQUEST_SUCCESS, response });
    yield put({ type: types.GET_LISTING_REQUEST, data });
    yield put({ type: generalTypes.HIDE_MODAL });
  } catch (error) {
    yield put({ type: types.CANCEL_BOOKING_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *addCardToListing ({ data, callBack }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.addCard, data);
    yield put({ type: types.ADD_CARD_TO_LISTING_REQUEST_SUCCESS, response });

    if (typeof callBack === "function") {
      yield call(callBack, response);
    }

    yield put({ type: generalTypes.HIDE_MODAL });
  } catch (error) {
    yield put({ type: types.ADD_CARD_TO_LISTING_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *createListingPriceOffer ({ data }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.createListingPriceOffer, data);
    yield put({ type: types.CREATE_LISTING_PRICE_OFFER_REQUEST_SUCCESS, response });
    yield put({ type: generalTypes.HIDE_MODAL });
    yield put({ type: types.GET_LISTING_REQUEST, data });
  } catch (error) {
    yield put({ type: types.CREATE_LISTING_PRICE_OFFER_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *declineListingPriceOffer ({ data, callBack }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.declineListingPriceOffer, data);
    yield put({ type: types.DECLINE_LISTING_PRICE_OFFER_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Price offer declined successfully."
      }
    });

    if (typeof callBack === "function") {
      yield call(callBack, response);
    }
  } catch (error) {
    yield put({ type: types.DECLINE_LISTING_PRICE_OFFER_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

function *acceptListingPriceOffer ({ data, callBack }) {
  yield put({ type: types.LISTING_SHOW_LOADING });

  try {
    const response = yield call(listing.acceptListingPriceOffer, data);
    yield put({ type: types.ACCEPT_LISTING_PRICE_OFFER_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Price offer accepted successfully."
      }
    });

    if (typeof callBack === "function") {
      yield call(callBack, response);
    }
  } catch (error) {
    yield put({ type: types.ACCEPT_LISTING_PRICE_OFFER_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.LISTING_HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_HOTEL_LIST_REQUEST, getHotelList),
    yield takeLatest(types.GET_HOTEL_REQUEST, getHotel),
    yield takeLatest(types.GET_LISTING_REQUEST, serverSagaWrapper(getListing)),
    yield takeLatest(types.GET_LISTING_LIST_REQUEST, fetchListings),
    yield takeLatest(types.GET_MY_PURCHASES_LIST_REQUEST, getMyPurchasesList),
    yield takeLatest(types.GET_MY_LISTINGS_REQUEST, getSellerListingList),
    yield takeLatest(types.CREATE_LISTING_REQUEST, createListing),
    yield takeLatest(types.SEND_LISTING_TO_APPROVAL_REQUEST, sendListingToApproval),
    yield takeLatest(types.UPDATE_LISTING_REQUEST, updateListing),
    yield takeLatest(types.DELETE_LISTING_REQUEST, deleteListing),
    yield takeLatest(types.START_BOOKING_LISTING_REQUEST, startBookingListing),
    yield takeLatest(types.END_BOOKING_LISTING_REQUEST, endBookingListing),
    yield takeLatest(types.PURCHASE_ORGANIC_LISTING_REQUEST, purchaseOrganicListing),
    yield takeLatest(types.PURCHASE_B2B_LISTING_REQUEST, purchaseB2BListing),
    yield takeLatest(types.CREATE_LISTING_DISPUTE_REQUEST, createListingDispute),
    yield takeLatest(types.GET_ATTACHMENTS_URL_REQUEST, getAttachmentsUrl),
    yield takeLatest(types.UPDATE_LISTING_ACTIVITY_REQUEST, updateListingActivity),
    yield takeLatest(types.GET_BEST_DEALS_REQUEST, serverSagaWrapper(getBestDeals)),
    yield takeLatest(types.CANCEL_BOOKING_REQUEST, cancelBooking),
    yield takeLatest(types.ADD_CARD_TO_LISTING_REQUEST, addCardToListing),
    yield takeLatest(types.CREATE_LISTING_PRICE_OFFER_REQUEST, createListingPriceOffer),
    yield takeLatest(types.DECLINE_LISTING_PRICE_OFFER_REQUEST, declineListingPriceOffer),
    yield takeLatest(types.ACCEPT_LISTING_PRICE_OFFER_REQUEST, acceptListingPriceOffer)
  ])
}
