import React from "react";
import { Col, Input, Row, Select } from "antd";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import moment from "moment";

import Label from "../Form/Label";
import DobPicker from "../DobPicker";
import { ISO31661ALPHA2 } from "../../../api/constants";
import PhoneInput from "react-phone-input-2";

export const ProfileDetails = ({ profile, errors, control, isProcessing, setValue, setPhone }) => {
  return (
    <Row className="profile-details">
      <Col span={24}>
        <Row>
          <Col xs={{ span: 12 }} md={{ span: 12 }}>
            <div className="profile-details__field profile-details__field--first-name">
              <Label>First Name</Label>
              <Controller
                name="firstName"
                as={<Input
                  id="profile-details-first-name"
                  className={`form-input ${errors.firstName ? "form-input--error" : ""}`}
                  data-testid="profile-details-first-name"
                />}
                control={control}
                defaultValue={profile.first_name}
                onChange={([event]) => {
                  return event.target.value;
                }}
              />
              {errors.firstName && <span
                className="profile-details__error"
                data-testid="profile-details-error"
              >{errors.firstName.message}</span>}
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 12 }}>
            <div className="profile-details__field">
              <Label>Last Name</Label>
              <Controller
                name="lastName"
                as={<Input
                  id="profile-details-last-name"
                  className={`form-input ${errors.lastName ? "form-input--error" : ""}`}
                  data-testid="profile-details-last-name"
                />}
                control={control}
                defaultValue={profile.last_name}
                onChange={([event]) => {
                  return event.target.value;
                }}
              />
              {errors.lastName && <span
                className="profile-details__error"
                data-testid="profile-details-error"
              >{errors.lastName.message}</span>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="profile-details__field">
              <Label>Email</Label>
              <Input readOnly disabled value={profile.email} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={`profile-details__field ${errors.dateOfBirth ? "profile-details__field--error" : ""}`}>
              <Label>Date of Birth</Label>
              <Controller
                name="dateOfBirth"
                disabled={isProcessing}
                control={control}
                as={<DobPicker
                  onChange={dateOfBirth => setValue("dateOfBirth", dateOfBirth)}
                  defaultValue={profile.dateOfBirth ? moment.utc(profile.dateOfBirth) : null}
                  data-testid="profile-details-date-picker"
                />}
              />
              {errors.dateOfBirth && <span
                className="profile-details__error"
                data-testid="profile-details-error"
              >{errors.dateOfBirth.message}</span>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="profile-details__field">
              <Label>Nationality</Label>
              <Controller
                name="nationality"
                as={<Select
                  showSearch
                  optionFilterProp="children"
                  className="profile-details__nationality-select form-select"
                  defaultValue="UK"
                  data-testid="profile-details-nationality"
                >
                  {
                    ISO31661ALPHA2.map(nationality => {
                      return <Select.Option value={nationality.code} key={nationality.code}>
                        {nationality.name}
                      </Select.Option>;
                    })
                  }
                </Select>}
                control={control}
                defaultValue={profile.nationality}
                onChange={([event]) => {
                  return event;
                }}
              />
              {errors.nationality && <span
                className="profile-details__error"
                data-testid="profile-details-error"
              >{errors.nationality.message}</span>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="profile-details__field">
              <Label>Phone Number</Label>
              <Controller
                name="phone"
                as={<PhoneInput
                  id="profile-details-phone-number"
                  className={`form-input ${errors.phone ? "form-input--error" : ""}`}
                  inputProps={{
                    "data-testid": "profile-details-phone-number"
                  }}
                  country="gb"
                  preferredCountries={["gb", "us"]}
                  autoFormat
                  enableSearch
                  disableSearchIcon
                  countryCodeEditable={false}
                />}
                control={control}
                defaultValue={profile?.phoneCountryCode
                  ? `${profile.phoneCountryCode}${profile.phoneNumber}` : "+44"}
                onChange={([value, country]) => {
                  setPhone({
                    number: value.replace(country.dialCode, "").replace(/\D/g, ""),
                    countryCode: `${country.dialCode}`.replace(/\D/g, "")
                  });

                  return value;
                }}
              />
              {errors.phone &&
              <span
                className="profile-details__error"
                data-testid="profile-details-error"
              >Invalid phone number</span>
              }
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

ProfileDetails.propTypes = {
  profile: PropTypes.object,
  isProcessing: PropTypes.bool,
  errors: PropTypes.object,
  control: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired
};

export default ProfileDetails;
