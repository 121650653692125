export const placesWithSearchRadiusSpecified = [
  {
    name: "London",
    placeId: "ChIJdd4hrwug2EcRmSrV3Vo6llI",
    searchRadius: 2
  },
  {
    name: "Birmingham",
    placeId: "ChIJc3FBGy2UcEgRmHnurvD-gco",
    searchRadius: 2
  },
  {
    name: "Leeds",
    placeId: "ChIJmb1k2ko-eUgRqdwTAv26rVE",
    searchRadius: 2
  },
  {
    name: "Glasgow",
    placeId: "ChIJ685WIFYViEgRHlHvBbiD5nE",
    searchRadius: 2
  },
  {
    name: "Sheffield",
    placeId: "ChIJFb7o-qkKeUgReLAGr_UnKD4",
    searchRadius: 2
  },
  {
    name: "Bradford",
    placeId: "ChIJGRnC6XpfeUgRTxknMtjt4I8",
    searchRadius: 2
  },
  {
    name: "Edinburgh",
    placeId: "ChIJIyaYpQC4h0gRJxfnfHsU8mQ",
    searchRadius: 2
  },
  {
    name: "Liverpool",
    placeId: "ChIJt2BwZIrfekgRAW4XP28E3EI",
    searchRadius: 2
  },
  {
    name: "Manchester",
    placeId: "ChIJ2_UmUkxNekgRqmv-BDgUvtk",
    searchRadius: 2
  },
  {
    name: "Bristol",
    placeId: "ChIJYdizgWaDcUgRH9eaSy6y5I4",
    searchRadius: 2
  },
  {
    name: "Wakefield",
    placeId: "ChIJMQv7R2BdeUgRyNqy1Hx9aXE",
    searchRadius: 2
  },
  {
    name: "Cardiff",
    placeId: "ChIJ9VPsNNQCbkgRDmeGZdsGNBQ",
    searchRadius: 2
  },
  {
    name: "Coventry",
    placeId: "ChIJtyJuZVGxcEgRiQZPVvVg9gQ",
    searchRadius: 2
  },
  {
    name: "Nottingham",
    placeId: "ChIJzXkHOdIyeEgRFdsZGSBjgBA",
    searchRadius: 2
  },
  {
    name: "Leicester",
    placeId: "ChIJc2y3SatCd0gRuJy0byodFZo",
    searchRadius: 2
  },
  {
    name: "Sunderland",
    placeId: "ChIJn3Pwii1hfkgRwwN_AX1unXQ",
    searchRadius: 2
  },
  {
    name: "Belfast",
    placeId: "ChIJO4rQ1_3_YEgRMcXH7ywWVy4",
    searchRadius: 2
  },
  {
    name: "Newcastle upon Tyne",
    placeId: "ChIJzWRvDH6FfUgRkWGncrBS4gs",
    searchRadius: 2
  },
  {
    name: "Brighton",
    placeId: "ChIJZ0Ep9gmFdUgR-Q59cnqvxpw",
    searchRadius: 2
  },
  {
    name: "Hull",
    placeId: "ChIJrWABfI8g1kcR_zDxgp1-RG0",
    searchRadius: 2
  },
  {
    name: "Plymouth",
    placeId: "ChIJPeqVDlONbEgRk4X1zrUsKDs",
    searchRadius: 2
  },
  {
    name: "Stoke-on-Trent",
    placeId: "ChIJDUSuD1IRekgRBO7KAi_NzfE",
    searchRadius: 2
  },
  {
    name: "Wolverhampton",
    placeId: "ChIJ_dclMtSAcEgRJgE4R5WgbVI",
    searchRadius: 2
  },
  {
    name: "Derby",
    placeId: "ChIJp8qkhjLkeUgR3zZOld7JXwA",
    searchRadius: 2
  },
  {
    name: "Swansea",
    placeId: "ChIJsZdOWlVFbkgRdMvnL44Sdz0",
    searchRadius: 2
  },
  {
    name: "Southampton",
    placeId: "ChIJCSkVvleJc0gR8HHaTGpajKc",
    searchRadius: 2
  },
  {
    name: "Salford",
    placeId: "ChIJEVdF_g2ue0gRFtj1WL_-9G0",
    searchRadius: 2
  },
  {
    name: "Aberdeen",
    placeId: "ChIJSXXXH0wFhEgRcsT0XNoFu-g",
    searchRadius: 2
  },
  {
    name: "Westminster",
    placeId: "ChIJVbSVrt0EdkgRQH_FO4ZkHc0",
    searchRadius: 2
  },
  {
    name: "Portsmouth",
    placeId: "ChIJ6fEUGKRCdEgReTs3A-qDtkU",
    searchRadius: 2
  },
  {
    name: "York",
    placeId: "ChIJ8WWY4UDDeEgR0eRUiomrdEc",
    searchRadius: 2
  },
  {
    name: "Peterborough",
    placeId: "ChIJBUaqzH0H2EcR1Y1t2eEt_q4",
    searchRadius: 2
  },
  {
    name: "Dundee",
    placeId: "ChIJ05xjq0RBhkgRKuVLOB475JQ",
    searchRadius: 2
  },
  {
    name: "Lancaster",
    placeId: "ChIJUYcT0kFhe0gRibPAlZUI6gs",
    searchRadius: 2
  },
  {
    name: "Oxford",
    placeId: "ChIJrx_ErYAzcUgRAnRUy6jbIMg",
    searchRadius: 2
  },
  {
    name: "Newport",
    placeId: "ChIJlUugHBMebkgRROINkjoWXEk",
    searchRadius: 2
  },
  {
    name: "Preston",
    placeId: "ChIJTTx7C8sMe0gRIYeyefsDh9Y",
    searchRadius: 2
  },
  {
    name: "St Albans",
    placeId: "ChIJCcmT56A4dkgRWUCmRoCE7HE",
    searchRadius: 2
  },
  {
    name: "Norwich",
    placeId: "ChIJE_UzUdWq0EcRL0USDuClTGg",
    searchRadius: 2
  },
  {
    name: "Chester",
    placeId: "ChIJafWcYtnBekgRn_jYjbNsYkk",
    searchRadius: 2
  },
  {
    name: "Cambridge",
    placeId: "ChIJLQEq84ld2EcRIT1eo-Ego2M",
    searchRadius: 2
  },
  {
    name: "Salisbury",
    placeId: "ChIJt5sANlWMc0gRBqpe8oDgow0",
    searchRadius: 2
  },
  {
    name: "Exeter",
    placeId: "ChIJp41J1MRSbEgRHq3fayXjdqk",
    searchRadius: 2
  },
  {
    name: "Gloucester",
    placeId: "ChIJz_Lr9vv9cEgRzxnoIZabcOw",
    searchRadius: 2
  },
  {
    name: "Lisburn",
    placeId: "ChIJHcei1If9YEgROxjzlI2rl8I",
    searchRadius: 2
  },
  {
    name: "Chichester",
    placeId: "ChIJ4Yt2BKNIdEgRM0KquAQxdIk",
    searchRadius: 2
  },
  {
    name: "Winchester",
    placeId: "ChIJY3T5jVIFdEgRoe2oZl2GpGc",
    searchRadius: 2
  },
  {
    name: "Londonderry",
    placeId: "ChIJY5PQNOTdX0gRL_NVxyr6Ib0",
    searchRadius: 2
  },
  {
    name: "Carlisle",
    placeId: "ChIJD2vuPt_hfEgRLWgVurZDClw",
    searchRadius: 2
  },
  {
    name: "Worcester",
    placeId: "ChIJ1cy7AFhQcEgRHJHa0PSkmqs",
    searchRadius: 2
  },
  {
    name: "Bath",
    placeId: "ChIJLeE-dKZ4cUgRCZpt1tAnixM",
    searchRadius: 2
  },
  {
    name: "Durham",
    placeId: "ChIJwbHYJaUqfEgRK0Ui9dVGimc",
    searchRadius: 2
  },
  {
    name: "Lincoln",
    placeId: "ChIJ-wQRccsMeEgRx93H6M89YDY",
    searchRadius: 2
  },
  {
    name: "Hereford",
    placeId: "ChIJQVNuqiXUb0gRlmw8_UuanNU",
    searchRadius: 2
  },
  {
    name: "Armagh",
    placeId: "ChIJjRbLfVWNYEgRSBjJzX1jLrk",
    searchRadius: 2
  },
  {
    name: "Inverness",
    placeId: "ChIJK94XLVtxj0gRPcQ-LtEJQ2I",
    searchRadius: 2
  },
  {
    name: "Stirling",
    placeId: "ChIJMXhDPNdXiEgRrXbVAjcf8Ps",
    searchRadius: 2
  },
  {
    name: "Canterbury",
    placeId: "ChIJiSyDDcVK2UcRivmDN1Pl8Z4",
    searchRadius: 2
  },
  {
    name: "Lichfield",
    placeId: "ChIJUXmcNNSgcEgRqI0kNeH9QLI",
    searchRadius: 2
  },
  {
    name: "Newry",
    placeId: "ChIJCyeEvSQzYEgRB2ctrkJAUlY",
    searchRadius: 2
  },
  {
    name: "Ripon",
    placeId: "ChIJ1SSPySkAfEgRT2SmHk8rtDE",
    searchRadius: 2
  },
  {
    name: "Bangor",
    placeId: "ChIJ_zQzgLUAZUgRv06vzs7KqrA",
    searchRadius: 2
  },
  {
    name: "Truro",
    placeId: "ChIJdRpa1XwQa0gRtAcdle9HY2E",
    searchRadius: 2
  },
  {
    name: "Ely",
    placeId: "ChIJz58AkVEYckgRMlQh6hfZQ1E",
    searchRadius: 2
  },
  {
    name: "St Davids",
    placeId: "ChIJe7wg-0ceaUgRmUO4zoZjWOw",
    searchRadius: 2
  }
];
