import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import Menu from "../shared/Menu";
import { connect } from "react-redux";
import { SUPER_ADMIN_ROLE } from "../constants";
import { managementRoutes, fullPathBuilder } from "../router/WebsiteManagementRoutes";

const menuItemBuilder = (path, label) => ({
  url: fullPathBuilder(path),
  label,
  key: path
})

function WebsiteManagementLayout({ children, location, userRole }) {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  return (
    <Row>
      <Col span={24} className="admin-website-management">
        <Menu
          items={[
            ...(userRole === SUPER_ADMIN_ROLE ? [
              menuItemBuilder(managementRoutes.HOME_PAGE, "Home page"),
            ] : []),
            menuItemBuilder(managementRoutes.ABOUT_US, "About Us"),
            ...(userRole === SUPER_ADMIN_ROLE ? [
              menuItemBuilder(managementRoutes.SELLING_PAGE, "Selling page"),
              menuItemBuilder(managementRoutes.CONTACT_US, "Contact us"),
              menuItemBuilder(managementRoutes.RESALE_DEALS, "Resale deals"),
              menuItemBuilder(managementRoutes.BLOG, "Blog"),
              menuItemBuilder(managementRoutes.FAQ, "FAQ"),
              menuItemBuilder(managementRoutes.TERMS_AND_CONDITIONS, "Terms and Conditions"),
              menuItemBuilder(managementRoutes.PRIVACY_POLICY, "Privacy policy"),
              menuItemBuilder(managementRoutes.COOKIES_POLICY, "Cookie policy"),
            ] : [])
          ]}
          mode="horizontal"
          selected={[active]}
        />
      </Col>

      <Col span={24}>
        { children }
      </Col>
    </Row>
  )
}

WebsiteManagementLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
  userRole: PropTypes.string,
};

export default withRouter(connect(
  state => ({
    isProfileFilled: state.profile.permissions.isProfileFilled,
    userRole: state.profile.permissions.role
  })
)(WebsiteManagementLayout));
