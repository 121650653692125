import React, { useEffect } from "react";

export const GoogleTagManager = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
      const script = document.createElement("script");
      script.type = "text/plain";
      script.setAttribute("data-cookie-consent", "tracking");
      script.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}');
      `;

      document.getElementsByTagName("head")[0].appendChild(script);

      const noScript = document.createElement("noscript");
      const iframe = document.createElement("iframe");
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`;
      iframe.height = 0;
      iframe.width = 0;
      iframe.style = "display:none;visibility:hidden";

      noScript.appendChild(iframe);
      document.getElementsByTagName("body")[0].prepend(noScript);
    }
  }, []);

  return <React.Fragment/>
};

export default GoogleTagManager;
