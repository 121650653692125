import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row } from "antd";
import { Controller, useForm } from "react-hook-form";
import Label from "../shared/Form/Label";
import * as yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  clearAuthErrorAction,
  clearResetPasswordEmailStatusAction,
  resetPasswordRequest
} from "../../redux/auth/authActions";
import ResetPasswordEmailSent from "./ResetPasswordEmailSent";

const updatePasswordUrl = "auth/set-new-password";

const ResetPasswordSchema = yup.object().shape({
  email: yup.string().email("Please provide correct email").required("Email can not be empty")
});

export const ResetPassword = ({
  isProcessing,
  resetPassword,
  clearResetPasswordEmailStatus,
  isResetPasswordEmailSent,
  error,
  clearError
}) => {
  const { handleSubmit, errors, control } = useForm({
    validationSchema: ResetPasswordSchema
  });

  const [email, setEmail] = useState();

  useEffect(() => {
    clearError();
    clearResetPasswordEmailStatus();
  }, []);

  const resetPasswordHandler = (data) => {
    setEmail(data.email);

    resetPassword({
      ...data,
      slug: updatePasswordUrl
    });
  };

  if (isResetPasswordEmailSent) {
    return <ResetPasswordEmailSent email={email} updatePasswordUrl={updatePasswordUrl}/>;
  }

  return (
    <Row justify="space-around" align="middle" className="auth-row">
      <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 8 }}>
        <div className="reset-password">
          <h1 className="reset-password__title">Reset your password</h1>

          <div className="reset-password__subtitle">
            Confirm your email and we'll send you a link to reset your password.
          </div>

          <form onSubmit={handleSubmit(resetPasswordHandler)} className="reset-password__form form-container">
            <div className="reset-password__field">
              <Label htmlFor="reset-password-email">Email</Label>
              <Controller
                name="email"
                as={<Input
                  id="reset-password-email"
                  className={`form-input ${errors.email ? "form-input--error" : ""}`}
                  data-testid="reset-password-email"
                />}
                control={control}
                onChange={([event]) => {
                  clearError();

                  return event.target.value;
                }}
              />
              {(error || errors.email) && <span
                className="reset-password__error"
                data-testid="reset-password-error"
              >{error || errors.email.message}</span>}
            </div>

            <Button
              className="submit-button"
              htmlType="submit"
              type="primary"
              disabled={isProcessing}
              data-testid="reset-password-submit"
              block
            >
              Reset My Password
            </Button>
          </form>
        </div>
      </Col>
    </Row>
  );
};

ResetPassword.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  clearResetPasswordEmailStatus: PropTypes.func.isRequired,
  isResetPasswordEmailSent: PropTypes.bool.isRequired,
  clearError: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.auth.processing,
    error: state.auth.error,
    isResetPasswordEmailSent: state.auth.isResetPasswordEmailSent
  }),
  {
    resetPassword: resetPasswordRequest,
    clearResetPasswordEmailStatus: clearResetPasswordEmailStatusAction,
    clearError: clearAuthErrorAction
  }
)(ResetPassword);
