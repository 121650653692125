import React, { Fragment } from "react";
import PropTypes from "prop-types";

const HotelCardGuests = ({ list = [] }) => {
  return (
    <ul className="hotel-card-guests">
      {list.map((item, index) => {
        return (
          <Fragment key={item}>
            <li>{item}</li>
            {index !== list.length - 1 ? <span>&#8226;</span> : null}
          </Fragment>
        );
      })}
    </ul>
  );
};

HotelCardGuests.propTypes = {
  list: PropTypes.array.isRequired
};

export default HotelCardGuests;
