import axios from "../../helpers/axios";

import { API_URL } from "../constants";

const staticPage = {
  async getStaticPage(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/pages/${data.permalink}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateStaticPage(data) {
    try {
      const result = await axios.put(`${API_URL}/admin/pages/${data.permalink}`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default staticPage;
