export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ACCOUNT_VERIFICATION_REQUEST = "ACCOUNT_VERIFICATION_REQUEST";
export const ACCOUNT_VERIFICATION_REQUEST_SUCCESS = "ACCOUNT_VERIFICATION_REQUEST_SUCCESS";
export const ACCOUNT_VERIFICATION_REQUEST_ERROR = "ACCOUNT_VERIFICATION_REQUEST_ERROR";
export const ACCOUNT_VERIFICATION_REQUEST_3DS_REQUIRED = "ACCOUNT_VERIFICATION_REQUEST_3DS_REQUIRED";

export const PROFILE_PERMISSIONS_REQUEST = "PROFILE_PERMISSIONS_REQUEST";
export const PROFILE_PERMISSIONS_REQUEST_SUCCESS = "PROFILE_PERMISSIONS_REQUEST_SUCCESS";
export const PROFILE_PERMISSIONS_REQUEST_ERROR = "PROFILE_PERMISSIONS_REQUEST_ERROR";

export const KYC_VERIFICATION_REQUEST = "KYC_VERIFICATION_REQUEST";
export const KYC_VERIFICATION_REQUEST_SUCCESS = "KYC_VERIFICATION_REQUEST_SUCCESS";
export const KYC_VERIFICATION_REQUEST_ERROR = "KYC_VERIFICATION_REQUEST_ERROR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_REQUEST_PROCESSING = "CHANGE_PASSWORD_REQUEST_PROCESSING";
export const CHANGE_PASSWORD_REQUEST_SUCCESS = "CHANGE_PASSWORD_REQUEST_SUCCESS";
export const CHANGE_PASSWORD_REQUEST_ERROR = "CHANGE_PASSWORD_REQUEST_ERROR";

export const CHANGE_EMAIL_REQUEST = "CHANGE_EMAIL_REQUEST";
export const CHANGE_EMAIL_REQUEST_PROCESSING = "CHANGE_EMAIL_REQUEST_PROCESSING";
export const CHANGE_EMAIL_REQUEST_SUCCESS = "CHANGE_EMAIL_REQUEST_SUCCESS";
export const CHANGE_EMAIL_REQUEST_ERROR = "CHANGE_EMAIL_REQUEST_ERROR";

export const CLOSE_ACCOUNT_REQUEST = "CLOSE_ACCOUNT_REQUEST";
export const CLOSE_ACCOUNT_REQUEST_SUCCESS = "CLOSE_ACCOUNT_REQUEST_SUCCESS";
export const CLOSE_ACCOUNT_REQUEST_ERROR = "CLOSE_ACCOUNT_REQUEST_ERROR";

export const CREATE_BANK_ACCOUNT_REQUEST = "CREATE_BANK_ACCOUNT_REQUEST";
export const CREATE_BANK_ACCOUNT_REQUEST_SUCCESS = "CREATE_BANK_ACCOUNT_REQUEST_SUCCESS";
export const CREATE_BANK_ACCOUNT_REQUEST_ERROR = "CREATE_BANK_ACCOUNT_REQUEST_ERROR";

export const GET_BANK_ACCOUNTS_REQUEST = "GET_BANK_ACCOUNTS_REQUEST";
export const GET_BANK_ACCOUNTS_REQUEST_SUCCESS = "GET_BANK_ACCOUNTS_REQUEST_SUCCESS";
export const GET_BANK_ACCOUNTS_REQUEST_ERROR = "GET_BANK_ACCOUNTS_REQUEST_ERROR";

export const DELETE_BANK_ACCOUNT_REQUEST = "DELETE_BANK_ACCOUNT_REQUEST";
export const DELETE_BANK_ACCOUNT_REQUEST_SUCCESS = "DELETE_BANK_ACCOUNT_REQUEST_SUCCESS";
export const DELETE_BANK_ACCOUNT_REQUEST_ERROR = "DELETE_BANK_ACCOUNT_REQUEST_ERROR";

export const UPDATE_BANK_ACCOUNT_REQUEST = "UPDATE_BANK_ACCOUNT_REQUEST";
export const UPDATE_BANK_ACCOUNT_REQUEST_SUCCESS = "UPDATE_BANK_ACCOUNT_REQUEST_SUCCESS";
export const UPDATE_BANK_ACCOUNT_REQUEST_ERROR = "UPDATE_BANK_ACCOUNT_REQUEST_ERROR";

export const GET_BALANCE_REQUEST = "GET_BALANCE_REQUEST";
export const GET_BALANCE_REQUEST_SUCCESS = "GET_BALANCE_REQUEST_SUCCESS";
export const GET_BALANCE_REQUEST_ERROR = "GET_BALANCE_REQUEST_ERROR";

export const WITHDRAW_MONEY_REQUEST = "WITHDRAW_MONEY_REQUEST";
export const WITHDRAW_MONEY_REQUEST_SUCCESS = "WITHDRAW_MONEY_REQUEST_SUCCESS";
export const WITHDRAW_MONEY_REQUEST_ERROR = "WITHDRAW_MONEY_REQUEST_ERROR";

export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_REQUEST_SUCCESS = "GET_TRANSACTIONS_REQUEST_SUCCESS";
export const GET_TRANSACTIONS_REQUEST_ERROR = "GET_TRANSACTIONS_REQUEST_ERROR";

export const CLEAR_PROFILE_ERROR = "CLEAR_PROFILE_ERROR";
export const SHOW_KYC_VALIDATION_FORM = "TOGGLE_KYC_VALIDATION_FORM";

export const CLEAR_VALIDATION_SEND_MESSAGE = "CLEAR_VALIDATION_SEND_MESSAGE";

export const CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST = "CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST";
export const CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST_SUCCESS = "CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST_SUCCESS";
export const CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST_ERROR = "CREATE_ACCOUNT_VERIFICATION_INTENT_REQUEST_ERROR";
