import * as types from "./dashboardActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  country: "GB",
  listings: {
    organic: 0,
    b2b: 0
  },
  users: {
    total: 0,
    newRegistrations: 0,
    notSoldProfile: 0
  },
  disputes: [],
  fees: [],
  notifications: [],
  funds: {
    organicFees: 0,
    b2bFees: 0,
    inDispute: 0,
    pending: 0,
    available: 0
  },
  geolocationData: {
    byListings: [],
    byUsers: []
  },
  salesListings: {
    organic: [],
    sales: []
  }
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_DASHBOARD_LISTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        listings: action.response,
        error: ""
      };
    case types.ADMIN_GET_DASHBOARD_LISTINGS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_GET_DASHBOARD_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        users: action.response,
        error: ""
      };
    case types.ADMIN_GET_DASHBOARD_USERS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_GET_DASHBOARD_DISPUTES_REQUEST_SUCCESS:
      return {
        ...state,
        disputes: action.response,
        error: ""
      };
    case types.ADMIN_GET_DASHBOARD_DISPUTES_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_GET_DASHBOARD_FEES_REQUEST_SUCCESS:
      return {
        ...state,
        fees: action.response,
        error: ""
      };
    case types.ADMIN_GET_DASHBOARD_FEES_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        notifications: action.response,
        error: ""
      };
    case types.ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_GET_DASHBOARD_FUNDS_REQUEST_SUCCESS:
      return {
        ...state,
        funds: action.response,
        error: ""
      };
    case types.ADMIN_GET_DASHBOARD_FUNDS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        geolocationData: action.response,
        error: ""
      };
    case types.ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        salesListings: action.response,
        error: ""
      };
    case types.ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_UPDATE_COUNTRY_VIEW_ACTION:
      return {
        ...state,
        country: action.data
      };
    default:
      return state;
  }
};

export default dashboard;
