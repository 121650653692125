import * as types from "./disputeActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: [],
  requestSuccess: false,
  singleDispute: {},
  singleDisputeNotes: []
};

const dispute = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
        requestSuccess: false
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_DISPUTE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: ""
      };
    case types.ADMIN_UPDATE_DISPUTE_REQUEST_SUCCESS:
      return {
        ...state,
        data: state.data.map(item => item.id === action.response.id ? { ...item, ...action.response } : item),
        singleDispute: { ...state.singleDispute, ...action.response, Listing: state.singleDispute.Listing },
        error: ""
      };
    case types.ADMIN_GET_DISPUTE_REQUEST_SUCCESS:
      return {
        ...state,
        singleDispute: action.response,
        singleDisputeNotes: action.response.DisputeNotes || [],
        error: ""
      };
    case types.ADMIN_CREATE_DISPUTE_NOTE_REQUEST_SUCCESS:
      return {
        ...state,
        singleDisputeNotes: action.response,
        error: ""
      };
    case types.ADMIN_GET_DISPUTE_LIST_REQUEST_ERROR:
    case types.ADMIN_GET_DISPUTE_REQUEST_ERROR:
    case types.ADMIN_UPDATE_DISPUTE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default dispute;
