import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import * as yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";

import { ISO31661ALPHA2 } from "../../api/constants";
import { updateProfileDetailsRequest } from "../../redux/profileDetails/profileDetailsActions";
import DobPicker from "../shared/DobPicker";
import Label from "../shared/Form/Label";
import SubmitButton from "../shared/SubmitButton"

const ProfileDetailsSchema = yup.object().shape({
  firstName: yup.string()
    .trim()
    .max(25, "Max length of First name - 25 symbols")
    .required("First name can not be empty"),
  lastName: yup.string().trim().max(25, "Max length of Last name - 25 symbols").required("Last name can not be empty"),
  dateOfBirth: yup.mixed().required("Date of Birth can not be empty").test({
    name: "isDateOfBirthValid",
    test: value => {
      const date = moment.utc(value);

      return date.isValid() && Math.abs(date.diff(moment.utc(), "years")) >= 18;
    },
    message: "Date of Birth should be valid date and you must be over the age of 18 to use our service"
  }),
  nationality: yup.mixed().required("Nationality can not be empty"),
  phone: yup.string().trim().test({
    name: "phoneLength",
    test: value => !!value && /[0-9]/.test(value) && value.length > 10 && value.length < 20
  })
});

export const ProfileDetails = ({ profile, isProcessing, isProfileDetailsUpdated, updateProfileDetails }) => {
  const [phone, setPhone] = useState({});

  const { handleSubmit, errors, control, reset, setValue, formState } = useForm({
    validationSchema: ProfileDetailsSchema
  });

  useEffect(() => {
    if (profile.id) {
      setPhone({
        number: profile.phoneNumber,
        countryCode: profile.phoneCountryCode
      });

      reset({
        firstName: profile.first_name,
        lastName: profile.last_name,
        dateOfBirth: profile.dateOfBirth ? moment.utc(profile.dateOfBirth) : null,
        nationality: profile.nationality,
        phone: profile?.phoneCountryCode ? `${profile.phoneCountryCode}${profile.phoneNumber}` : "+44"
      });
    }
  }, [profile]);

  const onSubmitHandler = (data) => {
    updateProfileDetails({
      ...data,
      phoneCountryCode: phone.countryCode,
      phoneNumber: phone.number
    });
  };

  return (
    <div className="profile-details">
      <h2 className="form-subtitle">Personal Details</h2>
      <form onSubmit={handleSubmit(onSubmitHandler)} className="profile-details__form">
        <div>
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <div className="profile-details__field profile-details__field--first-name">
                <Label>First Name</Label>
                <Controller
                  name="firstName"
                  as={<Input
                    id="profile-details-first-name"
                    className={`form-input ${errors.firstName ? "form-input--error" : ""}`}
                    data-testid="profile-details-first-name"
                  />}
                  control={control}
                  defaultValue={profile.first_name}
                  onChange={([event]) => {
                    return event.target.value;
                  }}
                />
                {errors.firstName && <span
                  className="profile-details__error"
                  data-testid="profile-details-error"
                >{errors.firstName.message}</span>}
              </div>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <div className="profile-details__field">
                <Label>Last Name</Label>
                <Controller
                  name="lastName"
                  as={<Input
                    id="profile-details-last-name"
                    className={`form-input ${errors.lastName ? "form-input--error" : ""}`}
                    data-testid="profile-details-last-name"
                  />}
                  control={control}
                  defaultValue={profile.last_name}
                  onChange={([event]) => {
                    return event.target.value;
                  }}
                />
                {errors.lastName && <span
                  className="profile-details__error"
                  data-testid="profile-details-error"
                >{errors.lastName.message}</span>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="profile-details__field">
                <Label>Email</Label>
                <Input readOnly disabled value={profile.email} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={`profile-details__field ${errors.dateOfBirth ? "profile-details__field--error" : ""}`}>
                <Label>Date of Birth</Label>
                <Controller
                  name="dateOfBirth"
                  disabled={isProcessing}
                  control={control}
                  as={<DobPicker
                    onChange={dateOfBirth => setValue("dateOfBirth", dateOfBirth)}
                    defaultValue={profile.dateOfBirth ? moment.utc(profile.dateOfBirth) : null}
                    data-testid="profile-details-date-picker"
                  />}
                />
                {errors.dateOfBirth && <span
                  className="profile-details__error"
                  data-testid="profile-details-error"
                >{errors.dateOfBirth.message}</span>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="profile-details__field">
                <Label>Nationality</Label>
                <Controller
                  name="nationality"
                  as={<Select
                    showSearch
                    optionFilterProp="children"
                    className="profile-details__nationality-select form-select"
                    defaultValue="UK"
                    data-testid="profile-details-nationality"
                  >
                    {
                      ISO31661ALPHA2.map(nationality => {
                        return <Select.Option value={nationality.code} key={nationality.code}>
                          {nationality.name}
                        </Select.Option>;
                      })
                    }
                  </Select>}
                  control={control}
                  defaultValue={profile.nationality}
                  onChange={([event]) => {
                    return event;
                  }}
                />
                {errors.nationality && <span
                  className="profile-details__error"
                  data-testid="profile-details-error"
                >{errors.nationality.message}</span>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="profile-details__field">
                <Label>Phone Number</Label>

                <Controller
                  name="phone"
                  as={<PhoneInput
                    id="profile-details-phone-number"
                    className={`form-input ${errors.phone ? "form-input--error" : ""}`}
                    inputProps={{
                      "data-testid": "profile-details-phone-number"
                    }}
                    country="gb"
                    preferredCountries={["gb", "us"]}
                    autoFormat
                    enableSearch
                    disableSearchIcon
                    countryCodeEditable={false}
                  />}

                  control={control}
                  defaultValue={profile?.phoneCountryCode
                    ? `${profile.phoneCountryCode}${profile.phoneNumber}` : "+44"}
                  onChange={([value, country]) => {
                    setPhone({
                      number: value.replace(country.dialCode, "").replace(/\D/g, ""),
                      countryCode: `${country.dialCode}`.replace(/\D/g, "")
                    });

                    return value;
                  }}
                />
                {errors.phone &&
                  <span
                    className="profile-details__error"
                    data-testid="profile-details-error"
                  >Invalid phone number</span>
                }
              </div>
            </Col>
          </Row>
        </div>
        <Row className="form-actions" justify="end">
          <Col>
            <SubmitButton
              success={isProfileDetailsUpdated && !isProcessing && !formState.dirty}
              disabled={isProcessing}
              text="Save details"
              successText="Saved"
              data-testid="profile-details-submit"
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

ProfileDetails.propTypes = {
  updateProfileDetails: PropTypes.func.isRequired,
  profile: PropTypes.object,
  isProcessing: PropTypes.bool,
  error: PropTypes.string,
  isProfileDetailsUpdated: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    isProcessing: state.profileDetails.isProfileDetailsUpdating,
    error: state.profileDetails.error,
    isProfileDetailsUpdated: state.profileDetails.isProfileDetailsUpdated
  }),
  {
    updateProfileDetails: updateProfileDetailsRequest
  }
)(ProfileDetails);
