import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Table } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

import { itemRender } from "../../../../helpers/pagination";
import { getListingStatus } from "../../../../helpers/common";
import { LISTING_TYPE_ORGANIC } from "../../../constants";
import { getUserListingsRequest } from "../../../../redux/admin/userManagement/userManagementActions";

export const UserListings = ({
  user,
  listings,
  isProcessing,
  pagination,
  getUserListings
}) => {
  const [paginationObj, setPaginationObj] = useState({
    pageSize: 10,
    userId: user.id
  });

  useEffect(() => {
    getUserListings(paginationObj);
  }, [paginationObj]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const renderLink = (text, record) => (
    <Link to={`/listings/${record.key}`} className="feedback-list__link" target="_blank">
      {text}
    </Link>
  );

  return (
    <div className="user-management__listings">
      <Row>
        <Col span={24}>
          <h2 className="form-subtitle">
            Listings created by this user
          </h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={isProcessing}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              itemRender: itemRender,
              showSizeChanger: false
            }}
            dataSource={listings.map(listing => {
              let listingStatus = getListingStatus(listing.status, listing.checkInDate);
              if (listingStatus !== "sold" && listing.Seller && listing.Seller.status === "suspended") {
                listingStatus = "suspended";
              }

              return {
                key: listing.slug,
                hotelName: listing.Hotel.name,
                id: listing.id,
                status: listingStatus,
                buyerName: listing.Buyer ? `${listing.Buyer.firstName} ${listing.Buyer.lastName}` : "",
                createdAt: moment(listing.createdAt).format("DD MMM YYYY"),
                checkInDate: moment(listing.checkInDate).format("DD MMM YYYY"),
                provider: listing.type === LISTING_TYPE_ORGANIC ? listing.provider : "",
                publishStatus: listing.publishStatus,
                paymentStatus: listing.type === LISTING_TYPE_ORGANIC ? (listing.isPaidInFull ? "Paid in full" : "Payment at the property") : ""
              }
            })}
            columns={[
              {
                title: "Title",
                dataIndex: "hotelName",
                key: "hotelName",
                render: renderLink,
                sorter: true,
                width: 150
              },
              {
                title: "Payment status",
                dataIndex: "paymentStatus",
                key: "paymentStatus",
                sorter: false,
                width: 120
              },
              {
                title: "Ref. no.",
                dataIndex: "id",
                key: "id",
                sorter: true,
                width: 90
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                sorter: true,
              },
              {
                title: "Buyer",
                dataIndex: "buyerName",
                key: "buyerName",
                sorter: true
              },
              {
                title: "Provider",
                dataIndex: "provider",
                key: "provider",
                sorter: true
              },
              {
                title: "Listed",
                dataIndex: "createdAt",
                key: "createdAt",
                defaultSortOrder: "descend",
                sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                sortDirections: ["descend", "ascend"],
                width: 145
              },
              {
                title: "Commencement",
                dataIndex: "checkInDate",
                key: "checkInDate",
                sorter: (a, b) => moment(a.checkInDate).unix() - moment(b.checkInDate).unix(),
                sortDirections: ["descend", "ascend"],
                width: 145
              },
              {
                title: "Active",
                dataIndex: "publishStatus",
                key: "publishStatus",
                defaultSortOrder: "",
                sorter: true,
                sortDirections: ["descend", "ascend"],
              },
            ]}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </div>
  )
};

UserListings.propTypes = {
  user: PropTypes.object.isRequired,
  listings: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  getUserListings: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    listings: state.admin.userManagement.singleUser.listings.data,
    pagination: {
      current: state.admin.userManagement.singleUser.listings.curPage,
      total: state.admin.userManagement.singleUser.listings.total,
      pageSize: state.admin.userManagement.singleUser.listings.perPage
    },
    isProcessing: state.admin.userManagement.processing
  }),
  {
    getUserListings: getUserListingsRequest
  }
)(UserListings);
