import * as types from "./subscriptionsActionsTypes";

export const getSubscriptionsListRequest = (data) => {
  return { type: types.ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST, data };
};

export const getCsvExportUrlRequest = (data) => {
  return { type: types.ADMIN_GET_SUBSCRIPTIONS_CSV_EXPORT_URL_REQUEST, data };
};

export const deleteSubscriptionRequest = data => {
  return { type: types.ADMIN_DELETE_SUBSCRIPTION_REQUEST, data };
};

export const clearSubscriptionsErrorAction = () => {
  return { type: types.ADMIN_CLEAR_SUBSCRIPTIONS_ERROR };
};
