import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Menu as AntMenu } from "antd";

const Menu = ({ items, mode = "horizontal", selected = [], menuProps = {} }) => {
  return (
    <AntMenu {...menuProps} selectedKeys={selected} mode={mode} className={`menu ${menuProps.className || ""}`}>
      { items.map(item => {
        return (
          <AntMenu.Item key={item.key}>
            <Link to={item.url}>{item.label}</Link>
          </AntMenu.Item>
        );
      })}
    </AntMenu>
  );
};

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  mode: PropTypes.string,
  selected: PropTypes.array,
  menuProps: PropTypes.object
};

export default Menu;
