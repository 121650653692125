import React from "react";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";

export const PasswordUpdated = () => {
  return (
    <Row justify="space-around" align="middle" className="auth-row">
      <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 8 }}>
        <div className="password-updated form-container">
          <h1 className="password-updated__title">Password Changed Successfully</h1>
          <img src="/images/double_tick.svg" alt="envelope" className="password-updated__icon"/>
          <Link to="/auth/sign-in" className="password-updated__login-link">
            <Button className="submit-button" type="primary" block>
              Login
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default PasswordUpdated;
