import { all, call, put, takeLatest } from "redux-saga/effects";

import blog from "../../api/blog";
import * as types from "./blogActionsTypes";
import serverSagaWrapper from "../../helpers/serverSagaWrapper";

function *getArticleList ({ data }) {
  yield put({ type: types.BLOG_GET_ARTICLE_LIST_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getArticleList, data);
    yield put({ type: types.BLOG_GET_ARTICLE_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_ARTICLE_LIST_REQUEST_ERROR, error: error.message });
  }
}

function *updateArticleList ({ data }) {
  yield put({ type: types.BLOG_GET_ARTICLE_LIST_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getArticleList, data);
    yield put({ type: types.BLOG_UPDATE_ARTICLE_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_ARTICLE_LIST_REQUEST_ERROR, error: error.message });
  }
}

function *getArticle ({ data, errorCallback }) {
  yield put({ type: types.BLOG_GET_ARTICLE_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getArticle, data);
    yield put({ type: types.BLOG_GET_ARTICLE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_ARTICLE_REQUEST_ERROR, error: error.message });
    yield call(errorCallback);
  }
}

function *getCategoryList ({ data }) {
  yield put({ type: types.BLOG_GET_CATEGORY_LIST_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getCategoryList, data);
    yield put({ type: types.BLOG_GET_CATEGORY_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_CATEGORY_LIST_REQUEST_ERROR, error: error.message });
  }
}

function *getArticleListByCategory ({ data }) {
  yield put({ type: types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getArticleList, data);
    yield put({ type: types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_ERROR, error: error.message });
  }
}

function *getLatestNews ({ data }) {
  yield put({ type: types.BLOG_GET_LATEST_NEWS_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getArticleList, data);
    yield put({ type: types.BLOG_GET_LATEST_NEWS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_LATEST_NEWS_REQUEST_ERROR, error: error.message });
  }
}

function *getNewsUpdates ({ data }) {
  yield put({ type: types.BLOG_GET_NEWS_UPDATES_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getArticleList, data);
    yield put({ type: types.BLOG_GET_NEWS_UPDATES_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_NEWS_UPDATES_REQUEST_ERROR, error: error.message });
  }
}

function *updateArticleListByCategory ({ data }) {
  yield put({ type: types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getArticleList, data);
    yield put({ type: types.BLOG_UPDATE_ARTICLE_LIST_BY_CATEGORY_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST_ERROR, error: error.message });
  }
}

function *getRelatedArticles ({ data }) {
  yield put({ type: types.BLOG_GET_RELATED_ARTICLES_REQUEST_PROCESSING });

  try {
    const response = yield call(blog.getRelatedArticles, data);
    yield put({ type: types.BLOG_GET_RELATED_ARTICLES_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.BLOG_GET_RELATED_ARTICLES_REQUEST_ERROR, error: error.message });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.BLOG_GET_ARTICLE_LIST_REQUEST, serverSagaWrapper(getArticleList)),
    yield takeLatest(types.BLOG_UPDATE_ARTICLE_LIST_REQUEST, updateArticleList),
    yield takeLatest(types.BLOG_GET_ARTICLE_REQUEST, serverSagaWrapper(getArticle)),
    yield takeLatest(types.BLOG_GET_CATEGORY_LIST_REQUEST, serverSagaWrapper(getCategoryList)),
    yield takeLatest(types.BLOG_GET_ARTICLE_LIST_BY_CATEGORY_REQUEST, serverSagaWrapper(getArticleListByCategory)),
    yield takeLatest(types.BLOG_GET_LATEST_NEWS_REQUEST, serverSagaWrapper(getLatestNews)),
    yield takeLatest(types.BLOG_GET_NEWS_UPDATES_REQUEST, serverSagaWrapper(getNewsUpdates)),
    yield takeLatest(types.BLOG_UPDATE_ARTICLE_LIST_BY_CATEGORY_REQUEST, updateArticleListByCategory),
    yield takeLatest(types.BLOG_GET_RELATED_ARTICLES_REQUEST, serverSagaWrapper(getRelatedArticles)),
  ])
}

