import React from "react";
import PropTypes from "prop-types";
import { parseDataForHotelCard } from "../../../helpers/common";
import HotelCard from "../../shared/HotelCard";

const MyListingsCard = ({ data = {} }) => {
  const hotelInfo = parseDataForHotelCard(data);
  const priceLabel = hotelInfo.status === "sold" ? "Sold for " : null;

  return (
    <HotelCard
      {...hotelInfo}
      priceLabel={priceLabel}
    />
  );
};

MyListingsCard.propTypes = {
  data: PropTypes.object.isRequired
};

export default MyListingsCard;
