import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const subscriptions = {
  async getSubscriptionsList(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/subscriptions?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getCsvExportUrl(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/subscriptions/export-csv?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteSubscription(data) {
    try {
      const result = await axios.delete(`${API_URL}/admin/subscriptions/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default subscriptions;
