import React from "react";
import PropTypes from "prop-types";
import moment from "moment"
import { Link } from "react-router-dom";
import { Rate } from "antd";

import { getCurrencyBadge } from "../../helpers/common";
import Image from "./Image";

const ListingCard = ({ listing, size = "big", baseElementSize = 10 }) => {
  const imageUrl = listing.Hotel && listing.Hotel.HotelImages && listing.Hotel.HotelImages[0] &&
    listing.Hotel.HotelImages[0].imageUrl;
  const hotelAddress = listing.Hotel && listing.Hotel.HotelAddress ? listing.Hotel.HotelAddress : {};

  return (
    <Link style={{ fontSize: `${baseElementSize}px` }} to={`/listings/${listing.slug}`} className={`listing-card listing-card--${size}`}>
      <div className="listing-card__container">
        { !!listing.discount &&
          <div className="listing-card__discount">{`-${listing.discount}% off`}</div>
        }
        { !!listing.hotelRating && size === "big" &&
          <div className="listing-card__hotel-rating">
            {listing.hotelRating}
            <Rate disabled count={1} defaultValue={1} />
          </div>
        }
        { size === "small" &&
          <div className="listing-card__selling-info-container">
            <div className="listing-card__price-container">
              { !!listing.originalPrice && +listing.originalPrice > +listing.sellingPrice &&
                <div style={{ fontSize: `${baseElementSize}px` }} className="listing-card__response-container">
                  <div className="listing-card__original-price">
                    {getCurrencyBadge(listing.Seller ? listing.Seller.currency : listing.currency)}
                    {(+listing.originalPrice).toFixed(2)}
                  </div>
                </div>
              }
              <div className="listing-card__price">
                {getCurrencyBadge(listing.Seller ? listing.Seller.currency : listing.currency)}
                {(+listing.sellingPrice).toFixed(2)}
              </div>
            </div>
          </div>
        }
        <Image imageUrl={imageUrl} alt={listing.Hotel.name} loading="eager"/>
        <div className="listing-card__info">
          <div className="listing-card__city-name">{hotelAddress.cityName}</div>
          <div className="listing-card__details-container">
            <div className="listing-card__dates">
              {moment(listing.checkInDate).format("MMM DD")} - {moment(listing.checkOutDate).format("MMM DD")}
            </div>
            { !!listing.hotelRating && size === "small" &&
              <div className="listing-card__hotel-rating">
                {listing.hotelRating}
                <Rate disabled count={1} defaultValue={1} />
              </div>
            }
            { size === "big" &&
              <div className="listing-card__selling-info-container">
                <div className="listing-card__price-container">
                  { !!listing.originalPrice && +listing.originalPrice > +listing.sellingPrice &&
                    <div style={{ fontSize: `${baseElementSize}px` }} className="listing-card__response-container">
                      <div className="listing-card__original-price">
                        {getCurrencyBadge(listing.Seller ? listing.Seller.currency : listing.currency)}
                        {(+listing.originalPrice).toFixed(2)}
                      </div>
                    </div>
                  }
                  <div className="listing-card__price">
                    {getCurrencyBadge(listing.Seller ? listing.Seller.currency : listing.currency)}
                    {(+listing.sellingPrice).toFixed(2)}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="listing-card__hotel-name">
        {listing.Hotel.name}
      </div>
    </Link>
  )
};

ListingCard.propTypes = {
  listing: PropTypes.object.isRequired,
  size: PropTypes.string,
  baseElementSize: PropTypes.number,
};

export default ListingCard;

