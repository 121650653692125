import { combineReducers } from "redux";

import adminArticle from "./admin/article/articleReducer";
import adminCategory from "./admin/category/categoryReducer";
import adminDispute from "./admin/dispute/disputeReducer";
import adminFeedback from "./admin/feedback/feedbackReducer";
import adminFinanceSettings from "./admin/finance/financeReducer";
import adminNotification from "./admin/notification/notificationReducer";
import adminStaticPage from "./admin/staticPages/staticPagesReducer";
import adminListings from "./admin/listings/listingsReducer";
import adminDashboard from "./admin/dashboard/dashboardReducer";
import adminFaq from "./admin/faq/faqReducer";
import adminExperiences from "./admin/experiences/experiencesReducer";
import adminSubscriptions from "./admin/subscriptions/subscriptionsReducer";
import adminHotels from "./admin/hotels/hotelsReducer";
import adminHighlights from "./admin/highlights/highlightsReducer";
import adminReservations from "./admin/reservations/reservationsReducer";
import adminPlaces from "./admin/places/placesReducer";
import userManagement from "./admin/userManagement/userManagementReducer";
import auth from "./auth/authReducer";
import blog from "./blog/blogReducer";
import general from "./general/generalReducer";
import highlights from "./highlights/highlightsReducer";
import listing from "./listing/listingReducer";
import profile from "./profile/profileReducer";
import profileDetails from "./profileDetails/profileDetailsReducer";
import places from "./places/placesReducer";
import staticPage from "./staticPages/staticPagesReducer";

// Combine reducer into single one
const rootReducer = combineReducers({
  admin: combineReducers({
    article: adminArticle,
    category: adminCategory,
    dashboard: adminDashboard,
    dispute: adminDispute,
    feedback: adminFeedback,
    finance: adminFinanceSettings,
    notification: adminNotification,
    listings: adminListings,
    staticPage: adminStaticPage,
    userManagement: userManagement,
    faq: adminFaq,
    experiences: adminExperiences,
    subscriptions: adminSubscriptions,
    hotels: adminHotels,
    highlights: adminHighlights,
    places: adminPlaces,
    reservations: adminReservations,
  }),
  auth,
  blog,
  general,
  highlights,
  listing,
  profile,
  profileDetails,
  places,
  staticPage
});

export default rootReducer;
