import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as yup from "yup";

import {
  updatePaymentFeesConfigurationRequest
} from "../../../redux/admin/finance/financeActions";
import InputWithLabel from "../../shared/InputWithLabel";
import { PAYMENT_FORM_TYPE_LISTING_PUBLISH } from "../../constants";

const verificationSchema = yup.object().shape({
  value: yup.number().min(0).required().typeError("Please enter a valid number."),
  vat: yup.number().min(0).required().typeError("Please enter a valid number."),
});

const ListingFeesConfigurationForm = ({
  paymentFeesConfigurations,
  isProcessing,
  updatePaymentFeesConfiguration,
}) => {

  const { handleSubmit, control, formState, reset, errors } = useForm({
    validationSchema: verificationSchema,
  });

  const { dirtyFields } = formState;

  const currency = paymentFeesConfigurations.currency.toUpperCase();

  const submitHandler = (data) => {
    updatePaymentFeesConfiguration({
      ...data,
      id: paymentFeesConfigurations.id,
    });
  };

  useEffect(() => {
    reset(paymentFeesConfigurations);
  }, [paymentFeesConfigurations]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <form onSubmit={handleSubmit(submitHandler)} className="listing-fees-configuration__container">
          <Row gutter={16} align="top">
            <Col span={10}>
              <InputWithLabel
                name="value"
                className="form-section__field"
                control={control}
                id="value"
                label={
                  `${paymentFeesConfigurations.type === PAYMENT_FORM_TYPE_LISTING_PUBLISH
                    ? "Publication fee"
                    : "Verification fee"}, ${currency === "USD" ? "$" : "£"}`
                }
                defaultValue={paymentFeesConfigurations.value
                  ? parseFloat(paymentFeesConfigurations.value)
                  : 0
                }
                error={errors.value}
                type="number"
                min={0}
                decimalSeparator="."
                placeholder="0.0"
              />
            </Col>
            <Col span={10}>
              <InputWithLabel
                name="vat"
                className="form-section__field"
                control={control}
                id="vat"
                label={"VAT, %"}
                defaultValue={paymentFeesConfigurations.vat ? parseFloat(paymentFeesConfigurations.vat) : 0}
                error={errors.vat}
                type="number"
                min={0}
                decimalSeparator="."
                placeholder="0,0"
              />
            </Col>
            <Col span={4} className="listing-fees-configuration__payment-submit-container">
              <Button
                htmlType="submit"
                disabled={isProcessing || !dirtyFields.size}
                className="button button--primary"
              >
                Save
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  )
};

ListingFeesConfigurationForm.propTypes = {
  paymentFeesConfigurations: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  updatePaymentFeesConfiguration: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isProcessing: state.admin.finance.processing
  }),
  {
    updatePaymentFeesConfiguration: updatePaymentFeesConfigurationRequest,
  }
)(ListingFeesConfigurationForm);
