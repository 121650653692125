import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Row, Col } from "antd";

import BlogArticleCard from "./BlogArticleCard";
import FollowUs from "./FollowUs";

import { getLatestNewsRequest } from "../../redux/blog/blogActions";
import { useServerSafeEffect, useMessage } from "../../hooks";
import Loader from "../shared/Loader";

const LatestNews = ({ latestNews, processing, error, getLatestNews }) => {
  useServerSafeEffect(() => {
    getLatestNews({ pageSize: 4, page: 1, type: "latest" })
  }, []);

  useMessage({ type: "error", error });

  return (
    <Row wrap={false} gutter={16} className="latest-news">
      {processing && !latestNews.length &&
        <Col
          lg={{ span: 24 }}
          xl={{ span: 4 * 6 }}
          xxl={{ span: 4 * 4 }}
        >
          <Loader type="section"/>
        </Col>
      }
      {latestNews?.map((article) => (
        <BlogArticleCard key={uuidv4()} article={article}/>
      ))}
      <Col
        className="blog-card-wrapper"
        xs={{ span: 12 }}
        sm={{ span: 8 }}
        lg={{ span: 6 }}
      >
        <FollowUs/>
      </Col>
    </Row>
  )
};

LatestNews.propTypes = {
  latestNews: PropTypes.array.isRequired,
  processing: PropTypes.bool.isRequired,
  getLatestNews: PropTypes.func.isRequired,
  error: PropTypes.string,
};


export default connect(
  state => ({
    latestNews: state.blog.latestNews.data,
    error: state.blog.latestNews.error,
    processing: state.blog.latestNews.processing,
  }),
  {
    getLatestNews: getLatestNewsRequest,
  }
)(LatestNews);

