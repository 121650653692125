import React from "react";
import { Controller } from "react-hook-form";
import { Input, InputNumber, Tooltip } from "antd";
import PropTypes from "prop-types";

import Label from "./Form/Label";

const InputWithLabel = ({
  id,
  name,
  className = "",
  control,
  onChange,
  label = "",
  tooltip,
  error = "",
  placeholder = "",
  type = "text",
  // eslint-disable-next-line
  defaultValue = undefined,
  isInvalid = false,
  maxLength = null,
  ...rest
}) => {
  if (!onChange) {
    if (type === "number") {
      onChange = ([value]) => value;
    } else {
      onChange = ([event]) => event.target.value;
    }
  }

  return (
    <div className={`input-with-label__field ${className}`}>
      {label &&
      <Label htmlFor={id} className={error ? "input-with-label__label-error" : ""}>
        {label}
        {
          tooltip &&
          <Tooltip placement="topLeft" title={tooltip} className="profile__field-tooltip" arrowPointAtCenter>
            <span>*</span>
          </Tooltip>
        }
      </Label>
      }
      { type === "number" ? (
        <Controller
          name={name}
          defaultValue={defaultValue}
          as={<InputNumber
            id={id}
            placeholder={placeholder}
            maxLength={maxLength}
            className={`form-input ${(error || isInvalid) ? "form-input--error" : ""}`}
            data-testid={id}
            {...rest}
          />}
          control={control}
          onChange={onChange}
        />
      ) : (
        <Controller
          name={name}
          defaultValue={defaultValue}
          as={<Input
            id={id}
            placeholder={placeholder}
            type={type}
            maxLength={maxLength}
            className={`form-input ${(error || isInvalid) ? "form-input--error" : ""}`}
            data-testid={id}
            {...rest}
          />}
          control={control}
          onChange={onChange}
        />
      )}
      {error && <span className="input-with-label__error" data-testid="input-with-label-error">{error.message}</span>}
    </div>
  );
};

InputWithLabel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isInvalid: PropTypes.bool,
  maxLength: PropTypes.number
};

export default InputWithLabel;
