import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Card, Rate, Input, Table } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { getStaticPageRequest, updateStaticPageRequest } from "../../../redux/admin/staticPages/staticPagesActions";
import { getHotelsRequest } from "../../../redux/admin/hotels/hotelsActions";
import { getHighlightsRequest, addHighlightRequest,
  deleteHighlightRequest, updateHighlightRequest } from "../../../redux/admin/highlights/highlightsActions";
import RichTextEditor from "../../shared/RichTextEditor/RichTextEditor";
import InputWithLabel from "../../shared/InputWithLabel";
import testHtmlLength from "./helpers/testHtmlLength";
import { itemRender } from "../../../helpers/pagination";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { pullAt } from "lodash";
import arrayMove from "./helpers/arrayMove";
import debounce from "lodash/debounce";

const CommentCard = SortableElement(({ nameValue, locationValue, starsNumber, textValue, onEdit, onDelete }) => {
  const { control, errors, watch, setValue } = useForm({
    validationSchema: yup.object().shape({
      name: yup.string().trim().required("Name can not be empty"),
      location: yup.string().trim().required("Location can not be empty"),
      text: yup.string().trim().required("Text can not be empty"),
    })
  });

  const commentData = watch();
  const { name, location, text, stars } = commentData;

  useEffect(() => {
    if (name || location || text || stars) {
      onEdit(commentData);
    }
  }, [name, location, text, stars]);

  return (
    <Card
      style={{ cursor: "move", flexGrow: 1, width: "calc(33% - 14px)", maxWidth: "calc(33% - 14px)" }}
      actions={[
        <DeleteOutlined key="delete" onClick={() => onDelete()} />,
      ]}
    >
      <Row className="admin-home-page__row">
        <Col span={24}>
          <InputWithLabel
            name="name"
            control={control}
            id="name"
            label="User name"
            error={errors.name}
            placeholder="Name"
            defaultValue={nameValue}
          />
        </Col>
      </Row>
      <Row className="admin-home-page__row">
        <Col span={24}>
          <InputWithLabel
            name="location"
            control={control}
            id="location"
            label="Location"
            error={errors.location}
            placeholder="Location"
            defaultValue={locationValue}
          />
        </Col>
      </Row>
      <Row className="admin-home-page__row">
        <Col span={24}>
          <Controller
            name="stars"
            as={(
              <Rate
                defaultValue={starsNumber}
                onChange={(num) => setValue("stars", num)}
                value={stars}
              />
            )}
            defaultValue={starsNumber}
            control={control}
            onChange={([value]) => value }
          />
        </Col>
      </Row>
      <Row className="admin-home-page__row">
        <Col span={24}>
          <Controller
            name="text"
            as={(
              <Input.TextArea
                id="text"
                label="Review text"
                error={errors.text}
                placeholder="Review text"
                defaultValue={textValue}
                rows={4}
              />
            )}
            defaultValue={textValue}
            control={control}
            onChange={([value]) => value }
          />
        </Col>
      </Row>
    </Card>
  )
});

CommentCard.propTypes = {
  nameValue: PropTypes.string,
  locationValue: PropTypes.string,
  starsNumber: PropTypes.number,
  textValue: PropTypes.string,
};

CommentCard.defaultProps = {
  nameValue: "",
  locationValue: "",
  starsNumber: 0,
  textValue: "",
};

const CommentsList = SortableContainer(({ items, onAddReview, onEditBuilder, onDeleteBuilder }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "24px", flexWrap: "wrap", alignItems: "stretch" }}>
      { items.map(({ name, location, stars, text }, idx) => (
        <CommentCard
          index={idx}
          key={Math.random()}
          locationValue={location}
          nameValue={name}
          starsNumber={stars}
          textValue={text}
          onEdit={onEditBuilder(idx)}
          onDelete={onDeleteBuilder(idx)}
        />
      )) }
      <Button
        style={{
          flexGrow: 1,
          width: "calc(33% - 14px)",
          maxWidth: "calc(33% - 14px)",
          height: "462px",
          borderRadius: "4px",
        }}
        type="dashed"
        icon={<PlusOutlined style={{ fontSize: "30px" }} />}
        onClick={onAddReview}
      />
    </div>
  );
})

const HomePageSchema = yup.object().shape({
  title: yup.string().trim().required("Title can not be empty"),
  subtitle: yup.string().trim(),
  sellMainContent: yup.string().trim(),
  sellSubContent: yup.string().trim(),
  buyMainContent: yup.string().trim(),
  buySubContent: yup.string().trim(),
  signUpText: yup.string().trim().required("Sign up text can not be empty"),
  pageMainContent: yup.string().trim()
    .test({
      name: "pageMainContentLength",
      message: "Page content can not be empty",
      test: testHtmlLength
    }),
  wwaSecondContent: yup.string().trim().required("Field can not be empty"),
  wwaFirstContent: yup.string().trim().required("Field can not be empty"),
  wwaThirdContent: yup.string().trim().required("Field can not be empty"),
  wwaFourthContent: yup.string().trim().required("Field can not be empty"),
  partnersContent: yup.string().trim()
    .test({
      name: "partnersContentLength",
      message: "Partners content can not be empty",
      test: testHtmlLength
    }),
  reviewsContent: yup.string().trim()
    .test({
      name: "reviewsContentLength",
      message: "Reviews content can not be empty",
      test: testHtmlLength
    }),
});

const HomePage = ({ isProcessing, updatePage, page, getPage, location,
  highlights, hotels, pagination, getHotels,
  getHighlights, deleteHighlight, addHighlight, updateHighlight }) => {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  const { handleSubmit, control, errors, reset, setValue, watch } = useForm({
    validationSchema: HomePageSchema,
  });
  const data = watch();

  const [reviewsContent, setReviewsContent] = useState([]);
  const [reviewsMoved, setReviewsMoved] = useState({ moved: false, oldIndex: 0, newIndex: 0 });
  const [reviewDeleted, setReviewDeleted] = useState({ deleted: false, index: -1 });
  const [reviewAdded, setReviewAdded] = useState({ added: false });
  const [reviewEdited, setReviewEdited] = useState({ edited: false, value: null, idx: -1 });

  const onAddReview = () => {
    const newRevArr = [...reviewsContent, { name: "", locations: "", text: "", stars: 5 }];
    setValue("reviews", newRevArr);
    setReviewsContent(newRevArr);
  };

  const onMoveReview = ({ newIndex, oldIndex }) => {
    if (newIndex === oldIndex) return;

    const newRevArr = arrayMove(reviewsContent || [], oldIndex, newIndex);
    setValue("reviews", newRevArr);
    setReviewsContent(newRevArr);
  };

  const onDeleteReview = (idx) => {
    const newRevArr = [...reviewsContent];
    pullAt(newRevArr, idx);
    setValue("reviews", newRevArr);
    setReviewsContent(newRevArr);
  }

  useEffect(() => {
    getPage({ permalink: active });
  }, []);

  useEffect(() => {
    const reviews = JSON.parse(page.reviews || "[]");
    reset({
      ...page,
      reviews,
    });
    setReviewsContent(reviews);
  }, [page]);

  useEffect(() => {
    if (reviewsMoved.moved) {
      onMoveReview(reviewsMoved);
      setReviewsMoved({ moved: false, oldIndex: 0, newIndex: 0 });
    }
  }, [reviewsMoved]);

  useEffect(() => {
    if (reviewDeleted.deleted) {
      onDeleteReview(reviewDeleted.index);
      setReviewDeleted({ deleted: false, index: -1 });
    }
  }, [reviewDeleted]);

  useEffect(() => {
    if (reviewAdded.added) {
      onAddReview();
      setReviewAdded({ added: false });
    }
  }, [reviewAdded]);

  useEffect(() => {
    if (reviewEdited.edited) {
      const newRevList = [...reviewsContent];
      newRevList[reviewEdited.idx] = reviewEdited.value;
      setReviewsContent(newRevList);
      setReviewEdited({ edited: false, value: null, idx: -1 });
    }
  }, [reviewEdited]);

  const submitHandler = (formData) => {
    updatePage({
      ...formData,
      reviews: JSON.stringify(reviewsContent),
      permalink: active
    });
  };

  const [searching, setSearching] = useState(false)

  const [paginationObj, setPaginationObj] = useState({
    pageSize: 10,
    sort: "name"
  });

  const [paginationHighlightObj] = useState({
    pageSize: 50,
    sort: "name"
  });

  const formattedHighlights = useMemo(() => {
    const fHighlights = [];
    if (searching) {
      hotels.forEach((hotel) => {
        fHighlights.push(
          {
            ...hotel,
            isHighlight: false,
            hotelId: hotel.id
          });
      });
    } else {
      highlights.forEach((highlight) => {
        fHighlights.push(
          {
            ...highlight,
            isHighlight: true,
            name: highlight.Hotel.name,
            hotelId: highlight.Hotel.id
          });
      });
    }

    return fHighlights
  }, [highlights, hotels]);


  useEffect(() => {
    if (!paginationObj.search) {
      setSearching(false);
      getHighlights(paginationHighlightObj);
    } else {
      setSearching(true);
      getHotels(paginationObj);
    }
  }, [paginationObj]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleSearch = (value) => {
    if (!value) {
      setPaginationObj({ pageSize: 50, sort: "name", page: 1 });
    }
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderToggleHighlight = record => (
    <img
      id={`${record.key}`}
      src={record.enabled ? "/images/check-white.svg" : "/images/check-grey.svg"}
      alt="toggle"
      className={record.enabled ? "highlight-list__toggle enabled" : "highlight-list__toggle"}
      onClick={(e) => {
        if (e.target) {
          if (record.enabled) {
            record.enabled = false
            e.target.classList.remove("enabled");
            e.target.src = "/images/check-grey.svg"
            deleteHighlight({ id: record.key })
            record.isHighlight = false
          } else {
            record.enabled = true
            e.target.classList.add("enabled");
            e.target.src = "/images/check-white.svg"
            addHighlight({ hotelId: record.hotelId,
              maxPercentage: parseInt(record.maxPercentage, 10),
              enabled: true })
            record.isHighlight = true
            handleSearch()
          }
        }
      }}
    />
  );

  const renderUptoInput = record => (
    <input
      defaultValue={`${record.maxPercentage || "0"}`}
      type="number"
      className="highlight-list__input-upto"
      onBlur={() => {
        if (record.isHighlight && record.unsaved) {
          updateHighlight({ id: record.id, maxPercentage: parseInt(record.maxPercentage, 10), enabled: true })
        }
      }
      }
      onInput={(e) => {
        const self = e.target
        const value = self.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
        self.value = value
        record.maxPercentage = value
        record.unsaved = true
      }
      }
    />
  );
  renderUptoInput

  return (
    <Row className="admin-home-page">
      <Col span={24}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Row className="admin-section">
            <Col span={24}>
              <Row>
                <Col span={24} className="admin-home-page__title">
                  Edit meta
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaTitle"
                    control={control}
                    id="metaTitle"
                    label="Title"
                    error={errors.metaTitle}
                    placeholder="Title"
                    defaultValue={page.metaTitle}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaDescription"
                    control={control}
                    id="metaDescription"
                    label="Description"
                    error={errors.metaDescription}
                    placeholder="Description"
                    defaultValue={page.metaDescription}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaKeywords"
                    control={control}
                    id="metaKeywords"
                    label="Keywords"
                    error={errors.metaKeywords}
                    placeholder="Keywords"
                    defaultValue={page.metaKeywords}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="admin-home-page__title">
                  Edit content
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="title"
                    control={control}
                    id="title"
                    label="Title"
                    error={errors.title}
                    placeholder="Title"
                    defaultValue={page.title}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="subtitle"
                    control={control}
                    id="subtitle"
                    label="Subtitle"
                    error={errors.subtitle}
                    placeholder="Subtitle"
                    defaultValue={page.subtitle}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <Card title="Sell card" bordered={false}>
                    <Row className="admin-home-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="sellMainContent"
                          control={control}
                          id="sellMainContent"
                          label="Main content"
                          error={errors.sellMainContent}
                          placeholder="Main content"
                          defaultValue={page.sellMainContent}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-home-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="sellSubContent"
                          control={control}
                          id="sellSubContent"
                          label="Sub content"
                          error={errors.sellSubContent}
                          placeholder="Sub content"
                          defaultValue={page.sellSubContent}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Buy card" bordered={false}>
                    <Row className="admin-home-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="buyMainContent"
                          control={control}
                          id="buyMainContent"
                          label="Main content"
                          error={errors.buyMainContent}
                          placeholder="Main content"
                          defaultValue={page.buyMainContent}
                        />
                      </Col>
                    </Row>
                    <Row className="admin-home-page__row">
                      <Col span={24}>
                        <InputWithLabel
                          name="buySubContent"
                          control={control}
                          id="buySubContent"
                          label="Sub content"
                          error={errors.buySubContent}
                          placeholder="Sub content"
                          defaultValue={page.buySubContent}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} className="admin-home-page__row">
                <Col span={12}>
                  <InputWithLabel
                    name="signUpText"
                    control={control}
                    id="signUpText"
                    label="Sign up text"
                    error={errors.signUpText}
                    placeholder="Sign up text"
                    defaultValue={page.signUpText}
                  />
                </Col>
              </Row>
              <Row className="admin-home-page__row">
                <Col span={24}>
                  <label className="admin-home-page__label">Page main content</label>
                  <Controller
                    name="pageMainContent"
                    as={<RichTextEditor
                      value={page.pageMainContent}
                    />}
                    defaultValue={page.pageMainContent}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.pageMainContent &&
                    <span className="admin-home-page__error">{errors.pageMainContent.message}</span>
                  }
                </Col>
              </Row>
              <label className="admin-home-page__label">"Who we are" cards</label>
              <Row gutter={24} className="admin-home-page__row">
                <Col span={6}>
                  <Card title="1st card" bordered={false}>
                    <InputWithLabel
                      name="wwaFirstContent"
                      control={control}
                      id="wwaFirstContent"
                      label="Content"
                      error={errors.wwaFirstContent}
                      placeholder="Content"
                      defaultValue={page.wwaFirstContent}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="2nd card" bordered={false}>
                    <InputWithLabel
                      name="wwaSecondContent"
                      control={control}
                      id="wwaSecondContent"
                      label="Content"
                      error={errors.wwaSecondContent}
                      placeholder="Content"
                      defaultValue={page.wwaSecondContent}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="3rd card" bordered={false}>
                    <InputWithLabel
                      name="wwaThirdContent"
                      control={control}
                      id="wwaThirdContent"
                      label="Content"
                      error={errors.wwaThirdContent}
                      placeholder="Content"
                      defaultValue={page.wwaThirdContent}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="4th card" bordered={false}>
                    <InputWithLabel
                      name="wwaFourthContent"
                      control={control}
                      id="wwaFourthContent"
                      label="Content"
                      error={errors.wwaFourthContent}
                      placeholder="Content"
                      defaultValue={page.wwaFourthContent}
                    />
                  </Card>
                </Col>
              </Row>
              <Row className="admin-home-page__row">
                <Col span={24}>
                  <label className="admin-home-page__label">Partners section content</label>
                  <Controller
                    name="partnersContent"
                    as={<RichTextEditor
                      value={page.partnersContent}
                    />}
                    defaultValue={page.partnersContent}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.partnersContent &&
                    <span className="admin-home-page__error">{errors.partnersContent.message}</span>
                  }
                </Col>
              </Row>
              <Row className="admin-home-page__row">
                <Col span={24}>
                  <label className="admin-home-page__label">Reviews section content</label>
                  <Controller
                    name="reviewsContent"
                    as={<RichTextEditor
                      value={page.reviewsContent}
                    />}
                    defaultValue={page.reviewsContent}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.reviewsContent &&
                    <span className="admin-home-page__error">{errors.reviewsContent.message}</span>
                  }
                </Col>
              </Row>
              <Row className="admin-home-page__row">
                <Col span={24}>
                  {
                    useMemo(() => (
                      <Controller
                        name="reviews"
                        as={<CommentsList
                          items={data.reviews || []}
                          onSortEnd={({ newIndex, oldIndex }) => setReviewsMoved({
                            moved: true,
                            newIndex,
                            oldIndex,
                          })}
                          axis="xy"
                          onAddReview={() => { setReviewAdded({ added: true }) }}
                          onEditBuilder={(id) => (reviewData) => {
                            setReviewEdited({ edited: true, idx: id, value: reviewData });
                          }}
                          onDeleteBuilder={(id) => () => {
                            setReviewDeleted({ deleted: true, index: id });
                          }}
                          pressDelay={200}
                        />}
                        defaultValue={data.reviews}
                        control={control}
                        onChange={([value]) => value}
                      />
                    ), [data.reviews])
                  }
                </Col>
              </Row>
              <Row className="admin-home-page__row">
                <Col span={24}>
                  <label className="admin-home-page__label">Hotel Highlight section</label>

                  <Row justify="space-between" align="top">
                    <Col span={12}></Col>
                    <Col span={12} className="hotels-highlights-list__search-wrapper">
                      <Input.Search
                        allowClear
                        onChange={event => delayedSearch(event.target.value)}
                        onSearch={handleSearch}
                        placeholder="Search Hotels"
                        className="feedback-list__search"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Table
                        loading={isProcessing}
                        pagination={{
                          disabled: !searching,
                          current: pagination.current,
                          pageSize: pagination.pageSize,
                          total: searching ? pagination.total : 0,
                          itemRender: itemRender,
                          showSizeChanger: false
                        }}
                        dataSource={formattedHighlights.map(highlight => {
                          return {
                            key: highlight.id,
                            name: highlight.name,
                            id: highlight.id,
                            hotelId: highlight.hotelId,
                            maxPercentage: highlight.maxPercentage || 0,
                            enabled: highlight.enabled || false,
                            isHighlight: highlight.isHighlight || false
                          }
                        })}
                        columns={[
                          {
                            title: "Name",
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                            defaultSortOrder: "ascend",
                          },
                          {
                            title: "Ref. no.",
                            dataIndex: "hotelId",
                            key: "id",
                            sorter: true
                          },
                          {
                            title: "Upto %",
                            key: "maxPercentage",
                            width: 40,
                            render: renderUptoInput
                          },
                          {
                            title: "",
                            key: "actions",
                            width: 40,
                            render: renderToggleHighlight
                          }
                        ]}
                        onChange={handleTableChange}
                      />
                    </Col>
                  </Row>
                  { errors.highlightContent &&
                    <span className="admin-home-page__error">{errors.highlightContent.message}</span>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="admin-home-page__submit">
              <Button htmlType="submit" disabled={isProcessing} className="button button--primary">Save</Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  )
};

HomePage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  getPage: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  hotels: PropTypes.array.isRequired,
  highlights: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  getHotels: PropTypes.func.isRequired,
  getHighlights: PropTypes.func.isRequired,
  deleteHighlight: PropTypes.func.isRequired,
  addHighlight: PropTypes.func.isRequired,
  updateHighlight: PropTypes.func.isRequired
};

export default connect(
  state => ({
    hotels: state.admin.hotels.data,
    highlights: state.admin.highlights.data,
    pagination: {
      current: state.admin.hotels.curPage,
      total: state.admin.hotels.total,
      pageSize: state.admin.hotels.perPage
    },
    isProcessing: state.admin.staticPage.processing,
    error: state.admin.staticPage.error,
    page: state.admin.staticPage.page,
    requestSuccess: state.admin.hotels.requestSuccess
  }),
  {
    getHighlights: getHighlightsRequest,
    deleteHighlight: deleteHighlightRequest,
    addHighlight: addHighlightRequest,
    updateHighlight: updateHighlightRequest,
    getHotels: getHotelsRequest,
    getPage: getStaticPageRequest,
    updatePage: updateStaticPageRequest
  }
)(HomePage);
