import { all, put, call, takeLatest } from "redux-saga/effects";

import faq from "../../../api/admin/faq";
import * as types from "./faqActionsTypes";
import * as generalTypes from "./../../general/generalActionsTypes";
import { MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR } from "../../../react/constants";

function *createQuestion ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.createQuestion, data);
    yield put({ type: types.ADMIN_CREATE_QUESTION_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_CLEAR_QUESTION, response });
    yield put({ type: types.ADMIN_CLEAR_QUESTION_ERROR, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: `Question ${data.action === "publish" ? "published" : "created" } successfully`
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_CREATE_QUESTION_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
    }
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *updateQuestion ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.updateQuestion, data);
    yield put({ type: types.ADMIN_UPDATE_QUESTION_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: `Question ${data.action === "publish" ? "published" : (data.action === "unpublish" ? "unpublished" : "saved") } successfully`
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_QUESTION_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
    }
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *getQuestion ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.getQuestion, data);
    yield put({ type: types.ADMIN_GET_QUESTION_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_QUESTION_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *getQuestions ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.getQuestions, data);
    yield put({
      type: types.ADMIN_GET_QUESTIONS_REQUEST_SUCCESS,
      response: data.pageSize === -1 ? { data: response } : response
    });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_QUESTIONS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *deleteQuestion ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.deleteQuestion, data);
    yield put({ type: types.ADMIN_DELETE_QUESTION_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_GET_QUESTIONS_REQUEST });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_QUESTION_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *getCategories ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.getCategories, data);
    yield put({
      type: types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_SUCCESS,
      response: data.pageSize === -1 ? { data: response } : response
    });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *createCategory ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.createCategory, data);
    yield put({ type: types.ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST_SUCCESS, response });
    data = { sort: data.sort };
    yield put({ type: types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST, data });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Category created successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
    }
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *deleteCategory ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.deleteCategory, data);
    yield put({ type: types.ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST_SUCCESS, response });
    data = { sort: data.sort };
    yield put({ type: generalTypes.HIDE_MODAL });
    yield put({ type: types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST, data });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *getCategory ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.getCategory, data);
    yield put({ type: types.ADMIN_GET_QUESTIONS_CATEGORY_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_QUESTIONS_CATEGORY_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *updateQuestionsOrder ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.updateQuestionsOrder, data);
    yield put({ type: types.ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
    }
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *resetQuestionsOrder ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.resetQuestionsOrder, data);
    yield put({ type: types.ADMIN_RESET_QUESTIONS_ORDER_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_GET_QUESTIONS_REQUEST, data: { categoryId: data.categoryId, sort: "position,title" } });
  } catch (error) {
    yield put({ type: types.ADMIN_RESET_QUESTIONS_ORDER_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
    }
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

function *updateCategoriesOrder ({ data }) {
  yield put({ type: types.FAQ_SHOW_LOADING });

  try {
    const response = yield call(faq.updateCategoriesOrder, data);
    yield put({ type: types.ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
    }
  } finally {
    yield put({ type: types.FAQ_HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_CREATE_QUESTION_REQUEST, createQuestion),
    yield takeLatest(types.ADMIN_UPDATE_QUESTION_REQUEST, updateQuestion),
    yield takeLatest(types.ADMIN_GET_QUESTION_REQUEST, getQuestion),
    yield takeLatest(types.ADMIN_GET_QUESTIONS_REQUEST, getQuestions),
    yield takeLatest(types.ADMIN_DELETE_QUESTION_REQUEST, deleteQuestion),
    yield takeLatest(types.ADMIN_GET_QUESTIONS_CATEGORIES_REQUEST, getCategories),
    yield takeLatest(types.ADMIN_CREATE_QUESTIONS_CATEGORY_REQUEST, createCategory),
    yield takeLatest(types.ADMIN_DELETE_QUESTIONS_CATEGORY_REQUEST, deleteCategory),
    yield takeLatest(types.ADMIN_GET_QUESTIONS_CATEGORY_REQUEST, getCategory),
    yield takeLatest(types.ADMIN_UPDATE_QUESTIONS_ORDER_REQUEST, updateQuestionsOrder),
    yield takeLatest(types.ADMIN_RESET_QUESTIONS_ORDER_REQUEST, resetQuestionsOrder),
    yield takeLatest(types.ADMIN_UPDATE_QUESTIONS_CATEGORIES_ORDER_REQUEST, updateCategoriesOrder)
  ])
}
