import * as types from "./placesActionsTypes";

export const initialState = {
  list: [],
  processing: false,
  error: "",
  singlePlace: {}
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.GET_PLACES_REQUEST_SUCCESS:
      return {
        ...state,
        list: action.response,
        error: ""
      };
    case types.SET_PLACES_LIST:
      return {
        ...state,
        list: action.data,
        error: ""
      };
    case types.GET_PLACE_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        singlePlace: action.response,
        error: ""
      };
    case types.GET_PLACES_REQUEST_ERROR:
    case types.GET_PLACE_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default profile;
