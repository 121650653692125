import * as types from "./hotelsActionsTypes";

export const getHotelsRequest = (data) => {
  return { type: types.ADMIN_GET_HOTELS_REQUEST, data };
};

export const deleteHotelRequest = (data) => {
  return { type: types.ADMIN_DELETE_HOTEL_REQUEST, data };
};

export const getHotelsFacilitiesRequest = (data) => {
  return { type: types.ADMIN_GET_HOTELS_FACILITIES_REQUEST, data };
};

export const addHotelRequest = (data, callBack) => {
  return { type: types.ADMIN_ADD_HOTEL_REQUEST, data, callBack };
};

export const getHotelRequest = (data) => {
  return { type: types.ADMIN_GET_HOTEL_REQUEST, data };
};

export const updateHotelRequest = (data) => {
  return { type: types.ADMIN_UPDATE_HOTEL_REQUEST, data };
};

export const clearHotelDetailsAction = (data) => {
  return { type: types.ADMIN_CLEAR_HOTEL_DETAILS_ACTION, data };
};
