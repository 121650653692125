import * as types from "./dashboardActionsTypes";

export const getDashboardListingsRequest = (data) => {
  return { type: types.ADMIN_GET_DASHBOARD_LISTINGS_REQUEST, data };
};

export const getDashboardUsersRequest = (data) => {
  return { type: types.ADMIN_GET_DASHBOARD_USERS_REQUEST, data };
};

export const getDashboardDisputesRequest = (data) => {
  return { type: types.ADMIN_GET_DASHBOARD_DISPUTES_REQUEST, data };
};

export const getDashboardFeesRequest = (data) => {
  return { type: types.ADMIN_GET_DASHBOARD_FEES_REQUEST, data };
};

export const getDashboardNotificationsRequest = (data) => {
  return { type: types.ADMIN_GET_DASHBOARD_NOTIFICATIONS_REQUEST, data };
};

export const getDashboardFundsRequest = (data) => {
  return { type: types.ADMIN_GET_DASHBOARD_FUNDS_REQUEST, data };
};

export const getDashboardGeolocationDataRequest = (data) => {
  return { type: types.ADMIN_GET_DASHBOARD_GEOLOCATION_DATA_REQUEST, data };
};

export const getDashboardSalesListingsRequest = (data) => {
  return { type: types.ADMIN_GET_DASHBOARD_SALES_LISTINGS_REQUEST, data };
};

export const updateAdminCountryViewAction = (data) => {
  return { type: types.ADMIN_UPDATE_COUNTRY_VIEW_ACTION, data };
};
