import React from "react";
import PropTypes from "prop-types"

const Label = ({ className, ...props }) => {

  return (
    <label className={`form-label ${className ? className : ""}`} {...props} />
  );
};

Label.propTypes = {
  className: PropTypes.string
}

export default Label;
