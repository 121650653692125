import React from "react";
import { Route, Switch } from "react-router-dom";

import AdminBlogLayout from "../layout/BlogLayout";

import Seo from "../shared/Seo";
import ArticleList from "../Admin/Blog/ArticleList";
import CategoryList from "../Admin/Blog/CategoryList";
import PageNotFound from "../PageNotFound/PageNotFound";

export default function BlogRoutes() {
  return (
    <AdminBlogLayout>
      <Switch>
        <Route
          path="/admin/blog/articles"
          render={(props) => (
            <Seo title="Blog Articles" noindex>
              <ArticleList {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/blog/categories"
          render={(props) => (
            <Seo title="Blog Categories" noindex>
              <CategoryList {...props} />
            </Seo>
          )}
        />
        <Route component={PageNotFound} />
      </Switch>
    </AdminBlogLayout>
  );
}
