import React from "react";
import { Button } from "antd";

const CountButton = (props) => {
  return (
    <Button className="count-button" shape="circle-outline" {...props} />
  );
};

export default CountButton;
