export const ADMIN_SHOW_LOADING = "ADMIN_SHOW_LOADING";
export const ADMIN_HIDE_LOADING = "ADMIN_HIDE_LOADING";

export const ADMIN_GET_EXPERIENCES_LIST_REQUEST = "ADMIN_GET_EXPERIENCES_LIST_REQUEST";
export const ADMIN_GET_EXPERIENCES_LIST_REQUEST_SUCCESS = "ADMIN_GET_EXPERIENCES_LIST_REQUEST_SUCCESS";
export const ADMIN_GET_EXPERIENCES_LIST_REQUEST_ERROR = "ADMIN_GET_EXPERIENCES_LIST_REQUEST_ERROR";

export const ADMIN_GET_EXPERIENCE_REQUEST = "ADMIN_GET_EXPERIENCE_REQUEST";
export const ADMIN_GET_EXPERIENCE_REQUEST_SUCCESS = "ADMIN_GET_EXPERIENCE_REQUEST_SUCCESS";
export const ADMIN_GET_EXPERIENCE_REQUEST_ERROR = "ADMIN_GET_EXPERIENCE_REQUEST_ERROR";

export const ADMIN_CREATE_EXPERIENCE_REQUEST = "ADMIN_CREATE_EXPERIENCE_REQUEST";
export const ADMIN_CREATE_EXPERIENCE_REQUEST_SUCCESS = "ADMIN_CREATE_EXPERIENCE_REQUEST_SUCCESS";
export const ADMIN_CREATE_EXPERIENCE_REQUEST_ERROR = "ADMIN_CREATE_EXPERIENCE_REQUEST_ERROR";

export const ADMIN_UPDATE_EXPERIENCE_REQUEST = "ADMIN_UPDATE_EXPERIENCE_REQUEST";
export const ADMIN_UPDATE_EXPERIENCE_REQUEST_SUCCESS = "ADMIN_UPDATE_EXPERIENCE_REQUEST_SUCCESS";
export const ADMIN_UPDATE_EXPERIENCE_REQUEST_ERROR = "ADMIN_UPDATE_EXPERIENCE_REQUEST_ERROR";

export const ADMIN_DELETE_EXPERIENCE_REQUEST = "ADMIN_DELETE_EXPERIENCE_REQUEST";
export const ADMIN_DELETE_EXPERIENCE_REQUEST_SUCCESS = "ADMIN_DELETE_EXPERIENCE_REQUEST_SUCCESS";
export const ADMIN_DELETE_EXPERIENCE_REQUEST_ERROR = "ADMIN_DELETE_EXPERIENCE_REQUEST_ERROR";

export const ADMIN_CLEAR_EXPERIENCE = "ADMIN_CLEAR_EXPERIENCE";
export const ADMIN_CLEAR_EXPERIENCE_ERROR = "ADMIN_CLEAR_EXPERIENCE_ERROR";
