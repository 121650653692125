import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd"

const SubmitButton = ({
  text,
  disabled,
  type,
  successText,
  success,
  ...rest
}) => {
  return (
    <Button
      type={type}
      htmlType="submit"
      className={`submit-button ${success ? "submit-button__success" : "" }`}
      disabled={disabled}
      data-testid="profile-details-submit"
      icon={
        success &&
        <img src="/images/success_white_bg.svg" alt="success" className="success-icon"/>
      }
      {...rest}
    >
      {success ? successText || text : text}
    </Button>
  )
}


SubmitButton.defaultProps = {
  type: "primary",
  successText: "",
  disabled: false,
  success: false
}

SubmitButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  success: PropTypes.bool,
  successText: PropTypes.string,
  disabled: PropTypes.bool
}


export default SubmitButton;

