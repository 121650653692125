import React, { useCallback, useRef } from "react";
import { Table } from "antd";
import { DndProvider, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import PropTypes from "prop-types";

import DragableBodyRow from "./DragableBodyRow";

const RNDContext = createDndContext(HTML5Backend);

const DragSortingTable = ({ columns, data, pagination, handleOrderChange, setData }) => {

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];

      const updatedData = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      setData({ data: updatedData });

      handleOrderChange(updatedData);
    },
    [data],
  );

  const manager = useRef(RNDContext);

  return (
    <DndProvider manager={manager.current.dragDropManager}>
      <Table
        className="drag-sorting-table"
        columns={columns}
        dataSource={data}
        components={components}
        pagination={pagination}
        onRow={(record, index) => ({
          index,
          moveRow,
        })}
      />
    </DndProvider>
  );
};

DragSortingTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleOrderChange: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

export default DragSortingTable;
