import React from "react";
import { Route, Switch } from "react-router-dom";

import MainLayout from "../layout/MainLayout";

import Seo from "../shared/Seo";
import LegalPage from "../LegalPage/LegalPage";
import ContactUs from "../ContactUs/ContactUs";
import AboutUs from "../AboutUs/AboutUs";
import Blog from "../Blog/Blog";
import BlogArticle from "../Blog/BlogArticle";
import PageNotFound from "../PageNotFound/PageNotFound";
import Faq from "../Faq/Faq";
import Selling from "../Selling/Selling";

import {
  getAboutUsPageSchemaScriptContent,
  getContactUsPageSchemaScriptContent,
  getBlogPageSchemaScriptContent
} from "../../helpers/schema";

export default function PublicRoutes() {
  return (
    <MainLayout>
      <Switch>
        <Route
          path="/legal/terms-and-conditions"
          render={(props) => (
            <Seo
              title="Terms and Conditions"
              description="See all terms & conditions for using PlansChange to book your hotel, including cancellation, secure booking and best price guarantee."
              noindex
            >
              <LegalPage {...props} />
            </Seo>
          )}
        />
        <Route
          path="/legal/privacy-policy"
          render={(props) => (
            <Seo
              title="Privacy Policy"
              description="We take privacy seriously at PlansChange, see our privacy policy to see how we make sure your information is protected, safe and never used without your consent."
              noindex
            >
              <LegalPage {...props} />
            </Seo>
          )}
        />
        <Route
          path="/legal/cookie-policy"
          render={(props) => (
            <Seo
              title="Cookie Policy"
              description="We use cookies on PlansChange.com to make sure that your experience is secure. View our cookie policy here and opt our of specific cookies to protect your privacy."
              noindex
            >
              <LegalPage {...props} />
            </Seo>
          )}
        />
        <Route
          path="/contact-us"
          render={(props) => (
            <Seo
              title="Contact Us"
              description="Get in touch with us with ease. Our customers are important to us, contact us via email, calling us or our social channels and our dedicated team will get back to you. "
              schemaMarkup={getContactUsPageSchemaScriptContent()}
            >
              <ContactUs {...props} />
            </Seo>
          )}
        />
        <Route
          path="/about-us"
          render={(props) => (
            <Seo
              title="About Us"
              description="PlansChange is a hotel booking site like no other. Created with you in mind! No more confusion when it comes to hotel bookings, complete transparency. Plans change, we get it. "
              schemaMarkup={getAboutUsPageSchemaScriptContent()}
            >
              <AboutUs {...props} />
            </Seo>
          )}
        />
        <Route
          path="/faq"
          render={(props) => (
            <Seo
              title="FAQs"
              description="Travel shouldn't have to be complicated. That's why our FAQs answer all your queries from general to payment details. "
            >
              <Faq {...props} />
            </Seo>
          )}
        />
        <Route
          path="/selling"
          render={(props) => (
            <Seo
              title="How to Sell Your Hotel Room Reservation | PlansChange"
              description="Sell your hotel room reservation on PlansChange if your plans change and you are outside your cancellation policy."
            >
              <Selling {...props} />
            </Seo>
          )}
        />
        <Route
          path="/blog"
          exact
          render={(props) => (
            <Seo
              title="Blog"
              description="Looking for inspiration and the top travel tips? The PlansChange blog is full of travel news, the top things to see and do and where to stay. Browse our latest blogs."
              schemaMarkup={getBlogPageSchemaScriptContent()}
            >
              <Blog {...props} />
            </Seo>
          )}
        />
        <Route path="/blog/articles/:slug" component={BlogArticle} />
        <Route
          render={(props) => (
            <Seo noindex>
              <PageNotFound {...props} />
            </Seo>
          )}
        />
      </Switch>
    </MainLayout>
  );
}
