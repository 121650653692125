import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Select, Input } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BackLink from "../../shared/BackLink";
import History from "../../shared/History/History";
import moment from "moment";
import {
  getUserRequest,
  updateUserRequest,
  clearUserAction,
  clearUserKYCFileAction,
  clearUserErrorAction,
  downloadKYCFileRequest
} from "../../../redux/admin/userManagement/userManagementActions";
import { getFeeConfigurationsRequest } from "../../../redux/admin/finance/financeActions";
import { hideModalAction, showModalAction } from "../../../redux/general/generalActions";
import {
  MODAL_TYPE_ERROR,
  MODAL_TYPE_KYC_FAILURE_REASONS,
  KYC_NOT_VERIFIED_STATUS,
  KYC_PENDING_STATUS,
  KYC_VERIFIED_STATUS,
  KYC_FAILED_STATUS,
  KYC_MANGO_PAY_FAILED_STATUS,
  CARD_NOT_VERIFIED_STATUS,
  CARD_VERIFICATION_FAILED_STATUS,
  CARD_VERIFIED_STATUS
} from "../../constants";
import { ISO31661ALPHA2 } from "../../../api/constants";
import { getFeeLabel } from "../../../helpers/common";
import UserListings from "./UserListings/UserListings";

const KYS_STATUS = {
  [KYC_NOT_VERIFIED_STATUS]: {
    infoClassName: "status-text--not-verified",
    infoTitle: "KYC not verified",
  },
  [KYC_VERIFIED_STATUS]: {
    infoClassName: "status-text--verified",
    infoTitle: "KYC validated",
  },
  [KYC_PENDING_STATUS]: {
    infoClassName: "status-text--pending",
    infoTitle: "KYC pending",
  },
  [KYC_FAILED_STATUS]: {
    infoClassName: "status-text--failed",
    infoTitle: "KYC failed",
    failedButton: true
  },
  [KYC_MANGO_PAY_FAILED_STATUS]: {
    infoClassName: "status-text--failed",
    infoTitle: "KYC failed",
    allowButton: true
  }
};

const User = ({
  getUser,
  updateUser,
  clearUser,
  clearUserKYCFile,
  clearUserError,
  downloadKYCFile,
  getFeeConfigurations,
  showModal,
  history,
  user,
  KYCFile,
  feeConfigurations,
  error,
  match,
  isProcessing
}) => {
  const { id } = match.params;

  const [status, setStatus] = useState("");
  const [feeId, setFeeId] = useState();
  const [promoCode, setPromoCode] = useState("");

  useEffect(() => {
    getUser({ id });

    return clearUser;
  }, [id]);

  useEffect(() => {
    getFeeConfigurations();
  }, []);

  useEffect(() => {
    setStatus(user.status);
    setFeeId(user.feeId);
    setPromoCode(user.promocode);
  }, [user]);

  useEffect(() => {
    if (KYCFile) {
      clearUserKYCFile();
      const array = new Uint8Array(KYCFile.content.data);
      const blob = new Blob([array]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = KYCFile.fileName;
      a.click();
    }
  }, [KYCFile]);

  useEffect(() => {
    if (error) {
      clearUserError();
      showModal({ type: MODAL_TYPE_ERROR, title: error });
    }
  }, [error]);

  const renderCardVerificationStatus = () => {
    switch (user.cardVerificationStatus) {
      case CARD_NOT_VERIFIED_STATUS:
        return <div className="user-management__verification status-text--not-verified">Not verified</div>;
      case CARD_VERIFIED_STATUS:
        return <div className="user-management__verification status-text--verified">Verified</div>;
      case CARD_VERIFICATION_FAILED_STATUS:
        return (
          <div className="user-management__verification">
            <div className="status-text--failed">Verification failed</div>
            <Button className="button button--primary" onClick={() => {
              updateUser({ id: user.id, body: { cardVerificationStatus: CARD_NOT_VERIFIED_STATUS } });
            }}>Allow verification</Button>
          </div>
        );
      default:
        return null;
    }
  };

  const onClickHandler = (kycStatus) => {
    updateUser({ id: user.id, body: { kycStatus: kycStatus } });
  };

  const KYC_STYLE = KYS_STATUS[user.kycStatus] || KYS_STATUS[KYC_NOT_VERIFIED_STATUS];

  let userCurrencyBadge = "£";
  if (user.Address && user.Address.country) {
    userCurrencyBadge = user.Address.country === "US" ? "$" : "£";
  }

  const userNationality = ISO31661ALPHA2.find(country => country.code === user.nationality);

  return (
    <div className="user-management">
      <Row>
        <Col span={24} className="feedback__back-link-container">
          <BackLink label="Back to management" url="/admin/user-management" />
        </Col>
      </Row>
      <Row gutter={30}>
        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
          <Row>
            <Col span={24} className="form-section profile-info">
              <Row>
                <Col flex="100px">
                  <div className="profile-info__avatar-img-container">
                    <img src={user.avatarUrl ? user.avatarUrl : "/images/noun_avatar.svg"} alt="avatar" />
                  </div>
                </Col>
                <Col flex="auto">
                  <div className="profile-info__text">
                    <h3>{user ? `${user.firstName} ${user.lastName}` : ""}</h3>
                    {renderCardVerificationStatus()}
                    <div className="profile-info__text-info">
                      <span>User from:</span>
                      <b>{moment(user.createdAt).format("DD MMM YYYY")}</b>
                    </div>
                    <div className="profile-info__text-info">
                      <span>Last activity:</span>
                      <b>Today</b>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row justify="start">
                <Col span={24} className="profile-info__status">
                  <div className="profile-info__status-select">
                    <div className="select-with-label__field">
                      <label htmlFor="user-status" className="select-with-label__label">Status</label>
                      <Select
                        id="user-status"
                        className="select-with-label__input"
                        disabled={isProcessing}
                        value={status}
                        onChange={data => {
                          setStatus(data);
                        }}
                      >
                        <Select.Option value="active">Active</Select.Option>
                        <Select.Option value="suspended">Suspended</Select.Option>
                      </Select>
                    </div>
                    <div className="select-with-label__field">
                      <label htmlFor="user-commission" className="select-with-label__label">Personal commission</label>
                      <Select
                        id="user-feeId"
                        className="select-with-label__input"
                        value={feeId}
                        onChange={data => {
                          setFeeId(data);
                        }}
                      >
                        {
                          feeConfigurations.map(option => (
                            <Select.Option key={`fee-option-${option.id}`} value={option.id}>
                              {getFeeLabel(option.id, feeConfigurations)}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </div>
                  </div>
                  <div>
                    <Button
                      className="button button--primary"
                      disabled={
                        (status === user.status && feeId === user.feeId && promoCode === user.promocode) || isProcessing
                      }
                      onClick={() => {
                        updateUser({ id: user.id, body: { "status": status, "feeId": feeId, "promocode": promoCode } });
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row justify="start" style={{ marginTop: "10px" }}>
                <Col span={24} className="profile-info__status">
                  <div className="profile-info__status-select">
                    <div className="select-with-label__field">
                      <label htmlFor="user-promo" className="select-with-label__label">Promo code</label>
                      <Input
                        id="user-promo"
                        placeholder="Code"
                        onChange={(e) => setPromoCode(e.target.value)}
                        value={promoCode}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="form-section form-section__separator">
            <Col span={12}>
              <div className="form-section__separator-label">Wallet balance</div>
              <div className="form-section__separator-value">
                {user.balance >= 0 ? `${userCurrencyBadge} ${(+user.balance).toFixed(2)}` : "N/A"}
              </div>
            </Col>
            <Col span={12}>
              <div className="form-section__separator-label">Withdrawn</div>
              <div className="form-section__separator-value">
                {user.withdrawn >= 0 ? `${userCurrencyBadge} ${(+user.withdrawn).toFixed(2)}` : "N/A"}
              </div>
            </Col>
          </Row>
          <Row className="form-section form-section__separator">
            <Col span={8}>
              <div className="form-section__separator-label">Total bought, £</div>
              <div className="form-section__separator-value">
                {user.purchased.gbp >= 0 ? `£ ${(+user.purchased.gbp).toFixed(2)}` : "N/A"}
              </div>
            </Col>
            <Col span={8}>
              <div className="form-section__separator-label">Total bought, $</div>
              <div className="form-section__separator-value">
                {user.purchased.usd >= 0 ? `$ ${(+user.purchased.usd).toFixed(2)}` : "N/A"}
              </div>
            </Col>
            <Col span={8}>
              <div className="form-section__separator-label">Pending</div>
              <div className="form-section__separator-value">
                {user.pending >= 0 ? `${userCurrencyBadge} ${(+user.pending).toFixed(2)}` : "N/A"}
              </div>
            </Col>
          </Row>
          <Row className="form-section form-section__separator">
            <Col span={8}>
              <div className="form-section__separator-label">Listings</div>
              <div className="form-section__separator-value">{user.listings}</div>
            </Col>
            <Col span={8}>
              <div className="form-section__separator-label">Bookings</div>
              <div className="form-section__separator-value">{user.bookings}</div>
            </Col>
            <Col span={8}>
              <div className="form-section__separator-label">Disputes</div>
              <div className="form-section__separator-value">{user.disputes}</div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <History history={history} title="User profile history" />
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
          <Row className="form-section kyc-section">
            <Col span={24}>
              <Row style={{ marginBottom: "10px" }}>
                <Col span={24} className="kyc-section__info">
                  <div>
                    <img src="/images/calendar.svg" alt="calendar" />
                    <span>{user.dateOfBirth ? moment(user.dateOfBirth).format("DD MMM YYYY") : "N/A"}</span>
                  </div>
                  { userNationality &&
                    <div>
                      <img src="/images/language.svg" alt="info"/>
                      <span>{userNationality.name}</span>
                    </div>
                  }
                  <div>
                    <img src="/images/phone.svg" alt="phone" />
                    <span>
                      {user.phoneCountryCode && user.phoneNumber ? `${user.phoneCountryCode} ${user.phoneNumber}` : "N/A"}
                    </span>
                  </div>
                  <div>
                    <img src="/images/letter.svg" alt="letter" />
                    <span>{user.email}</span>
                  </div>
                  { user.Address &&
                    <div className="address__view">
                      <img src="/images/location.svg" alt="letter"/>
                      <div className="address__view-text">
                        <div>{user.Address.address1}</div>
                        <div>{user.Address.city ? `${user.Address.city},` : ""} {user.Address.country}</div>
                        <div>{user.Address.zipCode}</div>
                      </div>
                    </div>
                  }
                </Col>
              </Row>
              <Row className="kyc-section__status">
                <div className="kyc-section__status-info">
                  <img src="/images/check-mark.svg" alt="check-circle" />
                  <div>
                    {
                      user.kycFailureReason && <div>{`${user.kycFailureReason}:`}</div>
                    }
                    <b className={KYC_STYLE.infoClassName}>{KYC_STYLE.infoTitle}</b>
                  </div>
                </div>
                <div className="kyc-section__status-document">
                  {
                    user.KYCDocument && user.KYCDocument.documentType &&
                      <p>
                        <span>Document:</span>
                        <b>{user.KYCDocument.documentType === "passport" ? "Passport" : "Driving License"}</b>
                      </p>
                  }
                </div>
                <div className="kyc-section__status-files">
                  {
                    user.KYCDocument && user.KYCDocument.KYCFiles
                      ? user.KYCDocument.KYCFiles.map(file => (
                        <div key={file.id} onClick={() => {
                          downloadKYCFile({ userId: user.id, documentId: user.KYCDocument.id, fileId: file.id });
                        }}>
                          <img src="/images/file.svg" alt="file" />
                          <span>{file.fileName}</span>
                        </div>
                      ))
                      : null
                  }
                </div>
                <div className="kyc-section__status-buttons">
                  {(KYC_STYLE.allowButton || KYC_STYLE.failedButton) &&
                    <Fragment>
                      <div>
                        <Button className="button button--danger" disabled={KYC_STYLE.failedButton} onClick={() => onClickHandler(KYC_FAILED_STATUS)}>
                          Failed KYC
                        </Button>
                        <Button
                          className="button button--primary"
                          disabled={KYC_STYLE.allowButton}
                          onClick={() => showModal({
                            type: MODAL_TYPE_KYC_FAILURE_REASONS,
                            params: { userId: user.id }
                          })}
                        >
                          Allow KYC
                        </Button>
                      </div>
                      <div className="kyc-section__hint">
                        To Allow KYC for the user, you should confirm Failed KYC first.
                      </div>
                    </Fragment>}
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      { !!user.id &&
        <Row>
          <Col span={24}>
            <UserListings user={user}/>
          </Col>
        </Row>
      }
    </div>
  )
};

User.propTypes = {
  history: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  KYCFile: PropTypes.object,
  feeConfigurations: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
  clearUserKYCFile: PropTypes.func.isRequired,
  clearUserError: PropTypes.func.isRequired,
  downloadKYCFile: PropTypes.func.isRequired,
  getFeeConfigurations: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    history: state.admin.userManagement.singleUser.profileHistory,
    user: state.admin.userManagement.singleUser.user,
    KYCFile: state.admin.userManagement.KYCFile,
    feeConfigurations: state.admin.finance.feeConfigurations,
    error: state.admin.userManagement.error,
    isProcessing: state.admin.userManagement.processing
  }),
  {
    getUser: getUserRequest,
    updateUser: updateUserRequest,
    clearUser: clearUserAction,
    clearUserKYCFile: clearUserKYCFileAction,
    clearUserError: clearUserErrorAction,
    downloadKYCFile: downloadKYCFileRequest,
    getFeeConfigurations: getFeeConfigurationsRequest,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(User);
