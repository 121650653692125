import * as types from "./highlightsActionsTypes";

export const initialState = {
  list: [],
  processing: false,
  error: ""
};

const highlights = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.GET_HIGHLIGHTS_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: ""
      };
    case types.SET_HIGHLIGHTS_LIST:
      return {
        ...state,
        data: action.data,
        error: ""
      };
    case types.GET_HIGHLIGHTS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default highlights;
