import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import omit from "lodash/omit";
import * as queryString from "query-string";

import { getAttachmentsUrlRequest, getListingRequest } from "../../redux/listing/listingActions";
import HotelCard from "../shared/HotelCard"
import InternalLink from "../shared/InternalLink"
import { parseDataForHotelCard } from "../../helpers/common"
import Loader from "../shared/Loader"

const PaymentResult = ({ listing, isAuthenticated, match, location, getListing, getAttachmentsUrl }) => {
  const { slug } = match.params;
  const {
    checkInDate, checkOutDate, adults, children, childrenAge, rooms, status, accessToken
  } = queryString.parse(location.search, { arrayFormat: "bracket" });

  useEffect(() => {
    slug && getListing({ slug, checkInDate, checkOutDate, adults, children, childrenAge, rooms, accessToken });
  }, [slug]);

  return listing.id ? (
    <div className="profile-layout">
      <Row justify="space-around" align="middle" className="share-listing share-listing__margin-top">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <HotelCard
                {...omit(parseDataForHotelCard(listing), ["status"])}
                fullCancellationPaymentMessage
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="share-listing__container">
              {
                status === "success"
                  ? (
                    <div>
                      <img src="/images/success.svg" className="share-listing__icon"/>
                      <div className="share-listing__text">
                        Your booking is now confirmed.<br/>
                        {listing.bookingConfirmation && <React.Fragment>
                          Confirmation number: <b>{listing.bookingConfirmation}</b><br/>
                        </React.Fragment>
                        }
                        {listing.bookingReference && <React.Fragment>
                          Booking reference: <b>{listing.bookingReference}</b><br/>
                        </React.Fragment>
                        }
                        For more details check your email.
                      </div>
                    </div>
                  )
                  : (
                    <div>
                      <img src="/images/danger.svg" className="share-listing__icon"/>
                      <div className="share-listing__text">
                      Something went wrong. You have the following options:
                      </div>
                      <div className="share-listing__list">
                        <ul>
                          <li>
                            <Link to={`/listings/${slug}/details?${queryString.stringify(
                              { checkInDate, checkOutDate, adults, children, childrenAge, rooms },
                              { skipEmptyString: true, skipNull: true, arrayFormat: "bracket" }
                            )}`}>Try again</Link>
                          </li>
                          <li>
                          Contact your bank or credit card provider
                          </li>
                          <li>
                            <Link to="/contact-us">Contact our support team</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )
              }
            </Col>
          </Row>
          <Row>
            <Col span={24} className="share-listing__container-back">
              {
                isAuthenticated && status === "success"
                  ? <InternalLink to="/profile/purchases/upcoming">Your purchases</InternalLink>
                  : status !== "failed"
                    ? <Button
                      className="share-listing__download-confirmation"
                      type="primary"
                      onClick={() => getAttachmentsUrl({ id: listing.id, accessToken })}
                    >
                      Download confirmation
                    </Button>
                    : <InternalLink to="/">Back to home</InternalLink>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : <Loader type="page" />;
};

PaymentResult.propTypes = {
  listing: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  getListing: PropTypes.func.isRequired,
  getAttachmentsUrl: PropTypes.func.isRequired
};

export default connect(
  state => ({
    listing: state.listing.singleListing,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  {
    getListing: getListingRequest,
    getAttachmentsUrl: getAttachmentsUrlRequest
  }
)(PaymentResult);
