import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

import FeeConfiguration from "./FeeConfiguration";
import ChargeOptions from "./ChargeOptions";
import {
  getChargeOptionsRequest,
  getFeeConfigurationsRequest,
  getPaymentFeesConfigurationsRequest
} from "../../../redux/admin/finance/financeActions";
import ListingFeesConfiguration from "./ListingFeesConfiguration";

const FinanceSettings = ({ getChargeOptions, getFeeConfigurations, getPaymentFeesConfigurations }) => {
  useEffect(() => {
    getChargeOptions();
    getFeeConfigurations();
    getPaymentFeesConfigurations();
  }, []);

  return (
    <div className="finance-settings">
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <h3>Listing fees options</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ListingFeesConfiguration />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col xs={{ span: 24 }} xl={{ span: 14 }}>
          <Row>
            <Col span={24}>
              <h3>Charge options</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ChargeOptions />
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 10 }}>
          <Row>
            <Col span={24}>
              <h3>Fee configuration</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FeeConfiguration />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
};

FinanceSettings.propTypes = {
  getChargeOptions: PropTypes.func.isRequired,
  getFeeConfigurations: PropTypes.func.isRequired,
  getPaymentFeesConfigurations: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    getChargeOptions: getChargeOptionsRequest,
    getFeeConfigurations: getFeeConfigurationsRequest,
    getPaymentFeesConfigurations: getPaymentFeesConfigurationsRequest
  }
)(FinanceSettings);
