import * as types from "./financeActionsTypes";

export const getChargeOptionsRequest = () => {
  return { type: types.ADMIN_GET_CHARGE_OPTIONS_REQUEST };
};

export const updateChargeOptionsRequest = data => {
  return { type: types.ADMIN_UPDATE_CHARGE_OPTION_REQUEST, data };
};

export const setDefaultCommissionRequest = () => {
  return { type: types.ADMIN_SET_DEFAULT_COMMISSION_REQUEST };
};

export const getFeeConfigurationsRequest = () => {
  return { type: types.ADMIN_GET_FEE_CONFIGURATIONS_REQUEST };
};

export const updateFeeConfigurationRequest = data => {
  return { type: types.ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST, data };
};

export const getListingsForAccountingRequest = data => {
  return { type: types.ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST, data };
};

export const getListingsForAccountingCsvExportUrlRequest = (data) => {
  return { type: types.ADMIN_GET_LISTINGS_FOR_ACCOUNTING_CSV_EXPORT_URL_REQUEST, data };
};

export const getPaymentFeesConfigurationsRequest = () => {
  return { type: types.ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST };
};

export const updatePaymentFeesConfigurationRequest = data => {
  return { type: types.ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST, data };
};
