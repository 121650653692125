import React from "react";
import { Route, Switch } from "react-router-dom";

import AdminFaqLayout from "../layout/FaqLayout";

import Seo from "../shared/Seo";
import Questions from "../Admin/Faq/Questions";
import Categories from "../Admin/Faq/Categories";
import PageNotFound from "../PageNotFound/PageNotFound";

export default function BlogRoutes() {
  return (
    <AdminFaqLayout>
      <Switch>
        <Route
          path="/admin/faq/questions"
          render={(props) => (
            <Seo title="FAQs" noindex>
              <Questions {...props} />
            </Seo>
          )}
        />
        <Route
          path="/admin/faq/categories"
          render={(props) => (
            <Seo title="FAQ Categories" noindex>
              <Categories {...props} />
            </Seo>
          )}
        />
        <Route component={PageNotFound} />
      </Switch>
    </AdminFaqLayout>
  );
}
