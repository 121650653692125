import React, { useEffect } from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  clearSubscribedStatusStateAction,
  subscribeToMarketingCampaignAction
} from "../../../redux/general/generalActions";
import { Controller, useForm } from "react-hook-form";
import { Button, Input } from "antd";
import { useHistory } from "react-router-dom";

const SubscribeSchema = yup.object().shape({
  email: yup.string().trim().email("Please provide correct email").required("Email can not be empty").nullable()
});

export const Subscribe = ({
  isProcessing,
  isSubscribedToMarketingCampaign,
  subscribeToMarketingCampaign,
  clearSubscribedStatusState,
  showIcon = false,
}) => {
  const history = useHistory();
  const { handleSubmit, errors, control } = useForm({
    validationSchema: SubscribeSchema,
  });

  useEffect(() => {
    return () => {
      clearSubscribedStatusState();
    }
  }, []);

  useEffect(() => {
    if (isSubscribedToMarketingCampaign) {
      history.push("/auth/sign-up");
    }
  }, [isSubscribedToMarketingCampaign]);

  const onSubmitHandler = (data) => {
    subscribeToMarketingCampaign(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="subscribe-form">
      <div className="subscribe-form__input-container">
        { showIcon &&
          <img
            src="/images/icons/envelope-solid.svg"
            alt="Subscribe"
            className="subscribe-form__icon"
          />
        }
        <Controller
          name="email"
          as={<Input
            id="subscribe-email"
            className={`subscribe-form__input${showIcon ? " subscribe-form__input--with-icon" : ""} ${errors.email ? "subscribe-form__input--error" : ""}`}
            placeholder="Enter your email"
          />}
          control={control}
        />
        { errors.email && <span className="subscribe-form__error">{errors.email.message}</span>}
      </div>
      <Button
        htmlType="submit"
        disabled={isProcessing}
        className="button subscribe-form__button"
      >
        <span>Join Us</span>
      </Button>
    </form>
  );
}

Subscribe.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  isSubscribedToMarketingCampaign: PropTypes.bool.isRequired,
  subscribeToMarketingCampaign: PropTypes.func.isRequired,
  clearSubscribedStatusState: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
};

export default connect(
  state => ({
    isProcessing: state.general.processing,
    isSubscribedToMarketingCampaign: state.general.subscribeToMarketingCampaignSuccess
  }),
  {
    subscribeToMarketingCampaign: subscribeToMarketingCampaignAction,
    clearSubscribedStatusState: clearSubscribedStatusStateAction,
  }
)(Subscribe)
