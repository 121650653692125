import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const InternalLink = ({ to, children, className, ...rest }) => {
  return (
    <Link className={`ant-btn ant-btn-primary ${className}`} to={to} {...rest}>
      {children}
    </Link>
  )
}

InternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
}

export default InternalLink;
