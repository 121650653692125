import React, { useMemo } from "react";
import { Col, Row, Grid } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

import { getFaqRequest, getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import FaqAccordion from "./FaqAccordion";
import { getFaqSchemaScriptContent } from "../../helpers/schema";

export const Faq = ({ categories, updatedAt, getFaq, getStaticPage, pageData }) => {
  const { md } = Grid.useBreakpoint();

  useServerSafeEffect(() => {
    getFaq();
    getStaticPage();
  }, []);

  const handleClickScroll = (categoryId) => {
    const headerHeight = 100;
    const categoryElement = document.getElementById(`category-${categoryId}`);

    categoryElement.scrollIntoView(true);

    const scrolledY = document.body.scrollTop;

    if (scrolledY) {
      document.body.scroll(0, md ? scrolledY - headerHeight : scrolledY);
    }
  };

  const questions = useMemo(() => {
    let questionsList = [];

    for (const category of categories) {
      questionsList = [
        ...questionsList,
        ...category.Faqs
      ]
    }

    return questionsList;
  }, [categories]);

  return (
    <React.Fragment>
      <Helmet>
        { !!questions.length && typeof window === "undefined" &&
          <script type="application/ld+json">{getFaqSchemaScriptContent(questions)}</script>
        }
        {
          pageData.metaTitle && (
            <title>{ pageData.metaTitle}</title>
          )
        }
        {
          pageData.metaDescription && (
            <meta name="description" content={pageData.metaDescription} />
          )
        }
        {
          pageData.metaKeywords && (
            <meta name="keywords" content={pageData.metaKeywords} />
          )
        }
      </Helmet>
      <Row justify="space-around" align="top" className="faq-page">
        <Col xs={{ span: 24 }} md={{ span: 20 }}>
          <Row justify="center" className="legal-content">
            <Col xs={{ span: 24 }} md={{ span: 18 }} lg={{ span: 15 }} className="faq-page__content">
              <Row>
                <Col span={24}>
                  <h1 className="faq-page__title">Frequently Asked Questions</h1>
                </Col>
              </Row>
              {updatedAt &&
              <Row>
                <Col span={24} className="faq-page__updated">
                  Last Updated: {moment(updatedAt).format("DD MMM YYYY")}
                </Col>
              </Row>
              }
              <Row>
                <Col span={24} className="faq-page__categories">
                  {categories.map(category => {
                    return <div
                      className="faq-page__category"
                      key={`title-${category.id}`}
                      onClick={() => handleClickScroll(category.id)}
                    >
                      {category.title}
                    </div>;
                  })}
                </Col>
              </Row>
              <Row>
                <Col span={24} className="questions">
                  {categories.map(category => {
                    return <div key={`category-container-${category.id}`}>
                      {category.Faqs && !!category.Faqs.length &&
                      <Row key={category.id} className="questions__container">
                        <Col span={24}>
                          <div id={`category-${category.id}`} className="questions__category">{category.title}</div>
                          {category.Faqs.map(question => {
                            return (
                              <div key={question.id} className="faq-page__faqs-item">
                                <FaqAccordion
                                  answer={parse(question.content)}
                                  question={question.title}
                                  defaultOpen={false}
                                />
                              </div>
                            );
                          })}
                        </Col>
                      </Row>}
                    </div>;
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

Faq.propTypes = {
  categories: PropTypes.array.isRequired,
  getFaq: PropTypes.func.isRequired,
  updatedAt: PropTypes.string,
  getStaticPage: PropTypes.func.isRequired,
  pageData: PropTypes.any.isRequired,
};

export default connect(
  state => ({
    categories: state.staticPage.faq.categories,
    updatedAt: state.staticPage.faq.updatedAt,
    pageData: state.staticPage.page,
  }),
  {
    getFaq: getFaqRequest,
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "faq-page" }),
  }
)(Faq);
