import { all, put, call, takeLatest } from "redux-saga/effects";

import feedback from "../../../api/admin/feedback";
import * as types from "./feedbackActionsTypes";

function *getFeedbackList ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(feedback.getFeedbackList, data);
    yield put({ type: types.ADMIN_GET_FEEDBACK_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_FEEDBACK_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *deleteFeedback ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(feedback.deleteFeedback, data);
    yield put({ type: types.ADMIN_DELETE_FEEDBACK_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_GET_FEEDBACK_LIST_REQUEST });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_FEEDBACK_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getFeedback ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(feedback.getFeedback, data);
    yield put({ type: types.ADMIN_GET_FEEDBACK_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_FEEDBACK_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_FEEDBACK_LIST_REQUEST, getFeedbackList),
    yield takeLatest(types.ADMIN_DELETE_FEEDBACK_REQUEST, deleteFeedback),
    yield takeLatest(types.ADMIN_GET_FEEDBACK_REQUEST, getFeedback)
  ])
}
