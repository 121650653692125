import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Tooltip, Button, Space } from "antd";
import * as queryString from "query-string";

import { getListingRequest, setNewListingData } from "../../../redux/listing/listingActions";
import InternalLink from "../../shared/InternalLink";
import MyListingsCard from "../MyListingsCard";
import DisputeView from "./DisputeView";
import ShareButtons from "./ShareButtons";
import { DISPUTE_PROPS } from "../../constants";
import { Link } from "react-router-dom";
import { hideModalAction } from "../../../redux/general/generalActions";

const ShareListing = ({ createdListing, location, match, getListing, setNewListing, hideModal }) => {
  const { slug } = match.params;
  const { type, status = "success" } = queryString.parse(location.search);
  const [listing, setListing] = useState({});

  useEffect(() => {
    hideModal();
    getListing({ slug: slug });
  }, []);

  useEffect(() => {
    if (createdListing && Object.keys(createdListing).length) {
      setListing(Object.assign(createdListing));
    }
  }, [createdListing]);

  useEffect(() => {
    if (listing && Object.keys(listing).length) {
      setNewListing({});
    }
  }, [listing]);

  if (!listing || !Object.keys(listing).length) {
    return null;
  }

  return (
    <Row justify="space-around" align="middle" className="share-listing">
      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
        { status === "failed"
          ? <Row>
            <Col span={24} className="share-listing__container">
              <div>
                <img src="/images/danger.svg" className="share-listing__icon"/>
                <div className="share-listing__text">
                  Something went wrong. You have the following options:
                </div>
                <div className="share-listing__list">
                  <ul>
                    <li>
                      <Link to={`/listings/${slug}`}>Try again</Link>
                    </li>
                    <li>
                      Contact your bank or credit card provider
                    </li>
                    <li>
                      <Link to="/contact-us">Contact our support team</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          : <ShareButtons type={type}/>
        }
        {listing.id && (
          <>
            <MyListingsCard data={listing}/>
            {
              listing?.Dispute?.status &&
              DISPUTE_PROPS[listing.Dispute.status] &&
                <DisputeView labelSeller={DISPUTE_PROPS[listing.Dispute.status].labelSeller}/>
            }
          </>
        )}
        <Row>
          <Col span={24} className="share-listing__container-back share-listing__container-back__top_margin">
            <Space size="large">
              <InternalLink to={`/listings/${listing.slug}`}>
              View listing
              </InternalLink>
              {
                listing.bookingStartedAt || listing.paymentStartedAt
                  ? <Tooltip title="Someone is purchasing this listing">
                    <Button type="primary" disabled>
                      Edit listing
                    </Button>
                  </Tooltip>
                  : <InternalLink to={`/listings/${listing.slug}/edit`}>
                    Edit listing
                  </InternalLink>
              }
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ShareListing.propTypes = {
  createdListing: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getListing: PropTypes.func.isRequired,
  setNewListing: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    createdListing: state.listing.singleListing,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
  }),
  {
    getListing: getListingRequest,
    setNewListing: setNewListingData,
    hideModal: hideModalAction,
  }
)(ShareListing);
