import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, Row } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  clearQuestionAction,
  clearQuestionErrorAction,
  createQuestionRequest,
  updateQuestionRequest,
  getQuestionRequest, getCategoriesRequest
} from "../../../redux/admin/faq/faqActions";
import BackLink from "../../shared/BackLink";
import InputWithLabel from "../../shared/InputWithLabel";
import RichTextEditor from "../../shared/RichTextEditor/RichTextEditor";
import SelectWithLabel from "../../shared/SelectWithLabel";
import { showModalAction } from "../../../redux/general/generalActions";
import { MODAL_TYPE_FAQ_PREVIEW } from "../../constants";

const QuestionSchema = yup.object().shape({
  title: yup.string().trim().max(255).required("Title can not be empty"),
  category: yup.mixed().required("Category can not be empty"),
  content: yup.string().trim()
    .test({
      name: "contentLength",
      message: "Content can not be empty",
      test: value => {
        let text = "";
        if (value) {
          text = value.replace(/(<([^>]+)>)/ig, "");
        }

        return !!text.length;
      }
    })
});

const Question = ({
  question,
  categories,
  isProcessing,
  match,
  createQuestion,
  updateQuestion,
  getQuestion,
  clearQuestion,
  clearError,
  getCategories,
  showModal
}) => {
  const { id } = match.params;
  const buttonRef = useRef(null);
  const [categoryId, setCategoryId] = useState(null);
  let action = "save";

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: QuestionSchema,
  });

  useEffect(() => {
    getCategories({ pageSize: -1 });
  }, []);

  useEffect(() => {
    if (id !== "new") {
      getQuestion({ id });
    } else {
      clearQuestion();
      clearError();
    }
  }, [id]);

  useEffect(() => {
    const category = categories.find(questionCategory => questionCategory.id === +question.categoryId);

    reset({
      title: question.title,
      category: category ? category.title : null,
      content: question.content
    });
  }, [question]);

  const submitHandler = (data) => {
    const status = action === "publish" ? "published" : (question.status || "unpublished");

    if (action === "preview") {
      showModal({
        title: "",
        type: MODAL_TYPE_FAQ_PREVIEW,
        width: "60%",
        params: data
      });
    } else if (id && id !== "new") {
      updateQuestion({ id, status, action, categoryId, ...data });
    } else {
      createQuestion({ status, action, categoryId, ...data });
    }
  };

  return (
    <Row className="admin-question">
      <Col span={24}>
        <Row>
          <Col span={24}>
            <BackLink label="Back to FAQ" url="/admin/faq/questions" />
          </Col>
        </Row>
        <Row className="admin-question__row">
          <Col span={24}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <Row className="admin-section">
                <Col span={12}>
                  <Row>
                    <Col span={24} className="admin-question__title">
                      New question
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputWithLabel
                        name="title"
                        control={control}
                        id="title"
                        label="Title"
                        error={errors.title}
                        placeholder="Title"
                        defaultValue={question.title}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="admin-question__category">
                      <SelectWithLabel
                        name="category"
                        control={control}
                        id="category"
                        defaultValue={question.category}
                        showSearch={true}
                        onChange={value => {
                          clearError();
                          setCategoryId(value[0]);

                          return value[0];
                        }}
                        label="Category"
                        error={errors.category}
                        options={categories.map(c => ({ value: c.id, label: c.title }))}
                        placeholder="Select"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Controller
                    name="content"
                    as={<RichTextEditor
                      value={question.content}
                    />}
                    defaultValue={question.content}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.content &&
                    <span className="admin-question__error">{errors.content.message}</span>
                  }
                </Col>
              </Row>
              <Row>
                <Col span={24} className="admin-question__submit">
                  <Button
                    htmlType="submit"
                    disabled={isProcessing}
                    className="button button--default"
                    ref={buttonRef}
                  >
                    Save
                  </Button>
                  <Button
                    htmlType="button"
                    disabled={isProcessing}
                    className="button button--default"
                    onClick={() => {
                      action = "preview";
                      buttonRef.current.click();
                    }}
                  >
                    Preview
                  </Button>
                  <Button
                    htmlType="button"
                    disabled={isProcessing}
                    className="button button--primary"
                    onClick={() => {
                      action = "publish";
                      buttonRef.current.click();
                    }}
                  >
                    Publish
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Question.propTypes = {
  match: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  error: PropTypes.string,
  isProcessing: PropTypes.bool,
  createQuestion: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  getQuestion: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  clearQuestion: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    question: state.admin.faq.questions.singleQuestion,
    error: state.admin.faq.error,
    isProcessing: state.admin.faq.processing,
    categories: state.admin.faq.categories.data
  }),
  {
    createQuestion: createQuestionRequest,
    updateQuestion: updateQuestionRequest,
    getQuestion: getQuestionRequest,
    clearError: clearQuestionErrorAction,
    clearQuestion: clearQuestionAction,
    getCategories: getCategoriesRequest,
    showModal: showModalAction
  }
)(Question);
