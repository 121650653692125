import { all, put, call, takeLatest } from "redux-saga/effects";

import hotels from "../../../api/admin/hotels";
import * as types from "./hotelsActionsTypes";
import * as generalTypes from "../../general/generalActionsTypes";
import { MODAL_TYPE_SUCCESS } from "../../../react/constants";

function *getHotels ({ data }) {
  yield put({ type: types.ADMIN_HOTELS_SHOW_LOADING });

  try {
    const response = yield call(hotels.getHotels, data);
    yield put({ type: types.ADMIN_GET_HOTELS_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_HOTELS_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HOTELS_HIDE_LOADING })
  }
}

function *deleteHotel ({ data }) {
  yield put({ type: types.ADMIN_HOTELS_SHOW_LOADING });

  try {
    const response = yield call(hotels.deleteHotel, data);
    yield put({ type: types.ADMIN_DELETE_HOTEL_REQUEST_SUCCESS, response });
    yield put({ type: types.ADMIN_GET_HOTELS_REQUEST });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_HOTEL_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HOTELS_HIDE_LOADING })
  }
}

function *getHotelsFacilities ({ data }) {
  yield put({ type: types.ADMIN_HOTELS_SHOW_LOADING });

  try {
    const response = yield call(hotels.getHotelsFacilities, data);
    yield put({ type: types.ADMIN_GET_HOTELS_FACILITIES_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_HOTELS_FACILITIES_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HOTELS_HIDE_LOADING })
  }
}

function *addHotel ({ data, callBack }) {
  yield put({ type: types.ADMIN_HOTELS_SHOW_LOADING });

  try {
    const response = yield call(hotels.addHotel, data);
    yield put({ type: types.ADMIN_ADD_HOTEL_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Hotel added successfully"
      }
    });

    if (typeof callBack === "function") {
      yield call(callBack, response.id);
    }
  } catch (error) {
    yield put({ type: types.ADMIN_ADD_HOTEL_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HOTELS_HIDE_LOADING })
  }
}

function *getHotel ({ data }) {
  yield put({ type: types.ADMIN_HOTELS_SHOW_LOADING });

  try {
    const response = yield call(hotels.getHotel, data);
    yield put({ type: types.ADMIN_GET_HOTEL_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_HOTEL_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HOTELS_HIDE_LOADING })
  }
}

function *updateHotel ({ data }) {
  yield put({ type: types.ADMIN_HOTELS_SHOW_LOADING });

  try {
    const response = yield call(hotels.updateHotel, data);
    yield put({ type: types.ADMIN_UPDATE_HOTEL_REQUEST_SUCCESS, response });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Hotel data updated successfully",
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_HOTEL_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.ADMIN_HOTELS_HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_HOTELS_REQUEST, getHotels),
    yield takeLatest(types.ADMIN_DELETE_HOTEL_REQUEST, deleteHotel),
    yield takeLatest(types.ADMIN_GET_HOTELS_FACILITIES_REQUEST, getHotelsFacilities),
    yield takeLatest(types.ADMIN_ADD_HOTEL_REQUEST, addHotel),
    yield takeLatest(types.ADMIN_GET_HOTEL_REQUEST, getHotel),
    yield takeLatest(types.ADMIN_UPDATE_HOTEL_REQUEST, updateHotel),
  ])
}
