import * as types from "./profileDetailsActionsTypes";

export const profileGetRequest = () => {
  return { type: types.GET_PROFILE_DETAILS_REQUEST };
};

export const updateUserRequest = (data) => {
  return { type: types.UPDATE_USER_REQUEST, data };
};

export const getProfileHistoryRequest = () => {
  return { type: types.GET_PROFILE_HISTORY_REQUEST };
};

export const createTemporaryProfileRequest = (data) => {
  return { type: types.CREATE_TEMPORARY_PROFILE_REQUEST, data };
};

export const updateProfileDetailsRequest = data => {
  return { type: types.UPDATE_PROFILE_DETAILS_REQUEST, data };
};

export const updateProfileAddressRequest = data => {
  return { type: types.UPDATE_PROFILE_ADDRESS_REQUEST, data };
};
