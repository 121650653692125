import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row } from "antd";
import { Redirect } from "react-router-dom";

import ListingView from "../shared/ListingView";
import {
  createListingRequest,
  updateListingRequest,
  clearListingErrorAction,
  setNewListingData,
} from "../../redux/listing/listingActions";
import {
  CARD_VERIFIED_STATUS,
  CARD_NOT_VERIFIED_STATUS,
  MODAL_TYPE_ACCOUNT_VERIFICATION,
  MODAL_TYPE_LISTING_PUBLISH_PAYMENT
} from "../constants";
import { showModalAction } from "../../redux/general/generalActions";
import Loader from "../shared/Loader"

const PreviewListing = ({
  listing,
  location,
  history,
  isListingCreated,
  isListingCreatedAndPublished,
  cardVerificationStatus,
  createListing,
  updateListing,
  clearListingError,
  showModal,
  setNewListing,
}) => {
  const backToEditPath = location.pathname.slice(0, -8);

  const showAccountVerificationModal = () => {
    showModal({
      type: MODAL_TYPE_ACCOUNT_VERIFICATION,
      width: 900,
      params: {
        listing,
        silent: true
      }
    });
  };

  useEffect(() => {
    if (isListingCreated) {
      if (cardVerificationStatus === CARD_NOT_VERIFIED_STATUS) {
        showAccountVerificationModal();
      } else if (listing.isPaymentRequired) {
        showModal({
          type: MODAL_TYPE_LISTING_PUBLISH_PAYMENT,
          width: 700,
          params: {
            listing
          }
        });
      }
    }
  }, [isListingCreated]);

  const saveListingHandler = (status) => {
    const formData = new FormData();
    Object.entries(listing.formInputsData).forEach(item => {
      if (item[1] !== null) {
        formData.append(item[0], item[1])
      }
    });

    formData.append("status", status === "update" ? listing.status : status);

    if (listing.facilities && listing.facilities.length) {
      for (const facility of listing.facilities) {
        formData.append("facilities[]", facility);
      }
    }

    if (listing.childrenAge && listing.childrenAge.length) {
      for (const age of listing.childrenAge) {
        formData.append("childrenAge[]", age);
      }
    }

    if (listing.savedFiles && listing.savedFiles.length) {
      for (const file of listing.savedFiles) {
        formData.append("documents[]", file);
      }
    }
    if (listing.files && listing.files.length) {
      for (const file of listing.files) {
        formData.append("documents[]", file);
      }
    }

    if (listing.id && ["update", "pending"].includes(status)) {
      updateListing({ id: listing.id, body: formData, status });
    } else {
      createListing(formData);
    }
  };

  if (isListingCreatedAndPublished) {
    setNewListing({});
    clearListingError();

    return <Redirect to={`/listings/${listing.slug}/share`} />;
  } else if (isListingCreated && listing.slug) {
    clearListingError();

    if (
      cardVerificationStatus === CARD_VERIFIED_STATUS &&
      !listing.isPaymentRequired
    ) {
      setNewListing({});

      showModal({
        type: "success",
        title: "Listing saved as draft successfully!"
      });

      return <Redirect to={`/listings/${listing.slug}/edit/preview`} />;
    }
  } else if (!listing.Hotel || !listing.Hotel.id) {
    return <Redirect to={backToEditPath} />;
  }

  return (
    <Row>
      <Col span={24}>
        { listing && listing.Hotel && listing.Hotel.id ?
          <ListingView
            listing={{ ...listing, type: "organic" }}
            hotel={listing.Hotel}
            hotelFacilities={listing.Hotel.facilities}
            listingFacilities={listing.facilities}
            listingRoomType={listing.roomType}
            isPreview={true}
            publishListing={() => {
              setNewListing({ ...listing, isPaymentRequired: true });
              saveListingHandler("draft");
            }}
            saveListing={() => saveListingHandler("update")}
            saveDraftListing={() => saveListingHandler("draft")}
            push={history.push}
          /> : <Loader type="page"/>
        }
      </Col>
    </Row>
  )
};

PreviewListing.propTypes = {
  listing: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isListingCreated: PropTypes.bool.isRequired,
  isListingCreatedAndPublished: PropTypes.bool.isRequired,
  cardVerificationStatus: PropTypes.string.isRequired,
  createListing: PropTypes.func.isRequired,
  updateListing: PropTypes.func.isRequired,
  clearListingError: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setNewListing: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    listing: state.listing.singleListing,
    user: state.auth.user,
    isListingCreated: state.listing.successCreated,
    isListingCreatedAndPublished: state.listing.successCreatedAndPublished,
    cardVerificationStatus: state.profile.permissions.cardVerificationStatus,
  }),
  {
    createListing: createListingRequest,
    updateListing: updateListingRequest,
    clearListingError: clearListingErrorAction,
    showModal: showModalAction,
    setNewListing: setNewListingData,
  }
)(PreviewListing);
