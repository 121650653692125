import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Transactions from "./Transactions/Transactions";
import { KYC_FAILED_STATUS, KYC_NOT_VERIFIED_STATUS } from "./../constants";
import { hideModalAction, showModalAction } from "../../redux/general/generalActions";
import { getBalanceRequest, withdrawMoneyRequest } from "../../redux/profile/profileActions";
import { getCurrencyBadge } from "../../helpers/common";
import WalletBalance from "./WalletBalance";
import WalletPending from "./WalletPending/WalletPending";

export const UserWallet = ({
  kycStatus,
  isBankAccountExists,
  isProcessing,
  history,
  balance,
  currency,
  showModal,
  hideModal,
  getBalance,
  withdrawMoney
}) => {
  useEffect(() => {
    getBalance();
  }, []);

  const handleWithdraw = () => {
    if (isBankAccountExists) {
      showModal({
        type: "confirm",
        width: 450,
        title: `Are You sure you would like to withdraw <span class="user-wallet__withdraw-bold">${getCurrencyBadge(currency)}${(+balance.available).toFixed(2)} </span> to your bank account?`,
        params: {
          modalBodyClassName: "user-wallet__withdraw-modal",
          icon: "/images/clock.svg",
          confirmButtonText: "Confirm",
          isProcessing,
          confirmAction: () => {
            withdrawMoney({ currency, withdrawn: balance.available });
            hideModal();
          }
        }
      });
    } else {
      showModal({
        type: "info",
        width: 450,
        title: "To enable transfer of funds, you need to set up a bank account.",
        params: {
          buttonText: "Add account",
          isCancelButton: true,
          confirmAction: () => {
            history.push("/seller/bank-account");
          }
        }
      });
    }
  };

  return (
    <div className="user-wallet">
      <section className="section">
        <WalletBalance onWithdraw={handleWithdraw}/>
      </section>
      <section className="section">
        <WalletPending/>
      </section>
      {kycStatus === KYC_FAILED_STATUS &&
      <section className="section" data-testid="user-wallet-kyc-failed">
        <Row>
          <Col className="profile-section user-wallet__info" data-testid="user-wallet-kyc-failed-info">
            <img src="/images/danger.svg" alt="info" className="user-wallet__info-icon"/>
            <div className="user-wallet__info-text">
              Unfortunately, your KYC verification has failed. Our administration team will investigate why this has
              happened and contact you shortly to resolve the issue.
            </div>
          </Col>
        </Row>
      </section>}
      {[KYC_FAILED_STATUS, KYC_NOT_VERIFIED_STATUS].includes(kycStatus) &&
      <section className="section" data-testid="user-wallet-pass-kyc-info">
        <Row justify="space-between" align="start" className="balance__hint">
          <Col>
            <div className="balance__hint-first-line">
              In order to withdraw your funds you need to pass Know Your Customer validation
            </div>
            {[KYC_NOT_VERIFIED_STATUS].includes(kycStatus) &&
            <div data-testid="user-wallet-kyc-documents-info">
              You will need to upload a copy of your passport or driving license.
              Verification of your document can take up to 24 hours.
            </div>}
          </Col>
        </Row>
      </section>}
      <Row>
        <Col span={24}>
          <Transactions/>
        </Col>
      </Row>
    </div>
  );
};

UserWallet.propTypes = {
  error: PropTypes.string,
  kycStatus: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  balance: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  getBalance: PropTypes.func.isRequired,
  withdrawMoney: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isBankAccountExists: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired
};

export default connect(
  state => ({
    error: state.profile.error,
    kycStatus: state.profile.permissions.kycStatus,
    isBankAccountExists: state.profile.permissions.isBankAccountExists,
    isProcessing: state.profile.processing,
    balance: state.profile.balance,
    currency: state.profile.permissions.currency
  }),
  {
    showModal: showModalAction,
    hideModal: hideModalAction,
    getBalance: getBalanceRequest,
    withdrawMoney: withdrawMoneyRequest
  }
)(UserWallet);
