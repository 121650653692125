import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import ListingsSearchFiltersModalBody from "../shared/Modal/ListingsSearchFiltersModalBody";
import * as queryString from "query-string";

const INITIAL_SEARCH_QUERY = {
  checkInDate: null,
  checkOutDate: null,
  rooms: 1,
  adults: 2,
  children: 0,
  childrenAge: []
};

const FilterHotelsList = ({ initialValue = {}, lastSearchParams = {} }) => {
  const history = useHistory();

  const defaultSearchValue = { ...initialValue, ...lastSearchParams };
  const [filterNumber, setFilterNumber] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [searchQuery, setSearchQuery] = useState({ ...INITIAL_SEARCH_QUERY, ...defaultSearchValue });

  useEffect(() => {
    if (history.location.search) {
      const query = queryString.parse(history.location.search.slice(1), { arrayFormat: "bracket" }) || {};
      setSearchQuery({ ...INITIAL_SEARCH_QUERY, ...query });
    }
  }, [history.location.search]);

  useEffect(() => {
    // url listener
    history.listen((location) => {
      const urlParams = new URLSearchParams(location.search);

      const allParams = Array.from(urlParams.keys());
      const filterNum = allParams.filter((param) => param.includes("[]")).length;

      setFilterNumber(filterNum);
    });
  }, []);

  return (
    <>
      <div className="filter-button-container">
        <Button className={`filter-button ${filterNumber > 0 ? "has-filters" : ""}` } onClick={() => setShowFilters(true)}>
          <div>Filters</div>
          {filterNumber > 0 && <div className="filter-counter">{filterNumber}</div>}
        </Button>
        {filterNumber > 0 && (
          <div
            className="filter-clear-button"
            onClick={() => {
              const urlQuery = queryString.stringify({
                placeId: searchQuery.placeId,
                placeType: searchQuery.placeType,
                label: searchQuery.label,
                checkInDate: searchQuery.checkInDate,
                checkOutDate: searchQuery.checkOutDate,
                rooms: searchQuery.rooms,
                adults: searchQuery.adults,
                children: searchQuery.children,
                childrenAge: searchQuery.childrenAge
              }, {
                skipEmptyString: true,
                skipNull: true,
                arrayFormat: "bracket"
              });

              history.push(`${history.location.pathname}?${urlQuery}`);
            }}
          >
            Clear
          </div>
        )}
      </div>
      <Modal
        visible={showFilters}
        onCancel={() => setShowFilters(false)}
        closable={false}
        footer={null}
        className="modal modal--search-filters"
        centered={true}
        destroyOnClose={true}
        width={1000}
      >
        <ListingsSearchFiltersModalBody
          onCancel={() => setShowFilters(false)}
          width="90%"
          searchQuery={searchQuery}
        />
      </Modal>
    </>
  );
};

FilterHotelsList.propTypes = {
  initialValue: PropTypes.object,
  lastSearchParams: PropTypes.object,
};

export default FilterHotelsList;
