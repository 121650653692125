import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Col, Row } from "antd";
import { resetPasswordRequest } from "../../redux/auth/authActions";

export const ResetPasswordEmailSent = ({ resendResetPasswordEmail, isProcessing, email, updatePasswordUrl }) => {
  const [isEmailSentAgain, setIsEmailSentAgain] = useState(false);

  const resendResetPasswordEmailHandler = () => {
    if (isProcessing) {
      return;
    }

    setIsEmailSentAgain(true);

    resendResetPasswordEmail({
      email,
      slug: updatePasswordUrl
    });
  };

  return (
    <Row justify="space-around" align="middle" className="auth-row">
      <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 8 }}>
        <div className="reset-password-email-sent form-container">
          <h1 className="reset-password-email-sent__title">Password Reset Email Sent</h1>
          <div className="reset-password-email-sent__subtitle">
            {isEmailSentAgain ? "Follow the directions in the email" : "An email has been sent. Follow the directions in the email to reset your password."}
          </div>
          <img
            src={isEmailSentAgain ? "/images/double_tick.svg" : "/images/envelope.svg"}
            alt="envelope"
            className="reset-password-email-sent__icon"
          />
          <div className="form-row">
            <Link to="/" className="reset-password-email-sent__redirect-link">
              <Button className="submit-button" type="primary" block>Go to homepage</Button>
            </Link>
          </div>
          <div className="reset-password-email-sent__resend-email-label">
            Didn’t receive an Email? <span
              className="link--primary reset-password-email-sent__resend-email-link"
              onClick={resendResetPasswordEmailHandler}
            >Re-Send Email</span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

ResetPasswordEmailSent.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  resendResetPasswordEmail: PropTypes.func.isRequired,
  error: PropTypes.string,
  email: PropTypes.string.isRequired,
  updatePasswordUrl: PropTypes.string.isRequired
};

export default connect(
  state => ({
    isProcessing: state.auth.processing
  }),
  { resendResetPasswordEmail: resetPasswordRequest }
)(ResetPasswordEmailSent);
