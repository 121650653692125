import React from "react";
import { Route, Switch } from "react-router-dom";

import SellerAccountLayout from "../layout/SellerAccountLayout";

import Seo from "../shared/Seo";
import UserWallet from "../UserWallet/UserWallet";
import BankAccount from "../BankAccount/BankAccount";
import PageNotFound from "../PageNotFound/PageNotFound";

export default function SellerAccountRoutes() {
  return (
    <SellerAccountLayout>
      <Switch>
        <Route
          path="/seller/wallet"
          render={(props) => (
            <Seo
              title="Seller Wallet"
              description="Cha - ching! You're in the money, view your seller wallet for sold hotel listings and claim your cash."
            >
              <UserWallet {...props} />
            </Seo>
          )}
        />
        <Route
          path="/seller/bank-account"
          render={(props) => (
            <Seo
              title="Bank Account"
              description="You've sold your hotel listing, now it's time to claim your cash! Transfer your funds to your bank account with our hassle free process!"
              noindex
            >
              <BankAccount {...props} />
            </Seo>
          )}
        />
        <Route component={PageNotFound} />
      </Switch>
    </SellerAccountLayout>
  );
}
