import React from "react";
import PropTypes from "prop-types";
import { Upload } from "antd"

const UploadDragger = ({
  className,
  multiple,
  beforeUpload,
  onChange,
  showUploadList,
  fileList,
  title,
  text,
  ...rest
}) => {
  return (
    <Upload.Dragger
      className={`form-file-upload__container ${className}`}
      multiple={multiple}
      accept=".pdf,.doc,.docx,.jpeg,.jpg,.gif,.png"
      beforeUpload={beforeUpload}
      onChange={onChange}
      showUploadList={showUploadList}
      fileList={fileList}
      {...rest}
    >
      <div className="form-file-upload__title-wrapper">
        {title && <h3 className="form-file-upload__title">{title}</h3>}
        {text && <p className="form-file-upload__subtext">{text}</p>}
      </div>
      <img alt="dnd-image" src="/images/dnd-image.svg"/>
    </Upload.Dragger>
  )
}

UploadDragger.defaultProps = {
  className: "",
  multiple: true,
  beforeUpload: () => false,
  showUploadList: false,
}

UploadDragger.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  beforeUpload: PropTypes.func,
  onChange: PropTypes.func,
  showUploadList: PropTypes.bool,
  fileList: PropTypes.array,
  title: PropTypes.string,
  text: PropTypes.string
}

export default UploadDragger;
