import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Empty,
  Input,
  InputNumber,
  Rate,
  Row,
  Tooltip,
  Typography,
} from "antd";
import * as yup from "yup";
import moment from "moment";
import debounce from "lodash/debounce";
import parse from "html-react-parser";

import {
  createListingRequest,
  getHotelListRequest,
  getHotelRequest,
  getListingRequest,
  setNewListingData
} from "../../redux/listing/listingActions";
import { hideModalAction, showModalAction } from "../../redux/general/generalActions";
import { getCurrencyBadge, round } from "../../helpers/common";
import DatePicker from "../shared/DatePicker";
import SelectWithLabel from "../shared/SelectWithLabel";
import Label from "../shared/Form/Label";
import { CARD_VERIFICATION_FAILED_STATUS } from "../constants";
import CountInput from "./CreateListingForm/CountInput";
import CustomInputNumber from "./CreateListingForm/CustomInputNumber";
import CountButton from "./CreateListingForm/CountButton";
import PlusIcon from "../shared/icons/PlusIcon/PlusIcon";
import MinusIcon from "../shared/icons/MinusIcon/MinusIcon";
import UploadDragger from "../shared/UploadDragger"

const ROOM_TYPE_OPTIONS = [
  {
    room: "Double",
    types: [
      { label: "Accessible", value: "Accessible double room" },
      { label: "Standard", value: "Standard double room" },
      { label: "Classic", value: "Classic double room" },
      { label: "Superior", value: "Superior double room" },
      { label: "Deluxe", value: "Deluxe double room" },
      { label: "Club", value: "Club double room" },
      { label: "Premier", value: "Premier double room" }
    ]
  },
  {
    room: "King",
    types: [
      { label: "Standard", value: "Standard king room" },
      { label: "Superior", value: "Superior king room" },
      { label: "Executive", value: "Executive king room" },
      { label: "City", value: "City king room" }
    ]
  },
  {
    room: "Twin",
    types: [
      { label: "Accessible", value: "Accessible twin room" },
      { label: "Standard", value: "Standard twin room" },
      { label: "Superior", value: "Superior twin room" },
      { label: "Deluxe", value: "Deluxe twin room" }
    ]
  },
  {
    room: "Queen",
    types: [
      { label: "Superior", value: "Superior queen room" },
      { label: "Deluxe", value: "Deluxe queen room" }
    ]
  },
  {
    room: "Single",
    types: [
      { label: "Standard", value: "Standard single room" },
      { label: "Deluxe", value: "Deluxe single room" }
    ]
  },
  {
    room: "Suite",
    types: [
      { label: "Junior", value: "Junior suite" }
    ]
  },
  {
    room: "Other",
    types: [
      { label: "Triple", value: "Triple" },
      { label: "Quad", value: "Quad" },
      { label: "Studio", value: "Studio" }
    ]
  }
];

const ADDITIONAL_OPTIONS = [
  { label: "Breakfast included", value: "Breakfast included" },
  { label: "Dinner included", value: "Dinner included" },
  { label: "Lounge Access included", value: "Lounge Access included" }
];

const listingSchema = yup.object().shape({
  hotelId: yup.mixed().required("Hotel name can not be empty"),
  roomType: yup.mixed().required("Room type can not be empty"),
  roomTypeOther: yup.string()
    .trim()
    .max(255, "Max length of Additional room description - 255 characters"),
  facilitiesOther: yup.string()
    .trim()
    .max(255, "Max length of Additional facilities - 255 characters"),
  hotelRating: yup.number().min(1).required("Star rating can not be empty"),
  rooms: yup.number().min(1).max(10),
  adults: yup.number().min(1).max(10),
  children: yup.number().min(0).max(10),
  bookingName: yup.string()
    .trim()
    .max(255, "Max length of booking name - 255 symbols")
    .required("Booking name can not be empty"),
  bookingReference: yup.string()
    .trim()
    .max(30, "Max length of booking reference - 30 symbols")
    .required("Booking reference can not be empty"),
  bookingPin: yup.string().trim().max(255, "Max length of booking PIN - 255 symbols").nullable(),
  checkInOutDates: yup.array(yup.date()).required(),
  originalPrice: yup.number().min(33.33).required(),
  sellingPrice: yup.number().min(25).required()
    .when("originalPrice", {
      is: value => !!value && value < 1990,
      then: yup.number().test({
        name: "max",
        exclusive: false,
        params: {},
        message: "Max selling price must be less than 75% of the original price",
        test: function (value) {

          return value <= (parseFloat(this.parent.originalPrice) * 0.75)
        }
      }),
      otherwise: yup.number().max(1990)
    }),
  isPaidInFull: yup.string().required("The paid status must be selected."),
  documents: yup.object().shape({
    file: yup.mixed().required("Documents are required"),
    fileList: yup.array().max(5, "You can't upload more than 5 documents"),
  }).required("Documents are required"),
  termsAndConditions: yup.boolean().required().oneOf([true], "Field must be checked"),
  provider: yup.string().trim()
    .oneOf([
      "PlansChange.com", "Booking.com", "Expedia.com", "HotelTonight.com", "Hotels.com", "Kayak.com",
      "Travelocity.com", "Trivago.com", "Agoda.com", "Hotel Website / Dial In / Desk", "Other"
    ], "Please select correct booking provider")
    .required("Booking provider can't be empty"),
  reasonOfSale: yup.string().trim().nullable(),
  receiveOffers: yup.string().trim().oneOf(["always", "in-last-4-hours"]).nullable(),
  areLocalFeesPayable: yup.boolean().optional(),
});

const SellingListing = ({
  listing,
  hotels,
  isAuthenticated,
  isProcessing,
  match,
  location,
  history,
  fee,
  vat,
  cardVerificationStatus,
  getHotelList,
  getHotel,
  getListing,
  setNewListing,
  showModal,
  hideModal,
  currency
}) => {
  const [childrenAge, setChildrenAge] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [facilitiesOther, setFacilitiesOther] = useState("");
  const [roomType, setRoomType] = useState(null);
  const [roomTypeOther, setRoomTypeOther] = useState("");
  const [files, setFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [fileTypeError, setFileFileTypeError] = useState(false);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [sellingDiscount, setSellingDiscount] = useState(25);
  const [systemFee, setSystemFee] = useState(0);
  const [systemVat, setSystemVat] = useState(0);
  const [isPaidInFull, setIsPaidInFull] = useState("full");
  const [receiveOffers, setReceiveOffers] = useState(null);

  const { handleSubmit, errors, control, reset, setValue, getValues, watch } = useForm({
    validationSchema: listingSchema
  });

  const { slug } = match.params;

  useEffect(() => {
    setFiles([]);
  }, []);

  useEffect(() => {
    slug && getListing({ slug: slug });
  }, [slug]);

  useEffect(() => {
    const documents = getValues("documents");

    setFacilities(listing.facilities || []);
    setFacilitiesOther(listing.facilitiesOther || "");
    setRoomType(listing.roomType || []);
    setRoomTypeOther(listing.roomTypeOther || "");
    listing.files && setFiles(listing.files);
    listing.ListingDocuments && !savedFiles.length && !files.length &&
    setSavedFiles(listing.ListingDocuments);

    setChildrenAge(listing.childrenAge || childrenAge);
    setOriginalPrice(listing.originalPrice || 0);
    setSellingPrice(listing.sellingPrice || 0);
    if (slug) {
      setIsPaidInFull(listing.isPaidInFull ? "full" : "arrival");
      setReceiveOffers(listing.receiveOffers);
    }

    const formFields = {
      hotelId: listing.hotelId,
      hotelRating: listing.hotelRating || (listing.Hotel ? listing.Hotel.rating : 0),
      checkInOutDates: [
        listing.checkInDate ? moment(listing.checkInDate) : listing.checkInDate,
        listing.checkOutDate ? moment(listing.checkOutDate) : listing.checkOutDate,
      ],
      bookingName: listing.bookingName,
      bookingReference: listing.bookingReference,
      originalPrice: listing.originalPrice,
      sellingPrice: listing.sellingPrice,
      roomType: listing.roomType,
      roomTypeOther: listing.roomTypeOther || "",
      facilitiesOther: listing.facilitiesOther || "",
      provider: listing.provider,
      rooms: listing.rooms,
      adults: listing.adults,
      children: listing.children,
      documents: {
        file: listing.ListingDocuments?.[0] || documents?.file,
        fileList: listing.ListingDocuments || documents?.fileList
      },
      isPaidInFull,
      areLocalFeesPayable: listing.areLocalFeesPayable,
    };

    if (listing.bookingPin) {
      formFields.bookingPin = listing.bookingPin;
    }

    if (listing.reasonOfSale) {
      formFields.reasonOfSale = listing.reasonOfSale;
    }

    if (listing.originalPrice && listing.sellingPrice) {
      formFields.sellingDiscount = ((listing.originalPrice - listing.sellingPrice) / listing.originalPrice) * 100
    } else {
      formFields.sellingDiscount = 25
    }

    reset(formFields);
  }, [listing]);

  useEffect(() => {
    if (sellingPrice) {
      const totalFee = sellingPrice * (listing.fee ? parseFloat(listing.fee) : fee);
      setSystemFee(round(totalFee / 100, 2));
      setSystemVat(round(((totalFee / 100) * (listing.vat ? parseFloat(listing.vat) : vat)) / 100, 2));
    } else {
      setSystemFee(0);
      setSystemVat(0);
    }
  }, [sellingPrice]);

  const setPrice = (price) => {
    if (price && originalPrice) {
      if (price >= 25 && price <= (originalPrice * 0.75)) {
        // within range
        setSellingPrice(price)
        if (originalPrice) {
          let discountVal = ((originalPrice - price) / originalPrice) * 100
          if (discountVal < 25) {
            discountVal = 25
          }
          setSellingDiscount(discountVal)
          setValue("sellingDiscount", discountVal)
        }
      } else if (price < 25) {
        // below minimum
        price = 25.00
        let discountVal = round(((originalPrice - price) / originalPrice) * 100, 1)
        setSellingPrice(price)
        setValue("sellingPrice", price)
        if (discountVal < 25) {
          discountVal = 25
        }
        setSellingDiscount(discountVal)
        setValue("sellingDiscount", discountVal)
      } else if (price > (originalPrice * 0.75)) {
        // above maximum
        price = round(originalPrice * 0.75, 2)
        const discountVal = 25
        setSellingPrice(price)
        setValue("sellingPrice", price)
        setSellingDiscount(discountVal)
        setValue("sellingDiscount", discountVal)
      }
    } else {
      const discountVal = 25
      setSellingDiscount(discountVal)
      setValue("sellingDiscount", discountVal)
    }
  }

  const setDiscount = (discount) => {
    const maxDiscount = originalPrice ? round(((originalPrice - 25) / originalPrice) * 100, 1) : 100
    if (discount <= maxDiscount && discount >= 25) {
      setSellingDiscount(discount)
      if (originalPrice) {
        let sellingPriceVal = (originalPrice / 100.0) * (100.0 - discount)
        if (sellingPriceVal < 25) {
          sellingPriceVal = 25
        }
        setSellingPrice(sellingPriceVal)
        setValue("sellingPrice", sellingPriceVal)
      }
    }
  }

  const handleDiscountIncrease = () => {
    setValue("sellingDiscount", sellingDiscount + 1)
    setDiscount(sellingDiscount + 1)
  }

  const handleDiscountDecrease = () => {
    setValue("sellingDiscount", sellingDiscount - 1)
    setDiscount(sellingDiscount - 1)
  }

  useEffect(() => {
    if (originalPrice) {
      if (sellingDiscount) {
        const sellingPriceVal = round((originalPrice / 100.0) * (100.0 - sellingDiscount), 2)

        if (sellingPriceVal >= 25) {
          setSellingPrice(sellingPriceVal)
          setValue("sellingPrice", sellingPriceVal)
        }
      }
    }
  }, [originalPrice]);

  const searchHotel = (query) => {
    if (query.length > 2) {
      getHotelList({ search: query, pageSize: 50 });
    }
  };

  const deleteFileFromForm = (key, id) => {
    const documents = getValues("documents");
    const fileList = documents.fileList.filter(item => item[key] !== id);

    setValue("documents", { ...(fileList.length && {
      file: id === documents.file[key] ? fileList[0] : documents.file,
      fileList,
    }) })
  };


  const downloadFile = (file) => {
    let url = file.fileUrl;
    if (!url && file instanceof File) {
      url = window.URL.createObjectURL(new Blob([file], { type: file.type }));
    }

    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", file.fileName || file.name);
    tempLink.click();
  };

  const onSubmitHandler = (data) => {
    const isFilesValid = files.reduce((isValid, file) => {
      return [
        "application/pdf", "image/jpeg", "image/jpg", "image/gif", "image/png", "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ].includes(file.type);
    }, true);

    if (!isFilesValid) {
      return setFileFileTypeError(true);
    }

    delete data.documents;

    const checkInDate = data.checkInOutDates?.[0]
      ? moment(data.checkInOutDates?.[0]).format("YYYY-MM-DD")
      : data.checkInOutDates?.[0];
    const checkOutDate = data.checkInOutDates?.[1]
      ? moment(data.checkInOutDates?.[1]).format("YYYY-MM-DD")
      : data.checkInOutDates?.[1];

    console.log(data);

    setNewListing({
      ...listing,
      ...data,
      Hotel: listing.Hotel,
      formInputsData: {
        ...data,
        isPaidInFull: isPaidInFull === "full",
        checkInDate,
        checkOutDate
      },
      files,
      savedFiles: [...savedFiles.map(({ fileName }) => fileName)],
      facilities,
      facilitiesOther,
      roomType,
      roomTypeOther,
      childrenAge,
      checkInDate,
      checkOutDate
    });

    if (isPaidInFull === "full") {
      showModal({
        type: "info",
        title: "Please confirm that you have paid the booking in full and comply with our terms and conditions",
        width: 500,
        params: {
          buttonText: "Yes",
          isCancelButton: true,
          cancelButtonText: "No",
          confirmAction: () => history.push(`${location.pathname}/preview`),
          cancelAction: () => {
            hideModal();
          }
        }
      });
    } else {
      history.push(`${location.pathname}/preview`);
    }
  };

  const handleChildrenIncrease = () => {
    setChildrenAge([...childrenAge, 10]);
  };

  const handleChildrenDecrease = () => {
    const lastPosition = childrenAge.length - 1;
    setChildrenAge(childrenAge.slice(0, lastPosition));
  };

  const handleChangeChildAge = index => age => {
    const newAges = [...childrenAge];
    newAges[index] = age;
    setChildrenAge(newAges);
  };

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: "/auth/sign-in", state: { from: location } }}/>;
  }

  if (cardVerificationStatus === CARD_VERIFICATION_FAILED_STATUS && !slug) {
    return <Redirect to="/seller/wallet"/>;
  }

  const createError = errors && !!Object.keys(errors).length;

  let totalMoneyAmount = sellingPrice - (systemFee + systemVat);
  if (isPaidInFull === "arrival") {
    totalMoneyAmount = ((watch("originalPrice", 0)) - sellingPrice) + (systemFee + systemVat);
  }

  return (
    <Row className="listing__container">
      <Col span={24}>
        <Row justify="space-around" align="middle">
          <Col xs={{ span: 22 }} md={{ span: 20 }} xl={{ span: 16 }}>
            <Typography.Title level={1}>
              {slug ? "Update Booking details" : "Create Your Hotel Listing"}
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col xs={{ span: 22 }} md={{ span: 20 }} xl={{ span: 16 }}>
            <form className="profile__form" onSubmit={handleSubmit(onSubmitHandler)}>

              { isPaidInFull === "arrival" &&
                <Row className="form-container form-row">
                  <Col span={24} className="listing__red-text-container">
                    You can only sell your booking on here if your credit card has not been pre authorised for the full
                    value of your stay at the time of booking. If your booking has been made where your card has been
                    used to hold the booking only you can sell this on our website and recover some of your money back.
                  </Col>
                </Row>
              }
              <Row className="form-container">
                <Col span={24}>
                  <Row>
                    <h2 className="form-subtitle">Hotel Details</h2>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <div className={`form-section__field listing__field-hotel placeholder-image${errors.hotelId ? " form-section__field--error" : ""}`}>
                        <Label htmlFor="hotelId">Hotel Name</Label>
                        <div>
                          <Controller
                            name="hotelId"
                            as={<Input type="hidden" defaultValue={listing.Hotel && listing.Hotel.id}/>}
                            control={control}
                          />
                          <Controller
                            name="isPaidInFull"
                            as={<Input type="hidden" defaultValue={isPaidInFull}/>}
                            control={control}
                          />
                          <AutoComplete
                            key={`autocomplete-${listing.Hotel ? listing.Hotel.id : ""}`}
                            placeholder="Add the name of the Hotel"
                            defaultValue={listing.Hotel && listing.Hotel.name}
                            className="form-section__select form-input"
                            onSearch={debounce(value => searchHotel(value), 500)}
                            onSelect={(value, option) => {
                              getHotel({ id: option.hotel });
                              setValue("hotelId", option.hotel);
                            }}
                            notFoundContent={<Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description="Enter hotel name"
                            />}
                            filterOption={false}
                            disabled={listing.status === "published"}
                          >
                            {hotels.map(hotel => (
                              <AutoComplete.Option
                                hotel={hotel.id}
                                value={hotel.name}
                                key={`hotel-${hotel.id}`}
                              >{hotel.name}</AutoComplete.Option>
                            ))}
                          </AutoComplete>
                        </div>
                        {errors.hotelId && <span className="profile__error">{errors.hotelId.message}</span>}
                        <Link
                          className="listing-create-form__hotel-not-found"
                          to="/contact-us"
                        >
                          Can't find your hotel? Contact us
                        </Link>
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <Row className="listing__rating-container">
                        <div
                          key={`hotel-rating-${listing.hotelRating || (listing.Hotel ? listing.Hotel.rating : 0)}`}
                          className={`${errors.hotelRating ? " form-section__field--error" : ""} listing__field-rating`}
                        >
                          <Label>Star rating</Label>
                          <Controller
                            name="hotelRating"
                            as={
                              <Rate
                                value={listing.hotelRating || (listing.Hotel ? listing.Hotel.rating : 0)}
                                disabled={!!listing?.Hotel?.rating}
                              />
                            }
                            control={control}
                            defaultValue={listing.hotelRating || (listing.Hotel ? listing.Hotel.rating : null)}
                          />
                          {errors.hotelRating && <span className="profile__error">Rating can not be empty</span>}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  {
                    listing.Hotel && listing.Hotel.id &&
                    <Row>
                      <div className="form-section__field">
                        <span className="form-section__label">Description</span>
                        <div className="listing__hotel-description">{parse(listing.Hotel.description)}</div>
                      </div>
                      <div className="form-section__field">
                        <span className="form-section__label">Facilities</span>
                        <div className="listing__field-facilities">
                          {
                            listing.Hotel.topFacilities && listing.Hotel.topFacilities.map(item => (
                              <div className="listing__field-facilities-item" key={`facility-${item}`}>
                                <img src="/images/check.svg" alt="check-icon"/>
                                <span>{item}</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className={`form-section__field listing__field-room-type${errors.roomType ? " listing__field-room-type--error" : ""}`}>
                        <span className="form-section__label">Room type</span>
                        <Controller
                          name="roomType"
                          disabled={isProcessing}
                          as={<div>
                            <Row gutter={[16, 16]}>
                              {ROOM_TYPE_OPTIONS.map(item => {
                                return <Col xs={{ span: 24 }} md={{ span: 8 }} key={`room-type-${item.room}`}>
                                  <Row>
                                    <Col span={24} className="listing__room-type-title">
                                      {item.room}
                                    </Col>
                                  </Row>
                                  <Row>
                                    {item.types.map(option => {
                                      return <Col span={24} key={option.value}>
                                        <Checkbox
                                          value={option.value}
                                          onChange={event => {
                                            setRoomType(event.target.value);
                                            setValue("roomType", event.target.value);
                                          }}
                                          checked={roomType === option.value}
                                          disabled={listing.status === "published"}
                                          className="listing__checkbox--disabled"
                                        >
                                          {option.label}
                                        </Checkbox>
                                      </Col>;
                                    })}
                                    {item.room === "Other" && <Col span={24} key="other">
                                      <Checkbox
                                        value="other"
                                        onChange={event => {
                                          setRoomType(event.target.value);
                                          setValue("roomType", event.target.value);
                                        }}
                                        checked={roomType === "other"}
                                        disabled={listing.status === "published"}
                                        className="listing__checkbox--disabled"
                                      >
                                          Other
                                      </Checkbox>
                                    </Col>
                                    }
                                  </Row>
                                </Col>;
                              })}
                            </Row>
                          </div>}
                          control={control}
                          defaultValue={listing.roomType}
                        />
                        {errors.roomType && <span className="profile__error">{errors.roomType.message}</span>}
                        <Row gutter={[16, 16]}>
                          <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Controller
                              name="roomTypeOther"
                              as={<Input
                                defaultValue={roomTypeOther}
                                className={`form-section__input listing__input-field-other${errors.roomTypeOther ? " form-section__input--error" : ""}`}
                              />
                              }
                              placeholder="Additional room description"
                              control={control}
                              defaultValue={listing.roomTypeOther}
                              disabled={listing.status === "published"}
                              onChange={([event]) => {
                                setRoomTypeOther(event.target.value);
                                setValue("roomTypeOther", event.target.value);

                                return event.target.value;
                              }}
                            />
                          </Col>
                        </Row>
                        {errors.roomTypeOther && <span className="profile__error">{errors.roomTypeOther.message}</span>}
                      </div>
                      <div className="form-section__field listing__field-additional-info">
                        <span className="form-section__label">Additional information</span>
                        <Checkbox.Group
                          options={ADDITIONAL_OPTIONS}
                          value={facilities}
                          onChange={data => {
                            setFacilities(data);
                          }}
                          disabled={listing.status === "published"}
                          className="listing__checkbox-group--disabled"
                        />
                      </div>
                      <div className="form-section__field listing__field-additional-info">
                        <span className="form-section__label">Other facilities</span>
                        <Controller
                          name="facilitiesOther"
                          as={<Input
                            defaultValue={facilitiesOther}
                            className="form-section__input listing__input-field-other"
                          />
                          }
                          placeholder="Please enter any other facilities"
                          control={control}
                          defaultValue={listing.facilitiesOther}
                          disabled={listing.status === "published"}
                          onChange={([event]) => {
                            setFacilitiesOther(event.target.value);
                            setValue("facilitiesOther", event.target.value);
                            return event.target.value;
                          }}
                        />
                      </div>
                      {errors.facilitiesOther && <span className="profile__error">{errors.facilitiesOther.message}</span>}
                    </Row>
                  }
                </Col>
              </Row>
              <Row className="form-container form-row">
                <Col span={24}>
                  <Row>
                    <h2 className="form-subtitle">Reservation Details</h2>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Row gutter={8} className="form-row">
                        <Col span={24}>
                          <div
                            key={`listing-${listing.id}-dates-input-container`}
                            className={`${errors.checkInOutDates ? " form-section__field--error" : ""}`}
                          >
                            <Label htmlFor="checkInOutDates">Dates</Label>
                            <Controller
                              name="checkInOutDates"
                              as={<DatePicker
                                type={"range"}
                                format="DD MMM YYYY"
                                className="form-section__range-piker"
                                dropdownClassName="listing-search__datepicker"
                                allowClear={false}
                                separator="&ndash;"
                                disabled={listing.status === "published"}
                              />}
                              disabledDate={current => current && current < moment().startOf("day")}
                              placeholder={["Check in", "Check out"]}
                              control={control}
                              onChange={([event]) => event}
                              showToday={false}
                              defaultValue={[
                                listing.checkInDate ? moment(listing.checkInDate) : null,
                                listing.checkOutDate ? moment(listing.checkOutDate) : null
                              ]}
                            />
                          </div>
                          {(errors.checkInOutDates &&
                            <span className="profile__error">Please check the dates</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="form-row">
                        <SelectWithLabel
                          name="provider"
                          control={control}
                          id="provider"
                          label="Original Booking Provider"
                          isInvalid={!!errors.provider}
                          defaultValue={listing.provider}
                          options={[
                            {
                              value: "PlansChange.com",
                              label: "PlansChange.com"
                            },
                            {
                              value: "Agoda.com",
                              label: "Agoda.com"
                            },
                            {
                              value: "Booking.com",
                              label: "Booking.com"
                            },
                            {
                              value: "Expedia.com",
                              label: "Expedia.com"
                            },
                            {
                              value: "HotelTonight.com",
                              label: "HotelTonight.com"
                            },
                            {
                              value: "Hotels.com",
                              label: "Hotels.com"
                            },
                            {
                              value: "Kayak.com",
                              label: "Kayak.com"
                            },
                            {
                              value: "Travelocity.com",
                              label: "Travelocity.com"
                            },
                            {
                              value: "Trivago.com",
                              label: "Trivago.com"
                            },
                            {
                              value: "Hotel Website / Dial In / Desk",
                              label: "Hotel Website / Dial In / Desk"
                            },
                            {
                              value: "Other",
                              label: "Other"
                            }
                          ]}
                          placeholder="e.g. PlansChange.com"
                          disabled={listing.status === "published"}
                        />
                      </Row>

                      <Row gutter={8} className="form-row">
                        <Col span={14}>
                          <div className={`${errors.bookingReference ? " form-section__field--error" : ""}`}>
                            <Label htmlFor="bookingReference">Booking reference</Label>
                            <Controller
                              name="bookingReference"
                              as={<Input
                                id="bookingReference"
                                className={`form-section__input${errors.bookingReference ? " form-section__input--error" : ""}`}
                              />}
                              placeholder="Enter reference"
                              control={control}
                              defaultValue={listing.bookingReference}
                              onChange={([event]) => {
                                return event.target.value;
                              }}
                              disabled={listing.status === "published"}
                            />
                            {errors.bookingReference && <span className="form-section__error">{errors.bookingReference.message}</span>}
                          </div>
                        </Col>
                        <Col span={10}>
                          <div className={`${errors.bookingPin ? " form-section__field--error" : ""}`}>
                            <Label htmlFor="bookingPin" className="listing-create-form__tooltip-label">
                              <div className="listing-create-form__tooltip-label-wrapper">
                                <span>PIN</span>
                                <Tooltip
                                  overlayClassName="form-section__label-tooltip-text"
                                  placement="topRight"
                                  title="This is optional and only applies if you have been provided with a pin on your booking confirmation"
                                >
                                  <img src="/images/info.svg" className="form-section__label-tooltip-image" alt="info"/>
                                </Tooltip>
                              </div>
                            </Label>
                            <Controller
                              name="bookingPin"
                              as={<Input
                                id="bookingPin"
                                className={`form-section__input${errors.bookingPin ? " form-section__input--error" : ""}`}
                              />}
                              placeholder="Enter PIN"
                              control={control}
                              defaultValue={listing.bookingPin}
                              onChange={([event]) => {
                                return event.target.value;
                              }}
                              disabled={listing.status === "published"}
                            />
                          </div>
                        </Col>
                      </Row>

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <div className={`form-row${errors.rooms || errors.adults ? " form-section__field--error" : ""}`}>
                        <Row justify="space-between" align="middle" className="listing-create-form__count-items-row">
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} className="listing-create-form__count-item">
                            <Label>Rooms</Label>
                            <div className="listing-create-form__count-input-wrapper">
                              <Controller
                                name="rooms"
                                as={<CountInput
                                  value={listing.rooms || 1}
                                  min={0}
                                  max={10}
                                  disabled={listing.status === "published"}
                                />}
                                control={control}
                                defaultValue={listing.rooms || 1}
                              />
                            </div>
                          </Col>
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} className="listing-create-form__count-item">
                            <Label>Adults</Label>
                            <div className="listing-create-form__count-input-wrapper">
                              <Controller
                                name="adults"
                                as={<CountInput
                                  value={listing.adults || 2}
                                  min={0}
                                  max={10}
                                  disabled={listing.status === "published"}
                                />}
                                control={control}
                                defaultValue={listing.adults || 2}
                              />
                            </div>
                          </Col>
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} className="listing-create-form__count-item">
                            <Label>Children</Label>
                            <div className="listing-create-form__count-input-wrapper">
                              <Controller
                                name="children"
                                as={<CountInput
                                  onDecrease={handleChildrenDecrease}
                                  onIncrease={handleChildrenIncrease}
                                  min={0}
                                  max={10}
                                  value={listing.children || 0}
                                  disabled={listing.status === "published"}
                                />}
                                control={control}
                                defaultValue={listing.children || 0}
                              />
                            </div>
                          </Col>
                        </Row>
                        {
                          (errors.rooms || errors.adults) &&
                          <span className="profile__error">Rooms and guest are required</span>
                        }
                      </div>

                      {childrenAge && childrenAge.length ?
                        <Row className="form-row listing-create-form__child-count-items-row">
                          {childrenAge.map((age, index) => {
                            return (
                              <Col
                                key={index}
                                xs={{ span: 12 }}
                                sm={{ span: 8 }}
                                className="listing-create-form__child-count-item"
                              >
                                <div>
                                  <Label>Child {index + 1} Age</Label>
                                  <CountInput
                                    defaultValue={age}
                                    min={1}
                                    max={16}
                                    onChange={handleChangeChildAge(index)}
                                    renderCount={({ count, handleChange }) => {
                                      return (
                                        <CustomInputNumber
                                          type="number"
                                          value={count}
                                          onChange={handleChange}
                                          min={1}
                                          max={16}
                                          disabled={listing.status === "published"}
                                        />
                                      );
                                    }}
                                    disabled={listing.status === "published"}
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row> : null}

                      <Row className={`form-row${errors.bookingName ? " form-section__field--error" : ""}`}>
                        <Label htmlFor="bookingName">Name on the Original booking Confirmation</Label>
                        <Controller
                          name="bookingName"
                          as={<Input
                            id="bookingName"
                            className={`form-section__input${errors.bookingName ? " form-section__input--error" : ""}`}
                            disabled={listing.status === "published"}
                          />}
                          placeholder="Enter name"
                          control={control}
                          defaultValue={listing.bookingName}
                          onChange={([event]) => {
                            return event.target.value;
                          }}
                        />
                      </Row>
                      <Row className={`form-row${errors.reasonOfSale ? " form-section__field--error" : ""}`}>
                        <SelectWithLabel
                          name="reasonOfSale"
                          control={control}
                          id="reasonOfSale"
                          label="Why are you reselling?"
                          isInvalid={!!errors.reasonOfSale}
                          defaultValue={listing.reasonOfSale}
                          options={[
                            {
                              value: "Event Cancelled",
                              label: "Event Cancelled"
                            },
                            {
                              value: "Family or other illness",
                              label: "Family or other illness"
                            },
                            {
                              value: "Change of plans",
                              label: "Change of plans"
                            },
                            {
                              value: "Work Commitments",
                              label: "Work Commitments"
                            },
                            {
                              value: "Relationship Breakup",
                              label: "Relationship Breakup"
                            },
                            {
                              value: "Don't want to go",
                              label: "Don't want to go"
                            },
                            {
                              value: "Other",
                              label: "Other"
                            }
                          ]}
                          placeholder="Select"
                          disabled={listing.status === "published"}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Controller
                        name="areLocalFeesPayable"
                        disabled={isProcessing}
                        className={`${errors.areLocalFeesPayable ? " listing__field-is-paid--error" : ""}`}
                        as={
                          <Checkbox
                            className="checkbox--primary listing__checkbox--disabled"
                            key="are-local-fees-payable"
                            // checked={!!watch("areLocalFeesPayable")}
                            disabled={listing.status === "published"}
                          >
                            Are there local fees payable at the property?
                          </Checkbox>}
                        control={control}
                        onChange={([event]) => event.target.checked}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              { isPaidInFull === "arrival" &&
                <Row className="form-container form-row">
                  <Col span={24}>
                    <p>
                      Selling a ‘pay on arrival’ hotel booking? First, we’ll pre-authorise your card for the original
                      booking value and create a listing for 7 days. If you need to extend your listing beyond this,
                      we’ll pre-authorise your card again on day 7.
                    </p>
                    <p>
                      Once you have sold your booking, we will charge you the discount given by you, plus our service
                      fee, to your card. After all, the hotel will still expect the full amount! We’ll then
                      pay the hotel the original booking value, release your pre-authorisation & you can enjoy the
                      money you’ve recouped.
                    </p>
                  </Col>
                </Row>
              }
              <Row className="form-container form-row">
                <Col span={24}>
                  <Row align="middle" gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 14 }}>
                      <Row>
                        <h2 className="form-subtitle">Price Details</h2>
                      </Row>
                      <div className="listing__commissions-inputs">
                        <Row gutter={8}>
                          <Col xs={{ span: 24 }} sm={{ span: 9 }}>
                            <div className={`form-section__field${errors.originalPrice ? " form-section__field--error" : ""}`}>
                              <Label htmlFor="originalPrice">
                                Original Purchase Price
                              </Label>
                              <Controller
                                name="originalPrice"
                                as={<InputNumber
                                  id="originalPrice"
                                  min={33.33}
                                  precision={2}
                                  step=".01"
                                  decimalSeparator="."
                                  className={`form-section__input${errors.originalPrice ? " form-section__input--error" : ""}`}
                                  type="number"
                                  disabled={listing.status === "published"}
                                />}
                                placeholder="0.00"
                                control={control}
                                defaultValue={listing.originalPrice}
                                onChange={([event]) => {
                                  setOriginalPrice(+event || 0);

                                  return event;
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                            <div className={`form-section__field${errors.sellingPrice ? " form-section__field--error" : ""}`}>
                              <Label htmlFor="sellingPrice">Selling Price</Label>
                              <Controller
                                name="sellingPrice"
                                as={<InputNumber
                                  id="sellingPrice"
                                  min={25}
                                  max={originalPrice ? round(originalPrice * 0.75, 2) : 1990}
                                  precision={2}
                                  step=".01"
                                  decimalSeparator="."
                                  className={`form-section__input${errors.sellingPrice ? " form-section__input--error" : ""}`}
                                  type="number"
                                />}
                                placeholder="0.00"
                                control={control}
                                defaultValue={sellingPrice}
                                onChange={([event]) => {
                                  setPrice(+event || 0);

                                  return event;
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                            <div className="form-section__field">
                              <div className="above-label">(Minimum 25%)</div>
                              <Label htmlFor="sellingDiscount">Discount %</Label>
                              <div className="count-input">
                                <div className="count-input__item left">
                                  <CountButton
                                    disabled={sellingDiscount <= 25}
                                    onClick={handleDiscountDecrease}
                                    icon={<MinusIcon/>}
                                  />
                                </div>

                                <div className="count-input__item count-input__central-item">
                                  <Controller
                                    name="sellingDiscount"
                                    as={<InputNumber
                                      id="sellingDiscount"
                                      min={25}
                                      max={originalPrice ? round(((originalPrice - 25) / originalPrice) * 100, 1) : 100}
                                      precision={1}
                                      step=".1"
                                      decimalSeparator="."
                                      className="form-section__input$"
                                      type="number"
                                    />}
                                    placeholder="25"
                                    control={control}
                                    defaultValue={sellingDiscount}
                                    onChange={([event]) => {
                                      setDiscount(+event || 0);

                                      return event;
                                    }}
                                  />
                                </div>

                                <div className="count-input__item right">
                                  <CountButton
                                    disabled={sellingDiscount >= 100}
                                    onClick={handleDiscountIncrease}
                                    icon={<PlusIcon/>}
                                  />
                                </div>
                              </div>

                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className={`listing__price-label${errors.originalPrice || errors.sellingPrice ? " profile__error" : ""}`}>
                            *Selling price is required to be lower than the original price by 25%. The minimum
                            is {getCurrencyBadge(currency)}25 and the maximum is {getCurrencyBadge(currency)}1990.
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 10 }} className="listing__commissions">
                      { isPaidInFull === "arrival" &&
                        <Row gutter={[0, 16]} justify="center" className="listing__commissions-item">
                          <Col span={14}>
                            <span className="listing__commissions-label">
                              Discount given by you
                            </span>
                          </Col>
                          <Col span={10} className="listing__commissions-money">
                            {getCurrencyBadge(currency)} {(watch("originalPrice", 0) - sellingPrice).toFixed(2)}
                          </Col>
                        </Row>
                      }
                      <Row gutter={[0, 16]} justify="center" className="listing__commissions-item">
                        <Col span={14}>
                          <span className="listing__commissions-label">
                            { isPaidInFull === "arrival" ? "Our service fee" : `Our Charges ${listing.fee || fee}%` }
                          </span>
                        </Col>
                        <Col span={10} className="listing__commissions-money">
                          {getCurrencyBadge(currency)} {systemFee.toFixed(2)}
                        </Col>
                      </Row>
                      <Row gutter={[0, 16]} className="listing__commissions-item">
                        <Col span={14}>
                          <span className="listing__commissions-label">VAT/Sales Tax</span>
                        </Col>
                        <Col span={10} className="listing__commissions-money">
                          {getCurrencyBadge(currency)} {systemVat.toFixed(2)}
                        </Col>
                      </Row>
                      <Row gutter={[0, 16]} className="listing__commissions-item">
                        <Col span={14}>
                          <span className="listing__commissions-label">
                            {isPaidInFull === "arrival" ? "Total charge to your card on sale" : "Total Charges"}
                          </span>
                        </Col>
                        <Col span={10} className="listing__commissions-money">
                          {isPaidInFull === "arrival"
                            ? `${getCurrencyBadge(currency)} ${totalMoneyAmount.toFixed(2)}`
                            : `${getCurrencyBadge(currency)} ${(systemFee + systemVat).toFixed(2)}`
                          }
                        </Col>
                      </Row>
                      <Row
                        gutter={[0, 16]}
                        className={`listing__commissions-receive-item${isPaidInFull === "arrival" ? " listing__commissions-receive-item--saving" : ""}`}
                      >
                        <Col span={14}>
                          {isPaidInFull === "arrival" ? "You will save on sale" : "You will receive"}
                        </Col>
                        <Col span={10} className="listing__commissions-money">
                          {isPaidInFull === "arrival"
                            ? `${getCurrencyBadge(currency)} ${(watch("originalPrice", 0) - totalMoneyAmount).toFixed(2)}`
                            : `${getCurrencyBadge(currency)} ${totalMoneyAmount.toFixed(2)}`
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              { isPaidInFull === "full" &&
                <Row className="form-container form-row">
                  <Col span={24}>
                    <Controller
                      name="receiveOffers"
                      disabled={isProcessing}
                      className={`${errors.receiveOffers ? " listing__field-is-paid--error" : ""}`}
                      as={
                        <Row>
                          <Col span={24}>
                            <Checkbox
                              className="checkbox--primary listing__checkbox--disabled"
                              key="always"
                              value="always"
                              checked={receiveOffers === "always"}
                              disabled={listing.status === "published"}
                              onChange={event => {
                                if (event.target.checked) {
                                  setReceiveOffers(event.target.value);
                                  setValue("receiveOffers", event.target.value);
                                } else {
                                  setReceiveOffers(null);
                                  setValue("receiveOffers", null);
                                }
                              }}
                            >
                              I wish to receive best offers at any time
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox
                              className="checkbox--primary listing__checkbox--disabled"
                              key="in-last-4-hours"
                              value="in-last-4-hours"
                              checked={receiveOffers === "in-last-4-hours"}
                              disabled={listing.status === "published"}
                              onChange={event => {
                                if (event.target.checked) {
                                  setReceiveOffers(event.target.value);
                                  setValue("receiveOffers", event.target.value);
                                } else {
                                  setReceiveOffers(null);
                                  setValue("receiveOffers", null);
                                }
                              }}
                            >
                              I wish to receive best offers in the last 4 hours
                            </Checkbox>
                          </Col>
                        </Row>}
                      control={control}
                    />
                  </Col>
                </Row>
              }
              <Row className={`form-container form-row${fileTypeError || errors.documents ? " file-upload__files--error" : ""}`}>
                <Col span={24}>
                  <Row>
                    <h2 className="form-subtitle">Attach Booking Confirmation</h2>
                  </Row>
                  <Row>
                    <p>
                      This should clearly show: Booking Confirmation number / Proof of payment and Price Paid / Name on
                      the Booking.
                    </p>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Controller
                        name="documents"
                        as={<UploadDragger
                          title="Reservation confirmation"
                          text="(confirming the reservation is NON-REFUNDABLE)"
                          accept=".pdf,.jpeg,.jpg,.gif,.png"
                          disabled={(files.length + savedFiles?.length >= 5) || listing.status === "published"}
                          maxCount={5}
                          multiple
                          showUploadList={false}
                          beforeUpload={(file, fileList) => {
                            setFiles([
                              ...files,
                              ...fileList
                            ]);

                            return false;
                          }}
                          fileList={[...files, ...savedFiles]}
                        />
                        }
                        control={control}
                      />
                    </Col>
                    {files.length + savedFiles?.length >= 5 && <Col
                      xs={{ span: 24 }}
                      className="file-upload__file-types"
                    >
                      <span>You can't upload more than 5 documents</span>
                    </Col>}
                  </Row>
                  <Row>
                    <Col span={24}>
                      {savedFiles?.map(({ fileName, fileUrl, id }, index) => {
                        return (
                          <div key={`file-${index}-${fileName}`} className="file-upload__file">
                            <div
                              onClick={() => downloadFile(fileUrl)}
                              className="pointer"
                            >
                              {fileName}
                            </div>
                            <div
                              onClick={() => {
                                setSavedFiles(savedFiles.filter(item => item.fileName !== fileName))
                                deleteFileFromForm("id", id);
                              }}
                              className="file-upload__delete-uploaded-file"
                            >
                              { listing.status !== "published" &&
                                <img src="/images/close.svg" alt="delete"/>
                              }
                            </div>
                          </div>
                        );
                      })}
                      {files.map(file => {
                        return (
                          <div key={file.uid} className="file-upload__file">
                            <div onClick={() => downloadFile(file)} className="pointer">
                              {file.name}
                            </div>
                            <div
                              onClick={() => {
                                setFiles((prevFiles) => prevFiles.filter(item => item.uid !== file.uid));
                                deleteFileFromForm("uid", file.uid);
                              }}

                              className="file-upload__delete-uploaded-file"
                            >
                              <img src="/images/close.svg" alt="delete"/>
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      className={
                        `file-upload__file-types${
                          errors.documents ||
                          errors.documents?.file ||
                          errors.documents?.filesList ? " file-upload__file-types--error" : ""}`}
                    >
                      <span>{
                      errors.documents?.message ||
                      errors.documents?.file?.message ||
                      errors.documents?.filesList?.message
                      }</span>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      className={`form-file-upload__file-types${errors.documents ? " file-upload__file-types--error" : ""}`}
                    >
                      *Supports: .pdf, .doc, .docx, .jpeg, .jpg, .gif and .png file format
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="listing-create-form__agree-checkbox-container">
                    <Controller
                      name="termsAndConditions"
                      as={<Checkbox
                        defaultValue={!!slug}
                        className={`checkbox--primary${errors.termsAndConditions ? " sign-up__checkbox--error" : ""}`}
                      >
                        I agree with PlansChange <Link
                          to="/legal/terms-and-conditions"
                          target="_blank"
                          className="link--primary"
                        >Terms & Conditions</Link> and <Link
                          className="link--primary"
                          to="/legal/privacy-policy"
                          target="_blank"
                        >Privacy Statement</Link>
                      </Checkbox>}
                      control={control}
                      defaultValue={!!slug}
                      onChange={([event]) => {
                        return event.target.checked;
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <div
                className={`listing-create-form__preview ${createError ? "listing-create-form__preview-error" : "listing-create-form__preview-default"}`}
              >
                {(createError) &&
                <p className="listing__create-error">
                  There are some missing information. Please check the entered info and then Preview.
                  <span style={{ display: "none" }}>
                    ${JSON.stringify(errors)}
                  </span>
                </p>
                }
                <h2 className="form-subtitle">Now, let's make sure all the details are right</h2>
                <Button
                  htmlType="submit"
                  className="listing-create-form__preview-button"
                  disabled={createError}
                  type="primary"
                >
                  Preview Listing
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SellingListing.propTypes = {
  listing: PropTypes.object.isRequired,
  hotels: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  fee: PropTypes.number.isRequired,
  vat: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  cardVerificationStatus: PropTypes.string.isRequired,
  getHotelList: PropTypes.func.isRequired,
  getHotel: PropTypes.func.isRequired,
  createListing: PropTypes.func.isRequired,
  getListing: PropTypes.func.isRequired,
  setNewListing: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    listing: state.listing.singleListing,
    hotels: state.listing.hotels,
    isProcessing: state.listing.processing,
    isAuthenticated: state.auth.isAuthenticated,
    cardVerificationStatus: state.profile.permissions.cardVerificationStatus,
    fee: state.profile.permissions.fee,
    vat: state.profile.permissions.vat,
    currency: state.profile.permissions.currency
  }),
  {
    getHotelList: getHotelListRequest,
    getHotel: getHotelRequest,
    createListing: createListingRequest,
    getListing: getListingRequest,
    setNewListing: setNewListingData,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(SellingListing);
