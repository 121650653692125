import * as types from "./notificationActionsTypes";
// import unionBy from "lodash/unionBy";

export const initialState = {
  processing: false,
  error: "",
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: [],
  requestSuccess: false
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
        requestSuccess: false
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_NOTIFICATION_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        ...action.response
      };
    case types.ADMIN_READ_NOTIFICATION_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        data: state.data.map(item => {
          const updatedItem = action.response.find(x => x.id === item.id);

          return updatedItem ? { ...item, ...updatedItem } : item;
        }),
        error: ""
      };
    case types.ADMIN_GET_NOTIFICATION_LIST_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default notification;
