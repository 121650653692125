import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Checkbox, Col, Rate, Row, Typography } from "antd";
import * as yup from "yup";
import * as queryString from "query-string";
import PhoneInput from "react-phone-input-2";

import { profileGetRequest } from "../../redux/profileDetails/profileDetailsActions";
import {
  clearListingErrorAction,
  endBookingListingRequest,
  getListingRequest, purchaseB2BListingRequest,
  purchaseOrganicListingRequest,
  startBookingListingRequest,
  updateListingActivityRequest
} from "../../redux/listing/listingActions";
import { hideModalAction, showModalAction } from "../../redux/general/generalActions";
import InputWithLabel from "../shared/InputWithLabel";
import SelectWithLabel from "../shared/SelectWithLabel";
import { ImageBgCover } from "../shared/SimpleComponents/ImageBgCover/ImageBgCover";
import { getCurrencyBadge } from "../../helpers/common";
import { ISO31661ALPHA2, regions } from "../../api/constants";
import { LISTING_TYPE_B2B, LISTING_TYPE_ORGANIC } from "../constants";
import Label from "../shared/Form/Label";
import ListingDetails from "./ListingDetails";

const commonFields = {
  firstName: yup.string()
    .trim()
    .max(25, "Max length of First name - 25 symbols")
    .required("First name can not be empty"),
  lastName: yup.string().trim().max(25, "Max length of Last name - 25 symbols").required("Last name can not be empty"),
  phone: yup.string().trim().test({
    name: "phoneLength",
    test: value => !!value && /[0-9]/.test(value) && value.length > 10 && value.length < 20
  }),
  address1: yup.string().max(255, "Address could be maximum 255 symbols")
    .required("Address can not be empty")
    .nullable(),
  city: yup.string().max(255, "City could be maximum 255 symbols")
    .required("City name can not be empty")
    .nullable(),
  country: yup.mixed().required("Country / Region can not be empty"),
  state: yup.mixed().when("country", {
    is: value => value && ["US"].includes(value),
    then: yup.mixed().required("State can not be empty"),
    otherwise: yup.mixed().notRequired()
  }),
  zipCode: yup.string().max(25, "Zip/Post could be maximum 255 symbols")
    .required("Zip/Post code  can not be empty")
    .nullable(),
  termsAndConditions: yup.boolean().oneOf([true])
};

const unregisteredFields = {
  email: yup.string().email("Please provide correct email").required("Email can not be empty")
};

const b2bListingFields = {
  cardNumber: yup.string().trim()
    .matches(/[0-9]/, "Please provide correct card number")
    .min(15, "Please provide correct card number")
    .max(16, "Please provide correct card number")
    .required("Card number can not be empty"),
  expiryMonth: yup.string().trim().required("Expiry month can not be empty"),
  expiryYear: yup.string().trim().required("Expiry year can not be empty"),
  cardCvv: yup.string().trim()
    .matches(/[0-9]/, "Incorrect CVV/CVC")
    .required("CVV/CVC can not be empty")
};

const organicListingFields = {
  mangoPayTermsAndConditions: yup.boolean().oneOf([true])
};

let timeoutOuter = null;
let timeoutInner = null;
let timerTimeout = null;
let updateUserActivityTimer = null;

const PrepaymentDetails = ({
  isAuthenticated,
  profile,
  address,
  listing,
  mangoPayRedirectUrl,
  isProcessing,
  history,
  match,
  isSomebodyProcessingListing,
  location,
  getProfile,
  getListing,
  startBookingListing,
  endBookingListing,
  purchaseOrganicListing,
  purchaseB2BListing,
  showModal,
  hideModal,
  clearListingError,
  updateListingActivity
}) => {
  const { slug } = match.params;
  const {
    checkInDate, checkOutDate, adults, children, childrenAge, rooms
  } = queryString.parse(location.search, { arrayFormat: "bracket" });

  let formFields = commonFields;
  if (!isAuthenticated) {
    formFields = { ...commonFields, ...unregisteredFields };
  }

  if (listing.type === LISTING_TYPE_B2B) {
    formFields = { ...formFields, ...b2bListingFields };
  } else if (listing.type === LISTING_TYPE_ORGANIC) {
    formFields = { ...formFields, ...organicListingFields };
  }

  const { handleSubmit, errors, control, reset, watch, setValue } = useForm({
    validationSchema: yup.object().shape(formFields)
  });

  const [phone, setPhone] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [timerDate, setTimerDate] = useState(Date.now());
  const [timerStartDate, setTimerStartDate] = useState(Date.now());

  const startTimer = () => {
    timerTimeout && clearInterval(timerTimeout);

    setTimerStartDate(Date.now());

    timerTimeout = setInterval(() => {
      setTimerDate(Date.now());
    }, 1000);
  };

  useEffect(() => {
    getListing({ slug, checkInDate, checkOutDate, adults, children, childrenAge, rooms });
    isAuthenticated && getProfile();

    return () => {
      listing.id && endBookingListing({ id: listing.id, slug });
      timeoutOuter && clearInterval(timeoutOuter);
      timeoutInner && clearTimeout(timeoutInner);
      timerTimeout && clearInterval(timerTimeout);

      updateUserActivityTimer && clearInterval(updateUserActivityTimer);

      clearListingError();
    };
  }, []);

  useEffect(() => {
    setSelectedCountry(address.country);
  }, [address]);

  useEffect(() => {
    if (listing.id) {
      startBookingListing({ id: listing.id, slug, checkInDate, checkOutDate, adults, children, childrenAge, rooms });
      startTimer();

      updateUserActivityTimer && clearInterval(updateUserActivityTimer);
      updateUserActivityTimer = setInterval(() => {
        listing.id && updateListingActivity({ id: listing.id, slug });
      }, 10000);

      timeoutOuter && clearInterval(timeoutOuter);
      timeoutOuter = setInterval(() => {
        timerTimeout && clearInterval(timerTimeout);
        timeoutInner && clearTimeout(timeoutInner);
        timeoutInner = setTimeout(() => {
          hideModal();
          history.push(`/listings/${slug}${!listing.sellerId ? location.search : ""}`);
        }, 60000);
        showModal({
          type: "info",
          title: "Do you wish to continue?",
          params: {
            buttonText: "Yes",
            isCancelButton: true,
            cancelButtonText: "No",
            confirmAction: () => {
              timeoutInner && clearTimeout(timeoutInner);
              startBookingListing({
                id: listing.id, slug, checkInDate, checkOutDate, adults, children, childrenAge, rooms
              });
              startTimer();
            },
            cancelAction: () => {
              const listingsSearchQuery = window && window.localStorage && window.localStorage.getItem("searchQuery");
              history.push(`/listings${listingsSearchQuery || ""}`);
            }
          }
        });

      }, 5 * 60 * 1000);
    }
  }, [listing]);

  useEffect(() => {
    if (profile.id) {
      setPhone({
        number: profile.phoneNumber,
        countryCode: profile.phoneCountryCode
      });

      reset({
        firstName: profile.first_name,
        lastName: profile.last_name,
        phone: profile?.phoneCountryCode ? `${profile.phoneCountryCode}${profile.phoneNumber}` : "+44",
        address1: address.address1,
        city: address.city,
        country: address.country,
        state: address.state,
        zipCode: address.zipCode
      });
    }
  }, [profile]);

  useEffect(() => {
    if (mangoPayRedirectUrl) {
      const a = document.createElement("a");
      a.href = mangoPayRedirectUrl;
      a.click();
    }
  }, [mangoPayRedirectUrl]);

  useEffect(() => {
    if (isSomebodyProcessingListing) {
      updateUserActivityTimer && clearInterval(updateUserActivityTimer);

      showModal({
        type: "info",
        title: "Cannot start booking process for listing. Somebody on it at the moment",
        params: {
          icon: "/images/danger.svg",
          buttonText: "Ok",
          confirmAction: () => history.push(`/listings/${slug}${!listing.sellerId ? location.search : ""}`)
        }
      });
    }
  }, [isSomebodyProcessingListing]);

  const onSubmitHandler = (data) => {
    const requestData = {
      ...data,
      slug,
      phoneCountryCode: phone.countryCode,
      phoneNumber: phone.number
    };

    if (listing.type === LISTING_TYPE_B2B) {
      purchaseB2BListing({
        ...requestData,
        checkInDate,
        checkOutDate,
        rooms,
        adults,
        children,
        childrenAge
      }, ({ status, listingSlug, accessToken }) => {
        let params = { status, accessToken };
        if (status === "failed") {
          params = {
            ...params,
            checkInDate,
            checkOutDate,
            adults,
            children,
            childrenAge,
            rooms
          };
        }

        history.push(`/listings/${listingSlug}/payment?${queryString.stringify(
          params,
          { skipEmptyString: true, skipNull: true, arrayFormat: "bracket" }
        )}`);
      });
    } else if (listing.type === LISTING_TYPE_ORGANIC) {
      purchaseOrganicListing(
        isAuthenticated ? "update" : "create",
        listing.id,
        requestData
      );
    }
  };

  const imageUrl = listing.Hotel && listing.Hotel.HotelImages && listing.Hotel.HotelImages[0] &&
    listing.Hotel.HotelImages[0].imageUrl;
  const timeDiff = 300 - Math.floor((timerDate - timerStartDate) / 1000);
  const minutes = timeDiff > 0 && timeDiff <= 300 ? Math.floor(timeDiff / 60) : timeDiff > 300 ? 5 : 0;
  const seconds = timeDiff > 0 && timeDiff <= 300 ? `0${timeDiff - (minutes * 60)}`.slice(-2) : "00";

  if (listing.status === "sold") {
    return <Redirect to={`/listings/${slug}${!listing.sellerId ? location.search : ""}`}/>;
  }

  return (
    <Row className="prepayment-details__container">
      <Col>
        <Row align="middle">
          <Col>
            <Link
              to={`/listings/${slug}${!listing.sellerId ? location.search : ""}`}
              className="prepayment-details__back"
            >
              <img src="/images/icons/arrow-back.svg" alt="back"/>
              <span>Back to listing</span>
            </Link>
          </Col>
        </Row>
        <Row className="form-section prepayment-details__timer-block prepayment-details__timer-block--mobile">
          <Col span={24} className="prepayment-details__timer-container">
            <p>
              We hold the reservation to allow you to confirm the purchase
            </p>
            <div className="prepayment-details__timer">{`${minutes}:${seconds}`}</div>
          </Col>
        </Row>
        <Row align="middle">
          <Col>
            <Typography.Title
              level={1}
              className="prepayment-details__title"
            >
              {isAuthenticated ? "Confirm and pay" : "Confirmation details"}
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col>
            <Row className="prepayment-details__body" gutter={{ xs: 0, md: 24, xl: 80 }}>
              <Col xs={{ span: 24 }} md={{ span: 13 }} lg={{ span: 14 }}>
                <form className="profile__form" onSubmit={handleSubmit(onSubmitHandler)}>
                  <Row className="form-container section">
                    <Col span={24}>
                      <Row>
                        <Col className="form-subtitle">Personal Details</Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 12 }} md={{ span: 12 }}>
                          <div className="profile__field profile__field--first-name">
                            <InputWithLabel
                              name="firstName"
                              control={control}
                              id="profile-first-name"
                              label="First Name"
                              error={errors.firstName}
                            />
                          </div>
                        </Col>
                        <Col xs={{ span: 12 }} md={{ span: 12 }}>
                          <div className="profile__field">
                            <InputWithLabel
                              name="lastName"
                              control={control}
                              id="profile-last-name"
                              label="Last Name"
                              error={errors.lastName}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          { !isAuthenticated &&
                            <div className="profile__field">
                              <InputWithLabel
                                name="email"
                                control={control}
                                id="profile-email"
                                label="Email"
                                error={errors.email}
                              />
                            </div>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <div className="profile__field">
                            <Label>Phone Number</Label>
                            <Controller
                              name="phone"
                              as={<PhoneInput
                                id="profile-details-phone-number"
                                data-testid="profile-details-phone-number"
                                className={`profile__input ${errors.phone ? "profile__input--error" : ""}`}
                                country="gb"
                                preferredCountries={["gb", "us"]}
                                autoFormat
                                enableSearch
                                disableSearchIcon
                                countryCodeEditable={false}
                              />}
                              control={control}
                              onChange={([value, countryData]) => {
                                setPhone({
                                  number: value.replace(countryData.dialCode, "").replace(/\D/g, ""),
                                  countryCode: `+${countryData.dialCode}`.replace(/\D/g, "")
                                });

                                return value;
                              }}
                            />
                            {errors.phone &&
                            <span className="profile__error">Invalid phone number</span>
                            }
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="form-container section">
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <div className="form-subtitle">Address</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="address__form">
                          <div className="address__view">
                            <div className="address__field">
                              <InputWithLabel
                                name="address1"
                                control={control}
                                id="address-address"
                                label="Address"
                                error={errors.address1}
                                placeholder="Address"
                              />
                            </div>
                            <div className="address__field">
                              <InputWithLabel
                                name="city"
                                control={control}
                                id="address-city"
                                label="City"
                                error={errors.city}
                                placeholder="City"
                              />
                            </div>
                            <div className="address__field">
                              <SelectWithLabel
                                name="country"
                                disabled={!!address.country}
                                control={control}
                                id="address-country"
                                label="Country / Region"
                                showSearch={true}
                                onChange={value => {
                                  setSelectedCountry(value[0]);

                                  if (value[0] !== watch("country")) {
                                    setValue("state", null);
                                  }

                                  return value[0];
                                }}
                                error={errors.country}
                                options={ISO31661ALPHA2.map(country => {
                                  return {
                                    value: country.code,
                                    label: country.name
                                  };
                                })}
                                placeholder="Select"
                              />
                            </div>
                            {["US", "CA"].includes(selectedCountry) &&
                              <div className="address__field">
                                <SelectWithLabel
                                  name="state"
                                  control={control}
                                  id="state"
                                  label="State"
                                  showSearch={true}
                                  onChange={value => {
                                    return value[0];
                                  }}
                                  error={errors.state}
                                  defaultValue={address.state}
                                  options={regions[selectedCountry].map(state => {
                                    return {
                                      value: state.code,
                                      label: state.name
                                    };
                                  })}
                                  placeholder="Select State"
                                />
                              </div>
                            }
                            <div className="address__field ">
                              <InputWithLabel
                                name="zipCode"
                                control={control}
                                id="address-zip-code"
                                label="Zip/Post Code"
                                error={errors.zipCode}
                                placeholder="Zip/Post Code"
                                onChange={([event]) => {
                                  return event.target.value ? event.target.value.toUpperCase() : null;
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  { listing.type === LISTING_TYPE_B2B &&
                    <Row className="form-container section">
                      <Col span={24}>
                        <Row>
                          <Col className="form-subtitle">Payment Details</Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <div className="profile__field">
                              <InputWithLabel
                                name="cardNumber"
                                control={control}
                                id="card-number"
                                label="Card number"
                                error={errors.cardNumber}
                                placeholder="Card Number"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={32}>
                          <Col span={16}>
                            <Row gutter={16} align="bottom">
                              <Col span={12}>
                                <div className="profile__field">
                                  <SelectWithLabel
                                    name="expiryMonth"
                                    control={control}
                                    id="expiry-month"
                                    label="Expiry Date"
                                    options={(() => {
                                      const months = [];

                                      for (let month = 1; month <= 12; month++) {
                                        months.push({
                                          value: month >= 10 ? month : `0${month}`,
                                          label: month
                                        });
                                      }

                                      return months;
                                    })()}
                                    placeholder="Month"
                                    isInvalid={!!errors.expiryMonth}
                                  />
                                </div>
                              </Col>
                              <Col span={12}>
                                <div className="profile__field">
                                  <SelectWithLabel
                                    name="expiryYear"
                                    control={control}
                                    id="expiry-year"
                                    options={(() => {
                                      const years = [];
                                      const currentYear = new Date().getFullYear();

                                      for (let year = currentYear; year <= currentYear + 10; year++) {
                                        years.push({
                                          value: year,
                                          label: year
                                        });
                                      }

                                      return years;
                                    })()}
                                    placeholder="Year"
                                    isInvalid={!!errors.expiryYear}
                                  />
                                </div>
                              </Col>
                              {(errors.expiryMonth || errors.expiryYear) &&
                              <Col span={24} className="prepayment-details__error">
                                Please provide valid expiry date
                              </Col>
                              }
                            </Row>
                          </Col>
                          <Col span={8}>
                            <div className="profile__field">
                              <InputWithLabel
                                autoComplete="off"
                                name="cardCvv"
                                control={control}
                                id="card-cvv"
                                label="CVV/CVC"
                                error={errors.cardCvv}
                                placeholder="CVV/CVC"
                                style={{ WebkitTextSecurity: "disc" }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                  <Row className="prepayment-details__row-with-padding">
                    <Col span={24}>
                      <Controller
                        name="termsAndConditions"
                        as={(
                          <Checkbox
                            checked={!!watch("termsAndConditions")}
                            className={`checkbox--primary${errors.termsAndConditions ? " prepayment-details__checkbox--error" : ""}`}
                          >
                            I agree with <Link
                              to="/legal/terms-and-conditions"
                              className="link--primary"
                              target="_blank"
                            >Terms & Conditions</Link>
                          </Checkbox>
                        )}
                        control={control}
                        defaultValue={!!watch("termsAndConditions")}
                        onChange={([event]) => event.target.checked}
                      />
                    </Col>
                  </Row>
                  { listing.type === LISTING_TYPE_ORGANIC &&
                    <Row className="prepayment-details__row-with-padding">
                      <Col span={24}>
                        <Controller
                          name="mangoPayTermsAndConditions"
                          as={(
                            <Checkbox
                              checked={!!watch("mangoPayTermsAndConditions")}
                              className={`checkbox--primary${errors.mangoPayTermsAndConditions ? " prepayment-details__checkbox--error" : ""}`}
                            >
                              I agree with MangoPay <a
                                href="https://www.mangopay.com/privacy/"
                                rel="noopener noreferrer"
                                target="_blank"
                              >Terms & Conditions</a>
                            </Checkbox>)
                          }
                          control={control}
                          defaultValue={!!watch("mangoPayTermsAndConditions")}
                          onChange={([event]) => event.target.checked}
                        />
                      </Col>
                    </Row>
                  }
                  <Row className="prepayment-details__row-with-padding">
                    <Col span={24} className="prepayment-details__submit">
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={isProcessing && listing.id}
                        disabled={isProcessing || !listing.id}
                      >
                        {listing.type === LISTING_TYPE_B2B ? "Book now" : "Proceed to payment"}
                      </Button>
                    </Col>
                    { listing.type === LISTING_TYPE_ORGANIC &&
                      <Col span={24}>
                        <img
                          src="/images/powered-by-mango-pay.png"
                          alt="powered by MangoPay"
                          className="prepayment-details__powered-by-mango-pay"
                        />
                      </Col>
                    }
                  </Row>
                </form>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11 }} lg={{ span: 10 }}>
                <Row className="prepayment-details__timer-block">
                  <Col span={24} className="prepayment-details__timer-container">
                    <p>
                      We hold the reservation to allow you to confirm the purchase
                    </p>
                    <div className="prepayment-details__timer">{`${minutes}:${seconds}`}</div>
                  </Col>
                </Row>
                {
                  listing && listing.id &&
                  <Row className="prepayment-details__listing">
                    <Col span={24}>
                      <Row className="prepayment-details__listing-image">
                        <ImageBgCover url={imageUrl} height={75} iconSize="medium"/>
                      </Row>
                      <Row className="prepayment-details__listing-hotel-name">
                        {listing.Hotel && listing.Hotel.name}
                      </Row>
                      {listing.hotelRating &&
                      <Row className="prepayment-details__listing-rating">
                        <Col span={24}>
                          <span className="prepayment-details__listing-rating-label">Rating</span>
                          <Rate disabled count={listing.hotelRating} defaultValue={listing.hotelRating}/>
                        </Col>
                      </Row>
                      }
                      <Row>
                        <Col span={24}>
                          <ListingDetails
                            listing={listing}
                            renderButton={() => {
                              return (
                                <Col span={24} className="prepayment-details__listing-price">
                                  <span>Purchase price</span>
                                  <span>{`${getCurrencyBadge(listing.Seller.currency)}${(+listing.sellingPrice).toFixed(2)}`}</span>
                                </Col>
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

PrepaymentDetails.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  listing: PropTypes.object.isRequired,
  mangoPayRedirectUrl: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isSomebodyProcessingListing: PropTypes.bool.isRequired,
  getProfile: PropTypes.func.isRequired,
  getListing: PropTypes.func.isRequired,
  startBookingListing: PropTypes.func.isRequired,
  endBookingListing: PropTypes.func.isRequired,
  purchaseOrganicListing: PropTypes.func.isRequired,
  purchaseB2BListing: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  clearListingError: PropTypes.func.isRequired,
  updateListingActivity: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.profileDetails.profile,
    mangoPayRedirectUrl: state.listing.paymentDetails.redirectUrl,
    listing: state.listing.singleListing,
    address: state.profileDetails.profile.Address || {},
    isProcessing: state.listing.processing,
    isSomebodyProcessingListing: state.listing.isSomebodyProcessingListing
  }),
  {
    getProfile: profileGetRequest,
    getListing: getListingRequest,
    startBookingListing: startBookingListingRequest,
    endBookingListing: endBookingListingRequest,
    purchaseOrganicListing: purchaseOrganicListingRequest,
    purchaseB2BListing: purchaseB2BListingRequest,
    showModal: showModalAction,
    hideModal: hideModalAction,
    clearListingError: clearListingErrorAction,
    updateListingActivity: updateListingActivityRequest
  }
)(PrepaymentDetails);
