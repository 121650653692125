import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Select } from "antd";

const sortLabels = [
  {
    label: "Price Low/High",
    value: "price",
  },
  {
    label: "Price High/Low",
    value: "-price",
  },
  {
    label: "Saving",
    value: "saving",
  },
  {
    label: "Distance",
    value: "distance",
  },
];

const sortListings = (sortBy, list) => {
  return [...list].sort((prev, next) => {
    if (prev.selectedHotel) {
      return -1
    }
    if (next.selectedHotel) {
      return 1
    }
    if (prev.type === "organic" && prev.type !== "organic") {
      return -1
    }
    if (prev.type !== "organic" && prev.type === "organic") {
      return 1
    }
    switch (sortBy) {
      case "price":
        return prev.sellingPrice - next.sellingPrice;
      case "-price":
        return next.sellingPrice - prev.sellingPrice;
      case "saving": // highest first
        return next.discount - prev.discount;
      case "distance": // lower first
        if (
          !next.Hotel?.distanceFromSelectedLocation?.value ||
          !prev.Hotel?.distanceFromSelectedLocation?.value
        ) {
          return -1;
        }

        return (
          prev.Hotel.distanceFromSelectedLocation.value -
          next.Hotel.distanceFromSelectedLocation.value
        );

      default:
        return 0;
    }
  });
};

const SortHotelsList = ({ list, updateList, isProcessing, defaultValue }) => {
  const [value, setValue] = useState();
  const [listLength, setListLength] = useState(0);

  useEffect(() => {
    if (!value && defaultValue && defaultValue !== value && !isProcessing) {
      setValue(defaultValue)
    }
    if (!isProcessing && list.length > 1 && listLength !== list.length) {
      updateList(sortListings(value, list));
      setListLength(list.length)
    }
  }, [isProcessing, list]);

  const handleSort = useCallback(
    (val) => {
      setValue(val);
      updateList(sortListings(val, list));
    },
    [list]
  );

  return (
    <Select
      value={value}
      className="sort-hotel-list"
      disabled={isProcessing}
      placeholder="Sort by..."
      options={sortLabels}
      onChange={handleSort}
    />
  );
};

SortHotelsList.propTypes = {
  updateList: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.string
};

export default SortHotelsList;
