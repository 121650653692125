import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const highlights = {
  async getHighlights(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/highlights?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteHighlight(data) {
    try {
      const result = await axios.delete(`${API_URL}/admin/highlights/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async addHighlight(data) {
    try {
      const result = await axios.post(`${API_URL}/admin/highlights`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getHighlight(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/highlights/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateHighlight(data) {
    try {
      const result = await axios.put(`${API_URL}/admin/highlights/${data.id}`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default highlights;
