import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const experiences = {
  async getExperiencesList(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/experiences?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createExperience(data) {
    try {
      const result = await axios.post(`${API_URL}/admin/experiences`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateExperience(data) {
    try {
      const result = await axios.put(`${API_URL}/admin/experiences/${data.id}`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getExperience(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/experiences/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteExperience(data) {
    try {
      const result = await axios.delete(`${API_URL}/admin/experiences/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  }
};

export default experiences;
