import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { regions, ISO31661ALPHA2 } from "../../api/constants";
import InputWithLabel from "../shared/InputWithLabel";
import SelectWithLabel from "../shared/SelectWithLabel";
import { updateProfileAddressRequest } from "../../redux/profileDetails/profileDetailsActions";
import SubmitButton from "../shared/SubmitButton"

const AddressSchema = yup.object().shape({
  address1: yup.string().max(255, "Address could be maximum 255 symbols")
    .required("Address can not be empty")
    .nullable(),
  city: yup.string().max(255, "City could be maximum 255 symbols")
    .required("City name can not be empty")
    .nullable(),
  country: yup.mixed().required("Country / Region can not be empty"),
  state: yup.mixed().when("country", {
    is: value => value && ["US"].includes(value),
    then: yup.mixed().required("State can not be empty"),
    otherwise: yup.mixed().notRequired()
  }),
  zipCode: yup.string().max(25, "Zip/Post could be maximum 255 symbols")
    .required("Zip/Post code  can not be empty")
    .nullable()
});

export const Address = ({ address, isProcessing, isProfileAddressUpdated, updateProfileAddress }) => {
  const { handleSubmit, errors, control, reset, watch, setValue, formState } = useForm({
    validationSchema: AddressSchema
  });

  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    if (address.address1) {
      reset({
        address1: address.address1,
        city: address.city,
        country: address.country,
        state: address.state,
        zipCode: address.zipCode
      });
    }

    setSelectedCountry(address.country);
  }, [address]);

  return (
    <Row className="profile-details">
      <Col span={24} className="profile-section">
        <h1 className="form-subtitle">Address</h1>
        <form onSubmit={handleSubmit(updateProfileAddress)} className="profile-details__form">
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div className="profile-details__field">
                    <InputWithLabel
                      name="address1"
                      control={control}
                      id="address-address"
                      label="Address"
                      error={errors.address1}
                      placeholder="Address"
                      defaultValue={address.address1}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="profile-details__field">
                    <InputWithLabel
                      name="city"
                      control={control}
                      id="address-city"
                      label="City"
                      error={errors.city}
                      placeholder="City"
                      defaultValue={address.city}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="profile-details__field">
                    <SelectWithLabel
                      name="country"
                      control={control}
                      id="address-country"
                      label="Country / Region"
                      showSearch={true}
                      onChange={value => {
                        setSelectedCountry(value[0]);

                        if (value[0] !== watch("country")) {
                          setValue("state", null);
                        }

                        return value[0];
                      }}
                      error={errors.country}
                      defaultValue={address.country}
                      options={ISO31661ALPHA2
                        .filter(country => country.isSupportedInMangoPay)
                        .map(country => {
                          return {
                            value: country.code,
                            label: country.name
                          };
                        })
                      }
                      placeholder="Select"
                      disabled={!!address && !!address.country}
                    />
                  </div>
                </Col>
              </Row>
              {
                ["US", "CA"].includes(selectedCountry) &&
                <Row>
                  <Col span={24}>
                    <div className="profile-details__field">
                      <SelectWithLabel
                        name="state"
                        control={control}
                        id="state"
                        label="State"
                        showSearch={true}
                        onChange={value => {
                          return value[0];
                        }}
                        error={errors.state}
                        defaultValue={address.state}
                        options={regions[selectedCountry].map(state => {
                          return {
                            value: state.code,
                            label: state.name
                          };
                        })}
                        placeholder="Select State"
                      />
                    </div>
                  </Col>
                </Row>
              }
              <Row>
                <Col span={24}>
                  <div className="profile-details__field">
                    <InputWithLabel
                      name="zipCode"
                      control={control}
                      id="address-zip-code"
                      label="Zip/Post Code"
                      error={errors.zipCode}
                      placeholder="Zip/Post Code"
                      defaultValue={address.zipCode}
                      onChange={([event]) => {
                        return event.target.value ? event.target.value.toUpperCase() : null;
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="form-actions" justify="end">
                <Col>
                  <SubmitButton
                    success={isProfileAddressUpdated && !isProcessing && !formState.dirty}
                    disabled={isProcessing}
                    text="Save address"
                    successText="Saved"
                    data-testid="profile-details-submit"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

Address.propTypes = {
  updateProfileAddress: PropTypes.func.isRequired,
  address: PropTypes.object,
  isProcessing: PropTypes.bool,
  error: PropTypes.string,
  isProfileAddressUpdated: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    isProcessing: state.profileDetails.isProfileAddressUpdating,
    error: state.profileDetails.error,
    isProfileAddressUpdated: state.profileDetails.isProfileAddressUpdated
  }),
  {
    updateProfileAddress: updateProfileAddressRequest
  }
)(Address);
