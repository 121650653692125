import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import { getDashboardDisputesRequest } from "../../../redux/admin/dashboard/dashboardActions";

const Disputes = ({ disputes, country, getDisputes }) => {
  useEffect(() => {
    getDisputes({ country });
  }, []);

  useEffect(() => {
    getDisputes({ country });
  }, [country]);

  return (
    <React.Fragment>
      <div className="admin-dashboard__static-section-title">Disputes</div>
      <div className="admin-dashboard__static-section">
        { disputes.map((row, key) => {
          return (
            <Row key={key} justify="space-between" align="middle" className="admin-dashboard__static-section-row">
              <Col span={20}>{row.label}</Col>
              <Col span={4} className="admin-dashboard__static-section-number">{row.value}</Col>
            </Row>
          );
        }) }
      </div>
    </React.Fragment>
  );
};

Disputes.propTypes = {
  disputes: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
  getDisputes: PropTypes.func.isRequired
};

export default connect(
  state => ({
    disputes: state.admin.dashboard.disputes,
    country: state.admin.dashboard.country
  }),
  {
    getDisputes: getDashboardDisputesRequest
  }
)(Disputes);
