import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row, Upload, DatePicker, Empty, Select } from "antd";
import { useParams } from "react-router";
import moment from "moment";
import debounce from "lodash/debounce";

import CropExperienceImageModalBody from "./CropExperienceImageModalBody";
import InputWithLabel from "../../shared/InputWithLabel";
import BackLink from "../../shared/BackLink";
import TextAreaWithLabel from "../../shared/TextAreaWithLabel";
import { getPlacesRequest } from "../../../redux/places/placesActions";
import {
  clearExperienceAction,
  clearExperienceErrorAction,
  createExperienceRequest,
  getExperienceRequest,
  updateExperienceRequest
} from "../../../redux/admin/experiences/experiencesActions";

const ExperienceVerificationSchema = yup.object().shape({
  name: yup.string().trim().max(25).required("Name can not be empty"),
  dates: yup.array(yup.date()).required(),
  placeId: yup.string().trim().required("Location can not be empty"),
  description: yup.string().trim().max(200).required("Description can not be empty").nullable()
});

const Experience = ({
  experience,
  places,
  error,
  isProcessing,
  clearExperience,
  clearError,
  createExperience,
  updateExperience,
  getExperience,
  getPlaces
}) => {
  const { id } = useParams();

  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState(null);
  const [noImageError, setNoImageError] = useState(false);
  const [cropImageUrl, setCropImageUrl] = useState();
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [locationLabel, setLocationLabel] = useState("");
  const [locations, setLocations] = useState(places);

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: ExperienceVerificationSchema
  });

  useEffect(() => {
    clearExperience();
    if (id !== "new") {
      getExperience({ id });
    }

    return () => clearExperience();
  }, [id]);

  useEffect(() => {
    reset({
      name: experience.name,
      description: experience.description,
      placeId: experience.placeId,
      dates: [
        experience.fromDate ? moment(experience.fromDate) : null,
        experience.toDate ? moment(experience.toDate) : null
      ]
    });
    setImageUrl(experience.imageUrl);
    setLocationLabel(experience.locationLabel);
    setLocations(experience.placeId
      ? [{ placeId: experience.placeId, label: experience.locationLabel }]
      : []
    );
  }, [experience]);

  useEffect(() => {
    if (places.length) {
      setLocations(places);
    }
  }, [places]);

  const clearErrorHandler = () => {
    if (error) {
      clearError();
    }
  };

  const submitHandler = (data) => {
    if (!imageUrl) {
      return setNoImageError(true);
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("placeId", data.placeId);
    formData.append("locationLabel", locationLabel);
    formData.append("fromDate", moment(data.dates[0]).format("YYYY-MM-DD"));
    formData.append("toDate", moment(data.dates[1]).format("YYYY-MM-DD"));
    imageUrl !== experience.imageUrl && formData.append("image", image);

    if (id === "new") {
      createExperience({ body: formData });
    } else {
      updateExperience({ id: id, body: formData });
    }
  };

  function searchCity(query) {
    if (query.length > 2) {
      getPlaces({ input: query });
    }
  }

  return (
    <div className="admin-experience">
      <Row>
        <Col span={24}>
          <BackLink label="Back to experiences" url="/admin/experiences"/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Row>
              <Col span={24} className="form-section">
                <Row>
                  <div className="form-section__title">{id === "new" ? "New experience" : "Edit experience"}</div>
                </Row>
                <Row>
                  <Col span={16} style={{ paddingRight: "19px" }}>
                    <Row>
                      <InputWithLabel
                        name="name"
                        className="form-section__field"
                        control={control}
                        id="name"
                        onChange={([event]) => {
                          clearErrorHandler();

                          return event.target.value;
                        }}
                        label="Name"
                        defaultValue={experience.name}
                        error={errors.name}
                        placeholder="Enter Name"
                        maxLength={25}
                      />
                    </Row>
                    <Row>
                      <Col span={24} className="form-section__field">
                        <label htmlFor="dates" className="form-label">Period</label>
                        <Controller
                          name="dates"
                          as={<DatePicker.RangePicker
                            showArrow={true}
                            className={`form-section__input${errors.dates ? " form-section__field--error" : ""}`}
                            format="DD-MM-YYYY"
                            value={[
                              experience.fromDate ? moment(experience.fromDate) : null,
                              experience.toDate ? moment(experience.toDate) : null
                            ]}
                          />}
                          disabledDate={current => current && current < moment().startOf("day")}
                          placeholder={["From date", "To date"]}
                          control={control}
                          onChange={([event]) => event}
                          showToday={false}
                        />
                        {(errors.dates &&
                          <span className="profile__error">Please check the dates</span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8} style={{ paddingLeft: "19px" }}>
                    <div className={`file-upload__container${noImageError ? " file-upload__error" : ""}`}>
                      <p>{`Background image${noImageError ? " can't be empty" : ""}`}</p>
                      {
                        imageUrl
                          ? (
                            <div className="file-upload__image-container">
                              <img
                                src={imageUrl}
                                alt="image"
                                className="file-upload__image-preview"
                                onClick={evt => {
                                  evt.preventDefault();
                                }}
                              />
                              <img
                                src="/images/delete.svg"
                                alt="delete"
                                className="file-upload__image-delete"
                                onClick={() => {
                                  setImageUrl("");
                                  setImage(null);
                                }}
                              />
                            </div>
                          )
                          : (
                            <Controller
                              name="document"
                              as={<Upload.Dragger
                                accept=".jpeg,.jpg,.png"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                fileList={[]}
                                beforeUpload={file => {
                                  setNoImageError(false);
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    setCropImageUrl(reader.result);
                                    setIsCropperOpen(true);
                                  };
                                  reader.readAsDataURL(file);

                                  return false;
                                }}
                              >
                                <div className="file-upload__text-container">
                                  <div>Drag & Drop</div>
                                  <div>or</div>
                                  <div className="file-upload__button">Upload</div>
                                </div>
                              </Upload.Dragger>}
                              control={control}
                            />
                          )
                      }
                      <p>Supports: .jpeg, .jpg, .png file formats</p>
                      <p>Recommended: minimum 995x435 px</p>
                      <Modal
                        className="modal"
                        visible={isCropperOpen}
                        onCancel={() => setIsCropperOpen(false)}
                        footer={null}
                        width="100%"
                        destroyOnClose={true}
                        closable={false}
                      >
                        <CropExperienceImageModalBody
                          cropImageUrl={cropImageUrl}
                          onCancel={() => setIsCropperOpen(false)}
                          onCrop={(croppedImageUrl, croppedImage) => {
                            setImageUrl(croppedImageUrl);
                            setIsCropperOpen(false);
                            setImage(croppedImage);
                          }}
                        />
                      </Modal>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="form-section__field">
                <label htmlFor="dates" className="form-label">Location</label>
                <Controller
                  name="placeId"
                  as={<Select
                    showSearch
                    showArrow={false}
                    className={`form-section__input${errors.placeId ? " form-section__field--error" : ""}`}
                    placeholder="Enter location"
                    dropdownClassName="admin-experience__dropdown-location"
                    filterOption={() => {
                      return true;
                    }}
                    value={experience.placeId}
                    onSearch={debounce(value => searchCity(value), 500)}
                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Enter a location" />}
                    optionLabelProp="label"
                  >
                    {
                      locations.map(item => {
                        const labels = item.label.split(", ");

                        return (
                          <Select.Option key={item.placeId} value={item.placeId} label={item.label}>
                            <div className="listing-search__place">
                              <img src="/images/location.svg" alt="location" className="listing-search__place-icon"/>
                              <div>
                                <div className="listing-search__place-title">{labels.shift()}</div>
                                <div className="listing-search__place-subtitle">{labels.join(", ")}</div>
                              </div>
                            </div>
                          </Select.Option>
                        )
                      })
                    }
                  </Select>}
                  control={control}
                  onChange={([placeId, data]) => {
                    setLocationLabel(data.label);

                    return placeId;
                  }}
                />
                {(errors.placeId &&
                  <span className="profile__error">{errors.placeId.message}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24} className="form-section__field">
                <TextAreaWithLabel
                  name="description"
                  control={control}
                  id="description"
                  label="Description"
                  error={errors.description}
                  placeholder="Add description"
                  rows={3}
                  maxLength={200}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  htmlType="submit"
                  disabled={isProcessing}
                  className="button button--default"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </div>
  );
};

Experience.propTypes = {
  experience: PropTypes.object.isRequired,
  error: PropTypes.string,
  isProcessing: PropTypes.bool,
  requestSuccess: PropTypes.bool,
  places: PropTypes.array.isRequired,
  clearError: PropTypes.func.isRequired,
  clearExperience: PropTypes.func.isRequired,
  createExperience: PropTypes.func.isRequired,
  updateExperience: PropTypes.func.isRequired,
  getExperience: PropTypes.func.isRequired,
  getPlaces: PropTypes.func.isRequired
};

export default connect(
  state => ({
    experience: state.admin.experiences.singleExperience,
    error: state.admin.experiences.error,
    isProcessing: state.admin.experiences.processing,
    requestSuccess: state.admin.experiences.requestSuccess,
    places: state.places.list
  }),
  {
    getExperience: getExperienceRequest,
    createExperience: createExperienceRequest,
    updateExperience: updateExperienceRequest,
    clearError: clearExperienceErrorAction,
    clearExperience: clearExperienceAction,
    getPlaces: getPlacesRequest
  }
)(Experience);
