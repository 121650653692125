import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const Seo = ({ title, description, noindex, schemaMarkup, children }) => {
  let fullTitle = title || "Plans Change";

  if (!fullTitle.includes("Plans Change")) {
    fullTitle = `Plans Change | ${title}`;
  }

  return (
    <React.Fragment>
      <Helmet>
        {description && <meta name="description" content={description} />}
        <meta name="robots" content={noindex ? "noindex" : "index"} />
        <title>{fullTitle}</title>
        {schemaMarkup && typeof window === "undefined" && <script type="application/ld+json">{schemaMarkup}</script>}
      </Helmet>
      {children}
    </React.Fragment>
  );
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  noindex: PropTypes.bool,
  schemaMarkup: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default Seo;
