import React from "react";
import { Col, Grid, Row } from "antd";
import PropTypes from "prop-types";

import ListingCard from "./ListingCard";

const BestDeal = ({ item }) => {
  const { xs, md, lg } = Grid.useBreakpoint();

  if (Array.isArray(item)) {
    return (
      <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 14 }}>
        <Row gutter={16} className="best-deal__listing-second-row">
          {item.map((listing, index) => {
            let size = "big";
            if (index <= 1 || (index === 2 && md && !lg) || xs) {
              size = "small";
            }

            return (
              <Col
                key={`best-deal-${listing.id}`}
                xs={{ span: 24 }}
                xl={{ span: index <= 1 ? 12 : 24 }}
                className="best-deal__listing"
              >
                <ListingCard
                  listing={listing}
                  size={size}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    );
  }

  return (
    <Col
      key={`best-deal-${item.id}`}
      xs={{ span: 24 }}
      md={{ span: 12 }}
      xl={{ span: 10 }}
      className="best-deal__listing"
    >
      <ListingCard
        listing={item}
        size={(xs || (md && !lg)) ? "small" : "big"}
      />
    </Col>
  );
};

BestDeal.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
};

export default BestDeal;
