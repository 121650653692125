import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Row } from "antd";

import { profileGetRequest } from "../../redux/profileDetails/profileDetailsActions";
import ProfileDetails from "./ProfileDetails";
import Address from "./Address";
import AdditionalServices from "./AdditionalServices/AdditionalServices"

export const Profile = ({
  getProfile,
  profile,
  address,
  isProfileFilled,
}) => {

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Row justify="center" align="top">
      <Col xs={{ span: 24 }}>
        <h1 className="form-title">Enter Your Details</h1>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            <div className="form-container profile__form-item">
              <ProfileDetails profile={profile}/>
            </div>
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            <div className="form-container">
              <Address address={address}/>
            </div>
          </Col>
        </Row>
        <span className="profile__required-hint">
          *All fields are mandatory
        </span>
        {isProfileFilled && <AdditionalServices/>}
      </Col>
    </Row>
  );
};

Profile.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  address: PropTypes.object,
  isProfileFilled: PropTypes.bool.isRequired,
  processing: PropTypes.bool,
  error: PropTypes.string
};

export default connect(
  state => ({
    profile: state.profileDetails.profile,
    address: state.profileDetails.profile.Address || {},
    isProfileFilled: state.profile.permissions.isProfileFilled,
    isProcessing: state.profileDetails.processing,
    error: state.profileDetails.error
  }),
  {
    getProfile: profileGetRequest,
  }
)(Profile);
