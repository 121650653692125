import * as types from "./highlightsActionsTypes";

export const getHighlightsRequest = (data) => {
  return { type: types.ADMIN_GET_HIGHLIGHTS_REQUEST, data };
};

export const deleteHighlightRequest = (data) => {
  return { type: types.ADMIN_DELETE_HIGHLIGHT_REQUEST, data };
};

export const addHighlightRequest = (data, callBack) => {
  return { type: types.ADMIN_ADD_HIGHLIGHT_REQUEST, data, callBack };
};

export const getHighlightRequest = (data) => {
  return { type: types.ADMIN_GET_HIGHLIGHT_REQUEST, data };
};

export const updateHighlightRequest = (data) => {
  return { type: types.ADMIN_UPDATE_HIGHLIGHT_REQUEST, data };
};

export const clearHighlightDetailsAction = (data) => {
  return { type: types.ADMIN_CLEAR_HIGHLIGHT_DETAILS_ACTION, data };
};
