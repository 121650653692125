import { all, put, call, takeLatest } from "redux-saga/effects";

import category from "../../../api/admin/category";
import * as types from "./categoryActionsTypes";
import * as generalTypes from "./../../general/generalActionsTypes";
import { MODAL_TYPE_SUCCESS, MODAL_TYPE_ERROR } from "../../../react/constants";

function *getCategoryList ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(category.getCategoryList, data);
    yield put({ type: types.ADMIN_GET_CATEGORY_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_CATEGORY_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *createCategory ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(category.createCategory, data);
    yield put({ type: types.ADMIN_CREATE_CATEGORY_REQUEST_SUCCESS, response });
    data = { sort: data.sort };
    yield put({ type: types.ADMIN_GET_CATEGORY_LIST_REQUEST, data });
    yield put({
      type: generalTypes.SHOW_MODAL,
      data: {
        type: MODAL_TYPE_SUCCESS,
        title: "Category created successfully"
      }
    });
  } catch (error) {
    yield put({ type: types.ADMIN_CREATE_CATEGORY_REQUEST_ERROR, error: error.message });
    if (error.status !== 400) {
      yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR } });
    }
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *deleteCategory ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(category.deleteCategory, data);
    yield put({ type: types.ADMIN_DELETE_CATEGORY_REQUEST_SUCCESS, response });
    data = { sort: data.sort };
    yield put({ type: types.ADMIN_GET_CATEGORY_LIST_REQUEST, data });
  } catch (error) {
    yield put({ type: types.ADMIN_DELETE_CATEGORY_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_CATEGORY_LIST_REQUEST, getCategoryList),
    yield takeLatest(types.ADMIN_CREATE_CATEGORY_REQUEST, createCategory),
    yield takeLatest(types.ADMIN_DELETE_CATEGORY_REQUEST, deleteCategory),
  ])
}
