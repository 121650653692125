import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BankAccountForm from "./BankAccountForm";
import {
  deleteBankAccountRequest,
  getBankAccountsRequest,
  updateBankAccountRequest
} from "../../redux/profile/profileActions";
import { BANK_ACCOUNT_PRIORITY_PRIMARY } from "../constants";
import BankAccountContainer from "./BankAccountContainer";

export const BankAccount = ({
  bankAccounts,
  getBankAccounts,
  error,
  isProcessing,
  deleteBankAccount,
  requestSuccess,
  updateBankAccount
}) => {
  const [showAccountForm, setShowAccountForm] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    hidden: true,
    bankAccountId: null,
    bankAccountName: ""
  });

  useEffect(() => {
    getBankAccounts();
  }, []);

  useEffect(() => {
    if (bankAccounts.length) {
      setShowAccountForm(false);
    }

    setModalOptions({
      hidden: true,
      bankAccountId: null,
      bankAccountName: ""
    });
  }, [requestSuccess]);

  if (bankAccounts.length && !showAccountForm) {
    return (
      <div className="bank-account">
        <section className="section">
          {bankAccounts.map(bankAccount => {
            return (
              <div key={bankAccount.Id} className="bank-account__wrapper">
                <BankAccountContainer
                  name={bankAccount.bankName}
                  priority={bankAccount.priority}
                  accountNumber={bankAccount.AccountNumber}
                  makePriority={() => updateBankAccount({
                    bankAccountId: bankAccount.Id,
                    priority: BANK_ACCOUNT_PRIORITY_PRIMARY
                  })}
                  onDelete={() => setModalOptions({
                    hidden: false,
                    bankAccountId: bankAccount.Id,
                    bankAccountName: bankAccount.bankName
                  })}
                  address={bankAccount.OwnerAddress}
                />
              </div>
            );
          })}
        </section>
        {bankAccounts.length < 2 &&
        <div className="bank-account__add-account-section">
          <Button
            type="primary"
            onClick={() => setShowAccountForm(true)}
          >
            Add account
          </Button>
        </div>
        }
        <Modal
          visible={!modalOptions.hidden}
          onCancel={() => setModalOptions({
            ...modalOptions,
            hidden: true
          })}
          closable={false}
          footer={null}
          className="modal"
        >
          <div className="bank-account__modal-body">
            <img src="/images/delete.svg" alt="close" className="bank-account__modal-icon"/>
            <div className="bank-account__modal-text">
              Are You sure you would like to
              delete <span className="text-semi-bold">{modalOptions.bankAccountName}</span> bank account
            </div>
            <div className="bank-account__modal-buttons">
              <Button
                className="bank-account__modal-button"
                type="primary"
                disabled={isProcessing}
                onClick={() => deleteBankAccount({ bankAccountId: modalOptions.bankAccountId })}
              >
                Delete
              </Button>
              <Button
                className="bank-account__modal-button"
                onClick={() => setModalOptions({
                  ...modalOptions,
                  hidden: true
                })}
              >
                Cancel
              </Button>
            </div>
            {error &&
            <div className="bank-account__modal-error">
              Something went wrong. Please try again later.
            </div>
            }
          </div>
        </Modal>
      </div>
    );
  }

  if (showAccountForm) {
    return <BankAccountForm/>;
  }

  return (
    <div className="bank-account">
      <Row>
        <Col span={24} className="bank-account__empty-container">
          <Row align="middle" justify="space-around">
            <Col span={24} className="profile-section bank-account__empty">
              <img src="/images/info.svg" alt="info" className="bank-account__empty-icon"/>
              <div className="bank-account__empty-text">
                To enable transfer of funds, you need to set up a bank account.
              </div>
            </Col>
          </Row>
          <Row align="middle" justify="space-around">
            <Col span={24}>
              <Button
                type="primary"
                onClick={() => setShowAccountForm(true)}
              >
                Add Account
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

BankAccount.propTypes = {
  bankAccounts: PropTypes.array.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
  deleteBankAccount: PropTypes.func.isRequired,
  updateBankAccount: PropTypes.func.isRequired,
  error: PropTypes.string,
  isProcessing: PropTypes.bool,
  requestSuccess: PropTypes.bool
};

export default connect(
  state => ({
    bankAccounts: state.profile.bankAccounts.list,
    error: state.profile.bankAccounts.error,
    isProcessing: state.profile.processing,
    requestSuccess: state.profile.bankAccounts.success
  }),
  {
    getBankAccounts: getBankAccountsRequest,
    deleteBankAccount: deleteBankAccountRequest,
    updateBankAccount: updateBankAccountRequest
  }
)(BankAccount);
