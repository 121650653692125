export const ADMIN_HIGHLIGHTS_SHOW_LOADING = "ADMIN_HIGHLIGHTS_SHOW_LOADING";
export const ADMIN_HIGHLIGHTS_HIDE_LOADING = "ADMIN_HIGHLIGHTS_HIDE_LOADING";

export const ADMIN_GET_HIGHLIGHTS_REQUEST = "ADMIN_GET_HIGHLIGHTS_REQUEST";
export const ADMIN_GET_HIGHLIGHTS_REQUEST_SUCCESS = "ADMIN_GET_HIGHLIGHTS_REQUEST_SUCCESS";
export const ADMIN_GET_HIGHLIGHTS_REQUEST_ERROR = "ADMIN_GET_HIGHLIGHTS_REQUEST_ERROR";

export const ADMIN_DELETE_HIGHLIGHT_REQUEST = "ADMIN_DELETE_HIGHLIGHT_REQUEST";
export const ADMIN_DELETE_HIGHLIGHT_REQUEST_SUCCESS = "ADMIN_DELETE_HIGHLIGHT_REQUEST_SUCCESS";
export const ADMIN_DELETE_HIGHLIGHT_REQUEST_ERROR = "ADMIN_DELETE_HIGHLIGHT_REQUEST_ERROR";

export const ADMIN_ADD_HIGHLIGHT_REQUEST = "ADMIN_ADD_HIGHLIGHT_REQUEST";
export const ADMIN_ADD_HIGHLIGHT_REQUEST_SUCCESS = "ADMIN_ADD_HIGHLIGHT_REQUEST_SUCCESS";
export const ADMIN_ADD_HIGHLIGHT_REQUEST_ERROR = "ADMIN_ADD_HIGHLIGHT_REQUEST_ERROR";

export const ADMIN_GET_HIGHLIGHT_REQUEST = "ADMIN_GET_HIGHLIGHT_REQUEST";
export const ADMIN_GET_HIGHLIGHT_REQUEST_SUCCESS = "ADMIN_GET_HIGHLIGHT_REQUEST_SUCCESS";
export const ADMIN_GET_HIGHLIGHT_REQUEST_ERROR = "ADMIN_GET_HIGHLIGHT_REQUEST_ERROR";

export const ADMIN_UPDATE_HIGHLIGHT_REQUEST = "ADMIN_UPDATE_HIGHLIGHT_REQUEST";
export const ADMIN_UPDATE_HIGHLIGHT_REQUEST_SUCCESS = "ADMIN_UPDATE_HIGHLIGHT_REQUEST_SUCCESS";
export const ADMIN_UPDATE_HIGHLIGHT_REQUEST_ERROR = "ADMIN_UPDATE_HIGHLIGHT_REQUEST_ERROR";

export const ADMIN_CLEAR_HIGHLIGHT_DETAILS_ACTION = "ADMIN_CLEAR_HIGHLIGHT_DETAILS_ACTION";
