import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Table, DatePicker, Select, Input } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

import { itemRender } from "../../../helpers/pagination";
import { getNotificationListRequest, readNotificationListRequest } from "../../../redux/admin/notification/notificationActions";
import {
  NOTIFICATION_TYPE_REGISTRATION,
  NOTIFICATION_TYPE_KYC,
  NOTIFICATION_TYPE_FAILED_VERIFICATION,
  NOTIFICATION_TYPE_CREATED_LISTING,
  NOTIFICATION_TYPE_CANCELLED_LISTING,
  NOTIFICATION_TYPE_MARKETING_SUBSCRIPTION
} from "../../constants";

const NOTIFICATION_TYPES = {
  [NOTIFICATION_TYPE_REGISTRATION]: "Registration",
  [NOTIFICATION_TYPE_KYC]: "KYC",
  [NOTIFICATION_TYPE_FAILED_VERIFICATION]: "Failed verification",
  [NOTIFICATION_TYPE_CREATED_LISTING]: "Created listing",
  [NOTIFICATION_TYPE_CANCELLED_LISTING]: "Cancelled listing",
  [NOTIFICATION_TYPE_MARKETING_SUBSCRIPTION]: "Email subscription",
};

const NOTIFICATION_SOURCES = {
  "user-profile": "User Profile",
  "seller-account": "Seller Account",
  "listings": "Listings",
  "subscription": "Subscriptions"
};

const NotificationList = ({
  getNotificationList,
  readNotificationList,
  notificationList,
  pagination,
  history,
  isProcessing
}) => {
  const [paginationObj, setPaginationObj] = useState({
    pageSize: 10,
    type: null,
    sort: "-createdAt"
  });

  useEffect(() => {
    getNotificationList(paginationObj);
  }, [paginationObj]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleFilterByType = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, type: value });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedFilterByDate = debounce(date => {
    setPaginationObj({
      ...paginationObj,
      createdAt: date ? moment(date).format("YYYY-MM-DD") : date,
      page: 1
    });
  }, 300);

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderSource = text => <span className="feedback-list__link">{text}</span>;

  const getDetails = (record) => {
    const user = record.User ? `${record.User.firstName} ${record.User.lastName}` : "Noname";
    const listing = record.Object ? record.Object.id : "1";
    const subscribedEmail = record.SubscribedEmail ? record.SubscribedEmail.email : "";

    switch (record.type) {
      case NOTIFICATION_TYPE_REGISTRATION:
        return `${user} signed up`;
      case NOTIFICATION_TYPE_KYC:
        return `${user} - KYC failed`;
      case NOTIFICATION_TYPE_FAILED_VERIFICATION:
        return `User ${user} - verification failed`;
      case NOTIFICATION_TYPE_CREATED_LISTING:
        return `${user} create listing #${listing}`;
      case NOTIFICATION_TYPE_CANCELLED_LISTING:
        return `${user} cancelled listing #${listing}`;
      case NOTIFICATION_TYPE_MARKETING_SUBSCRIPTION:
        return `${subscribedEmail} subscribed to marketing updates`;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Row className="feedback-list notification-list">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" align="top">
                <Col span={18} className="feedback-list__filters">
                  <span className="feedback-list__filter">Show items</span>
                  <Select
                    className="feedback-list__filter"
                    defaultValue={paginationObj.pageSize}
                    onChange={value => {
                      setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
                    }}
                  >
                    <Select.Option value="10">10</Select.Option>
                    <Select.Option value="20">20</Select.Option>
                    <Select.Option value="30">30</Select.Option>
                  </Select>
                  <Select
                    className="feedback-list__filter"
                    style={{ width: "150px" }}
                    defaultValue={paginationObj.type}
                    onChange={handleFilterByType}
                  >
                    <Select.Option value={null}>All</Select.Option>
                    {
                      Object.entries(NOTIFICATION_TYPES).map(items => (
                        <Select.Option value={items[0]} key={`type-${items[0]}`}>{items[1]}</Select.Option>
                      ))
                    }
                  </Select>
                  <DatePicker
                    className="feedback-list__filter"
                    format="DD-MM-YYYY"
                    onChange={value => delayedFilterByDate(value)}
                  />
                </Col>
                <Col span={6}>
                  <Input.Search
                    onChange={event => delayedSearch(event.target.value)}
                    onSearch={handleSearch}
                    placeholder="Search"
                    className="feedback-list__search"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isProcessing}
                rowClassName={record => record.className}
                onRow={record => {
                  return {
                    onClick: () => {
                      !record.isRead && readNotificationList({ body: { ids: [record.key] } });

                      let urlToRedirect = `/admin/user-management/users/${record.id}`;

                      switch (record.typeValue) {
                        case NOTIFICATION_TYPE_CREATED_LISTING:
                        case NOTIFICATION_TYPE_CANCELLED_LISTING:
                          urlToRedirect = `/listings/${record.slug}`;
                          break;
                        case NOTIFICATION_TYPE_MARKETING_SUBSCRIPTION:
                          urlToRedirect = "/admin/subscriptions";
                          break;
                      }

                      history.push(urlToRedirect);
                    },
                  };
                }}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  itemRender: itemRender,
                  showSizeChanger: false
                }}
                dataSource={notificationList.map(notification => {
                  return {
                    key: notification.id,
                    className: notification.isRead ? "" : "unread",
                    id: [
                      NOTIFICATION_TYPE_CREATED_LISTING,
                      NOTIFICATION_TYPE_CANCELLED_LISTING
                    ].includes(notification.type)
                      ? notification.Object && notification.Object.id
                      : notification.User && notification.User.id,
                    slug: [
                      NOTIFICATION_TYPE_CREATED_LISTING,
                      NOTIFICATION_TYPE_CANCELLED_LISTING
                    ].includes(notification.type)
                      ? notification.Object && notification.Object.slug
                      : notification.User && notification.User.id,
                    details: getDetails(notification),
                    source: NOTIFICATION_SOURCES[notification.source],
                    type: NOTIFICATION_TYPES[notification.type],
                    typeValue: notification.type,
                    isRead: notification.isRead,
                    createdAt: moment(notification.createdAt).format("DD MMM YYYY, hh:mmA")
                  }
                })}
                columns={[
                  {
                    title: "Source",
                    dataIndex: "source",
                    key: "source",
                    render: renderSource,
                    width: 150
                  },
                  {
                    title: "Type",
                    dataIndex: "type",
                    key: "type",
                    sorter: true
                  },
                  {
                    title: "Details",
                    dataIndex: "details",
                    key: "details"
                  },
                  {
                    title: "Date/Time",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                    sortDirections: ["descend", "ascend"],
                    width: 160
                  }
                ]}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
};

NotificationList.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  notificationList: PropTypes.array.isRequired,
  getNotificationList: PropTypes.func.isRequired,
  readNotificationList: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    isProcessing: state.admin.notification.processing,
    error: state.admin.notification.error,
    notificationList: state.admin.notification.data,
    pagination: {
      current: state.admin.notification.curPage,
      total: state.admin.notification.total,
      pageSize: state.admin.notification.perPage
    },
  }),
  {
    getNotificationList: getNotificationListRequest,
    readNotificationList: readNotificationListRequest
  }
)(NotificationList);
