import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import { getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import SwitchButton from "../shared/SwitchButton";
import { Link } from "react-router-dom";
import Seo from "../shared/Seo";

const legals = {
  "terms-and-conditions": {
    title: "Terms and Conditions",
    description: "See all terms & conditions for using PlansChange to book your hotel, including cancellation, secure booking and best price guarantee."
  },
  "privacy-policy": {
    title: "Privacy Policy",
    description: "We take privacy seriously at PlansChange, see our privacy policy to see how we make sure your information is protected, safe and never used without your consent."
  },
  "cookie-policy": {
    title: "Cookie Policy",
    description: "We use cookies on PlansChange.com to make sure that your experience is secure. View our cookie policy here and opt our of specific cookies to protect your privacy."
  }
};

const menuItems = [
  {
    key: "privacy-policy",
    label: "Privacy Policy",
    url: "/legal/privacy-policy"
  },
  {
    key: "terms-and-conditions",
    label: "Terms and Conditions",
    url: "/legal/terms-and-conditions"
  },
  {
    key: "cookie-policy",
    label: "Cookie Policy",
    url: "/legal/cookie-policy"
  }
];

const openCookiePreferences = () => {
  if (window) {
    if (window.cookieconsent) {
      window.cookieconsent.openPreferencesCenter();
    }
  }
}

const MenuItemsList = ({ items = [], activeItem }) => {
  return (
    <Row gutter={[16, 16]}>
      {items.map(item => {
        return (
          <Col xl={{ span: 24 }} key={item.key}>
            <Link to={item.url}>
              <SwitchButton isActive={activeItem === item.key}>{item.label}</SwitchButton>
            </Link>
          </Col>
        );
      })}
    </Row>
  );
};

MenuItemsList.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.string.isRequired
};

export const LegalPage = ({ location, getPage, page }) => {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  useServerSafeEffect(() => {
    getPage({ permalink: active });
  }, [active]);

  return (
    <React.Fragment>
      <Seo
        title={legals[active]?.title}
        description={legals[active]?.description}
      />
      <Row justify="space-around" align="top" className="legal-page">
        <Col xs={{ span: 24 }} md={{ span: 20 }}>
          <Row justify="start" className="legal-content">
            <Col xs={{ span: 24 }} xl={{ span: 5 }}>
              <div className="legal-page__sidebar">
                <Row gutter={[16, 16]}>
                  <MenuItemsList items={menuItems} activeItem={active}/>
                </Row>
              </div>
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 15 }}>
              <Row>
                <Col span={24}>
                  <h1 className="legal-page__title">PlansChange {legals[active]?.title}</h1>
                </Col>
              </Row>
              {page.updatedAt &&
              <Row>
                <Col span={24} className="legal-page__updated">
                  Last Updated: {moment(page.updatedAt).format("DD MMM YYYY")}
                </Col>
              </Row>
              }
              <Row className="legal-page__mobile-menu">
                <MenuItemsList items={menuItems} activeItem={active}/>
              </Row>
              <Row>
                <Col className="legal-page__content ql-editor ql-editor--content-parsed">
                  {parse(page.content || "")}
                </Col>
              </Row>
              { active === "cookie-policy" && <Row>
                <a href="#" onClick={openCookiePreferences} id="open_preferences_center">Update cookies preferences</a>
              </Row>}
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

LegalPage.propTypes = {
  getPage: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(
  state => ({
    page: state.staticPage.page
  }),
  {
    getPage: getPublicStaticPageRequest
  }
)(LegalPage);
