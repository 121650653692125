import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Button, Checkbox, Col, Input, Row } from "antd";
import * as yup from "yup";
import moment from "moment";

import {
  clearAuthErrorAction,
  clearVerifyAccountStatusAction,
  resendActivationEmailRequest,
  signInRequest
} from "../../redux/auth/authActions";

const SignInSchema = yup.object().shape({
  email: yup.string().trim().email("Please provide correct email").required("Email can not be empty").nullable(),
  password: yup.string().required("Password can not be empty"),
  termsAndConditions: yup.boolean().oneOf([true])
});

export const SignIn = ({
  isProcessing,
  error,
  isTerminated,
  isSuspended,
  verifiedEmail,
  login,
  resendActivationEmail,
  clearError,
  clearVerifyAccountStatus
}) => {
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [termsAndConditionsSelected, setTermsAndConditionsSelected] = useState(false);

  const queryPrevLocation = useMemo(() => queryString.stringify({
    ...queryString.parse(state?.from?.search),
    ...{ prevPath: state?.from?.pathname }
  }, {
    skipEmptyString: true,
    skipNull: true
  }), [state])

  const { handleSubmit, errors, control, reset } = useForm({
    validationSchema: SignInSchema
  });

  useEffect(() => {
    clearError();

    setEmail(verifiedEmail);
    reset({ email: verifiedEmail });
  }, []);

  const onSubmitHandler = (data) => {
    if (!termsAndConditionsSelected) {
      return;
    }

    setEmail(data.email);
    login(data);
    clearVerifyAccountStatus();
  };

  const resendEmailHandler = () => {
    resendActivationEmail({
      email,
      slug: "auth/verify"
    });
  };

  if (isTerminated || isSuspended) {
    return (
      <Row justify="space-around" align="middle" className="auth-row">
        <div className="sign-in__terminated form-container" data-testid="sign-in-user-deleted">
          <img src="/images/info-big.svg" alt="info"/>
          <h1 className="sign-in__title">Your account was {isSuspended ? "suspended" : "terminated"}</h1>
          <div className="sign-in__terminated-text">
            The Email associated with this account
            was {isSuspended ? "suspended" : "terminated"}{error ? ` ${moment(error).format("DD-MM-YYYY")}` : ""}.
            If you wish to reactivate your account
            please <Link className="link--primary" to="/contact-us">contact our support team</Link> for
            further assistance.
          </div>
        </div>
      </Row>
    );
  }

  return (
    <Row justify="space-around" align="middle" className="auth-row">
      <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <div className="sign-in">
          <h1 className="sign-in__title">Log In</h1>

          <div className="sign-in__sign-up-text">
            Don't have an account yet? <Link to="/auth/sign-up" className="sign-in__sign-up-link">Sign up</Link>
          </div>

          <form onSubmit={handleSubmit(onSubmitHandler)} className="sign-in__form form-container">
            <div className="sign-in__field">
              <label htmlFor="sign-in-email" className="sign-in__label">Email</label>
              <Controller
                name="email"
                as={<Input
                  id="sign-in-email"
                  className={`sign-in__input ${errors.email ? "sign-in__input--error" : ""}`}
                  data-testid="sign-in-email"
                />}
                control={control}
                onChange={([event]) => {
                  clearError();
                  clearVerifyAccountStatus();

                  return event.target.value;
                }}
              />
              {errors.email &&
              <span className="sign-in__error" data-testid="sign-in-error">{errors.email.message}</span>}
            </div>

            <div className="sign-in__field">
              <label htmlFor="sign-in-password" className="sign-in__label">Password</label>
              <Controller
                name="password"
                as={<Input.Password
                  visibilityToggle={true}
                  id="sign-in-password"
                  data-testid="sign-in-password"
                  className={`sign-in__input ${errors.password ? "sign-in__input--error" : ""}`}
                />}
                control={control}
                onChange={([event]) => {
                  clearError();

                  return event.target.value;
                }}
              />
              <div className="sign-in__error-container">
                {(error || errors.password) &&
                <span className="sign-in__error" data-testid="sign-in-error">{error || errors.password.message}</span>}
                {
                  error === "Verify your email first" && email
                    ? <div className="sign-in__forgot-password-link" onClick={resendEmailHandler}>Resend verification
                      email</div>
                    : <Link to="/auth/reset-password" className="sign-in__forgot-password-link">I forgot my
                      password</Link>
                }
              </div>
            </div>

            <div className="sign-up__checkbox-container">
              <Controller
                name="termsAndConditions"
                as={<Checkbox
                  value={termsAndConditionsSelected}
                  className={`checkbox--primary sign-up__checkbox${errors.termsAndConditions ? " sign-up__checkbox--error" : ""}`}
                  data-testid="sign-in-terms"
                >
                  By signing in or creating an account, you agree with our <Link
                    to="/legal/terms-and-conditions"
                    className="link--primary"
                    target="_blank"
                  >Terms & Conditions</Link> and <Link
                    to="/legal/privacy-policy"
                    className="link--primary"
                    target="_blank"
                  >Privacy Statement</Link>
                </Checkbox>}
                control={control}
                defaultValue={termsAndConditionsSelected}
                onChange={([event]) => {
                  setTermsAndConditionsSelected(event.target.checked);

                  return event.target.checked;
                }}
              />
            </div>

            <Button
              type="primary"
              htmlType="submit"
              disabled={isProcessing}
              className="sign-in__submit"
              data-testid="sign-in-submit"
              block
            >
              Log In
            </Button>
            <div className="sign-in__or">OR</div>
            {/*TODO Production hotfix. Replace this with link tag*/}
            <div
              onClick={() => window.location.href = `${process.env.REACT_APP_API_HOST}/api/v1/auth/google-login?${queryPrevLocation}`}
              // href={`${process.env.REACT_APP_API_HOST}/api/v1/auth/google-login?${queryPrevLocation}`}
              className="sign-in__with-google"
            >
              <img src="/images/google.svg" alt="Sign Up with Google" className="sign-in__google-logo"/>
              Log In with Google
            </div>
          </form>
        </div>
      </Col>
    </Row>
  );
};

SignIn.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  resendActivationEmail: PropTypes.func.isRequired,
  error: PropTypes.string,
  isTerminated: PropTypes.bool,
  isSuspended: PropTypes.bool,
  clearError: PropTypes.func.isRequired,
  verifiedEmail: PropTypes.string,
  clearVerifyAccountStatus: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.auth.processing,
    error: state.auth.error,
    isTerminated: state.auth.isTerminated,
    isSuspended: state.auth.isSuspended,
    verifiedEmail: state.auth.verifiedEmail
  }),
  {
    login: signInRequest,
    resendActivationEmail: resendActivationEmailRequest,
    clearError: clearAuthErrorAction,
    clearVerifyAccountStatus: clearVerifyAccountStatusAction
  }
)(SignIn);
