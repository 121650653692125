import { all, put, call, takeLatest } from "redux-saga/effects";

import reservations from "../../../api/admin/reservations";
import * as types from "./reservationsActionsTypes";

function *reservationSearch ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(reservations.reservationSearchRequest, data);
    yield put({ type: types.ADMIN_RESERVATIONS_SEARCH_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_RESERVATIONS_SEARCH_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *reservationModify ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(reservations.reservationModifyRequest, data);
    yield put({ type: types.ADMIN_RESERVATIONS_MODIFY_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_RESERVATIONS_MODIFY_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_RESERVATIONS_SEARCH_REQUEST, reservationSearch),
    yield takeLatest(types.ADMIN_RESERVATIONS_MODIFY_REQUEST, reservationModify)
  ])
}
