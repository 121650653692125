import React from "react";
import { Col, Grid, Rate, Row } from "antd";
import { reviews } from "../../../static/reviews";
import PropTypes from "prop-types";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation, Swiper, SwiperSlide } from "../Swiper/Swiper";

export const Reviews = ({ reviewsData }) => {
  const { xs, md, lg } = Grid.useBreakpoint();

  const isMobileView = !!xs;
  const isTabletView = md && !lg;

  let chunkSize = 3;
  if (isMobileView) {
    chunkSize = 1;
  }

  if (isTabletView) {
    chunkSize = 2;
  }

  return (
    <div className="home-page-reviews swiper-container swiper-with-navigation">
      <Swiper
        slidesPerView={chunkSize}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        spaceBetween={30}
        loop
      >
        {reviewsData.map((review, key) => {
          return (
            <SwiperSlide
              key={`review-${review.name}-${key}`}
            >
              <Col>
                <Row className="home-page-reviews__review">
                  <Col span={24}>
                    <Row className="home-page-reviews__review-heading" align="top">
                      <Col xs={{ span: 12 }} sm={{ span: 24 }} lg={{ span: 12 }}>
                        <Row>
                          <Col span={24} className="home-page-reviews__review-name">
                            {review.name}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} className="home-page-reviews__review-location">
                            {review.location}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 24 }} lg={{ span: 12 }} className="home-page-reviews__review-rating">
                        <Rate
                          value={review.rating}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="home-page-reviews__review-text">
                        “{review.text}”
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  );
}

Reviews.propTypes = {
  reviewsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      location: PropTypes.string,
      text: PropTypes.string,
      rating: PropTypes.number,
    }),
  ),
}

Reviews.defaultProps = {
  reviewsData: reviews,
}

export default Reviews;
