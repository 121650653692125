import { all, put, call, takeLatest } from "redux-saga/effects";

import dispute from "../../../api/admin/dispute";
import * as types from "./disputeActionsTypes";
import * as generalTypes from "../../general/generalActionsTypes";
import { MODAL_TYPE_ERROR } from "../../../react/constants";

function *getDisputeList ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dispute.getDisputeList, data);
    yield put({ type: types.ADMIN_GET_DISPUTE_LIST_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DISPUTE_LIST_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getDispute ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dispute.getDispute, data);
    yield put({ type: types.ADMIN_GET_DISPUTE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_GET_DISPUTE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *updateDispute ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dispute.updateDispute, data);
    yield put({ type: types.ADMIN_UPDATE_DISPUTE_REQUEST_SUCCESS, response });
    yield put({ type: generalTypes.HIDE_MODAL });
  } catch (error) {
    yield put({ type: types.ADMIN_UPDATE_DISPUTE_REQUEST_ERROR, error: error.message });
    yield put({ type: generalTypes.SHOW_MODAL, data: { type: MODAL_TYPE_ERROR, title: error.message } });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *createDisputeNote ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dispute.createDisputeNote, data);
    yield put({ type: types.ADMIN_CREATE_DISPUTE_NOTE_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.ADMIN_CREATE_DISPUTE_NOTE_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.ADMIN_GET_DISPUTE_LIST_REQUEST, getDisputeList),
    yield takeLatest(types.ADMIN_GET_DISPUTE_REQUEST, getDispute),
    yield takeLatest(types.ADMIN_UPDATE_DISPUTE_REQUEST, updateDispute),
    yield takeLatest(types.ADMIN_CREATE_DISPUTE_NOTE_REQUEST, createDisputeNote),
  ])
}
