export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_STATIC_PAGE_REQUEST = "ADMIN_GET_STATIC_PAGE_REQUEST";
export const ADMIN_GET_STATIC_PAGE_REQUEST_SUCCESS = "ADMIN_GET_STATIC_PAGE_REQUEST_SUCCESS";
export const ADMIN_GET_STATIC_PAGE_REQUEST_ERROR = "ADMIN_GET_STATIC_PAGE_REQUEST_ERROR";

export const ADMIN_UPDATE_STATIC_PAGE_REQUEST = "ADMIN_UPDATE_STATIC_PAGE_REQUEST";
export const ADMIN_UPDATE_STATIC_PAGE_REQUEST_SUCCESS = "ADMIN_UPDATE_STATIC_PAGE_REQUEST_SUCCESS";
export const ADMIN_UPDATE_STATIC_PAGE_REQUEST_ERROR = "ADMIN_UPDATE_STATIC_PAGE_REQUEST_ERROR";

export const ADMIN_CLEAR_STATIC_PAGE_ERROR = "ADMIN_CLEAR_STATIC_PAGE_ERROR";
