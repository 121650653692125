import * as types from "./hotelsActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  requestSuccess: false,
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: [],
  facilities: [],
  singleHotel: {
    location: {
      coordinates: []
    },
    facilities: [],
    HotelImages: []
  }
};

const hotels = (state = initialState, action) => {
  switch (action.type) {
    case types.ADMIN_HOTELS_SHOW_LOADING:
      return {
        ...state,
        processing: true,
        requestSuccess: false
      };
    case types.ADMIN_HOTELS_HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_HOTELS_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: ""
      };
    case types.ADMIN_DELETE_HOTEL_REQUEST_SUCCESS:
    case types.ADMIN_UPDATE_HOTEL_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: true
      };
    case types.ADMIN_GET_HOTELS_FACILITIES_REQUEST_SUCCESS:
      return {
        ...state,
        facilities: action.response
      };
    case types.ADMIN_ADD_HOTEL_REQUEST_SUCCESS:
    case types.ADMIN_GET_HOTEL_REQUEST_SUCCESS:
      return {
        ...state,
        singleHotel: action.response
      };
    case types.ADMIN_CLEAR_HOTEL_DETAILS_ACTION:
      return {
        ...state,
        singleHotel: initialState.singleHotel
      };
    case types.ADMIN_GET_HOTELS_REQUEST_ERROR:
    case types.ADMIN_GET_HOTELS_FACILITIES_REQUEST_ERROR:
    case types.ADMIN_DELETE_HOTEL_REQUEST_ERROR:
    case types.ADMIN_ADD_HOTEL_REQUEST_ERROR:
    case types.ADMIN_GET_HOTEL_REQUEST_ERROR:
    case types.ADMIN_UPDATE_HOTEL_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default hotels;
