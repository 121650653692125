import React, { useEffect } from "react";
import { Row, Col, Select, Button } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

import {
  deleteQuestionRequest,
  getQuestionsRequest,
  updateQuestionRequest,
  updateQuestionsOrderRequest,
  resetQuestionsOrderRequest,
  setQuestionsAction,
  getCategoryRequest
} from "../../../redux/admin/faq/faqActions";
import { hideModalAction, showModalAction } from "../../../redux/general/generalActions";
import DragSortingTable from "../../shared/DragSortingTable/DragSortingTable";
import BackLink from "../../shared/BackLink";

const Category = ({
  questions,
  match,
  requestSuccess,
  category,
  isProcessing,
  error,
  showModal,
  hideModal,
  getQuestions,
  updateQuestion,
  deleteQuestion,
  updateQuestionsOrder,
  resetQuestionsOrder,
  getCategory,
  setQuestions
}) => {
  const { id } = match.params;

  useEffect(() => {
    getCategory({ id });
    getQuestions({ categoryId: id, sort: "position,title", pageSize: -1 });
  }, []);

  useEffect(() => {
    hideModal();
  }, [requestSuccess]);

  const renderStatusSelect = (text, record) => {
    return (
      <span>
        <Select
          id={`status-${record.key}`}
          disabled={isProcessing}
          value={text}
          onChange={data => {
            updateQuestion({ id: record.key, status: data, action: data.slice(0, -2) });
          }}
        >
          <Select.Option value="published">Published</Select.Option>
          <Select.Option value="unpublished">Unpublished</Select.Option>
        </Select>
      </span>
    );
  };

  const renderEditButton = record => (
    <Link to={`/admin/faq/questions/${record.key}`} >
      <img
        src="/images/edit.svg"
        alt="edit"
        className="feedback-list__delete"
      />
    </Link>
  );

  const renderDeleteButton = record => (
    <img
      src="/images/close.svg"
      alt="remove"
      className="feedback-list__delete"
      onClick={() => showModal({
        type: "confirm",
        title: "Are You sure you would like to delete question?",
        params: {
          isProcessing,
          error,
          confirmAction: () => deleteQuestion({ id: record.key })
        }
      })}
    />
  );

  const handleOrderChange = (updatedData) => {
    updateQuestionsOrder({
      categoryId: id,
      data: updatedData.map((item, key) => {
        return {
          id: item.id,
          position: key + 1
        }
      })
    });
  };

  return (
    <Row className="admin-category">
      <Col span={24}>
        <Row>
          <Col span={24}>
            <BackLink label="Back to FAQ" url="/admin/faq/categories" />
          </Col>
        </Row>
        <Row className="admin-category__row">
          <Col span={24}>
            <Row align="middle" justify="space-between" className="admin-category__heading">
              <Col span={16} className="admin-category__title">
                {category.title}
              </Col>
              <Col span={8} className="admin-category__reset">
                <Button
                  className="button button--primary"
                  onClick={() => showModal({
                    type: "confirm",
                    title: "Are You sure you would like to reset default order?",
                    params: {
                      icon: "/images/info.svg",
                      confirmButtonText: "Yes",
                      confirmButtonClassName: "button--primary",
                      isProcessing,
                      error,
                      confirmAction: () => resetQuestionsOrder({ categoryId: id })
                    }
                  })}>
                  Reset to default order
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DragSortingTable
                  data={questions.map(question => {
                    return {
                      key: question.id,
                      id: question.id,
                      title: question.title,
                      categoryId: question.categoryId,
                      status: question.status,
                      createdAt: question.createdAt,
                      createdAtMoment: moment(question.createdAt).format("DD MMM YYYY, hh:mmA")
                    }
                  })}
                  columns={[
                    {
                      title: "Title",
                      dataIndex: "title",
                      key: "title",
                      className: "article-list__items-name"
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      key: "status",
                      width: 145,
                      render: renderStatusSelect
                    },
                    {
                      title: "Created",
                      dataIndex: "createdAtMoment",
                      key: "createdAt",
                      width: 145
                    },
                    {
                      title: "",
                      key: "actions",
                      width: 20,
                      render: renderEditButton
                    },
                    {
                      title: "",
                      key: "actions",
                      width: 20,
                      render: renderDeleteButton
                    }
                  ]}
                  pagination={false}
                  handleOrderChange={handleOrderChange}
                  setData={setQuestions}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

Category.propTypes = {
  match: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  category: PropTypes.object.isRequired,
  getQuestions: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  updateQuestionsOrder: PropTypes.func.isRequired,
  resetQuestionsOrder: PropTypes.func.isRequired,
  getCategory: PropTypes.func.isRequired,
  setQuestions: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.faq.processing,
    error: state.admin.faq.error,
    questions: state.admin.faq.questions.data,
    requestSuccess: state.admin.faq.requestSuccess,
    category: state.admin.faq.categories.singleCategory
  }),
  {
    getQuestions: getQuestionsRequest,
    updateQuestion: updateQuestionRequest,
    deleteQuestion: deleteQuestionRequest,
    updateQuestionsOrder: updateQuestionsOrderRequest,
    resetQuestionsOrder: resetQuestionsOrderRequest,
    getCategory: getCategoryRequest,
    setQuestions: setQuestionsAction,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(Category);
