import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Table, DatePicker, Select, Input } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

import {
  deleteArticleRequest,
  getArticleListRequest,
  publishArticleRequest
} from "../../../redux/admin/article/articleActions";
import { getCategoryListRequest } from "../../../redux/admin/category/categoryActions";
import { itemRender } from "../../../helpers/pagination";
import { hideModalAction, showModalAction } from "../../../redux/general/generalActions";

const ArticleList = ({
  articleList,
  getCategories,
  getArticleList,
  pagination,
  options,
  isProcessing,
  error,
  requestSuccess,
  deleteArticle,
  publishArticle,
  showModal,
  hideModal
}) => {
  const [paginationObj, setPaginationObj] = useState({
    categoryId: null,
    pageSize: 10,
    sort: "-createdAt"
  });

  useEffect(() => {
    getCategories({ perPage: 100 });
  }, []);

  useEffect(() => {
    getArticleList(paginationObj);
  }, [paginationObj]);

  useEffect(() => {
    hideModal();
  }, [requestSuccess]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleFilterByCategory = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, categoryId: value });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedFilterByDate = debounce(date => {
    setPaginationObj({
      ...paginationObj,
      createdAt: date ? moment(date).format("YYYY-MM-DD") : date,
      page: 1
    });
  }, 300);

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderImage = imageUrl => <div className="article-list__image"><img src={imageUrl} alt="cover-image" /></div>;

  const renderCategories = array => (
    <div className="article-list__categories">
      {
        array.map(category => <span key={category.id} className="category-name">{category.name}</span>)
      }
    </div>
  );

  const renderStatusSelect = (text, record) => {
    const value = text ? "publish" : "unpublish";

    return (
      <span>
        <Select
          id={`status-${record.key}`}
          disabled={isProcessing}
          value={value}
          onChange={data => {
            publishArticle({ id: record.key, publish: data });
          }}
        >
          <Select.Option value="publish">Published</Select.Option>
          <Select.Option value="unpublish">Unpublished</Select.Option>
        </Select>
      </span>
    );
  };

  const renderEditButton = record => (
    <Link to={`/admin/blog/articles/${record.key}`} >
      <img
        src="/images/edit.svg"
        alt="edit"
        className="feedback-list__delete"
      />
    </Link>
  );

  const renderDeleteButton = record => (
    <img
      src="/images/close.svg"
      alt="remove"
      className="feedback-list__delete"
      onClick={() => showModal({
        type: "confirm",
        title: "Are You sure you would like to delete feedback?",
        params: {
          isProcessing,
          error,
          confirmAction: () => deleteArticle({ id: record.key })
        }
      })}
    />
  );

  return (
    <Row className="feedback-list article-list">
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Row justify="space-between" align="top">
              <Col span={16} className="feedback-list__filters">
                <Select
                  className="feedback-list__filter article-list__filter-size"
                  defaultValue={paginationObj.pageSize}
                  onChange={value => {
                    setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
                  }}
                >
                  <Select.Option value={10}>Show 10</Select.Option>
                  <Select.Option value={20}>Show 20</Select.Option>
                  <Select.Option value={30}>Show 30</Select.Option>
                </Select>
                <Select
                  className="feedback-list__filter article-list__filter-category"
                  defaultValue={paginationObj.categoryId}
                  onChange={handleFilterByCategory}
                >
                  <Select.Option value={null}>All</Select.Option>
                  {
                    options.map(category => <Select.Option key={`option-${category.id}`} value={category.id}>{category.name}</Select.Option>)
                  }
                </Select>
                <DatePicker
                  className="feedback-list__filter"
                  onChange={value => delayedFilterByDate(value)}
                  format="DD-MM-YYYY"
                />
              </Col>
              <Col span={8} className="article-list__search-wrapper">
                <Input.Search
                  onChange={event => delayedSearch(event.target.value)}
                  onSearch={handleSearch}
                  placeholder="Search"
                  className="feedback-list__search"
                />
                <Link to="/admin/blog/articles/new" className="ant-btn button button--primary">New Article</Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className="article-list__items"
              loading={isProcessing}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                itemRender: itemRender,
                showSizeChanger: false
              }}
              dataSource={articleList.map(article => {
                return {
                  key: article.id,
                  coverImageUrl: article.coverImageUrl,
                  title: article.title,
                  categories: article.categories,
                  author: article.author ? `${article.author.first_name} ${article.author.last_name}` : "",
                  publishDate: article.publishDate,
                  createdAt: moment(article.createdAt).format("DD MMM YYYY, hh:mmA")
                }
              })}
              columns={[
                {
                  title: "Cover",
                  dataIndex: "coverImageUrl",
                  key: "coverImageUrl",
                  render: renderImage
                },
                {
                  title: "Name",
                  dataIndex: "title",
                  key: "title",
                  className: "article-list__items-name"
                },
                {
                  title: "Category",
                  dataIndex: "categories",
                  key: "categories",
                  render: renderCategories
                },
                {
                  title: "Author",
                  dataIndex: "author",
                  key: "author"
                },
                {
                  title: "Status",
                  dataIndex: "publishDate",
                  key: "publishDate",
                  defaultSortOrder: "",
                  sorter: (a, b) => moment(a.publishDate).unix() - moment(b.publishDate).unix(),
                  sortDirections: ["descend", "ascend"],
                  render: renderStatusSelect
                },
                {
                  title: "Created",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                  sortDirections: ["descend", "ascend"],
                  width: 145
                },
                {
                  title: "",
                  key: "actions",
                  width: 20,
                  render: renderEditButton
                },
                {
                  title: "",
                  key: "actions",
                  width: 20,
                  render: renderDeleteButton
                }
              ]}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

ArticleList.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  articleList: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  getCategories: PropTypes.func.isRequired,
  getArticleList: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  publishArticle: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.article.processing,
    error: state.admin.article.error,
    options: state.admin.category.data,
    articleList: state.admin.article.data,
    pagination: {
      current: state.admin.article.curPage,
      total: state.admin.article.total,
      pageSize: state.admin.article.perPage
    },
    requestSuccess: state.admin.article.requestSuccess
  }),
  {
    getCategories: getCategoryListRequest,
    getArticleList: getArticleListRequest,
    deleteArticle: deleteArticleRequest,
    publishArticle: publishArticleRequest,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(ArticleList);
