export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const GET_PROFILE_DETAILS_REQUEST = "GET_PROFILE_DETAILS_REQUEST";
export const GET_PROFILE_DETAILS_REQUEST_ERROR = "GET_PROFILE_DETAILS_REQUEST_ERROR";
export const GET_PROFILE_DETAILS_REQUEST_SUCCESS = "GET_PROFILE_DETAILS_REQUEST_SUCCESS";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_REQUEST_ERROR = "UPDATE_USER_REQUEST_ERROR";
export const UPDATE_USER_REQUEST_SUCCESS = "UPDATE_USER_REQUEST_SUCCESS";

export const GET_PROFILE_HISTORY_REQUEST = "GET_PROFILE_HISTORY_REQUEST";
export const GET_PROFILE_HISTORY_REQUEST_SUCCESS = "GET_PROFILE_HISTORY_REQUEST_SUCCESS";
export const GET_PROFILE_HISTORY_REQUEST_ERROR = "GET_PROFILE_HISTORY_REQUEST_ERROR";

export const CREATE_TEMPORARY_PROFILE_REQUEST = "CREATE_TEMPORARY_PROFILE_REQUEST";
export const CREATE_TEMPORARY_PROFILE_REQUEST_ERROR = "CREATE_TEMPORARY_PROFILE_REQUEST_ERROR";
export const CREATE_TEMPORARY_PROFILE_REQUEST_SUCCESS = "CREATE_TEMPORARY_PROFILE_REQUEST_SUCCESS";

export const UPDATE_PROFILE_DETAILS_REQUEST = "UPDATE_PROFILE_DETAILS_REQUEST";
export const UPDATE_PROFILE_DETAILS_REQUEST_PROCESSING = "UPDATE_PROFILE_DETAILS_REQUEST_PROCESSING";
export const UPDATE_PROFILE_DETAILS_REQUEST_ERROR = "UPDATE_PROFILE_DETAILS_REQUEST_ERROR";
export const UPDATE_PROFILE_DETAILS_REQUEST_SUCCESS = "UPDATE_PROFILE_DETAILS_REQUEST_SUCCESS";

export const UPDATE_PROFILE_ADDRESS_REQUEST = "UPDATE_PROFILE_ADDRESS_REQUEST";
export const UPDATE_PROFILE_ADDRESS_REQUEST_PROCESSING = "UPDATE_PROFILE_ADDRESS_REQUEST_PROCESSING";
export const UPDATE_PROFILE_ADDRESS_REQUEST_ERROR = "UPDATE_PROFILE_ADDRESS_REQUEST_ERROR";
export const UPDATE_PROFILE_ADDRESS_REQUEST_SUCCESS = "UPDATE_PROFILE_ADDRESS_REQUEST_SUCCESS";

export const CLEAR_PROFILE_DETAILS_ERROR = "CLEAR_PROFILE_DETAILS_ERROR";
