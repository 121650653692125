import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Croppie from "croppie";
import { Row, Col, Button } from "antd";
import debounce from "lodash/debounce";
import "croppie/croppie.css";

export const CropArticleImageModalBody = ({ cropImageUrl, onCancel, onCrop }) => {
  const cropperRef = useRef(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState("");

  let cropper = null;

  useEffect(() => {
    cropper = new Croppie(cropperRef.current, {
      viewport: {
        width: 680,
        height: 450
      },
      enableExif: true,
      customClass: "crop-article-image-modal__cropper-container"
    });

    cropper.bind({
      url: cropImageUrl
    });

    cropperRef.current.addEventListener("update", () => {
      cropper.result({ type: "base64" })
        .then(debounce(data => setCroppedImagePreview(data), 300));
    });

    return () => {
      cropper.destroy();
    }
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[32, 32]}>
          <Col span={14} className="crop-article-image-modal__cropper-column">
            <Row>
              <Col span={24} className="crop-article-image-modal__title">
                Crop image
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div ref={cropperRef} />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="crop-article-image-modal__buttons">
                <Button
                  type="default"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => onCrop(croppedImagePreview)}
                >
                  Crop
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row>
              <Col span={24} className="crop-article-image-modal__title">
                Image Previews
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row className="crop-article-image-modal__preview-row">
                  <Col span={24}>
                    <div
                      style={{ backgroundImage: `url("${croppedImagePreview}")` }}
                      className="crop-article-image-modal__preview crop-article-image-modal__preview--extra-large"
                    />
                  </Col>
                </Row>
                <Row className="crop-article-image-modal__preview-row">
                  <Col span={24}>
                    <div
                      style={{ backgroundImage: `url("${croppedImagePreview}")` }}
                      className="crop-article-image-modal__preview crop-article-image-modal__preview--large"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <div
                      style={{ backgroundImage: `url("${croppedImagePreview}")` }}
                      className="crop-article-image-modal__preview crop-article-image-modal__preview--medium"
                    />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ backgroundImage: `url("${croppedImagePreview}")` }}
                      className="crop-article-image-modal__preview crop-article-image-modal__preview--small"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CropArticleImageModalBody.propTypes = {
  cropImageUrl: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired
};

export default CropArticleImageModalBody;
