import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const dispute = {
  async getDisputeList(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/disputes?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getDispute(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/disputes/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateDispute(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/disputes/${data.id}`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createDisputeNote(data) {
    try {
      const result = await axios.post(`${API_URL}/admin/disputes/${data.id}/notes`, data.body);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default dispute;
