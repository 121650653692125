import * as types from "./placesActionsTypes";

export const getPlacesRequest = data => {
  return { type: types.GET_PLACES_REQUEST, data };
};

export const setPlacesListAction = data => {
  return { type: types.SET_PLACES_LIST, data };
};

export const getPlaceDetailsRequest = data => {
  return { type: types.GET_PLACE_DETAILS_REQUEST, data };
};
