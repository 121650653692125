import React, { Component } from "react";

export default class ReactQuill extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      this.ReactQuill = require("react-quill");
    }
  }

  render() {
    const ReactQuillInner = this.ReactQuill;

    if (typeof window !== "undefined" && ReactQuill) {
      return <ReactQuillInner {...this.props}/>;
    }

    return <textarea/>;
  }
}
