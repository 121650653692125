export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const ADMIN_GET_CHARGE_OPTIONS_REQUEST = "ADMIN_GET_CHARGE_OPTIONS_REQUEST";
export const ADMIN_GET_CHARGE_OPTIONS_REQUEST_SUCCESS = "ADMIN_GET_CHARGE_OPTIONS_REQUEST_SUCCESS";
export const ADMIN_GET_CHARGE_OPTIONS_REQUEST_ERROR = "ADMIN_GET_CHARGE_OPTIONS_REQUEST_ERROR";

export const ADMIN_UPDATE_CHARGE_OPTION_REQUEST = "ADMIN_UPDATE_CHARGE_OPTION_REQUEST";
export const ADMIN_UPDATE_CHARGE_OPTION_REQUEST_SUCCESS = "ADMIN_UPDATE_CHARGE_OPTION_REQUEST_SUCCESS";
export const ADMIN_UPDATE_CHARGE_OPTION_REQUEST_ERROR = "ADMIN_UPDATE_CHARGE_OPTION_REQUEST_ERROR";

export const ADMIN_SET_DEFAULT_COMMISSION_REQUEST = "ADMIN_SET_DEFAULT_OPTION_REQUEST";
export const ADMIN_SET_DEFAULT_COMMISSION_REQUEST_SUCCESS = "ADMIN_SET_DEFAULT_OPTION_REQUEST_SUCCESS";
export const ADMIN_SET_DEFAULT_COMMISSION_REQUEST_ERROR = "ADMIN_SET_DEFAULT_OPTION_REQUEST_ERROR";

export const ADMIN_GET_FEE_CONFIGURATIONS_REQUEST = "ADMIN_GET_FEE_CONFIGURATIONS_REQUEST";
export const ADMIN_GET_FEE_CONFIGURATIONS_REQUEST_SUCCESS = "ADMIN_GET_FEE_CONFIGURATIONS_REQUEST_SUCCESS";
export const ADMIN_GET_FEE_CONFIGURATIONS_REQUEST_ERROR = "ADMIN_GET_FEE_CONFIGURATIONS_REQUEST_ERROR";

export const ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST = "ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST";
export const ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST_SUCCESS = "ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST_SUCCESS";
export const ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST_ERROR = "ADMIN_UPDATE_FEE_CONFIGURATION_REQUEST_ERROR";

export const ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST = "ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST";
export const ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST_SUCCESS = "ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST_SUCCESS";
export const ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST_ERROR = "ADMIN_GET_LISTINGS_FOR_ACCOUNTING_REQUEST_ERROR";

export const ADMIN_GET_LISTINGS_FOR_ACCOUNTING_CSV_EXPORT_URL_REQUEST = "ADMIN_GET_LISTINGS_FOR_ACCOUNTING_CSV_EXPORT_URL_REQUEST";

export const ADMIN_CLEAR_FINANCE_SETTINGS_ERROR = "ADMIN_CLEAR_FINANCE_SETTINGS_ERROR";

export const ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST = "ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST";
export const ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST_SUCCESS = "ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST_SUCCESS";
export const ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST_ERROR = "ADMIN_GET_PAYMENT_FEES_CONFIGURATIONS_REQUEST_ERROR";

export const ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST = "ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST";
export const ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST_SUCCESS = "ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST_SUCCESS";
export const ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST_ERROR = "ADMIN_UPDATE_PAYMENT_FEES_CONFIGURATION_REQUEST_ERROR";
