import React, { useEffect } from "react";

export const Hotjar = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const script = document.createElement("script");
      script.innerHTML = `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${process.env.REACT_APP_HOTJAR_SITE_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;r.type = "text/plain";
          r.setAttribute("data-cookie-consent", "tracking");
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;

      document.getElementsByTagName("head")[0].appendChild(script);
    }
  }, []);

  return <React.Fragment/>
};

export default Hotjar;
