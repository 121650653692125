import * as types from "./subscriptionsActionsTypes";

export const initialState = {
  processing: false,
  error: "",
  total: 0,
  curPage: 1,
  pages: 1,
  perPage: 10,
  data: [],
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };
    case types.ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.response,
        error: ""
      };
    case types.ADMIN_GET_SUBSCRIPTIONS_LIST_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.ADMIN_CLEAR_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        error: "",
        requestSuccess: false
      };
    default:
      return state;
  }
};

export default subscriptions;
