import React from "react";
import PropTypes from "prop-types"

const PreventCopyWrapper = ({ warningMessage, children }) => {
  return (
    <div
      style={{
        userSelect: "none"
      }}
      onCopy={e => {
        e.clipboardData.setData("text/plain", warningMessage);
        e.preventDefault()
      }}
      onContextMenu={(e) => {
        e.preventDefault()
      }}
    >
      {children}
    </div>
        
  );
}

PreventCopyWrapper.defaultProps = {
  warningMessage: "You can not copy this content",
}

PreventCopyWrapper.propTypes = {
  warningMessage: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
}

export default PreventCopyWrapper;
