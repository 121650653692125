import axios from "../helpers/axios";
import Cookies from "universal-cookie";
import * as queryString from "query-string";

import { API_URL } from "./constants";
import { getBrowserInfo } from "../helpers/common";

const cookies = new Cookies();
const cookiesExpiresAt = cookies.get("expiresAt");

const profile = {
  async accountVerification(data) {
    try {
      const result = await axios.post(`${API_URL}/seller/verify-account`, {
        ...data,
        browserInfo: getBrowserInfo()
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getPermissions(data) {
    try {
      const result = await axios.get(`${API_URL}/profile/permissions`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async verifyKYC(data) {
    try {
      const result = await axios.post(`${API_URL}/seller/verify-kyc`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getProfile() {
    try {
      const result = await axios.get(`${API_URL}/users/profile`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateUser(data) {
    try {
      const result = await axios.put(`${API_URL}/users`, data);

      cookies.set("authUser", {
        id: result.data.id,
        first_name: result.data.first_name,
        last_name: result.data.last_name,
        email: result.data.email
      }, {
        expires: new Date(cookiesExpiresAt),
        path: "/"
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createTemporaryProfile(data) {
    try {
      const result = await axios.post(`${API_URL}/users/temporary`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async changePassword(data) {
    try {
      const result = await axios.post(`${API_URL}/users/changePassword`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async changeEmail(data) {
    try {
      const result = await axios.patch(`${API_URL}/users/change-email`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async closeAccount() {
    try {
      const result = await axios.delete(`${API_URL}/users`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getProfileHistory(data) {
    try {
      const result = await axios.get(`${API_URL}/profile/history`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createBankAccount(data) {
    try {
      const result = await axios.post(`${API_URL}/seller/bank-accounts`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getBankAccounts() {
    try {
      const result = await axios.get(`${API_URL}/seller/bank-accounts`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteBankAccount(data) {
    try {
      const result = await axios.delete(`${API_URL}/seller/bank-accounts/${data.bankAccountId}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateBankAccount(data) {
    try {
      const result = await axios.patch(`${API_URL}/seller/bank-accounts/${data.bankAccountId}`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getBalance() {
    try {
      const result = await axios.get(`${API_URL}/seller/balance`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async withdrawMoney() {
    try {
      const result = await axios.get(`${API_URL}/seller/withdraw`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getTransactions(data) {
    try {
      const result = await axios.get(`${API_URL}/seller/transactions?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateProfileDetails(data) {
    try {
      const result = await axios.patch(`${API_URL}/users/profile`, data);

      cookies.set("authUser", {
        id: result.data.id,
        first_name: result.data.first_name,
        last_name: result.data.last_name,
        email: result.data.email
      }, {
        expires: new Date(cookiesExpiresAt),
        path: "/"
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateProfileAddress(data) {
    try {
      const result = await axios.patch(`${API_URL}/users/address`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createAccountVerificationIntent(data) {
    try {
      const result = await axios.post(`${API_URL}/seller/payment-intents`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default profile;
