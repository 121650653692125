import React from "react";
import PropTypes from "prop-types";

export const ArrowRightIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <g id="Group_3689" data-name="Group 3689" transform="translate(-1537 -2323)">
        <g id="Ellipse_37" data-name="Ellipse 37" transform="translate(1537 2323)" fill="none" stroke={props.color || "#fff"} strokeWidth="3">
          <circle cx="28" cy="28" r="28" stroke="none"/>
          <circle cx="28" cy="28" r="26.5" fill="none"/>
        </g>
        <path id="Path_1903" data-name="Path 1903" d="M103.5,173.5l9.946,9.946-9.946,9.946" transform="translate(1458.157 2167.471)" fill="none" stroke={props.color || "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
      </g>
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  color: PropTypes.string
};

export default ArrowRightIcon;
