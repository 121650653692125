import React, { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Button, Checkbox, Col, Modal, Row, Upload } from "antd";

import CropArticleImageModalBody from "./CropArticleImageModalBody";

import {
  clearArticleAction,
  clearArticleErrorAction,
  createArticleRequest,
  getArticleRequest,
  saveAndPublishArticleRequest,
  updateArticleRequest
} from "../../../redux/admin/article/articleActions";
import { getCategoryListRequest } from "../../../redux/admin/category/categoryActions";
import BlogSingleArticle from "../../Blog/BlogSingleArticle";
import InputWithLabel from "../../shared/InputWithLabel";
import SelectWithLabel from "../../shared/SelectWithLabel";
import BackLink from "../../shared/BackLink";
import RichTextEditor from "../../shared/RichTextEditor/RichTextEditor";
import TextAreaWithLabel from "../../shared/TextAreaWithLabel";

const ArticleVerificationSchema = yup.object().shape({
  title: yup.string().trim().max(255).required("Title can not be empty"),
  category: yup.array().min(1, "Category can not be empty"),
  metaTitle: yup.string().trim().nullable(),
  metaDescription: yup.string().trim().nullable(),
  url: yup.string().trim().nullable(),
  shortDescription: yup.string().trim().required("Short description can not be empty").nullable(),
  body: yup.string().trim().required("Body can not be empty"),
  isTopArticle: yup.boolean(),
  isLatestArticle: yup.boolean()
});

let action = "save";

const Article = ({
  article,
  options,
  error,
  match,
  isProcessing,
  getCategories,
  getArticle,
  createArticle,
  updateArticle,
  publishArticle,
  clearArticle,
  clearError
}) => {
  const { id } = match.params;

  const buttonRef = useRef(null);

  const [imageUrl, setImageUrl] = useState("");
  const [noImageError, setNoImageError] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [cropImageUrl, setCropImageUrl] = useState();
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [previewArticle, setPreviewArticle] = useState({
    title: "",
    body: "",
    categories: [],
    coverImageUrl: ""
  });

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: ArticleVerificationSchema
  });

  useEffect(() => {
    getCategories({ perPage: 100 });
  }, []);

  useEffect(() => {
    clearArticle();
    if (id !== "new") {
      getArticle({ id });
    }

    return () => clearArticle();
  }, [id]);

  useEffect(() => {
    reset({
      title: article.title,
      url: article.slug,
      shortDescription: article.shortDescription,
      body: article.body,
      category: article.categories.map(item => item.id),
      isTopArticle: article.isTopArticle,
      isLatestArticle: article.isLatestArticle,
      metaTitle: article.metaTitle,
      metaDescription: article.metaDescription
    });
    setImageUrl(article.coverImageUrl);
    setPreviewArticle(article);
  }, [article]);

  const clearErrorHandler = () => {
    if (error) {
      clearError();
    }
  };

  const submitHandler = (data) => {
    if (!imageUrl) {
      return setNoImageError(true);
    }

    const formData = new FormData();
    formData.append("title", data.title);
    data.category.forEach(item => formData.append("category", item));

    if (data.url) {
      formData.append("url", data.url);
    }

    formData.append("shortDescription", data.shortDescription);
    formData.append("body", data.body);
    imageUrl !== article.coverImageUrl &&
    formData.append("coverImage", imageUrl);
    formData.append("isTopArticle", data.isTopArticle);
    formData.append("isLatestArticle", data.isLatestArticle);
    formData.append("metaTitle", data.metaTitle || "");
    formData.append("metaDescription", data.metaDescription || "");

    if (action === "publish") {
      publishArticle({ id: id === "new" ? null : id, body: formData, publish: "publish" });
    } else if (action === "preview") {
      setPreviewArticle({
        title: data.title,
        shortDescription: data.shortDescription,
        body: data.body,
        categories: data.category.map(itemId => options.find(option => itemId === option.id)).filter(x => x),
        coverImageUrl: imageUrl
      });
      setIsPreviewOpen(true);
    } else if (id === "new") {
      createArticle({ body: formData });
    } else {
      updateArticle({ id: id, body: formData });
    }
    action = "save";
  };

  return (
    <Fragment>
      <div className="admin-article">
        <Row>
          <Col span={24}>
            <BackLink label="Back to articles" url="/admin/blog/articles"/>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <Row>
                <Col span={24} className="form-section">
                  <Row>
                    <div className="form-section__title">{id === "new" ? "New article" : "Edit article"}</div>
                  </Row>
                  <Row>
                    <Col span={12} style={{ paddingRight: "19px" }}>
                      <Row>
                        <InputWithLabel
                          name="title"
                          className="form-section__field"
                          control={control}
                          id="title"
                          onChange={([event]) => {
                            clearErrorHandler();

                            return event.target.value;
                          }}
                          label="Title"
                          defaultValue={article.title}
                          error={errors.title}
                          placeholder="Enter Title"
                        />
                      </Row>
                      <Row>
                        <div className="form-section__field">
                          <SelectWithLabel
                            mode="multiple"
                            name="category"
                            control={control}
                            id="category"
                            defaultValue={article.categories.map(item => item.id)}
                            onChange={value => {
                              clearError();

                              return value[0];
                            }}
                            label="Category"
                            error={errors.category}
                            options={options.map(c => ({ value: c.id, label: c.name }))}
                            placeholder="Select"
                          />
                        </div>
                      </Row>
                    </Col>
                    <Col span={12} style={{ paddingLeft: "19px" }}>
                      <div className={`file-upload__container${noImageError ? " file-upload__error" : ""}`}>
                        <p>{`Cover image${noImageError ? " can't be empty" : ""}`}</p>
                        {
                          imageUrl
                            ? (
                              <div className="file-upload__image-container">
                                <img
                                  src={imageUrl}
                                  alt="image"
                                  className="file-upload__image-preview"
                                  onClick={evt => {
                                    evt.preventDefault();
                                  }}
                                />
                                <img
                                  src="/images/delete.svg"
                                  alt="delete"
                                  className="file-upload__image-delete"
                                  onClick={() => {
                                    setImageUrl("");
                                  }}
                                />
                              </div>
                            )
                            : (
                              <Controller
                                name="document"
                                as={<Upload.Dragger
                                  accept=".jpeg,.jpg,.png"
                                  listType="picture-card"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  fileList={[]}
                                  beforeUpload={file => {
                                    setNoImageError(false);
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                      setCropImageUrl(reader.result);
                                      setIsCropperOpen(true);
                                    };
                                    reader.readAsDataURL(file);

                                    return false;
                                  }}
                                >
                                  <div className="file-upload__text-container">
                                    <div>Drag & Drop</div>
                                    <div>or</div>
                                    <div className="file-upload__button">Upload</div>
                                  </div>
                                </Upload.Dragger>}
                                control={control}
                              />
                            )
                        }
                        <p>Supports: .jpeg, .jpg, .png file formats</p>
                        <p>Recommended: minimum 680x450 px</p>
                        <Modal
                          className="modal admin-article__cropper"
                          visible={isCropperOpen}
                          onCancel={() => setIsCropperOpen(false)}
                          footer={null}
                          width="100%"
                          destroyOnClose={true}
                          closable={false}
                        >
                          <CropArticleImageModalBody
                            cropImageUrl={cropImageUrl}
                            onCancel={() => setIsCropperOpen(false)}
                            onCrop={croppedImage => {
                              setImageUrl(croppedImage);
                              setIsCropperOpen(false);
                            }}
                          />
                        </Modal>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="form-section__field">
                  <InputWithLabel
                    name="metaTitle"
                    control={control}
                    id="metaTitle"
                    label="Meta Title"
                    error={errors.metaTitle}
                    placeholder="Enter meta title"
                    maxLength={255}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="form-section__field">
                  <TextAreaWithLabel
                    name="metaDescription"
                    control={control}
                    id="metaDescription"
                    label="Meta Description"
                    error={errors.metaDescription}
                    placeholder="Add meta description"
                    rows={3}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="form-section__field">
                  <InputWithLabel
                    name="url"
                    control={control}
                    id="url"
                    label="Article Url"
                    error={errors.url}
                    placeholder="Enter Url"
                    maxLength={255}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="form-section__field">
                  <TextAreaWithLabel
                    name="shortDescription"
                    control={control}
                    id="shortDescription"
                    label="Short Description"
                    error={errors.shortDescription}
                    placeholder="Add description"
                    rows={3}
                    maxLength={200}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="form-section__field">
                  <Controller
                    name="body"
                    as={<RichTextEditor
                      value={article.body}
                    />}
                    defaultValue={article.body}
                    control={control}
                    onChange={([value]) => value}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Controller
                    name="isTopArticle"
                    as={(
                      <Checkbox
                        checked={!!article.isTopArticle}
                      >
                        Display in News Updates section
                      </Checkbox>)
                    }
                    control={control}
                    defaultValue={!!article.isTopArticle}
                    onChange={([event]) => event.target.checked}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Controller
                    name="isLatestArticle"
                    as={(
                      <Checkbox
                        checked={!!article.isLatestArticle}
                      >
                        Display in Latest News section
                      </Checkbox>)
                    }
                    control={control}
                    defaultValue={!!article.isLatestArticle}
                    onChange={([event]) => event.target.checked}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="admin-article__submit">
                  <Button
                    htmlType="submit"
                    disabled={isProcessing}
                    className="button button--default"
                    ref={buttonRef}
                  >
                    Save
                  </Button>
                  <Button
                    htmlType="button"
                    className="button button--default"
                    onClick={() => {
                      action = "preview";
                      buttonRef.current.click();
                    }}
                  >
                    Preview
                  </Button>
                  <Button
                    htmlType="button"
                    disabled={isProcessing}
                    className="button button--primary"
                    onClick={() => {
                      action = "publish";
                      buttonRef.current.click();
                    }}
                  >
                    Publish
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
      <Modal
        className="admin-article__preview"
        visible={isPreviewOpen}
        onCancel={() => setIsPreviewOpen(false)}
        footer={null}
      >
        <BlogSingleArticle article={previewArticle}/>
      </Modal>
    </Fragment>
  );
};

Article.propTypes = {
  error: PropTypes.string,
  isProcessing: PropTypes.bool,
  requestSuccess: PropTypes.bool,
  getArticle: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  createArticle: PropTypes.func.isRequired,
  updateArticle: PropTypes.func.isRequired,
  publishArticle: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  clearArticle: PropTypes.func.isRequired,
  article: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired
};

export default connect(
  state => ({
    article: state.admin.article.singleArticle,
    options: state.admin.category.data,
    error: state.admin.article.error,
    isProcessing: state.admin.article.processing,
    requestSuccess: state.admin.article.requestSuccess
  }),
  {
    getArticle: getArticleRequest,
    getCategories: getCategoryListRequest,
    createArticle: createArticleRequest,
    updateArticle: updateArticleRequest,
    publishArticle: saveAndPublishArticleRequest,
    clearError: clearArticleErrorAction,
    clearArticle: clearArticleAction
  }
)(Article);
