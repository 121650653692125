import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getMyPurchasesListRequest } from "../../redux/listing/listingActions";
import MyPurchasesCard from "./MyPurchasesCard";
import HotelCardsLists from "../shared/HotelCardsLists";

const navOptions = [
  {
    key: "upcoming",
    url: "/profile/purchases/upcoming",
    label: "Upcoming",
    queryValue: "upcoming",
  },
  {
    key: "past",
    url: "/profile/purchases/past",
    label: "Past",
    queryValue: "past"
  }
];

const MyPurchases = ({
  dataSource,
  isProcessing,
  pagination,
  getList
}) => {

  return (
    <HotelCardsLists
      navOptions={navOptions}
      dataSource={dataSource}
      isProcessing={isProcessing}
      pagination={pagination}
      getList={getList}
      hotelCard={MyPurchasesCard}
      queryKey="status"
      title="My Purchases"
    />
  );
};

MyPurchases.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired
};

export default connect(
  state => ({
    dataSource: state.listing.myPurchases.data,
    isProcessing: state.listing.processing,
    pagination: {
      current: state.listing.myPurchases.curPage,
      total: state.listing.myPurchases.total,
      pageSize: state.listing.myPurchases.perPage
    },
  }),
  {
    getList: getMyPurchasesListRequest,
  }
)(MyPurchases);
