import * as types from "./listingsActionsTypes";

export const getListingsRequest = (data) => {
  return { type: types.ADMIN_GET_LISTINGS_REQUEST, data };
};

export const getCsvExportUrlRequest = (data) => {
  return { type: types.ADMIN_GET_CSV_EXPORT_URL_REQUEST, data };
};

export const updateListingRequest = (data) => {
  return { type: types.ADMIN_UPDATE_LISTING_REQUEST, data };
};

export const clearListingErrorAction = () => {
  return { type: types.ADMIN_CLEAR_LISTING_ERROR };
};
