import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { getStaticPageRequest, updateStaticPageRequest } from "../../../redux/admin/staticPages/staticPagesActions";
import RichTextEditor from "../../shared/RichTextEditor/RichTextEditor";
import InputWithLabel from "../../shared/InputWithLabel";
import testHtmlLength from "./helpers/testHtmlLength";

const ContactUsSchema = yup.object().shape({
  contactUsContent: yup.string().trim()
    .test({
      name: "contactUsContentLength",
      message: "Content can not be empty",
      test: testHtmlLength
    }),
  contactDetailsTitle: yup.string().trim().required("Title can not be empty"),
  socialTitle: yup.string().trim().required("Title can not be empty"),
});

const ContactUs = ({ isProcessing, updatePage, page, getPage, location }) => {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: ContactUsSchema,
  });

  useEffect(() => {
    getPage({ permalink: active });
  }, []);

  useEffect(() => {
    reset(page);
  }, [page]);

  const submitHandler = (formData) => {
    updatePage({
      ...formData,
      permalink: active
    });
  };

  return (
    <Row className="admin-contact-us">
      <Col span={24}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Row className="admin-section">
            <Col span={24}>
              <Row>
                <Col span={24} className="admin-contact-us__title">
                  Edit meta
                </Col>
              </Row>
              <Row gutter={16} className="admin-contact-us__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaTitle"
                    control={control}
                    id="metaTitle"
                    label="Title"
                    error={errors.metaTitle}
                    placeholder="Title"
                    defaultValue={page.metaTitle}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-contact-us__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaDescription"
                    control={control}
                    id="metaDescription"
                    label="Description"
                    error={errors.metaDescription}
                    placeholder="Description"
                    defaultValue={page.metaDescription}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-contact-us__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaKeywords"
                    control={control}
                    id="metaKeywords"
                    label="Keywords"
                    error={errors.metaKeywords}
                    placeholder="Keywords"
                    defaultValue={page.metaKeywords}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className="admin-contact-us__title">
                  Edit content
                </Col>
              </Row>
              <Row className="admin-contact-us__row">
                <Col span={24}>
                  <label className="admin-contact-us__label">"Contact us" content</label>
                  <Controller
                    name="contactUsContent"
                    as={<RichTextEditor
                      value={page.contactUsContent}
                    />}
                    defaultValue={page.contactUsContent}
                    control={control}
                    onChange={([value]) => value }
                  />
                  { errors.contactUsContent &&
                    <span className="admin-contact-us__error">{errors.contactUsContent.message}</span>
                  }
                </Col>
              </Row>
              <Row className="admin-contact-us__row">
                <Col span={24}>
                  <InputWithLabel
                    name="contactDetailsTitle"
                    control={control}
                    id="contactDetailsTitle"
                    label="Contact details title"
                    error={errors.contactDetailsTitle}
                    placeholder="Title"
                    defaultValue={page.contactDetailsTitle}
                  />
                </Col>
              </Row>
              <Row className="admin-contact-us__row">
                <Col span={24}>
                  <InputWithLabel
                    name="socialTitle"
                    control={control}
                    id="socialTitle"
                    label="Social title"
                    error={errors.socialTitle}
                    placeholder="Title"
                    defaultValue={page.socialTitle}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="admin-contact-us__submit">
              <Button htmlType="submit" disabled={isProcessing} className="button button--primary">Save</Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  )
};

ContactUs.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  getPage: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.staticPage.processing,
    error: state.admin.staticPage.error,
    page: state.admin.staticPage.page
  }),
  {
    getPage: getStaticPageRequest,
    updatePage: updateStaticPageRequest
  }
)(ContactUs);
