import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Col, Rate } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import { createHotelRoomsTextsArray, getCurrencyBadge } from "../../helpers/common";
import { HOTEL_BRAND_LOGOS } from "../constants";

export const ResaleListingItem = ({ listing, history }) => {
  const { Hotel } = listing;

  if (!Hotel) {
    return null;
  }
  const imageUrl = Hotel.HotelImages[0] ? Hotel.HotelImages[0].imageUrl : "";
  const brandLogo = HOTEL_BRAND_LOGOS.find(brand => brand.codes.includes(Hotel.brandCode));
  const isRefundable = listing?.additionalDetails?.isRefundable;
  const hotelAddress = listing.Hotel && listing.Hotel.HotelAddress ? listing.Hotel.HotelAddress : {};

  return (
    <Link
      to={`/listings/${listing.slug}${!listing.sellerId ? `${history.location.search}` : ""}`}
      target="_blank"
      onClick={(e) => {
        if (!listing.id) {
          e.preventDefault();
        }
      }}
    >
      <Row className={`listing-item resale-listing-item ${listing.searching ? "searching" : ""}`}>
        {
          listing.type === "organic" && (
            <div className="resale-deals-badge">Resale Deal</div>
          )
        }
        <Col
          xs={{ span: 24 }}
          xl={{ span: 10 }}
          className={`listing-item__image${!imageUrl ? " listing-item__image--bordered" : ""}`}
        >
          { imageUrl
            ? <div
              className="listing-item__hotel-image"
              style={{ backgroundImage: `url('${imageUrl}')` }}
            />
            : <div className="listing-item__hotel-image">
              <img src="/images/no-image.svg" alt={Hotel.name} className="listing-item__empty-hotel-image"/>
            </div>
          }
          <div className="resale-listing-item__detail">
            <div className="resale-listing-item__city-name">{hotelAddress.cityName}</div>
            <div className="resale-listing-item__details-container">
              <div className="resale-listing-item__dates">
                {moment(listing.checkInDate).format("MMM DD")} - {moment(listing.checkOutDate).format("MMM DD")}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 14 }} className="listing-item__info">
          <div className="listing-item__hotel-name">{Hotel.name || ""}</div>
          { (!!listing.hotelRating || brandLogo) &&
            <div className={`listing-item__hotel-rating-container ${!(listing.hotelRating || listing.Hotel.rating) && "listing-item__hotel-rating-container__justify-end"}`} >
              {!!listing.hotelRating &&
                <div className="listing-item__hotel-rating">
                  <Rate disabled count={listing.hotelRating} defaultValue={listing.hotelRating}/>
                </div>
              }
              {!listing.hotelRating && !!listing.Hotel.rating &&
                <div className="listing-item__hotel-rating">
                  <Rate disabled count={listing.Hotel.rating} defaultValue={listing.Hotel.rating}/>
                </div>
              }
              {brandLogo &&
                <img src={brandLogo.logo} alt="hotel chain"/>
              }
            </div>
          }
          {listing.id ?
            <React.Fragment>
              <div className="listing-item__row">
                <div className="listing-item__title">Guests</div>
                <div className="listing-item__characteristic">
                  {
                    [
                      ...createHotelRoomsTextsArray(listing.rooms, listing.adults, listing.children),
                      ...(listing.facilities || []),
                      (listing.facilitiesOther ? listing.facilitiesOther : null),
                      (listing.type === "organic" ? listing.roomType !== "other" ? listing.roomType : null : null),
                      (listing.roomTypeOther ? listing.roomTypeOther : null)
                    ].filter(item => !!item).join(" • ")
                  }
                </div>
              </div>
              <div className="listing-item__row">
                <div className="listing-item__title">Address</div>
                {Hotel?.distanceFromSelectedLocation &&
                  <span className="listing-item__characteristic listing-item__characteristic__pink">
                    {Hotel.distanceFromSelectedLocation.value} {Hotel.distanceFromSelectedLocation.units.toLowerCase()}
                  </span>}
                <div className="listing-item__characteristic">
                  {Hotel.HotelAddress.countryName },&nbsp;
                  {Hotel.HotelAddress.cityName},&nbsp;
                  {Hotel.HotelAddress.addressLine},&nbsp;
                  {Hotel.HotelAddress.postalCode}
                </div>
              </div>

              {!!listing.originalPrice &&
                <div className="listing-item__row">
                  <div className="listing-item__title">Original Price</div>
                  <div className="listing-item__original-price-container">
                    <div className="listing-item__original-price">
                      <img src="/images/money-bill-alt.svg" alt="price" className="listing-item__original-price-icon"/>
                      <div>{`${getCurrencyBadge(listing.Seller ? listing.Seller.currency : listing.currency)}${(+listing.originalPrice).toFixed(2)}`}</div>
                    </div>
                    {!!listing.discount &&
                      <div className="listing-item__discount">
                        {`-${listing.discount}% off`}
                      </div>
                    }
                  </div>
                </div>
              }
              <div className="listing-item__row listing-item__flex_row">
                <div className="listing-item__flex_row_item">
                  <div className="listing-item__price">
                    {!!listing.originalPrice && "Now "}
                    {getCurrencyBadge(listing.Seller ? listing.Seller.currency : listing.currency)}
                    {(+listing.sellingPrice).toFixed(2)}
                  </div>
                  {listing.type === "organic" && !!listing.reasonOfSale &&
                    <div className="listing-item__reason-for-sale">
                      <div>Reason for Resale: {listing.reasonOfSale}</div>
                    </div>
                  }
                  {isRefundable &&
                    <div className="listing-item__free-cancellation">
                      <img src="/images/icons/check-pink.svg" alt="check"/>
                      <div>Free Cancellation</div>
                    </div>
                  }
                </div>
                <div className="listing-item__book">
                  View Deal &gt;
                </div>
              </div>
            </React.Fragment>
            : listing.searching && listing.Hotel && listing.Hotel.HotelAddress ?
              <div className="searching-row">
                <div className="listing-item__row placeholder-info">
                  <div className="listing-item__title">Guests</div>
                  { listing.searchQuery ?
                    <div className="listing-item__characteristic">{`${listing.searchQuery.rooms} room • ${listing.searchQuery.adults} 
                    adults${listing.searchQuery.children !== "0" ? ` • ${listing.searchQuery.children } children` : "" } ...`}</div>
                    : <div className="listing-item__characteristic">1 room • 2 adults • ...</div>
                  }
                </div>
                <div className="listing-item__row placeholder-info">
                  <div className="listing-item__title">Address</div>
                  <span className="listing-item__characteristic listing-item__characteristic__pink">0 km</span>
                  <div className="listing-item__characteristic">
                    {listing.Hotel.HotelAddress.countryName },&nbsp;
                    {listing.Hotel.HotelAddress.cityName},&nbsp;
                    {listing.Hotel.HotelAddress.addressLine},&nbsp;
                    {listing.Hotel.HotelAddress.postalCode}
                  </div>
                </div>
                <span className="search-label">Searching</span>
              </div>
              : !listing.searching &&
                <div className="no-availability-row">
                  <p>There is no availability at this hotel for your search criteria.</p>
                  <p>See below for other hotels nearby or try changing your criteria.</p>
                </div>
          }
        </Col>
      </Row>
    </Link>
  );
};

ResaleListingItem.propTypes = {
  listing: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(ResaleListingItem);
