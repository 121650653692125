import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Rate, Typography, Button } from "antd";
import { ImageBgCover } from "./SimpleComponents/ImageBgCover/ImageBgCover";
import parse from "html-react-parser";
import { withRouter } from "react-router";
import * as queryString from "query-string";
import ListingDetails from "../Listing/ListingDetails";

const ListingViewLoading = ({
  location
}) => {
  const defaultLoadingListing = {
    id: "loading",
    hotelRating: 5,
    rooms: 1,
    adults: 2,
    children: 0,
    checkInDate: new Date(),
    checkOutDate: new Date(),
    currency: false,
    originalPrice: 0,
    reasonOfSale: false,
    Hotel: {
      name: "Searching",
      source: "loading",
      description: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac ex et tellus rhoncus vestibulum. 
        Etiam dapibus, leo aliquet pharetra dapibus, enim diam eleifend ante, condimentum pulvinar quam ipsum at quam. 
        Ut finibus, est nec vehicula eleifend, lacus orci sollicitudin turpis, a malesuada ante sem eu turpis. 
        Nunc tincidunt sit amet turpis vel dignissim. Praesent lacinia suscipit diam sed vestibulum. 
        Quisque ac tellus convallis, facilisis sapien eget, suscipit quam. Nunc accumsan justo quis sapien dictum,
        id consequat felis finibus. Pellentesque vel eros placerat nisi finibus suscipit. Cras a feugiat est.</p>

        <p>Cras condimentum cursus leo ac malesuada. Fusce congue augue eget justo laoreet imperdiet. Cras rutrum, 
        ipsum bibendum suscipit condimentum, dui justo sagittis eros, a ultrices enim dui eu nisi. Fusce a justo varius, 
        lobortis orci nec, pellentesque augue. Sed nec aliquam justo. Phasellus euismod malesuada hendrerit. 
        Donec sit amet dui sagittis, auctor dui sit amet, vulputate neque. Nulla fermentum porttitor nunc, mattis elementum mauris. 
        Pellentesque eget augue mi. Donec maximus enim tortor. Morbi ac sagittis lorem. Proin vitae massa in diam pellentesque consequat. 
        Proin ac nisl vitae ligula finibus euismod et ac leo. Sed feugiat arcu sapien, sed luctus sapien bibendum at. 
        Phasellus sit amet purus pharetra, imperdiet lorem quis, consectetur lorem. Quisque tortor nibh, venenatis et massa vel, 
        scelerisque hendrerit elit.</p>`,
      HotelImages: [],
      topFacilities: [],
      HotelAddress: {
        countryName: "Country",
        cityName: "City",
        addressLine: "Searching",
        postalCode: ""
      }
    }
  };

  const [listing, setListing] = useState(defaultLoadingListing);
  const [detailClass, setDetailClass] = useState("details-transparent");


  useEffect(() => {
    const search = location ? location.search : false
    if (search) {
      const {
        label, checkInDate, checkOutDate
      } = queryString.parse(search, { arrayFormat: "bracket" })

      let hotelName = "Searching";
      let cityName = "City";
      let countryName = "Country";
      if (label && label.indexOf(",")) {
        const labelDecoded = decodeURI(label)
        setDetailClass("details-opaque")
        hotelName = labelDecoded.substring(0, labelDecoded.indexOf(","));
        const rest = labelDecoded.substring(labelDecoded.indexOf(",") + 1);
        if (rest.indexOf(",")) {
          cityName = rest.substring(0, rest.indexOf(","));
          countryName = rest.substring(rest.indexOf(",") + 1);
        } else {
          cityName = rest;
          countryName = "";
        }
      }

      setListing({
        ...defaultLoadingListing,
        checkInDate,
        checkOutDate,
        Hotel: {
          ...defaultLoadingListing.Hotel,
          name: hotelName,
          HotelAddress: {
            ...defaultLoadingListing.Hotel.HotelAddress,
            countryName,
            cityName
          }
        }
      })

    }
  }, []);

  const renderButton = () => {
    return (
      <Col span={24}>
        <Button
          type="primary"
          className="listing-details__action-button"
          disabled={true}
        >
            Book for
        </Button>
      </Col>
    )
  }

  return (
    <Row justify="space-around" align="middle" className="listing-view listing-view-loading">
      <Col xs={{ span: 24 }} md={{ span: 22 }} xl={{ span: 20 }}>
        <Row className="listing-view__hotel-name-container">
          <Col span={24}>
            <Row className="listing-view__hotel-name">
              <Col span={24}>
                <Typography.Title className={detailClass}>{listing.Hotel.name}</Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 13 }}>
                <Row gutter={32} justify="space-between" align="middle">
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: listing.hotelRating ? 12 : 24 }}
                    lg={{ span: listing.hotelRating ? 14 : 24 }}
                    className={detailClass}
                  >
                    {listing.Hotel.HotelAddress.countryName}, {listing.Hotel.HotelAddress.cityName}
                  </Col>
                  {
                    !!listing.hotelRating &&
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 10 }}
                      className="listing-view__rating"
                    >
                      <Rate
                        key={`listing-rate-${listing.id}`}
                        disabled
                        count={listing.hotelRating}
                        defaultValue={listing.hotelRating}
                      />
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={{ xs: 0, md: 32, xl: 80 }} align="start">
          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 13 }}>
            <ImageBgCover url={""} height={56} iconSize="big" className="listing-view__hotel-images-empty" />
            <Row className="listing-view__hotel-description">
              <Col span={24}>
                <Row>
                  <Col span={24} className="listing-view__hotel-description-title">
                    Description
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {parse(listing.Hotel.description)}
                  </Col>
                </Row>
              </Col>
            </Row>
            {
              listing.Hotel.topFacilities && listing.Hotel.topFacilities.length
                ? <Row className="mobile-padding">
                  <div className="form-container listing-view__facilities">
                    <div className="listing-view__facilities-container">
                      <div className="listing-view__facilities-label-container">
                        <div className="listing-view__facilities-label">Amenities</div>
                        <div
                          className="listing-view__facilities-see-all"
                        >
                          See all <img src="/images/arrow-circle-icon.svg" alt="See all"/>
                        </div>
                      </div>
                      <div className="listing__field-facilities">
                        {
                          listing.Hotel.topFacilities.map((item, index) => (
                            <div className="listing__field-facilities-item" key={`hotel-facilities-${item}-${index}`}>
                              <img
                                className="listing-view__facilities-icon"
                                src={`/images/icons/${item.toLowerCase().replace(/\W/g, "-")}.svg`}
                                alt={item}
                              />
                              <span>{item}</span>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </Row>
                : null
            }
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 11 }}>
            <div
              className="listing-view__details-container"
            >
              { listing && <ListingDetails listing={listing} renderButton={renderButton}/> }
              { !isNaN(listing.id) &&
                <Row>
                  <Col span={24}>
                    <div className="listing-view__ref-number">Ref. no. {listing.id}</div>
                  </Col>
                </Row>
              }
            </div>
          </Col>
        </Row>
        <Row className="listing-view__hotel-description mobile-view">
          <Col span={24}>
            <Row>
              <Col span={24} className="listing-view__hotel-description-title">
                Description
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {parse(listing.Hotel.description)}
              </Col>
            </Row>
          </Col>
        </Row>
        { listing.Hotel.topFacilities && !!listing.Hotel.topFacilities.length &&
          <Row>
            <div className="form-container listing-view__facilities mobile-view">
              <div className="listing-view__facilities-container">
                <div className="listing-view__facilities-label-container">
                  <div className="listing-view__facilities-label">Amenities</div>
                  <div
                    className="listing-view__facilities-see-all"
                  >
                    See all <img src="/images/arrow-circle-icon.svg" alt="See all"/>
                  </div>
                </div>
                <div className="listing__field-facilities">
                  {
                    listing.Hotel.topFacilities.map((item, index) => (
                      <div className="listing__field-facilities-item" key={`hotel-facilities-${item}-${index}`}>
                        <img
                          className="listing-view__facilities-icon"
                          src={`/images/icons/${item.toLowerCase().replace(/\W/g, "-")}.svg`}
                          alt={item}
                        />
                        <span>{item}</span>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </Row>
        }

      </Col>
    </Row>
  );
};

ListingViewLoading.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(ListingViewLoading);
