import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { getStaticPageRequest, updateStaticPageRequest } from "../../../redux/admin/staticPages/staticPagesActions";
import InputWithLabel from "../../shared/InputWithLabel";

const FAQSchema = yup.object().shape({});

const FAQ = ({ isProcessing, updatePage, page, getPage, location }) => {
  const path = location.pathname.split("/");
  const active = path[path.length - 1];

  const { handleSubmit, control, errors, reset } = useForm({
    validationSchema: FAQSchema,
  });

  useEffect(() => {
    getPage({ permalink: active });
  }, []);

  useEffect(() => {
    reset(page);
  }, [page]);

  const submitHandler = (formData) => {
    updatePage({
      ...formData,
      permalink: active
    });
  };

  return (
    <Row className="admin-faq">
      <Col span={24}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Row className="admin-section">
            <Col span={24}>
              <Row>
                <Col span={24} className="admin-faq__title">
                  Edit meta
                </Col>
              </Row>
              <Row gutter={16} className="admin-faq__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaTitle"
                    control={control}
                    id="metaTitle"
                    label="Title"
                    error={errors.metaTitle}
                    placeholder="Title"
                    defaultValue={page.metaTitle}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-faq__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaDescription"
                    control={control}
                    id="metaDescription"
                    label="Description"
                    error={errors.metaDescription}
                    placeholder="Description"
                    defaultValue={page.metaDescription}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="admin-faq__row">
                <Col span={12}>
                  <InputWithLabel
                    name="metaKeywords"
                    control={control}
                    id="metaKeywords"
                    label="Keywords"
                    error={errors.metaKeywords}
                    placeholder="Keywords"
                    defaultValue={page.metaKeywords}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="admin-faq__submit">
              <Button htmlType="submit" disabled={isProcessing} className="button button--primary">Save</Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  )
};

FAQ.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  getPage: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.staticPage.processing,
    error: state.admin.staticPage.error,
    page: state.admin.staticPage.page
  }),
  {
    getPage: getStaticPageRequest,
    updatePage: updateStaticPageRequest
  }
)(FAQ);
