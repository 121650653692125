import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Table, InputNumber } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { updateFeeConfigurationRequest } from "../../../redux/admin/finance/financeActions";

const FeeConfiguration = ({
  feeConfigurations,
  isProcessing,
  updateFeeConfiguration
}) => {
  const { control } = useForm({
    mode: "onChange",
  });

  const renderFeeInput = (text, record) => (
    <div>
      <Controller
        name={`${record.key}`}
        as={<InputNumber
          id={record.key}
          className="profile__input"
          min={0}
          max={100}
          precision={1}
          decimalSeparator="."
          placeholder="0.0"
        />}
        control={control}
        defaultValue={text}
        onChange={([event]) => {
          return event
        }}
        onBlur={([event]) => {
          updateFeeConfiguration({ id: event.target.id, body: { fee: parseFloat(event.target.value) } });
        }}
      />
    </div>
  );

  return (
    <form className="profile__form">
      <Table
        loading={isProcessing}
        pagination={false}
        dataSource={feeConfigurations.map(item => {
          return {
            key: item.id,
            title: item.title,
            code: item.code,
            fee: item.fee
          }
        })}
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: 200
          },
          {
            title: "Code",
            dataIndex: "code",
            key: "code",
            width: 100
          },
          {
            title: "Fee, %",
            dataIndex: "fee",
            key: "fee",
            render: renderFeeInput,
            width: 100
          }
        ]}
      />
    </form>
  )
};

FeeConfiguration.propTypes = {
  feeConfigurations: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  updateFeeConfiguration: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    feeConfigurations: state.admin.finance.feeConfigurations,
    error: state.admin.finance.error,
    isProcessing: state.admin.finance.processing
  }),
  {
    updateFeeConfiguration: updateFeeConfigurationRequest,
  }
)(FeeConfiguration);
