import { all, put, call, takeLatest } from "redux-saga/effects";

import auth from "../../api/auth";
import * as types from "./authActionsTypes";
import serverSagaWrapper from "../../helpers/serverSagaWrapper"


function *signIn ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(auth.login, data);
    yield put({ type: types.SIGN_IN_REQUEST_SUCCESS, response: response.user });
  } catch (error) {
    yield put({ type: types.SIGN_IN_REQUEST_ERROR, error: error });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *signUp ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(auth.register, data);
    yield put({ type: types.SIGN_UP_REQUEST_SUCCESS, response });
    yield put({ type: types.SIGN_IN_REQUEST_SUCCESS, response: {
      id: response.user.id,
      first_name: response.user.first_name,
      last_name: response.user.last_name,
      email: response.user.email
    } });
  } catch (error) {
    yield put({ type: types.SIGN_UP_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *signOut () {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(auth.logout);
    yield put({ type: types.SIGN_OUT_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.SIGN_OUT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *resendActivationEmail ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(auth.resendActivationEmail, data);
    yield put({ type: types.RESEND_ACTIVATION_EMAIL_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.RESEND_ACTIVATION_EMAIL_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *verifyAccount ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(auth.verifyAccount, data);
    yield put({ type: types.VERIFY_ACCOUNT_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.VERIFY_ACCOUNT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *resetPassword ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(auth.resetPassword, data);
    yield put({ type: types.RESET_PASSWORD_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *setNewPassword ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(auth.setNewPassword, data);
    yield put({ type: types.SET_NEW_PASSWORD_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.SET_NEW_PASSWORD_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.SIGN_IN_REQUEST, signIn),
    yield takeLatest(types.SIGN_UP_REQUEST, signUp),
    yield takeLatest(types.SIGN_OUT_REQUEST, signOut),
    yield takeLatest(types.RESEND_ACTIVATION_EMAIL_REQUEST, resendActivationEmail),
    yield takeLatest(types.VERIFY_ACCOUNT_REQUEST, serverSagaWrapper(verifyAccount)),
    yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword),
    yield takeLatest(types.SET_NEW_PASSWORD_REQUEST, setNewPassword)
  ])
}
