import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Radio } from "antd";
import moment from "moment";
import keyBy from "lodash/keyBy";

import { getDashboardSalesListingsRequest } from "../../../redux/admin/dashboard/dashboardActions";
import SectionHeading from "./SectionHeading";
import { regions } from "../../../api/constants";

const SalesListings = ({ salesListings, country, getSalesListings }) => {
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD")
  });
  const [type, setType] = useState("organic");

  useEffect(() => {
    getSalesListings({ ...searchQuery, country });
  }, [searchQuery]);

  useEffect(() => {
    getSalesListings({ ...searchQuery, country });
  }, [country]);

  const states = keyBy(regions.US, "code");

  const renderRow = (item, key) => {
    return (
      <Row key={key} align="middle" justify="space-between">
        <Col className="admin-geo-data__state-name">
          {states[item.state] ? states[item.state].name : item.state}
        </Col>
        <Col
          className={`admin-geo-data__state-value ${item.percents >= 60 ? "admin-geo-data__state-value--green" : item.percents <= 10 ? "admin-geo-data__state-value--red" : "admin-geo-data__state-value--blue"}`}
        >
          {item.percents}%
        </Col>
      </Row>
    );
  };

  return (
    <Row className="admin-geo-data">
      <Col span={24}>
        <SectionHeading
          setSearchQuery={setSearchQuery}
          title="Sales listings"
          searchQuery={searchQuery}
          sectionKey="sales-listings"
        />
        <Row className="admin-geo-data__content">
          <Col span={24}>
            <Row>
              <Col className="admin-geo-data__types-select" >
                <Radio.Group value={type} onChange={e => setType(e.target.value)}>
                  <Radio.Button value="organic">Organic</Radio.Button>
                  <Radio.Button value="sales">Sales</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                { type === "organic" &&
                  <React.Fragment>
                    { salesListings.organic.length ? salesListings.organic.map((item, key) => {
                      return renderRow(item, key);
                    }) : <div className="admin-geo-data__empty">No data found</div> }
                  </React.Fragment>
                }
                { type === "sales" &&
                  <React.Fragment>
                    { salesListings.sales.length ? salesListings.sales.map((item, key) => {
                      return renderRow(item, key);
                    }) : <div className="admin-geo-data__empty">No data found</div> }
                  </React.Fragment>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SalesListings.propTypes = {
  salesListings: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  getSalesListings: PropTypes.func.isRequired
};

export default connect(
  state => ({
    salesListings: state.admin.dashboard.salesListings,
    country: state.admin.dashboard.country
  }),
  {
    getSalesListings: getDashboardSalesListingsRequest
  }
)(SalesListings);
