import axios from "axios";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

import { API_URL } from "./constants";

const cookies = new Cookies();

const auth = {
  async login(data) {
    try {
      const result = await axios.post(`${API_URL}/auth/login`, data);
      const authToken = result.data.token.accessToken;
      const expiresAt = new Date(result.data.token.expiresAt);
      const user = jwt_decode(authToken);

      cookies.set("authToken", authToken, {
        expires: expiresAt,
        path: "/"
      });

      cookies.set("authUser", user, {
        expires: expiresAt,
        path: "/"
      });

      cookies.set("expiresAt", expiresAt, {
        expires: expiresAt,
        path: "/"
      });

      cookies.set("loggedIn", Math.round(Date.now() / 1000), {
        expires: expiresAt,
        path: "/"
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async register(data) {
    try {
      const result = await axios.post(`${API_URL}/auth/register`, data);
      const authToken = result.data.token.accessToken;
      const expiresAt = new Date(result.data.token.expiresAt);
      const user = jwt_decode(authToken);

      cookies.set("authToken", authToken, {
        expires: expiresAt,
        path: "/"
      });

      cookies.set("authUser", user, {
        expires: expiresAt,
        path: "/"
      });

      cookies.set("expiresAt", expiresAt, {
        expires: expiresAt,
        path: "/"
      });

      cookies.set("loggedIn", Math.round(Date.now() / 1000), {
        expires: expiresAt,
        path: "/"
      });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async resendActivationEmail(data) {
    try {
      return await axios.post(`${API_URL}/auth/resend-activate-email`, data);
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async verifyAccount(data) {
    try {
      return await axios.post(`${API_URL}/auth/verify-email/${data.token}`);
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async resetPassword(data) {
    try {
      return await axios.post(`${API_URL}/auth/forgot-password`, data);
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async setNewPassword(data) {
    try {
      return await axios.post(`${API_URL}/auth/forgot-password/${data.token}`, data);
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async logout() {
    try {
      cookies.remove("authToken", { path: "/" });
      cookies.remove("authUser", { path: "/" });
      cookies.remove("expiresAt", { path: "/" });
      cookies.remove("loggedIn", { path: "/" });

      return true;
    }
    catch (err) {
      console.error(err, "err login")
    }
  },
};

export default auth;
