import axios from "../../helpers/axios";
import * as queryString from "query-string";

import { API_URL } from "../constants";

const question = {
  async createQuestion(data) {
    try {
      const result = await axios.post(`${API_URL}/admin/faq`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateQuestion(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/faq/${data.id}`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getQuestion(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/faq/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getQuestions(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/faq?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteQuestion(data) {
    try {
      const result = await axios.delete(`${API_URL}/admin/faq/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async createCategory(data) {
    try {
      const result = await axios.post(`${API_URL}/admin/faq/categories`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getCategories(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/faq/categories?${queryString.stringify(data, {
        skipEmptyString: true,
        skipNull: true
      })}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async deleteCategory(data) {
    try {
      const result = await axios.delete(`${API_URL}/admin/faq/categories/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async getCategory(data) {
    try {
      const result = await axios.get(`${API_URL}/admin/faq/categories/${data.id}`);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateQuestionsOrder(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/faq/update-order`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async resetQuestionsOrder(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/faq/reset-order`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
  async updateCategoriesOrder(data) {
    try {
      const result = await axios.patch(`${API_URL}/admin/faq/categories/update-order`, data);

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default question;
