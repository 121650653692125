import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, Input, Row } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { clearSetNewPasswordStatusAction, setNewPasswordRequest } from "../../redux/auth/authActions";
import PasswordUpdated from "./PasswordUpdated";
import Label from "../shared/Form/Label";

const SetNewPasswordSchema = yup.object().shape({
  password: yup.string().required("Password can not be empty").matches(
    /^(?=.*\d)(?=.*[A-Z]).{8,255}$/,
    "Password should be at least 8 characters long and include at least one number and one capital letter"
  ),
  passwordConfirm: yup.string().oneOf([yup.ref("password"), null], "Passwords don't match")
});

export const SetNewPassword = ({
  match,
  isProcessing,
  setNewPassword,
  clearSetNewPasswordStatus,
  isNewPasswordSet
}) => {
  const { token } = match.params;

  const { handleSubmit, errors, control } = useForm({
    validationSchema: SetNewPasswordSchema
  });

  useEffect(() => {
    clearSetNewPasswordStatus();
  }, []);

  const setNewPasswordHandler = data => {
    setNewPassword({
      ...data,
      token
    });
  };

  if (isNewPasswordSet) {
    return <PasswordUpdated/>;
  }

  return (
    <Row justify="space-around" align="middle" className="auth-row">
      <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 8 }}>
        <div className="set-new-password">
          <h1 className="set-new-password__title">Set New Password</h1>

          <div className="set-new-password__subtitle">
            Password should be at least 8 characters long and include at least one number and one capital letter
          </div>

          <form onSubmit={handleSubmit(setNewPasswordHandler)} className="set-new-password__form form-container">
            <div className="set-new-password__field">
              <Label htmlFor="set-new-password-password">Password</Label>
              <Controller
                name="password"
                as={<Input.Password
                  visibilityToggle={true}
                  id="set-new-password-password"
                  className={`form-input ${errors.password ? "form-input--error" : ""}`}
                  data-testid="set-new-password-password"
                />}
                control={control}
              />
              {errors.password && <span
                className="set-new-password__error"
                data-testid="set-new-password-error"
              >{errors.password.message}</span>}
            </div>

            <div className="set-new-password__field">
              <Label htmlFor="set-new-password-password-confirm">Confirm Password</Label>
              <Controller
                name="passwordConfirm"
                as={<Input.Password
                  visibilityToggle={true}
                  id="set-new-password-password-confirm"
                  className={`form-input ${errors.passwordConfirm ? "form-input--error" : ""}`}
                  data-testid="set-new-password-password-confirm"
                />}
                control={control}
              />
              {errors.passwordConfirm && <span
                className="set-new-password__error"
                data-testid="set-new-password-error"
              >{errors.passwordConfirm.message}</span>}
            </div>

            <Button
              className="submit-button"
              htmlType="submit"
              disabled={isProcessing}
              type="primary"
              data-testid="set-new-password-submit"
              block
            >
              Set New Password
            </Button>
          </form>
        </div>
      </Col>
    </Row>
  );
};

SetNewPassword.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  clearSetNewPasswordStatus: PropTypes.func.isRequired,
  isNewPasswordSet: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired
};

export default connect(
  state => ({
    isProcessing: state.auth.processing,
    error: state.auth.error,
    isNewPasswordSet: state.auth.isNewPasswordSet
  }),
  {
    setNewPassword: setNewPasswordRequest,
    clearSetNewPasswordStatus: clearSetNewPasswordStatusAction
  }
)(SetNewPassword);
