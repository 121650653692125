import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row, Select, InputNumber, Empty } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import { withRouter } from "react-router";
import * as queryString from "query-string";
import { connect } from "react-redux";

import { createHotelRoomsText } from "../../helpers/common";
import DatePicker from "../shared/DatePicker";
import { setListingsListAction, setLastSearchParams } from "../../redux/listing/listingActions";
import { getPlacesRequest } from "../../redux/places/placesActions";

const INITIAL_SEARCH_QUERY = {
  checkInDate: null,
  checkOutDate: null,
  rooms: 1,
  adults: 2,
  children: 0,
  childrenAge: []
};

const ListingSearch = ({
  initialValue = {},
  isProcessing,
  places,
  showFilters,
  showFiltersLabel,
  showMobileVersion,
  history,
  onChangeCity,
  onSearch,
  setListingsList,
  getPlaces,
  setLastParams,
  lastSearchParams
}) => {
  const searchRef = useRef(null);

  let defaultSearchValue = { ...initialValue, ...lastSearchParams };
  if (initialValue.placeId) {
    defaultSearchValue = { ...initialValue };
  }
  const [cities, setCities] = useState(
    defaultSearchValue.placeId
      ? [
        {
          placeId: defaultSearchValue.placeId,
          placeType: defaultSearchValue.placeType,
          label: decodeURI(defaultSearchValue.label)
        },
      ]
      : []
  );
  const [searchQuery, setSearchQuery] = useState({ ...INITIAL_SEARCH_QUERY, ...defaultSearchValue });
  const [errors, setErrors] = useState({
    location: false,
    checkInDate: false,
    checkOutDate: false
  });

  useEffect(() => {
    if (places.length) {
      setCities(places);
    }
  }, [places]);

  useEffect(() => {
    if (history.location.search) {
      const query = queryString.parse(history.location.search.slice(1), { arrayFormat: "bracket" }) || {};

      if (!query.checkInDate || !query.checkOutDate || !query.placeId) {
        setErrors({
          location: !query.placeId,
          checkInDate: !query.checkInDate,
          checkOutDate: !query.checkOutDate
        });
      }

      setSearchQuery({ ...INITIAL_SEARCH_QUERY, ...query });
    }
  }, [history.location.search]);

  const updateLastSearchQuery = useCallback(() => {
    const saveFields =
      [
        "checkInDate",
        "checkOutDate",
        "rooms",
        "adults",
        "children",
        "childrenAge",
        "label",
        "placeId",
        "placeType",
      ];
    const normalizedSearchQuery = pick(searchQuery, saveFields);

    if (!isEqual(normalizedSearchQuery, lastSearchParams)) {
      setLastParams(normalizedSearchQuery);
    }
  }, [searchQuery, lastSearchParams]);

  async function searchCity(query) {
    if (query.length > 2) {
      getPlaces({ input: query });
      setSearchQuery({
        ...searchQuery,
        placeId: null,
        placeType: null,
        label: null,
      })
    }
  }

  const onChangeCityHandler = (value) => {
    const selectedCity = value && cities.find(item => value === item.placeId);

    const query = {
      ...searchQuery,
      placeId: null,
      placeType: null,
      label: null
    };

    if (selectedCity) {
      query.placeId = selectedCity.placeId;
      query.label = encodeURI(selectedCity.label);
      query.placeType = selectedCity.placeType;
    }

    setSearchQuery(query);
    setErrors({
      location: !query.placeId,
      checkInDate: !searchQuery.checkInDate,
      checkOutDate: !searchQuery.checkOutDate
    });

    if (typeof onChangeCity === "function") {
      onChangeCity(query);
    }
  };

  const updateInputsReadOnlyStatus = () => {
    setTimeout(() => {
      const inputs = document.querySelectorAll("#customSelectWrapper .ant-input-number-input");
      for (const input of inputs) {
        input.setAttribute("readOnly", true);
      }
    }, 100);
  };

  const onSearchHandler = () => {
    const { checkInDate, checkOutDate, placeId } = searchQuery;

    if (!checkInDate || !checkOutDate || !placeId) {
      setErrors({
        location: !searchQuery.placeId,
        checkInDate: !searchQuery.checkInDate,
        checkOutDate: !searchQuery.checkOutDate
      });

      return;
    }

    setListingsList([]);

    if (typeof onSearch === "function") {
      updateLastSearchQuery();
      onSearch(searchQuery);
    }
  };

  const customSelectOptions = () => {
    const newChildrenAgeArray = [...searchQuery.childrenAge];
    if (searchQuery.childrenAge.length !== +searchQuery.children) {
      if (searchQuery.childrenAge.length < +searchQuery.children) {
        newChildrenAgeArray.push(10);
      } else {
        newChildrenAgeArray.splice(-1, 1);
      }

      setSearchQuery({ ...searchQuery, childrenAge: newChildrenAgeArray });
      updateInputsReadOnlyStatus();
    }

    return (
      <div className={"rooms-guests__select"} id="customSelectWrapper">
        <div className="select-row">
          <label htmlFor="rooms" className="form-section__label">Rooms</label>
          <InputNumber
            id="rooms"
            min={1}
            max={10}
            allowClear={true}
            value={searchQuery.rooms}
            onChange={(event) => {
              setSearchQuery({ ...searchQuery, rooms: event });

              return event
            }}
          />
        </div>
        <div className="select-row">
          <label htmlFor="adults" className="form-section__label">Adults</label>
          <InputNumber
            id="adults"
            min={1}
            max={10}
            allowClear={true}
            value={searchQuery.adults}
            onChange={(event) => {
              setSearchQuery({ ...searchQuery, adults: event });

              return event
            }}
          />
        </div>
        <div className="select-row select-row--no-margin">
          <label htmlFor="childrenSelect" className="form-section__label">Children</label>
          <InputNumber
            id="childrenSelect"
            min={0}
            max={10}
            defaultValue={searchQuery.children}
            onChange={event => {
              setSearchQuery({ ...searchQuery, children: event });

              return event
            }}
          />
        </div>
        { newChildrenAgeArray.map((age, index) => {
          return (
            <div className="select-row select-row--no-margin" key={`child-${index}`}>
              <label htmlFor={`childrenAgeSelect-${index}`} className="form-section__label">Child {index + 1} Age</label>
              <InputNumber
                id={`childrenAgeSelect-${index}`}
                min={1}
                max={16}
                defaultValue={age || 10}
                onChange={event => {
                  newChildrenAgeArray[index] = event;
                  setSearchQuery({ ...searchQuery, childrenAge: newChildrenAgeArray });

                  return event
                }}
              />
            </div>
          )
        }) }
      </div>
    );
  };

  return (
    <React.Fragment>
      <Col span={24} className={
        `listing-search ${searchQuery.placeId && !searchQuery.checkInDate ? " listing-search__highlighted date-empty" : ""}`
      } ref={searchRef}>
        <Row>
          <Col
            xs={{ span: 12 }}
            md={{ span: showMobileVersion ? 12 : showFilters ? 6 : 7 }}
            xl={{ span: showFilters ? 6 : 7 }}
            className="listing-search__field-container listing-search__field-container--bordered"
          >
            <Row className="listing-search__label-wrapper listing-search__label-wrapper--location">
              <label htmlFor="location" className="listing-search__label">Location</label>
            </Row>
            <Row className={`listing-search__input-wrapper${errors.location ? " listing-search__input-wrapper--error" : ""}`}>
              <Col span={24} className="location__select-wrapper">
                <Select
                  showSearch
                  showArrow={false}
                  className="listing-search__input"
                  placeholder="Where are you going?"
                  dropdownClassName="listing-search__dropdown-location"
                  filterOption={() => {
                    return true;
                  }}
                  value={searchQuery.placeId}
                  spellCheck="false"
                  onSearch={debounce(value => searchCity(value), 500)}
                  onChange={onChangeCityHandler}
                  notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Enter a District, City, State or Country name" />}
                  optionLabelProp="label"
                  getPopupContainer={() => searchRef.current}
                >
                  {
                    cities.map(item => {
                      const locations = item.label.split(", ");

                      return (
                        <Select.Option key={item.placeId} value={item.placeId} label={item.label}>
                          <div className="listing-search__place">
                            <img src="/images/location.svg" alt="location" className="listing-search__place-icon"/>
                            <div>
                              <div className="listing-search__place-title">{locations.shift()}</div>
                              <div className="listing-search__place-subtitle">{locations.join(", ")}</div>
                            </div>
                          </div>
                        </Select.Option>
                      )
                    })
                  }
                </Select>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: showMobileVersion ? 12 : (showFilters ? 6 : 7) }}
            xl={{ span: showFilters ? 7 : 8 }}
            className={`listing-search__field-container listing-search__field-dates listing-search__field-container--bordered ${ !searchQuery.checkInDate || !searchQuery.checOutDate ? "empty" : "" }`}
          >
            <Row className="listing-search__label-wrapper">
              <label htmlFor="calendar" className="listing-search__label">Period</label>
            </Row>
            <Row className="date-select__wrapper">
              <Col
                span={24}
                className={`listing-search__input-wrapper${(errors.checkInDate || errors.checkOutDate) ? " listing-search__input-wrapper--error" : ""}`}
              >
                <DatePicker
                  type={"range"}
                  className="listing-search__input"
                  format="DD MMM"
                  placeholder={["Check in", "Check out"]}
                  dropdownClassName="listing-search__datepicker"
                  separator={false}
                  suffixIcon={false}
                  allowClear={false}
                  value={[
                    searchQuery.checkInDate ? moment(searchQuery.checkInDate) : null,
                    searchQuery.checkOutDate ? moment(searchQuery.checkOutDate) : null
                  ]}
                  disabledDate={current => current && current < moment().startOf("day")}
                  onChange={value => {
                    setSearchQuery({
                      ...searchQuery,
                      checkInDate: value && value[0] ? value[0].format("YYYY-MM-DD") : null,
                      checkOutDate: value && value[1] ? value[1].format("YYYY-MM-DD") : null
                    });

                    setErrors({
                      location: !searchQuery.placeId,
                      checkInDate: !value || !value[0],
                      checkOutDate: !value || !value[1]
                    });
                  }}
                  getPopupContainer={() => searchRef.current}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 14 }}
            md={{ span: showMobileVersion ? 14 : ((showFilters && showFiltersLabel) ? 6 : 8) }}
            xl={{ span: (showFilters && showFiltersLabel) ? 6 : 8 }}
            className={`listing-search__field-container listing-search__field-guests ${showFilters ? " listing-search__field-container--bordered" : ""}`}>
            <Row className="listing-search__label-wrapper">
              <label className="listing-search__label">Guests</label>
            </Row>
            <Row className="listing-search__input-wrapper">
              <div className="rooms-guests__select-wrapper">
                <Select
                  showArrow={false}
                  showSearch={false}
                  mode="multiple"
                  className="listing-search__input listing-search__input-rooms"
                  dropdownRender={customSelectOptions}
                  dropdownClassName="listing-search__dropdown-rooms"
                  onDropdownVisibleChange={updateInputsReadOnlyStatus}
                  getPopupContainer={() => searchRef.current}
                />
                <div className="rooms-guests__select-value-wrapper">
                  {
                    searchQuery.rooms || searchQuery.adults
                      ? <span className="rooms-guests__select-value">{createHotelRoomsText(searchQuery.rooms, searchQuery.adults, searchQuery.children)}</span>
                      : <span className="rooms-guests__select-placeholder">How many of you?</span>
                  }
                </div>
              </div>
            </Row>
          </Col>
          <Col
            xs={{ span: 10 }}
            md={{ span: 10 }}
            xl={{ span: 1 }} className="listing-search__buttons">
            { isProcessing
              ? <img src="/images/loading.svg" alt="search" className="search-icon search-icon--loading"/>
              : <Button
                htmlType="button"
                className={`button${showFilters ? " button--margin-left" : ""}`}
                onClick={onSearchHandler}
                disabled={isProcessing}
              >
                <img src="/images/search.svg" alt="search" className="search-icon"/>
              </Button>
            }
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  )
};

ListingSearch.propTypes = {
  initialValue: PropTypes.object,
  isProcessing: PropTypes.bool,
  places: PropTypes.array,
  showFilters: PropTypes.bool,
  showFiltersLabel: PropTypes.bool,
  showMobileVersion: PropTypes.bool,
  history: PropTypes.object,
  filtersLabel: PropTypes.string,
  disableFilters: PropTypes.bool,
  onChangeCity: PropTypes.func,
  onSearch: PropTypes.func,
  setListingsList: PropTypes.func,
  getPlaces: PropTypes.func,
  setLastParams: PropTypes.func,
  lastSearchParams: PropTypes.object
};

export default withRouter(connect(
  state => ({
    isProcessing: state.listing.processing,
    places: state.places.list,
    lastSearchParams: state.listing.lastSearchParams,
  }),
  {
    setListingsList: setListingsListAction,
    getPlaces: getPlacesRequest,
    setLastParams: setLastSearchParams
  }
)(ListingSearch));
