import React from "react";
import PropTypes from "prop-types";
import { Col, Layout, Row } from "antd";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import AdminSidebar from "../Admin/AdminSidebar";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../constants";

function AdminLayout({ children, userRole }) {
  if (!userRole) {
    return null;
  } else if (userRole !== ADMIN_ROLE && userRole !== SUPER_ADMIN_ROLE) {
    return <Redirect to="/" />;
  }

  return (
    <div className="user-content">
      <Header/>
      <Layout.Content className="admin-content">
        <Row justify="space-around" align="middle">
          <Col xs={{ span: 24 }} md={{ span: 20 }}>
            <Layout.Content>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 5 }}>
                  <AdminSidebar />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 18 }} lg={{ span: 19 }}>
                  { children }
                </Col>
              </Row>
            </Layout.Content>
          </Col>
        </Row>
      </Layout.Content>
      <Footer/>
    </div>
  )
}

AdminLayout.propTypes = {
  children: PropTypes.any,
  userRole: PropTypes.string.isRequired
};

export default connect(
  state => ({
    userRole: state.profile.permissions.role
  })
)(AdminLayout);
