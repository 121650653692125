import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const CheckDateItem = ({ name, date }) => {
  const formattedDate = moment(date).format("DD MMM YYYY");

  return (
    <div className="check-date-item">
      <span className="check-date-item__name">{name}</span>
      <div className="check-date-item__date-wrapper">
        <img alt="date image" className="check-date-item__icon" src="/images/icons/calendar-check.svg"/>
        <span className="check-date-item__date">{formattedDate}</span>
      </div>
    </div>
  );
};

CheckDateItem.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};

export default CheckDateItem;
