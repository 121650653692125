import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Row, Col, Button, Typography } from "antd";
import * as yup from "yup";

import TextAreaWithLabel from "../../shared/TextAreaWithLabel";
import { createListingDisputeRequest, clearListingErrorAction } from "../../../redux/listing/listingActions";
import { showModalAction } from "../../../redux/general/generalActions";
import SuccessDispute from "./SuccessDispute";
import CheckboxGroup from "./CheckboxGroup";
import UploadDragger from "../../shared/UploadDragger";

const REASONS_OPTIONS = [
  { label: "Problem at Check In", value: "Problem at Check In" },
  { label: "Room type didn’t match description", value: "Room type didn’t match description" },
  { label: "Hotel facilities didn’t match description", value: "Hotel facilities didn’t match description" },
  { label: "Problem at Check Out", value: "Problem at Check Out" },
  { label: "Other", value: "Other" }
];

const disputeSchema = yup.object().shape({
  reasons: yup.array().required("Select at least one item"),
  description: yup.string().trim().required("Description can not be empty"),
});

const CreateDispute = ({
  isProcessing,
  successCreatedDispute,
  match,
  createListingDispute,
  clearListingError,
  showModal
}) => {
  const [files, setFiles] = useState([]);
  const [fileTypeError, setFileFileTypeError] = useState(false);
  const [filesError, setFilesError] = useState("");

  const { handleSubmit, errors, control } = useForm({
    validationSchema: disputeSchema
  });

  const { slug } = match.params;

  useEffect(() => {
    clearListingError();
  }, []);

  const deleteFile = fileUid => {
    const filesList = files.filter(file => file.uid !== fileUid);

    setFiles(filesList);
    setFileFileTypeError(false);
  };

  const onSubmitHandler = (data) => {
    const isFilesValid = files.reduce((isValid, file) => {
      return ["application/pdf", "image/jpeg", "image/jpg", "image/gif", "image/png"].includes(file.type);
    }, true);

    if (!isFilesValid) {
      return setFileFileTypeError(true);
    }

    const formData = new FormData();
    formData.append("description", data.description);
    if (data.reasons.length) {
      for (const reason of data.reasons) {
        formData.append("reasons[]", reason);
      }
    }
    if (files.length) {
      for (const file of files) {
        formData.append("documents[]", file);
      }
    }

    showModal({
      type: "info",
      width: 450,
      title: "Are you sure you would like to raise a dispute? This action can't be reverted.",
      params: {
        isProcessing,
        buttonText: "Yes",
        isCancelButton: true,
        confirmAction: () => createListingDispute({ slug, body: formData })
      }
    });
  };

  if (successCreatedDispute) {
    return <SuccessDispute/>
  }

  return (
    <Row className="listing__container create-dispute">
      <Col span={24}>
        <Row justify="space-around" align="middle" >
          <Col xs={{ span: 22 }} md={{ span: 20 }} xl={{ span: 16 }}>
            <Typography.Title level={1}>
                Dispute
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col xs={{ span: 22 }} md={{ span: 20 }} xl={{ span: 16 }}>
            <form className="profile__form" onSubmit={handleSubmit(onSubmitHandler)}>
              <Row className="form-container">
                <Col span={24}>
                  <Row className="form-row">
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <h2 className="form-subtitle">Reasons for dispute</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Controller
                            name="reasons"
                            control={control}
                            id="reasons"
                            as={
                              <CheckboxGroup
                                options={REASONS_OPTIONS}
                                className={`create-dispute__checkbox-group ${errors.reasons ? " form-section__input--error" : ""}`}
                                grid={{ span: 24 }}
                              />
                            }
                            defaultValue={[]}
                            onChange={([value]) => value }
                          />
                          {errors.reasons && <span className="profile__error">{errors.reasons.message}</span>}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="form-row">
                    <Col span={24}>
                      <TextAreaWithLabel
                        name="description"
                        control={control}
                        id="description"
                        label="Please give a brief description of the issue and a member will consider your appeal:"
                        error={errors.description}
                        placeholder="Write your comment here..."
                        rows={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={`form-container form-row file-upload__container${fileTypeError || filesError ? " file-upload__files--error" : ""}`}>
                <Col span={24}>
                  <h2 className="form-subtitle">Attach document</h2>
                  <Row>
                    <Col span={24}>
                      <UploadDragger
                        beforeUpload={(file, fileList) => {
                          if (files.length < 5) {
                            setFiles([...files, ...fileList]);
                            setFilesError("");
                          } else {
                            setFilesError("Dispute could have max 5 documents");
                          }

                          return false;
                        }}
                        fileList={files}

                        title={
                          <>
                            <span className="file-upload__text-link">Add up to 5 files</span>
                            <span>Or drop files here</span>
                          </>
                        }
                      />

                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      { files.map(file => {
                        return (
                          <div key={file.uid} className="file-upload__file">
                            <div>
                              {file.name}
                            </div>
                            <div
                              onClick={() => {
                                deleteFile(file.uid);
                                setFilesError("");
                              }}
                              className="file-upload__delete-uploaded-file"
                            >
                              <img src="/images/close.svg" alt="delete"/>
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      className={`form-file-upload__file-types${fileTypeError ? " file-upload__file-types--error" : ""}`}
                    >
                      Supports: .pdf, .jpeg, .jpg, .gif and .png file format
                    </Col>
                    <Col
                      xs={{ span: 24 }} md={{ span: 12 }}
                      className={`file-upload__file-types${filesError ? " file-upload__file-types--error" : ""}`}
                    >
                      <span>{filesError}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify="center">
                <Button
                  className="profile__form__submit-btn"
                  htmlType="submit"
                  type="primary"
                  disabled={isProcessing}
                  loading={isProcessing}
                >
                  Raise dispute
                </Button>
              </Row>
            </form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CreateDispute.propTypes = {
  listing: PropTypes.object.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  successCreatedDispute: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  createListingDispute: PropTypes.func.isRequired,
  clearListingError: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    listing: state.listing.singleListing,
    isProcessing: state.listing.processing,
    successCreatedDispute: state.listing.successCreatedDispute,
  }),
  {
    createListingDispute: createListingDisputeRequest,
    clearListingError: clearListingErrorAction,
    showModal: showModalAction,
  }
)(CreateDispute);
