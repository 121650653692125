import React, { useEffect } from "react";

export const GoogleAds = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const libScript = document.createElement("script");
      libScript.async = true;
      libScript.type = "text/plain";
      libScript.setAttribute("data-cookie-consent", "targeting");
      libScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ADS_ID}`;

      const script = document.createElement("script");
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GOOGLE_ADS_ID}');
      `;

      const node = document.getElementsByTagName("script")[0];
      node.parentNode.insertBefore(libScript, node);
      node.parentNode.insertBefore(script, node);
    }
  }, []);

  return <React.Fragment/>
};

export default GoogleAds;
