import React, { useEffect, useState } from "react";
import { Col, DatePicker, Input, Row, Select, Table } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { itemRender } from "../../../helpers/pagination";
import {
  deleteQuestionRequest, getCategoriesRequest,
  getQuestionsRequest,
  updateQuestionRequest
} from "../../../redux/admin/faq/faqActions";
import { hideModalAction, showModalAction } from "../../../redux/general/generalActions";
import debounce from "lodash/debounce";

const Questions = ({
  questions,
  categories,
  pagination,
  requestSuccess,
  isProcessing,
  error,
  showModal,
  hideModal,
  getQuestions,
  updateQuestion,
  deleteQuestion,
  getCategories
}) => {
  const [paginationObj, setPaginationObj] = useState({
    status: null,
    pageSize: 10,
    sort: "-createdAt"
  });

  useEffect(() => {
    getCategories({ pageSize: -1 });
  }, []);

  useEffect(() => {
    getQuestions(paginationObj);
  }, [paginationObj]);

  useEffect(() => {
    hideModal();
  }, [requestSuccess]);

  const handleTableChange = (tablePagination, filters, sorter) => {
    const sortByValue = typeof sorter.order === "undefined" ? sorter.order : `${sorter.order === "descend" ? "-" : ""}${sorter.field}`;
    setPaginationObj({ ...paginationObj, page: tablePagination.current, sort: sortByValue });
  };

  const handleFilterByStatus = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, status: value });
  };

  const handleSearch = (value) => {
    setPaginationObj({ ...paginationObj, page: 1, search: value });
  };

  const delayedFilterByDate = debounce(date => {
    setPaginationObj({
      ...paginationObj,
      createdAt: date ? moment(date).format("YYYY-MM-DD") : date,
      page: 1
    });
  }, 300);

  const delayedSearch = debounce(query => handleSearch(query), 500);

  const renderStatusSelect = (text, record) => {
    return (
      <span>
        <Select
          id={`status-${record.key}`}
          disabled={isProcessing}
          value={text}
          onChange={data => {
            updateQuestion({ id: record.key, status: data, action: data.slice(0, -2) });
          }}
        >
          <Select.Option value="published">Published</Select.Option>
          <Select.Option value="unpublished">Unpublished</Select.Option>
        </Select>
      </span>
    );
  };

  const renderEditButton = record => (
    <Link to={`/admin/faq/questions/${record.key}`} >
      <img
        src="/images/edit.svg"
        alt="edit"
        className="feedback-list__delete"
      />
    </Link>
  );

  const renderDeleteButton = record => (
    <img
      src="/images/close.svg"
      alt="remove"
      className="feedback-list__delete"
      onClick={() => showModal({
        type: "confirm",
        title: "Are You sure you would like to delete question?",
        params: {
          isProcessing,
          error,
          confirmAction: () => deleteQuestion({ id: record.key })
        }
      })}
    />
  );

  return (
    <Row className="feedback-list article-list">
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Row justify="space-between" align="top">
              <Col span={16} className="feedback-list__filters">
                <span className="feedback-list__filter">Show items</span>
                <Select
                  className="feedback-list__filter article-list__filter-size"
                  defaultValue={paginationObj.pageSize}
                  onChange={value => {
                    setPaginationObj({ ...paginationObj, page: 1, pageSize: value });
                  }}
                >
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="20">20</Select.Option>
                  <Select.Option value="30">30</Select.Option>
                </Select>
                <Select
                  className="feedback-list__filter article-list__filter-category"
                  defaultValue={paginationObj.status}
                  onChange={handleFilterByStatus}
                >
                  <Select.Option value={null}>All</Select.Option>
                  <Select.Option value="published">Published</Select.Option>
                  <Select.Option value="unpublished">Unpublished</Select.Option>
                </Select>
                <DatePicker
                  className="feedback-list__filter"
                  onChange={value => delayedFilterByDate(value)}
                  format="DD-MM-YYYY"
                />
              </Col>
              <Col span={8} className="article-list__search-wrapper">
                <Input.Search
                  onChange={event => delayedSearch(event.target.value)}
                  onSearch={handleSearch}
                  placeholder="Search"
                  className="feedback-list__search"
                />
                <Link to="/admin/faq/questions/new" className="ant-btn button button--primary">New Question</Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className="article-list__items"
              loading={isProcessing}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                itemRender: itemRender,
                showSizeChanger: false
              }}
              dataSource={questions.map(question => {
                return {
                  key: question.id,
                  title: question.title,
                  category: (() => {
                    const currentCategory = categories.find(questionCategory => {
                      return questionCategory.id === question.categoryId;
                    });

                    return currentCategory ? currentCategory.title : "";
                  })(),
                  status: question.status,
                  createdAt: moment(question.createdAt).format("DD MMM YYYY, hh:mmA")
                }
              })}
              columns={[
                {
                  title: "Title",
                  dataIndex: "title",
                  key: "title",
                  className: "article-list__items-name"
                },
                {
                  title: "Category",
                  dataIndex: "category",
                  key: "category",
                  className: "article-list__items-name"
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  sortDirections: ["descend", "ascend"],
                  width: 145,
                  sorter: true,
                  render: renderStatusSelect
                },
                {
                  title: "Created",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
                  sortDirections: ["descend", "ascend"],
                  width: 145
                },
                {
                  title: "",
                  key: "actions",
                  width: 20,
                  render: renderEditButton
                },
                {
                  title: "",
                  key: "actions",
                  width: 20,
                  render: renderDeleteButton
                }
              ]}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Questions.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  getQuestions: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.admin.faq.processing,
    error: state.admin.faq.error,
    questions: state.admin.faq.questions.data,
    pagination: {
      current: state.admin.faq.questions.curPage,
      total: state.admin.faq.questions.total,
      pageSize: state.admin.faq.questions.perPage
    },
    categories: state.admin.faq.categories.data,
    requestSuccess: state.admin.faq.requestSuccess
  }),
  {
    getQuestions: getQuestionsRequest,
    updateQuestion: updateQuestionRequest,
    deleteQuestion: deleteQuestionRequest,
    getCategories: getCategoriesRequest,
    showModal: showModalAction,
    hideModal: hideModalAction
  }
)(Questions);
