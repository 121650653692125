import React, { useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Grid, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import chunk from "lodash/chunk";
import { Helmet } from "react-helmet";

import { getBestDealsRequest, setNewListingData } from "../../redux/listing/listingActions";
import { partners } from "../../static/partners";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import PartnersList from "../shared/PartnersList";
import BestDeal from "../shared/BestDeal";
import Reviews from "./Reviews/Reviews";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Subscribe from "../shared/Subscribe/Subscribe";
import { getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";
import parse from "html-react-parser";
import "swiper/swiper-bundle.css";
import ListingCard from "../shared/ListingCard";
import HighlightCard from "../shared/HighlightCard";
import { Pagination, Navigation, Swiper, SwiperSlide } from "./Swiper/Swiper";
import { getHighlightsRequest } from "../../redux/highlights/highlightsActions";

export const Home = ({
  bestDeals,
  getBestDeals,
  getStaticPage,
  pageData,
  getHighlights,
  highlights,
  setNewListing,
}) => {
  const { xs, sm, md, lg } = Grid.useBreakpoint();

  useServerSafeEffect(() => {
    getStaticPage();
    setNewListing({});
  }, []);

  const formattedBestDeals = useMemo(() => {
    const listings = [];
    let latestGroupIndex = 0;

    bestDeals.forEach((bestDeal, index) => {
      if (index % 4 === 0) {
        listings.push(bestDeal);
        latestGroupIndex++;
      } else {
        const lastArrayItem = listings[latestGroupIndex];

        if (Array.isArray(lastArrayItem)) {
          listings[latestGroupIndex] = [
            ...lastArrayItem,
            bestDeal
          ];
        } else {
          latestGroupIndex = listings.length;
          listings.push([bestDeal]);
        }
      }
    });

    return chunk(listings, 2);
  }, [bestDeals]);

  useServerSafeEffect(() => {
    getBestDeals({ page: 1, pageSize: 32 });
  }, []);

  const formattedHighlights = useMemo(() => {
    const fHighlights = [];
    if (highlights) {
      highlights.forEach((highlight) => {
        fHighlights.push(highlight);
      });
    }

    return fHighlights
  }, [highlights]);

  useServerSafeEffect(() => {
    getHighlights({ page: 1, pageSize: 50 });
  }, []);

  const getNumberOfResaleDealsPerScreen = () => {
    if (xs) {
      return 1;
    }

    if (sm && !md) {
      return 2;
    }

    if ((sm || md) && !lg) {
      return 3
    }

    return 4;
  }

  return (
    <>
      <Helmet>
        {
          pageData.metaTitle && (
            <title>{ pageData.metaTitle}</title>
          )
        }
        {
          pageData.metaDescription && (
            <meta name="description" content={pageData.metaDescription} />
          )
        }
        {
          pageData.metaKeywords && (
            <meta name="keywords" content={pageData.metaKeywords} />
          )
        }
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      </Helmet>
      <div className="main-content home-page">
        <Row>
          <Col span={24}>
            <section className="home-page__fixed-wrapper">
              <Header/>
              <section className="home-page__search-container">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 18 }} className="container-medium home-page__search">
                    <Row>
                      <Col span={24} align="middle">
                        <h1 className="home-page__search-title">
                          { pageData.title }
                        </h1>
                        <p className="home-page__search-subtitle">
                          { pageData.subtitle }
                        </p>
                      </Col>
                    </Row>
                    <Row justify="space-around">
                      <Col span={24} className="home-page__action-buttons">
                        <Link to="/selling">
                          <div className="home-page__sell-container">
                            <div className="home-page__sell-button">
                              Sell
                            </div>
                            { !!pageData.sellMainContent &&
                              <p className="sell-main">
                                { pageData.sellMainContent }
                              </p>
                            }
                            { !!pageData.sellSubContent &&
                              <p className="sell-sub"><b>
                                { pageData.sellSubContent }
                              </b></p>
                            }
                          </div>
                        </Link>
                        <Link to="/resale-deals">
                          <div className="home-page__buy-container">
                            <div className="home-page__buy-button">
                              Buy
                            </div>
                            { !!pageData.buyMainContent &&
                              <p className="buy-main">
                                { pageData.buyMainContent }
                              </p>
                            }
                            { !!pageData.buySubContent &&
                              <p className="buy-sub"><b>
                                { pageData.buySubContent }
                              </b></p>
                            }
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </section>
              <section className="home-page__subscribe">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 16 }} align="middle">
                    <Row align="middle" gutter={24}>
                      <Col xs={{ span: 24 }} md={{ span: 10 }}>
                        { pageData.signUpText }
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 14 }}>
                        <Subscribe showIcon={true}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </section>
              {!!formattedBestDeals.length &&
                <section className="home-page__section home-page__mini-resale-deals">
                  <Row justify="center" className="home-page__listings">
                    <Col xs={{ span: 22 }} lg={{ span: 18 }} className="swiper-container">
                      <Row align="middle" justify="start" className="home-page__listings-title-container">
                        <Col span={24} className="home-page__listings-title">
                          <Typography.Title
                            className="home-page__section-resale-deals-title"
                            level={4}
                          >
                            Latest Resale Deals
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          { bestDeals.length > getNumberOfResaleDealsPerScreen() &&
                          <Swiper
                            className={`home-page__resale-deals-small-swiper show-${getNumberOfResaleDealsPerScreen()}`}
                            slidesPerView={getNumberOfResaleDealsPerScreen()}
                            pagination={{
                              clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            spaceBetween={30}
                            loop
                            autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                            }}
                          >
                            {bestDeals.map((listing, key) => {
                              return (
                                <SwiperSlide key={`best-deals-group-${key}`}>
                                  <ListingCard listing={listing} size="small" baseElementSize={7} />
                                </SwiperSlide>
                              )
                            })}
                          </Swiper>
                          }
                          { bestDeals.length <= getNumberOfResaleDealsPerScreen() &&
                            <div className={`home-page__resale-deals-small-swiper no-swiping show-${getNumberOfResaleDealsPerScreen()}`}>
                              {bestDeals.map((listing, key) => {
                                return (
                                  <div className="swiper-slide" key={`best-deals-mini-noswiper-${key}`}>
                                    <ListingCard listing={listing} size="small" baseElementSize={7} />
                                  </div>
                                )
                              })}
                            </div>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </section>
              }
              <section className="home-page__section home-page__hotel-highlights">
                <Row justify="center" className="home-page__highlights">
                  <Col xs={{ span: 24 }} lg={{ span: 18 }} className="container-medium">
                    {!!formattedHighlights.length &&
                      <Row gutter={[24, 16]} justify="center" className="home-page__highlight-row">
                        {formattedHighlights.map((highlight, key) => {
                          return (
                            <Col xs={{ span: 24 }} lg={{ span: 8 }} key={key} className="hotel-highlight-placeholder">
                              <HighlightCard highlight={highlight} baseElementSize={10} />
                            </Col>
                          )
                        })}
                      </Row>
                    }
                  </Col>
                </Row>
              </section>
            </section>
            <section className="home-page__section home-page__reviews-section">
              <Row justify="space-around" align="middle">
                <Col xs={{ span: 22 }} lg={{ span: 20 }} align="center">
                  <Row>
                    <Col span={24}>
                      <Typography.Title level={2} className="home-page__section-title">
                        <div className="plainHtml">
                          {parse(pageData.reviewsContent || "")}
                        </div>
                      </Typography.Title>
                      <div
                        className="trustpilot-widget"
                        data-locale="en-GB"
                        data-template-id="5419b6a8b0d04a076446a9ad"
                        data-businessunit-id="627a4c099c36a16dd3a583d2"
                        data-style-height="24px"
                        data-style-width="100%"
                        data-theme="light"
                        data-min-review-count="10"
                        data-style-alignment="center"
                      >
                        <a
                          href="https://uk.trustpilot.com/review/www.planschange.com"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Trustpilot
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row justify="space-around">
                    <Col span={24} align="left">
                      <Reviews
                        reviewsData={
                          JSON.parse(pageData.reviews || "[]")
                            .map(r => ({ ...r, rating: r.stars }))
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
            {!!formattedBestDeals.length &&
              <section className="home-page__section">
                <Row justify="center" className="home-page__listings">
                  <Col xs={{ span: 22 }} lg={{ span: 20 }} className="swiper-container">
                    <Row align="middle" justify="start" className="home-page__listings-title-container">
                      <Col span={24} className="home-page__listings-title">
                        <Typography.Title
                          className="home-page__section-title"
                          level={2}
                        >
                          Latest Resale Deals
                        </Typography.Title>
                        <Link to="/resale-deals" className="home-page__listings-link">
                          <img src="/images/icons/clock-white.svg" alt="clock"/>
                          <span>Get them while they last!</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Swiper
                          className="home-page__resale-deals-swiper"
                          slidesPerView={1}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination]}
                          loop
                        >
                          {formattedBestDeals.map((listings, key) => {
                            return (
                              <SwiperSlide key={`best-deals-group-${key}`}>
                                <div>
                                  <Row gutter={16}>
                                    {listings.map((item, itemKey) => <BestDeal key={`best-deal-container-${itemKey}`} item={item}/>)}
                                  </Row>
                                </div>
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </section>
            }
            <section className="home-page__section">
              <Row justify="center">
                <Col xs={{ span: 22 }} md={{ span: 20 }} lg={{ span: 18 }} align="middle" className="home-page__who-we-are">
                  <Row>
                    <Col span={24} className="ant-typography">
                      <div className="plainHtml">
                        {parse(pageData.pageMainContent || "")}
                      </div>
                    </Col>
                  </Row>
                  <Row className="home-page__who-we-are__items">
                    <Col span={24} align="left">
                      <Row gutter={[32, 32]}>
                        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                          <Link to="/listings/create">
                            <Row align="middle" className="home-page__who-we-are__item">
                              <Col span={24} align="center">
                                <img
                                  className="home-page__who-we-are__item-icon"
                                  src="/images/icons/sell-and-recover.svg"
                                  alt="Resell your room. You decide the price"
                                />
                              </Col>
                              <Col span={24} align="center">
                                <span className="home-page__who-we-are__item-label">
                                  { pageData.wwaFirstContent }
                                </span>
                              </Col>
                            </Row>
                          </Link>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                          <Link to="/selling">
                            <Row
                              align="middle"
                              className="home-page__who-we-are__item"
                            >
                              <Col span={24} align="center">
                                <img
                                  className="home-page__who-we-are__item-icon"
                                  src="/images/icons/instant-exchange.svg"
                                  alt="Search for resale rooms at discounted rates"
                                />
                              </Col>
                              <Col span={24} align="center">
                                <span className="home-page__who-we-are__item-label">
                                  { pageData.wwaSecondContent }
                                </span>
                              </Col>
                            </Row>
                          </Link>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                          <Link to="/about-us">
                            <Row align="middle" className="home-page__who-we-are__item">
                              <Col span={24} align="center">
                                <img
                                  className="home-page__who-we-are__item-icon"
                                  src="/images/icons/confidence.svg"
                                  alt="Book with confidence all rooms guaranteed"
                                />
                              </Col>
                              <Col span={24} align="center">
                                <span className="home-page__who-we-are__item-label">
                                  { pageData.wwaThirdContent }
                                </span>
                              </Col>
                            </Row>
                          </Link>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                          <Row align="middle" className="home-page__who-we-are__item">
                            <Col span={24} align="center">
                              <img
                                className="home-page__who-we-are__item-icon"
                                src="/images/icons/anytime.svg"
                                alt="Partnered with branded hotels"
                              />
                            </Col>
                            <Col span={24} align="center">
                              <span className="home-page__who-we-are__item-label">
                                { pageData.wwaFourthContent }
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Link to="/about-us">
                        <Button type="primary" className="home-page__who-we-are__more">Find out more</Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
            <section className="home-page__section home-page__partners-section">
              <Row justify="center">
                <Col xs={{ span: 22 }} lg={{ span: 18 }} align="middle" className="ant-typography">
                  <div className="plainHtml">
                    {parse(pageData.partnersContent || "")}
                  </div>
                  <PartnersList partners={partners}/>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
        <Footer/>
      </div>
    </>
  );
};

Home.propTypes = {
  bestDeals: PropTypes.array.isRequired,
  getBestDeals: PropTypes.func.isRequired,
  getStaticPage: PropTypes.func.isRequired,
  pageData: PropTypes.any.isRequired,
  getHighlights: PropTypes.func.isRequired,
  setNewListing: PropTypes.func.isRequired,
  highlights: PropTypes.array
};

export default connect(
  state => ({
    isProcessing: state.listing.processing,
    bestDeals: state.listing.bestDeals,
    pageData: state.staticPage.page,
    highlights: state.highlights.data
  }),
  {
    getBestDeals: getBestDealsRequest,
    getHighlights: getHighlightsRequest,
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "home-page" }),
    setNewListing: setNewListingData,
  }
)(Home);
