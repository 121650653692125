import React, { useEffect, useState, useRef, useMemo } from "react";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet-universal";
import * as queryString from "query-string";
import "leaflet/dist/leaflet.css";
import isEqual from "lodash/isEqual";

import ResaleListingItem from "../Listing/ResaleListingItem";
import HighlightCard from "../shared/HighlightCard";
import HotelView from "../shared/HotelView";

import { getBestDealsRequest } from "../../redux/listing/listingActions";
import { getHighlightsRequest } from "../../redux/highlights/highlightsActions";
import { getFaqRequest, getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";
import { setPlacesListAction } from "../../redux/places/placesActions";

import { ejectFaqs, getCurrencyBadge } from "../../helpers/common";
import FaqsList from "../shared/FaqsList";
import PartnersList from "../shared/PartnersList";
import SellBooking from "../shared/SellBooking";
import { ImageBgCover } from "../shared/SimpleComponents/ImageBgCover/ImageBgCover";

import { itemRender } from "../../helpers/pagination";
import { MAPBOX_ACCESS_TOKEN, MAPBOX_USERNAME, MAPBOX_STYLE_ID } from "../../api/constants";
import Pagination from "../shared/Pagination";
import SortBestDealsList from "../Listing/SortBestDealsList";
import ListingSearch from "../shared/ListingSearch";
import useSingleton from "../../hooks/useSingleton";

import { partners } from "../../static/partners";

import Header from "../shared/Header";
import Footer from "../shared/Footer";

const PAGE_SIZE = 5;
const defaultFaqsAmount = 10;
let Leaflet = null;

const reducer = (acc, cur) => {
  try {
    const longitude = cur.Hotel.location.coordinates[0];
    const latitude = cur.Hotel.location.coordinates[1];

    return {
      minLat: !acc.minLat || latitude < acc.minLat ? latitude : acc.minLat,
      maxLat: !acc.maxLat || latitude > acc.maxLat ? latitude : acc.maxLat,
      minLon: !acc.minLon || longitude < acc.minLon ? longitude : acc.minLon,
      maxLon: !acc.maxLon || longitude > acc.maxLon ? longitude : acc.maxLon
    }
  } catch (e) {
    return acc;
  }
};

export const ResaleDeals = ({
  isProcessing,
  coordinates,
  getBestDeals,
  getStaticPage,
  pageData,
  match,
  bestDeals,
  getHighlights,
  highlights,
  categories,
  getFaq,
}) => {
  const history = useHistory();

  const mapRef = useRef(null);
  const markerRefs = useRef({});
  let delayedFlyTo = false;

  useSingleton(() => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      Leaflet = require("leaflet");
    }
  });

  const [highlightlId, setHighlightId] = useState({});
  const [highlightHotel, setHighlightHotel] = useState({});
  const [searchQuery, setSearchQuery] = useState({});

  const [bounds, setBounds] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapView, setMapView] = React.useState(false)
  const [sortedBestDeals, setSortedBestDeals] = useState([]);

  useServerSafeEffect(() => {
    getStaticPage();
    getBestDeals({ page: 1, pageSize: 52 });
    getHighlights({ page: 1, pageSize: 50 });
    getFaq();
  }, []);

  useServerSafeEffect(() => {
    const { slug } = match.params;
    if (slug) {
      if (slug.indexOf("-")) {
        const hlId = parseInt(slug.substring(0, slug.indexOf("-")), 10);

        if (hlId !== highlightlId) {
          setHighlightId(hlId);
        }

        const place = slug.substring(slug.indexOf("-") + 1);
        if (place.indexOf("-")) {
          const newSearch = {
            label: place.substring(place.indexOf("-") + 1),
            placeId: place.substring(0, place.indexOf("-")),
            placeType: "hotel"
          }
          let hlHotel = false
          if (highlights) {
            hlHotel = highlights.filter((hl) => hl.id === hlId)[0]
          }
          if (hlHotel) {
            setHighlightHotel(hlHotel.Hotel)
          }

          if (!isEqual(newSearch, searchQuery)) {
            setSearchQuery(newSearch);
          }
        }
      }
    }
  }, [match]);

  const faqs = useMemo(() => ejectFaqs(categories), [categories]);

  const onShowMap = () => {
    setMapView(true)
    setTimeout(() => {
      mapRef.current.leafletElement.invalidateSize();
    }, 100)
  }
  const onShowList = () => setMapView(false)

  const setBestDealsList = (bestDealsList) => {
    setSortedBestDeals(bestDealsList)
  }

  // eslint-disable-next-line no-unused-vars
  const formattedHighlights = useMemo(() => {
    const fHighlights = [];
    if (highlights) {
      highlights.forEach((highlight) => {
        fHighlights.push(highlight);
        if (highlightlId === highlight.id) {
          setHighlightHotel(highlight.Hotel)
        }
      });
    }

    return fHighlights
  }, [highlights]);

  useEffect(() => {
    const boundsObj = bestDeals.reduce(reducer, { minLat: null, maxLat: null, minLon: null, maxLon: null, });
    if (!bounds && !Object.values(boundsObj).some(b => b === null)) {
      setBounds([[boundsObj.minLat, boundsObj.minLon], [boundsObj.maxLat, boundsObj.maxLon]]);
    }
    const sortedDeals = [...bestDeals].sort((prev, next) => {
      // default sort date
      return next.checkInDate > prev.checkInDate ? -1 : next.checkInDate === prev.checkInDate ? 0 : 1
    })
    setSortedBestDeals(sortedDeals)
  }, [bestDeals]);

  const mapClickHandler = (ev) => {
    if (mapRef.current && ev.latlng) {
      mapRef.current.leafletElement.panTo(ev.latlng)
    }
    setSelectedMarker(null);
  };

  const markerClickHandler = (ev, id) => {
    setSelectedMarker(id);
    if (mapRef.current && ev.latlng) {
      mapRef.current.leafletElement.panTo(ev.latlng)
    }
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);

    setTimeout(() => {
      document.querySelector("#root").scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const searchListings = (data) => {
    if (!Object.values(data).find(b => b)) {
      return;
    }

    const urlQuery = queryString.stringify(data, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: "bracket"
    });

    history.push(`/listings?${urlQuery}`);
  };

  const getAvailableListingsLabel = () => {
    const start = (currentPage * PAGE_SIZE) - (PAGE_SIZE - 1);
    let end = currentPage * PAGE_SIZE;

    if (end > bestDeals.length) {
      end = bestDeals.length;
    }

    return `${start} – ${end} of ${bestDeals.length} places to stay`;
  };

  return (
    <div className="main-content resale-deals">
      <Row>
        <Col span={24}>
          <Header/>
          <section className="resale-deals__section resale-deals__search">
            <Row justify="center">
              <Col xs={{ span: 22 }} lg={{ span: 18 }} className="container-medium">
                <Row>
                  <Col span={24}>
                    <h1 className="resale-deals__search-title">{pageData.resaleDealsTitle}</h1>
                    <h2 className="resale-deals__search-subtitle">{pageData.resaleDealsSubtitle}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} key={searchQuery.label}>
                    <ListingSearch
                      initialValue={searchQuery}
                      onSearch={searchListings}
                    />
                  </Col>
                </Row>
                <div className="resale-spacer"></div>
              </Col>
            </Row>
          </section>
          {!!highlightHotel && highlightHotel.id &&
          <section className="resale-deals__section resale-deals__hotel-highlight">
            <Row justify="center" className="resale-deals__highlight">
              <Col xs={{ span: 24 }} lg={{ span: 18 }} className="container-medium">
                <HotelView key={highlightHotel} hotel={highlightHotel}/>
                <div className="resale-spacer"></div>
              </Col>
            </Row>
          </section>
          }
          <Row justify="space-around" align="middle" className="listing-list resale-listing-list">
            <Col xs={{ span: 24 }} md={{ span: 22 }}>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 16 }} className={`listing-list__list-container ${mapView ? "mobile-hidden" : ""}`}>
                  <Row>
                    <Col span={24} className="listing-list__items">
                      <Row>
                        <Col xs={{ span: 24 }} xl={{ span: 18 }} className="flex-column">
                          <Typography.Title level={1} className="listing-list__title">
                        Resale Deals
                          </Typography.Title>
                          { !isProcessing && bestDeals && bestDeals.length > 0 && !mapView &&
                        <button className="map-switch" onClick={onShowMap}>
                          <img src="/images/location.svg" alt="location" className="listing-search__place-icon"/>
                          Map View
                        </button>
                          }
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col xs={{ span: 24 }} xl={{ span: 18 }} className="listing-sort__total">
                          { isProcessing && bestDeals && bestDeals.length > 0 &&
                      <React.Fragment>
                        { `${bestDeals.length} Found` }
                      </React.Fragment>
                          }
                          { !isProcessing &&
                      <React.Fragment>
                        {getAvailableListingsLabel()}
                      </React.Fragment>
                          }
                        </Col>
                        <Col
                          className="listing-list__sort"
                          xs={{ span: 14 }}
                          sm={{ span: 14 }}
                          md={{ span: 14 }}
                          lg={{ span: 14 }}
                          xl={{ span: 6 }}
                        >
                          <SortBestDealsList
                            defaultValue="date"
                            list={bestDeals}
                            updateList={(listings) => {
                              setBestDealsList(listings);
                              setCurrentPage(1);
                            }}
                            isProcessing={isProcessing}
                            searchQuery={searchQuery}
                          />
                        </Col>
                        <Col
                          className="listing-list__sort"
                          xs={{ span: 10 }}
                          sm={{ span: 10 }}
                          md={{ span: 10 }}
                          lg={{ span: 10 }}
                          xl={{ span: 6 }}
                        >
                        </Col>
                      </Row>
                      {
                        isProcessing && !bestDeals.length && <React.Fragment>
                          {["load-1", "load-2", "load-3"].map(item => {
                            return (
                              <Row key={item} className="listing-list__item listing-list__item--loading">
                                <Col xs={{ span: 24 }} xl={{ span: 10 }} className="listing-list__item-image-wrapper">
                                  <div className="listing-list__item-image">
                                    <div className="animation-slide-box">
                                      <div className="animation-slide-box__inner" />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={{ span: 24 }} xl={{ span: 14 }} className="listing-list__item-hotel">
                                  <div className="listing-list__item-hotel-line">
                                    <div className="animation-slide-box">
                                      <div className="animation-slide-box__inner" />
                                    </div>
                                  </div>
                                  <div className="listing-list__item-hotel-line">
                                    <div className="animation-slide-box">
                                      <div className="animation-slide-box__inner" />
                                    </div>
                                  </div>
                                  <div className="listing-list__item-hotel-line">
                                    <div className="animation-slide-box">
                                      <div className="animation-slide-box__inner" />
                                    </div>
                                  </div>
                                  <div className="listing-list__item-hotel-line">
                                    <div className="animation-slide-box">
                                      <div className="animation-slide-box__inner" />
                                    </div>
                                  </div>
                                  <div className="listing-list__item-buy">
                                    <div className="animation-slide-box">
                                      <div className="animation-slide-box__inner" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}

                        </React.Fragment>
                      }
                      {
                        sortedBestDeals.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE).map(listing => {
                          return (
                            <div
                              onMouseOut= {() => {
                                if (delayedFlyTo) {
                                  clearTimeout(delayedFlyTo)
                                }
                              }}
                              onMouseOver={() => {
                                const mapCoordinates = listing.Hotel &&
                            listing.Hotel.location &&
                            listing.Hotel.location.coordinates;
                                if (mapCoordinates) {
                                  if (delayedFlyTo) {
                                    clearTimeout(delayedFlyTo)
                                  }
                                  delayedFlyTo = setTimeout(() => {
                                    setSelectedMarker(listing.id);
                                    mapRef.current.leafletElement.flyTo([mapCoordinates[1], mapCoordinates[0]], 14);
                                    if (markerRefs.current[listing.id]?.leafletElement) {
                                markerRefs.current[listing.id]?.leafletElement.openPopup();
                                    }
                                  }, 1000)
                                }
                              }}
                              key={`listing-${listing.id}`}>
                              <ResaleListingItem listing={listing} />
                            </div>
                          )
                        })
                      }
                    </Col>
                  </Row>
                  {
                    bestDeals.length > PAGE_SIZE &&
              <Row justify="center" align="middle" className="listing-list__pagination">
                <Col span={24} align="center">
                  <Pagination
                    current={currentPage}
                    pageSize={PAGE_SIZE}
                    total={bestDeals.length}
                    itemRender={itemRender}
                    onChange={handlePaginationChange}
                  />
                </Col>
              </Row>
                  }
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }} className={`listing-list__map-container ${!mapView ? "mobile-hidden" : ""}`}>
                  <LeafletMap
                    ref={mapRef}
                    center={[coordinates.latitude, coordinates.longitude]}
                    bounds={bounds}
                    zoom={10}
                    minZoom={1}
                    maxZoom={14}
                    attributionControl={true}
                    zoomControl={false}
                    doubleClickZoom={true}
                    scrollWheelZoom={true}
                    dragging={true}
                    animate={true}
                    easeLinearity={0.35}
                    onClick={mapClickHandler}
                  >
                    <TileLayer
                      url={`https://api.mapbox.com/styles/v1/${MAPBOX_USERNAME}/${MAPBOX_STYLE_ID}/tiles/256/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`}
                      // url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                      // url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"
                    />
                    {
                      sortedBestDeals.map(item => {
                        if (!item.Hotel) return null;
                        const mapCoordinates = item.Hotel && item.Hotel.location && item.Hotel.location.coordinates;
                        const imageUrl = item.Hotel.HotelImages[0] && item.Hotel.HotelImages[0].imageUrl;
                        let icon = false;
                        if (typeof window !== "undefined" && item.id) {
                          // eslint-disable-next-line global-require
                          const { divIcon, Point } = Leaflet;
                          icon = divIcon({
                            iconSize: new Point(40, 20),
                            iconAnchor: new Point(20, 20),
                            html: item.type === "organic"
                              ? selectedMarker === item.id
                                ? `<div class="listing-map__marker listing-map__marker_resale active">${getCurrencyBadge(item.Seller.currency)}${(+item.sellingPrice).toFixed(2)}</div>`
                                : `<div class="listing-map__marker listing-map__marker_resale">${getCurrencyBadge(item.Seller.currency)}${(+item.sellingPrice).toFixed(2)}</div>`
                              : selectedMarker === item.id
                                ? `<div class="listing-map__marker active">${getCurrencyBadge(item.Seller.currency)}${(+item.sellingPrice).toFixed(2)}</div>`
                                : `<div class="listing-map__marker">${getCurrencyBadge(item.Seller.currency)}${(+item.sellingPrice).toFixed(2)}</div>`,
                            className: item.type === "organic" ? "leaflet-div-icon resale" : "leaflet-div-icon"
                          });
                        }

                        return mapCoordinates && item.id
                          ? (
                            <Marker
                              ref={el => {
                                markerRefs.current[item.id] = el;
                              }}
                              key={`marker-${item.id}`}
                              icon={icon}
                              position={[mapCoordinates[1], mapCoordinates[0]]}
                              onClick={(ev) => markerClickHandler(ev, item.id)}
                              zIndexOffset={selectedMarker === item.id ? 1000 : item.type === "organic" ? 500 : 0}
                              riseOnHover
                            >
                              <Popup closeButton={true} className="listing-map__popup">
                                <Link
                                  to={`/listings/${item.slug}`}
                                  target="_blank"
                                  className="listing-map__popup-link"
                                >
                                  <div className="listing-map__popup-image">
                                    <ImageBgCover url={imageUrl} height={60} />
                                  </div>
                                  <div className="listing-map__popup-text">
                                    <div className="listing-map__popup-title">{item.Hotel.name}</div>
                                    { item.discount > 0 &&
                                <div className="listing-map__popup-discount">
                                  {`Save ${item.discount}%`}
                                </div>
                                    }
                                  </div>
                                </Link>
                              </Popup>
                            </Marker>
                          )
                          : null;
                      })
                    }
                  </LeafletMap>
                  <div className="listing-map__controls">
                    <img
                      src="/images/icons/plus-solid.svg"
                      className="listing-map__zoom-control"
                      onClick={() => mapRef.current.leafletElement.zoomIn()}
                    />
                    <img
                      src="/images/icons/minus-solid.svg"
                      className="listing-map__zoom-control"
                      onClick={() => mapRef.current.leafletElement.zoomOut()}
                    />
                  </div>
                  <div className="listing-map__switch">
                    {mapView && <button className="map-switch" onClick={onShowList} >
                      <img src="/images/list.svg" alt="location" className="listing-search__place-icon"/>
                List View
                    </button>}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <section className="resale-deals__section resale-deals__hotel-highlights">
                <Row justify="center" className="resale-deals__highlights">
                  <Col xs={{ span: 24 }} lg={{ span: 18 }} className="container-medium">
                    {!!formattedHighlights.length &&
                  <Row gutter={[24, 16]} justify="center" className="resale-deals__highlight-row">
                    {formattedHighlights.map((highlight, key) => {
                      return (
                        <Col xs={{ span: 24 }} lg={{ span: 8 }} key={key} className="hotel-highlight-placeholder">
                          <HighlightCard highlight={highlight} baseElementSize={10} />
                        </Col>
                      )
                    })}
                  </Row>
                    }
                  </Col>
                </Row>
              </section>
              <section className="resale-deals__section">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 20 }}>
                    <Typography.Title
                      className="resale-deals__section-title"
                      level={1}
                    >
                      {pageData.partnersTitle}
                    </Typography.Title>
                    <p>{pageData.partnersSubtitle}</p>
                    <PartnersList partners={partners}/>
                  </Col>
                </Row>
              </section>
              <section className="resale-deals__section">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 20 }}>
                    <SellBooking content={pageData.sellRoomsContent} />
                  </Col>
                </Row>
              </section>
              <section className="resale-deals__section">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 20 }}>
                    <div className="faq-section-header">
                      <div>
                        <Typography.Title className="resale-deals__section-title">FAQs</Typography.Title>
                        <p>All your questions, answered. Okay, at least most.</p>
                      </div>
                      <div>
                        <Link className="faq-section-header__link" to="/faq">
                          <span>See All</span>
                          <img
                            className="faq-section-header__arrow-icon"
                            alt="arrow icon"
                            src="/images/arrow-circle-icon.svg"
                          />
                        </Link>
                      </div>
                    </div>
                    <FaqsList faqs={faqs.slice(0, defaultFaqsAmount)}/>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer/>
    </div>
  )
};

ResaleDeals.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  coordinates: PropTypes.object.isRequired,
  bestDeals: PropTypes.array.isRequired,
  getBestDeals: PropTypes.func.isRequired,
  getHighlights: PropTypes.func.isRequired,
  highlights: PropTypes.array,
  match: PropTypes.object.isRequired,
  pageData: PropTypes.any.isRequired,
  categories: PropTypes.array.isRequired,
  getFaq: PropTypes.func.isRequired,
  setPlaces: PropTypes.func.isRequired,
  getStaticPage: PropTypes.func.isRequired
};

export default connect(
  state => ({
    coordinates: state.listing.coordinates,
    isProcessing: state.listing.processing,
    bestDeals: state.listing.bestDeals,
    highlights: state.highlights.data,
    pageData: state.staticPage.page,
    categories: state.staticPage.faq.categories,
  }),
  {
    getBestDeals: getBestDealsRequest,
    getHighlights: getHighlightsRequest,
    getFaq: getFaqRequest,
    setPlaces: setPlacesListAction,
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "resale-deals" }),
  }
)(ResaleDeals);
