import React, { useEffect } from "react";

export const FacebookPixel = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const img = document.createElement("img");
      img.width = 1;
      img.height = 1;
      img.style = "display:none";
      img.src = `https://www.facebook.com/tr?id=${process.env.REACT_APP_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`;

      const noScript = document.createElement("noscript");
      noScript.appendChild(img);

      const script = document.createElement("script");
      script.type = "text/plain";
      script.setAttribute("data-cookie-consent", "targeting");
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.REACT_APP_FACEBOOK_PIXEL_ID}');
        fbq('track', 'PageView');
      `;

      const node = document.getElementsByTagName("script")[0];
      node.parentNode.insertBefore(script, node);
      node.parentNode.insertBefore(noScript, node);
    }
  }, []);

  return <React.Fragment/>
};

export default FacebookPixel;
